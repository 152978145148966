<template>
  <div class="relative" @click.prevent="$emit('cardClick', item)">
    <div
      class="
        absolute
        z-10
        text-white text-xs
        antialiased
        tracking-wide
        px-3
        py-1
        rounded-tl rounded-br
        shadow
      "
      :class="{
        'bg-gray-600': item.status === -1,
        'bg-yellow-500': item.status === 0,
        'bg-green-600': item.status === 1,
        'bg-blue-500': item.status === 2,
        'bg-gray-500': item.status === 3,
        'bg-red-500': item.status === 5,
        'bg-gray-400': item.status === 6,
        'bg-gray-500': item.status === 7,
      }"
    >
      {{ item.statusLabel }}
    </div>

    <div
      class="absolute z-10 text-white text-xs antialiased tracking-wide right-0"
      v-tooltip="{
        theme: {
          placement: 'bottom-left',
          theme: {},
        },
      }"
    >
      <div
        v-if="item?.isTalentRequired"
        class="px-1 py-1 rounded-tr rounded-bl bg-green-600 shadow"
        v-tooltip="'Looking for Talent'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <img
      class="
        object-cover
        h-32
        w-80
        rounded-lg rounded-tr-none rounded-br-none
        cursor-pointer
      "
      :src="item.image"
      alt=""
    />
  </div>
  <div
    class="relative w-4/4 w-full ml-4 inline-block pr-4 cursor-pointer"
    @click.prevent="$emit('cardClick', item)"
  >
    <div class="break-all">
      <div class="text-black text-xl line-clamp-1">
        {{ item.name }}
      </div>
      <div
        class="text-gray-500"
        v-if="item?.description?.length < 180"
      >
        {{ item?.description }}
      </div>
      <div class="text-gray-500" v-else>
        {{ item?.description?.substring(0, 180) + " ..." }}
      </div>
    </div>
    <div>
      <div class="absolute bottom-2 left-0 right-4 pt-2 text-xs text-gray-700">
        <div class="flex items-center justify-between leading-tight">
           <avatar-grouping position="right" :dataObj="item?.metaData" />
          <!-- <div class="flex -space-x-1 overflow-hidden">
            <img
              v-for="(user, index) in item.users.slice(0, 3)"
              :key="index"
              class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
              :src="user.image"
              alt="users"
              :title="user.name"
            />

            <div
              v-if="item.users.length - 3 > 0"
              class="
                inline-block
                rounded-full
                w-8
                h-8
                bg-gray-500
                p-0
                border-0
                inline-flex
                items-center
                justify-center
                text-gray-500
                ml-2
              "
            >
              <div
                class="
                  sm:text-base
                  text-base
                  font-medium
                  title-font
                  text-gray-200
                "
              >
                +{{ item.users.length - 3 }}
              </div>
            </div>
          </div> -->
          <div class="text-grey-darker text-sm text-right float-right">
            <div
              v-if="item.phase"
              class="
                inline-block
                px-2
                py-1
                mr-2
                leading-none
                bg-gray-500
                text-white
                rounded-full
                font-semibold
                tracking-wide
                text-xs
              "
            >
              {{ item.phase }}
            </div>

            <div class="inline-block text-left">
              <span v-if="typeof item.type === 'object'">
                <span
                  v-if="item.type.length === 0"
                  class="
                    mr-1
                    inline-block
                    px-2
                    py-1
                    leading-none
                    border border-blue-500
                    text-blue-500
                    rounded-full
                    font-semibold
                    tracking-wide
                    text-xs
                  "
                  >No Theme</span
                >
                <span
                  v-else-if="item.type.length === 1"
                  class="
                    mr-1
                    inline-block
                    px-2
                    py-1
                    leading-none
                    border border-blue-500
                    text-blue-500
                    rounded-full
                    font-semibold
                    tracking-wide
                    text-xs
                  "
                  >{{ item.type[0] }}</span
                >
                <div class="dropdown inline-block relative" v-else>
                  <span
                    @mouseover="showTypeOver(item.name)"
                    @mouseout="showTypeOut(null)"
                    class="
                      mr-1
                      inline-block
                      px-2
                      py-1
                      leading-none
                      border border-blue-500
                      text-blue-500
                      rounded-full
                      font-semibold
                      tracking-wide
                      text-xs
                    "
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3 w-3 inline-block"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    {{ item.type.length }} Themes
                  </span>
                  <ul
                    v-show="showType === item.name"
                    @mouseover="showTypeOver(item.name)"
                    @mouseout="showTypeOut(null)"
                    class="
                      dropdown-menu
                      absolute
                      bg-white
                      text-blue-500
                      border border-blue-500
                      bg-blue-50
                      z-50
                      p-1
                      -left-28
                      mt-0
                      max-h-48
                      overflow-auto
                      rounded
                    "
                  >
                    <li
                      v-for="type in item.type"
                      :key="type"
                      class="
                        p-0.5
                        m-0.5
                        text-xs
                        float-left
                        inline-block
                        px-2
                        py-1
                        leading-none
                        border border-blue-500
                        text-blue-500
                        rounded-full
                        text-xs
                      "
                    >
                      {{ type }}
                    </li>
                  </ul>
                </div>
              </span>
              <span v-if="typeof item.type === 'string'">
                <span
                  class="
                    mr-2
                    inline-block
                    px-2
                    py-1
                    leading-none
                    bg-indigo-200
                    text-indigo-800
                    rounded-full
                    font-semibold
                    tracking-wide
                    text-xs
                  "
                  >{{ item.type }}</span
                ><br />
              </span>
            </div>

            {{ item.date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LikeFallowShare from "../components/LikeFallowShare.vue";
import AvatarGrouping from "../components/AvatarGrouping.vue";
import ActionSignUpIcon from "../components/ActionSignUpIcon.vue";
export default {
  data() {
    return {
      showType: "",
    };
  },
  components: {
    LikeFallowShare,
    AvatarGrouping,
    ActionSignUpIcon,
  },
  props: {
    item: Object,
    AvatarGrouping: Array,
  },
  methods: {
    showTypeOver: function (item) {
      this.showType = item;
    },
    showTypeOut: function (item) {
      this.showType = null;
    },
  },
};
</script>
