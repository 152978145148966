import feathersClient from "../services/feathersClient";
import moment from "moment";

const state = {
  dashboardData: {},
  bogLoading: true,
  phaseLoading: true,
  leaderboardLoading: true,
  leaderBoardData: null,
  judgingProgressTableData: []
};

const mutations = {
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  BOG_LOADING_STATUS(state, payload) {
    state.bogLoading = payload;
  },
  PHASE_LOADING_STATUS(state, payload) {
    state.phaseLoading = payload;
  },
  LEADER_BOARD_LOADING_STATUS(state, payload) {
    state.leaderboardLoading = payload;
  },
  LEADER_BOARD_DATA(state, payload) {
    state.leaderBoardData = payload;
  },
  // TABLE_DATA(state, payload) {
  //   state.tableData = payload;
  // },
  JUDGING_PROGRESS_TABLE_DATA(state, payload) {
    state.judgingProgressTableData = payload;
  },
};
const actions = {
  getBogData(context, payload) {
    return context.dispatch("getBogo", payload);
  },

  getPhaseData(context, payload) {
    return context.dispatch("getDetailsByPhaseWise", payload);
  },
  getUsersData(context, payload) {
    return context.dispatch("getLeaderBoardData", payload);
  },
  async getBogo(context, payload) {
    let createdStartDate, createdEndDate;
    if (payload?.date) {
      createdStartDate = moment(payload?.date).toDate();
      createdStartDate.setHours(0);
      createdStartDate.setMinutes(0);
      createdStartDate.setSeconds(0);
      createdStartDate.setMilliseconds(0);

      //
      createdEndDate = moment(payload?.date).toDate();
      createdEndDate.setHours(23);
      createdEndDate.setMinutes(59);
      createdEndDate.setSeconds(59);
      createdEndDate.setMilliseconds(59);
    } else {
      createdStartDate = new Date(moment(new Date()));
      createdStartDate.setHours(0);
      createdStartDate.setMinutes(0);
      createdStartDate.setSeconds(0);
      createdStartDate.setMilliseconds(0);

      //
      createdEndDate = new Date(moment(new Date()));
      createdEndDate.setHours(23);
      createdEndDate.setMinutes(59);
      createdEndDate.setSeconds(59);
      createdEndDate.setMilliseconds(59);
    }

    let dashboard = {};
    const { accessToken, apiKey } = context.rootState.user.user;
    const initiativesDashboardService = feathersClient.service(
      "initiatives/dashboard/bogo"
    );
    const initiatives = feathersClient.service(
      "initiatives"
    );
    const tokenString = `Bearer ${accessToken}`;

    // const result = await initiatives.find({
    //   query: {"$select": ["metaData"],},
    //   headers: {
    //     "Authorization": tokenString
    //   },
    // });

    const query = {
      baseId: process.env.VUE_APP_BASE_ID,
      aggragation: [
        {
          $match: {
            $and: [
              { metaData: { $exists: true } },
              { organizationId: context.rootState.user.user.organizationId },
              { parentInitiativeId: { $exists: false } },
              { status: { $in: [0, 1, 2, 3, 5, 6, 7] } },
              { baseId: process.env.VUE_APP_BASE_ID },
            ],
          },
        },
        {
          $project: {
            _id: 1,
            name: 1,
            bogFramework: "$metaData.challengeType",
            greenBog: {
              $cond: [{ $eq: ["$metaData.challengeType", "Strategic Impact"] }, 1, 0],
            },
            blueBog: {
              $cond: [{ $eq: ["$metaData.challengeType", "Market Impact"] }, 1, 0],
            },
            orangeBog: {
              $cond: [{ $eq: ["$metaData.challengeType", "Velocity Impact"] }, 1, 0],
            },
            otherBog: {
              $cond: {
                if: {
                  $and: [
                    { $ne: ["$metaData.challengeType", "Strategic Impact"] },
                    { $ne: ["$metaData.challengeType", "Market Impact"] },
                    { $ne: ["$metaData.challengeType", "Velocity Impact"] },
                  ]
                },
                then: 1,
                else: 0
              }
            },
          },
        },
        {
          $group: {
            _id: null,
            "Strategic Impact": { $sum: "$greenBog" },
            "Market Impact": { $sum: "$blueBog" },
            "Velocity Impact": { $sum: "$orangeBog" }
          },
        },
      ]
    };

    query.aggragation = JSON.stringify(query.aggragation);
    try {
      context.commit("BOG_LOADING_STATUS", true);
      const tokenString = `Bearer ${accessToken}`;
      const result = await initiativesDashboardService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });
      let value = result[0] || {};
      let output = Object.entries(value).map(([name, value]) => ({
        name,
        value,
      }));
      output.shift();
      context.commit("BOG_LOADING_STATUS", false);
      return output;
      return result;
    } catch (err) {
      context.commit("BOG_LOADING_STATUS", false);
      console.log(err);
    }
  },
  async getJudgingProgress(context, payload){
    try {
      let playbookPhasesData;
      const { accessToken, apiKey } = context.rootState.user.user;
      const baseId = process.env.VUE_APP_BASE_ID;
      playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
      let judgingPhaseNames = [];
      let judgingPhases = playbookPhasesData.filter(phase=>phase?.metaData?.isJudgingRequired);
      for(let phase of judgingPhases){
        judgingPhaseNames.push(phase.name);
      }
      let judgingPlaybookBaseIds = [];
      for(let phase of playbookPhasesData){
        if(phase?.metaData?.judgingPlaybookBaseId){
          judgingPlaybookBaseIds.push(phase.metaData.judgingPlaybookBaseId);
        }
      }
      let andCondition = [{"status": {"$in": [0, 1, 2, 3, 5, 6, 7]}},{"baseId": baseId}];
      const query = {
        $and: andCondition,
        $select: ['_id', 'name', 'status', 'activities', 'metaData', 'subInitiatives'],
        $populate: [{
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name runStatus'
            },
            select: 'name runStatus activities'
          },
          select: 'name runStatus activities metaData'
        },{
          path: 'subInitiatives', select: 'name activities metaData status teamId',
          populate:[{path:'teamId', select: 'members'}]
        }]
      }
      const initiativeService = feathersClient.service("initiatives");
      let initiativeData = await initiativeService.find({query: query});
      let seriesData = {};
      let categoryArray = [];
      let judgingCompleted = [];
      for(let phaseName of judgingPhaseNames){
        categoryArray.push({"value": [phaseName, 0]});
        judgingCompleted.push({"value": [phaseName, 0]})
      }
      if(initiativeData?.data?.length > 0){
        let counts = {
          phase1IdeaCount : 0,
          phase2IdeaCount : 0,
          phase3IdeaCount : 0,
          phase4IdeaCount : 0,
          phase5IdeaCount : 0,
          phase6IdeaCount : 0,
          phase1JudgingCompletedIdeaCount : 0,
          phase2JudgingCompletedIdeaCount : 0,
          phase3JudgingCompletedIdeaCount : 0,
          phase4JudgingCompletedIdeaCount : 0,
          phase5JudgingCompletedIdeaCount : 0,
          phase6JudgingCompletedIdeaCount : 0
        }
        let validSubIdeaStatus = [0, 1, 2]
        for(let idea of initiativeData.data){
          for(let [i, activity] of idea.activities.entries()){
            if(activity.runStatus==1){
              counts[`phase${i+1}IdeaCount`]++;
              if(activity?.metaData?.selectedJudgesTeamId && idea?.subInitiatives?.length>0){
                for(let [index, subIdea] of idea.subInitiatives.entries()){
                  if(validSubIdeaStatus.includes(subIdea.status) && 
                      subIdea?.teamId?._id && 
                      subIdea?.metaData?.reviews?.length>0 && 
                      subIdea?.teamId?.members?.length>0 && 
                      subIdea.teamId._id == activity.metaData.selectedJudgesTeamId){
                    if(subIdea.metaData.reviews.length == subIdea.teamId.members.length){
                      counts[`phase${i+1}JudgingCompletedIdeaCount`]++;
                    }
                  }
                }
              }
            }
          }
        }
        categoryArray[0].value[1] = counts.phase3IdeaCount;
        categoryArray[0].itemStyle = {color: '#00ABD6'}
        categoryArray[1].value[1] = counts.phase4IdeaCount;
        categoryArray[1].itemStyle = {color: '#00ABD6'}
        categoryArray[2].value[1] = counts.phase5IdeaCount;
        categoryArray[2].itemStyle = {color: '#00ABD6'}
        judgingCompleted[0].value[1] = counts.phase3JudgingCompletedIdeaCount;
        judgingCompleted[0].itemStyle = {color: '#DEDEDE'}
        judgingCompleted[1].value[1] = counts.phase4JudgingCompletedIdeaCount;
        judgingCompleted[1].itemStyle = {color: '#DEDEDE'}
        judgingCompleted[2].value[1] = counts.phase5JudgingCompletedIdeaCount;
        judgingCompleted[2].itemStyle = {color: '#DEDEDE'}
        seriesData.categoryArray = categoryArray;
        seriesData.judgingCompleted = judgingCompleted;
        seriesData.judgingPhaseNames = judgingPhaseNames;
        return seriesData;
      }
    } catch (error) {
      console.log("🚀 ~ file: dashboard.js ~ line 206 ~ getJudgingProgress ~ error", error) 
    }
  },

  async createLeaderBoard(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {


      const leaderBoardTabledata = {
        accountId: payload.user.accountId,
        accountUserId: payload.user.aspenifyUserId,
        type: "leaderboard",
        metaData: {
          tableData: payload.tableData
        },
        lastUpdatedBy: payload.user.aspenifyUserId,
        createdBy: payload.user.aspenifyUserId,
        organizationId: context.rootState.user.user.organizationId,
        suborganizationId: null
      };


      const result = await TeamResourceService.create(leaderBoardTabledata);

      context.commit("LEADER_BOARD_DATA", result);
      context.commit("LEADER_BOARD_LOADING_STATUS", false);
      return result.data[0]
    } catch (error) {
      console.log(error)
      context.commit("LEADER_BOARD_LOADING_STATUS", false);
    }
  },

  async getLeaderBoardData(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        type: 'leaderboard'
      }

      const result = await TeamResourceService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      context.commit("LEADER_BOARD_DATA", result.data[0]);
      context.commit("LEADER_BOARD_LOADING_STATUS", false);
      return result.data[0]
    } catch (error) {
      console.log(error)
      context.commit("LEADER_BOARD_LOADING_STATUS", false);
    }
  },
  async updateLeaderBoardData(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {


      await TeamResourceService.patch(payload.id,
        {
          $set: {
            'metaData.tableData': payload.updatedTableData
          }
        });

    } catch (error) {
      console.log(error)
    }
  },

  async getDetailsByPhaseWise(context, payload) {
    const { accessToken, apiKey } = context.rootState.user.user;
    const initiativeService = feathersClient.service("initiatives");
    const query = {
      $select: ["name","metaData.challengeType", "activities"],
      $and: [
        { status: {"$in": [0, 1, 2, 3, 5, 6, 7]} },
        { baseId: process.env.VUE_APP_BASE_ID },
      ],
      $populate: [
        {
          path: "activities",
          select: "name runStatus",
        },
      ]
    };

    const playbookService = feathersClient.service("playbooks");
    const playbookQuery = {
      status: 4,
      $select: ["metaData.challengeType", "activities"],
      baseId: process.env.VUE_APP_BASE_ID,
      $populate: [
        {
          path: "activities",
          select: "name",
        },
      ]
    }


    try {
      context.commit("PHASE_LOADING_STATUS", true);
      const tokenString = `Bearer ${accessToken}`;

      const playbookData = await playbookService.find({
        query: playbookQuery,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }, 
      });

      const initiativesInfo = await initiativeService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });
      context.commit("PHASE_LOADING_STATUS", false);
      return {
        playbookData : playbookData[0],
        initiativesInfo
      };

    } catch (err) {
      context.commit("PHASE_LOADING_STATUS", false);
      console.log(err);
    }
  },

  async getJudgingProgressTable(context, payload) {
    const { accessToken, apiKey } = context.rootState.user.user;
    const initiativeService = feathersClient.service("initiatives");
    const baseId = process.env.VUE_APP_BASE_ID;
    let playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
    let judgingPhaseNames = [];
    let judgingPhases = playbookPhasesData.filter(phase=>phase?.metaData?.isJudgingRequired);
    for(let phase of judgingPhases){
      judgingPhaseNames.push(phase.name);
    }
    let seriesData = {};
    let categoryArray = [];
    let judgingCompleted = [];
    for(let phaseName of judgingPhaseNames){
      categoryArray.push({"value": [phaseName, 0]});
      judgingCompleted.push({"value": [phaseName, 0]})
    }
    let andCondition = [{"status": {"$in": [0, 1, 2, 3, 5, 6, 7]}},{"baseId": baseId}];
    const query = {
      $and: andCondition,
      $select: ['_id', 'name', 'status', 'activities', 'metaData', 'subInitiatives'],
      $populate: [{
        path: 'activities',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            select: 'name runStatus'
          },
          select: 'name runStatus activities'
        },
        select: 'name runStatus activities metaData'
      },{
        path: 'subInitiatives', select: 'name activities metaData status teamId',
        populate:[{path:'teamId', select: 'members'}]
      }]
    }
    try {
      let tableData = [];
      let phaseNames = context.rootState.playbook.phaseNames;
      let playbookPhases = phaseNames.map((phase) => {
        return phase.label;
      })
      for(let phase of phaseNames){
        tableData.push({
          "phaseName": phase.label,
          "categories": [
            {"name": "Strategic Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0, }, 
            {"name": "Market Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0},
            {"name": "Velocity Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0}
          ],
          "phaseTotalIdeas": 0,
          "judgedTotalIdeas": 0,
          "movedOnTotalIdeas": 0,
          "judgingingCompletedInPercentage": 0
        });
        tableData.rowBackgroundColor= "#DADADA";
        tableData.judgingPipelineData = [];
      }
      const tokenString = `Bearer ${accessToken}`;
      const initiativesInfo = await initiativeService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });
      if(initiativesInfo?.data?.length>0){
        for(let initiative of initiativesInfo.data){
          let lastPhaseId = initiative.activities.slice(-1)[0]._id;
          initiative.activities.every(function (phaseActivity, phaseIndex) {
            let runStatuses = [-1, 0, 1, 6];
            if (lastPhaseId == phaseActivity._id) {
              runStatuses = [-1, 0, 1, 2, 6];
            }
            if (runStatuses.includes(phaseActivity.runStatus)) {
              if(playbookPhasesData?.length>0){
                let currentActivity = playbookPhasesData[phaseIndex];
                if(currentActivity?.metaData?.judgingPlaybookBaseId){
                  initiative.judgingPlaybookBaseId = currentActivity.metaData.judgingPlaybookBaseId;
                }
              }
              initiative.phase = playbookPhases[phaseIndex];
              initiative.phaseIndex = phaseIndex;
              return false;
            }
            return true;
          });
        }
        let sortedIdeaData = initiativesInfo.data.sort((a, b) => {
          return (b.phaseIndex - a.phaseIndex)
        });
        for(let idea of sortedIdeaData){
          let categoryIndex = tableData[idea.phaseIndex].categories.findIndex(
            (x) => x.name == idea.metaData.challengeType
          );
          for (let i = idea.phaseIndex; i >= 0; i--) {
            tableData[i].categories[categoryIndex].phaseTotal += 1;
            if(i>0){
              tableData[i-1].categories[categoryIndex].movedOnTotal = tableData[i].categories[categoryIndex].phaseTotal;
            }
          }
          if(idea?.subInitiatives?.length>0){
            for(let subInitiative of idea.subInitiatives){
              idea.activities.map((activity, index)=>{
                if(activity?.metaData?.selectedJudgesTeamId && subInitiative?.teamId?._id && activity.metaData.selectedJudgesTeamId == subInitiative.teamId._id){
                  if(subInitiative?.metaData?.reviews?.length>0 && subInitiative.metaData.reviews.length == subInitiative.teamId.members.length){
                    tableData[index].categories[categoryIndex].judgedTotal += 1;
                  }
                }
              })
            }
          }
        }
      }
      for(let [i, data] of tableData.entries()){
        for(let category of data.categories){
          if(category.phaseTotal){
            data[`${category.name} Rate of Conversion`] = category.movedOnTotal/category.phaseTotal*100;
            data[`${category.name} Rate of Conversion`] = `${Math.round(data[`${category.name} Rate of Conversion`])} %`;
          } else {
            data[`${category.name} Rate of Conversion`] = 0;
          }
          data.phaseTotalIdeas += category.phaseTotal;
          data.judgedTotalIdeas += category.judgedTotal;
          data.movedOnTotalIdeas += category.movedOnTotal;
          data[category.name] = category.phaseTotal
        }

        if(data.phaseTotalIdeas > 0){
          data.judgingingCompletedInPercentage = data.judgedTotalIdeas/data.phaseTotalIdeas*100;
          data.judgingingCompletedInPercentage = `${Math.round(data.judgingingCompletedInPercentage)} %`;
          data['Active Submissions'] = data.phaseTotalIdeas;
          data['Active Submissions Rate of Conversion'] = data.movedOnTotalIdeas/data.phaseTotalIdeas*100;
          data['Active Submissions Rate of Conversion'] = `${Math.round(data['Active Submissions Rate of Conversion'])} %`;
        } else {
          data['Active Submissions'] = 0;
          data['Active Submissions Rate of Conversion'] = 0;
        }
        if(i==2){
          categoryArray[0].value[1] = data.phaseTotalIdeas;
          categoryArray[0].itemStyle = {color: '#00ABD6'}
          judgingCompleted[0].value[1] = data.judgedTotalIdeas
          judgingCompleted[0].itemStyle = {color: '#DEDEDE'}
          tableData.judgingPipelineData.push(data);
        }
        if(i==3){
          categoryArray[1].value[1] = data.phaseTotalIdeas;
          categoryArray[1].itemStyle = {color: '#00ABD6'}
          judgingCompleted[1].value[1] = data.judgedTotalIdeas
          judgingCompleted[1].itemStyle = {color: '#DEDEDE'}
          tableData.judgingPipelineData.push(data);
        }
        if(i==4){
          categoryArray[2].value[1] = data.phaseTotalIdeas;
          categoryArray[2].itemStyle = {color: '#00ABD6'}
          judgingCompleted[2].value[1] = data.judgedTotalIdeas
          judgingCompleted[2].itemStyle = {color: '#DEDEDE'}
          tableData.judgingPipelineData.push(data);
        }
      }
      tableData.categoryArray = categoryArray;
      tableData.judgingCompleted = judgingCompleted;
      tableData.judgingPhaseNames = judgingPhaseNames;
      context.commit("JUDGING_PROGRESS_TABLE_DATA", tableData);
    } catch (err) {
      console.log(err);
    }
  },
  
  // async getJudgingTableData(context, payload) {
  //   let andCondition = [{"status": {"$in": [0, 1, 2]}},{"baseId": process.env.VUE_APP_BASE_ID}];
  //   const query = {
  //     $and: andCondition,
  //     $select: ['_id', 'name', 'status', 'activities', 'metaData', 'subInitiatives'],
  //     $populate: [{
  //       path: 'activities',
  //       populate: {
  //         path: 'activities',
  //         populate: {
  //           path: 'activities',
  //           select: 'name runStatus'
  //         },
  //         select: 'name runStatus activities'
  //       },
  //       select: 'name runStatus activities metaData'
  //     },{
  //       path: 'subInitiatives', select: 'name activities metaData status teamId',
  //       populate:[{path:'teamId', select: 'members'}]
  //     }]
  //   }
  //   const { accessToken, apiKey } = context.rootState.user.user;
  //   const initiativeService = feathersClient.service("initiatives");
  //   const playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
  //   try {
  //     let tableData = [];
  //     let phaseNames = context.rootState.playbook.phaseNames;
  //     let playbookPhases = phaseNames.map((phase) => {
  //       return phase.label;
  //     })
  //     for(let phase of phaseNames){
  //       tableData.push({
  //         "phaseName": phase.label,
  //         "categories": [
  //           {"name": "Strategic Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0, }, 
  //           {"name": "Market Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0},
  //           {"name": "Velocity Impact", "phaseTotal": 0, "judgedTotal": 0, "movedOnTotal": 0}
  //         ],
  //         "phaseTotalIdeas": 0
  //       });
  //       tableData.rowBackgroundColor= "#DADADA"
  //     }
  //     const tokenString = `Bearer ${accessToken}`;
  //     const initiativesInfo = await initiativeService.find({
  //       query: query,
  //       headers: {
  //         "x-api-key": apiKey,
  //         "oauth-accesstoken": tokenString,
  //       },
  //     });
  //     if(initiativesInfo?.data?.length>0){
  //       for(let initiative of initiativesInfo.data){
  //         let lastPhaseId = initiative.activities.slice(-1)[0]._id;
  //         initiative.activities.every(function (phaseActivity, phaseIndex) {
  //           let runStatuses = [-1, 0, 1, 6];
  //           if (lastPhaseId == phaseActivity._id) {
  //             runStatuses = [-1, 0, 1, 2, 6];
  //           }
  //           if (runStatuses.includes(phaseActivity.runStatus)) {
  //             if(playbookPhasesData?.length>0){
  //               let currentActivity = playbookPhasesData[phaseIndex];
  //               if(currentActivity?.metaData?.judgingPlaybookBaseId){
  //                 initiative.judgingPlaybookBaseId = currentActivity.metaData.judgingPlaybookBaseId;
  //               }
  //             }
  //             initiative.phase = playbookPhases[phaseIndex];
  //             initiative.phaseIndex = phaseIndex;
  //             return false;
  //           }
  //           return true;
  //         });
  //       }
  //       let sortedIdeaData = initiativesInfo.data.sort((a, b) => {
  //         return (b.phaseIndex - a.phaseIndex)
  //       });
  //       for(let idea of sortedIdeaData){
  //         let categoryIndex = tableData[idea.phaseIndex].categories.findIndex(
  //           (x) => x.name == idea.metaData.challengeType
  //         );
  //         for (let i = idea.phaseIndex; i >= 0; i--) {
  //           tableData[i].categories[categoryIndex].phaseTotal += 1;
  //           if(i>0){
  //             tableData[i-1].categories[categoryIndex].movedOnTotal = tableData[i].categories[categoryIndex].phaseTotal;
  //           }
  //         }
  //         if(idea?.subInitiatives?.length>0){
  //           for(let subInitiative of idea.subInitiatives){
  //             idea.activities.map((activity, index)=>{
  //               if(activity?.metaData?.selectedJudgesTeamId && subInitiative?.teamId?._id && activity.metaData.selectedJudgesTeamId == subInitiative.teamId._id){
  //                 if(subInitiative?.metaData?.reviews?.length>0 && subInitiative.metaData.reviews.length == subInitiative.teamId.members.length){
  //                   tableData[index].categories[categoryIndex].judgedTotal += 1;
  //                 }
  //               }
  //             })
  //           }
  //         }
  //       }
  //     }
  //     for(let data of tableData){
  //       for(let category of data.categories){
  //         data.phaseTotalIdeas += category.phaseTotal
  //       }
  //     }
  //     context.commit("TABLE_DATA", tableData);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
};

const getters = {
  dashboardData: (state) => state.dashboardData,
  bogLoading: (state) => state.bogLoading,
  phaseLoading: (state) => state.phaseLoading,
  leaderBoardData: (state) => state.leaderBoardData,
  leaderboardLoading: (state) => state.leaderboardLoading,
  // tableData: (state) => state.tableData,
  judgingProgressTableData: (state) => state.judgingProgressTableData
};

const DashboardModule = {
  state,
  mutations,
  actions,
  getters,
};

export default DashboardModule;
