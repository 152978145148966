<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-40
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white shadow-lg w-5/12 text-gray-600">
        <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
          <div class="font-medium text-white text-lg flex w-6/12">
            <div class="flex-grow w-1/12 font-bold mt-2">Open Mic Request</div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="this.$emit('closeRequestModal')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div class="p-5">
          <div>
            <p class="font-bold">The Request has been submitted</p>
            <div class="items-center shadow-md mt-2 rounded">
              <div class="bg-white w-full flex items-center py-2">
                <img
                  :src="setData?.commentBy?.profilePic"
                  :title="setData?.commentBy?.name"
                  alt="avatar"
                  class="w-10 h-10 object-cover rounded-full mx-3"
                />
                <div class="w-full">
                  <div class="float-right px-3 py-3 text-sm">
                    {{
                      timeago.format(
                        new Date(
                          selectedInitiative?.metaData?.signUpForOpenmic?.date
                        )
                      )
                    }}
                  </div>
                  <a href="#" class="text-gray-700 font-bold hover:underline">
                    {{ setData?.commentBy?.name }}
                  </a>

                  <div class="text-gray-700 text-sm font-light">
                    {{ setData?.commentBy?.role }}
                  </div>
                </div>
              </div>
              <p class="p-2 border-t border-gray-100 h-40 overflow-auto">
                {{ setData?.comment }}
              </p>
            </div>
            <p class="my-3">
              You will receive a message from the Open Mic program team
              indicating the scheduled date for your Open Mic session.
            </p>
          </div>

          <div>
            <div class="text-right mt-3">
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
                @click="this.$emit('closeRequestModal')"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import * as timeago from "timeago.js";
export default {
  name: "ViewOpenMicRequest",
  components: {},
  props: {
    actionType: String,
    selectedInitiative: Object,
  },
  emits: ["closeRequestModal"],
  data() {
    return {
      comment: "",
      timeago,
    };
  },
  methods: {},
  computed: {
    setActionType() {
      let data = [];
      let meta = this.selectedInitiative?.metaData;
      if (this.actionType === "viewSignUp") {
        data = meta?.signUpForOpenmic;
      } else if (this.actionType === "viewArchitecture") {
        data = meta?.registerArchitecture;
      } else if (this.actionType === "viewBuildingBlock") {
        data = meta?.registerBuildingBlock;
      } else if (this.actionType === "viewStaff") {
        data = meta?.registerExtendedStaff;
      }
      return data;
    },
    setData() {
      let user =
        this.setActionType[this.setActionType?.length - 1]?.userComment;
      return user;
    },
  },
  mounted: function () {},
  created: function () {},
  beforeUpdate() {},
};
</script>
