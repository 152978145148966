<template>
<!-- <pre>{{idea}}</pre> -->
  <div v-if="idea" class="bg-white rounded block overflow-hidden relative">
    <div
      data-placeholder
      class="absolute z-10 text-white text-xs antialiased tracking-wide"
    >
      <div class="px-3 py-1 rounded-tl rounded-br shadow bg-yellow-500">
        Submitted
      </div>
    </div>
    <div data-placeholder class="relative pb-48 bg-gray-100 overflow-hidden">
      
    </div>
    <div class="flex-1 p-4">
      <div class="">
        <div
          data-placeholder
          class="
            h-5
            w-1/5
            overflow-hidden
            relative
            bg-gray-200
            inline-block
            mr-2
            rounded-full
          "
        ></div>
        <div
          data-placeholder
          class="
            h-5
            w-1/5
            overflow-hidden
            relative
            bg-gray-200
            inline-block
            mr-2
            rounded-full
          "
        ></div>
      </div>
      <div style="min-height: 180px">
        <h2 class="mt-2 mb-2 text-gray-800 font-bold break-words">
          {{idea.name}}
        </h2>
        <div
          data-placeholder
          class="h-1 w-full overflow-hidden relative bg-gray-200 mb-1"
        ></div>
        <div
          data-placeholder
          class="h-1 w-full overflow-hidden relative bg-gray-200 mb-1"
        ></div>
        <div
          data-placeholder
          class="h-1 w-full overflow-hidden relative bg-gray-200 mb-1"
        ></div>
        <div
          data-placeholder
          class="h-1 w-full overflow-hidden relative bg-gray-200 mb-1"
        ></div>
        <div
          data-placeholder
          class="h-1 w-1/3 overflow-hidden relative bg-gray-200 mb-1"
        ></div>
      </div>
    </div>
    <div class="p-4 border-t border-b text-xs text-gray-700">
      <div class="flex items-center justify-between leading-tight">
        <div class="text-grey-darker text-sm">
          <h3>Submitted</h3>
          <div
            data-placeholder
            class="h-5 w-20 overflow-hidden relative bg-gray-200"
          ></div>
        </div>

        <div class="text-grey-darker text-sm text-right">
          <h3>Updated</h3>
          <div
            data-placeholder
            class="h-5 w-20 overflow-hidden relative bg-gray-200"
          ></div>
        </div>
      </div>
    </div>
    <div class="p-4 text-xs text-gray-700">
      <div class="flex items-center justify-between leading-tight">
        <div
          data-placeholder
          class="h-5 w-20 overflow-hidden relative bg-gray-200"
        ></div>

        <div
          data-placeholder
          class="h-5 w-20 overflow-hidden relative bg-gray-200"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    idea:Object
  },
};
</script>
<style scoped>
[data-placeholder]::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgba(254, 254, 254);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  animation: load 1s infinite;
}
@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}
</style>