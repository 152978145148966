<template>
  <div>
    <div class="file-input" @change="onChange">
      <input type="file" :id="id" class="file" />
      <label :for="label">
        {{name}}
        <p class="file-name"></p>
      </label>
    </div>

    <!-- <input type="file" size="30" @change="onChange" /> -->
    <xlsx-read :file="file">
      <xlsx-json>
        <template #default="{ collection }">
          <div>
            {{ emit(collection) }}
          </div>
        </template>
      </xlsx-json>
    </xlsx-read>
  </div>
</template>

<script>
import {
  XlsxRead,
  XlsxJson,
  XLSX,
} from "../../node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";

export default {
  components: {
    XlsxRead,
    XlsxJson,
    XLSX,
  },
  data() {
    return {
      file: null,
    };
  },
  props: {
    name: String,
    label: String,
    id: String
  },
  methods: {
    onChange() {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    emit(collection) {
      if (collection) {
        if(this.name == "Import Ideas"){
          this.$emit('importIdeas', collection);
        }
        if(this.name == "Import BU"){
          this.$emit('importBU', collection);
        }
        if(this.name == "Import Skills"){
          this.$emit('importSkills', collection);
        }
        if(this.name == "Import Users"){
          this.$emit('importUsers', collection);
        }
      }
    },
  },
};
</script>
<style scoped>
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  padding: 8px 16px;
  background: rgb(0, 188, 235);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-name {
  position: absolute;
  bottom: -35px;
  left: 10px;

  color: #555;
}

input:hover + label,
input:focus + label {
  background: #3ba4e0;
}

/* Adding an outline to the label on focus */
input:focus + label {
 
}
</style>