<template>
  <!-- :class="{
      'bg-blue-500': user.email === 'kunaldfsdaf@aspenify.com',
      'bg-white': user.email === 'nihil@aspenify.com',
    }" -->
  <section class="text-gray-600 body-font">
    <div
      v-for="(comment, index) in showOnlyReleventComments"
      :key="index"
      class="
        container
        mx-auto
        mb-2
        bg-white
        rounded
        shadow
        border border-gray-100
        grid
        break-word-all
      "
      :class="{
        'bg-blue-100': comment.isPrivateMessage,
      }"
    >
      <!-- <pre>{{comment?.notes?.accountUserId}}</pre> -->
      <div
        class="float-left w-full text-sm p-4 pb-1 rounded place-items-center"
      >
        <div class="float-left pr-2">
          <div class="">
            <img
              class="w-8 h-8 rounded-full cursor-pointer"
              :src="comment?.notes?.accountUserId?.profilePic"
              @click="goToProfile(comment?.notes?.accountUserId)"
            />
          </div>
        </div>
        <div class="float-left">
          <div
            class="text-base font-semibold leading-4"
            @click="goToProfile(comment?.notes?.accountUserId)"
          >
            {{
              comment?.notes?.accountUserId?.firstName +
              " " +
              comment?.notes?.accountUserId?.lastName
            }}
          </div>
        </div>

        <div class="float-right mt-2 ml-3" v-if="!showAction">
          <div class="relative inline-block text-left dropdown">
            <span class="rounded-md hover:shadow-sm">
              <button
                v-if="
                  isWso ||
                  comment?.notes?.accountUserId?._id === user?.aspenifyUserId && !executiveAccess
                "
                type="button"
                aria-haspopup="true"
                aria-expanded="true"
                @click="menuId(index)"
              >
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                  />
                </svg>
              </button>
            </span>
            <div
              v-if="menuIndex === index"
              class="
                opacity-0
                invisible
                dropdown-menu
                transition-all
                duration-300
                transform
                origin-top-right
                -translate-y-2
                scale-95
                relative
                z-20
              "
            >
              <div
                class="
                  absolute
                  right-0
                  w-48
                  p-2
                  mt-2
                  origin-top-right
                  bg-white
                  border border-gray-200
                  divide-y divide-gray-100
                  rounded-md
                  shadow-lg
                  outline-none
                "
                aria-labelledby="headlessui-menu-button-1"
                role="menu"
              >
                <button
                  @click="editComment(comment)"
                  href="javascript:void(0)"
                  :class="dotMenuButtonCss"
                  v-if="
                    comment?.notes?.accountUserId?._id === user?.aspenifyUserId
                  "
                >
                  Edit
                </button>
                <button
                  @click="deleteComment(comment)"
                  href="javascript:void(0)"
                  :class="dotMenuButtonCss"
                  v-if="
                    isWso ||
                    comment?.notes?.accountUserId?._id === user?.aspenifyUserId
                  "
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="float-right mt-2">
          {{ timeago.format(new Date(comment?.notes?.notedate)) }}
        </div>
      </div>

      <div class="max-w-4xl px-10 bg-white rounded-lg shadow-md">
        <div class="inline-block p-4 pt-0 break-word-all">
          {{ comment.notes.content }}
        </div>
      </div>
      <div>
        <div class="p-4" v-if="textreply === index">
          <textarea
            autofocus
            rows="6"
            v-model="message"
            name="elevatorPitch"
            id="elevatorPitch"
            class="
              bg-gray-200
              appearance-none
              border-2 border-gray-200
              rounded
              shadow-md
              w-full
              py-2
              px-2
              text-gray-700
              leading-tight
              focus:outline-none focus:bg-white focus:border-gray-500
              mb-2
            "
          />
          <div
            class="
              w-full
              text-right
              float-right
              items-center
              justify-between
              pt-2
              pb-4
            "
          >
            <button
              type="button"
              class="
                mr-3
                inline-flex
                justify-center
                hover:scale-110
                focus:outline-none
                px-4
                py-2
                rounded
                cursor-pointer
                hover:bg-gray-200
                bg-gray-100
                text-gray-700
                duration-200
                ease-in-out
                transition
                text-sm
              "
              @click="textareaReply(null)"
            >
              Cancel
            </button>

            <button
              class="
                inline-flex
                text-white
                bg-indigo-500
                border-0
                px-4
                py-2
                focus:outline-none
                hover:bg-indigo-600
                rounded
                text-sm
                disabled:opacity-30
              "
              :disabled="message?.length === 0"
              @click="updateReply(comment, $event, index)"
            >
              Save
            </button>
          </div>
        </div>
        <div
          v-else
          class="
            flex
            border-t border-gray-200
            w-full
            py-2
            px-4
            text-blue-700 text-sm
            place-items-center
          "
        >
          <div class="flex-1" v-if="!showAction && !executiveAccess">
            <button
              @click="textareaReply(comment, $event, index)"
              class="cursor-pointer hover:bg-blue-100 px-3 py-1 rounded"
            >
              Reply
            </button>
          </div>
          <div class="flex-1 text-right">
            <button
              @click="showReplys(index, comment.subComments)"
              class="cursor-pointer hover:bg-blue-100 px-3 py-1 rounded"
              v-if="comment.subComments?.length > 0"
            >
              {{
                showSubreplyIndex === index && showSubreply ? "Hide" : "Show"
              }}
              {{ comment?.subComments?.length }}
              reply
            </button>
          </div>
        </div>
      </div>
      <div v-if="showSubreplyIndex === index && showSubreply" >
        <subComments
          v-if="showSubComments"
          :subComments="allSubComments"
          @openCloseModal="openCloseNoteModal"
          @editDeleteNotes="editDeleteNotes"
          :noteObj="noteObj"
          :todo="todo"
          spacing=15
          :initiativeId="initiativeId"
          :user="user"
          @commentAdded="getAllComments"
        >
        </subComments>
      </div>
    </div>
  </section>
  <EditDeleteNote
    :openClose="openCloseNote"
    @openCloseModal="openCloseNoteModal"
    @editDeleteNotes="editDeleteNotes"
    :noteObj="noteObj"
    :todo="todo"
  >
  </EditDeleteNote>
</template>

<script>
import { defineComponent, ref } from "vue";
import * as timeago from "timeago.js";
import EditDeleteNote from "../../components/confirmationModal/EditDeleteNote.vue";
import subComments from "./SubComments.vue";
export default {
  props: {
    comments: Array,
    isParticipant: Boolean,
    user: Object,
    initiativeId: String,
    commentsFor: String,
    isWso: Boolean,
    isActionVisible: Boolean,
    // todo:''
  },
  data() {
    return {
      menuIndex: null,
      subMenuIndex: null,
      showSubreply: null,
      showSubreplyIndex: null,
      textreply: null,
      message: "",
      openCloseNote: false,
      timeago,
      noteObj: null,
      showSubComments: false,
      allSubComments: [],
    };
  },
  computed: {
    showAction() {
      return this.isActionVisible ? this.isActionVisible : false;
    },
    dotMenuButtonCss() {
      return "w-full text-left block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-30";
    },
    showOnlyReleventComments() {
      // debugger
      let allComments = this.comments;
      if (this.isWso && this.commentsFor !== "judgindDetails") {
        this.$emit("commentsCount", allComments);
        return allComments;
      } else if (this.commentsFor === "judgindDetails") {
        allComments = allComments.filter((singleRecord) => {
          return singleRecord.isPrivateMessage;
        });
        this.$emit("commentsCount", allComments);
        return allComments;
      } else if (this.commentsFor === "ideaDetails") {
        let messagesForloggedInUser = [];
        allComments.forEach((record) => {
          if (record.isPrivateMessage) {
            let userIsPartOfMessage = record.recipents.filter((recipient) => {
              return (
                recipient?.email?.toLowerCase() ===
                this.user.email?.toLowerCase()
              );
            });
            if (userIsPartOfMessage?.length > 0) {
              messagesForloggedInUser.push(record);
            }
          } else {
            messagesForloggedInUser.push(record);
          }
        });
        this.$emit("commentsCount", messagesForloggedInUser);
        return messagesForloggedInUser;
      }
      this.$emit("commentsCount", allComments);
      return allComments;
    },
    executiveAccess() {
      return this.user && this.user.roles.includes("executive");
    },
  },
  beforeUpdate: function () {},
  created: function () {
  },
  components: { EditDeleteNote, subComments },
  emits: ["commentAdded", "commentsCount"],
  methods: {
    menuId(index) {
      this.menuIndex = index;
    },
    subMenuId(index) {
      this.subMenuIndex = index;
    },
    showReplys(index, subComments) {
      this.showSubComments = true;
      this.allSubComments = subComments;
      if (this.showSubreplyIndex === index) {
        this.showSubreply = !this.showSubreply;
      } else {
        this.showSubreply = true;
      }
      this.showSubreplyIndex = index;
    },
    textareaReply(comment, event, index) {
      this.textreply = index;
    },
    async updateReply(comment, event, index) {
      //   return false;
      let payload = {
        user: this.user,
        initiativeId: this.initiativeId,
        comment: this.message,
        recipients: [],
        parentCommentId: comment._id,
      };
      await this.$store.dispatch("addComment", payload);
      await this.$emit("commentAdded", "");

      this.textreply = null;
      this.message = "";
      this.showReplys(index);
    },
    async deleteComment(comment) {
      await this.$store.dispatch("deleteComment", comment);
      await this.$emit("commentAdded", "");
    },
    openCloseNoteModal(value) {
      this.openCloseNote = value;
    },
    editComment(comment) {
      this.todo = "edit";
      let singleComment = comment;
      singleComment.content = comment.notes.content;
      this.noteObj = { ...singleComment };
      this.openCloseNoteModal(true);
    },
    editDeleteNotes:function () {
      this.$store.dispatch("updateComments", this.noteObj).then(()=>{
        this.$emit("commentAdded", "");
      })  
      this.openCloseNoteModal(false);
    },
    goToProfile(user) {
      var name = user?.email
        ?.toLowerCase()
        ?.toLowerCase()
        ?.substring(0, user?.email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?._id, userName: name },
      });
    },
    getAllComments(){
      this.$emit("commentAdded", "");
    }
  },
};
</script>

<style scoped></style>
