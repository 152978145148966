<template>
  <div id="scrollTop"></div>
  <div
    v-if="loadingFlag">
    <loader-view></loader-view>
  </div>
  <div v-else class="px-6 py-6">
    <!-- <div class="flex mb-4 w-full mx-auto">
      <a
        class="
          mr-1
          cursor-pointer
          text-indigo-500
          inline-flex
          items-left
          md:mb-2
          lg:mb-0
        "
        @click.prevent="backToList"
      >
        All Ideas
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mt-0.5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
      <span class="ml-1">New Submission</span>
    </div> -->
    <!-- <div class="cursor-pointer flex mb-4 w-full mx-auto">
      <a
        class="text-indigo-500 inline-flex items-left md:mb-2 lg:mb-0"
        @click.prevent="backToList"
      >
        <i class="mdi mdi-arrow-left mr-2"></i>
         All Ideas
      </a>
    </div> -->
    <div class="px-4 py-5 bg-gray-100">
      <div class="flex flex-wrap w-full mb-20">
        <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
          <h1
            class="sm:text-xl text-lg font-medium title-font mb-2 text-gray-900"
          >
            New Submission
          </h1>
          <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
      </div>
      <div class="flex ml-5 mr-5">
        <div class="w-1/3 text-center px-10">
          <div
            class="rounded-lg flex items-center justify-center"
            :class="{
              'border-2': currentStep == 1,
              'border-indigo-500': currentStep == 1,
              'bg-indigo-300': currentStep == 1,
              'bg-green-400': currentStep > 1,
            }"
          >
            <div
              class="
                w-1/3
                bg-transparent
                h-20
                flex
                items-center
                justify-center
                icon-step
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 512 512"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M181.173,256c-41.28,0-74.667,33.387-74.667,74.667s33.387,74.667,74.667,74.667c41.28,0,74.667-33.387,74.667-74.667
				C255.84,289.493,222.453,256.107,181.173,256z M181.173,384c-29.44,0-53.333-23.893-53.333-53.333s23.893-53.333,53.333-53.333
				s53.333,23.893,53.333,53.333S210.613,384,181.173,384z"
                      />
                      <path
                        d="M406.453,64c-24.533-0.107-44.373,20.693-42.667,45.547c1.387,21.013,18.453,38.08,39.467,39.68
				c24.96,1.813,45.76-17.92,45.76-42.453v-0.107C449.013,83.2,430.027,64.107,406.453,64z M427.787,106.667
				c-0.107,9.28-6.08,17.813-14.933,20.373c-14.72,4.373-28.053-6.72-27.733-20.907c0.213-10.987,9.067-20.16,20.053-20.8
				c12.373-0.747,22.613,8.96,22.613,21.227V106.667z"
                      />
                      <path
                        d="M361.867,285.013c0.747-2.773,0.32-5.653-1.067-8.107l-42.987-73.92c-2.987-5.013-9.387-6.827-14.507-3.947
				l-27.413,15.68c-12.16-10.453-26.133-18.56-41.173-23.893v-30.933c0-5.867-4.8-10.667-10.667-10.667H138.72
				c-5.867,0-10.667,4.8-10.667,10.667v30.933c-15.147,5.333-29.12,13.44-41.28,23.893l-27.52-15.68
				c-5.12-2.88-11.52-1.173-14.507,3.947l-43.093,73.92c-2.987,5.12-1.28,11.627,3.84,14.613L32.8,307.2
				c-2.56,15.573-2.56,31.467,0,47.04L5.493,369.813c-5.12,2.88-6.827,9.493-3.947,14.613c0,0,0,0,0,0.107l42.987,73.92
				c2.987,5.013,9.387,6.827,14.507,3.947l27.627-15.787c13.547,11.093,26.453,18.56,41.28,23.787v30.933
				c0,5.867,4.8,10.667,10.667,10.667h85.333c5.867,0,10.667-4.8,10.667-10.667V470.4c15.04-5.333,29.013-13.44,41.173-23.893
				l27.413,15.68c5.12,2.88,11.52,1.173,14.507-3.947l42.987-73.92c2.987-5.12,1.28-11.627-3.84-14.613l-27.2-15.573
				c2.56-15.573,2.56-31.36,0-46.933l27.2-15.573C359.307,290.133,361.12,287.787,361.867,285.013z M312.373,292.48
				c-4.053,2.347-6.08,6.933-5.12,11.52c3.84,17.6,3.84,35.947,0,53.547c-0.96,4.48,1.067,9.173,5.12,11.52L336.8,383.04
				l-32.32,55.467l-24.747-14.187c-4.053-2.24-9.067-1.707-12.48,1.387c-15.787,14.08-29.227,21.867-46.4,26.773
				c-4.587,1.28-7.787,5.44-7.68,10.24v27.947h-65.067v-28.053c0.96-4.8-2.24-8.96-6.827-10.24
				c-17.387-4.907-30.827-12.693-46.507-26.773c-3.413-3.093-8.427-3.627-12.373-1.387L57.547,438.4l-32.213-55.467l24.533-13.973
				c4.053-2.347,6.08-6.933,5.12-11.52c-3.84-17.6-3.84-35.84,0-53.547c0.96-4.48-1.067-9.173-5.12-11.52L25.333,278.4l32.32-55.467
				l24.853,14.187c3.947,2.24,8.96,1.707,12.373-1.387c15.68-14.08,29.12-21.76,46.507-26.773c4.587-1.28,7.787-5.547,7.893-10.24
				v-28.053h62.933v28.16c0.96,4.8,4.053,8.96,8.64,10.24c17.28,4.907,30.72,12.693,46.4,26.773
				c3.413,3.093,8.427,3.627,12.373,1.28l24.853-14.187l32.213,55.467L312.373,292.48z"
                      />
                      <path
                        d="M506.293,126.933l-14.613-8.427c0.64-3.947,0.96-7.893,0.96-11.84s-0.32-7.893-0.96-11.84l14.827-8.427
				c5.12-2.88,6.827-9.493,3.947-14.507c0,0,0,0,0-0.107l-21.547-37.013c-2.88-5.013-9.28-6.827-14.4-3.947l-15.893,8.747
				c-6.613-4.907-13.867-8.853-21.44-11.947v-16.96C437.067,4.8,426.4,0,426.4,0h-42.667c0,0-10.667,4.8-11.627,10.667v17.067
				c-6.187,2.987-12.907,6.933-18.987,11.733l-14.507-8.533c-5.12-2.987-11.627-1.28-14.613,3.84l-21.333,37.013
				c-2.987,5.12-1.173,11.627,3.947,14.613c0,0,0,0,0.107,0l14.827,8.533c-0.533,3.84-0.853,7.787-0.853,11.733
				s0.32,7.893,0.853,11.84l-14.827,8.427c-5.12,2.88-6.827,9.493-3.947,14.507c0,0,0,0,0,0.107l21.44,37.013
				c1.387,2.453,3.733,4.267,6.507,4.907c2.773,0.747,5.653,0.32,8.107-1.173l14.187-8.427c6.08,4.8,12.8,8.747,20.053,11.733
				v17.067c-0.107,5.867,10.56,10.56,10.56,10.56h42.667c0,0,10.667-4.693,9.707-10.56V185.6c8.64-3.093,15.787-7.04,22.4-11.947
				l15.467,8.64c5.013,2.88,11.52,1.173,14.4-3.84l21.653-37.013C513.12,136.533,511.413,129.92,506.293,126.933z M474.293,80.107
				c-3.947,2.347-5.973,7.147-4.907,11.733c2.453,9.707,2.347,19.84-0.107,29.547c-1.067,4.587,0.96,9.387,5.013,11.733
				l11.947,6.827l-10.987,18.667l-12.693-7.147c-4.053-2.24-9.173-1.707-12.587,1.493c-6.507,6.08-16.533,11.733-26.88,14.933
				c-4.48,1.387-7.467,5.547-7.36,10.133V192h-22.4v-13.76c0.96-4.693-2.027-8.747-6.507-10.133
				c-9.493-3.093-18.133-8.107-25.387-14.933c-3.52-3.307-8.747-3.84-12.8-1.387l-11.413,6.827l-10.667-18.347l12.053-6.933
				c4.16-2.347,6.187-7.147,5.12-11.733c-1.173-4.8-1.813-9.813-1.813-14.827c0-5.013,0.64-9.92,1.707-14.827
				c1.067-4.587-0.96-9.387-5.12-11.733l-12.16-6.933l10.56-18.453l11.84,6.933c4.053,2.347,9.28,1.813,12.693-1.493
				c7.253-6.827,15.893-11.84,25.387-14.933c4.48-1.387,7.467-5.547,7.573-10.133V21.333h20.267v13.76
				c0.96,4.693,4.053,8.747,8.427,10.133c10.453,3.307,20.48,8.96,26.88,14.933c3.413,3.2,8.427,3.84,12.48,1.6l13.013-7.253
				l10.88,18.667L474.293,80.107z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="
                w-2/3
                bg-gray-200
                h-24
                flex flex-col
                items-center
                justify-center
                px-1
                rounded-r-lg
                body-step
              "
            >
              <h2 class="font-bold text-base">Project Overview</h2>
              <p class="text-sm text-gray-600">Details about your submission</p>
            </div>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"
            />
          </svg>
        </div>
        <div class="w-1/3 text-center px-6">
          <div
            class="rounded-lg flex items-center justify-center"
            :class="{
              'border-2': currentStep == 2,
              'border-indigo-500': currentStep == 2,
              'bg-indigo-300': currentStep == 2,
              'bg-gray-300': currentStep <= 2,
              'bg-green-400': currentStep > 2,
            }"
          >
            <div
              class="
                w-1/3
                bg-transparent
                h-20
                flex
                items-center
                justify-center
                icon-step
              "
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path
                  d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z"
                />
              </svg>
            </div>
            <div
              class="
                w-2/3
                bg-gray-200
                h-24
                flex flex-col
                items-center
                justify-center
                px-1
                rounded-r-lg
                body-step
              "
            >
              <h2 class="font-bold text-base">Team</h2>
              <p class="text-sm text-gray-600">Tell us about your team</p>
            </div>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"
            />
          </svg>
        </div>
        <div class="w-1/3 text-center px-6">
          <div
            class="rounded-lg flex items-center justify-center"
            :class="{
              'border-2': currentStep == 3,
              'border-indigo-500': currentStep == 3,
              'bg-indigo-300': currentStep == 3,
              'bg-gray-300': currentStep <= 3,
              'bg-green-300': currentStep > 3,
            }"
          >
            <div
              class="
                w-1/3
                bg-transparent
                h-20
                flex
                items-center
                justify-center
                icon-step
              "
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path
                  d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"
                />
              </svg>
            </div>
            <div
              class="
                w-2/3
                bg-gray-200
                h-24
                flex flex-col
                items-center
                justify-center
                px-1
                rounded-r-lg
                body-step
              "
            >
              <h2 class="font-bold text-base">Confirmation</h2>
              <p class="text-sm text-gray-600">
                Submit your nomination for review
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentStep == 1">
        <div
          v-if="validationErros"
          class="bg-red-50 border-l-8 border-red-900 mt-10 ml-24 mr-24"
        >
          <div class="flex items-center">
            <div class="p-2">
              <div class="flex items-center">
                <div class="ml-2">
                  <svg
                    class="h-8 w-8 text-red-900 mr-2 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p class="px-6 py-4 text-red-900 font-semibold text-lg">
                  Please provide required information.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- page 1 -->
        <section class="container mx-auto mt-8 p-10 px-32 relative">
          <!-- Idea Title-->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">
                Submission Title <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500">
                Try to make it self-explanatory and concise​. <br />
                <!-- e.g. "Edge Data Accelerator" -->
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <!-- <label class="block text-gray-700 text-base font-bold mb-2"
                >Idea Title<span class="text-red-500">&nbsp;*</span></label
              > -->
              <input
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  mb-1
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="title"
                placeholder=""
                v-model.trim="formData.name"
                @change="checkDuplicateName(formData.name)"
                maxlength="100"
                type="text"
              />
              <text-area-error
                :value="formData.name"
                :validationErros="validationErros"
                validate="name"
                :count="99"
                :nextButtonClicked="nextButtonClicked"
              />
              <span class="text-left float-left text-sm text-red-500">{{ submissionTitleErrorMsg }}</span>
            </div>
          </div>

          <hr class="border border-gray-200 my-10" />

          <!-- Idea picture-->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">Thumbnail</h3>
              <p class="text-sm text-gray-500">
                Select a picture to represent your submission
              </p>
            </div>

            <div class="w-1/3 pr-10">
              <div class="items-center">
                <div
                  class="
                    w-100
                    h-50
                    mb-5
                    relative
                    rounded-lg
                    bg-indigo-200
                    mt-0
                    shadow-inset
                  "
                >
                  <img
                    v-if="this.formData.cloudinaryImageUrl"
                    id="image"
                    class="object-contain w-full h-25 rounded"
                    :src="this.formData.cloudinaryImageUrl"
                  />
                  <img
                    v-else
                    id="image"
                    class="object-contain w-full h-25 rounded"
                    :src="customImage"
                  />

                  <!-- <div v-if="isError" class="p-2">
                    {{ errorText }}
                  </div> -->
                </div>

                <input
                  type="file"
                  id="upload"
                  ref="file"
                  v-on:change="onAddFiles()"
                  hidden
                  accept="image/x-png,image/gif,image/jpeg,image/png"
                />
                <label
                  class="
                    cursor-pointer
                    inine-flex
                    justify-between
                    items-center
                    focus:outline-none
                    border
                    py-2
                    px-4
                    rounded-lg
                    shadow-sm
                    text-left text-gray-600
                    bg-white
                    hover:bg-gray-100
                    font-medium
                  "
                  for="upload"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                      stroke="none"
                    ></rect>
                    <path
                      d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"
                    />
                    <circle cx="12" cy="13" r="3" />
                  </svg>
                  Upload Photo
                  <span class="loader" v-if="imageUploading"></span>
                </label>

                <div class="w-100 text-gray-500 text-xs mt-5">
                  Click to add a picture for your idea (e.g. .png, .jpg or .jpeg
                  format only)
                </div>
                <div>
                  <p
                    v-if="
                      validationErros && validationErros['cloudinaryImageUrl']
                    "
                    class="w-100 mt-2 text-sm text-red-600"
                  >
                    {{ validationErros["cloudinaryImageUrl"][0] }}
                  </p>
                  <p class="w-100 mt-2 text-sm text-red-600">
                    {{ this.errorText }}
                  </p>
                </div>
              </div>
            </div>

            <div class="w-1/3"></div>
          </div>

          <hr class="border border-gray-200 my-10" />

          <!-- Categories -->
          <div class="flex justify-center relative">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  <set-title
                    v-if="playbook"
                    :metaDataSchema="playbook.metaDataSchema"
                    name="challengeType"
                    defaultText="Category"
                  />
                  <span class="text-red-500">&nbsp;*</span>
                </div>
                <div class="text-gray-700 z-50">
                  <tooltip>
                    <div>
                      <ul
                        role="list"
                        class="space-y-2 py-2 m-auto text-gray-600"
                      >
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span
                            ><b>Strategic Impact</b> - Innovation that has enabled Cisco to
                        move into new markets​</span
                          >
                        </li>
                        <li class="space-x-2"></li>
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span><b>Market Impact</b> - Innovation that has transformed the
                        markets where Cisco currently plays​​</span>
                        </li>
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span
                            ><b>Velocity Impact</b> - Innovation that has allowed Cisco
                        (internal) or our customers (external) to move faster
                        and get value sooner</span
                          >
                        </li>
                      </ul>
                    </div>
                  </tooltip>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  description="challengeType"
                  defaultText="Pick one Category that aligns best with your submission."
                />
              </p>
            </div>
            <div class="w-2/3 pr-16">
              <Multiselect
                class="
                  block
                  text-gray-700 text-base
                  font-normal
                  mb-2
                  z-40
                  multi-tag
                "
                mode="single"
                trackBy="label"
                label="label"
                v-model="formData.metaData.challengeType"
                placeholder="Select Category"
                :options="challengeTypes"
              ></Multiselect>
              <div
                v-if="
                  validationErros && validationErros['metaData.challengeType']
                "
              >
                <p class="mt-2 text-sm text-red-600">
                  {{ validationErros["metaData.challengeType"][0] }}
                </p>
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 my-10" />

           <!-- core team -->
           <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">
                Core Team Size <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500">
                Indicate the size of your core team that worked on this project
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <!-- <label class="block text-gray-700 text-base font-bold mb-2"
                >Idea Title<span class="text-red-500">&nbsp;*</span></label
              > -->
              <input
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  mb-1
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="title"
                placeholder="Enter the number of core team members"
                v-model="formData.metaData.coreTeamSize"
                type="Number"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 187"
                :min="1"
              />
              <P v-if="coreTeamErrorMsg" class="text-red-500 text-sm">{{ "Core Team Size  is required" }}</P>
             
            </div>
          </div>
          <hr class="border border-gray-200 my-10" />
           <!-- Tags -->
          <div class="flex justify-center relative zIndex-10">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  Tags
                  <span class="text-red-500">&nbsp;*</span>
                </div>
                <div class="text-gray-700 z-50">
                  <tooltip>
                    <div>
                      Examples: AI/ML, Applications, AR/VR, Edge, Networking, Routing, SaaS, Switching
                    </div>
                  </tooltip>
                </div>
              </div>


              <p class="text-sm text-gray-500">
                Add tags to further categorize your idea.
              </p>
            </div>
            <div class="w-2/3 pr-16">
              <span class="text-xs text-gray-500">After typing a tag below, press “Enter” to save the tag.</span>
              <Multiselect
                class="
                  block
                  text-gray-700 text-base
                  font-normal
                  mb-2
                  multi-tag
                  tagCustomInput
                "
                mode="tags"
                :createTag="true"
                :searchable="true"
                trackBy="label"
                label="label"
                v-model="formData.tags"
                placeholder="Type your tag and press Enter"
                :options="formData.tags"
                @search-change="tagCheck"
              ></Multiselect>
              <!-- @keydown.space.prevent -->
              <div>
                <div v-if="validationErros && validationErros['tags']">
                  <p class="mt-2 text-sm text-red-600">
                    {{ validationErros["tags"][0] }}
                  </p>
                </div>
                <p v-if="tagAvaliable" class="mt-2 text-sm text-red-600">
                  Tag already exists.
                </p>
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 my-10" />

          <!-- Problem Statemet -->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  Problem Statement
                  <span class="text-red-500">&nbsp;*</span>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                Describe the problem your nomination aims to solve. This should
                include the prior state of the industry and/or Cisco before
                development and deployment of the nomination. Why is the problem
                so incredibly annoying and painful?
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <div>
              <!-- <textarea
                name=""
                rows="10"
                maxlength="3000"
                v-model.trim="formData.description"
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="inline-full-name"
              >
              </textarea> -->
              <AppTextEditor
                  class="pb-1" 
                  :content="formData.description"
                  @update-content="updateContent"
                  :maxLimit = 3000
                  :minLimit=1000
                  placeholder="description"
                  label="description"
                />
              <text-area-error
                :value="formData.description"
                :validationErros="validationErros"
                validate="description"
                :count="2999"
                :nextButtonClicked="nextButtonClicked"
                label="description"
                />
            </div>
          </div>
          </div>

          <hr class="border border-gray-200 my-10" />

          <!-- Value Proposition -->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  <set-title
                    v-if="playbook"
                    :metaDataSchema="playbook.metaDataSchema"
                    name="problemDescription"
                    defaultText="Value Proposition & Top 3 Innovations"
                  />
                  <span class="text-red-500">&nbsp;*</span>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  description="problemDescription"
                  defaultText="Value Proposition & Top 3 Innovations"
                />
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <div>
              <!-- <textarea
                name=""
                rows="10"
                maxlength="3000"
                v-model.trim="formData.metaData.problemDescription"
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="inline-full-name"
              >
              </textarea> -->
              <AppTextEditor
                  class="pb-1" 
                  :content="formData.metaData.problemDescription"
                  @update-content="updateContent"
                  :maxLimit = 3000
                  :minLimit=1000
                  placeholder="problemDescription"
                  label="problemDescription"
                />
              <text-area-error
                :value="formData.metaData.problemDescription"
                :validationErros="validationErros"
                validate="metaData.problemDescription"
                :count="2999"
                label="problemDescription"
                :nextButtonClicked="nextButtonClicked"
              />
            </div>
              <div v-if="!getProblemDescriptionImage">
                <submission-attachment
                  class="pt-4"
                  metaField="problemDescription"
                  :accessToken="accessToken"
                  :attachments="this.formData.attachments"
                  @fileUploaded="fileUploaded"
                />
              </div>
              <div v-if="getProblemDescriptionImage" class="mt-6">
                <div class="flex bg-white flex-col rounded-lg py-1 px-3">
                  <div class="w-full flex p-1">
                    <div class="mr-3 relative flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="flex-grow overflow-hidden">
                      <div
                        class="font-semibold text-sm text-gray-700 break-all"
                      >
                        {{
                          getProblemDescriptionImage.documentName.split(
                            ".problemDescription"
                          )[0]
                        }}
                      </div>
                    </div>
                    <div class="">
                      <div
                        @click="
                          deleteAttachmentById(
                            getProblemDescriptionImage.documentId
                          )
                        "
                        class="cursor-pointer blue-400 text-blue-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash-2 hover:opacity-50"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path
                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                          ></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 mt-10 mb-10" />

          <!-- Results -->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  <set-title
                    v-if="playbook"
                    :metaDataSchema="playbook.metaDataSchema"
                    name="potentialMarket"
                    defaultText="Potential First Market"
                  />
                  <span class="text-red-500">&nbsp;*</span>
                </div>
                <div class="text-gray-700">
                  <tooltip>
                    <set-title
                      v-if="playbook"
                      :metaDataSchema="playbook.metaDataSchema"
                      description="potentialMarket"
                      defaultText="What makes your solution unique and differentiated?"
                  /></tooltip>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                Describe the results and benefits of the submission including
                when it was introduced to market, business model, growth in
                adoption, financial and technical benefits.
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <div>
              <AppTextEditor
                  class="pb-1" 
                  :content="formData.metaData.potentialMarket"
                  @update-content="updateContent"
                  :maxLimit = 3000
                  :minLimit=1000
                  placeholder="potentialMarket"
                  label="potentialMarket"
                />
              <!-- <textarea
                name=""
                rows="10"
                maxlength="3000"
                v-model.trim="formData.metaData.potentialMarket"
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="inline-full-name"
              >
              </textarea> -->
              <text-area-error
                :value="formData.metaData.potentialMarket"
                :validationErros="validationErros"
                validate="metaData.potentialMarket"
                :count="2999"
                label="potentialMarket"
                :nextButtonClicked="nextButtonClicked"
              />
            </div>
              <div v-if="!getPotentialMarketImage">
                <submission-attachment
                  class="pt-4"
                  metaField="potentialMarket"
                  :accessToken="accessToken"
                  :attachments="this.formData.attachments"
                  @fileUploaded="fileUploaded"
                />
              </div>
              <div v-if="getPotentialMarketImage" class="">
                <div class="flex bg-white flex-col rounded-lg py-1 px-3">
                  <div class="w-full flex p-1">
                    <div class="mr-3 relative flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="flex-grow overflow-hidden">
                      <div
                        class="font-semibold text-sm text-gray-700 break-all"
                      >
                        {{
                          getPotentialMarketImage.documentName.split(
                            ".potentialMarket"
                          )[0]
                        }}
                      </div>
                    </div>
                    <div class="">
                      <div
                        @click="
                          deleteAttachmentById(
                            getPotentialMarketImage.documentId
                          )
                        "
                        class="cursor-pointer blue-400 text-blue-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash-2 hover:opacity-50"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path
                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                          ></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              </div>
            </div>

         <!-- FCS Date -->
      
        <div class=" flex mt-10">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">
                Launch/GA Date <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500">
                Indicate General Availability (GA) date of project. We recommend that projects be GA as of June 2023 or before, however projects GA for less time will be considered. 
              </p>
            </div>

            <div class="">
              <!-- <label class="block text-gray-700 text-base font-bold mb-2"
                >Idea Title<span class="text-red-500">&nbsp;*</span></label
              > -->
              <div class="">
                         <!-- <input
                            class="
                            bg-white
                            appearance-none
                            border border-gray-200
                            rounded
                            w-48
                            py-2
                            px-4
                            mb-1
                           text-gray-700
                            leading-tight
                           focus:outline-none focus:bg-white focus:border-gray-500
                          "
                          id="title"
                          placeholder=""
                          v-model.trim="formData.metaData.fcsDate"
                          type="date"
                          :max="today"
                          /> -->
              <v-date-picker v-model="formData.metaData.fcsDate" :max-date="today" no-time>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex relative border w-48 border-gray-200 bg-white">
                  <div class="relative">
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="MM/DD/YYYY"
                      class="
                        pl-8
                        pr-2
                        py-1
                        rounded
                        w-full
                        focus:outline-none
                        focus:bg-white focus:border-gray-500
                      "
                    />
                  </div>
                </div>
               </template>
              </v-date-picker>
                          <P v-if="fcsDateErrorMsg" class="text-red-500 text-sm">{{ "Launch/GA Date is Required" }}</P>
                    </div>
          </div>
        </div>
        <div class=" flex mt-8">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold ">
                Revenue to-date <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500 ">
                Enter the amount of revenue generated to-date
              </p>
            </div>

            <div class="">
              <div class="">
                         <input
                            class="
                            bg-white
                            appearance-none
                            border border-gray-200
                            rounded
                            w-48
                            py-2
                            px-4
                            mb-1
                           text-gray-700
                            leading-tight
                           focus:outline-none focus:bg-white focus:border-gray-500
                          "
                          id="title"
                          placeholder=""
                          v-model.trim="formData.metaData.revenueDate"
                          type="text"
                          @input="formatCurrency"
                          onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 32"
                          :min="1"
                          />
                          <p class="text-xs text-gray-500">(Enter Currency in USD)</p>
                          <p v-if="revenueDateErrorMsg" class="text-red-500 text-sm">{{ "Revenue to Date is Required" }}</p>
                    </div>
          </div>
        </div>
        <!-- <div class="flex mt-4">
            <div class="w-1/3">
              <h3 class="text-blue-900 text-base font-bold pr-15 ">
               {{`User Count/Activation    to-date`}} <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500  pr-16">
                Enter the user count towards this product to-date
              </p>
            </div>

            <div class=" mr-16">
              <div class="">
                         <input
                            class="
                            bg-white
                            appearance-none
                            border border-gray-200
                            rounded
                            w-48
                            py-2
                            px-4
                            mb-1
                           text-gray-700
                            leading-tight
                           focus:outline-none focus:bg-white focus:border-gray-500
                          "
                          id="title"
                          placeholder=""
                          v-model.trim="formData.metaData.activationDate"
                          type="number"
                          onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 187"
                          :min="1"
                          />
                          <P v-if="activationDateErrorMsg" class="text-red-500 text-sm">{{ "Count to Activation Date is Required" }}</P>
                    </div>
         
        </div>
      </div> -->
                   
                  
                  <hr class="border border-gray-200 my-10" />
          <!--Risks -->
          <div class="flex justify-center">
            <div class="w-1/3 pr-16">
              <div class="flex flex-row">
                <div class="text-blue-900 text-base font-bold">
                  <set-title
                    v-if="playbook"
                    :metaDataSchema="playbook.metaDataSchema"
                    name="elevatorPitch"
                    defaultText="Risk"
                  />
                  <span class="text-red-500">&nbsp;*</span>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  description="elevatorPitch"
                  defaultText="Explain what technical and business risks were identified."
                />
              </p>
            </div>

            <div class="w-2/3 pr-16 relative">
              <div>
              <!-- <textarea
                name=""
                rows="10"
                maxlength="1500"
                v-model.trim="formData.metaData.elevatorPitch"
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="inline-full-name"
              >
              </textarea> -->
              <AppTextEditor
                  class="pb-1" 
                  :content="formData.metaData.elevatorPitch"
                  @update-content="updateContent"
                  :maxLimit = 1500
                  placeholder="elevatorPitch"
                  label="elevatorPitch"
                />
              <text-area-error
                :value="formData.metaData.elevatorPitch"
                :validationErros="validationErros"
                validate="metaData.elevatorPitch"
                :count="1499"
                  label="elevatorPitch"
                :nextButtonClicked="nextButtonClicked"
              />
            </div>
            </div>
          </div>

          <hr class="border border-gray-200 my-16" />

          <!-- About You / Your Team -->
          <div class="flex justify-center z-20">
            <div class="w-1/3 pr-16">
              <div class="inline text-blue-900 text-base font-bold">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  name="aboutTeam"
                  defaultText="About You / Your Team"
                />
                <span class="text-red-500">&nbsp;*</span>
                <span class="inline-block mt-1 text-gray-700">
                  <tooltip>
                    <set-title
                      v-if="playbook"
                      :metaDataSchema="playbook.metaDataSchema"
                      description="aboutTeam"
                      defaultText="About You / Your Team"
                  /></tooltip>
                </span>
              </div>

              <p class="text-sm text-gray-500">
                Describe the team involved in the core innovation effort. Give
                examples of cross-functional partnerships. Explain usage of open
                source or other internal technology/developments to help team
                scale.
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <div>
                <!-- <textarea
                  name=""
                  rows="10"
                  maxlength="1500"
                  v-model.trim="formData.metaData.aboutTeam"
                  class="
                    bg-white
                    appearance-none
                    border border-gray-200
                    rounded
                    w-full
                    py-2
                    px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="inline-full-name"
                  required
                >
                </textarea> -->
                <AppTextEditor
                  class="pb-1" 
                  :content="formData.metaData.aboutTeam"
                  @update-content="updateContent"
                  :maxLimit = 1500
               placeholder="aboutTeam"
                  label="aboutTeam"
                />
                <text-area-error
                  :value="formData.metaData.aboutTeam"
                  :validationErros="validationErros"
                  validate="metaData.aboutTeam"
                  :count="1499"
                  label="aboutTeam"
                  :nextButtonClicked="nextButtonClicked"
                />
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 my-16" />

          <!-- testimonialAwardsIpAndOpenSource -->
          <div class="flex justify-center z-20">
            <div class="w-1/3 pr-16">
              <div class="inline text-blue-900 text-base font-bold">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  name="testimonialAwardsIpAndOpenSource"
                  defaultText="testimonialAwardsIpAndOpenSource"
                />
                <span class="text-red-500">&nbsp;*</span>
                <span class="inline-block mt-1 text-gray-700">
                  <tooltip>
                    <div>
                      <p>
                        Provide no more than three written testimonials from
                        applicable sources. Sources of testimonials should
                        include:​
                      </p>
                      <ul
                        role="list"
                        class="space-y-2 py-2 m-auto text-gray-600"
                      >
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span
                            >1x Cisco SVP from the team that developed the
                            project and/or</span
                          >
                        </li>
                        <li class="space-x-2"></li>
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span>1x Cisco DE/Fellow and/or</span>
                        </li>
                        <li class="space-x-2">
                          <span class="text-purple-500 font-semibold"
                            >&check;</span
                          >
                          <span
                            >1x customer (internal or external) of project</span
                          >
                        </li>
                      </ul>
                      <p>
                        Provide a list of awards won - types of awards can be
                        internal or external evaluations, product bake-offs,
                        conference awards, etc.​
                      </p>
                      <p class="space-y-2 py-2">
                        List any resulting standards, patents, copyrights,
                        licenses, or contributions to open-source projects.
                      </p>
                    </div>
                  </tooltip>
                </span>
              </div>
              <p class="text-sm text-gray-500">
                Provide up to three written testimonials, list of awards won and
                any standards, patents, copyrights, licenses, or contributions
                to open-source projects as a result of your submission.
              </p>
            </div>

            <div class="w-2/3 pr-16">
              <div>
              <!-- <textarea
                name=""
                rows="10"
                maxlength="1500"
                v-model.trim="
                  formData.metaData.testimonialAwardsIpAndOpenSource
                "
                class="
                  bg-white
                  appearance-none
                  border border-gray-200
                  rounded
                  w-full
                  py-2
                  px-4
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="inline-full-name"
                required
              >
              </textarea> -->
              <AppTextEditor
                  class="pb-1" 
                  :content="formData.metaData.testimonialAwardsIpAndOpenSource"
                  @update-content="updateContent"
                  :maxLimit = 1500
                 placeholder="testimonialAwardsIpAndOpenSource"
                  label="testimonialAwardsIpAndOpenSource"
                />
              <text-area-error
                :value="formData.metaData.testimonialAwardsIpAndOpenSource"
                :validationErros="validationErros"
                validate="metaData.testimonialAwardsIpAndOpenSource"
                :count="1499"
                :nextButtonClicked="nextButtonClicked"
                label="testimonialAwardsIpAndOpenSource"
              />
            </div>
              <div v-if="!getTestimonialAwardsIpAndOpenSourceImage">
                <submission-attachment
                  class="pt-4"
                  metaField="testimonialAwardsIpAndOpenSource"
                  :accessToken="accessToken"
                  :attachments="this.formData.attachments"
                  @fileUploaded="fileUploaded"
                />
              </div>
              <div v-if="getTestimonialAwardsIpAndOpenSourceImage" class="mt-6">
                <div class="flex bg-white flex-col rounded-lg py-1 px-3">
                  <div class="w-full flex p-1">
                    <div class="mr-3 relative flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="flex-grow overflow-hidden">
                      <div
                        class="font-semibold text-sm text-gray-700 break-all"
                      >
                        {{
                          getTestimonialAwardsIpAndOpenSourceImage.documentName.split(
                            ".testimonialAwardsIpAndOpenSource"
                          )[0]
                        }}
                      </div>
                    </div>
                    <div class="">
                      <div
                        @click="
                          deleteAttachmentById(
                            getTestimonialAwardsIpAndOpenSourceImage.documentId
                          )
                        "
                        class="cursor-pointer blue-400 text-blue-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash-2 hover:opacity-50"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path
                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                          ></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 mt-10 mb-10" />

          <!-- Buttons  -->
          <div class="flex justify-end">
            <div class="flex p-2 mt-4">
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    mr-10
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-indigo-600
                    transition
                  "
                  @click="nextStep"
                  :class="{ 'opacity-30 cursor-default': checkValid()}"
                  :disabled="checkValid()"
                >
                  Next
                </button>
                <button
                  @click="submitIdea({ saveAsDraft: true })"
                  :class="{ 'opacity-30 cursor-default': checkValid()}"
                  :disabled="checkValid()"
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    border
                    duration-200
                    ease-in-out
                    border-gray-600
                    transition
                  "
                >
                  Save As Draft
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="currentStep == 2">
        {{ validationError }}
        <div
          v-if="validationErros"
          class="bg-red-50 border-l-8 border-red-900 mt-10 ml-24 mr-24"
        >
          <div class="flex items-center">
            <div class="p-2">
              <div class="flex items-center">
                <div class="ml-2">
                  <svg
                    class="h-8 w-8 text-red-900 mr-2 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p class="px-6 py-4 text-red-900 font-semibold text-lg">
                  Please provide required information.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- page 2-->
        <section class="container mx-auto mt-10 px-24">
          <div class="flex justify-center z-20">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">
                Submission Point of Contact
              </h3>
              <p class="text-sm text-gray-500">
                Assign a Point of Contact. This person will be the main contact
                point for your idea.
              </p>
            </div>

            <div class="w-1/3 pr-16">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Search Point of Contact</label
              >
              <autocompletesingleselect
                :suggestions="usersByDisplayName"
                :selection.sync="value1"
                :selected.sync="formData.metaData.teamLead"
                :dropdownVisibleTo="dropdownVisibleTo"
                @displayNameFilterSearch="displayNameFilterSearch"
                @cleared="clearTeamLeadInput"
                @setActiveWindow="setActiveWindow"
                accessTo="lead"
                @enableSave="enableSave"
                openList="TeamLead"
                :loaderFlag="loaderFlag"
                @setLoadeFlag="setLoadeFlag"
                @setLoadingFlag="setLoadingFlag"
              >
              </autocompletesingleselect>
            </div>

            <div class="w-1/3 pr-16">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Selected Point of Contact</label
              >
              <div v-if="formData.metaData.teamLead.length > 0">
                <div class="flex flex-col max-w-sm mx-auto">
                  <ul class="-mx-4">
                    <li
                      class="flex mb-1"
                      v-for="(user, index) in formData.metaData.teamLead"
                      :key="index"
                      :title="user.name"
                    >
                      <user-card
                        :selectUser="user"
                        :index="index"
                        @removeUser="deleteTeamLead"
                      />
                      <!-- <div
                        class="
                          bg-white
                          w-full
                          flex
                          items-center
                          rounded-xl
                          px-2
                        "
                      >
                        <div
                          class="relative flex items-center space-x-4 contents"
                        >
                          <img
                            :src="user.image"
                            class="w-10 h-10 rounded-full"
                          />
                        </div>
                        <div class="flex-grow p-3">
                          <div class="font-semibold text-gray-700">
                            {{ user.name }}
                          </div>
                          <div class="text-xs italic text-gray-700">
                            {{ user.email }}
                          </div>
                        </div>
                        <div class="p-2">
                          <span
                            @click="deleteTeamLead(index)"
                            class="
                              cursor-pointer
                              block
                              h-4
                              w-4
                              ml-5
                              blue-400
                              fill-current
                              text-blue-600
                              bottom-0
                              right-0
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                              ></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </span>
                        </div>
                      </div> -->
                    </li>
                  </ul>
                </div>
              </div>
              <div class="-mx-4" v-else>
                <info-card message="No Point of Contact selected!" />
              </div>
            </div>
          </div>
          <hr class="border border-gray-200 my-10" />
          <div class="flex justify-center z-20">
            <div class="w-1/3 pr-16">
              <h3 class="text-blue-900 text-base font-bold">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  name="teamMembers"
                  defaultText="Your Team"
                />
              </h3>

              <p class="text-sm text-gray-500">
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  description="teamMembers"
                  defaultText="Assign up to four additional team members as back up point of contacts."
                />
              </p>
            </div>

            <div class="w-1/3 pr-16">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Search
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  name="teamMembers"
                  defaultText="Team Members"
                />
              </label>
              <autocomplete
                :suggestions="usersByDisplayName"
                :selected.sync="formData.metaData.teamMembers"
                @cleared="clearTeamMemberInput"
                @displayNameFilterSearch="displayNameFilterSearch"
                openList="member"
                :dropdownVisibleTo="dropdownVisibleTo"
                @setActiveWindow="setActiveWindow"
                :loaderFlag="loaderFlag"
                @setLoadeFlag="setLoadeFlag"
                @setLoadingFlag="setLoadingFlag"
              >
              </autocomplete>
            </div>

            <div class="w-1/3 pr-16">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Selected
                <set-title
                  v-if="playbook"
                  :metaDataSchema="playbook.metaDataSchema"
                  name="teamMembers"
                  defaultText="Team Members"
                />
              </label>
              <div v-if="formData.metaData.teamMembers.length > 0">
                <div class="flex flex-col max-w-sm mx-auto">
                  <ul class="-mx-4">
                    <li
                      class="flex mb-1"
                      v-for="(user, index) in formData.metaData.teamMembers"
                      :key="index"
                      :title="user.name"
                    >
                      <user-card
                        :selectUser="user"
                        :index="index"
                        @removeUser="deleteMember"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="-mx-4" v-else>
                <info-card message="No submission team members selected!" />
              </div>
            </div>
          </div>

          <hr class="border border-gray-200 my-10" />

          <div class="flex justify-end">
            <div class="flex p-2 mt-8">
              <button
                class="
                  text-base
                  hover:scale-110
                  focus:outline-none
                  flex
                  justify-center
                  px-4
                  py-2
                  rounded
                  font-bold
                  cursor-pointer
                  hover:bg-gray-200
                  bg-gray-100
                  text-gray-700
                  border
                  duration-200
                  ease-in-out
                  border-gray-600
                  transition
                "
                @click="previousStep"
              >
                Previous
              </button>
              <button
                @click="submitIdea({ saveAsDraft: true })"
                class="
                  text-base
                  ml-2
                  hover:scale-110
                  focus:outline-none
                  flex
                  justify-center
                  px-4
                  py-2
                  rounded
                  font-bold
                  cursor-pointer
                  hover:bg-gray-200
                  bg-gray-100
                  text-gray-700
                  border
                  duration-200
                  ease-in-out
                  border-gray-600
                  transition
                "
              >
                Save As Draft
              </button>
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    mr-10
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-600
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-indigo-600
                    transition
                  "
                  @click="nextStep"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="currentStep == 3">
        <section class="container mx-auto mt-10 p-10 px-32 py-10">
          <div class="flex justify-center">
            <div class="w-1/3 pl-16">
              <h3 class="text-blue-900 text-base font-bold">
                Acknowledgement <span class="text-red-500">&nbsp;*</span>
              </h3>
              <p class="text-sm text-gray-500">
                Please acknowledge Terms & Conditions
              </p>
            </div>

            <div class="w-2/3 pl-16">
              <div class="flex justify-start items-start mt-4">
                <div
                  class="
                    bg-white
                    border-2
                    rounded
                    border-gray-400
                    w-6
                    h-6
                    flex flex-shrink-0
                    justify-center
                    items-center
                    mr-2
                    focus-within:border-blue-500
                  "
                >
                  <input
                    v-model="origIdeaTermsCodition"
                    type="checkbox"
                    class="form-checkbox h-5 w-5"
                  />
                  <svg
                    class="
                      fill-current
                      hidden
                      w-4
                      h-4
                      text-green-500
                      pointer-events-none
                    "
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                </div>
                <div class="px-2 text-base text-gray-700 select-none">
                  <label>The submitting team certifies that the information provided is
                  accurate to the best of its knowledge.</label>
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <hr class="border border-gray-200 my-3" />

          <div class="flex justify-end">
            <div class="flex p-2 mt-8">
              <button
                class="
                  text-base
                  hover:scale-110
                  focus:outline-none
                  flex
                  justify-center
                  px-4
                  py-2
                  rounded
                  font-bold
                  cursor-pointer
                  hover:bg-gray-200
                  bg-gray-100
                  text-gray-700
                  border
                  duration-200
                  ease-in-out
                  border-gray-600
                  transition
                "
                @click="previousStep"
              >
                Previous
              </button>
              <button
                @click="submitIdea({ saveAsDraft: true })"
                class="
                  text-base
                  ml-2
                  hover:scale-110
                  focus:outline-none
                  flex
                  justify-center
                  px-4
                  py-2
                  rounded
                  font-bold
                  cursor-pointer
                  hover:bg-gray-200
                  bg-gray-100
                  text-gray-700
                  border
                  duration-200
                  ease-in-out
                  border-gray-600
                  transition
                "
              >
                Save As Draft
              </button>
              <div class="flex-auto flex flex-row-reverse">
                <button
                  @click="submitIdea"
                  :disabled="!origIdeaTermsCodition"
                  :class="
                    !origIdeaTermsCodition
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  "
                  class="
                    text-base
                    ml-2
                    mr-10
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-600
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-teal-600
                    transition
                  "
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <deleteAttachment
    :openClose="openClose"
    @openCloseModal="openCloseModal"
    @done="done"
    :todo="todo"
  >
  </deleteAttachment>
  <idea-submitted-info
    :openInfoModal="openInfoModal"
    @submittedInfoModalflag="submittedInfoModalflag"
    page="myIdea"
  >
  </idea-submitted-info>
  <!-- <loader-view v-if="isLoader" :loaderFlag="isLoader" loadingTitle=""> </loader-view> -->
</template>
<script>
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import * as Yup from "yup";
import feathersClient from "../services/feathersClient";
import { getRandomPhoto } from "../services/unsplash.js";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";

import SubmissionAttachment from "../components/SubmissionAttachment.vue";

import FileUpload from "../components/FileUpload.vue";
import InfoCard from "../components/InfoCard.vue";
import Tooltip from "../components/Tooltip.vue";
import TextAreaError from "../components/TextAreaError.vue";
import deleteAttachment from "../components/confirmationModal/DeleteAttachmentModal.vue";
import Checkbox from "../components/Checkbox.vue";
import { format } from "fecha";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import AddFiles from "../components/Modal/AddFiles.vue";

import DeleteIcon from "../components/Icon/DeleteIcon.vue";
import AttachmentIcon from "../components/Icon/AttachmentIcon.vue";

import users from "../services/users";
import mentors from "../services/mentors";
import objectId from "../helpers/objectId";
import SetTitle from "../components/SetTitle";
import IdeaSubmittedInfo from "../components/IdeaSubmittedInfo.vue";
import UserCard from "../components/UserCard";
import slugify from "../helpers/slugify";
import AppTextEditor from "../components/AppTextEditor.vue"
let pageOneSchema = Yup.object({
  name: Yup.string()
    .required("Submission title is required")
    .test("len", "Must be less than equal to 100 characters", function (val) {
      return val && val.length <= 101;
    })
    .test("name", "Submission title already exists.", function (val) {
      return this.options.context.dataFunction(val);
    }),
  description: Yup.string()
    .required("Description is required")
    .test(
      "len",
      "Minimum limit 1000 characters",
      (val) => {
        let text = val?.replace(/<[^>]+>/g, '')
      return text && text.length >= 1000
    }
    ),
  tags: Yup.array().min(1, "At least 1 tag is required"),
  metaData: Yup.object({
    challengeType: Yup.string().required(
      "Please select valid submission category"
    ),
    elevatorPitch: Yup.string()
      .required("Risk data is required"),
      // .test(
      //   "len",
      //   "Must be less than equal to 1500 characters",
      //   (val) => val && val.length <= 1500
      // ),
    aboutTeam: Yup.string()
      .required("Team data is required"),
      // .test(
      //   "len",
      //   "Must be less than equal to 1500 characters",
      //   (val) => val && val.length <= 1500
      // ),
    testimonialAwardsIpAndOpenSource: Yup.string()
      .required("Testimonials and awards information data is required"),
      // .test(
      //   "len",
      //   "Must be less than equal to 1500 characters",
      //   (val) => val && val.length <= 1500
      // ),
    problemDescription: Yup.string()
      .required("Value proposition is required")
      .test(
      "len",
      "Minimum limit 1000 characters",
      (val) => {
        let text = val?.replace(/<[^>]+>/g, '')
      return text && text.length >= 1000
    }
    ),
      coreTeamSize: Yup.number().min(1)
      .required("Team member  is required"),
      fcsDate: Yup.date()
      .required("Launch/GA Date is required"),
      // activationDate: Yup.number().min(1)
      // .required("count of activation date  is required"),
      revenueDate: Yup.string()
      .required("revenue to  date  is required"),
       potentialMarket: Yup.string()
      .required("Result information is required")
      .test(
      "len",
      "Minimum limit 1000 characters",
      (val) => {
        let text = val?.replace(/<[^>]+>/g, '')
      return text && text.length >= 1000
    }
    ),
  }),
});

// let pageTwoSchema = Yup.object({
//   metaData: Yup.object({
//     aboutTeam: Yup.string()
//       .required("About You / Your Team is Required")
//       .test(
//         "len",
//         "Must less than 2000 characters",
//         (val) => val && val.length < 2000
//       ),
//     progress: Yup.string()
//       .required("Progress is required")
//       .test(
//         "len",
//         "Must less than 2000 characters",
//         (val) => val && val.length < 2000
//       ),
//   }),
// });

let baseSchema = Yup.object({
  name: Yup.string()
    .required("Submission title is required")
    .test("len", "Must less than 100 characters", function (val) {
      return val && val.length < 101;
    })
    .test("name", "Submission title already exists.", function (val) {
      return this.options.context.dataFunction(val);
    }),
  metaData: Yup.object({
    challengeType: Yup.string().required(
      "Please select valid submission category"
    ),
  }),
});

// const PRACTICE_ID = process.env.VUE_APP_PRACTICE_ID;
const BASE_ID = process.env.VUE_APP_BASE_ID;

export default {
  data() {
    return {
      query: "mountain",
      dataLoading: false,
      inEdit: false,
      unsplashData: null,
      currentStep: 1,
      ventureTermsCondition: false,
      origIdeaTermsCodition: false,
      formData: {
        // practiceId: PRACTICE_ID,
        baseId: BASE_ID,
        attachments: [],
        tags: [],
        metaData: {
          teamMembers: [],
          mentors: [],
          teamLead: [],
          talent: [],
          isTalentRequired: false,
        },
      },
      users: this.usersByDisplayName,
      mentors: mentors,
      value: "",
      value1: "",
      value2: "",
      open: false,
      validationErros: null,
      current: 0,
      schema: pageOneSchema,
      isError: false,
      errorText: null,
      cloudinaryImageUrl: "",
      imageUploading: false,
      openClose: false,
      todo: null,
      index: null,
      openAddFile: false,
      fileObj: null,
      tagsList: [],
      tagAvaliable: false,
      openInfoModal: false,
      usersByDisplayName: [],
      dropdownVisibleTo: "",
      talentFlag: true,
      isLoader: false,
      loaderFlag: false,
      nextButtonClicked: false,
      coreTeamErrorMsg:false,
      fcsDateErrorMsg:false,
     // activationDateErrorMsg:false,
      revenueDateErrorMsg:false,
      today:'',
      submissionTitleErrorMsg: '',
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "initiatives",
      "selectedInitiative",
      "loadingFlag",
      "marketHorizons",
      "challengeTypes",
      "playbook",
      "msGraphAccessToken",
      "enableSubmission"
    ]),
    accessToken() {
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      return tokenString;
    },
    getProblemDescriptionImage(){
      if (this.formData && this.formData.attachments) {
        const image = this.formData.attachments.find((rec) =>
          rec.documentName.endsWith(".problemDescription")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getTestimonialAwardsIpAndOpenSourceImage() {
      if (this.formData && this.formData.attachments) {
        const image = this.formData.attachments.find((rec) =>
          rec.documentName.endsWith(".testimonialAwardsIpAndOpenSource")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getAboutTeamImage() {
      if (this.formData && this.formData.attachments) {
        const image = this.formData.attachments.find((rec) =>
          rec.documentName.endsWith(".aboutTeam")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getPotentialMarketImage() {
      if (this.formData && this.formData.attachments) {
        const image = this.formData.attachments.find((rec) =>
          rec.documentName.endsWith(".potentialMarket")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getElevatorPitchImage() {
      if (this.formData && this.formData.attachments) {
        const image = this.formData.attachments.find((rec) =>
          rec.documentName.endsWith(".elevatorPitch")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    HorizonList() {
      return this.marketHorizons;
    },
    customImage() {
      let randomNumber = Math.floor(Math.random() * 16) + 1;
      const picture =
        process.env.VUE_APP_IDEA_IMAGE + "/" + randomNumber + ".png";
      return picture;
    },
  },
  setup() {},
  created: function () {
  let todaydate = new Date();
  this.today=format(todaydate,"YYYY-MM-DD") 
   let coreTeamSize=(this.selectedInitiative?.metaData?.coreTeamSize);
   let fcsDate=(this.selectedInitiative?.metaData?.fcsDate);
   // let activationDate=(this.selectedInitiative?.metaData?.activationDate);
   let revenueDate=(this.selectedInitiative?.metaData?.revenueDate);
    let userPayload = {
      name: this.user.name + ' ' + (this.user?.lastName || ""),
      email: this.user.email?.toLowerCase(),
      image: this.user.profilePic,
      role: "TeamLead",
    };
    this.formData.metaData.teamLead.push(userPayload);
    this.$store.dispatch("getAccessToken", "");
    this.inEdit = false;
    if (this.$route.name === "EditIdea") {
      if (this.selectedInitiative == null) {
        this.$router.push({ name: "MyIdeas" });
      } else {
        //load initiative data
        this.$store.dispatch(
          "getInDraftInitiativeDetailsById",
          this.selectedInitiative.id
        );
        this.unwatch = this.$store.watch(
          (state, getters) => getters.selectedInitiative,
          (newValue) => {
            if (newValue != null) {
              const selectedInitiative = newValue;
              this.inEdit = true;

              const teamMebers = [];
              const loggedInUserName = this.user.name;
              if (selectedInitiative.teamMembers) {
                selectedInitiative.teamMembers.forEach(function (member) {
                  if (member.name != loggedInUserName) {
                    const user = {
                      name: member.name,
                      email: member.email?.toLowerCase(),
                      image: member.image,
                    };
                    teamMebers.push(user);
                  }
                });
              }

              this.formData.name = selectedInitiative.name;
              this.formData.tags = selectedInitiative.tags;
              this.formData.metaData.coreTeamSize = coreTeamSize;
              this.formData.metaData.fcsDate = fcsDate;
            //  this.formData.metaData.activationDate = activationDate;
              this.formData.metaData.revenueDate = revenueDate;
              this.formData.attachments = selectedInitiative.attachments;
              this.formData.metaData.challengeType = selectedInitiative.type;
              this.formData.metaData.marketHorizon =
                selectedInitiative.marketHorizon;
              this.formData.description = selectedInitiative.description;
              this.formData.cloudinaryImageUrl = selectedInitiative.image;
              this.formData.metaData.elevatorPitch =
                selectedInitiative.elevatorPitch;
              this.formData.metaData.problemDescription =
                selectedInitiative.problemDescription;
              this.formData.metaData.potentialMarket =
                selectedInitiative.potentialMarket;
              this.formData.metaData.testimonialAwardsIpAndOpenSource =
                selectedInitiative.testimonialAwardsIpAndOpenSource;
              this.formData.metaData.aboutTeam = selectedInitiative.aboutTeam;
              this.formData.metaData.talent = selectedInitiative.talent;
              this.formData.metaData.isTalentRequired =
                selectedInitiative.isTalentRequired;
              this.formData.metaData.progress = selectedInitiative.progress;
              this.formData.metaData.teamMembers = teamMebers;
              this.formData.metaData.mentors = selectedInitiative.mentors;
              this.formData.metaData.teamLead = selectedInitiative.teamLead;
              this.ventureTermsCondition =
                selectedInitiative.ventureTermsCondition;
              this.origIdeaTermsCodition =
                selectedInitiative.origIdeaTermsCodition;
            }
          }
        );
      }
    }
  },
  beforeUnmount() {
    if (this.unwatch) {
      this.unwatch();
    }
  },
  methods: {
    checkValid(){
      if(this.submissionTitleErrorMsg.length>0){
        return true;
      } else {
        if(!this.formData?.name || this.formData?.name?.trim().length==0){
          return true;
        } else {
          return false;
        }
      }
    },
    tagCheck(val) {
      let allTags = Object.values(this.formData.tags).filter((f) => f === val);
      if (allTags && allTags.length === 0) {
        this.tagAvaliable = false;
      } else {
        this.tagAvaliable = true;
      }
    },
    formatCurrency() {
      // Remove non-numeric characters except dot
      this.formData.metaData.revenueDate = this.formData.metaData.revenueDate.replace(/[^0-9.]/g, '')

      // Format as currency
      if (this.formData.metaData.revenueDate) {
        const numericValue = parseFloat(this.formData.metaData.revenueDate);
    if (!isNaN(numericValue)) {
      const formattedValue = numericValue.toLocaleString('en-US', { style: 'decimal' })
      this.formData.metaData.revenueDate = `$${formattedValue}`;
    }
      } 
    },
    clearTeamMemberInput() {
      this.formData.metaData.teamMembers = [
        ...this.formData.metaData.teamMembers,
      ];
    },
    updateContent(updatedValue,label){
      console.log(label);
      if(updatedValue==="<p></p>"){
        updatedValue=""
      }
      if(label !="description"){
        this.formData.metaData[`${label}`]=updatedValue
      }
  
      if(label=="description"){
        this.formData.description=updatedValue
      }
      
    },
    clearMentorsInput() {
      this.formData.metaData.mentors = [...this.formData.metaData.mentors];
    },
    clearTeamLeadInput() {
      this.formData.metaData.teamLead = [...this.formData.metaData.teamLead];
    },
    searchPhoto() {
      const param = {
        featured: true,
      };
      this.dataLoading = true;
      getRandomPhoto(param).then((res) => {
        this.unsplashData = res;
        this.dataLoading = false;
      });
    },
    addFilesModal: function (item) {
      this.fileObj = {
        documentName: "",
        documentUrl: "",
        documentId: objectId(),
      };
      this.todo = "add";
      this.openAddFileModal(true);
    },
    editFile: function (item) {
      this.fileObj = { ...item };
      this.todo = "edit";
      this.openAddFileModal(true);
    },
    formatYupErrors(validationError) {
      if (validationError.inner) {
        const acc = {}; 
        validationError.inner.forEach(function (err) {
          const path = err.path;
          if (!acc[path]) {
            acc[path] = [];
          }
          err.errors.forEach(function (errMsg) {
            acc[path].push(errMsg);
          });
        });
        return acc;
      }
    },
    postIdea: function () {
      this.currentStep = 1;
    },
    checkDuplicateName: async function (name) {
      let value = await this.checkInitiativeNameInDB(name);
      if(!value){
        this.submissionTitleErrorMsg = 'Submission title already exists.'
      } else {
        this.submissionTitleErrorMsg = ''
      }
    },
    checkInitiativeNameInDB: async function (val) {
        if (val) {
          val = slugify(val);
        }
        // const playbookId = process.env.VUE_APP_PRACTICE_ID;
        const baseId = process.env.VUE_APP_BASE_ID;
        const { apiKey, accessToken } = this;
        const initiatives = feathersClient.service("initiatives");

        const andCondition = [
          {
            baseId: baseId,
            slug: val,
          },
        ];

        const query = {
          $and: andCondition,
          $select: ["name"],
        };

        try {
          const tokenString = `Bearer ${accessToken}`;
          const result = await initiatives.find({
            query: query,
            headers: {
              "x-api-key": apiKey,
              "oauth-accesstoken": tokenString,
            },
          });

          if (result.data && result.data.length > 0) {
            return false;
          }

          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
    },
    validatePageOne: function (schemaObject) {
      const thisObject = this;
      const dataFunction = async function (val) {
        if (val) {
          val = slugify(val);
        }
        //There is not need to check for duplciate initiative name in edit phase
        if (thisObject.inEdit) {
          return true;
        }
        // const playbookId = process.env.VUE_APP_PRACTICE_ID;
        const baseId = process.env.VUE_APP_BASE_ID;
        const { apiKey, accessToken } = thisObject.user;
        const initiatives = feathersClient.service("initiatives");

        const andCondition = [
          {
            baseId: baseId,
            slug: val,
          },
        ];

        const query = {
          $and: andCondition,
          $select: ["name"],
        };

        try {
          const tokenString = `Bearer ${accessToken}`;
          const result = await initiatives.find({
            query: query,
            headers: {
              "x-api-key": apiKey,
              "oauth-accesstoken": tokenString,
            },
          });

          if (result.data && result.data.length > 0) {
            return false;
          }

          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      };
      return schemaObject.validate(this.formData, {
        abortEarly: false,
        context: { dataFunction },
      });
    },

    // validatePageTwo: function (schemaObject) {
    //   const dataFunction = async function (val) {
    //     try {
    //       return true;
    //     } catch (err) {
    //       console.log(err);
    //       return false;
    //     }
    //   };
    //   return schemaObject.validate(this.formData, {
    //     abortEarly: false,
    //     context: { dataFunction },
    //   });
    // },

    nextStep: async function () {
      await this.$store.dispatch('checkSubmissionEnableStatus');
      const numericAmount =this?.formData?.metaData?.revenueDate?.replace(/[$,]/g, '')
      console.log(numericAmount)
      if(this.enableSubmission){
        if(this?.formData?.metaData?.coreTeamSize){
          if(this?.formData?.metaData?.coreTeamSize <=0 ){
          this.coreTeamErrorMsg=true
         }else{
          this.coreTeamErrorMsg=false
         }  
        }else{
          this.coreTeamErrorMsg=true
        };
        if(this.formData.metaData.fcsDate){
          this.fcsDateErrorMsg=false
        }else{
          this.fcsDateErrorMsg=true
        };
        // if(this.formData.metaData.activationDate){
        //   if(this?.formData?.metaData?.activationDate <=0 ){
        //   this.activationDateErrorMsg=true
        //  }else{
        //   this.activationDateErrorMsg=false
        //  }
        // }else{
        //   this.activationDateErrorMsg=true
        // };
        if(this.formData.metaData.revenueDate){
          if(this.formData.metaData.revenueDate <=0){
            this.revenueDateErrorMsg=true
          }else{
            this.revenueDateErrorMsg=false
          }
        }else{
          this.revenueDateErrorMsg=true
        };
        this.formData?.metaData?.talent?.length > 0
          ? (this.talentFlag = true)
          : (this.talentFlag = false);
        if (this.currentStep == 1) {
          this.nextButtonClicked = true;
          this.validationErros = null;
          const thisObject = this;
          this.validatePageOne(pageOneSchema)
            .then(function () {
              thisObject.currentStep = thisObject.currentStep + 1;
              this.nextButtonClicked = false;
            })
            .catch(function (err) {
              thisObject.validationErros = thisObject.formatYupErrors(err);
              this.nextButtonClicked = false;
            });
        }
        //else if (this.currentStep == 2) {
        // this.validationErros = null;
        // const thisObject = this;
        // this.validatePageTwo(pageTwoSchema)
        //   .then(function () {
        //     thisObject.currentStep = thisObject.currentStep + 1;
        //   })
        //   .catch(function (err) {
        //     thisObject.validationErros = thisObject.formatYupErrors(err);
        //   });
        //}
        else {
          this.currentStep = this.currentStep + 1;
        }
          window.scrollTo(0, 0);
          setTimeout(() => {
          var elmnt = document.getElementById("scrollTop");
          elmnt.scrollIntoView();
        }, 20);
      } else {
        alert("Submissions are turned off");
      }
    },
    previousStep: function () {
      this.currentStep = this.currentStep - 1;
      setTimeout(() => {
        var elmnt = document.getElementById("scrollTop");
        elmnt.scrollIntoView();
      }, 20);
    },
    submitIdea: async function ({ saveAsDraft }) {
      function removeEmptyTags(inputString) {
        if(inputString){
          let outputString = inputString.replace(/<[^\/>][^>]*><\/[^>]*>/g, '');  
          return outputString;
        } else {
          return "";
        }
      }
      await this.$store.dispatch('checkSubmissionEnableStatus');
      if(this.enableSubmission){
        const { apiKey, accessToken } = this.user;
        let status = 0; //in design
        if (saveAsDraft) {
          status = -1;

          // if "Save As Draft"  on page 1 - just validate name and idea type
          if (this.currentStep == 1) {
            this.validationErros = null;
            try {
              // await this.validatePageOne(baseSchema);
              // await this.validateSaveAsDraft(baseSchema);
            } catch (err) {
              this.validationErros = this.formatYupErrors(err);
            }

            // if (this.validationErros) {
            //   return;
            // }
          }
        }
        const teamMembersArray = [...this.formData.metaData.teamMembers];
        teamMembersArray.map((member) => {
          member.role = "Team Member";
        });
        this.formData.metaData.mentors.map((mentor) => {
          mentor.role = "Mentor";
        });
        const currentUser = {
          name: this.user.name + ' ' + (this.user?.lastName || ""),
          email: this.user.email?.toLowerCase(),
          image: this.user.profilePic,
          // role: "Submitter",
        };
        // teamMembersArray.unshift(currentUser);
        let submittersArray = [];
        submittersArray.push(currentUser);
        if (this.formData.metaData.teamLead.length == 0) {
          this.formData.metaData.teamLead.push(currentUser);
        }
        let payload = {
          name: this.formData.name,
          description: removeEmptyTags(this.formData.description),
          tags: this.formData.tags,
          picture: this.formData.cloudinaryImageUrl
            ? this.formData.cloudinaryImageUrl
            : this.customImage,
          status: status,
          // practiceId: PRACTICE_ID,
          baseId: BASE_ID,
          attachments: this.formData.attachments,
          metaData: {
            source: "NA",
            bogFramework: "NA",
            priority: "NA",
            challengeType: this.formData.metaData.challengeType,
            marketHorizon: this.formData.metaData.marketHorizon,
            elevatorPitch: removeEmptyTags(this.formData.metaData.elevatorPitch),
            problemDescription: removeEmptyTags(this.formData.metaData.problemDescription),
            potentialMarket: removeEmptyTags(this.formData.metaData.potentialMarket),
            testimonialAwardsIpAndOpenSource:removeEmptyTags(this.formData.metaData.testimonialAwardsIpAndOpenSource),
            aboutTeam: removeEmptyTags(this.formData.metaData.aboutTeam),
            progress: this.formData.metaData.progress,
            teamMembers: teamMembersArray,
            mentors: this.formData.metaData.mentors,
            teamLead: this.formData.metaData.teamLead,
            submitter: submittersArray,
            ventureTermsCondition: this.ventureTermsCondition,
            origIdeaTermsCodition: this.origIdeaTermsCodition,
            isTalentRequired: this.formData.metaData.isTalentRequired,
            talent: this.formData.metaData.talent,
            coreTeamSize:this.formData?.metaData?.coreTeamSize,
            fcsDate:this.formData.metaData.fcsDate,
          //  activationDate:this.formData.metaData.activationDate,
            revenueDate:this.formData.metaData.revenueDate,
          },
        };
        // if (this.talentFlag) {
        //   payload.metaData.talent = this.formData?.metaData?.talent;
        // }
        let inEditIdea=true;
        if (this.inEdit && this.selectedInitiative) {
          const initiatives = feathersClient.service("initiatives");
          const tokenString = `Bearer ${accessToken}`;
          const query = {
            $select: ["metaData"],
          };
          let initiativeResult = await initiatives.get(
            this.selectedInitiative.id,
            {
              query: query,
              headers: {
                Authorization: tokenString,
              },
            }
          );
          delete payload.metaData.submitter;
          payload.metaData.submitter = initiativeResult.metaData.submitter;
          payload.initiativeId = this.selectedInitiative.id;
          if (this.selectedInitiative?.teamId) {
            payload.teamId = this.selectedInitiative.teamId;
          }
          payload.newIdea = true;
          payload.router = this.$router
          inEditIdea=false;
          await this.$store.dispatch("updateInitiative", payload);
        } else {
          this.$store.dispatch("createInitiative", payload);
        }
        if(inEditIdea){
        this.$router.push({ name: "MyIdeas" });
        }
        // if (status === 0) {
        //   this.openInfoModal = true;
        // } else {
        //   this.$router.push({ name: "MyIdeas" });
        // }

        setTimeout(() => {
          var elmnt = document.getElementById("scrollTop");
          elmnt.scrollIntoView();
        }, 20);
      } else {
        alert("Submissions are turned off");
      }
    },  
    createIdea: async function ({ saveAsDraft }) {
      let status = 0; //in design
      if (saveAsDraft) {
        status = -1;
      }
      const teamMembersArray = [...this.formData.metaData.teamMembers];
      teamMembersArray.map((member) => {
        member.role = "Team Member";
      });
      this.formData.metaData.mentors.map((mentor) => {
        mentor.role = "Mentor";
      });
      const currentUser = {
        name: this.user.name,
        email: this.user.email?.toLowerCase(),
        image: this.Submitter.profilePic,
        accountUserId:this.user._id
        // role: "Submitter",
      };
      // teamMembersArray.unshift(currentUser);
      let submittersArray = [];
      submittersArray.push(currentUser);
      if (this.formData.metaData.teamLead.length == 0) {
        this.formData.metaData.teamLead.push(currentUser);
      }
      const payload = {
        name: this.formData.name,
        description: this.formData.description,
        tags: this.formData.tags,
        picture: this.formData.cloudinaryImageUrl,
        status: status,
        // practiceId: BASE_ID,
        baseId: BASE_ID,
        attachments: this.formData.attachments,
        metaData: {
          challengeType: this.formData.metaData.challengeType,
          marketHorizon: this.formData.metaData.marketHorizon,
          elevatorPitch: this.formData.metaData.elevatorPitch,
          problemDescription: this.formData.metaData.problemDescription,
          coreTeamSize:this?.formData?.metaData?.coreTeamSize,
          fcsDate:this.formData.metaData.fcsDate,
          revenueDate:this.formData.metaData.revenueDate,
         // activationDate:this.formData.metaData.activationDate,
          potentialMarket: this.formData.metaData.potentialMarket,
          attachments: this.formData.metaData.attachments,
          teamMembers: teamMembersArray,
          submitter: submittersArray,
          mentors: this.formData.metaData.mentors,
          teamLead: this.formData.metaData.teamLead,
          ventureTermsCondition: this.ventureTermsCondition,
          origIdeaTermsCodition: this.origIdeaTermsCodition,
          isTalentRequired: this.talentFlag,
          talent: this.formData.metaData.talent,
        },
      };

      this.$store.dispatch("createInitiative", payload);
      this.$router.push({ name: "MyIdeas" });
      setTimeout(() => {
        var elmnt = document.getElementById("scrollTop");
        elmnt.scrollIntoView();
      }, 20);
    },
    fileUploaded: function (uploadedFiles) {
      console.log(uploadedFiles);
      this.formData.attachments = uploadedFiles;
    },
    deleteMentor: function (index) {
      this.formData.metaData.mentors = this.formData.metaData.mentors.filter(
        function (e, i) {
          return i != index;
        }
      );
    },
    deleteMember: function (index) {
      this.formData.metaData.teamMembers =
        this.formData.metaData.teamMembers.filter(function (e, i) {
          return i != index;
        });
    },
    deleteTeamLead: function (index) {
      this.formData.metaData.teamLead = this.formData.metaData.teamLead.filter(
        function (e, i) {
          return i != index;
        }
      );
    },
    backToList: function () {
      this.$router.push({ name: "SubmissionLibrary" });
    },
    onAddFiles() {
      if (this.$refs.file.files[0].size < 4000000) {
        this.errorText = "";
        this.imageUploading = true;
        this.uploadFileToCloudinary(this.$refs.file.files[0])
          .then((fileResponse) => {
            this.formData.cloudinaryImageUrl = fileResponse.secure_url;
            this.imageUploading = false;
          })
          .catch((err) => {
            this.imageUploading = false;
          });
      } else {
        this.errorText = "Upload Image below than 4 MB";
      }
    },
    openCloseModal(value) {
      this.openClose = value;
    },
    uploadFileToCloudinary(file) {
      return new Promise((resolve, reject) => {
        // const CLOUDINARY_URL =  "https://api.cloudinary.com/v1_1/dqj2q4qee/image/upload";
        // const CLOUDINARY_UPLOAD_PRESET = "xptgrebi";

        const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
        const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_PRESET;

        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        //formData.append("folder", "cloudinary-demo");
        formData.append("file", file);

        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }

          // Not successfull, let find our what happened
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files : " + error;
            this.isError = true;
            reject(error);
          }
        };

        request.onerror = (err) => {
          //  alert("error: " + err);
          reject(err);
        };

        request.send(formData);
      });
    },
    done() {
      this.openCloseModal(false);
      this.formData.attachments.splice(this.index, 1);
    },
    deleteAttachment(index) {
      this.openCloseModal(true);
      this.todo = "delete";
      this.index = index;
    },
    deleteAttachmentById(id) {
      const imageIndex = this.formData.attachments.findIndex(
        (rec) => rec.documentId == id
      );
      if (imageIndex > -1) {
        this.deleteAttachment(imageIndex);
      }
    },
    openAddFileModal(flag) {
      this.openAddFile = flag;
    },
    addFiles(file) {
      this.openAddFileModal(false);
    },
    submittedInfoModalflag(open) {
      this.openInfoModal = open;
      this.$router.push({ name: "MyIdeas" });
    },
    async displayNameFilterSearch(value, selectedValues) {
      this.setLoadeFlag(true);
      let payload = {
        value: value,
        accessToken: this.msGraphAccessToken,
      };
      let result = await this.$store.dispatch("getUsersByDisplayName", payload);
      if (selectedValues && selectedValues.length > 0) {
        let filteredResult = result.filter((e) => {
          return !selectedValues.some(
            (item) => item.email?.toLowerCase() === e.email?.toLowerCase()
          );
        });
        this.usersByDisplayName = filteredResult;
      } else {
        this.usersByDisplayName = result;
      }
      this.setLoadeFlag(false);
    },
    async setActiveWindow(value) {
      this.dropdownVisibleTo = value;
    },
    setLoadeFlag(flag) {
      this.loaderFlag = flag;
    },
    onChangeCheckbox(event) {
      this.formData.metaData.isTalentRequired = event.target.checked;
    },
    setLoadingFlag(flag) {
      this.isLoader = flag;
    },
  },
  components: {
    FileUpload,
    SubmissionAttachment,
    Tooltip,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    DeleteIcon,
    AttachmentIcon,
    SelectorIcon,
    InfoCard,
    deleteAttachment,
    Multiselect,
    AddFiles,
    TextAreaError,
    SetTitle,
    IdeaSubmittedInfo,
    LoaderView,
    Checkbox,
    UserCard,
    AppTextEditor
  },
};
</script>
<style>
.uploadButton {
  display: inline-block;
  background-color: indigo;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-left: 0.5rem;
}
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  vertical-align: top;
  display: inline-block;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.multiselect-tag {
  font-size: 12px;
  background: #1983bf;
  white-space: normal;
  word-break: break-word;
}

.multiselect-option.is-selected,
.multiselect-option.is-selected.is-pointed {
  background: #1983bf;
}

.tagCustomInput .multiselect-options,
.tagCustomInput .multiselect-caret {
  display: none;
}

.is-tags .multiselect-search input {
  word-break: break-word;
  overflow: auto;
  max-width: 290px!important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
