<template>
<div class="mr-20">
  <h3
    class="
      rounded-t
      p-3
      text-xl
      font-semibold
      bg-indigo-500
      text-white text-center
    "
  >
    Submissions by Category
  </h3>
  <div>
    <table
      class="
        min-w-full
        divide-y divide-gray-200
        table-auto
        bg-white
        shadow-md
        rounded
        text-sm text-gray-600
      "
    >
      <thead class="bg-gray-100 text-gray-500">
        <tr>
          <th scope="col" class="text-left p-2">Category Name</th>
          <th scope="col" class="text-right p-2">Total Number</th>
          <th scope="col" class="text-right p-2">Percentage</th>
        </tr>
      </thead>

      <tbody
        class="bg-white divide-y divide-gray-200 text-sm"
        v-if="updateData?.length > 0"
      >
        <tr
          class="cursor-pointer hover:bg-gray-50"
          v-for="(bogData, index) in updateData"
          :key="{ index }"
          @click="goToIdea(bogData)"
        >
          <td class="p-2">
            <div
              class="inline-block w-3 h-3 mt-1 mr-1 rounded-full"
              :class="{
                NA: bogData.name === 'NA',
                other: bogData.name === 'Others',
                green: bogData.name === 'Green',
                blue: bogData.name === 'Blue',
                orange: bogData.name === 'Orange',
              }"
            ></div>
            {{ bogData.name }}
          </td>
          <td class="p-2 text-right">{{ bogData.value }}</td>

          <td class="p-2 text-right">
            <span class="text-xs"> {{ bogData.percentage }}%</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="p-2">No data available !</div>
      </tbody>
    </table>
  </div>
</div>
</template>
<script>
export default {
  name: "BOGOTable",
  props: {
    data: Array,
  },
  computed: {
    updateData() {
      //return this.data
      let dataArr = this.data?.map((m) => {
        m.percentage = (
          (m.value /
            this.data
              .map((item) => item.value)
              .reduce((prev, curr) => prev + curr, 0)) *
          100
        ).toFixed(1);
        return m;
      });
      return dataArr;
    },
  },
  methods: {
    goToIdea(data) {
      //this.$emit("viewDetails", data);
    },
  },
};
</script>
<style scoped>
.other {
  background: #b2b2b2;
}
.blue {
  background: #107aa3;
}
.green {
  background: #00ab50;
}
.orange {
  background: #fc9d27;
}
.NA {
  background: #1aa1da;
}
</style>