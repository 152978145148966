<template>
  <div
    class="relative flex flex-col block overflow-hidden cursor-pointer z-10"
    @click.prevent="$emit('cardClick', item)"
  >
    <div class="absolute z-10 text-white text-xs antialiased tracking-wide">
      <div v-if="isJudgeOrUser && (item.status == 1 ||item.status == 6)" class="px-3 py-1 rounded-tl rounded-br shadow bg-green-500">
          {{ "Submitted" }}
      </div>
      <div v-else
        
        class="px-3 py-1 rounded-tl rounded-br shadow"
        :class="{
          'bg-gray-600': item.status === -1,
          'bg-yellow-500': item.status === 0,
          'bg-green-600': item.status === 1,
          'bg-blue-500': item.status === 2,
          'bg-gray-500': item.status === 3,
          'bg-red-500': item.status === 5,
          'bg-gray-400': item.status === 6,
          'bg-gray-500': item.status === 7,
        }"
      >
        {{ item.statusLabel }}
      </div>
    </div>
    <div
      class="absolute z-10 text-white text-xs antialiased tracking-wide right-0"
      v-tooltip="{
        theme: {
          placement: 'bottom-left',
          theme: {},
        },
      }"
    >
    </div>

    <div class="relative pb-48 overflow-hidden">
      <img
        class="
          absolute
          inset-0
          h-full
          w-full
          object-cover
          rounded-tl rounded-tr
        "
        :src="item.image"
        alt=""
      />
      <div
        v-if="!isJudgeOrUser && item.phase"
        class="
          absolute
          bottom-0
          right-0
          left-0
          block
          z-10
          bg-gray-500
          text-white
          px-3
          py-2
          text-xs
          antialiased
          tracking-wide
          h-10
        "
      >
        <div class="pt-1 inline-block font-bold">
          {{ item.phase }}
        </div>
      </div>
    </div>

    <div class="flex-1 p-4">
      <div>
        <div class="dropdown inline-block relative z-30">
          <span
            class="
              mr-1
              inline-block
              px-2
              py-1
              leading-none
              border border-blue-500
              text-blue-500
              rounded-full
              font-semibold
              text-xs
            "
            >{{ item?.type }}</span
          >
        </div>
      </div>
      <div style="min-height: 120px">
        <div class="flex">
          <h2
            class="
              mt-2
              mb-2
              text-gray-800
              font-bold
              break-words
              line-clamp-2
              h-10 w-11/12
            "
          >
            {{ item.name }}
          </h2>
          <div 
            class="w-1/12
            mt-2
            mb-2
            text-gray-800
            font-bold
            break-words
            line-clamp-2
            h-10 flex float-right" 
            v-if="item.status !== -1">
            <idea-grid-new-tab
              :item="item"
              @cardClickNewtab="cardClickNewtab"
              :user="user"
            />
        </div>
        </div>
        <div class="line-clamp-3" v-if="item?.description">
          <div
            class="text-gray-500 break-words"
            v-if="this.description?.length < 100"
          >
             {{this.description}}
          </div>
          <div class="text-gray-500 break-words" v-else>
            {{ this.description?.substring(0, 100) + " ..." }}
          </div>
        </div>
        <div v-else class="text-gray-500">NA</div>  
      </div>
    </div>
    <!-- <pre>{{ item?.metaData?.teamMembers }}</pre> -->
    <div class="p-4 border-t border-b text-xs text-gray-700">
      <div class="flex items-center justify-between leading-tight">
        <div class="text-grey-darker text-sm">
          <h3>Created At</h3>
          <p class="inline-block font-semibold">
            {{ item.createdAt }}
          </p>
        </div>

        <div class="text-grey-darker text-sm text-right">
          <h3>Edited At</h3>
          <p class="inline-block font-semibold">
            {{ item.updatedAt }}
          </p>
        </div>
      </div>
    </div>
    <div class="p-4 text-xs text-gray-700">
      <div class="flex items-center justify-between leading-tight">
        <avatar-grouping
          position="right"
          :dataObj="item?.metaData"
          :isUserModal="false"
        />

        <!-- <div class="text-grey-darker text-sm">
          <like-fallow-share
            :like="true"
            :likeCount="true"
            :follow="true"
            :followCount="true"
            :idea="item"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import LikeFallowShare from "./LikeFallowShare.vue";
import AvatarGrouping from "./AvatarGrouping.vue";
import ActionSignUpIcon from "./ActionSignUpIcon.vue";
import IdeaGridNewTab from "../components/IdeaGridNewTab.vue";
import { stripHtml } from "string-strip-html";
export default {
  data() {
    return {
      showType: "",
    };
  },
  components: {
    LikeFallowShare,
    AvatarGrouping,
    ActionSignUpIcon,
    IdeaGridNewTab
  },
  computed: {
    userAccess() {
      return this.user && this.user.roles.length===1 && this.user.roles.includes("user");
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
    description:function (){
      if(this.item && this.item.description){
        return stripHtml(this.item.description).result;
      } else {
        return ""
      }  
    }
  },
  props: {
    item: Object,
    user: Object,
  },
  methods: {
    showTypeOver: function (item) {
      this.showType = item;
    },
    showTypeOut: function (item) {
      this.showType = null;
    },
    cardClickNewtab: function (item) {
      let ideaID = item.id;
      let curURL = window.location.href;
      window.open(curURL+'/details/'+ideaID, '_blank');
    }
  },
};
</script>
