<template>
  <div
    class="flex sticky top-0 ease-in-in duration-500 relative z-30 "
 
  >
    <div
      class="
        ease-in-in
        duration-500
        bg-opacity-100
       
        bg-white
        sub-nav-bar
        w-60
        p-5
      "
    >
      <nav class="">
        <h3 class="text-blue-800 font-semibold mb-2">Admin</h3>
        <div class="text-blue-600 text-sm">
          <button class="inline-block w-full hover:text-blue-700">
            <router-link
              class="flex duration-200 my-1"
              to="/setting/webpages"
              :class="[
                $route.path.startsWith('/setting/webpages')
                  ? activeClass
                  : inactiveClass,
              ]"
              ><span>Manage Webpages</span>
            </router-link>
          </button>
          
        </div>
        <!-- <div class="text-blue-600 text-sm">
          <button class="inline-block w-full hover:text-blue-700">
            <router-link
              class="flex duration-200 my-1"
              to="/setting/leaderBoard"
              :class="[
                $route.path.startsWith('/setting/leaderBoard')
                  ? activeClass
                  : inactiveClass,
              ]"
              ><span>Leaderboard</span>
            </router-link>
          </button>
          
        </div> -->
        <div class="text-blue-600 text-sm">
          <button class="inline-block w-full hover:text-blue-700">
            <router-link
              class="flex duration-200 my-1"
              to="/setting/logs"
              :class="[
                $route.path.startsWith('/setting/logs')
                  ? activeClass
                  : inactiveClass,
              ]"
              ><span>Email Logs</span>
            </router-link>
          </button>
          
        </div>
        <div class="text-blue-600 text-sm">
          <button class="inline-block w-full hover:text-blue-700">
            <router-link
              class="flex duration-200 my-1"
              to="/setting/submissions"
              :class="[
                $route.path.startsWith('/setting/submissions')
                  ? activeClass
                  : inactiveClass,
              ]"
              ><span>Submission</span>
            </router-link>
          </button>
          
        </div>
         <div class="text-blue-600 text-sm">
          <button class="inline-block w-full hover:text-blue-700">
            <router-link
              class="flex duration-200 my-1"
              to="/setting/comments"
              :class="[
                $route.path.startsWith('/setting/comments')
                  ? activeClass
                  : inactiveClass,
              ]"
              ><span>Comments</span>
            </router-link>
          </button>
        </div>
        <div class="fixed bottom-0">
          <!-- BNottom Menu -->
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { defineComponent, ref } from "vue";
import { useSidebar } from "../hooks/useSidebar";

export default defineComponent({
  data() {
    return {};
  },
  props: {
    openSideBar: Boolean,
  },
  computed: {
    ...mapGetters(["user"]),
    managerAccess() {
      return this.user && this.user.roles.includes("manager");
    },
    userAccess() {
      return this.user && this.user.roles.includes("user");
    },
    judgeTeamLead() {
      return this.user && this.user.roles.includes("judgeManager");
    },
    judgeTeamMember() {
      return this.user && this.user.roles.includes("judgeMember");
    },
    showHideSidebar() {
      return this.openSideBar
        ? " ease-in-in duration-100  transition-all whitespace-nowrap inline-block hover:text-blue-400"
        : " ease-in-in duration-100  transition-all whitespace-nowrap hidden ";
    },
  },
  setup() {
    const { isOpen } = useSidebar();
    const activeClass = ref(" font-semibold ");
    const inactiveClass = ref(" ");

    return {
      isOpen,
      activeClass,
      inactiveClass,
    };
  },
});
</script>
<style scoped>
.sub-nav-bar {
}
</style>
