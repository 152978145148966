<template>
  <h3
    class="
      rounded-t
      p-3
      text-xl
      font-semibold
      bg-indigo-500
      text-white text-center
    "
  >
    CISCO Leader Board
  </h3>
  <div class="bg-white shadow-md rounded">
    <table
      class="
        min-w-full
        divide-y divide-gray-200
        table-auto
        bg-white
        shadow-md
        rounded
        text-sm text-gray-600
      "
    >
      <thead class="bg-gray-100 text-gray-500">
        <tr>
          <th class="p-2 text-left">Leader Name</th>
          <th class="p-2 text-right">Participation</th>
          <th class="p-2 text-right">Ideate</th>
          <th class="p-2 text-right">Validate</th>
          <th class="p-2 text-right">Scope</th>
          <th class="p-2 text-right">Incubate</th>
          <th class="p-2 text-right">Total</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          class="border-b border-gray-200 "
          v-for="(user, index) in userData"
          :key="{ index }"
          :class="{ 'bg-gray-50': index % 2 }"
        >
          <td class="p-2 text-left">
            <div class="text-left">
              {{ user.name }}
            </div>
          </td>

          <td class="p-2 text-right">
            <div class="text-right">{{ user.participants }}</div>
          </td>
          <td class="p-2 text-right">
            <div class="text-right">{{ user.ideate }}</div>
          </td>
          <td class="p-2 text-right">
            <div class="text-right">{{ user.validate }}</div>
          </td>
          <td class="p-2 text-right">
            <div class="text-right">{{ user.scope }}</div>
          </td>
          <td class="p-2 text-right">
            <div class="text-right">{{ user.incubate }}</div>
          </td>
          <td class="p-2 text-right">
            <div class="text-right">{{ user.total }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import IconUser from "../../components/Icon/Like.vue";
import LeaderBoard from "../../services/leaderboard";
export default defineComponent({
  name: "LeaderBoard",
  data() {
    return {
      users: LeaderBoard,
    };
  },
  props: {
    heading: String,
    userData: Object,
  },
  methods: {
    imageLoadError(event) {
      event.target.src = IconUser;
    },
  },

  setup() {},
});
</script>


