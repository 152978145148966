<template>
  <div class="flex -space-x-4">
    <div
      v-for="(user, index) in displayMembers"
      :key="index"
      v-tooltip="{
        text:
          user?.name?.length > 20
            ? user.name.substring(0, 20) + ' ...'
            : user.name,
        theme: {
          placement: position ? position : 'right',
        },
      }"
    >
      <!-- {{user}} -->
      <div v-if="user.image">
        <img
          :class="{ 'cursor-pointer': !this.isJudgeOrUser || this.user.aspenifyUserId === user.accountUserId}"
          class="
            userImage
            h-8
            w-8
            rounded-full
            ring-2 ring-white
            shadow
            hover:ring-4
            cursor-default
          "
          :src="user.image"
          alt="users"
          @click.stop="userDetail(user)"
        />
      </div>
      <div
        class="
          userImage
          h-8
          w-8
          rounded-full
          ring-2 ring-white
          shadow-lg
          item-center
          text-center
          bg-gray-400
          place-items-center
          grid
          font-thin
          text-lg text-white
        "
        v-else
        @click.stop="userDetail(user)"
      >
        {{ user?.name.charAt(0).toUpperCase()
        }}{{ user?.name.charAt(1).toUpperCase() }}
      </div>
    </div>
    <div
      v-if="countMembers?.length !== 0"
      class="
        inline-block
        rounded-full
        p-0
        inline-flex
        items-center
        justify-center
        bg-gray-500
        text-gray-100
        ml-2
        shadow-lg
        z-10
        h-8
        w-8
        ring-2 ring-white
        font-normal
        text-lg
        cursor-pointer
        hover:bg-gray-700 hover:shadow-xl
      "
      @click.stop="this.showUserList = true"
    >
      +{{ countMembers?.length }}
    </div>
  </div>

  <user-list-modal
    :allMembers="allMembers"
    v-if="showUserList"
    @close="close"
  />
</template>

<script>
import UserListModal from "../components/UserListModal";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showUserList: false,
    };
  },
  components: {
    UserListModal,
  },
  props: {
    dataObj: Object,
    dataArray: Array,
    position: String,
    isUserModal: Boolean,
  },
  computed: {
    ...mapGetters(["user"]),
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
    allMembers() {
      if (this.dataArray) {
        this.dataArray.map((m) => {
          m.image = m.profilePic;
          m.name = m?.firstName + " " + m?.lastName;
        });
        return this.dataArray;
      } else {
          
        let allUsers = [
          ...this.dataObj?.teamLead || [],
          ...this.dataObj?.teamMembers || [],
          ...this.dataObj?.mentors || [],
          ...this.dataObj?.submitter || [],
        ];
        allUsers?.map((m) => {
          if (m.email?.toLowerCase() === this.user?.email?.toLowerCase()) {
            m.image = this.user.profilePic;
          }
          // if(m.email!== this.user?.email){
          //   m.image = m.image;
          // }
        });
        return allUsers || [];
      }
    },
    displayMembers() {
      return this.allMembers?.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) => t.email?.toLowerCase() === tag.email?.toLowerCase()
          ) == index && index < 6
      );
    },
    countMembers() {
      return this.allMembers?.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) => t.email?.toLowerCase() === tag.email?.toLowerCase()
          ) == index && index >= 6
      );
    },
    setAllUser() {
      return this.countMembers.map((m, i) => m.name) + ",";
    },
  },
  methods: {
    close() {
      this.showUserList = false;
    },
    userDetail(user) {
      if(!this.isJudgeOrUser || this.user.aspenifyUserId === user.accountUserId){
        var email = user.email?.toLowerCase();
        var name = email.substring(0, email.lastIndexOf("@"));
        
        this.$router.push({
          name: "UserProfile",
          params: {
            userId: user?.accountUserId ? user?.accountUserId : user?._id,
            userName: name,
          }, // on like and follow, accoountUserId is not matching, so we are sending _id
        });
      }
    },
  },
};
</script>
<style scoped>
.userImage:hover {
  z-index: 30;
  position: relative;
  box-shadow: 0 0px 4px rgb(17, 17, 17);
}
</style>