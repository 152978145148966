<template>
  <TransitionRoot appear :show="requestSentSuccessModal" as="template">
    <Dialog as="div" :open="requestSentSuccessModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-80">
        <div class="min-h-screen text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
                text-center
              "
            >
              <img src="@/assets/images/artboard___1.png" />

              <div class="m-10">
                <h1 class="font-bold text-blue-400">Thank You !</h1>
                <h1 class="font-bold text-blue-400">
                  Your request has been sent.
                </h1>
                <p>Submitter / Team lead will receive your request.</p>
                <p>
                  You will be part of the submission team once they accept your
                  request.
                </p>

                <button
                  type="button"
                  @click="$emit('closerequestsentsuccessmodal', false)"
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-600
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-teal-600
                    transition
                    mt-5
                    mb-2
                  "
                >
                  Got It</button
                ><br />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "IdeaSubmittedInfo",
  beforeUpdate() {},
  props: {
    requestSentSuccessModal: Boolean,
    page: String,
  },
  data() {
    return {
      pageInfo: {
        heading: "a",
        subHeading: "b",
      },
    };
  },
  created: function () {
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {},
};
</script>
