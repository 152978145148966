<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";


import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { FunnelChart } from "echarts/charts";

import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  FunnelChart,
  CanvasRenderer,
]);

export default defineComponent({
  name: "Funnel",
  components: {
    VChart,
  },
  props: {
    data: Array,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  setup(props) {
    const option = ref({
      title: {
        text: "Funnel",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}%",
      },

      legend: {
        data: ["Ideate", "Venture Graduation", "3", "4", "5"],
      },
      series: [
        {
          name: props.heading,
          type: "funnel",
          left: "10%",
          top: 60,
          bottom: 60,
          width: "80%",
          min: 0,
          max: 100,
          minSize: "20%",
          maxSize: "100%",
          sort: "descending",
          gap: 2,
          label: {
            show: true,
            position: "inside",
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid",
            },
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: props.data.filter(f => f.value !== 0),
        },
      ],
      // series: [
      //   {
      //     name: "Expected",
      //     type: "funnel",
      //     left: "10%",
      //     width: "80%",
      //     label: {
      //       formatter: "{b}Expected",
      //     },
      //     min: 0,
      //     max: 100,
      //     minSize: "20%",
      //     maxSize: "100%",
      //     labelLine: {
      //       show: false,
      //     },
      //     itemStyle: {
      //       opacity: 0.7,
      //     },
      //     emphasis: {
      //       label: {
      //         position: "inside",
      //         formatter: "{b}Expected: {c}%",
      //       },
      //     },
      //     data: [
      //       { value: 60, name: "Visit" },
      //       { value: 40, name: "Inquiry" },
      //       { value: 20, name: "Order" },
      //       { value: 80, name: "Click" },
      //       { value: 100, name: "Show" },
      //     ],
      //   },
      //   {
      //     name: "Actual",
      //     type: "funnel",
      //     left: "10%",
      //     width: "80%",
      //     min: 0,
      //     max: 100,
      //     minSize: "20%",
      //     maxSize: "100%",
      //     label: {
      //       position: "inside",
      //       formatter: "{c}%",
      //       color: "#fff",
      //     },
      //     itemStyle: {
      //       opacity: 0.5,
      //       borderColor: "#fff",
      //       borderWidth: 2,
      //     },
      //     emphasis: {
      //       label: {
      //         position: "inside",
      //         formatter: "{b}Actual: {c}%",
      //       },
      //     },
      //     data: props.data.filter(f => f.value !== 0),
      //     // Ensure outer shape will not be over inner shape when hover.
      //     z: 100,
      //   },
      // ],
    });

    return { option };
  },
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>


