<template>

  <div
    v-html="loadContent"
  ></div>
</template>

<script >
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user", "webPages", "selectedWebPage"]),

    selectedPage() {
      return this.$store.getters.selectedWebPage;
    },
    loadContent() {
        return this.selectedPage?.metaData?.draftContent;
    },
  },
  methods: {},
  created: async function () {
    let payload = {
      pageId: this.$route.params.page,
    };
   
    await this.$store.dispatch("getPageById", payload);
  },
};
</script>
<style scoped>
</style>
