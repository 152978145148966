import { reactive, watch } from 'vue';
import feathersClient from '../services/feathersClient';
import createAuth0Client from '@auth0/auth0-spa-js'
import store from "../store/store";

export const $auth0 = reactive({
  client: null,
  state: {
    isLoading: true,
    isAuthenticated: false,
    error: null,
    user: null
  }
})

/** Returns the current instance of the SDK */
export const getClient = () => auth0Client

export async function setupAuth0({ domain, client_id, store, callbackRedirect }) {

  $auth0.client = await createAuth0Client({
    domain,
    client_id,
    redirect_uri: `${window.location.origin}/home`,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    audience: "https://www.aspenify.com/api"
    // audience: options.audience
  });

  const search = window.location.search;
  const comingFromRedirect = search.includes('code=') && search.includes('state=')
  try {
    // If the user is returning to the app after auth success with Auth0.
    if (comingFromRedirect) {
      // handle the redirect and retrieve tokens
      const { appState } = await $auth0.client.handleRedirectCallback()
      callbackRedirect(appState);
    }
  } catch (e) {
    $auth0.state.error = e
  } finally {
    // Initialize authentication state
    $auth0.state.isAuthenticated = await $auth0.client.isAuthenticated()
    $auth0.state.user = await $auth0.client.getUser();
    if ($auth0.state.user) {

      const accessToken = await $auth0.client.getTokenSilently();
      const apiKey = process.env.VUE_APP_API_KEY;


      const userData = await feathersClient.authenticate({
        strategy: 'api-key',
        accessToken: accessToken,
        apiKey: apiKey
      });

      const jwtAccessToken = userData.accessToken;
      const params = userData.query;

      const user = {
        name: userData.user.firstName,
        lastName:userData.user?.lastName,
        email: userData.user.email?.toLowerCase(),
        profilePic: userData.user.profilePic,
        accessToken: jwtAccessToken,
        accountId: params.accountId,
        organizationId: params.organizationId,
        aspenifyUserId: userData.user._id
      }
      const accountUserMetadataServiceService = await feathersClient.service('accountusermetadata');
      let userMetaData = await accountUserMetadataServiceService.find({"query":{"accountUserId" : user.aspenifyUserId}});
      let isContractor = false;
      if(userMetaData?.data?.length > 0 && userMetaData.data[0]?.metadata?.employment_type && userMetaData.data[0].metadata.employment_type == 'user.employee.vendor'){
        isContractor = true;
      }
      let roleObj = await checkRole(user);
      user.roles = roleObj.roles;
      if (roleObj?.userRole) {
        user.userRole = roleObj.userRole
      }
      if(isContractor){
        user.roles.push('contractor');
      }
      // user.roles = [];
      // user.roles.push('contractor');
      user.associatedTeam = roleObj.associatedTeam;
      user.isEtiEmployee = roleObj.isEtiEmployee;
      store.dispatch("login", user);
      
      //  below block for Judging user only
      // if(user.roles[0] === "judgeManager" || user.roles[0] === "judgeMember") {
      //   if (window.location.toString().includes("submissionclosed")) {
      //    // window.location.href = "/unauthorized";
      //   }
      // }
    }

    $auth0.state.isLoading = false;
  }
}

/**
 * A Vue plugin to expose the wrapper object throughout the application.
 * The Auth0 client is available as $auth0.client.
 */
export const Auth0Plugin = {
  install(app, options) {
    app.config.globalProperties.$auth0 = $auth0
  }
}

export function auth0Plugin(app) {
  app.use(Auth0Plugin)
}

/**
 * Storage provider for the FeathersJS Authentication Client.
 * The getItem and setItem are not needed, but have to be stubbed out to prevent errors.
 */
export const storage = {
  async getItem() {
    let token

    try {
      token = await instance.getTokenSilently()
    } catch (error) {
      token = ''
    }
    return token
  },
  setItem() { },
  removeItem() { }
}

export const routeGuard = async (to, from, next) => {
  const { isAuthenticated, isLoading, user } = $auth0.state;
  const { accessToken, aspenifyUserId, roles, userRole } = store.state.user.user;
  if(to.name != "Logout" && roles.includes("contractor")){
    return next('/unauthorized');
  }
  if (to.name == 'NewIdea') {
    const { accessToken, aspenifyUserId, roles, userRole } = store.state.user.user;
    if(userRole == "workspaceOwner" || roles.includes("manager")){
      // return true;
    } else {
      const tokenString = `Bearer ${accessToken}`;
      const playbookService = feathersClient.service("playbooks");
      const baseId = process.env.VUE_APP_BASE_ID;
      const playbookQuery = {
        baseId: baseId,
        status: 4,
        $select: ['createInitiative']
      };
      var result = await playbookService.find({
        query: playbookQuery,
        headers: {
          "Authorization": tokenString
        },
      });
      if(result?.length>0 && result[0].createInitiative){
        // return true;
      } else {
        if (roles == "judgeManager" || roles == "judgeMember") {
          return next('/submissionclosed');
        } else {
        return next('/submissionclosed');
        }
      }
    }
  }
  // let roleObj = await checkRole();
  const verify = async () => {

    if (!isAuthenticated) {
      return next({ path: '/', query: { returnUrl: to.path } });
    }
    const { roles } = store.state.user.user;

    //  below blcok will execute for Judging user only
    if (roles == "judgeManager" || roles == "judgeMember") {
      if ( 
       //  window.location.toString().includes("submitIdea")|| 
       // window.location.toString().includes("submissionclosed")|| 
        // window.location.toString().includes("myideas")|| 
         window.location.toString().includes("allideas")|| 
        window.location.toString().includes("progressview")|| 
        window.location.toString().includes("dashboard") || 
        window.location.toString().includes("processflow")) {
          //  window.location.href = "/unauthorized";
           return next('/unauthorized');

      }
    }

    if (to?.meta?.authorize?.role?.length > 0) {
      // When Judging menu is clicked - check whether user is
      // 1. Workspace Owner
      // 2. Power User
      // 3. Judging team (Manager/Member)
      if (to.name == 'Judging') {
        let teamIds = [process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID];
        let isUserPartOfTeam = await checkUserInTeamIncludingPowerUserTeam(process.env.VUE_APP_POWERUSER_TEAM_ID, teamIds);
        if (isUserPartOfTeam) {
          return next();
        } else {
          return next('/unauthorized');
        }
      }
      // When ProgressView menu is clicked - check whether user is
      // 1. Workspace Owner
      // 2. Power User
      if (to.name == 'ProgressView') {
        let teamIds = [process.env.VUE_APP_EXECUTIVE_TEAM_ID, process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID];
        let isUserPartOfTeam = await checkUserInTeamIncludingPowerUserTeam(process.env.VUE_APP_POWERUSER_TEAM_ID, teamIds);
        if (isUserPartOfTeam) {
          return next();
        } else {
          return next('/unauthorized');
        }
      }
      // When Dashboard menu is clicked - check whether user is
      // 1. Workspace Owner
      // 2. Power User
      // 3. Executive team (Manager/Member)
      if (to.name == 'Dashboard') {
        let teamIds = [process.env.VUE_APP_EXECUTIVE_TEAM_ID, process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID];
        let isUserPartOfTeam = await checkUserInTeamIncludingPowerUserTeam(process.env.VUE_APP_POWERUSER_TEAM_ID, teamIds);
        if (isUserPartOfTeam) {
          return next();
        } else {
          return next('/unauthorized');
        }
      }
      if (to.meta.authorize.role.filter(item => item === roleObj.roles.find(role => role === item))?.length === 0) {
        return next('/unauthorized');
      } else {
        return next();
      }
    } else {
      if(to.name == "NewIdea" && roles.includes('contractor')){
        return next('/unauthorized');
      } else {
        return next();
      }
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!isLoading) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (isLoading === false && user) {
      return verify();
    }
  });
};
const checkRole = (async (user) => {
  if ($auth0.state.user) {
    const subOrgMembershipService = feathersClient.service('/suborganizations/membership');
    let roleObj = {};
    roleObj.roles = [];
    let payload = {};
    payload.subOrgIds = [
      process.env.VUE_APP_POWERUSER_TEAM_ID,
      process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID,
      process.env.VUE_APP_EXECUTIVE_TEAM_ID,
      process.env.VUE_APP_ETI_TEAM_ID
    ]
    let teamUserRoles = await subOrgMembershipService.create(payload);
    let etiTeam;
    if (teamUserRoles?.length > 0 && teamUserRoles[0].userRole && teamUserRoles[0].userRole == "workspaceOwner") {
      roleObj.roles.push('manager');
      roleObj.userRole = 'workspaceOwner';
      const subOrgService = feathersClient.service('suborganizations');
      let subOrgData = await subOrgService.find({
        query: {"_id": process.env.VUE_APP_ETI_TEAM_ID, "organizationId": user.organizationId}
      });
      if(subOrgData?.data?.length>0){
        etiTeam = subOrgData.data[0].members.find(member => member.accountUserId == user.aspenifyUserId);
      }
    } else {


      let powerUserTeam = teamUserRoles.find(teamData => (teamData.teamId).toString() == process.env.VUE_APP_POWERUSER_TEAM_ID);
      let findJudgeTeamManager = teamUserRoles.find(teamData => teamData.userRole == "Manager" && (teamData.teamId).toString() == process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID);
      let findJudgeTeamMember = teamUserRoles.find(teamData => teamData.userRole == "Member" && (teamData.teamId).toString() == process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID);
      let executivesTeam = teamUserRoles.find(teamData => (teamData.teamId).toString() == process.env.VUE_APP_EXECUTIVE_TEAM_ID);
      etiTeam = teamUserRoles.find(teamData => (teamData.teamId).toString() == process.env.VUE_APP_ETI_TEAM_ID);
      if (powerUserTeam) {
        roleObj.roles.push('manager');
      }
      if (findJudgeTeamManager) {
        roleObj.roles.push('judgeManager');
        roleObj.associatedTeam = findJudgeTeamManager.associatedTeam;
      }
      if (findJudgeTeamMember) {
        roleObj.roles.push('judgeMember');
        roleObj.associatedTeam = findJudgeTeamMember.associatedTeam;
      }
      if (executivesTeam) {
        roleObj.roles.push('executive');
      }
      if (roleObj.roles.length == 0) {
        roleObj.roles.push('user');
      }
      let roles = roleObj.roles
      let rolename = roles[0];
      if(rolename === "user") {
        if (window.location.toString().includes("allideas")) {
          window.location.href = "/unauthorized";
        }
        
        if (window.location.toString().includes("processflow")) {
          window.location.href = "/unauthorized";
        }
      }
    }
    if(etiTeam){
      roleObj.isEtiEmployee = true;
    } else {
      roleObj.isEtiEmployee = false;
    }
    return roleObj;
  }
})

// This function checks in ORDERLY WAY whether user is 
// 1. Workspace Owner
// 2. Power User
// 3. Team (Manager/Member)
const checkUserInTeamIncludingPowerUserTeam = (async (powerUserTeamId, teamIds) => {
  if ($auth0.state.user) {
    const accessToken = await $auth0.client.getTokenSilently();
    const apiKey = process.env.VUE_APP_API_KEY;
    const userData = await feathersClient.authenticate({
      strategy: 'api-key',
      accessToken: accessToken,
      apiKey: apiKey
    });
    let userId = userData.user._id;
    const params = userData.query;
    const OrgService = feathersClient.service('organizations');
    let organizationData = await OrgService.find({
      query: {
        "_id": params.organizationId,
        "users": {
          "$elemMatch": { "accountUserId": userId, "role": "workspaceOwner" }
        }
      }
    });
    if (organizationData?.data?.length > 0) {
      return true;
    } else {
      let ORQuery = [{ "_id": powerUserTeamId }, { "parentOrganizationId": powerUserTeamId }];
      if (teamIds.length > 0) {
        for (let teamId of teamIds) {
          ORQuery.push({ "_id": teamId }, { "parentOrganizationId": teamId });
        }
      }
      const subOrgService = feathersClient.service('suborganizations');
      let userExistInTeam = await subOrgService.find({
        query: {
          "$or": ORQuery,
          "members": {
            "$elemMatch": { "accountUserId": userId }
          }
        }
      });
      if (userExistInTeam?.data?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
});