import feathersClient from '../services/feathersClient';

const state = {
  playbook: null,
  phaseNames: []
}

const mutations = {
  SET_PLAYBOOK(state, playbook) {
    state.playbook = Object.assign({}, playbook);
  },
  SET_PHASE_NAMES(state, payload) {
    state.phaseNames = payload
  },
  SET_CHALLENGE_TYPES(state, payload) {
    state.challengeTypes = payload
  },
  SET_MARKET_HORIZONS(state, payload) {
    state.marketHorizons = payload
  },
  SET_SOURCES(state, payload) {
    state.sources = payload
  },
  SET_BOG_FRAMEWORKS(state, payload) {
    state.bogFrameworks = payload
  }
}
const actions = {
  async readPlaybook(context) {

    context.commit('SET_LOADING_FLAG', true);
    // const playbookId = process.env.VUE_APP_PRACTICE_ID;

    const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
    const playbooks = feathersClient.service("playbooks");
    const baseId = process.env.VUE_APP_BASE_ID;
    let roleActivityMap = new Map();
    try {
      const query = {
        // _id: playbookId,
        baseId: baseId,
        status: 4,
        $select: ['name', 'description', 'status', 'activities', 'metaDataSchema', 'createInitiative', 'baseId', 'version', 'createInitiativeEndDate','createInitiativeStartDate', 'metaData'],
        $populate: [{
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name description sequence'
            },
            select: 'name description sequence'
          },
          select: 'name description sequence metaData'
        },{
          path: 'communities',
          select: 'name communities',
          populate: [
            {
              path: 'communities',
              select: 'name raci'
            }]
        }]
      };
      const tokenString = `Bearer ${accessToken}`;
      var result = await playbooks.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      //if playbook is deprecated/inactive, get new playbook with 
      // if(result[0] && result[0].baseId && result[0].status && result[0].status ===5){
      //   var playbookResult = await playbooks.find({
      //     query: {
      //       baseId: result[0].baseId,
      //       status: 4,
      //       $select: ['name', 'description', 'status', 'activities', 'metaDataSchema', 'baseId', 'version'],
      //       $populate: [{ path: 'activities', populate: { path: 'activities', populate: { path: 'activities', select: 'name description sequence' }, select: 'name description sequence' }, select: 'name description sequence' }]
      //     },
      //   });
      //   result= playbookResult
      // }  
      if (result && result[0]) {
        result[0].processedActivities = [...result[0].activities];
        context.commit('SET_PLAYBOOK', result[0]);
        if (result[0].activities && result[0].activities.length > 0) {
          let phaseNames = result[0].activities.map((phase) => {
            return { "value": phase.name, "label": phase.name };
          })
          context.commit('SET_PHASE_NAMES', phaseNames);
        }
        if (result[0].metaDataSchema && result[0].metaDataSchema.length > 0) {
          for (let metaData of result[0].metaDataSchema) {
            if (metaData.name == 'challengeType') {
              let challengeTypes = metaData.choices.map((data, i) => {
                return { "key": i, "value": data, "label": data };
              })
              context.commit('SET_CHALLENGE_TYPES', challengeTypes);
            }
            if (metaData.name == 'marketHorizon') {
              let marketHorizons = metaData.choices.map((data, i) => {
                return { "key": i, "value": data, "label": data };
              })
              context.commit('SET_MARKET_HORIZONS', marketHorizons);
            }
            if (metaData.name == 'source') {
              let sources = metaData.choices.map((data, i) => {
                return { "key": i, "value": data, "label": data };
              })
              context.commit('SET_SOURCES', sources);
            }
            if (metaData.name == 'bogFramework') {
              let bog = metaData.choices.map((data, i) => {
                return { "key": i, "value": data, "label": data };
              })
              context.commit('SET_BOG_FRAMEWORKS', bog);
            }
          }
        }
        let allIdeas = context.rootState.initiative.initiatives;
        let userTitle = null;      
        // Checks user is contractor.
        if(roles.includes('contractor')){
          userTitle = 'General User - Contractor';
        }
        // Checks user role.
        else if(roles.length == 1 && roles.includes('user')){
          userTitle = 'General User - Employee';
        }
        // Checks whether logged in user is workspace owner or is part of power users team.
        else if (userRole == 'workspaceOwner' || roles.includes('manager')) {
          userTitle = 'Program Lead';
        } 
        // Checks whether logged in user is part of executive team.
        else if(roles.includes('executive')){
          userTitle = 'Executive - General';
        }
        // Checks whether logged in user is part of judging team or its sub team.
        else if (roles.includes('judgeManager') || roles.includes('judgeMember')) {
          userTitle = 'Executive - Judge';
        } 
        else {
          // Check whether logged in user team lead in any of initiative.
          var leadIndex = allIdeas.findIndex(idea => idea.teamLead.some(lead => lead.accountUserId == aspenifyUserId));
          if (leadIndex >= 0) {
            userTitle = 'Team Lead';
          }
          // Check whether logged in user team member/ mentor in any of initiative. 
          else if (leadIndex == -1) {
            var memberIndex = allIdeas.findIndex(idea => idea.teamMembers.some(member => member.accountUserId == aspenifyUserId));
            var mentorIndex = allIdeas.findIndex(idea => idea.mentors.some(mentor => mentor.accountUserId == aspenifyUserId));
            if (memberIndex >= 0 || mentorIndex >= 0) {
              userTitle = 'Team Member';
            }
          }
        }
        if ((userTitle && userTitle != 'General User - Contractor') && result[0].communities && result[0].communities.length > 0) {
          let communitiesData = result[0].communities[0].communities;
          for (let community of communitiesData) {
            let newCommunity = {};
            let raci = community.raci.r.concat(community.raci.a).concat(community.raci.c).concat(community.raci.i);
            newCommunity.raci = [...new Set(raci)];
            roleActivityMap.set(community.name, newCommunity)
          }
          let roleTeamActivityMap = roleActivityMap.get(userTitle);
          if(roleTeamActivityMap?.raci?.length>0){
            let allActivities = [...result[0].activities];
            let raciArray = roleTeamActivityMap.raci
            result[0].processedActivities = [];
            for (let i = allActivities.length - 1; i >= 0; i--) {
              let activity = allActivities[i];
              let processedActivity = await processPlaybookActivities(allActivities, activity, i, raciArray);
              if (processedActivity) {
                result[0].processedActivities.push(processedActivity);
  
              }
            }
            result[0].processedActivities = result[0].processedActivities.reverse();
          }
        }
        context.commit('SET_PLAYBOOK', result[0]);
      } else {
        console.error(`Could not fetch data for playbook ${playbookId}`)
      }
      context.commit('SET_LOADING_FLAG', false);
    } catch (err) {
      context.commit('SET_LOADING_FLAG', false);
    }
  }
}
const processPlaybookActivities = async function (allActivities, activity, index, raciArray) {
  if (!raciArray.includes(activity._id)) {
    allActivities.splice(index, 1);
  } else {
    if (activity.activities) {
      let stagePhaseActivities = activity.activities;
      if (stagePhaseActivities.length > 0) {
        for (let i = stagePhaseActivities.length - 1; i >= 0; i--) {
          let activity = stagePhaseActivities[i];
          await processPlaybookActivities(stagePhaseActivities, activity, i, raciArray);
        }
      }
    }
    return activity;
  }
}

const getters = {
  playbook: (state) => state.playbook,
  phaseNames: (state) => state.phaseNames,
  challengeTypes: (state) => state.challengeTypes,
  marketHorizons: (state) => state.marketHorizons,
  sources: (state) => state.sources,
  bogFrameworks: (state) => state.bogFrameworks
}

const PlaybookModule = {
  state,
  mutations,
  actions,
  getters
}

export default PlaybookModule;