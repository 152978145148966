<template>
  <div class="p-2 text-sm text-red-600" v-if="!checkEnableJudging()">Judging is turned off</div>
  <div class="p-2 bg-indigo-100 rounded-md text-sm text-gray-600" v-if="showProgramUserText">"This was promoted by Program Team"</div>
  <!-- <pre>{{ parentInitiative?.parentInitiativeId?.metaData?.action }} </pre> -->
  <nav class="flex flex-col sm:flex-row text-sm border-t pt-3 border-gray-200">
    <button
      class="
        flex-grow
        pb-3
        px-4
        block
        hover:text-blue-500
        focus:outline-none
        cursor-default
        text-blue-500 border-b-2 font-medium border-blue-500
      "
    >
      Impact of Submission
    </button>
  </nav>
  <div class="flex flex-row mb-3">
    <div class="flex flex-col w-2/4 pt-1">
      <label class="h-7"></label>
      <label>Revenue Rank By Category</label>
      <!-- <label>User/Activation Rank By Category</label> -->
    </div>
    <div class="flex flex-col w-1/4 pt-1">
      <label class="text-center font-semibold mb-1">Rank</label>
      <label class="text-center">{{getRevenueRank}}</label>
      <!-- <label class="text-center">{{getActivationRank}}</label> -->
    </div>
    <div class="flex flex-col w-1/4 pt-1">
      <label class="text-center font-semibold mb-1">Percentile</label>
      <label class="text-center">{{getRevenuePercentile}}</label>
      <!-- <label class="text-center">{{getActivationPercentile}}</label> -->
    </div>
  </div>
  <nav class="flex flex-col sm:flex-row text-sm border-t-2 pt-3 border-blue-500">
    <button
      @click="setActive('tab1')"
      class="
        flex-grow
        text-gray-600
        pb-3
        px-4
        block
        hover:text-blue-500
        focus:outline-none
        border-b
        cursor-default
      "
      :class="{
        'text-blue-500 border-b-2 font-medium border-gray-200':
          activeItem === 'tab1',
      }"
    >
      Judging Criteria
    </button>
    <!-- <button
      @click="setActive('tab2')"
      class="
        flex-grow
        text-gray-600
        py-2
        px-4
        block
        hover:text-blue-500
        focus:outline-none
        border-b-2
      "
      :class="{
        'text-blue-500 border-b-2 font-medium border-blue-500':
          activeItem === 'tab2',
      }"
    >
      Private Messages
    </button> -->
  </nav>

  <!-- tab1 data -->
  <div class="float-left w-full mt-5" v-if="activeItem === 'tab1'" id="tab1">
    <!-- <h3 class="pb-3">Feedback History</h3> -->
    <div class="float-left w-full pb-3 border-b border-gray-200">
      <ReviewForm
      :initiative="selectedIdea?.parentInitiativeId"
        :selectedIdea="selectedIdea"
        :ideaPhaseIndex="selectedIdea?.phaseIndex" 
        :user="user"
        from="judging"
         @getAllIdeasCount="getAllIdeasCount"
         @openPanel="openPanel"
         @showToaster="showToaster"
      />
    </div>

    <!-- <div
      v-if="!isFeedsEmpty"
      class="mx-auto w-full float-left text-center my-20"
    >
      <img src="@/assets/images/no-feedback.png" class="w-1/2 mx-auto" />
      <h1 class="font-bold text-blue-500">No feedback has been provided yet</h1>
      <p class="text-xs">
        Once the judging team starts providing the <br />
        feedback you will be able to view it here.
      </p>
    </div>
   

    <div
      class="inline-block w-full bg-blue-400 rounded text-white mb-3 float-left"
    >
   
      <div v-if="parentInitiative?.parentInitiativeId?.metaData?.action" class="bg-blue-400">
        <div
          class="
            float-left
            w-full
            text-sm
            cursor-pointer
            p-2
            rounded
            bg-blue-400
          "
          @click="managerMsg = !managerMsg"
        >
          <div class="float-left mt-2 pr-2">
            <div
              v-if="parentInitiative?.parentInitiativeId?.metaData?.action?.icon === 'check'"
              class="bg-blue-500 text-white w-5 h-5 rounded-full"
            >
              <IconCheck />
            </div>
            <div
              v-if="parentInitiative?.parentInitiativeId?.metaData?.action?.icon === 'info'"
              class="bg-yellow-500 text-white w-5 h-5 rounded-full"
            >
              <IconExclimation />
            </div>
          </div>
          <div class="float-left">
            <div class="text-xs leading-3">Program Manager</div>
            <div class="text-lg font-semibold">
              {{ parentInitiative?.parentInitiativeId?.metaData?.action?.actionLabel }}
            </div>
          </div>

          <div class="float-right mt-2 ml-3">
            <icon-arrow-right v-if="managerMsg" />
            <icon-arrow-down v-if="!managerMsg" />
          </div>
          <div class="float-right mt-2">
            {{ timeago.format(new Date(parentInitiative?.parentInitiativeId?.metaData?.action?.date)) }}
          </div>
        </div>

        <div
          v-show="!managerMsg"
          class="
            border-t border-gray-200
            float-left
            w-full
            transition-all
            duration-500
            ease-in-out
            transform
          "
        >
          <div class="inline-block p-4">
            {{ parentInitiative?.parentInitiativeId?.metaData?.action?.comment }}
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="inline-block w-full rounded bg-gray-100 mb-3 float-left"
      v-for="(feed, index) in selectedIdea.activities[0].activities"
      :key="index"
    >
    
      <!-- <pre>{{ feed.metaData }}</pre> -->
      <div v-if="feed?.metaData?.updatedBy">
        <div
          class="float-left w-full text-sm cursor-pointer p-2 rounded"
          @click="openItem(feed)"
        >
          <div class="float-left mt-2 pr-2">
            <div
              v-if="feed.metaData.icon === 'check'"
              class="bg-blue-500 text-white w-5 h-5 rounded-full"
            >
              <IconCheck />
            </div>
            <div
              v-if="feed.metaData.icon === 'info'"
              class="bg-yellow-500 text-white w-5 h-5 rounded-full"
            >
              <IconExclimation />
            </div>
          </div>
          <div class="float-left">
            <div class="text-xs leading-3">{{ feed.name }}</div>
            <div class="text-lg font-semibold">{{ feed.metaData?.actionLabel }}</div>
          </div>

          <div class="float-right mt-2 ml-3">
            <icon-arrow-right v-if="feed.isopen" />
            <icon-arrow-down v-if="!feed.isopen" />
          </div>
          <div class="float-right mt-2">
            {{ timeago.format(new Date(feed?.metaData?.date)) }}
          </div>
        </div>

        <div
          v-show="!feed.isopen"
          class="border-t border-gray-200 float-left w-full"
        >
          <div class="inline-block p-4">
            {{ feed?.metaData?.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- tab2 data  -->
  <div class="float-left w-full" v-if="activeItem === 'tab2'" id="tab2">
    <!-- //startHere -->
    <div class="mt-5">
      <div class="font-semibold text-gray-700">Send Message To</div>
      <div class="leading-3">
        <!-- https://jsfiddle.net/xf0jzoct/ -->
        <!-- <Multiselect
          v-model="selectedUser"
          mode="tags"
          placeholder="Select employees"
          track-by="name"
          label="name"
          :close-on-select="false"
          :search="true"
          :options="allMembers"
        >
          <template v-slot:tag="{ option, handleTagRemove, disabled }">
            <div class="multiselect-tag is-user">
              <img :src="option.image" />
              {{ option.name }}
              <span
                v-if="!disabled"
                class="multiselect-tag-remove"
                @mousedown.prevent="handleTagRemove(option, $event)"
              >
                <span class="multiselect-tag-remove-icon"></span>
              </span>
            </div>
          </template>
        </Multiselect> -->

        <Multiselect
          class="block text-gray-700 text-base font-normal mb-2 z-100 multi-tag"
          mode="tags"
          trackBy="label"
          label="label"
          v-model="selectedUser"
          placeholder="Select "
          :options="allMembers"
          noOptionsText="User Not Found"
        ></Multiselect>
      </div>
    </div>
    <div class="mt-5">
      <div class="text font-semibold text-gray-700">Message</div>
      <!-- <div class="text-xs text-gray-500">
        Please tell us about your feedback
      </div> -->
      <div class="leading-3">
        <textarea
          name=""
          rows="6"
          placeholder="Please tell us about your feedback"
          v-model="privateMessageComment"
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-4
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-3
          "
          id="inline-full-name"
        >
        </textarea>
      </div>
    </div>
    <!-- save button start -->
    <div class="">
      <button
        :class="primaryBtn"
        @click="sendPrivateMessage"
        :disabled="
          privateMessageComment?.length === 0 || selectedUser?.length === 0
        "
      >
        Send
      </button>
    </div>

    <hr class="border border-gray-200 my-10" />
    <!-- comments startred -->
    <comments
      :comments="allComments"
      isParticipant="true"
      :initiativeId="selectedIdea?.parentInitiativeId"
      :user="user"
      @commentAdded="commentAdded"
      commentsFor="judgindDetails"
      :isWso="isWso"
    ></comments>
  </div>
  <!-- <pre> {{ selectedIdea }}</pre> -->
</template>
<script>
import * as timeago from "timeago.js";
import IconArrowRight from "../Icon/Arrow-Right.vue";
import IconArrowDown from "../Icon/Arrow-Down.vue";
import IconCheck from "../Icon/Check.vue";
import IconExclimation from "../Icon/Exclimation.vue";
import defaultImage from "../../assets/images/target2.jpg";
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import Comments from "../../components/IdeaSections/Comments.vue";
import ReviewForm from "./ReviewForm.vue";

export default {
  data() {
    return {
      timeago,
      managerMsg: false,
      activeItem: "tab1",
      currentInitiativeId: "",
      ideaTeamMembers: [],
      selectedUser: [],
      privateMessageComment: "",
      // comments: [],
    };
  },

  name: "FeedbackHistory",
  props: {
    selectedIdea: Object,
    selectedInitiative: Object,
    user: Object,
    parentInitiative: Object,
    allComments: Array,
  },
  components: {
    IconArrowRight,
    IconArrowDown,
    IconCheck,
    IconExclimation,
    Multiselect,
    Comments,
    ReviewForm,
  },
  created: async function () {
  },
  beforeUpdate: function () {},
  methods: {
    openItem: function (item) {
      item.isopen = !item.isopen;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    sendPrivateMessage: async function () {
      // return false;
      // return false;
      let payload = {
        user: this.user,
        initiative: this.selectedIdea,
        initiativeId: this.selectedIdea?.parentInitiativeId,
        comment: this.privateMessageComment,
        recipients: this.selectedUser,
      };
      await this.$store.dispatch("addComment", payload);
      this.privateMessageComment = "";
      this.selectedUser = [];
      await this.commentAdded();
    },
    userSelected: function (user) {},
    commentAdded: async function () {
      this.$emit("updateComments", this.selectedIdea?.parentInitiativeId);
    },
     async getAllIdeasCount() {
      this.$emit("getAllIdeasCount");
    },
    async openPanel(data) {
      this.$emit("openPanel", data);
    },
    async showToaster(data) {
      this.$emit("showToaster", data);
    },
    checkEnableJudging: function(){
      if (this.playbook?.hasOwnProperty("metaData") && this.playbook.metaData.enableJudging) {
        return true;
      } else if(this.playbook?.hasOwnProperty("metaData") && !this.playbook.metaData.enableJudging) {
        return false;
      } else if(!this.playbook?.hasOwnProperty("metaData")) {
        return true;
      }
    },
  
  },
  setClass: function (item) {
    if (item.isopen == true) {
      return "open";
    }
    return "close";
  },
  computed: {
    ...mapGetters(["playbook", "teamMembers", "initiatives", "challengeTypes","rankArrayByRevenue","rankArrayByActivations","rankArrayByRevenueHistory","rankArrayByActivationsHistory"]),
    isFeedsEmpty() {
      let judgeUpdates =
        this.selectedIdea.activities[0].activities.filter(
          (f) => f?.metaData?.updatedBy
        )?.length !== 0;

      let managerUpdate = this.selectedIdea?.metaData?.actionType;
      return judgeUpdates || managerUpdate;
    },
    primaryBtn() {
      return "inline-flex text-white bg-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-600 disabled:opacity-30 rounded";
    },
    allMembers() {
      let allTeamMembers = [
        ...this.parentInitiative?.parentInitiativeId?.metaData?.teamLead,
        ...this.parentInitiative?.parentInitiativeId?.metaData?.teamMembers,
        ...this.parentInitiative?.parentInitiativeId?.metaData?.mentors,
        ...this.parentInitiative?.parentInitiativeId?.metaData?.submitter,
      ];
      let key = "email";
      allTeamMembers = [
        ...new Map(allTeamMembers.map((item) => [item[key], item])).values(),
      ];
      allTeamMembers = allTeamMembers.filter((member) => {
        return member.email.toLowerCase() !== this.user.email.toLowerCase();
      });
      let result = allTeamMembers.map((data, i) => {
        return { key: i, value: data.accountUserId, label: data.name };
      });
      this.ideaTeamMembers = result;
      return result;
    },
    isWso() {
      return this.user.roles.includes("Manager");
    },
    showProgramUserText() {
      let reviewArray = this.selectedIdea?.metaData?.reviews?.filter(data => {
        return data.lastUpdatedBy === this.user.aspenifyUserId
      })
      return this.selectedIdea.status === 2 && !reviewArray?.length > 0
    },
    getRevenueRank(){
      let index;
      if(this.$route.params.status=='history'){
        if(!this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
        return object.id === this.selectedIdea._id;
        });
      } else {
        if(!this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
        return object.id === this.selectedIdea._id;
        });
      }      
      if(index===-1) return "NA"
      return index+1
    },
    getActivationRank(){
      
      let index;
      if(this.$route.params.status=='history'){
        if(!this.rankArrayByActivationsHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByActivationsHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
        return object.id === this.selectedIdea._id;
        });
      } else {
        if(!this.rankArrayByActivations[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByActivations[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
        return object.id === this.selectedIdea._id;
        });
      }
      if(index===-1) return "NA"
      return index+1
    },
    getRevenuePercentile(){
      let index
      let numerator
      let percentile
      if(this.$route.params.status=='history'){
        if(this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length===1)
        return "NA"
        if(!this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
          return object.id === this.selectedIdea._id;
        });
        numerator = this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length-index-1
        // percentile=(numerator/this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)*100
        let multiplyingFactor = 100/(this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType].length-1)
        let value=(this.rankArrayByRevenueHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)-(index+1)
        percentile=multiplyingFactor*value
      } else {
        if(!this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]) return "NA"
        index = this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
          return object.id === this.selectedIdea._id;
        });
        numerator = this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length-index-1
        if(this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length===1)
        return "NA"
        // percentile=(numerator/this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)*100      
        let multiplyingFactor = 100/(this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType].length-1)
        let value=(this.rankArrayByRevenue[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)-(index+1)
        percentile=multiplyingFactor*value
      }
      if(index===-1) return "NA"
      switch (Math.round(percentile)%10) {
        case 1:
        return Math.round(percentile)+"st"      
        case 2:
        return Math.round(percentile)+"nd"      
        case 3:
        return Math.round(percentile)+"rd"      
        default:
        return Math.round(percentile)+"th"      
      }
      return Math.round(percentile)+"th"
    },
    getActivationPercentile(){
      let index
      let numerator
      let percentile
      if(this.$route.params.status=='history'){
        index = this.rankArrayByActivationsHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
          return object.id === this.selectedIdea._id;
        });
        numerator = this.rankArrayByActivationsHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length-index-1
        percentile=(numerator/this.rankArrayByActivationsHistory[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)*100
      } else {
        index = this.rankArrayByActivations[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
          return object.id === this.selectedIdea._id;
        });
        numerator = this.rankArrayByActivations[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length-index-1
        percentile=(numerator/this.rankArrayByActivations[this.selectedIdea.parentInitiativeId.metaData.challengeType]?.length)*100      
      }
      if(index===-1) return "NA"
      switch (percentile) {
        case 1:
        return Math.round(percentile)+"st"      
        case 2:
        return Math.round(percentile)+"nd"      
        case 3:
        return Math.round(percentile)+"rd"      
        default:
        return Math.round(percentile)+"th"      
      }
      return Math.round(percentile)+"th"
    },
  },
};
</script>
<style scoped>
.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}
.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #35495e;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}

.user-image {
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 22px;
}
</style>
