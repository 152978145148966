<template>
  <div class="flex sticky top-0 ease-in-in duration-500 relative z-40">
    <!-- Backdrop -->
    <!-- <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="isOpen = false"
      class="z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    ></div> -->
    <!-- End Backdrop -->
    <!-- :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'" -->
    <div class="ease-in-in duration-500 bg-opacity-100 bg-gray-primary nav-bar">
      <nav class="">
        <!-- <div
          v-tooltip="{
            theme: {
              placement: 'right',
              theme: {},
            },
          }"
        >
          <button v-tooltip="'Home '">
            <router-link
              class="flex items-center duration-200 my-0 py-4 px-4"
              :class="[
                $route.path.startsWith('/home') ? activeClass : inactiveClass,
              ]"
              to="/home"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>

              <span class="mx-4" :class="showHideSidebar">Home</span>
            </router-link>
          </button>
        </div> -->
        <div class="">
          <div
            v-if="
              (!contractorAccess && !executiveAccess && (playbook?.createInitiative || this.user.isEtiEmployee))
            "
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'New Submission '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.path.startsWith('/submitIdea')
                    ? activeClass
                    : inactiveClass,
                ]"
                to="/submitIdea"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svgIcon"
                  width="24"
                  height="24"
                  viewBox="0 0 700 700"
                >
                  <g fill-rule="evenodd" class="color000 svgShape">
                    <path
                      d="m354.9 488.4c-117.4 0-212.91-95.52-212.91-212.91 0-117.4 95.504-212.92 212.91-212.92 117.4 0 212.91 95.52 212.91 212.92 0 117.39-95.504 212.91-212.91 212.91m0-486.37c-150.78 0-273.45 122.67-273.45 273.46 0 150.78 122.67 273.45 273.45 273.45 150.78 0 273.45-122.67 273.45-273.45 0-150.79-122.67-273.46-273.45-273.46"
                      class="color000 svgShape"
                    ></path>
                    <path
                      d="m377.6 125.57h-45.406v127.23h-127.23v45.402h127.23v127.23h45.406v-127.23h127.23v-45.402h-127.23z"
                      class="color000 svgShape"
                    ></path>
                  </g>
                </svg>

                <span class="mx-4" :class="showHideSidebar"
                  >New Submission</span
                >
              </router-link>
            </button>
          </div>
          <div
            v-if="
              !contractorAccess &&
              !executiveAccess
            "
            v-tooltip="{
              theme: {
                placement: 'right',
                theme: {
                  'z-index': 200000,
                  position: 'relative',
                },
              },
            }"
          >
            <button v-tooltip="'My Submissions '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.path.startsWith('/myideas')
                    ? activeClass
                    : inactiveClass,
                ]"
                to="/myideas"
              >
                <svg
                  class="svgIcon"
                  width="24"
                  height="24"
                  version="1.1"
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g>
                    <path
                      d="m256.48 380.07c-2.207-24.258-12.777-47.008-29.926-64.332-31.988-31.289-51.555-75.004-51.555-123.23 0-96.602 78.398-175 175-175s175 78.398 175 175c0 48.23-19.566 91.945-51.172 123.62-17.043 17.219-27.578 39.863-29.781 64.016-1.5781 11.375-2.6602 23.52-3.6406 34.297-1.6445 18.027-16.766 31.816-34.859 31.816h-111.09c-18.094 0-33.215-13.789-34.859-31.816-0.98047-10.777-2.0625-22.922-3.1133-34.367z"
                      fill-rule="evenodd"
                    />
                    <path
                      d="m428.74 507.48c0-9.6602-7.0781-17.484-15.805-17.484h-125.89c-8.7227 0-15.801 7.8242-15.801 17.484v17.531c0 9.6602 7.0781 17.484 15.801 17.484h125.89c8.7266 0 15.805-7.8242 15.805-17.484v-17.531z"
                      fill-rule="evenodd"
                    />
                  </g>
                </svg>

                <span class="mx-4" :class="showHideSidebar"
                  >My Submissions</span
                >
              </router-link>
            </button>
          </div>
          <div
            v-if="!isJudgeOrUser && !contractorAccess"
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'All Submissions '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 pl-5 pr-4"
                :class="[
                  $route.path.startsWith('/allideas')
                    ? activeClass
                    : inactiveClass,
                ]"
                to="/allideas"
              >
                <svg
                  class="svgIcon"
                  width="20"
                  height="20"
                  version="1.1"
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m315 210c0 19.32-15.664 35-35 35h-175c-19.336 0-35-15.68-35-35v-175c0-19.32 15.664-35 35-35h175c19.336 0 35 15.68 35 35zm315-175c0-19.32-15.664-35-35-35h-175c-19.336 0-35 15.68-35 35v175c0 19.32 15.664 35 35 35h175c19.336 0 35-15.68 35-35zm-315 315c0-19.32-15.664-35-35-35h-175c-19.336 0-35 15.68-35 35v175c0 19.336 15.664 35 35 35h175c19.336 0 35-15.664 35-35zm315 0c0-19.32-15.664-35-35-35h-175c-19.336 0-35 15.68-35 35v175c0 19.336 15.664 35 35 35h175c19.336 0 35-15.664 35-35z"
                  />
                </svg>

                <span class="mx-4" :class="showHideSidebar"
                  >All Submissions</span
                >
              </router-link>
            </button>
          </div>
          <div
            v-if="managerAccess || executiveAccess"
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'Progress '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.name === 'ProgressView' ? activeClass : inactiveClass,
                ]"
                to="/progressview"
              >
                <svg
                  class="svgIcon"
                  width="24"
                  height="24"
                  version="1.1"
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m266.88 463.23c0 18.754-10.008 36.086-26.25 45.465s-36.258 9.3789-52.5 0-26.25-26.711-26.25-45.465c0-18.758 10.008-36.09 26.25-45.469 16.242-9.3789 36.258-9.3789 52.5 0 16.242 9.3789 26.25 26.711 26.25 45.469zm52.5-297.5v35h227.5v-35zm0 175h227.5v-35h-227.5zm0 140h227.5v-35h-227.5zm-105-210c-18.914 0-36.391 10.09-45.844 26.469-9.457 16.379-9.457 36.559 0 52.938 9.4531 16.379 26.93 26.469 45.844 26.469 18.977-0.09375 36.461-10.305 45.867-26.785 9.4062-16.48 9.3047-36.727-0.26563-53.113-9.5664-16.387-27.152-26.422-46.125-26.328zm0-63.352-47.602-47.773c-7.8789 16.27-6.832 35.449 2.7656 50.766 9.6016 15.316 26.41 24.617 44.484 24.617 18.078 0 34.887-9.3008 44.484-24.617 9.6016-15.316 10.648-34.496 2.7656-50.766zm-37.102-61.949c10.148 10.324 26.773 26.426 37.273 36.926l113.05-112.35-24.676-25.551-86.102 86.102c-1.5039-0.26172-3.043-0.26172-4.5508 0-8.2227-8.0508-25.547-25.551-33.949-33.777l-24.676 25.727c5.25 4.375 18.375 17.5 23.625 22.926z"
                  />
                </svg>

                <span class="mx-4" :class="showHideSidebar">Progress</span>
              </router-link>
            </button>
          </div>
          <!-- <router-link
          v-if="userAccess"
          class="flex items-center duration-200 my-0 py-4 px-4 "
          :class="[
            $route.name === 'PlaybookLibrary' ? activeClass : inactiveClass,
          ]"
          to="/playbooks"
        >
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 512.001 512.001"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M368.692,393.365V20.981C368.69,9.393,359.297,0,347.71,0H75.695
C64.107,0,54.714,9.393,54.714,20.981v381.171c0,11.587,9.393,20.981,20.981,20.981h263.228
C355.363,423.133,368.692,409.805,368.692,393.365z M274.611,337.118H149.446c-11.296,0-21.048-8.679-21.545-19.964
c-0.528-11.979,9.028-21.857,20.89-21.857h125.164c11.296,0,21.048,8.679,21.545,19.964
C296.029,327.239,286.473,337.118,274.611,337.118z M274.611,273.43H149.446c-11.296,0-21.048-8.679-21.545-19.964
c-0.528-11.979,9.027-21.857,20.89-21.857h125.164c11.296,0,21.048,8.679,21.545,19.964
C296.029,263.552,286.473,273.43,274.611,273.43z M295.522,182.203c0,11.587-9.393,20.981-20.981,20.981h-125.68
c-11.587,0-20.981-9.393-20.981-20.981v-77.987c0-11.587,9.393-20.982,20.981-20.982h125.68c11.587,0,20.981,9.393,20.981,20.982
V182.203z"
                  />
                  <path
                    d="M436.376,88.867h-11.923c-7.699,0-13.941,6.241-13.941,13.941v299.415
c0,34.647-28.086,62.733-62.733,62.733H157.252c-7.699,0-13.941,6.241-13.941,13.941v12.194c0,11.548,9.363,20.911,20.911,20.911
h272.154c11.548,0,20.911-9.363,20.911-20.911V109.778C457.287,98.228,447.925,88.867,436.376,88.867z"
                  />
                </g>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>

          <span class="mx-4" :class="{'hidden' :openSideBar }">Playbook Library</span>
        </router-link> -->

          <div
            v-if="
              (judgeTeamMember || judgeTeamLead || managerAccess) &&
              !executiveAccess
            "
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'Judging '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.name === 'Judging' ? activeClass : inactiveClass,
                ]"
                to="/judging/toreview"
              >
                <svg
                  class="svgIcon"
                  width="24"
                  height="24"
                  version="1.1"
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g>
                    <path
                      d="m352.24 71.68c-148.4 0-268.8 91.281-268.8 204.4 0 43.68 18.48 84.559 49.281 117.6l-40.879 75.602c-5.6016 10.078 4.4805 20.719 15.121 16.801l98.559-39.762c42 20.719 92.961 33.039 146.72 33.039 148.4 0 268.8-91.281 268.8-204.4 0-113.12-120.4-203.28-268.8-203.28zm-86.238 193.76-23.52 22.398c-0.55859 0.55859-1.1211 1.6797-1.1211 2.8008l5.6016 33.602c0.55859 2.8008-2.2383 5.0391-5.0391 3.3594l-30.238-15.121c-1.1211-0.55859-2.2383-0.55859-3.3594 0l-28 15.121c-2.2383 1.1211-5.6016-0.55859-5.0391-3.3594l5.6016-33.039c0-1.1211 0-2.2383-1.1211-2.8008l-24.641-22.398c-1.6797-2.2383-0.55859-6.1602 2.2383-6.1602l33.039-5.6016c1.1211 0 2.2383-1.1211 2.2383-1.6797l15.121-30.238c1.1211-2.2383 5.0391-2.2383 6.1602 0l15.121 30.238c0.55859 1.1211 1.6797 1.6797 2.2383 1.6797l33.039 5.6016c2.8008 0.55469 3.918 3.918 1.6797 5.5977zm141.12 0-23.52 22.398c-0.55859 0.55859-1.1211 1.6797-1.1211 2.8008l5.6016 33.602c0.55859 2.8008-2.2383 5.0391-5.0391 3.3594l-29.121-15.121c-1.1211-0.55859-2.2383-0.55859-3.3594 0l-28.562 15.121c-2.2383 1.1211-5.6016-0.55859-5.0391-3.3594l5.6016-33.039c0-1.1211 0-2.2383-1.1211-2.8008l-24.641-22.398c-2.2383-2.2383-1.1211-6.1602 1.6797-6.1602l33.039-5.6016c1.1211 0 2.2383-1.1211 2.2383-1.6797l15.121-30.238c1.1211-2.2383 5.0391-2.2383 6.1602 0l15.121 30.238c0.55859 1.1211 1.6797 1.6797 2.2383 1.6797l33.039 5.6016c2.8008 0.55469 3.9219 3.918 1.6836 5.5977zm141.12 0-23.52 22.398c-0.55859 0.55859-1.1211 1.6797-1.1211 2.8008l5.6016 33.602c0.55859 2.8008-2.2383 5.0391-5.0391 3.3594l-30.238-15.121c-1.1211-0.55859-2.2383-0.55859-3.3594 0l-28.562 15.121c-2.2383 1.1211-5.6016-0.55859-5.0391-3.3594l5.6016-33.039c0-1.1211 0-2.2383-1.1211-2.8008l-24.641-22.398c-1.1211-2.2383 0-6.1602 2.8008-6.1602l33.039-5.6016c1.1211 0 2.2383-1.1211 2.2383-1.6797l15.121-30.238c1.1211-2.2383 5.0391-2.2383 6.1602 0l15.121 30.238c0.55859 1.1211 1.6797 1.6797 2.2383 1.6797l33.039 5.6016c2.8008 0.55469 3.9219 3.918 1.6797 5.5977z"
                    />
                  </g>
                </svg>

                <span class="mx-4" :class="showHideSidebar">Judging</span>
              </router-link>
            </button>
          </div>

          <div
            v-if="executiveAccess || managerAccess"
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'Dashboard '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.name === 'Dashboard' ? activeClass : inactiveClass,
                ]"
                to="/dashboard"
              >
                <svg
                  class="svgIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="noun-dashboard-1465995-FFFFFF"
                    d="M134.108,132.7l3.664-4.188a.375.375,0,0,0-.017-.512l-.254-.254a.375.375,0,0,0-.512-.017L132.8,131.4a2.248,2.248,0,1,0,1.306,1.306ZM132,144a12,12,0,1,1,12-12A12,12,0,0,1,132,144Zm-.375-21.372v.743a.371.371,0,0,0,.375.379.381.381,0,0,0,.375-.379v-.743a.371.371,0,0,0-.375-.379A.381.381,0,0,0,131.629,122.628Zm-7.879,8.622h-.743a.371.371,0,0,0-.379.375.381.381,0,0,0,.379.375h.743a.371.371,0,0,0,.379-.375A.381.381,0,0,0,123.75,131.25Zm17.25,0h-.743a.371.371,0,0,0-.379.375.381.381,0,0,0,.379.375H141a.371.371,0,0,0,.379-.375A.381.381,0,0,0,141,131.25ZM138.1,125l-.525.525a.371.371,0,0,0,0,.533.381.381,0,0,0,.533,0l.525-.525a.371.371,0,0,0,0-.533A.381.381,0,0,0,138.1,125Zm-11.667.525-.525-.525a.371.371,0,0,0-.533,0,.381.381,0,0,0,0,.533l.525.525a.371.371,0,0,0,.533,0A.381.381,0,0,0,126.433,125.524Zm8.7-2.317-.284.686a.371.371,0,0,0,.2.493.381.381,0,0,0,.491-.206l.284-.686a.371.371,0,0,0-.2-.493A.381.381,0,0,0,135.136,123.207Zm-10.578,4.95-.686-.284a.371.371,0,0,0-.493.2.381.381,0,0,0,.206.491l.686.284a.371.371,0,0,0,.493-.2.381.381,0,0,0-.206-.491Zm15.65-.284-.686.284a.371.371,0,0,0-.206.491.381.381,0,0,0,.493.2l.686-.284a.371.371,0,0,0,.206-.491A.381.381,0,0,0,140.208,127.873Zm-10.98-3.979-.284-.686a.371.371,0,0,0-.491-.206.381.381,0,0,0-.2.493l.284.686a.371.371,0,0,0,.491.206A.381.381,0,0,0,129.228,123.894Z"
                    transform="translate(-120.004 -120)"
                  />
                </svg>

                <span class="mx-4" :class="showHideSidebar">Dashboard</span>
              </router-link>
            </button>
          </div>

          <div
            v-if="executiveAccess || managerAccess"
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'Process Flow '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.name === 'IdeaProcess' ? activeClass : inactiveClass,
                ]"
                to="/processflow"
              >
                <svg
                  class="svgIcon"
                  width="24"
                  height="24"
                  version="1.1"
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g>
                    <path
                      d="m151.36 100.63c0-14.398-9.2227-26.641-22.07-31.188v-23.988c0-6.0977-4.9453-11.039-11.031-11.039-6.0859 0-11.039 4.9336-11.039 11.039v23.992c-12.848 4.5469-22.07 16.789-22.07 31.188 0 14.398 9.2227 26.641 22.07 31.188v114.2c-12.848 4.5469-22.07 16.789-22.07 31.188 0 14.398 9.2227 26.641 22.07 31.188v114.2c-12.848 4.5469-22.07 16.789-22.07 31.188 0 14.398 9.2227 26.641 22.07 31.188v23.984c0 6.1055 4.9453 11.039 11.039 11.039 6.0859 0 11.031-4.9336 11.031-11.039v-23.988c12.848-4.5469 22.07-16.789 22.07-31.188 0-14.398-9.2227-26.645-22.07-31.188v-114.2c12.848-4.5469 22.07-16.789 22.07-31.188 0-14.398-9.2227-26.641-22.07-31.188v-114.19c12.84-4.543 22.07-16.789 22.07-31.188zm441.42-44.145h-320.03s-14.785-1.3164-19.312 3.1914l-43.777 32.609c-4.5234 4.5078-4.5234 11.809 0 16.32l43.773 32.609c4.5312 4.5078 19.312 3.5547 19.312 3.5547h320.03c12.203 0 22.07-9.8711 22.07-22.07v-44.141c0.003906-12.195-9.8672-22.074-22.066-22.074zm0 176.57h-320.03s-14.785-1.3164-19.312 3.1875l-43.773 32.613c-4.5234 4.5039-4.5234 11.809 0 16.312l43.773 32.609c4.5312 4.5078 19.312 3.5625 19.312 3.5625h320.03c12.203 0 22.07-9.8711 22.07-22.07v-44.145c0-12.203-9.8711-22.07-22.07-22.07zm0 176.56h-320.03s-14.785-1.3086-19.312 3.1875l-43.777 32.613c-4.5234 4.5078-4.5234 11.809 0 16.32l43.773 32.609c4.5312 4.5078 19.312 3.5547 19.312 3.5547h320.03c12.203 0 22.07-9.8711 22.07-22.07v-44.141c0.003906-12.203-9.8672-22.074-22.066-22.074z"
                      fill-rule="evenodd"
                    />
                  </g>
                </svg>

                <span class="mx-4" :class="showHideSidebar">Process Flow</span>
              </router-link>
            </button>
          </div>
        </div>
        <div class="fixed bottom-0">
          <div
            v-if="managerAccess"
            v-tooltip="{ theme: { placement: 'right' } }"
          >
            <button v-tooltip="'Setting '">
              <router-link
                class="flex items-center duration-200 my-0 py-4 px-4"
                :class="[
                  $route.name === 'WebPages' ? activeClass : inactiveClass,
                ]"
                to="/setting/webpages"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-4" :class="showHideSidebar">Setting</span>
              </router-link>
            </button>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { defineComponent, ref } from "vue";
import { useSidebar } from "../hooks/useSidebar";

export default defineComponent({
  data() {
    return {};
  },
  props: {
    openSideBar: Boolean,
  },
  created:function(){
    if(!this.user){
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapGetters(["user","playbook"]),
    managerAccess() {
      return this.user && this.user.roles.includes("manager");
    },
    userAccess() {
      
      return this.user && this.user.roles.includes("user");
    },
    judgeTeamLead() {
      return this.user && this.user.roles.includes("judgeManager");
    },
    judgeTeamMember() {
      return this.user && this.user.roles.includes("judgeMember");
    },
    executiveAccess() {
      return this.user && this.user.roles.includes("executive");
    },
    contractorAccess() {
      return this.user && this.user.roles.includes("contractor");
    },
    isOnlyJudge() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember")))
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
    showHideSidebar() {
      return this.openSideBar
        ? " ease-in-in duration-100  transition-all whitespace-nowrap inline-block hover:text-blue-400"
        : " ease-in-in duration-100  transition-all whitespace-nowrap hidden ";
    },
  },
  setup() {
    const { isOpen } = useSidebar();
    const activeClass = ref("text-cisco-blue font-semibold fill-cisco-color");
    const inactiveClass = ref(
      "text-white font-semibold "
      // "border-blue-900 text-gray-300 hover:bg-gray-200 hover:bg-opacity-25 hover:text-gray-100"
    );

    return {
      isOpen,
      activeClass,
      inactiveClass,
    };
  },
});
</script>
<style scoped>
.nav-bar {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.svgIcon g {
}
.svgIcon path {
  fill: white;
}
.svgIcon:hover path {
  fill: #3ba4e0;
}
.fill-cisco-color .svgIcon path {
  fill: #3ba4e0;
}
</style>
