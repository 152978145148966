<template>
  <div
    class="border-b p-2 hover:bg-indigo-50 font-semibold text-sm cursor-pointer"
    @click="openEpic(epic)"
  >
    {{ epic.name }}
    <!-- \//name goes here -->
  </div>
  <div class="flex flex-col" v-show="epic.isEpicTabOpen">
    <jira-epic-detail-page :epic="epic" :epicRelatedIssues="epicRelatedIssues">
    </jira-epic-detail-page>
  </div>
</template>

<script>
import JiraEpicDetailPage from "./JiraEpicDetailPage.vue";
export default {
  name: "jira-epics-tabs",
  props: {
    epic: Object,
    stage: Object,
  },
  data() {
    return {
      epicRelatedIssues: {},
    };
  },
  components: {
    JiraEpicDetailPage,
  },
  created: async function () {
    let payload = {
      routeParameters: "/rest/agile/1.0/epic/" + this.epic.key + "/issue",
      integrationId: this.stage.integration._id,
    };
    this.epicRelatedIssues = await this.$store.dispatch("jiraAPI", payload);
  },
  methods: {
    openEpic: function (item) {
      item.isEpicTabOpen = !item.isEpicTabOpen;
    },
    setClass: function (item) {
      if (item.isEpicTabOpen == true) {
        return "open";
      }
      return "close";
    },
  },
};
</script>
<style scoped>
</style>