<template>
  <div class="pb-8">
    <section class="text-gray-600 body-font">
    <div
      class="
        container
        mx-auto
        flex
        px-5
        py-10
        items-center
        justify-center
        flex-col
      "
    >
      <!-- <div class="text-center lg:w-2/3 w-full pt-20">
        <p class="font-bold text-9xl p-6">403</p>
        <div class="flex justify-center">UNAUTHORIZED ACCESS</div>
      </div> -->

      <div class="w-2/6 p-10 rounded-3xl shadow-xl bg-white">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 345 270"
          fill="currentColor"
          style=""
        >
          <g xmlns="http://www.w3.org/2000/svg" id="Group_16986" data-name="Group 16986" transform="translate(-493.83 -183.265)">
            <g id="Group_16985" data-name="Group 16985" transform="translate(-918.17 -125.735)">
              <g id="Group_16980" data-name="Group 16980" transform="translate(1412 309)">
                <path id="Path_21392" data-name="Path 21392" d="M1787.4,501.023h-39.78A155.62,155.62,0,0,0,1735.188,393.6h28.21a2.937,2.937,0,0,0,2.938-2.937h0a2.937,2.937,0,0,0-2.937-2.937h-31.375a155.04,155.04,0,0,0-235.782-41.459h-47.733a2.937,2.937,0,0,0-2.937,2.937h0a2.937,2.937,0,0,0,2.937,2.937h41.255q-4.942,4.74-9.455,9.9h-65.37a2.937,2.937,0,0,0-2.937,2.937h0a2.937,2.937,0,0,0,2.937,2.937h60.483A155.218,155.218,0,0,0,1453.058,521.5h-7.74a2.937,2.937,0,0,0-2.937,2.937h0a2.937,2.937,0,0,0,2.937,2.937h10.23q1.6,3.574,3.379,7.05h-34.758a2.937,2.937,0,0,0-2.937,2.937h0a2.937,2.937,0,0,0,2.937,2.937h37.92a155.233,155.233,0,0,0,12.151,18.3h245.635a154.818,154.818,0,0,0,20.568-35.585h28.831a2.937,2.937,0,0,0,2.938-2.937h0a2.937,2.937,0,0,0-2.937-2.937h-26.55q1.839-5.04,3.334-10.239H1787.4a2.937,2.937,0,0,0,2.937-2.937h0A2.937,2.937,0,0,0,1787.4,501.023Z" transform="translate(-1412 -309)" fill="#f9f8f8"/>
                <g id="Group_16926" data-name="Group 16926" transform="translate(191.165 10.273)">
                  <g id="Group_16921" data-name="Group 16921" transform="translate(0 89.834)">
                    <path id="Path_21393" data-name="Path 21393" d="M2809.538,1783.775h-18.779a2.455,2.455,0,0,1,2.455-2.455h13.869a2.455,2.455,0,0,1,2.455,2.455Z" transform="translate(-2750.497 -1634.295)" fill="#f2f2f2"/>
                    <path id="Path_21394" data-name="Path 21394" d="M2827.143,1327.515h-9.882l3.212-91.537,2.158,1.435Z" transform="translate(-2772.55 -1180.49)" fill="#f2f2f2"/>
                    <g id="Group_16920" data-name="Group 16920" transform="translate(0)">
                      <path id="Path_21395" data-name="Path 21395" d="M2801.214,1783.775h-18.779a2.455,2.455,0,0,1,2.455-2.455h13.869a2.455,2.455,0,0,1,2.455,2.455Z" transform="translate(-2743.57 -1634.295)" fill="#f2f2f2"/>
                      <g id="Group_16919" data-name="Group 16919">
                        <g id="Group_16918" data-name="Group 16918">
                          <path id="Path_21396" data-name="Path 21396" d="M2838.373,1232.193l47.252,27.281-.516-2.686a2.215,2.215,0,0,0-.82-1.334l-30.87-23.9a2.216,2.216,0,0,0-1.991-.371l-6.193,1.85a2.216,2.216,0,0,1-1.743-.2l-4.116-2.376Z" transform="translate(-2790.119 -1175.895)" fill="#f2f2f2"/>
                          <path id="Path_21397" data-name="Path 21397" d="M2598.145,1230.457l-47.252,27.281,2.58.894a2.228,2.228,0,0,0,1.574-.043l36.12-14.782a2.23,2.23,0,0,0,1.324-1.547l1.491-6.278a2.229,2.229,0,0,1,1.054-1.415l4.112-2.374Z" transform="translate(-2550.893 -1175.895)" fill="#f2f2f2"/>
                          <path id="Path_21398" data-name="Path 21398" d="M2804.216,959.96V905.4l-2.066,1.788a2.223,2.223,0,0,0-.748,1.381l-5.259,38.677a2.223,2.223,0,0,0,.676,1.915l4.695,4.434a2.223,2.223,0,0,1,.7,1.616v4.75Z" transform="translate(-2754.961 -905.398)" fill="#f2f2f2"/>
                          <path id="Path_21399" data-name="Path 21399" d="M2820.427,1327.515h-9.07l3.212-91.537h2.646Z" transform="translate(-2767.637 -1180.49)" fill="#f2f2f2"/>
                          <circle id="Ellipse_123" data-name="Ellipse 123" cx="2.323" cy="2.323" r="2.323" transform="translate(45.46 57.215) rotate(-76.718)" fill="#f2f2f2"/>
                        </g>
                      </g>
                    </g>
                    <circle id="Ellipse_124" data-name="Ellipse 124" cx="2.118" cy="2.118" r="2.118" transform="translate(45.978 53.234)" fill="#f2f2f2"/>
                    <path id="Path_21400" data-name="Path 21400" d="M2551.73,1261.79l45.14-26.781-.041-.031-45.937,26.522Z" transform="translate(-2550.893 -1179.657)" fill="#f2f2f2"/>
                    <rect id="Rectangle_58623" data-name="Rectangle 58623" width="9.049" height="0.595" transform="translate(43.74 146.43)" fill="#f2f2f2"/>
                    <path id="Path_21401" data-name="Path 21401" d="M2840.353,905.98l.621,52.854.048-.02V905.4Z" transform="translate(-2791.767 -905.398)" fill="#f2f2f2"/>
                    <path id="Path_21402" data-name="Path 21402" d="M2891.974,1270.721l-45.763-25.7-.007.051,45.938,26.522Z" transform="translate(-2796.635 -1188.013)" fill="#f2f2f2"/>
                  </g>
                  <g id="Group_16925" data-name="Group 16925" transform="translate(26.213)">
                    <path id="Path_21403" data-name="Path 21403" d="M3121.146,1776.46h-30.064a3.93,3.93,0,0,1,3.93-3.93h22.2a3.93,3.93,0,0,1,3.93,3.93Z" transform="translate(-3026.624 -1537.146)" fill="#ececec"/>
                    <path id="Path_21404" data-name="Path 21404" d="M3149.332,1046h-15.821l5.142-146.548,3.455,2.3Z" transform="translate(-3061.93 -810.617)" fill="#ececec"/>
                    <g id="Group_16924" data-name="Group 16924" transform="translate(0 0)">
                      <path id="Path_21405" data-name="Path 21405" d="M3107.82,1776.46h-30.064a3.93,3.93,0,0,1,3.93-3.93h22.2a3.93,3.93,0,0,1,3.93,3.93Z" transform="translate(-3015.534 -1537.147)" fill="#f2f2f2"/>
                      <g id="Group_16923" data-name="Group 16923">
                        <g id="Group_16922" data-name="Group 16922">
                          <path id="Path_21406" data-name="Path 21406" d="M3167.312,893.393l75.649,43.676-.921-4.793a2.218,2.218,0,0,0-.821-1.336l-50.36-38.983a2.219,2.219,0,0,0-1.993-.371l-11.119,3.322a2.218,2.218,0,0,1-1.744-.2l-7.086-4.091Z" transform="translate(-3090.058 -803.261)" fill="#f2f2f2"/>
                          <path id="Path_21407" data-name="Path 21407" d="M2782.712,890.613l-75.649,43.676,4.61,1.6a2.223,2.223,0,0,0,1.569-.043l59.994-24.553,3.167-13.329,7.914-4.569Z" transform="translate(-2707.063 -803.261)" fill="#f2f2f2"/>
                          <path id="Path_21408" data-name="Path 21408" d="M3112.229,457.555V370.2l-3.689,3.193a2.225,2.225,0,0,0-.748,1.382l-8.579,63.1a2.224,2.224,0,0,0,.676,1.916l8.433,7.965a2.224,2.224,0,0,1,.7,1.617v8.18Z" transform="translate(-3033.373 -370.203)" fill="#f2f2f2"/>
                          <path id="Path_21409" data-name="Path 21409" d="M3138.579,1046h-14.52l5.142-146.548h4.236Z" transform="translate(-3054.065 -810.617)" fill="#f2f2f2"/>
                          <circle id="Ellipse_125" data-name="Ellipse 125" cx="3.719" cy="3.719" r="3.719" transform="translate(72.395 90.848) rotate(-67.5)" fill="#ececec"/>
                        </g>
                      </g>
                    </g>
                    <circle id="Ellipse_126" data-name="Ellipse 126" cx="3.391" cy="3.391" r="3.391" transform="translate(72.57 90.452) rotate(-67.5)" fill="#f2f2f2"/>
                    <path id="Path_21410" data-name="Path 21410" d="M2708.4,940.776l72.268-42.876-.065-.05-73.545,42.461Z" transform="translate(-2707.063 -809.283)" fill="#ececec"/>
                    <rect id="Rectangle_58624" data-name="Rectangle 58624" width="14.487" height="0.952" transform="translate(70.027 234.431)" fill="#ececec"/>
                    <path id="Path_21411" data-name="Path 21411" d="M3170.481,371.133l.994,84.619.076-.031V370.2Z" transform="translate(-3092.696 -370.203)" fill="#ececec"/>
                    <path id="Path_21412" data-name="Path 21412" d="M3253.125,955.074l-73.266-41.148-.011.081,73.545,42.461Z" transform="translate(-3100.49 -822.661)" fill="#ececec"/>
                  </g>
                </g>
                <g id="Group_16929" data-name="Group 16929" transform="translate(53.508 63.208)">
                  <g id="Group_16927" data-name="Group 16927" transform="translate(0 39.71)">
                    <rect id="Rectangle_58625" data-name="Rectangle 58625" width="35.201" height="75.714" rx="17.6" fill="#f2f2f2"/>
                    <path id="Path_21413" data-name="Path 21413" d="M1831.836,1203.7h-1.92V1085.1a.96.96,0,0,1,.96-.96h0a.96.96,0,0,1,.96.96Z" transform="translate(-1813.276 -1056.947)" fill="#e4e4e4"/>
                    <path id="Path_21414" data-name="Path 21414" d="M1806,1137.4l-5.339-9.248a.96.96,0,0,0-1.663.96l5.339,9.248Z" transform="translate(-1787.442 -1093.173)" fill="#e4e4e4"/>
                    <path id="Path_21415" data-name="Path 21415" d="M1831.579,1198.537l5.339-9.248a.96.96,0,0,0-1.663-.96l-5.339,9.248Z" transform="translate(-1813.276 -1143.251)" fill="#e4e4e4"/>
                  </g>
                  <g id="Group_16928" data-name="Group 16928" transform="translate(40.195)">
                    <rect id="Rectangle_58626" data-name="Rectangle 58626" width="44.7" height="96.147" rx="22.35" fill="#f2f2f2"/>
                    <path id="Path_21416" data-name="Path 21416" d="M2098.576,1043.106h-2.439V892.493a1.219,1.219,0,0,1,1.219-1.219h0a1.219,1.219,0,0,1,1.219,1.219Z" transform="translate(-2075.006 -856.746)" fill="#e4e4e4"/>
                    <path id="Path_21417" data-name="Path 21417" d="M2065.77,958.909l-6.78-11.744a1.219,1.219,0,1,0-2.112,1.219l6.78,11.744Z" transform="translate(-2042.201 -902.748)" fill="#e4e4e4"/>
                    <path id="Path_21418" data-name="Path 21418" d="M2098.249,1036.547l6.78-11.744a1.219,1.219,0,1,0-2.112-1.219l-6.78,11.744Z" transform="translate(-2075.006 -966.34)" fill="#e4e4e4"/>
                  </g>
                </g>
                <g id="Group_16937" data-name="Group 16937" transform="translate(61.003 216.469)">
                  <g id="Group_16932" data-name="Group 16932">
                    <g id="Group_16930" data-name="Group 16930" transform="translate(0 15.165)">
                      <path id="Path_21419" data-name="Path 21419" d="M1796.4,1700.373c1.026-.964,1.495-2.064,1.09-2.839-.5-.959-2.176-1.106-3.964-.431q.137-.35.254-.72c.953-3.043.409-5.922-1.216-6.431-1.583-.5-3.608,1.444-4.592,4.36-.815-3.342-2.907-5.7-4.7-5.276-1.689.4-2.517,3.117-1.973,6.233-2.474-1.092-4.862-1.006-5.623.312-.788,1.364.439,3.579,2.776,5.193a2.575,2.575,0,0,0-1.974,1.9c-.128,1.046.769,2.078,2.192,2.715a1.013,1.013,0,0,0-.054.6c.148.631.948,1.02,1.993,1.061v0h13.37v-.011a.587.587,0,0,0,.515-.317.465.465,0,0,0-.043-.38,1.523,1.523,0,0,0,1.39-.8,1.288,1.288,0,0,0-.244-1.175c1.805-.121,3.192-.912,3.236-1.93C1798.867,1701.553,1797.856,1700.743,1796.4,1700.373Z" transform="translate(-1775.434 -1688.988)" fill="#e4e4e4"/>
                    </g>
                    <g id="Group_16931" data-name="Group 16931" transform="translate(10.204)">
                      <path id="Path_21420" data-name="Path 21420" d="M1874.777,1619.577c1.886-1.773,2.748-3.8,2-5.221-.921-1.764-4-2.034-7.289-.792q.252-.643.466-1.324c1.752-5.6.751-10.891-2.236-11.826-2.912-.912-6.635,2.655-8.444,8.019-1.5-6.146-5.346-10.479-8.645-9.7-3.106.731-4.628,5.732-3.629,11.463-4.551-2.009-8.941-1.849-10.34.574-1.448,2.509.808,6.581,5.106,9.549-2.038.683-3.439,1.932-3.63,3.5-.235,1.924,1.414,3.821,4.031,4.993a1.862,1.862,0,0,0-.1,1.112c.273,1.16,1.743,1.876,3.665,1.952v.007h24.588v-.021a1.081,1.081,0,0,0,.947-.583.856.856,0,0,0-.079-.7,2.8,2.8,0,0,0,2.555-1.473,2.369,2.369,0,0,0-.449-2.161c3.32-.222,5.87-1.677,5.952-3.548C1879.322,1621.748,1877.462,1620.258,1874.777,1619.577Z" transform="translate(-1836.227 -1598.639)" fill="#f2f2f2"/>
                    </g>
                  </g>
                  <g id="Group_16933" data-name="Group 16933" transform="translate(210.066 3.56)">
                    <path id="Path_21421" data-name="Path 21421" d="M3030.915,1638.494c-1.68-1.579-2.447-3.38-1.785-4.649.82-1.571,3.563-1.811,6.491-.705q-.224-.572-.415-1.179c-1.56-4.983-.669-9.7,1.99-10.531,2.593-.812,5.909,2.365,7.52,7.14,1.334-5.473,4.76-9.331,7.7-8.64,2.766.651,4.121,5.1,3.231,10.208,4.052-1.789,7.962-1.647,9.208.511,1.29,2.234-.719,5.86-4.547,8.5,1.815.608,3.062,1.72,3.233,3.116.209,1.713-1.259,3.4-3.589,4.446a1.658,1.658,0,0,1,.088.99c-.243,1.033-1.552,1.671-3.264,1.738v.006h-21.894v-.019a.964.964,0,0,1-.844-.519.764.764,0,0,1,.071-.623,2.494,2.494,0,0,1-2.276-1.311,2.11,2.11,0,0,1,.4-1.924c-2.956-.2-5.228-1.493-5.3-3.16C3026.868,1640.427,3028.523,1639.1,3030.915,1638.494Z" transform="translate(-3026.929 -1619.85)" fill="#eaeaea"/>
                  </g>
                  <g id="Group_16934" data-name="Group 16934" transform="translate(237.428 21.215)">
                    <path id="Path_21422" data-name="Path 21422" d="M3191.549,1732.563c-.679-.638-.989-1.366-.721-1.879.331-.635,1.44-.732,2.623-.285q-.091-.231-.168-.476c-.63-2.014-.271-3.918.8-4.255,1.048-.328,2.387.955,3.038,2.885.539-2.211,1.923-3.77,3.11-3.491,1.117.263,1.665,2.062,1.306,4.125,1.637-.723,3.217-.665,3.72.206.521.9-.291,2.368-1.837,3.436a1.7,1.7,0,0,1,1.306,1.259c.085.692-.509,1.375-1.45,1.8a.669.669,0,0,1,.035.4c-.1.417-.627.675-1.319.7v0h-8.846v-.008a.389.389,0,0,1-.341-.209.308.308,0,0,1,.029-.251,1.008,1.008,0,0,1-.919-.53.851.851,0,0,1,.162-.778c-1.195-.08-2.112-.6-2.141-1.277C3189.914,1733.344,3190.583,1732.808,3191.549,1732.563Z" transform="translate(-3189.939 -1725.03)" fill="#e4e4e4"/>
                  </g>
                  <g id="Group_16935" data-name="Group 16935" transform="translate(128.825 5.264)">
                    <path id="Path_21423" data-name="Path 21423" d="M2546.68,1647.574c-1.583-1.488-2.307-3.186-1.683-4.382.773-1.481,3.358-1.707,6.118-.665q-.212-.539-.392-1.111c-1.471-4.7-.631-9.141,1.876-9.926,2.444-.765,5.569,2.229,7.088,6.731,1.258-5.159,4.487-8.8,7.256-8.144,2.607.613,3.885,4.811,3.046,9.622,3.82-1.686,7.5-1.552,8.679.482,1.216,2.105-.678,5.524-4.286,8.015,1.71.573,2.886,1.621,3.047,2.937.2,1.615-1.187,3.207-3.383,4.191a1.56,1.56,0,0,1,.083.934c-.229.974-1.463,1.575-3.076,1.638v.005h-20.637v-.018a.907.907,0,0,1-.795-.489.718.718,0,0,1,.067-.587,2.35,2.35,0,0,1-2.145-1.236,1.989,1.989,0,0,1,.377-1.814c-2.786-.187-4.927-1.407-5-2.978C2542.865,1649.4,2544.426,1648.146,2546.68,1647.574Z" transform="translate(-2542.923 -1630)" fill="#f2f2f2"/>
                  </g>
                  <g id="Group_16936" data-name="Group 16936" transform="translate(153.366 17.36)">
                    <path id="Path_21424" data-name="Path 21424" d="M2691.257,1712.02c-.9-.843-1.307-1.8-.953-2.482.438-.839,1.9-.967,3.465-.376q-.12-.306-.222-.63c-.833-2.66-.357-5.178,1.063-5.622,1.384-.434,3.155,1.262,4.015,3.812.712-2.922,2.542-4.982,4.11-4.613,1.477.347,2.2,2.725,1.725,5.45,2.163-.955,4.251-.879,4.916.273.689,1.193-.384,3.129-2.427,4.54a2.251,2.251,0,0,1,1.726,1.663c.112.915-.672,1.817-1.916,2.374a.886.886,0,0,1,.047.529c-.13.552-.829.892-1.742.928v0h-11.689v-.01a.514.514,0,0,1-.45-.277.408.408,0,0,1,.038-.332,1.331,1.331,0,0,1-1.215-.7,1.127,1.127,0,0,1,.214-1.028c-1.578-.106-2.791-.8-2.83-1.687C2689.1,1713.052,2689.98,1712.344,2691.257,1712.02Z" transform="translate(-2689.129 -1702.066)" fill="#e4e4e4"/>
                  </g>
                </g>
                <g id="Group_16938" data-name="Group 16938" transform="translate(131.359 63.263)">
                  <path id="Path_21425" data-name="Path 21425" d="M2728.694,917.353a1.386,1.386,0,0,0-.414.063c0-.069.008-.138.008-.208a2.913,2.913,0,0,0-5.717-.791,1.887,1.887,0,1,0-.513,3.7l.1,0v0h6.537a1.384,1.384,0,0,0,0-2.767Z" transform="translate(-2631.95 -875.958)" fill="#f2f2f2"/>
                  <path id="Path_21426" data-name="Path 21426" d="M2304.712,695.486a4.174,4.174,0,0,0-2.154.595,6.358,6.358,0,0,0-6.192-4.919,6.429,6.429,0,0,0-.784.05,8.99,8.99,0,0,0-17.193,3.675c0,.053,0,.106,0,.159a4.637,4.637,0,1,0-1.981,8.83h28.3a4.195,4.195,0,0,0,0-8.389Z" transform="translate(-2258.817 -685.898)" fill="#f2f2f2"/>
                  <path id="Path_21427" data-name="Path 21427" d="M2197.345,1306.854a2.747,2.747,0,0,1,1.417.391,4.182,4.182,0,0,1,4.073-3.236,4.252,4.252,0,0,1,.516.033,5.914,5.914,0,0,1,11.31,2.418c0,.035,0,.07,0,.1a3.05,3.05,0,1,1,1.3,5.808h-18.617a2.759,2.759,0,0,1,0-5.519Z" transform="translate(-2194.586 -1197.377)" fill="#f2f2f2"/>
                </g>
                <g id="Group_16979" data-name="Group 16979" transform="translate(57.977 208.287)">
                  <g id="Group_16946" data-name="Group 16946" transform="translate(255.543 24.657)">
                    <g id="Group_16943" data-name="Group 16943" transform="translate(0.557)">
                      <g id="Group_16942" data-name="Group 16942">
                        <g id="Group_16941" data-name="Group 16941">
                          <g id="Group_16940" data-name="Group 16940">
                            <g id="Group_16939" data-name="Group 16939">
                              <path id="Path_21428" data-name="Path 21428" d="M3288.371,1696.959l-.044.167a5.046,5.046,0,0,1-.4.985,3.611,3.611,0,0,1-3.546,2.093,5.039,5.039,0,0,1-1.056-.124l-.167-.042.044-.167a5.059,5.059,0,0,1,.4-.985,3.612,3.612,0,0,1,3.546-2.093,5.04,5.04,0,0,1,1.056.124Zm-4.786,2.824a3.532,3.532,0,1,0,1.636-2.2A4.219,4.219,0,0,0,3283.585,1699.784Z" transform="translate(-3283.155 -1696.792)" fill="#e4e4e4"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_16945" data-name="Group 16945" transform="translate(0 1.387)">
                      <g id="Group_16944" data-name="Group 16944">
                        <path id="Path_21429" data-name="Path 21429" d="M3283.212,1707.853a1.851,1.851,0,1,1,2.407-2.376A5.005,5.005,0,0,1,3283.212,1707.853Z" transform="translate(-3279.836 -1705.057)" fill="#f2f2f2"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_16954" data-name="Group 16954" transform="translate(249.693 5.632)">
                    <g id="Group_16951" data-name="Group 16951" transform="translate(0.447)">
                      <g id="Group_16950" data-name="Group 16950">
                        <g id="Group_16949" data-name="Group 16949">
                          <g id="Group_16948" data-name="Group 16948">
                            <g id="Group_16947" data-name="Group 16947">
                              <path id="Path_21430" data-name="Path 21430" d="M3253.011,1583.455l0,.215a6.249,6.249,0,0,1-.187,1.31,4.492,4.492,0,0,1-3.646,3.6,6.289,6.289,0,0,1-1.312.171h-.215l0-.215a6.3,6.3,0,0,1,.188-1.31,4.493,4.493,0,0,1,3.646-3.6,6.306,6.306,0,0,1,1.312-.17Zm-4.92,4.857a4.393,4.393,0,1,0,1.307-3.154A5.247,5.247,0,0,0,3248.091,1588.312Z" transform="translate(-3247.649 -1583.454)" fill="#e4e4e4"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_16953" data-name="Group 16953" transform="translate(0 1.905)">
                      <g id="Group_16952" data-name="Group 16952">
                        <path id="Path_21431" data-name="Path 21431" d="M3249.117,1598.47a2.3,2.3,0,1,1,2.185-3.6A6.227,6.227,0,0,1,3249.117,1598.47Z" transform="translate(-3244.984 -1594.803)" fill="#f2f2f2"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_16962" data-name="Group 16962" transform="translate(32.752)">
                    <g id="Group_16959" data-name="Group 16959">
                      <g id="Group_16958" data-name="Group 16958">
                        <g id="Group_16957" data-name="Group 16957">
                          <g id="Group_16956" data-name="Group 16956">
                            <g id="Group_16955" data-name="Group 16955">
                              <path id="Path_21432" data-name="Path 21432" d="M1953.745,1549.9l.136.116a5.234,5.234,0,0,1,.736.82,3.744,3.744,0,0,1,.348,4.255,5.242,5.242,0,0,1-.593.929l-.115.137-.136-.116a5.224,5.224,0,0,1-.736-.82,3.743,3.743,0,0,1-.347-4.255,5.244,5.244,0,0,1,.593-.929Zm.469,5.741a3.661,3.661,0,1,0-1.315-2.523A4.371,4.371,0,0,0,1954.214,1555.639Z" transform="translate(-1952.534 -1549.898)" fill="#e4e4e4"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_16961" data-name="Group 16961" transform="translate(1.022 0.733)">
                      <g id="Group_16960" data-name="Group 16960">
                        <path id="Path_21433" data-name="Path 21433" d="M1961.475,1557.582a1.918,1.918,0,1,1-1.127-3.32A5.189,5.189,0,0,1,1961.475,1557.582Z" transform="translate(-1958.624 -1554.262)" fill="#f2f2f2"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_16970" data-name="Group 16970" transform="translate(0 19.896)">
                    <g id="Group_16967" data-name="Group 16967" transform="translate(0 0.446)">
                      <g id="Group_16966" data-name="Group 16966">
                        <g id="Group_16965" data-name="Group 16965">
                          <g id="Group_16964" data-name="Group 16964">
                            <g id="Group_16963" data-name="Group 16963">
                              <path id="Path_21434" data-name="Path 21434" d="M1757.407,1671.1l.15-.01a4.418,4.418,0,0,1,.925.062,3.148,3.148,0,0,1,2.707,2.356,4.4,4.4,0,0,1,.188.907l.011.15-.15.01a4.416,4.416,0,0,1-.925-.062,3.147,3.147,0,0,1-2.707-2.356,4.412,4.412,0,0,1-.188-.907Zm3.653,3.179a3.078,3.078,0,1,0-2.272-.746A3.674,3.674,0,0,0,1761.06,1674.28Z" transform="translate(-1757.407 -1671.087)" fill="#e4e4e4"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_16969" data-name="Group 16969" transform="translate(1.341)">
                      <g id="Group_16968" data-name="Group 16968">
                        <path id="Path_21435" data-name="Path 21435" d="M1768.037,1669.764a1.613,1.613,0,1,1-2.627-1.336A4.363,4.363,0,0,1,1768.037,1669.764Z" transform="translate(-1765.398 -1668.428)" fill="#f2f2f2"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_16978" data-name="Group 16978" transform="translate(210.464 15.319)">
                    <g id="Group_16975" data-name="Group 16975" transform="translate(0 0.45)">
                      <g id="Group_16974" data-name="Group 16974">
                        <g id="Group_16973" data-name="Group 16973">
                          <g id="Group_16972" data-name="Group 16972">
                            <g id="Group_16971" data-name="Group 16971">
                              <path id="Path_21436" data-name="Path 21436" d="M3011.271,1643.845l.169,0a4.954,4.954,0,0,1,1.034.111,3.531,3.531,0,0,1,2.927,2.765,4.937,4.937,0,0,1,.17,1.026l.005.169-.169,0a4.915,4.915,0,0,1-1.034-.111,3.532,3.532,0,0,1-2.927-2.765,4.924,4.924,0,0,1-.17-1.026Zm3.951,3.731a3.453,3.453,0,1,0-2.514-.94A4.124,4.124,0,0,0,3015.222,1647.576Z" transform="translate(-3011.271 -1643.84)" fill="#e4e4e4"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_16977" data-name="Group 16977" transform="translate(1.506)">
                      <g id="Group_16976" data-name="Group 16976">
                        <path id="Path_21437" data-name="Path 21437" d="M3023.159,1642.78a1.81,1.81,0,1,1-2.885-1.618A4.9,4.9,0,0,1,3023.159,1642.78Z" transform="translate(-3020.242 -1641.162)" fill="#f2f2f2"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_16983" data-name="Group 16983" transform="translate(1464.468 428.846)">
                <g id="Group_16981" data-name="Group 16981" transform="translate(75.169 0)">
                  <path id="Path_21438" data-name="Path 21438" d="M2345.578,1142.446H2267.54l1.42-5.141,2.773-10.038,1.679-6.078,2.476-8.961,4.772-17.274,2.476-8.961,17.4-63h12.04l6.378,23.087L2329.982,1086l2.476,8.961,4.773,17.274,2.476,8.961,4.452,16.115Z" transform="translate(-2251.572 -1023)" fill="#b6d0fb"/>
                  <path id="Path_21439" data-name="Path 21439" d="M2345.578,1256.9H2267.54l1.419-5.141,2.773-10.038c54.38-3.212,47.225-81.181,47.225-81.181l25.2,91.218Z" transform="translate(-2251.573 -1137.458)" fill="#9badfb"/>
                  <rect id="Rectangle_58627" data-name="Rectangle 58627" width="109.972" height="10.456" transform="translate(0 119.446)" fill="#b6d0fb"/>
                  <path id="Path_21440" data-name="Path 21440" d="M2345.578,1709.131H2267.54l1.42-5.141h75.2Z" transform="translate(-2251.572 -1589.685)" fill="#6576ff"/>
                  <path id="Path_21441" data-name="Path 21441" d="M2449.745,1451.686s-1.635,34.489-41.26,42.378l52.96-.028Z" transform="translate(-2368.86 -1379.73)" fill="#5e9bff"/>
                  <path id="Path_21442" data-name="Path 21442" d="M2397.5,1407.262h-51.8l2.476-8.961h46.846Z" transform="translate(-2316.617 -1335.306)" fill="#fff"/>
                  <path id="Path_21443" data-name="Path 21443" d="M2368.817,1563.557h-66.294L2305,1554.6h61.343Z" transform="translate(-2280.683 -1465.367)" fill="#fff"/>
                  <path id="Path_21444" data-name="Path 21444" d="M2336.108,1734.616s-14.577,11.005-68.568,10.456h94v-10.456Z" transform="translate(-2251.573 -1615.17)" fill="#9badfb"/>
                </g>
                <g id="Group_16982" data-name="Group 16982" transform="translate(0 129.766)">
                  <rect id="Rectangle_58628" data-name="Rectangle 58628" width="147.355" height="3.586" rx="1.793" transform="translate(55.71)" fill="#b6d0fb"/>
                  <rect id="Rectangle_58629" data-name="Rectangle 58629" width="26.825" height="3.586" rx="1.793" transform="translate(35.148)" fill="#b6d0fb"/>
                  <rect id="Rectangle_58630" data-name="Rectangle 58630" width="28.004" height="3.586" rx="1.793" fill="#b6d0fb"/>
                  <rect id="Rectangle_58631" data-name="Rectangle 58631" width="23.621" height="3.586" rx="1.793" transform="translate(210.495)" fill="#b6d0fb"/>
                  <rect id="Rectangle_58632" data-name="Rectangle 58632" width="18.812" height="3.586" rx="1.793" transform="translate(241.56)" fill="#b6d0fb"/>
                </g>
              </g>
            </g>
              <text id="_404" data-name="404" transform="translate(627 268)" fill="#405b91" font-size="62" font-family="Muli-SemiBold, Muli" font-weight="600"><tspan x="0" y="0">404</tspan></text>
          </g>
        </svg>
         <h1 class="text-4xl text-red-300 text-center font-medium py-2">Oops! Page not found</h1> 
          <p class="mt-3 text-center text-gray-700">
            <span v-if="message">
              {{ message }}
            </span>
            <span v-else>
             The page you are looking for does not exist. It might have been moved or deleted.
            </span>
          </p>

          <div class="text-center pt-10">

            <button
              class="
                bg-indigo-500
                text-white
                font-semibold
                px-6
                py-3
                rounded-md
              "
              @click="$router.push({ name: 'Home' })"
            >
              Home
            </button>
            
            <!-- <button
              class="
                bg-indigo-500
                text-white
                font-semibold
                px-6
                py-3
                rounded-md
                float-left
              "
              @click="$router.push({ name: 'SubmissionLibrary' })"
            >
              All Ideas
            </button>
            <button
              class="
                bg-indigo-500
                text-white
                font-semibold
                px-6
                py-3
                rounded-md
                float-right
              "
              @click="$router.push({ name: 'MyIdeas' })"
            >
              My Ideas
            </button> -->
          </div>
      </div>
    </div>
  </section>
  </div>
</template>
<script >
export default {
  props: {
    message: String,
  },
};
</script>