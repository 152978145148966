<template>
  <div class="px-6 py-6">
    Setting
  </div>
</template>

<script >


export default {
  data() {
    return {};
  },
  methods: {

  },
};
</script>
<style scoped>

</style>
