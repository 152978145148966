<template>
  <!-- <pre>{{like}}</pre> -->
  <!-- <pre>{{idea.ideaFollowedBy}}</pre> -->
  <div
    v-if="like"
    class="
      rounded-full
      border
      flex
      float-left
      ml-1
      cursor-pointer
      hover:border hover:border-blue-600 hover:bg-blue-50
    "
    @click.stop="liked(idea)"
    v-tooltip="{
      text: 'Like',
      theme: {
        placement: 'left',
      },
    }"
  >
    <div
      class="rounded-full border px-1 py-1 transition-all duration-500"
      :class="{
        'bg-blue-600':idea?.ideaLikedBy?.filter((f) => {
                return f.email?.toLowerCase() === user.email?.toLowerCase();
              }).length > 0
            ,
      }"
    >
      <icon-like
        :fill="
          idea?.ideaLikedBy?.filter((f) => {
            return f.email?.toLowerCase() === user.email?.toLowerCase();
          }).length > 0
            ? '#ffffff'
            : '#666666'
        "
      />
    </div>
    <div
      v-if="idea?.ideaLikedBy?.length !== 0 && likeCount"
      class="rounded-full inline-block h-7 pl-2 pr-3 py-1 font-semibold"
    >
      {{ idea?.ideaLikedBy?.length }}
    </div>
  </div>
  <div
    v-if="follow"
    class="
      rounded-full
      border
      flex
      float-left
      ml-1
      hover:border hover:border-blue-600
      cursor-pointer
    "
    @click.stop="followClick(idea)"
    v-tooltip="{
      text: 'Follow',
      theme: {
        placement: 'left',
      },
    }"
  >
    <div
      class="rounded-full border px-1 py-1 transition-all duration-500"
      :class="{
        'bg-blue-600':
           idea?.ideaFollowedBy?.filter((f) => {
                return f.email?.toLowerCase() === user.email?.toLowerCase();
              }).length > 0
            
      }"
    >
      <icon-follow
        :fill="
          idea?.ideaFollowedBy?.filter((f) => {
                return f.email?.toLowerCase() === user.email?.toLowerCase();
              }).length > 0
           
            ? '#ffffff'
            : '#666666'
        "
      />
    </div>
    <div
      v-if="idea?.ideaFollowedBy?.length !== 0 && followCount"
      class="rounded-full inline-block h-7 pl-2 pr-3 py-1 font-semibold"
    >
      {{ idea?.ideaFollowedBy?.length }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconLike from "../components/Icon/Like.vue";
import IconFollow from "../components/Icon/Follow.vue";
export default {
  data() {
    return {};
  },
  components: {
    IconLike,
    IconFollow,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    liked: function (idea) {
      let index = idea.ideaLikedBy.findIndex(
        (item) => item.email?.toLowerCase() === this.user.email?.toLowerCase()
      );
      if (index >= 0) {
        idea.ideaLikedBy.splice(index, 1);
        this.$store.dispatch("unlikedIdea", idea);
      } else {
        idea.ideaLikedBy.push({
          email: this.user.email?.toLowerCase(),
          profilePic: this.user.profilePic,
          _id: this.user.aspenifyUserId,
        });
        this.$store.dispatch("likedIdea", idea);
      }
    },
    followClick: function (idea) {
      let index = idea.ideaFollowedBy.findIndex(
        (item) => item.email?.toLowerCase() === this.user.email?.toLowerCase()
      );
      if (index >= 0) {
        idea.ideaFollowedBy.splice(index, 1);
        this.$store.dispatch("unfollowedIdea", idea);
      } else {
        idea.ideaFollowedBy.push({
          email: this.user.email?.toLowerCase(),
          profilePic: this.user.profilePic,
          _id: this.user.aspenifyUserId,
        });
        this.$store.dispatch("followedIdea", idea);
      }
    },
  },
  props: {
    idea: Object,
    like: Boolean,
    follow: Boolean,
    likeCount: Boolean,
    followCount: Boolean,
  },
};
</script>