<template>
  <div :key="componentKey">
    <div v-if="inReview" class="inline-block" >
      <ul class="list-none statusCircle">
        <li
          v-tooltip="{
            text: team?.raci?.a?.teamId?.name || team.name,
            theme: {
              placement: 'left',
            },
          }"
          v-for="(team, index) in teams"
          :key="index"
          :class="{}"
        >
          <div
            class="bg-gray-300 w-5 h-5 rounded-full"
            v-if="!team.metaData"
          ></div>
          <div
            v-if="team?.metaData?.icon === 'check'"
            class="bg-blue-500 text-white w-5 h-5 rounded-full mr-2"
          >
            <IconCheck />
          </div>
          <div
            v-if="team?.metaData?.icon === 'info'"
            class="bg-yellow-500 text-white w-5 h-5 rounded-full mr-2"
          >
            <IconExclimation />
          </div>

          <!-- <IconCheck v-if="team.metaData" /> -->
          <!-- <IconExclimation  /> -->
        </li>
      </ul>
    </div>

    <div
    v-else
      class="
        z-10
        text-white text-xs
        antialiased
        rounded-full
        tracking-wide
        shadow-2xl
        inline-block
      "
    >
      <div class="bg-blue-500 px-3 rounded-full py-1.5" v-if="reviewed">
        Reviewed
      </div>
      <div class="bg-yellow-500 px-3 rounded-full py-1.5" v-if="cancelled">
        Cancelled
      </div>
      <div class="bg-blue-500 px-3 rounded-full py-1.5" v-if="closed">
        Judging ended
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "../components/Icon/Check.vue";
import IconExclimation from "../components/Icon/Exclimation.vue";
export default {
  name: "StatusTrack",
  data() {
    return {
      componentKey: 0,
    };
  },
  props: {
    teams: Array,
    initiative: Object,
  },
  watch: {
    initiative() {
      this.componentKey += 1;
    },
  },
  computed: {
    inReview() {
      return this.initiative?.metaData?.action?.actionType === "start";
    },
    reviewed() {
      return this.initiative?.metaData?.action?.actionType === "save";
    },
    cancelled() {
      return this.initiative?.metaData?.action?.actionType === "cancel";
    },
    closed() {
      return this.initiative?.metaData?.action?.actionType === "end";
    },
    inactive() {
      return this.initiative?.metaData?.isJudgingActive ? true : false;
    },
    // end() {
    //   return this.subInitiatives &&  this.subInitiatives?.filter((f) => {
    //     return f.status === 3;
    //   })?.length;
    // },
    // cancel() {
    //   return this.subInitiatives && this.subInitiatives?.filter((f) => {
    //     return f.status === 6;
    //   })?.length;
    // },
  },
  methods: {},
  components: {
    IconCheck,
    IconExclimation,
  },
};
</script>


<style scoped>
.statusCircle {
  display: inline-flex;

  padding: 4px;
  background: #eaeaea;
  border-radius: 500em;
}
.statusCircle li {
  display: inline-block;
  padding: 0px;
  border-radius: 500em;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0 1px;
}
</style>
