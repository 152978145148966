<template>
  <div class="mb-10">
   
    <h3 class="font-bold mb-2">Management</h3>

    <div>
      <div class="flex">
        <div class="flex-grow grid grid-cols-2 gap-4">
          <div>
            <div class="text-gray-400 text-sm font-bold">Ops POC</div>
            <div class="" v-if="editField === 'opsPOC'">
              <div class="w-full">
                <autocompletesingleselect
                  :suggestions="usersByDisplayName"
                  :selection.sync="value1"
                  :autoSave="true"
                  :loaderFlag="loaderFlag"
                  @autoSaveSuggestion="autoSaveSuggestion"
                  :dropdownVisibleTo="dropdownVisibleTo"
                  @displayNameFilterSearch="displayNameFilterSearch"
                  @cleared="closeSearch"
                  @enableSave="enableSave"
                />
              </div>
            </div>
            <div
              class="flex py-2 upppercase text-gray-700"
              v-else
              :class="isEditAccess ? hoverClassDropdown : ''"
              @click="isEditAccess ? editMe('opsPOC') : null"
            >
              <div
                class="
                  flex
                  items-center
                  w-full
                  group
                  bg-white
                  p-2
                  rounded
                  shadow-lg
                "
                v-if="formData?.opsPOC?.name"
              >
                <div
                  class="flex-shrink-0 h-8 w-8"
                  v-if="formData?.opsPOC?.image"
                >
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="formData?.opsPOC?.image"
                    alt=""
                  />
                </div>
                <div
                  class="
                    w-8
                    h-8
                    rounded-full
                    bg-gray-100
                    text-center
                    place-items-center
                    grid
                  "
                  v-else
                >
                  {{ formData?.opsPOC?.name.charAt(0).toUpperCase() }}
                </div>
                <div class="ml-2 hover:cursor-pointer flex-grow">
                  <div class="text-sm font-medium text-gray-900">
                    {{ formData?.opsPOC?.name }}
                  </div>
                </div>
                <div
                  class="
                    hover:bg-gray-300
                    rounded-full
                    p-1
                    cursor-pointer
                    hidden
                    group-hover:block
                  "
                  @click.prevent="removeUser('opsPOC')"
                  v-if="initiative.status !== 6"
                >
                  <close />
                </div>
              </div>
              <div v-else>NA</div>
            </div>
          </div>
          <div>
            <div class="text-gray-400 text-sm font-bold">PM POC</div>
            <div class="" v-if="editField === 'pmPOC'">
              <div class="w-full">
                <autocompletesingleselect
                  :suggestions="usersByDisplayName"
                  :selection.sync="value1"
                  :autoSave="true"
                  :loaderFlag="loaderFlag"
                  @autoSaveSuggestion="autoSaveSuggestion"
                  :dropdownVisibleTo="dropdownVisibleTo"
                  @displayNameFilterSearch="displayNameFilterSearch"
                  @cleared="closeSearch"
                  @enableSave="enableSave"
                />
              </div>
            </div>
            <div
              class="flex py-2 upppercase text-gray-700"
              v-else
              :class="isEditAccess ? hoverClassDropdown : ''"
              @click="isEditAccess ? editMe('pmPOC') : null"
            >
              <div
                class="
                  flex
                  items-center
                  w-full
                  group
                  bg-white
                  p-2
                  rounded
                  shadow-lg
                "
                v-if="formData?.pmPOC?.name"
              >
                <div
                  class="flex-shrink-0 h-8 w-8"
                  v-if="formData?.pmPOC?.image"
                >
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="formData?.pmPOC?.image"
                    alt=""
                  />
                </div>
                <div
                  class="
                    w-8
                    h-8
                    rounded-full
                    bg-gray-100
                    text-center
                    place-items-center
                    grid
                  "
                  v-else
                >
                  {{ formData?.pmPOC?.name.charAt(0).toUpperCase() }}
                </div>
                <div class="ml-2 hover:cursor-pointer flex-grow">
                  <div class="text-sm font-medium text-gray-900">
                    {{ formData?.pmPOC?.name }}
                  </div>
                </div>
                <div
                  class="
                    hover:bg-gray-300
                    rounded-full
                    p-1
                    cursor-pointer
                    hidden
                    group-hover:block
                  "
                  @click.prevent="removeUser('pmPOC')"
                  v-if="initiative.status !== 6"
                >
                  <close />
                </div>
              </div>
              <div v-else>NA</div>
            </div>
          </div>
          <div class="col-span-2">
            <div>
              <div class="text-gray-400 text-sm font-bold">
                Recommended BU/Function
              </div>
              <div class="" v-if="editField === 'BUFUnction'">
                <div class="w-full">
                  <inline-edit
                    component="dropdown"
                    :value="formData.buAction"
                    @editMe="editMe"
                    label="buAction"
                    @updateValue="updateValue"
                    :options="BUAction"
                  >
                  </inline-edit>
                </div>
              </div>
              <div
                class="block py-2 upppercase text-gray-700"
                v-else
                :class="isEditAccess ? hoverClassDropdown : ''"
                @click="isEditAccess ? editMe('BUFUnction') : null"
              >
                <span
                  v-for="type in formData.buAction"
                  :key="type"
                  class="
                    mr-1
                    inline-block
                    px-2
                    py-1
                    leading-none
                    border border-blue-500
                    text-blue-500
                    rounded-full
                    font-semibold
                    tracking-wide
                    text-xs
                  "
                  >{{ type }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="text-gray-400 text-sm font-bold">Progress</div>
            <div class="" v-if="editField === 'Progress'">
              <div class="w-full">
                <inline-edit
                  tags="false"
                  component="dropdown"
                  :value="formData.progress"
                  @editMe="editMe"
                  label="progress"
                  @updateValue="updateValue"
                  :options="progressList"
                >
                </inline-edit>
              </div>
            </div>
            <div
              class="flex py-2 upppercase text-gray-700"
              v-else
              :class="isEditAccess ? hoverClassDropdown : ''"
              @click="isEditAccess ? editMe('Progress') : null"
            >
              <span>
                <span class="capitalize">{{ formData.progress }}</span>
              </span>
            </div>
          </div>
          <div class="col-span-2">
            <div class="text-gray-400 text-sm font-bold">Action items</div>
            <div class="" v-if="editField === 'item'">
              <div class="w-full">
                <inline-edit
                  len="2000"
                  component="textarea"
                  :value="formData.actionItem"
                  @editMe="editMe"
                  label="actionItem"
                  @updateValue="updateValue"
                >
                </inline-edit>
              </div>
            </div>
            <div
              class="flex py-2 upppercase text-gray-700"
              v-else
              :class="isEditAccess ? hoverClass : ''"
              @click="isEditAccess ? editMe('item') : null"
            >
              <RichText :text="formData.actionItem" />
            </div>
          </div>
          <div class="col-span-2">
            <div class="text-gray-400 text-sm font-bold">Notes</div>
            <div v-if="editField === 'Notes'">
              <inline-edit
                len="2000"
                component="textarea"
                :value="formData.notes"
                @editMe="editMe"
                label="notes"
                @updateValue="updateValue"
              >
              </inline-edit>
            </div>
            <div
              v-else
              :class="isEditAccess ? hoverClass : ''"
              @click="isEditAccess ? editMe('Notes') : null"
              class="mt-2 text-base text-gray-600 py-1"
            >
              <RichText :text="formData.notes" />
            </div>
          </div>
        </div>
        <div class="pl-10">
          <div class="flex text-gray-400 text-sm font-bold w-80">
            <div class="flex-grow">Exec Sponsor</div>
            <div>
              <button
                class="
                  text-base
                  flex
                  justify-center
                  font-bold
                  cursor-pointer
                  duration-200
                  ease-in-out
                  transition
                "
                @click="isEditAccess ? editMe('execSponsors') : null"
                v-if="editField !== 'execSponsors'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="" v-if="editField === 'execSponsors'">
            <div class="w-full">
              <autocompletesingleselect
                :suggestions="usersByDisplayName"
                :selection.sync="value1"
                :autoSave="true"
                :loaderFlag="loaderFlag"
                @autoSaveSuggestion="autoSaveSuggestion"
                :dropdownVisibleTo="dropdownVisibleTo"
                @displayNameFilterSearch="displayNameFilterSearch"
                @cleared="closeSearch"
                @setLoadeFlag="setLoadeFlag"
                @setLoadingFlag="setLoadingFlag"
                @enableSave="enableSave"
              />
            </div>
          </div>
          <!-- <div
            class="flex py-2 upppercase text-gray-700"
            v-else
            :class="isEditAccess ? hoverClassDropdown : ''"
            @click="isEditAccess ? editMe('execSponsors') : null"
          >
            
          </div> -->
          <div class="mt-2 overflow-y-auto overflow-x-hidden h-80">
            <ul v-if="formData?.execSponsors?.length !== 0">
              <li v-for="(user, i) in formData?.execSponsors" :key="i">
                <div class="flex bg-white p-2 mb-1 rounded shadow-lg group">
                  <img v-if="user.image"
                    :src="user.image"
                    :title="user.name"
                    alt="avatar"
                    class="w-7 h-7 object-cover rounded-full mx-1"
                  />
                  <div
                  class="
                    w-8
                    h-8
                    rounded-full
                    bg-gray-300
                    text-center
                    place-items-center
                    grid
                    mr-1
                  "
                  v-else
                >
                  {{ user?.name.charAt(0).toUpperCase() }}
                </div>
                  <div class="flex-grow">
                    <div>{{ user.name }}</div>
                  </div>
                  <div
                    class="
                      cursor-pointer
                      hover:opacity-70
                      hidden
                      group-hover:block
                    "
                    @click="removeExpo(user)"
                  >
                    <Close1 />
                  </div>
                </div>
              </li>
            </ul>
            <div v-else-if="editField !== 'execSponsors'">NA</div>
          </div>
        </div>
      </div>

      <div class="mt-5" v-if="actionUpdates?.length !== 0">
        <div
          class="flex border bg-white p-3 mt-2 rounded place-items-center"
          v-for="(item, i) in actionUpdates"
          :key="i"
        >
          <div class="flex-1 text-gray-500 text-sm">
            {{ item?.name }}
          </div>
          <div class="flex-1 flex-col">
            <div class="text-gray-500 text-sm">Last</div>
            <div v-if="item?.data?.length !== 0">
              {{
                item?.data[item?.data?.length - 1]?.markComplete?.date
                  ? formatDate(
                      item?.data[item?.data?.length - 1]?.markComplete?.date
                    )
                  : formatDate(
                      item?.data[item?.data?.length - 2]?.markComplete?.date
                    )
              }}
            </div>
            <div v-else>NA</div>
          </div>

          <div class="flex-1 flex-col">
            <div class="text-gray-500 text-sm">Previous</div>
            <div v-if="item?.data?.length !== 0">
              {{
                item?.data[item?.data?.length - 1]?.markComplete
                  ? formatDate(
                      item?.data[item?.data?.length - 2]?.markComplete?.date
                    )
                  : formatDate(
                      item?.data[item?.data?.length - 3]?.markComplete?.date
                    )
              }}
            </div>
            <div v-else>NA</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import InlineEdit from "../../components/InlineEdit.vue";
import RichText from "../../components/RichText.vue";
import { format } from "fecha";
import users from "../../services/users.js";
import Close from "../../components/Icon/Close.vue";
import AvatarGrouping from "../AvatarGrouping.vue";
import Close1 from "../../components/Icon/Close.vue";
export default {
  name: "ActivityHistory",
  props: {
    initiative: Object,
  },
  components: {
    Multiselect,
    InlineEdit,
    RichText,
    Close,
    AvatarGrouping,
    Close1,
  },
  data(props) {
    return {
      workSpaceUsers: [],
      editField: false,
      arr: [1, 2, 3],
      formData: {
        opsPOC: "TBD",
        // execSponsors: "TBD",
        execSponsors: [],
        progress: "TBD",
        buAction: ["Legal", "Meraki"],
        actionItem: "TBD",
        notes: "TBD",
      },
      isExe: [],
      progressList: [
        { value: "On track", label: "On track" },
        { value: "Off track", label: "Off track" },
        { value: "At risk", label: "At risk" },
      ],
      BUAction: [
        { value: "AppDynamics", label: "AppDynamics" },
        { value: "Cloud Services", label: "Cloud Services" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Core Hardware Platforms", label: "Core Hardware Platforms" },
        {
          value: "Customer and Partner Services",
          label: "Customer and Partner Services",
        },
        { value: "Customer Experience", label: "Customer Experience" },
        { value: "Data and Analytics", label: "Data and Analytics" },
        { value: "Enterprise Networking", label: "Enterprise Networking" },
        { value: "IoT", label: "IoT" },
        { value: "IT", label: "IT" },
        { value: "Legal", label: "Legal" },
        {
          value: "Mass-Scale Infrastructure",
          label: "Mass-Scale Infrastructure",
        },
        { value: "Meraki", label: "Meraki" },
        { value: "Operations", label: "Operations" },
        { value: "People & Communities", label: "People & Communities" },
        { value: "Sales and Marketing", label: "Sales and Marketing" },
        { value: "Security", label: "Security" },
        { value: "Supply Chain", label: "Supply Chain" },
      ],
      usersByDisplayName: [],
      loaderFlag: false,
      setExpSpo: [],
    };
  },
  created: async function () {
    this.$store.dispatch("getAccessToken", "");
    await this.$store.dispatch("setInitiativeMetaData", this.initiative.id);
    let data = this.initiativeMetaData?.managementFields;
    this.formData = {
      opsPOC: data?.opsPOC || "TBD",
      pmPOC: data?.pmPOC || "TBD",
      // execSponsors: data?.execSponsors || "TBD",
      execSponsors: data?.execSponsors || [],
      progress: data?.progress || "TBD",
      buAction: data?.buAction || ["NA"],
      actionItem: data?.actionItem || "TBD",
      notes: data?.notes || "TBD",
    };
    this.setExpSpo = data?.execSponsors || [];
    this.workSpaceUsers = await this.$store.dispatch("getWorkspaceUsers");
  },
  computed: {
    ...mapGetters(["user", "msGraphAccessToken", "initiativeMetaData"]),
    hoverClass() {
      return "hover:bg-gray-200 hover:shadow-md rounded  -mx-2 px-2 ";
    },
    hoverClassDropdown() {
      return "hover:bg-gray-200 hover:shadow-md rounded -mx-2 px-2 ";
    },
    actionUpdates() {
      let updates = [];

      /*
      updates.push({
        name: "Sign Up For Openmic",
        data: this.initiative?.metaData?.signUpForOpenmic || [],
      });
      updates.push({
        name: "Register Architecture",
        data: this.initiative?.metaData?.registerArchitecture || [],
      });
      updates.push({
        name: "Register Building Block",
        data: this.initiative?.metaData?.registerBuildingBlock || [],
      });
      updates.push({
        name: "Register Extended Staff",
        data: this.initiative?.metaData?.registerExtendedStaff || [],
      });

      */

      return updates;
    },
    allUsers() {
      if (this.workSpaceUsers?.length > 0) {
        return this.workSpaceUsers.map((m) => {
          m.value = {
            name: m.firstName + " " + m.lastName,
            image: m.image,
            email: m.email?.toLowerCase(),
          };
          m.label = m.firstName + " " + m.lastName;
          return m;
        });
      } else {
        return [];
      }
    },
    isEditAccess() {
      return (
        this.user?.roles.includes("manager") && this.initiative.status !== 6
      );
    },
  },
  methods: {
    editMe(name) {
      this.editField = name;
    },
    formatDate(date) {
      if (date) {
        return format(new Date(date), "MMM D, YYYY");
      } else {
        return "NA";
      }
    },
    async updateValue(data) {
      let payload = {
        id: this.initiative.id,
        data: {
          key: data.label,
          label: "metaData.managementFields." + data.label,
          value: data.value,
        },
      };

      await this.$store.dispatch("updateInitiativeMetaData", payload);
      this.formData[data.label] = data.value;
      this.editField = null;
    },
    removeUser(label) {
      let obj = {
        label: label,
        value: "NA",
      };

      this.updateValue(obj);
    },
    setLoadeFlag(flag) {
      this.loaderFlag = flag;
    },
    setLoadingFlag(flag) {
      this.isLoader = flag;
    },
    closeSearch() {
      this.editField = null;
    },
    async displayNameFilterSearch(value, selectedValues) {
      this.setLoadeFlag(true);
      let payload = {
        value: value,
        accessToken: this.msGraphAccessToken,
      };
      let result = await this.$store.dispatch("getUsersByDisplayName", payload);

      if (selectedValues && selectedValues.length > 0) {
        let filteredResult = result.filter((e) => {
          return !selectedValues.some(
            (item) => item.email?.toLowerCase() === e.email?.toLowerCase()
          );
        });
        this.usersByDisplayName = filteredResult;
      } else {
        this.usersByDisplayName = result;
      }
      this.setLoadeFlag(false);
    },
    removeExpo(item) {
      this.editField = "execSponsors";
      this.setExpSpo = this.formData.execSponsors.filter(
        (f) => f.email !== item.email
      );

      this.setLoadeFlag(true);
      let obj = {
        label: this.editField,
        value: this.setExpSpo,
      };

      this.updateValue(obj);
      this.setLoadeFlag(false);
      this.editField = null;
    },
    autoSaveSuggestion(users) {
      let payload = users;
      if (this.editField === "execSponsors") {
        this.setExpSpo.push(users);
        payload = this.setExpSpo;
      }

      this.setLoadeFlag(true);
      let obj = {
        label: this.editField,
        value: payload,
      };

      this.updateValue(obj);
      this.setLoadeFlag(false);
      this.editField = null;
    },
  },
};
</script>
<style scoped>
</style>