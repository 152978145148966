<template>
  <div class="px-6 py-6" :key="componentKey">
    <h3 class="mb-3">Comments</h3>
    <div class="
        flex
        bg-white
        border-l-4 border-gray-300
        rounded-lg
        shadow
        text-gray-800
        px-4
        py-3
      " role="alert">
      <div class="ml-3 flex-grow font-bold">All Confirmation Comments</div>
      <div class="mr-2">
        <Checkbox :label="Comment" theme="primary" text="" position="left" @onChangeCheckbox="onChangeCheckbox('comment')"
          :defaultValue="value" :id="Comment" />
      </div>
    </div><br />
    <div class="
        flex
        bg-white
        border-l-4 border-gray-300
        rounded-lg
        shadow
        text-gray-800
        px-4
        py-3
      " role="alert">
      <div class="ml-3 flex-grow font-bold">Promote</div>
      <div class="mr-2">
        <Checkbox :label="Comment" theme="primary" text="" position="left" @onChangeCheckbox="onChangeCheckbox('promote')"
          :defaultValue="promoteValue" :id="Comment" />
      </div>
    </div><br />
    <div class="
        flex
        bg-white
        border-l-4 border-gray-300
        rounded-lg
        shadow
        text-gray-800
        px-4
        py-3
      " role="alert">
      <div class="ml-3 flex-grow font-bold">Unpromote</div>
      <div class="mr-2">
        <Checkbox :label="Comment" theme="primary" text="" position="left"
          @onChangeCheckbox="onChangeCheckbox('unpromote')" :defaultValue="unpromoteValue" :id="Comment" />
      </div>
    </div><br />
    <div class="
        flex
        bg-white
        border-l-4 border-gray-300
        rounded-lg
        shadow
        text-gray-800
        px-4
        py-3
      " role="alert">
      <div class="ml-3 flex-grow font-bold">Active</div>
      <div class="mr-2">
        <Checkbox :label="Comment" theme="primary" text="" position="left" @onChangeCheckbox="onChangeCheckbox('active')"
          :defaultValue="activeValue" :id="Comment" />
      </div>
    </div><br />
    <div class="
        flex
        bg-white
        border-l-4 border-gray-300
        rounded-lg
        shadow
        text-gray-800
        px-4
        py-3
      " role="alert">
      <div class="ml-3 flex-grow font-bold">Inactive</div>
      <div class="mr-2">
        <Checkbox :label="Comment" theme="primary" text="" position="left"
          @onChangeCheckbox="onChangeCheckbox('inactive')" :defaultValue="inactiveValue" :id="Comment" />
      </div>
    </div><br />
  </div>
</template>
  
<script >
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      componentKey: 0,
      value: false,
      promoteValue: false,
      unpromoteValue: false,
      activeValue: false,
      inactiveValue: false,
    };
  },
  beforeMount() {
    this.updateCheckboxState();
  },
  beforeUpdate: function () {
    this.updateCheckboxState();
  },
  components: {},
  computed: {
    ...mapGetters(["playbook"]),
  },
  watch: {
    playbook: function (newValue, oldValue) {
      this.componentKey += 1;
    },
  },
  created: async function () { },
  methods: {
    async onChangeCheckbox(key) {
      let payload = {
        id: this.playbook._id
      };
      if (key == 'comment') {
        this.value = !this.value;
        this.promoteValue = !this.value;
        this.unpromoteValue = !this.value;
        this.activeValue = !this.value;
        this.inactiveValue = !this.value;
        payload.updateData = {
          'metaData.enablePromoteComments': this.value, 'metaData.enableUnpromoteComments': this.value,
          'metaData.enableActiveComments': this.value, 'metaData.enableInactiveComments': this.value
        }
      }
      if (key == 'promote') {
        this.promoteValue = !this.promoteValue;
        payload.updateData = { 'metaData.enablePromoteComments': this.promoteValue }
      }
      if (key == 'unpromote') {
        this.unpromoteValue = !this.unpromoteValue;
        payload.updateData = { 'metaData.enableUnpromoteComments': this.unpromoteValue }
      }
      if (key == 'active') {
        this.activeValue = !this.activeValue;
        payload.updateData = { 'metaData.enableActiveComments': this.activeValue }
      }
      if (key == 'inactive') {
        this.inactiveValue = !this.inactiveValue;
        payload.updateData = { 'metaData.enableInactiveComments': this.inactiveValue }
      }
      if (this.promoteValue && this.unpromoteValue && this.activeValue && this.inactiveValue) {
        this.value = true;
      } else {
        this.value = false;
      }
      await this.$store.dispatch("updatePlaybook", payload);
      await this.$store.dispatch("readPlaybook");
    },
    updateCheckboxState() {
      if (
        this.playbook?.hasOwnProperty('metaData') &&
        this.playbook.metaData.enablePromoteComments &&
        this.playbook.metaData.enableUnpromoteComments &&
        this.playbook.metaData.enableActiveComments &&
        this.playbook.metaData.enableInactiveComments
      ) {
        this.value = true;
        this.promoteValue = true;
        this.unpromoteValue = true;
        this.activeValue = true;
        this.inactiveValue = true;
      } else if (this.playbook?.hasOwnProperty('metaData')) {
        this.value = false;
        this.promoteValue = this.playbook.metaData.enablePromoteComments || false;
        this.unpromoteValue = this.playbook.metaData.enableUnpromoteComments || false;
        this.activeValue = this.playbook.metaData.enableActiveComments || false;
        this.inactiveValue = this.playbook.metaData.enableInactiveComments || false;
      } else {
        this.value = false;
        this.promoteValue = false;
        this.unpromoteValue = false;
        this.activeValue = false;
        this.inactiveValue = false;
      }
    },
  },
};
</script>
<style scoped></style>