<template>
  <MultiSelect
    v-if="tags"
    class="bg-white"
    mode="tags"
    v-model="value"
    placeholder="Search users..."
    :close-on-select="false"
    :filter-results="false"
    :min-chars="1"
    :resolve-on-load="false"
    :delay="0"
    :searchable="true"
    :options="
      async function (query) {
        return await fetchUsers(query); // check JS block for implementation
      }
    "
  />
  <MultiSelect
    v-else
    class="bg-white"
    v-model="value"
    placeholder="Search users..."
    :close-on-select="false"
    :filter-results="false"
    :min-chars="1"
    :resolve-on-load="false"
    :delay="0"
    :searchable="true"
    :options="
      async function (query) {
        return await fetchUsers(query); // check JS block for implementation
      }
    "
  />

</template>
<script>
import { mapGetters } from "vuex";
import MultiSelect from "@vueform/multiselect";

export default {
  name: "ad-user-dropdown",
  data(props) {
    return {
      value: [],
      reset: props.reset,
    };
  },
  watch: {
    value: function (newValue, oldValue) {
      this.value = newValue;
      this.$emit("handleStateValue", this.setKey, [newValue]);
    },
    reset: function (newValue, oldValue) {
      this.value = [];
      this.$emit("handleStateValue", this.setKey, this.value);
    },
  },
  props: {
    tags: Boolean,
    setKey:String,
    setValue: Array,
    reset: Boolean,
  },
  components: {
    MultiSelect,
  },
  computed: {
    ...mapGetters(["msGraphAccessToken"]),
  },
  created: async function () {},

  methods: {
    async fetchUsers(value) {
      let payload = {
        value: value,
        accessToken: this.msGraphAccessToken,
      };
      let result = await this.$store.dispatch("getUsersByDisplayName", payload);
      result?.map((item, i) => {
        item.label = item.name;
        item.value = item.email;
      });
      return result;
    },
  },
};
</script>
<style scoped>
</style>
