<template>
  <form ref="anyName" @submit.prevent>
    <div style="position: relative" v-bind:class="{ open: openSuggestion }">
      <span class="closeIcon" @click="clearSelection()" v-if="selection.length !== 0 || autoSave">
        <!-- v-bind:class="{ displayNone: selection.length === 0 }" -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
      <input
        class="
          bg-white
          appearance-none
          border border-gray-200
          rounded
          z-3
          w-full
          py-2
          px-4
          text-gray-700
          leading-tight
          focus:outline-none focus:bg-white focus:border-gray-500
        "
        type="text"
        v-model="selection"
        @keydown.enter="enter"
        @keydown.down="down"
        @keydown.up="up"
        @input="searchUser"
        autofocus
        placeholder="Search..."
      />
      <!-- 48 24  -->
      <ul
        class="z-10 w-full absolute overflow-auto max-h-60"
        v-if="openList === dropdownVisibleTo && selection.length !== 0"
      >
        <li v-if="loaderFlag && selectToOpenList === openList">
          <div
            class="
              bg-gray-50
              w-full
              flex
              items-center
              
              rounded
              shadow
              border
            "
          >
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 animate-spin float-left "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg> -->
            <div ><circularloader /></div>
          </div>
        </li>
        <li
          v-else-if="
            selection.length > 0 && suggestions.length == 0 && !loaderFlag
          "
        >
          <div
            class="
              bg-gray-50
              w-full
              flex
              items-center
              p-2
              rounded
              shadow
              border
            "
          >
            <div class="font-semibold text-gray-700">Name Not Found!</div>
            <!-- <div class="text-gray-700">
                 Name Not Found!
                </div> -->
          </div>
        </li>
        <li
          v-for="(suggestion, index) in suggestions"
          :key="{ index }"
          v-bind:class="{ active: isActive(index) }"
          @click="suggestionClick(suggestion)"
          v-else
        >
          <a href="javascript:void(0)">
            <div
              class="
                bg-gray-50
                w-full
                flex
                items-center
                rounded
                shadow
                border
                hover:bg-gray-200
               

              "
            >
              <!-- <div class="w-12  items-center space-x-4">
                <img
                  src="@/assets/images/user.png"
                  alt="My profile"
                  class="w-10 h-10 rounded-full"
                />
              </div> -->
              <!-- <div
                class="
                  w-8
                  h-8
                  rounded-full
                  bg-gray-100
                  text-center
                  place-items-center
                  grid
                "
               
              >
                {{ suggestion?.name.charAt(0).toUpperCase() }}
              </div> -->
              <div class="w-full px-3 py-2">
                <div class="font-semibold text-gray-700">
                  {{ suggestion.name }}
                </div>
                <div class="text-gray-700">
                  {{ suggestion.jobTitle }}
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </form>
</template>
<script>
/* eslint-disable vue/no-mutating-props */
export default {
  data() {
    return {
      open: false,
      current: 0,
      backdoor: 0,
      selection: "",
      access: this.accessTo,
      selectToOpenList: "NA",
    };
  },
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    accessTo: {
      type: String,
    },
    initiative: {
      type: Object,
    },
    loaderFlag: {
      type: Boolean,
    },

    // selection: {
    //   type: String,
    //   required: true,
    //   twoWay: true,
    // },
    dropdownVisibleTo: {
      type: String,
      required: true,
    },
    openList: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      twoWay: true,
    },
    autoSave: Boolean,
  },
  computed: {
    // matches() {
    //   return (
    //     this.selection.trim() !== "" &&
    //     this.suggestions[0]
    //       .filter(
    //         (x) => !this.selected.filter((y) => y.name == x.name).length > 0
    //       )
    //       .filter((str) => {
    //         return (
    //           str.name.toLowerCase().indexOf(this.selection.toLowerCase()) >= 0
    //         );
    //       })
    //   );
    // },

    openSuggestion() {
      return this.selection !== "" && this.open === true;
    },
  },
  created: async function () {},
  beforeUpdate() {},
  methods: {
    enter() {
      this.selection = this.matches[this.current];
      this.open = false;
    },

    up() {
      if (this.current > 0) this.current--;
    },

    down() {
      if (this.current < this.matches.length - 1) this.current++;
    },

    isActive(index) {
      return index === this.current;
    },
    async searchUser(data) {
      await this.$emit("setLoadeFlag", true);
      this.selectToOpenList = this.openList;
      if (this.open == false) {
        this.open = true;
        this.current = 0;
        this.$emit("enableSave");
      }
      await this.$emit("setActiveWindow", this.selectToOpenList);
      this.change(data);
    },
    change: _.debounce(async function (data) {
      await this.$emit(
        "displayNameFilterSearch",
        this.selection,
        this.selected
      );
    }, 300),
    async clearSelection() {
      this.selection = "";
      this.open = false;
      this.selectToOpenList = "NA";
      if (this.autoSave) {
         this.$emit("cleared");
      } else {
        await this.$emit(
          "displayNameFilterSearch",
          this.selection,
          this.selected
        );
      }
    },
    async suggestionClick(suggestion) {
      
      let user = await this.$store.dispatch(
        "createProfilePicIfUserNotPresent",
        suggestion
      );
      this.open = false;
      this.selection = "";

      if (this.autoSave) {
        let userObj = {
          name: suggestion.name,
          email: suggestion.email.toLowerCase(),
          image: user.profilePic,
        };
        this.$emit("autoSaveSuggestion", userObj);
      } else {
        this.selected.push({
          name: suggestion.name,
          email: suggestion.email.toLowerCase(),
          image: user.profilePic,
          role: "TeamLead",
        });

        await this.$emit(
          "displayNameFilterSearch",
          this.selection,
          this.selected
        );
        if (this.selected.length > 1) {
          this.selected.splice(0, 1);
        }
      }
      await this.clearSelection();
    },
  },
};
</script>
<style scoped>
.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: gray;
  display: block;
  cursor: pointer;
}
.closeIcon:hover {
  opacity: 0.7;
}
.displayNone {
  display: none;
}
</style>
