<template>
  <!-- <pre
    >{{
      this.selectedIdea.activities[0].activities.map((f) => f?.raci?.a?.teamId)
    }} </pre
  > -->
  <div class="float-left w-full">
    <!-- <h3 class="pb-3">Feedback History</h3> -->

    <div
      v-if="!isFeedsEmpty"
      class="mx-auto w-full float-left text-center my-20"
    >
      <img src="@/assets/images/no-feedback.png" class="w-1/2 mx-auto" />
      <h1 class="font-bold text-blue-500">No feedback has been provided yet</h1>
      <p class="text-xs">
        Once the judging team starts providing the <br />
        feedback you will be able to view it here.
      </p>
    </div>

    <div
      class="inline-block w-full bg-blue-400 rounded text-white mb-3 float-left"
    >
      <!-- {{ feed }} -->
      <!-- <pre>{{ selectedIdea.metaData }} </pre> -->
      <div v-if="selectedIdea?.metaData?.actionLabel  " class="bg-blue-400">
        <div
          class="
            float-left
            w-full
            text-sm
            cursor-pointer
            p-2
            rounded
            bg-blue-400
          "
          @click="managerMsg = !managerMsg"
        >
          <div class="float-left mt-2 pr-2">
            <div
              v-if="selectedIdea.metaData?.icon === 'check'"
              class="bg-blue-500 text-white w-5 h-5 rounded-full"
            >
              <IconCheck />
            </div>
            <div
              v-if="selectedIdea.metaData?.icon === 'info'"
              class="bg-yellow-500 text-white w-5 h-5 rounded-full"
            >
              <IconExclimation />
            </div>
          </div>
          <div class="float-left">
            <div class="text-xs leading-3">Program Manager</div>
            <div class="text-lg font-semibold">
              {{ selectedIdea.metaData?.actionLabel }}
            </div>
          </div>

          <div class="float-right mt-2 ml-3">
            <icon-arrow-right v-if="managerMsg" />
            <icon-arrow-down v-if="!managerMsg" />
          </div>
          <div class="float-right mt-2">
            {{ timeago.format(new Date(selectedIdea.metaData?.date)) }}
          </div>
        </div>

        <div
          v-show="!managerMsg"
          class="
            border-t border-gray-200
            float-left
            w-full
            transition-all
            duration-500
            ease-in-out
            transform
          "
        >
          <div class="inline-block p-4">
            {{ selectedIdea.metaData?.comment }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="inline-block w-full rounded bg-gray-100 mb-3 float-left"
      v-for="(feed, index) in selectedIdea.activities[0].activities"
      :key="index"
    >
      <!-- {{ feed }} -->
      <!-- <pre>{{ feed.metaData }} </pre> -->
      <div
        v-if="
          feed.metaData &&
          (feed.metaData?.updatedBy === 'judgeManager' ||
            feed.metaData?.updatedBy === 'judgeMember')
        "
      >
        <div
          class="float-left w-full text-sm cursor-pointer p-2 rounded"
          @click="openItem(feed)"
        >
          <div class="float-left mt-2 pr-2">
            <div
              v-if="feed.metaData.icon === 'check'"
              class="bg-blue-500 text-white w-5 h-5 rounded-full"
            >
              <IconCheck />
            </div>
            <div
              v-if="feed.metaData.icon === 'info'"
              class="bg-yellow-500 text-white w-5 h-5 rounded-full"
            >
              <IconExclimation />
            </div>
          </div>
          <div class="float-left">
            <div class="text-xs leading-3">{{ feed.name }}</div>
            <div class="text-lg font-semibold">{{ feed.metaData.actionLabel }}</div>
          </div>

          <div class="float-right mt-2 ml-3">
            <icon-arrow-right v-if="feed.isopen" />
            <icon-arrow-down v-if="!feed.isopen" />
          </div>
          <div class="float-right mt-2">
            {{ timeago.format(new Date(feed.metaData?.date)) }}
          </div>
        </div>

        <div
          v-show="!feed.isopen"
          class="border-t border-gray-200 float-left w-full"
        >
          <div class="inline-block p-4">
            {{ feed.metaData.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre> {{ selectedIdea }}</pre> -->
</template>
<script>
import * as timeago from "timeago.js";
import IconArrowRight from "../Icon/Arrow-Right.vue";
import IconArrowDown from "../Icon/Arrow-Down.vue";
import IconCheck from "../Icon/Check.vue";
import IconExclimation from "../Icon/Exclimation.vue";
import defaultImage from "../../assets/images/target2.jpg";
export default {
  data() {
    return {
      timeago,
      managerMsg: false,
    };
  },
  name: "FeedbackHistory",
  props: {
    selectedIdea: Object,
    selectedInitiative: Object,
    user: Object,
  },
  components: { IconArrowRight, IconArrowDown, IconCheck, IconExclimation },
  mounted: function () {},
  methods: {
    openItem: function (item) {
      item.isopen = !item.isopen;
    },
  },
  setClass: function (item) {
    if (item.isopen == true) {
      return "open";
    }
    return "close";
  },
  computed: {
    isFeedsEmpty() {
      let judgeUpdates =
        this.selectedIdea.activities[0].activities.filter(
          (f) =>
            f?.metaData?.updatedBy === "judgeManager" ||
            f?.metaData?.updatedBy === "judgeMember"
        ).length !== 0;

      let managerUpdate = this.selectedIdea?.metaData?.actionType 

      return judgeUpdates || managerUpdate;
    },
  },
};
</script>
<style scoped></style>
