<template>
  <div>
    <div class="font-bold mb-2">
      Phase Comments /observations ( Program Team )
      <div class="float-right text-right" v-if="isEditAccess">
        <button
          class="
            hover:bg-blue-500
            bg-blue-700
            text-white
            hover:text-white
            py-1
            px-2
            border border-blue-500
            hover:border-transparent
            rounded
            text-sm
            font-semibold
          "
          @click="addNote()"
        >
          Add Comment
        </button>
      </div>
    </div>

    <div class="mt-6" v-if="notes.length === 0">
      <info-card message="No Comments found!" />
    </div>
    <div v-else>
      <ul class="mt-4 list-reset flex flex-col">
        <li
          v-for="(item, index) in notes"
          :key="index"
          class="rounded-md relative block border p-4 border-grey mb-2 bg-white"
        >
         
          <div class="flex flex-row justify-between relative">
            <div class="flex flex-row">
              <img
                class="w-10 h-10 mr-3 rounded-full cursor-pointer ring ring-gray-100 hover:shadow"
                :src="item.accountUserId.profilePic"
                alt=""
                @click="goToProfile(item)"
              />
              <div class="flex flex-col">
                <div class="text-sm text-gray-400 mb-1">
                  <span class="cursor-pointer hover:opacity-80" @click="goToProfile(item)">
                    {{
                      item.accountUserId.firstName +
                      " " +
                      item.accountUserId.lastName
                    }}
                  </span>
                  .
                  {{ timeago.format(new Date(item.notedate)) }}
                </div>
                <div class="-mt-1">
                  <RichText :text="item.content.replace(/<\/?[^>]+>/gi, ' ')" />
                </div>
              </div>
            </div>
            <div class="text-sm text-gray-400">
              <div class="dropdown float-right">
                <span class="rounded-md shadow-sm" v-if="isEditAccess">
                  <button
                    class="
                      float-right
                      text-right
                      font-medium
                      leading-5
                      text-gray-700
                      transition
                      duration-150
                      ease-in-out
                      bg-white
                      hover:text-gray-400
                      focus:outline-none
                      focus:border-blue-300
                      focus:shadow-outline-blue
                      active:bg-gray-50 active:text-gray-800
                      mt-0.5
                      ml-2
                    "
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                      />
                    </svg></button
                ></span>
                <div
                  class="
                    opacity-0
                    invisible
                    dropdown-menu
                    transition-all
                    duration-300
                    transform
                    origin-top-right
                    -translate-y-2
                    scale-95
                    z-100
                    relative
                  "
                >
                  <div
                    class="
                      absolute
                      right-0
                      w-36
                      p-2
                      mt-2
                      origin-top-right
                      bg-white
                      border border-gray-200
                      divide-y divide-gray-100
                      rounded-md
                      shadow-lg
                      outline-none
                    "
                    aria-labelledby="headlessui-menu-button-1"
                    role="menu"
                  >
                    <a
                      href="javascript:void(0)"
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-gray-800
                        hover:bg-gray-100
                      "
                      @click="editNote(item)"
                      v-if="
                        (item.accountUserId._id == user.aspenifyUserId ||
                          user.roles.includes('manager')) &&
                        selectedInitiative.status != 2 &&
                        selectedInitiative.status != 3 &&
                        selectedInitiative.status != 5 &&
                        isEditAccess
                      "
                      >Edit</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-gray-800
                        hover:bg-gray-100
                      "
                      v-if="
                        (item.accountUserId._id == user.aspenifyUserId ||
                          user.roles.includes('manager')) &&
                        selectedInitiative.status != 2 &&
                        selectedInitiative.status != 3 &&
                        selectedInitiative.status != 5 &&
                        isEditAccess
                      "
                      @click="deleteNote(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="text-right float-right">
               
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <EditDeleteNote
    :openClose="openCloseNote"
    @openCloseModal="openCloseNoteModal"
    @editDeleteNotes="editDeleteNotes"
    @postNote="postNote"
    :noteObj="noteObj"
    :todo="todo"
  >
  </EditDeleteNote>
</template>
<script>
import * as timeago from "timeago.js";
import EditDeleteNote from "../confirmationModal/EditDeleteNote.vue";
import InfoCard from "../../components/InfoCard.vue";
import { mapGetters } from "vuex";
import RichText from "../../components/RichText";
export default {
  components: { EditDeleteNote, InfoCard, RichText },
  name: "PhaseComment",
  props: {
    notes: Array,
    phaseData: Object,
    currentPhaseIndex: Number,
    initiative: Object,
  },
  data() {
    return {
      timeago,
      note: "",
      noteObj: null,
      openCloseNote: false,
      todo: null,
    };
  },
  computed: {
    ...mapGetters(["user", "selectedInitiative"]),
    isEditAccess() {
      return (
        this.user?.roles.includes("manager") &&
        this.initiative.phaseIndex === this.currentPhaseIndex && 
        this.initiative.status !==6
      );
    },
  },
  methods: {
    postNote: function () {
      this.openCloseNoteModal(false);

      this.$store.dispatch("addEditDeleteNoteInActivity", {
        _id: this.phaseData._id,
        note: this.noteObj.content,
        todo: "add",
      });
      this.noteObj = "";
    },
    openCloseNoteModal(value) {
      this.openCloseNote = value;
    },
    editNote: function (item) {
      this.noteObj = { ...item };
      this.todo = "edit";
      this.openCloseNoteModal(true);
    },
    addNote: function (item) {
      this.noteObj = { ...item };
      this.todo = "add";
      this.openCloseNoteModal(true);
    },
    deleteNote: function (item) {
      this.noteObj = { ...item };
      this.todo = "delete";
      this.openCloseNoteModal(true);
    },
    editDeleteNotes: function () {
      this.openCloseNoteModal(false);
      this.$store.dispatch("addEditDeleteNoteInActivity", {
        _id: this.phaseData._id,
        note: this.noteObj,
        todo: this.todo,
      });
    },
    goToProfile(user) {
      var name = user?.accountUserId?.email?.toLowerCase()?.toLowerCase()?.substring(
        0,
        user?.accountUserId?.email.lastIndexOf("@")
      );
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?.accountUserId?._id, userName: name },
      });
    },
  },
};
</script>
<style scoped></style>

<!-- <div v-if="notes.length > 0" class="">
    <div v-for="(item, index) in notes" :key="index" class="flex border-b">
      <div class="w-20 p-3">
        <img
          class="h-10 w-10 rounded-full"
          :src="item.accountUserId.profilePic"
          alt=""
        />
      </div>
      <div class="w-full pr-4 py-3 break-words">
        <span class="font-semibold text-gray-900 block">
          {{ item.accountUserId.firstName }}
          {{ item.accountUserId.lastName }}

          <span
            v-if="
              item.accountUserId._id == user.aspenifyUserId &&
              selectedInitiative.status != 2 &&
              selectedInitiative.status != 3 &&
              selectedInitiative.status != 5
            "
            @click="deleteNote(item)"
            class="
              float-right
              cursor-pointer
              block
              h-4
              w-4
              ml-5
              blue-400
              fill-current
              text-blue-600
              bottom-0
              right-0
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-trash-2"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path
                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
              ></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </span>
          <span
            v-if="
              item.accountUserId._id == user.aspenifyUserId &&
              selectedInitiative.status != 2 &&
              selectedInitiative.status != 3 &&
              selectedInitiative.status != 5
            "
            @click="editNote(item)"
            class="
              float-right
              cursor-pointer
              block
              h-4
              w-4
              ml-5
              blue-400
              fill-current
              text-blue-600
              bottom-0
              right-0
              mt-1
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </span>
        </span>
        {{ item.content.replace(/<\/?[^>]+>/gi, " ") }}
        <span class="block text-sm mb-2 mt-1"
          >{{ timeago.format(new Date(item.notedate)) }} &middot;</span
        >
      </div>
    </div>
  </div>
  <div v-else>
    <info-card message="No Comments found!" />
  </div>
  <div
    class="pt-4 mt-5"
    v-if="
      selectedInitiative.status != 2 &&
      selectedInitiative.status != 3 &&
      selectedInitiative.status != 5
    "
  >
   
    <textarea
      name="note"
      placeholder="Add a comment"
      id="note"
      rows="6"
      v-model="note"
      class="
        bg-gray-200
        appearance-none
        border-2 border-gray-200
        rounded
        shadow-md
        w-full
        py-2
        px-4
        text-gray-700
        leading-tight
        focus:outline-none
        focus:bg-white
        focus:border-gray-500
      "
    ></textarea>
    <div class="flex mt-2 justify-end">
      <button
        class="
          inline-flex
          text-white
          bg-indigo-500
          border-0
          py-1
          px-3
          focus:outline-none
          hover:bg-indigo-600
          rounded
          text-md
          disabled:opacity-30
        "
        :disabled="note.length === 0"
        type="button"
        @click="postNote"
      >
        <span class="ml-1">Add Comment </span>
      </button>
    </div>
  </div>
  <EditDeleteNote
    :openClose="openCloseNote"
    @openCloseModal="openCloseNoteModal"
    @editDeleteNotes="editDeleteNotes"
    :noteObj="noteObj"
    :todo="todo"
  >
  </EditDeleteNote> -->
