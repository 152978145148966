<template>
    <div v-if="openCloseNote" class="modal-backdrop z-[100]">
     <div class="modal">
      <section class="modal-body">
       <slot name="body">
        <div class=" 
          z-20              
          h-screen
          w-full
          fixed
          left-0
          top-0
          flex
          justify-center
          items-center
          g-opacity-50">
         <div class="bg-white rounded shadow-lg w-10/12 md:w-2/4">
          <div class="
               border-b
               p-5
               flex
               justify-between
               items-center
               rounded-t
             ">
           <h3 class="color font-medium text-white text-lg">
            Add Note
            <!-- {{documentData.documentName.substring(0,documentData.documentName.lastIndexOf("."))}} -->
           </h3>
           <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-2
                 rounded-full
                 focus:outline-none
               " @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
             <line x1="18" y1="6" x2="6" y2="18"></line>
             <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
           </button>
          </div>
          <div class="modal-body z-20 mt-3 mb-3 ml-2 mr-2">
            <AppTextEditor 
            :content="value"
             @update-content="updateContent"
             maxLimit = "20000"
            />
          </div>
          <div class="
               z-20
               w-full
               text-right
               float-right
               items-center
               justify-between
               p-5
               bg-white
               border-t
               rounded-bl-lg rounded-br-lg
             ">
           <button type="button" @click="close()" class="
             footerButton
               "> Cancel </button>
           <button :disabled="checkEmpty()" @click="addNote()" class="
           disabled:opacity-50 primaryButton
               "> Add </button>
          </div>
         </div>
        </div>
       </slot>
      </section>
     </div>
    </div>
    
   </template>
  
  <script>
 
  import AppTextEditor from "../../components/AppTextEditor";
  export default {
    name: "ConfirmationDialog",
    data() {
      return {
        
        note: ""
      };
    },
    computed: {
      
    },
    props: {
      
    },
    components: {
        AppTextEditor,
    },
    methods: {
      checkEmpty(){
        let excludedHtml = this.note.replace(/<\/?[^>]+(>|$)/g, "");
        excludedHtml=excludedHtml.toLowerCase().split(" ").join("")
        if(excludedHtml.length==0){
          return true;
        } else {
          return false;
        }
      },
      updateContent(updatedValue,label){ 
    this.value=updatedValue
    console.log(this.value);
    },
    //   addNote: async function(){
    //     this.$emit('setOpenCloseNote',false)
    //     if(this.note){
    //       let payload = {
    //         content: this.note,
    //         accountUserId: this.user.aspenifyUserId,
    //         notedate: new Date()
    //       }
    //       let updateElement = {"$push":{
    //           "notes": payload
    //       }}
    //       let data = {
    //         id: this.selectedActivity?._id,
    //         updateElement: updateElement        
    //       };
    //       await this.sessionStore.updateSessionActivity(data);
    //       await this.sessionStore.getActivityById(this.selectedActivity._id);
    //       await this.sessionStore.getSessionById(this.$route.query.id || this.selectedSession._id, 'dashboard');
    //       this.note="";
    //     }
    //   },
    //   close: function(){
    //     this.$emit('setOpenCloseNote',false);
    //     this.note="";
    //   },
    //   displayLiveCountDown: function() {
    //     this.RemainCountTotal = this.maxLimitCount - this.note.length;
    //     this.displayError = this.RemainCountTotal < 0;
    //   },
    },
  };
  </script>
  <style scoped>
  .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .modal {
      background: #FFFFFF;
      box-shadow: 2px 2px 20px 1px;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: #4AAE9B;
      justify-content: space-between;
    }
  
    .modal-footer {
      border-top: 1px solid #eeeeee;
      flex-direction: column;
      justify-content: flex-end;
    }
  
    .modal-body {
      position: relative;
      padding: 20px 10px;
    }
  
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4AAE9B;
      background: transparent;
    }
  
    .btn-green {
      color: white;
      background: #4AAE9B;
      border: 1px solid #4AAE9B;
      border-radius: 2px;
    }
    .footerButton {
      font-size: 12px;
      font-style: normal;
      border-width: 1px;
      border-color: #4CB0D6;
      color: #4CB0D6;
      border-radius: 100px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 8px;
      height: 32px;
      width: 100px
    }
    .footerButton:hover {
      background-color: white;
      color: #4CB0D6;
    }
    .backgroundColor{
      background-color: #4CB0D6;
    }
    .color{
      color: #08526D;
    }
  
    .primaryButton{
      font-size: 12px;
      font-style: normal;
      border-width: 1px;
      border-color: #4CB0D6;
      color:white;
      border-radius: 100px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 8px;
      height: 32px;
      width: 100px;
      background-color:#4CB0D6;
    }
  </style>
  