<template>
  <section
    class="text-gray-600 body-font px-6 py-6"
    v-if="isPostIdeaFlag && initiatives.length === 0"
  >
    <!-- <div v-if="loadingFlag">
      <h1>loading Idea...</h1>
    </div> -->
    <loader-view
      v-if="loadingFlag"
      :loaderFlag="loadingFlag"
      loadingTitle="Loading Submission..."
    >
    </loader-view>
    <div
      v-else
      class="
        container
        mx-auto
        flex
        px-5
        py-10
        items-center
        justify-center
        flex-col
        mt-10
      "
    >
      <img src="@/assets/images/Progress.png" width="300" />
      <div class="text-center lg:w-2/3 w-full">
        <div class="mb-8 mt-8 leading-relaxed">
          <h3 class="text-2xl text-blue-900">Nothing to show</h3>
          You will be able to view ideas here once <br />
          they were created.
        </div>
      </div>
    </div>
  </section>
  <div class="px-6 py-6" v-else>
    <div v-if="!initiativePromoteInAction" class="flex-1 overflow-auto">
      <div class="w-full">
        <progress-filter
          :changeInputText="changeInputText"
          :judgingStatus="judgingStatus"
          @setFilterForJudgingStatus="setFilterForJudgingStatus"
          @setJudgingStatusFilter="setJudgingStatusFilter"
          @removeFilterNameAfterModalClose="removeFilterNameAfterModalClose"
          @updateLayout="updateLayout"
          @queryFilter="queryFilter"
          @removeLoaderOnSeach="removeLoaderOnSeach"
          @setLoader="setLoader"
          @setSearchQuery="setSearchQuery"
          @onManageClick="onManageClick"
          @onMakeActiveClick="onMakeActiveClick"
          @onMakeInactiveClick="onMakeInactiveClick"
          @onAssignClick="onAssignClick"
          @onUnpromoteClick="onUnpromoteClick"
          @onPromoteClick="onPromoteClick"
          :onManageClicked="onManageClicked"
          @resetBulkOperationObject="resetBulkOperationObject"
        />
      </div>
      <div class="w-full block">
        <div class="overflow-x-scroll overflow-y-hidden block w-full">
          <div v-if="initiatives.length == 0 && !loadingFlag">
            <section class="text-gray-600 body-font w-full">
              <info-card message="No Submissions to display!" />
            </section>
          </div>
          <main v-if="playbook" class="inline-flex h-full overflow-hidden mt-5">
            <!-- first column -->
            <div
              v-for="(activity, phaseIndex) in playbook.activities"
              :key="activity._id"
              class="kanban-list flex flex-col bg-gray-100 rounded-md w-80"
              :class="{ 'ml-0': phaseIndex === 0, 'ml-3': phaseIndex !== 0 }"
            >
              <div
                class="
                  flex
                  items-center
                  justify-between
                  flex-shrink-0
                  phase-title
                  h-10
                  px-2
                  m-2
                "
              >
                <span class="block text-lg font-semibold">{{
                  activity.name
                }}</span>
                <span
                  class="
                    text-xs
                    font-semibold
                    inline-block
                    py-1
                    px-2
                    rounded-full
                    text-blue-100
                    bg-blue-600
                    uppercase
                    last:mr-0
                    mr-1
                  "
                >
                  {{
                    getInitiativesbyPhaseName(activity.name, phaseIndex)
                      ? getInitiativesbyPhaseName(activity.name, phaseIndex)
                          .length
                      : 0
                  }}
                </span>
              </div>
              <div v-show="onManageClicked" class="flex flex-row-reverse">
              <input
                class="cursor-pointer mr-4"
                :class="{'mr-4 cursor-not-allowed': getInitiativesbyPhaseName(activity.name, phaseIndex)?.length==0}"
                :disabled="getInitiativesbyPhaseName(activity.name, phaseIndex)?.length==0"
                type="checkbox"
                @click.stop=""
                @change="onChangeCheckbox(getInitiativesbyPhaseName(activity.name, phaseIndex),activity.name,phaseIndex)"
                :id="id"
                :checked="isPhaseChecked(activity.name)"
              />
              <p class="mr-2">Select All</p>
              </div>
              <ul class="px-3 pt-1 pb-3 relative" v-if="loadingFlag">
                <li
                  class="rounded-lg bg-white p-4 shadow-lg mb-3"
                  v-for="item in [1, 2, 3]"
                  :key="item"
                >
                  <div
                    data-placeholder
                    class="
                      rounded
                      h-4
                      my-2
                      mt-2
                      w-2/3
                      bg-gray-200
                      overflow-hidden
                      relative
                    "
                  ></div>

                  <span
                    data-placeholder
                    class="
                      h-8
                      my-3
                      w-8
                      rounded-full rounded-md
                      bg-gray-200
                      overflow-hidden
                      relative
                      block
                    "
                  >
                    <!-- button placeholder -->
                  </span>
                  <p
                    data-placeholder
                    class="
                      rounded
                      h-2
                      mb-1
                      w-full
                      bg-gray-200
                      overflow-hidden
                      relative
                    "
                  ></p>
                  <p
                    data-placeholder
                    class="
                      rounded
                      h-2
                      mb-1
                      w-full
                      bg-gray-200
                      overflow-hidden
                      relative
                    "
                  ></p>
                  <p
                    data-placeholder
                    class="
                      rounded
                      h-2
                      w-1/3
                      mb-1
                      bg-gray-200
                      overflow-hidden
                      relative
                    "
                  ></p>
                </li>
              </ul>
              <div
                v-if="getInitiativesbyPhaseName(activity.name, phaseIndex)"
                class="
                  flex-1
                  min-h-0
                  pb-1
                  mt-3
                  overflow-y-auto overflow-x-hidden
                "
              >
                <ul
                  v-for="initiative in getInitiativesbyPhaseName(
                    activity.name,
                    phaseIndex
                  )"
                  :key="initiative._id"
                  class="px-3 pt-1 pb-3"
                >
                  <li>
                    <initiative-card
                      :initiative="initiative"
                      :user="user"
                      :phaseIndex="phaseIndex"
                      :isLastPhase="
                        phaseIndex === playbook.activities.length - 1
                      "
                      @selectInitiative="selectInitiative"
                      @promote="promote"
                      @unpromote="unpromote"
                      @judging="judging"
                      @canceljudging="canceljudging"
                      @endJudging="endJudging"
                      @archive="archive"
                      @close="close"
                      :searchQuery="searchQuery"
                      @getByPhaseDetails="getByPhaseDetails"
                      @checkSearchQuery="checkSearchQuery"
                      :onManageClicked="onManageClicked"
                      :isChecked="isInitiativeChecked(initiative)"
                      @onChangeCheckboxForInitiative="onChangeCheckboxForInitiative"
                    />
                  </li>
                </ul>
              </div>
              <div class="px-4 pt-1" v-else>Loading Ideas...</div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div v-if="initiativePromoteInAction" class="flex-1">
      <div
        class="
          w-full
          h-full
          fixed
          block
          top-0
          left-0
          bg-gray-600
          opacity-75
          z-50
        "
      >
        <div
          class="
            text-green-500
            opacity-75
            top-1/2
            my-0
            mx-auto
            block
            relative
            w-48
            h-100
          "
          style="top: 50%"
        >
          <svg
            class="logo"
            x="0px"
            y="0px"
            width="200.1px"
            height="105.4px"
            viewBox="-305 291.9 200.1 105.4"
          >
            <g class="logo__mark">
              <path
                d="M-296.2,324.6c0-2.4-2-4.3-4.4-4.3s-4.4,1.9-4.4,4.3v9.1c0,2.4,2,4.4,4.4,4.4s4.4-1.9,4.4-4.4V324.6z"
              />
              <path
                transform="translate(97.7633,748.5409)"
                d="M-370.1-435.9c0-2.4-2-4.3-4.4-4.3c-2.4,0-4.4,1.9-4.4,4.3v21.1c0,2.4,2,4.3,4.4,4.3c2.4,0,4.4-1.9,4.4-4.3V-435.9z"
              />
              <path
                transform="translate(106.3727,754.4384)"
                d="M-354.8-458.2c0-2.4-2-4.3-4.4-4.3s-4.4,1.9-4.4,4.3v46.1c0,2.4,2,4.4,4.4,4.4s4.4-1.9,4.4-4.4V-458.2z"
              />
              <path
                transform="translate(114.9821,748.5409)"
                d="M-339.5-435.9c0-2.4-2-4.3-4.4-4.3c-2.4,0-4.4,1.9-4.4,4.3v21.1c0,2.4,2,4.3,4.4,4.3c2.4,0,4.4-1.9,4.4-4.3V-435.9z"
              />
              <path
                transform="translate(123.5817,744.2304)"
                d="M-324.2-419.6c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.4,1.9-4.4,4.3v9.1c0,2.4,2,4.4,4.4,4.4c2.4,0,4.3-1.9,4.3-4.4V-419.6z"
              />
              <path
                transform="translate(132.195,748.5409)"
                d="M-308.9-435.9c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v21.1c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3 C-308.9-414.8-308.9-435.9-308.9-435.9z"
              />
              <path
                transform="translate(140.8102,754.4384)"
                d="M-293.6-458.2c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v46.1c0,2.4,1.9,4.4,4.3,4.4s4.3-1.9,4.3-4.4V-458.2z"
              />
              <path
                transform="translate(149.4235,748.5409)"
                d="M-278.3-435.9c0-2.4-1.9-4.3-4.4-4.3c-2.4,0-4.3,1.9-4.3,4.3v21.1c0,2.4,1.9,4.3,4.3,4.3c2.5,0,4.4-1.9,4.4-4.3 C-278.3-414.8-278.3-435.9-278.3-435.9z"
              />
              <path
                transform="translate(158.0192,744.2304)"
                d="M-263-419.6c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v9.1c0,2.4,1.9,4.4,4.3,4.4s4.3-1.9,4.3-4.4V-419.6z"
              />
            </g>
            <g class="logo__type">
              <path
                transform="translate(102.0426,727.2001)"
                d="M-362.5-355.3c-0.4-0.2-3.2-1.9-7.4-1.9c-5.7,0-9.6,3.9-9.6,9.3c0,5.2,3.8,9.3,9.6,9.3c4.1,0,7-1.6,7.4-1.8v9.3 c-1.1,0.3-4.1,1.2-8,1.2c-9.9,0-18.5-6.8-18.5-18c0-10.4,7.8-18,18.5-18c4.1,0,7.2,1,8,1.2V-355.3z"
              />
              <path d="M-239.7,396.7h-8.8v-34.8h8.8V396.7z" />
              <path
                transform="translate(121.5124,727.9413)"
                d="M-327.9-358.1c-0.1,0-3.8-1.1-6.9-1.1c-3.5,0-5.4,1.2-5.4,2.8c0,2.1,2.6,2.9,4,3.3l2.4,0.8c5.7,1.8,8.3,5.7,8.3,9.9 c0,8.7-7.7,11.7-14.4,11.7c-4.7,0-9-0.9-9.5-1v-8c0.8,0.2,4.4,1.3,8.3,1.3c4.4,0,6.4-1.3,6.4-3.2c0-1.8-1.7-2.8-3.9-3.5 c-0.5-0.2-1.3-0.4-1.9-0.6c-4.9-1.5-9-4.4-9-10.2c0-6.5,4.9-10.9,12.9-10.9c4.3,0,8.3,1,8.5,1.1v7.6H-327.9z"
              />
              <path
                transform="translate(134.9958,727.2001)"
                d="M-303.9-355.3c-0.4-0.2-3.2-1.9-7.4-1.9c-5.7,0-9.6,3.9-9.6,9.3c0,5.2,3.8,9.3,9.6,9.3c4.1,0,7-1.6,7.4-1.8v9.3 c-1.1,0.3-4.1,1.2-8,1.2c-9.9,0-18.5-6.8-18.5-18c0-10.4,7.8-18,18.5-18c4.1,0,7.2,1,8,1.2V-355.3z"
              />
              <path
                transform="translate(144.9274,727.8212)"
                d="M-286.3-357.7c-5.2,0-9.1,4.1-9.1,9.1c0,5.1,3.9,9.1,9.1,9.1s9.1-4.1,9.1-9.1S-281.1-357.7-286.3-357.7 M-267.9-348.5 c0,9.9-7.7,18-18.4,18s-18.4-8.1-18.4-18s7.7-18,18.4-18S-267.9-358.4-267.9-348.5"
              />
            </g>
          </svg>
        </div>
        <div
          class="
            w-full
            h-full
            mt-4
            top-1/2
            my-0
            mx-auto
            block
            relative
            text-center text-xl
            font-semibold
            text-gray-200
          "
        >
          Promoting {{ totalActivitiesToComplete }} activities
        </div>
      </div>
    </div>
    <div v-if="initiativeUnpromoteInAction" class="flex-1">
      <div
        class="
          w-full
          h-full
          fixed
          block
          top-0
          left-0
          bg-gray-600
          opacity-75
          z-50
        "
      >
        <div
          class="
            text-green-500
            opacity-75
            top-1/2
            my-0
            mx-auto
            block
            relative
            w-48
            h-100
          "
          style="top: 50%"
        >
          <svg
            class="logo"
            x="0px"
            y="0px"
            width="200.1px"
            height="105.4px"
            viewBox="-305 291.9 200.1 105.4"
          >
            <g class="logo__mark">
              <path
                d="M-296.2,324.6c0-2.4-2-4.3-4.4-4.3s-4.4,1.9-4.4,4.3v9.1c0,2.4,2,4.4,4.4,4.4s4.4-1.9,4.4-4.4V324.6z"
              />
              <path
                transform="translate(97.7633,748.5409)"
                d="M-370.1-435.9c0-2.4-2-4.3-4.4-4.3c-2.4,0-4.4,1.9-4.4,4.3v21.1c0,2.4,2,4.3,4.4,4.3c2.4,0,4.4-1.9,4.4-4.3V-435.9z"
              />
              <path
                transform="translate(106.3727,754.4384)"
                d="M-354.8-458.2c0-2.4-2-4.3-4.4-4.3s-4.4,1.9-4.4,4.3v46.1c0,2.4,2,4.4,4.4,4.4s4.4-1.9,4.4-4.4V-458.2z"
              />
              <path
                transform="translate(114.9821,748.5409)"
                d="M-339.5-435.9c0-2.4-2-4.3-4.4-4.3c-2.4,0-4.4,1.9-4.4,4.3v21.1c0,2.4,2,4.3,4.4,4.3c2.4,0,4.4-1.9,4.4-4.3V-435.9z"
              />
              <path
                transform="translate(123.5817,744.2304)"
                d="M-324.2-419.6c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.4,1.9-4.4,4.3v9.1c0,2.4,2,4.4,4.4,4.4c2.4,0,4.3-1.9,4.3-4.4V-419.6z"
              />
              <path
                transform="translate(132.195,748.5409)"
                d="M-308.9-435.9c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v21.1c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3 C-308.9-414.8-308.9-435.9-308.9-435.9z"
              />
              <path
                transform="translate(140.8102,754.4384)"
                d="M-293.6-458.2c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v46.1c0,2.4,1.9,4.4,4.3,4.4s4.3-1.9,4.3-4.4V-458.2z"
              />
              <path
                transform="translate(149.4235,748.5409)"
                d="M-278.3-435.9c0-2.4-1.9-4.3-4.4-4.3c-2.4,0-4.3,1.9-4.3,4.3v21.1c0,2.4,1.9,4.3,4.3,4.3c2.5,0,4.4-1.9,4.4-4.3 C-278.3-414.8-278.3-435.9-278.3-435.9z"
              />
              <path
                transform="translate(158.0192,744.2304)"
                d="M-263-419.6c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v9.1c0,2.4,1.9,4.4,4.3,4.4s4.3-1.9,4.3-4.4V-419.6z"
              />
            </g>
            <g class="logo__type">
              <path
                transform="translate(102.0426,727.2001)"
                d="M-362.5-355.3c-0.4-0.2-3.2-1.9-7.4-1.9c-5.7,0-9.6,3.9-9.6,9.3c0,5.2,3.8,9.3,9.6,9.3c4.1,0,7-1.6,7.4-1.8v9.3 c-1.1,0.3-4.1,1.2-8,1.2c-9.9,0-18.5-6.8-18.5-18c0-10.4,7.8-18,18.5-18c4.1,0,7.2,1,8,1.2V-355.3z"
              />
              <path d="M-239.7,396.7h-8.8v-34.8h8.8V396.7z" />
              <path
                transform="translate(121.5124,727.9413)"
                d="M-327.9-358.1c-0.1,0-3.8-1.1-6.9-1.1c-3.5,0-5.4,1.2-5.4,2.8c0,2.1,2.6,2.9,4,3.3l2.4,0.8c5.7,1.8,8.3,5.7,8.3,9.9 c0,8.7-7.7,11.7-14.4,11.7c-4.7,0-9-0.9-9.5-1v-8c0.8,0.2,4.4,1.3,8.3,1.3c4.4,0,6.4-1.3,6.4-3.2c0-1.8-1.7-2.8-3.9-3.5 c-0.5-0.2-1.3-0.4-1.9-0.6c-4.9-1.5-9-4.4-9-10.2c0-6.5,4.9-10.9,12.9-10.9c4.3,0,8.3,1,8.5,1.1v7.6H-327.9z"
              />
              <path
                transform="translate(134.9958,727.2001)"
                d="M-303.9-355.3c-0.4-0.2-3.2-1.9-7.4-1.9c-5.7,0-9.6,3.9-9.6,9.3c0,5.2,3.8,9.3,9.6,9.3c4.1,0,7-1.6,7.4-1.8v9.3 c-1.1,0.3-4.1,1.2-8,1.2c-9.9,0-18.5-6.8-18.5-18c0-10.4,7.8-18,18.5-18c4.1,0,7.2,1,8,1.2V-355.3z"
              />
              <path
                transform="translate(144.9274,727.8212)"
                d="M-286.3-357.7c-5.2,0-9.1,4.1-9.1,9.1c0,5.1,3.9,9.1,9.1,9.1s9.1-4.1,9.1-9.1S-281.1-357.7-286.3-357.7 M-267.9-348.5 c0,9.9-7.7,18-18.4,18s-18.4-8.1-18.4-18s7.7-18,18.4-18S-267.9-358.4-267.9-348.5"
              />
            </g>
          </svg>
        </div>
        <div
          class="
            w-full
            h-full
            mt-4
            top-1/2
            my-0
            mx-auto
            block
            relative
            text-center text-xl
            font-semibold
            text-gray-200
          "
        >
          Unpromoting {{ totalActivitiesToComplete }} activities
        </div>
      </div>
    </div>

    <phase-modal
      v-if="isModalVisible && selectedInitiative"
      :initiative="selectedInitiative"
      :allInitiatives="initiatives"
      :activitiesToUpdate="activitiesToUpdate"
      @close="closeModal"
      @markComplete="completeActivity"
      @getSeletedPhaseData="getSeletedPhaseData"
    ></phase-modal>

    <inactive-confirm-bulk
    :openInactive="openActiveInactiveModal"
    @openInactiveModal="openArchiveModal"
    @activateInactive="activateInactive"
    :markType="markType"
    :count="bulkOperationObject.initiativeArray.length"
    ></inactive-confirm-bulk>

    <toaster
    :header="toaster.header"
    :subheader="toaster.subheader"
    :text="toaster.text"
    :type="toaster.type"
    />

    <bulk-info
    :openBulkInfo="openBulkInfoModal"
    @openBulkInfoModel="openBulkInfoModel"
    :headerText="headerText"
    :infoText="infoText"
    :footerText="footerText"
    ></bulk-info>

    <assign-judges-bulk
    :count="bulkOperationObject.initiativeArray.length"
    :openAssignJudges="openAssignJudges"
    @openAssignJudgesModal="openAssignJudgesModal"
    :currentPhaseIndexForBulkJudging="currentPhaseIndexForBulkJudging"
    :phaseTeamMembers="bulkPhaseTeamMembers"
    :challengeTypes="challengeTypes"
    :initiatives="allInitiatives" 
    @bulkJudgeAssignment="bulkJudgeAssignment"
    ></assign-judges-bulk>
  </div>
  <loader-view
      v-if="isPhaseModalLoader"
      :loaderFlag="isPhaseModalLoader"
    >
    </loader-view>
</template>
<script>
import { mapGetters } from "vuex";

import PhaseModal from "../components/progressView/PhaseModal.vue";
import InitiativeCard from "../components/progressView/InitiativeCard.vue";
import ProgressFilter from "../components/JudgingPanel/ProgressFilter.vue";
import InfoCard from "../components/InfoCard.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import InactiveConfirmBulk from "../components/confirmationModal/InactiveConfirmBulk.vue";
import BulkInfo from "../components/confirmationModal/BulkInfo.vue";
import Toaster from "../components/Toaster.vue";
import AssignJudgesBulk from "../components/Modal/AssignJudgesBulk.vue";

export default {
  data() {
    return {
      isFilter: false,
      isModalVisible: false,
      currentPhaseName: "",
      setCurrentPhaseFlag: false,
      currentPhaseIndex: "",
      filterData: { marketHorizon: "" },
      changeInputText: false,
      isLoader: false,
      isPhaseModalLoader:false,
      isPostIdeaFlag: true,
      searchQuery: {},
      openActiveInactiveModal: false,
      markType: "",
      onManageClicked:false,
      judgingStatus:[],
      bulkOperationObject: {
        initiativeArray:[],
        phaseArray:[],
      },
      toaster:{},
      openBulkInfoModal:false,
      headerText:"",
      infoText:"",
      footerText:"",
      openAssignJudges: false,
      currentPhaseIndexForBulkJudging:null,
      bulkPhaseTeamMembers:[],
      allInitiatives:[],
      challengeTypes:[],
    };
  },
  components: {
    PhaseModal,
    InitiativeCard,
    ProgressFilter,
    InfoCard,
    LoaderView,
    InactiveConfirmBulk,
    Toaster,
    BulkInfo,
    AssignJudgesBulk
  },
  mounted: function () {
    this.$nextTick(async function () {
      if(this.payloadForProgressFilterQuery){
        await this.$store.dispatch("setPayloadForProgressFilterQuery", null);
      }
      let query = {
        status: [1],
      };
      let payload = {
        currentPage: 1,
        searchQuery: query,
        isFilter: true,
      };
      this.$store.dispatch("getInitiativesByPhase", payload);
      this.$store.dispatch("resetSelectedInitiative");
      this.$store.dispatch("resetActivitiesToUpdateList");
    });
  },
  computed: {
    ...mapGetters([
      "initiatives",
      "selectedInitiative",
      "initiativePromoteInAction",
      "initiativeUnpromoteInAction",
      "totalActivitiesToComplete",
      "activitiesToUpdate",
      "completedActivities",
      "loadingFlag",
      "playbook",
      "user",
      "payloadForProgressFilterQuery",
      "phaseTeamMembers"
    ]),
  },
  methods: {
    setLoader(value) {
      this.isPostIdeaFlag = value;
    },
    getInitiativesbyPhaseName(phaseName, phaseIndex) {
      return this.initiatives.filter(function (item) {
        // let action = item?.subInitiatives[item?.subInitiatives?.length -1]?.metaData?.type
        return item.phaseIndex == phaseIndex;
        //return item.phaseIndex == phaseIndex && action !== 'moveToInactiveAndMerge' && action !== 'promoteToNextPhaseAndMerge';
        // return item;
      });
    },
    async selectInitiative(id) {
      this.isPhaseModalLoader=true;
      await this.$store.dispatch("getInitiativeById", id);
      await this.$store.dispatch("getInitiativeLogs", id); 
      this.isModalVisible = true;
      this.isPhaseModalLoader=false;
    },
    getSeletedPhaseData(id, currentPhaseIndex, updatedPhaseIndex, initiative) {
      let flag;
      if (this.currentPhaseIndex === currentPhaseIndex) {
        flag = false;
      } else {
        flag = true;
      }
      let payload = {
        id: id,
        isPhaseChanged: flag,
        currentPhaseIndex: currentPhaseIndex,
        isRedirectToActivities: true,
      };
      this.$store.dispatch("getInitiativeById", payload);
      this.$store.dispatch("getInitiativeLogs", id);
      // this.isModalVisible = true;
    },
    async closeModal() {
      this.$store.dispatch("resetSelectedInitiative");
      await this.getByPhaseDetails();
      this.isModalVisible = false;
      this.setCurrentPhaseFlag = false;
    },
    async completeActivity(activity) {
      await this.$store.dispatch("updateActivity", activity);
      this.$store.dispatch("getInitiativeLogs", this.selectedInitiative.id);
      this.$store.dispatch("getInitiativeEvents", activity.id);
    },
    async promote(initiativeData) {
      this.setLoader(false);
      await this.$store.dispatch("promoteInitiative", initiativeData);
      await this.getByPhaseDetails();
    },
    async unpromote(initiativeData) {
      this.setLoader(false);
      await this.$store.dispatch("unpromoteInitiative", initiativeData);
      await this.getByPhaseDetails();
    },
    setJudgingStatusFilter(value){
      this.judgingStatus=value;
    },
    async judging(initiativeData) {
      await this.$store.dispatch("startJudgingInitiative", initiativeData);

      let payload = {
        _id: initiativeData?.initiative?._id,
        metaData: {
          comment: "Judging is Started!",
          date: new Date(),
          updatedBy: this.user.aspenifyUserId,
          actionType: "start",
        },
      };
      await this.$store.dispatch("updateInitiativeMeta", payload);

      // this.selectInitiative(initiativeData.id)
    },
    async canceljudging(initiativeData) {
      let updateFeedback = {};
      updateFeedback.comment = "Judging is cancelled!";
      updateFeedback.date = new Date();
      updateFeedback.updatedBy = this.user.aspenifyUserId;
      updateFeedback.actionType = "cancel";

      let pay = {
        _id: initiativeData?.subInit?._id,
        status: 6,
        refreshBoard: true,
      };
      this.$store.dispatch("updateInitiativeStatus", pay);

      let payload = {
        _id: initiativeData?.initiative?._id,
        metaData: updateFeedback,
      };
      await this.$store.dispatch("updateInitiativeMeta", payload);
    },
    endJudging(initiativeData) {
      // this.$store.dispatch("startJudgingInitiative", initiativeData);
    },
    archive(initiative) {
      this.$store.dispatch("archiveInitiative", initiative);
    },
    close(initiativeData) {
      this.$store.dispatch("promoteInitiative", initiativeData);
    },
    updateLayout(layout) {
      this.layout = layout;
    },
    async queryFilter(queryParams) {
      await this.$store.dispatch("getInitiativesByPhase");
      this.resetBulkOperationObject()
    },
    removeFilterNameAfterModalClose(flagValue) {
      this.changeInputText = flagValue;
    },
    removeLoaderOnSeach(flagValue) {
      this.isLoader = flagValue;
    },
    setSearchQuery(query) {
      this.searchQuery = query;
      this.resetBulkOperationObject()
    },
    async checkSearchQuery(){
      let payload = {
        currentPage: 1,
        searchQuery: this.searchQuery,
        // sortBy: this.sortBy,
        isFilter: true,
      };
      await this.$store.dispatch("getInitiativesByPhase", payload);
    },
    async getByPhaseDetails() {
      let payload = {
        currentPage: 1,
        searchQuery: { status: [1] },
        isFilter: true,
      };
      if(this.payloadForProgressFilterQuery){
        await this.$store.dispatch("getInitiativesByPhase", this.payloadForProgressFilterQuery);
        await this.setFilterForJudgingStatus(this.judgingStatus)
      } else {
      await this.$store.dispatch("getInitiativesByPhase", payload);
      this.setLoader(true);
      }
      this.resetBulkOperationObject()
    },
    async setFilterForJudgingStatus(judgingStatus){
      if(judgingStatus.length>0){
        const filteredInitiatives=[];
        if(judgingStatus.includes("Not Started")){
          this.initiatives?.forEach(initiative => {
           if(initiative?.subInitiatives?.length>0){
             for(let subIdea of initiative.subInitiatives){
              if(subIdea.teamId == initiative?.seletedPhaseJudgesDetails?._id){
                if(!subIdea?.metaData?.reviews){
                filteredInitiatives.push(initiative);
              }
             }
            }
           }
          });
        }
        if(judgingStatus.includes("In Progress")){
          this.initiatives?.forEach(initiative => {
           if(initiative?.subInitiatives?.length>0){
             for(let subIdea of initiative.subInitiatives){
              if(subIdea.teamId == initiative?.seletedPhaseJudgesDetails?._id){
               if(subIdea?.metaData?.reviews?.length > 0 && subIdea?.metaData?.reviews?.length < initiative?.seletedPhaseJudgesDetails?.members?.length){
                filteredInitiatives.push(initiative);
              }
             }
            }
           }
          });
        }
        if(judgingStatus.includes("Completed")){
          this.initiatives?.forEach(initiative => {
           if(initiative?.subInitiatives?.length>0){
             for(let subIdea of initiative.subInitiatives){
              if(subIdea.teamId == initiative?.seletedPhaseJudgesDetails?._id){
               if(subIdea?.metaData?.reviews?.length === initiative?.seletedPhaseJudgesDetails?.members?.length){
                filteredInitiatives.push(initiative);
              }
             }
            }
           }
          });
        }
        await this.$store.dispatch("setUpdateInitiatives", filteredInitiatives);
      }
    },
    back() {
      // To go to the previous step and close the Phase modal
      this.isModalVisible = false;
    },
    onManageClick(){
      this.onManageClicked=!this.onManageClicked
    },
    onMakeActiveClick(){
      let activeSubmissionsPresent=this.bulkOperationObject.initiativeArray.some(initiative=>initiative.status===1)
      if(this.bulkOperationObject.initiativeArray.length===0){
        this.openBulkInfoModel(true,"zeroInitiatives")
      } else if(activeSubmissionsPresent){
        this.openBulkInfoModel(true,"activeSubmissions")
      } else {
      this.markType = 'active'
      this.openActiveInactiveModal = true;
      }
    },
    onMakeInactiveClick(){
      let inActiveSubmissionsPresent=this.bulkOperationObject.initiativeArray.some(initiative=>initiative.status===6)
      if(this.bulkOperationObject.initiativeArray.length===0){
        this.openBulkInfoModel(true,"zeroInitiatives")
      } else if(inActiveSubmissionsPresent){
        this.openBulkInfoModel(true,"inActiveSubmissions")
      } else {
      this.markType = 'inactive'
      this.openActiveInactiveModal = true;
      }
    },
    async onAssignClick(){
      let inActiveSubmissionsPresent=this.bulkOperationObject.initiativeArray.some(initiative=>initiative.status===6)
      let phaseIndexArr=[]
      this.bulkOperationObject.initiativeArray.map(init=>phaseIndexArr.push(init.phaseIndex))
      let isJudgingRequired= this.bulkOperationObject.initiativeArray.some(initiative=>initiative.nextPhaseInfo?.metaData?.isJudgingRequired)
      const noDups = new Set(phaseIndexArr);
      this.challengeTypes = this.payloadForProgressFilterQuery?.searchQuery["metaData.challengeType"].$in;
      if(!this.challengeTypes || this.challengeTypes.length === 0){
        this.openBulkInfoModel(true,"noChallengeTypes")
      }else if(!this.challengeTypes || this.challengeTypes.length > 1){
        this.openBulkInfoModel(true,"multipleChallengeTypes")
      }else if(this.bulkOperationObject.initiativeArray.length===0){
        this.openBulkInfoModel(true,"zeroInitiatives")
      } else if(inActiveSubmissionsPresent){
        this.openBulkInfoModel(true,"inActiveSubmissions")
      }else if(noDups.size>1){
        this.openBulkInfoModel(true,"moreThanOnePhase")
      }else if(!isJudgingRequired){
        this.openBulkInfoModel(true,"noJudgingRequired")
      } else {
        let query = {};
        let payload = {
          currentPage: 1,
          searchQuery: query,
          isFilter: true,
        };
        this.allInitiatives = await this.$store.dispatch("getInitiativesByPhaseForBulkAssignment", payload);
        await this.$store.dispatch("getTeamMembers", this.bulkOperationObject.initiativeArray[0]);
        this.bulkPhaseTeamMembers=this.phaseTeamMembers.map(member=>{
          let judge=JSON.parse(JSON.stringify(member))
          judge.isChecked=false
          return judge})
        this.markType = 'assignJudges'
        this.currentPhaseIndexForBulkJudging=[...noDups.values()][0]
        this.openAssignJudgesModal(true);
        
      }
    },
    onUnpromoteClick(){
      let inActiveSubmissionsPresent=this.bulkOperationObject.initiativeArray.some(initiative=>initiative.status===6)
      let phaseIndexArr=[]
      this.bulkOperationObject.initiativeArray.map(init=>phaseIndexArr.push(init.phaseIndex))
      const noDups = new Set(phaseIndexArr);
      if(this.bulkOperationObject.initiativeArray.length===0){
        this.openBulkInfoModel(true,"zeroInitiatives")
      } else if(inActiveSubmissionsPresent){
        this.openBulkInfoModel(true,"inActiveSubmissions")
      }else if(noDups.size>1){
        this.openBulkInfoModel(true,"moreThanOnePhase")
      }else if(noDups.has(0)){
        this.openBulkInfoModel(true,"firstPhase")
      } else {
      this.markType = 'unpromote'
      this.openActiveInactiveModal = true;
      }
    },
    onPromoteClick(){
      let inActiveSubmissionsPresent=this.bulkOperationObject.initiativeArray.some(initiative=>initiative.status===6)
      let phaseIndexArr=[]
      this.bulkOperationObject.initiativeArray.map(init=>phaseIndexArr.push(init.phaseIndex))
      const noDups = new Set(phaseIndexArr);
      if(this.bulkOperationObject.initiativeArray.length===0){
        this.openBulkInfoModel(true,"zeroInitiatives")
      } else if(inActiveSubmissionsPresent){
        this.openBulkInfoModel(true,"inActiveSubmissions")
      }else if(noDups.size>1){
        this.openBulkInfoModel(true,"moreThanOnePhase")
      }else if(noDups.has(this.playbook.activities.length - 1)){
        this.openBulkInfoModel(true,"lastPhase")
      } else {
      this.markType = 'promote'
      this.openActiveInactiveModal = true;
      }
    },
    openArchiveModal(value) {
      this.openActiveInactiveModal = value;
    },
    async activateInactive( markType , comment) {
      this.openActiveInactiveModal=false
      this.setLoader(false);
      let notPromotedInitiatives=[]
      if (markType === "active") {
        for (let index = 0; index < this.bulkOperationObject.initiativeArray.length; index++) {
          let initiative = this.bulkOperationObject.initiativeArray[index]
          if (initiative.status == 6) {
            let data = {
              selectedInitiative: initiative,
              comment: comment
            }
            data.selectedInitiative.id = data.selectedInitiative._id;
            if (data.selectedInitiative.status === 6) {
              data.status = 1;
              let payload = {
                _id: data.selectedInitiative._id,
                metaData: {
                  isMerged: false,
                  actionLabel: "inactive",
                  comment: data.comment,
                  date: new Date(),
                  type: "inactive",
                  updatedBy: this.user.aspenifyUserId,
                },
              };              
              this.toaster = {
                header:"Making Submission Active",
                subheader:`Made ${index+1}/${this.bulkOperationObject.initiativeArray.length} Submission Active`,
                text:"",
                type:"success"
              }
              await this.$store.dispatch("updateInitiativeMeta", payload);
              await this.$store.dispatch("inactivateIdeaMainIdea", data);
            }
          }
        }
      }
      if (markType === "inactive") {
        for (let index = 0; index < this.bulkOperationObject.initiativeArray.length; index++) {
          let initiative = this.bulkOperationObject.initiativeArray[index]
          if (initiative.status == 1) {
            let data = {
              selectedInitiative: initiative,
              comment: comment
            }
            data.selectedInitiative.id = data.selectedInitiative._id;
            if (data.selectedInitiative.status === 1) {
              data.status = 6;
              let payload = {
                _id: data.selectedInitiative._id,
                metaData: {
                  isMerged: false,
                  actionLabel: "inactive",
                  comment: data.comment,
                  date: new Date(),
                  type: "inactive",
                  updatedBy: this.user.aspenifyUserId,
                },
              };
              this.toaster = {
                header:"Making Submission Inactive",
                subheader:`Made ${index+1}/${this.bulkOperationObject.initiativeArray.length} Submission Inactive`,
                text:"",
                type:"success"
              }
              await this.$store.dispatch("updateInitiativeMeta", payload);
              await this.$store.dispatch("inactivateIdeaMainIdea", data);
            }
          }
        }
      }
      if(markType=== "unpromote"){
        for (let index = 0; index < this.bulkOperationObject.initiativeArray.length; index++) {
          let initiative = this.bulkOperationObject.initiativeArray[index]
          if (initiative.status == 1) {
          let initiativeData={
            initiative,
            phaseIndex: initiative.phaseIndex,
            comment,
            isCloseFlag: false,
            isBulkUnPromote:true,
            }
            this.toaster = {
                header:"Unpromoting Submissions",
                subheader:`Unpromoted ${index+1}/${this.bulkOperationObject.initiativeArray.length} Submissions`,
                text:"",
                type:"success"
              }
            await this.$store.dispatch("unpromoteInitiative", initiativeData);
          }
        }
      }
      if(markType=== "promote"){
        for (let index = 0; index < this.bulkOperationObject.initiativeArray.length; index++) {
          let initiative = this.bulkOperationObject.initiativeArray[index]
          if (initiative.status == 1) {
              let isJudgeCheckPassed=false
              if(initiative.nextPhaseInfo?.metaData?.isJudgingRequired){
                if(initiative.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
                  isJudgeCheckPassed= true;
                } else {
                  isJudgeCheckPassed= false ;
                }
              } else {
                isJudgeCheckPassed= true;
              }
              if(isJudgeCheckPassed){
                let initiativeData={
                  initiative,
                  phaseIndex: initiative.phaseIndex,
                  comment,
                  isCloseFlag: false
                  }
                  this.toaster = {
                      header:"Promoting Submissions",
                      subheader:`Promoted ${index+1}/${this.bulkOperationObject.initiativeArray.length} Submissions`,
                      text:"",
                      type:"success"
                }
                await this.$store.dispatch("promoteInitiative", initiativeData);
              } else {
                notPromotedInitiatives.push(initiative)
              }
            }
        }
    }
      await this.getByPhaseDetails();
      this.bulkOperationObject.initiativeArray=[],
      this.bulkOperationObject.phaseArray=[],
      this.resetBulkOperationObject()
      ;
      if(notPromotedInitiatives.length>0){
        this.openBulkInfoModel(true,"notPromotedInitiatives",notPromotedInitiatives)
      }
      setTimeout(() => {
        this.toaster={};
      }, 1000);
    },
    onChangeCheckbox(initiatives,activityName,phaseIndex){
      let phaseExistIndex=this.bulkOperationObject.phaseArray.indexOf(activityName)
      if(phaseExistIndex===-1){
        this.bulkOperationObject.phaseArray.push(activityName)
        initiatives.map(initiative=>{
        let index=this.bulkOperationObject.initiativeArray.findIndex(init=>init._id===initiative._id)
        if(index===-1){
          this.bulkOperationObject.initiativeArray.push(initiative)
        }
        })
      } else {
        this.bulkOperationObject.phaseArray= this.bulkOperationObject.phaseArray.filter(ele=>ele!==activityName)
        initiatives.map(initiative=>{
          this.bulkOperationObject.initiativeArray= this.bulkOperationObject.initiativeArray.filter(ele=>ele._id!==initiative._id)
        })
      }
    },
    onChangeCheckboxForInitiative(initiative,value,phaseIndex){
      if(value){
        this.bulkOperationObject.initiativeArray.push(initiative)
        let currentPhaseInitiatives = this.initiatives.filter(init=>init.phase === initiative.phase);
        let currentPhaseSelectedinitiatives = this.bulkOperationObject.initiativeArray.filter(init=>init.phase === initiative.phase);
        if(currentPhaseSelectedinitiatives.length === currentPhaseInitiatives.length){
          this.bulkOperationObject.phaseArray.push(initiative.phase)
        }
      }else {
        this.bulkOperationObject.phaseArray= this.bulkOperationObject.phaseArray.filter(ele=>ele !== initiative.phase)
        this.bulkOperationObject.initiativeArray= this.bulkOperationObject.initiativeArray.filter(ele=>ele._id!==initiative._id)
      }
    },
    isInitiativeChecked(initiative) {
      let isInitiativeExist= this.bulkOperationObject.initiativeArray.filter(ele=>ele._id===initiative._id)
      return isInitiativeExist.length>0;
    },
    isPhaseChecked(activityName) {
      let phaseExistIndex=this.bulkOperationObject.phaseArray.indexOf(activityName)
      if (phaseExistIndex!=-1){
        return true
      } 
      return false
    },
    openBulkInfoModel(value,type,notPromotedInitiatives) {
      if(type=="zeroInitiatives"){
        this.headerText = `Error - No initiatives selected`
        this.infoText = `<p>Please select initiatives before bulk update operation</p>`;
        this.footerText = ``;
      }
      if(type=="activeSubmissions"){
        this.headerText = `Error`
        this.infoText = `<p>Please select only inactive submissions</p>`;
        this.footerText = ``;
      }
      if(type=="inActiveSubmissions"){
        this.headerText = `Error`
        this.infoText = `<p>Please select only active submissions</p>`;
        this.footerText = ``;
      }
      if(type=="noJudgingRequired"){
        this.headerText = `Error`
        this.infoText = `<p>No judging required for this phase</p>`;
        this.footerText = ``;
      }
      if(type=="moreThanOnePhase"){
        this.headerText = `Error`
        this.infoText = `<p>Please select initiatives from single phase for bulk operation</p>`;
        this.footerText = ``;
      }
      if(type=="firstPhase"){
        this.headerText = `Error`
        this.infoText = `<p>Cannot Unpromote initiatives from this phase</p>`;
        this.footerText = ``;
      }
      if(type=="lastPhase"){
        this.headerText = `Error`
        this.infoText = `<p>Cannot Promote initiatives from this phase</p>`;
        this.footerText = ``;
      }
      if(type == "noChallengeTypes"){
        this.headerText = `Error`
        this.infoText = `<p>Please select one category from filters to proceed for bulk assign judges</p>`;
        this.footerText = ``;
      }
      if(type == "multipleChallengeTypes"){
        this.headerText = `Error`
        this.infoText = `<p>Please select only one category from filters to proceed for bulk assign judges</p>`;
        this.footerText = ``;
      }
      if(type=="notPromotedInitiatives"){
        let initiatives=""
        notPromotedInitiatives.map(init=>{
          initiatives+=init.name+", "
        })
        this.headerText = `Error`
        this.infoText = `<p>Please Assign Judges to following initiatives : \n ${initiatives.slice(0,-1)}</p>`;
        this.footerText = ``;
      }
      if(type=="notAssignedJudgeInitiatives"){
        let initiatives=""
        notPromotedInitiatives.map(init=>{
          initiatives+=`<tr class=border><td class="border p-2">${init.name}</td><td class="border p-2">${init.judge}</td><td class="border p-2">Submission Category ${init?.metaData?.challengeType}</td></tr>`
        })
        this.headerText = `Error`
        this.infoText = `<p>Judges could not be assigned to following submissions </p><br/>
                        <table class="border"><tr class="border-2"><th class="border p-2">Submission Title</th><th class="border p-2">Name of Judges</th><th class="border p-2">Reason</th></tr>${initiatives}</table>`;
        this.footerText = ``;
      }
      this.openBulkInfoModal = value;
    },
    resetBulkOperationObject() {
      this.bulkOperationObject={
        initiativeArray:[],
        phaseArray:[],
      }
    },
    openAssignJudgesModal(value) {
      this.openAssignJudges = value;
    },
    async bulkJudgeAssignment(currentPhaseIndex,phaseTeamMembers){
      this.setLoader(false);
      let notAssignedJudgeInitiatives=[]
      let members=[];
      for (let index = 0; index < this.bulkOperationObject.initiativeArray.length; index++) {
          let initiative = this.bulkOperationObject.initiativeArray[index]
          this.allInitiatives?.forEach((item)=>{
            if(item?.metaData?.challengeType === initiative?.metaData?.challengeType){
              item?.metaData?.teamLead?.forEach((lead)=>{members.push(lead.email)})
              item?.metaData?.teamMembers?.forEach((member)=>{members.push(member.email)})
              item?.metaData?.submitter?.forEach((member)=>{members.push(member.email)})
            }
          })
          let users = phaseTeamMembers.filter((member)=>{
              return member.isChecked;
          })
          const filteredArray = users.filter(judge => members.includes(judge.email))          
          if (initiative.status == 1 && filteredArray.length<1) {
            let data={}
            let payload = {
              "name" : initiative?.nextPhaseInfo?.name,
              "ideaId" : initiative?._id,
              "phaseId" : initiative?.nextPhaseInfo?._id,
              "selectedJudgesTeamId" : initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId
            }
            data.currentPhaseIndex=currentPhaseIndex;
            data.initiative=initiative;
            data.phaseTeamMembers=phaseTeamMembers;
            payload.users = users;
            let record = {};
            record.data = data;
            record.payload = payload;
            record.isBulk = true;
            this.toaster = {
              header:"Assigning Judges",
              subheader:`Assigned Judges to ${index+1}/${this.bulkOperationObject.initiativeArray.length} Submissions`,
              text:"",
              type:"success"
            }
            await this.$store.dispatch("manageTeam", record,true);
        } else {
          if(filteredArray.length>0){
            initiative.judge = "";
            filteredArray.forEach((judge)=>{
            initiative.judge += `${judge.firstName} ${judge.lastName}, `
          })
          }
          notAssignedJudgeInitiatives.push(initiative)
        }
      }
      this.openAssignJudgesModal(false);
      await this.getByPhaseDetails();
      this.bulkOperationObject.initiativeArray=[],
      this.bulkOperationObject.phaseArray=[],
      this.resetBulkOperationObject()
      if(notAssignedJudgeInitiatives.length>0){
        this.openBulkInfoModel(true,"notAssignedJudgeInitiatives",notAssignedJudgeInitiatives)
      }
      setTimeout(() => {
        this.toaster={};
      }, 1000);
      ;
  }
  },
  created: function () {
    if (!this.setCurrentPhaseFlag && this.selectedInitiative) {
      this.setCurrentPhaseFlag = true;
      this.currentPhaseName = this.selectedInitiative.phase;
      this.currentPhaseIndex = this.selectedInitiative.phaseIndex;
    }

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.back() // method for browser back button
      next()
    })
  },
};
</script>
<style scoped>
.kanban-list {
  overflow: auto;
  max-height: calc(100vh - 12rem);
  min-height: calc(100vh - 12rem);
}
.hide-scroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.phase-title {
  font-size: 1.25rem;
}
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@-webkit-keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}
@keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}
.logo {
  width: 180px;
  fill: #c6c7ca;
}

.logo__mark > path {
  animation: loading 1.25s ease-in-out infinite reverse;
}

.logo__mark > path:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.logo__mark > path:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.logo__mark > path:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.logo__mark > path:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.logo__mark > path:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.logo__mark > path:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.logo__mark > path:nth-child(7) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.logo__mark > path:nth-child(8) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.logo__mark > path:nth-child(9) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
</style>
