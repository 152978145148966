const LeaderBoard = [
  {
    name: "Liz Centoni",
    participants: 132,
    ideate: 73,
    validate: 29,
    scope: 9,
    incubate: 5,
    total: 116,
  },
  {
    name: "Maria Martinez",
    participants: 189,
    ideate: 96,
    validate: 7,
    scope: 1,
    incubate: null,
    total: 104,
  },
  {
    name: "Gerri Elliott",
    participants: 136,
    ideate: 63,
    validate: 7,
    scope: 1,
    incubate: 1,
    total: 72,
  },
  {
    name: "Todd Nightingale",
    participants: 67,
    ideate: 36,
    validate: null,
    scope: null,
    incubate: null,
    total: 36,
  },
  {
    name: "Jeetu Patel",
    participants: 55,
    ideate: 20,
    validate: 2,
    scope: null,
    incubate: null,
    total: 22,
  },
  {
    name: "Jonathan Davidson",
    participants: 15,
    ideate: 7,
    validate: null,
    scope: null,
    incubate: null,
    total: 7,
  },
  {
    name: "Eyal Dagan",
    participants: 11,
    ideate: 7,
    validate: null,
    scope: null,
    incubate: null,
    total: 7,
  },
  {
    name: "Fran Katsoudas",
    participants: 7,
    ideate: 1,
    validate: null,
    scope: null,
    incubate: null,
    total: 1,
  },
  {
    name: "Dev Stahlkopf",
    participants: 4,
    ideate: 1,
    validate: null,
    scope: null,
    incubate: null,
    total: 1,
  },
  {
    name: "Scott Herren",
    participants: 3,
    ideate: 1,
    validate: null,
    scope: null,
    incubate: null,
    total: 1,
  },
  
];
export default LeaderBoard;

// Leader	Participants	Ideate	Validate	Scope	Incubate	Total
// Liz Centoni	132	73	29	9	5	116
// Maria Martinez	189	96	7	1		104
// Gerri Elliott	136	63	7	1	1	72
// Todd Nightingale	67	36				36
// Jeetu Patel	55	20	2			22
// Jonathan Davidson	15	7				7
// Eyal Dagan	11	7				7
// Fran Katsoudas	7	1				1
// Dev Stahlkopf	4	1				1
// Scott Herren	3	1				1
