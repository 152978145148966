
  <template>
  <div class="block">
    <div
      v-if="validationErros && validationErros[validate]"
      class="text-left float-left text-sm text-red-500"
    >
      {{ validationErros[validate][0] }}
    </div>
    <div class="text-sm text-gray-500 text-right float-right">
      <span v-if="nameLength <= count">
        <span v-if="(this.label!=='testimonialAwardsIpAndOpenSource' && this.label!== 'description' &&this.label!== 'problemDescription' && this.label!=='elevatorPitch' 
      && this.label!=='aboutTeam' && this.label!=='potentialMarket')">Remaining
        <b>{{ count - nameLength+1 }} / {{ count+1 }}</b> {{this.label}} characters.</span
      ></span>
      <!-- <span v-else-if="nameLength > count" class="text-red-400">
      {{ alertbox()  }}
      Character limit exceeded by {{ nameLength - count }}
      </span> -->

      <span v-else-if="(nextButtonClicked && nameLength == count+2) || (!nextButtonClicked && nameLength >= count+1)" class="">
        Character limit reached.
        {{ alertbox(nameLength, count) }}
      </span>
      <!-- <span v-else>Max. {{ count+1 }} characters</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "TextAreaError",
  props: {
    value: String,
    count: Number,
    validationErros: Array,
    validate: String,
    nextButtonClicked: Boolean,
    label:String
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    nameLength() {
      return this.value && this.value.length;
    },
  },
  methods: { 
    alertbox(nameLength, count) {
      if(count >= nameLength){
        alert("Reached maximum characters limit.");
      }
    },
  },
};
</script>

<style scoped>
</style>


