<template>
  <!-- <pre>{{initiative}}</pre> -->
  <div>
    <h3 class="font-bold mb-2">Phase History</h3>
    <div v-if="initiativeEventLoading">
      <circularloader />
      <!-- <h3>Loading Idea events</h3> -->
    </div>
    <div v-else>
      <div>
        <div class="mt-2">
          <nav class="flex flex-col sm:flex-row">
            <button
              @click="setActive('tab1')"
              class="
                flex-grow
                text-gray-600
                py-4
                px-6
                block
                hover:text-blue-500
                focus:outline-none
                border-b-2
              "
              :class="{
                'text-blue-500 border-b-2 font-medium border-blue-500':
                  activeItem === 'tab1',
              }"
            >
              Submission
            </button>
            <!-- <button
              @click="setActive('tab2')"
              class="
                flex-grow
                text-gray-600
                py-4
                px-6
                block
                hover:text-blue-500
                focus:outline-none
                border-b-2
              "
              :class="{
                'text-blue-500 border-b-2 font-medium border-blue-500':
                  activeItem === 'tab2',
              }"
            >
              Judging
            </button> -->
          </nav>
          <div
            class="p-3 overflow-y-auto overflow-x-hidden bg-gray-50"
            style="height: calc(100vh - 210px)"
          >
            <div v-if="activeItem === 'tab1'" id="tab1">
              <div v-if="selectedInitiativeEvents.length !== 0">
                <ul class="mt-4 list-reset flex flex-col">
                  <li
                    v-for="(item, index) in selectedInitiativeEvents
                      .slice()
                      .reverse()"
                    :key="index"
                    class="
                      rounded-md
                      relative
                      block
                      border
                      p-4
                      border-grey
                      mb-2
                      bg-white
                    "
                  >
                 
                    <div class="flex flex-row justify-between">
                      <div class="flex flex-row">
                        <img
                          class="w-10 h-10 mr-3 rounded-full cursor-pointer ring ring-gray-100 hover:shadow"
                          :src="item.user.profilePic"
                          alt=""
                          @click="goToProfile(item.user)"
                        />
                        <div class="flex flex-col">
                          <p class="text-sm text-gray-400 mb-1">
                            <span class="cursor-pointer hover:opacity-80" @click="goToProfile(item.user)">
                              {{ item.user.userName }}
                              </span> .
                            {{ timeago.format(new Date(item.createdAt)) }}
                          </p>
                          <p v-if="item.displayText" class="-mt-1">
                            {{ item.type }} - {{ item.activityName }} -
                            {{ item.displayText }}
                          </p>
                          <p v-else class="-mt-1">
                            {{ item.type }} - {{ item.activityName }}
                          </p>
                        </div>
                      </div>
                      <!-- <h1 class="text-sm text-gray-400 w-2/5 text-right">
                
              </h1> -->
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else>
                <info-card message="No History Available!" />
              </div>
            </div>
            <!-- <div
              v-if="
                activeItem === 'tab2'"
              id="tab2"
            >
            <div v-if="initiative?.subInitiatives[initiative?.subInitiatives?.length -1]">
              <feedback-history
                :selectedIdea="
                  initiative?.subInitiatives[initiative?.subInitiatives?.length -1]
                "
                :initiative="initiative"
                :user="user"
              />
            </div>
             <div v-else>
              <info-card message="Judging has not started yet!" />
            </div>
              
            </div> -->
           
          </div>
        </div>
      </div>
      <span class="text-white">{{ selectedInitiativeEvents.length }} </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as timeago from "timeago.js";
import InfoCard from "../InfoCard.vue";
import FeedbackHistory from "../JudgingPanel/FeedbackHistory.vue";
export default {
  name: "ActivityHistory",
  props: {
    initiative: Object,
    selectedIdea: [],
  },
  components: {
    InfoCard,
    FeedbackHistory,
  },
  data() {
    return {
      timeago,
      activeItem: "tab1",
    };
  },
  computed: {
    ...mapGetters([
      "initiativeEventLoading",
      "selectedInitiativeEvents",
      "selectedInitiative",
      "user",
    ]),
  },
  methods: {
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    goToProfile(user) {
      var name = user?.email?.toLowerCase()?.substring(
        0,
        user?.email.lastIndexOf("@")
      );
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?.id, userName: name },
      });
    },
  },
  created: function () {
    this.$store.dispatch("getInitiativeEvents", this.initiative.id);
  },
};
</script>
<style scoped>
</style>