<template>
  <TransitionRoot appear :show="jiraEpic" as="template">
    <Dialog as="div" :open="jiraEpic" @close="closeEpicModal">
      <div
        class="
          modal
          h-screen
          w-full
          fixed
          left-0
          top-0
          flex
          justify-center
          items-center
          bg-black bg-opacity-80
          g-opacity-50
          z-40
        "
      >
        <div class="bg-white rounded shadow-lg w-11/12 text-gray-600">
          <!-- height 580px -->
          <div
            class="
              border-b
              p-5
              bg-blue-900
              flex
              justify-between
              items-center
              rounded-t
            "
          >
            <h3 class="font-medium text-white text-lg">
              {{ initiative.phase }} - {{ initiative.name }}
            </h3>
            <button
              class="
                bg-blue-400
                text-sm text-white
                py-2
                px-2
                rounded-full
                focus:outline-none
              "
              @click="closeEpicModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div class="p-3 h-120">
            <div v-if="allEpics.issues && allEpics.issues.length > 0">
              <div class="flex">
                <button
                  class="text-blue-700 p-1 cursor-pointer"
                  @click="closeEpicModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
                <div class="ml-3">
                  <div class="">Assigned Project</div>
                  <div class="text-xl font-bold text-gray-600">
                    {{ selectedProject.name }}
                  </div>
                </div>
                <div class="ml-auto">
                  <input
                    class="border-4 rounded-2xl from_Input pl-1 mb-2"
                    type="text"
                    placeholder="Search Epic"
                    v-model="searchText"
                  />
                </div>
              </div>

              <table class="min-w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sort('summary')"
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                        w-3/6
                      "
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Key
                    </th>
                    <th
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Creator
                    </th>
                    <th
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Priority
                    </th>
                    <th
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-right text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      <button
                        v-if="allEpicsAreSelected"
                        @click="disSelecrAllEpics"
                        class="
                          bg-transparent
                          hover:bg-white-100
                          text-black-700
                          font-semibold
                          hover:text-black
                          py-2
                          px-4
                          border border-blue-500
                          rounded
                        "
                      >
                        DESELECT ALL({{ sortedProjects?.length }})
                      </button>
                      <button
                        v-else
                        @click="selectAllEpics"
                        class="
                          bg-blue-500
                          hover:bg-blue-700
                          text-white
                          font-bold
                          py-2
                          px-4
                          rounded
                        "
                      >
                        SELECT ALL({{ sortedProjects?.length }})
                      </button>
                    </th>
                    <!-- <th scope="col" class="relative px-2 py-2">
                      <span class="sr-only">Edit</span>
                    </th> -->
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(epic, index) in sortedProjects"
                    v-bind:key="index"
                  >
                    <td class="px-2 py-2 w-3/6" :key="index">
                      <div class="flex items-center">
                        <div>
                          <div class="text-sm font-medium text-gray-900">
                            {{ epic.fields.summary }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ epic.key }}
                      </div>
                    </td>
                    <td
                      class="px-2 py-2 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ epic.fields.creator.displayName }}
                    </td>
                    <td
                      class="px-2 py-2 whitespace-nowrap text-sm text-gray-500"
                    >
                      <!-- priority name  -->
                      {{ epic.fields.priority.name }}
                    </td>
                    <td
                      class="
                        px-2
                        py-2
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                        pr-5
                      "
                    >
                      <button
                        v-if="selectedEpics.some((el) => el.key === epic.key)"
                        @click="disselectEpic(epic)"
                        class="
                          bg-transparent
                          hover:bg-white-100
                          text-black-700
                          font-semibold
                          hover:text-black
                          py-2
                          px-4
                          border border-blue-500
                          rounded
                        "
                      >
                        Selected
                      </button>
                      <!-- else block -->
                      <button
                        v-else
                        @click="selectEpic(epic)"
                        class="
                          bg-blue-500
                          hover:bg-blue-700
                          text-white
                          font-bold
                          py-2
                          px-4
                          rounded
                        "
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex justify-center items-center font-bold" v-else>
              NO EPICS FOR THIS PROJECT
            </div>
          </div>

          <div v-if="allEpics.issues && allEpics.issues.length > 0" class="p-3">
            <div class="float-right mb-5 ml-5">
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  mr-5
                  mb-5
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
                @click="setAllSelectedEpics"
                style="margin-right: 20px"
              >
                Confirm
              </button>
            </div>
            <div class="float-right">
              <button
                @click="closeEpicModal"
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
              >
                Cancel
              </button>
            </div>
            <div class="float-right mr-5 font-bold text-center text-gray-500">
              {{ selectedEpics?.length }} jira epic selected from
              {{ sortedProjects.length }}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { functions } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "jira-epic",
  props: {
    jiraEpic: Boolean,
    projectKey: String,
    allEpics: Object,
    selectedProject: Object,
    selectedRelease: Object,
    previouslySelectedEpics: Array,
    changeAndViewEpicsFlag: Boolean,
    initiative: Boolean,
  },
  data() {
    return {
      selectedEpics: [],
      buttonCondition: true,
      flagTOUpdateValues: false,
      allEpicsAreSelected: false,
      closeModalValue: false,
      searchText: "",
      // below is for table filter
      currentSort: "summary",
      currentSortDir: "asc",
    };
  },
  methods: {
    selectEpic: function (selectedSingleEpic) {
      this.selectedEpics.filter((epic) => {
        if (epic.key === selectedSingleEpic.key) {
          this.buttonCondition = false;
        } else {
          this.buttonCondition = true;
        }
      });
      let singleEpic = {
        self: selectedSingleEpic.self,
        name: selectedSingleEpic.fields.summary,
        description: selectedSingleEpic.fields.description,
        key: selectedSingleEpic.key,
        creatorName: selectedSingleEpic.fields.creator.displayName,
        creatorsProfilePic: selectedSingleEpic.fields.creator.avatarUrls,
        // projectKey: selectedSingleEpic.fields.project.key,
      };
      this.selectedEpics.push(singleEpic);
      this.selectedEpics = _.uniqBy(this.selectedEpics, "key");
    },
    disselectEpic: function (epic) {
      this.$emit("changechangeAndViewEpicsFlagValue");
      this.selectedEpics = this.selectedEpics.filter((singleEpic) => {
        return singleEpic.key !== epic.key;
      });
      this.selectedEpics = _.uniqBy(this.selectedEpics, "key");
    },
    selectAllEpics: function () {
      if (
        this.allEpics &&
        this.allEpics.issues &&
        this.allEpics.issues.length > 0
      ) {
        this.allEpics.issues.forEach((epic) => {
          let singleEpic = {
            self: epic.self,
            name: epic.fields.summary,
            description: epic.fields.description,
            key: epic.key,
            creatorName: epic.fields.creator.displayName,
            creatorsProfilePic: epic.fields.creator.avatarUrls,
            // projectKey: selectedSingleEpic.fields.project.key,
          };
          this.selectedEpics.push(singleEpic);
        });
        this.allEpicsAreSelected = true;
      }
    },
    disSelecrAllEpics: function () {
      this.selectedEpics = [];
      this.allEpicsAreSelected = false;
      this.$emit("changechangeAndViewEpicsFlagValue");
    },
    setAllSelectedEpics: function () {
      this.$emit(
        "closeModalOnEpicSelection",
        this.selectedEpics,
        this.selectedProject,
        this.selectedRelease
      );
    },
    closeEpicModal: function () {
      this.$emit("close");
      this.allEpicsAreSelected = false;
      //on modal close, set all values to initial state
      this.selectedEpics = [];
      // this.flagTOUpdateValues = false;
      // this.closeModalValue = true;
      this.$emit("changechangeAndViewEpicsFlagValue");
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
  },
  created: async function () {},
  computed: {
    ...mapGetters([]),
    buttonColor: function (epic) {
      return this.buttonCondition
        ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        : "bg-transparent hover:bg-white-100 text-black-700 font-semibold hover:text-black py-2 px-4 border border-blue-500 rounded";
    },
    checkIfThisEpicIsSelectedOrNot: function (epic) {
      let result = this.selectedEpics.filter((singleepic) => {
        return singleepic.key === epic.key;
      });
    },
    sortedProjects: function () {
      this.allEpics.issues = this.allEpics.issues.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a.fields[this.currentSort] < b.fields[this.currentSort])
          return -1 * modifier;
        if (a.fields[this.currentSort] > b.fields[this.currentSort])
          return 1 * modifier;
        return 0;
      });
      let allEpics = this.allEpics.issues;
      if (this.searchText.length > 0) {
        return allEpics.filter((singleProject) => {
          return singleProject.fields.summary
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        return allEpics;
      }
    },
  },

  mounted: function () {},
  beforeUpdate() {
    if (
      this.selectedEpics &&
      this.selectedEpics.length === 0 &&
      this.changeAndViewEpicsFlag
    ) {
      this.selectedEpics = [
        ...this.selectedEpics,
        ...this.previouslySelectedEpics,
      ];
      this.selectedEpics = _.uniqBy(this.selectedEpics, "key");
      if (this.allEpics?.issues?.length === this.selectedEpics?.length) {
        this.allEpicsAreSelected = true;
      }
      this.flagTOUpdateValues = true;
    }
  },
  components: {},
};
</script>
<style scoped>
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.2rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1983bf;
  background-color: #1983bf;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e9ecef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #1983bf;
  background-color: #1983bf;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.checklist
  .custom-checkbox
  input:checked
  + label
  + div
  .checklist-strikethrough {
  width: 100%;
}
.checklist .custom-checkbox input:checked > label {
  opacity: 0.7;
}
.-w-100 {
  width: calc(100% - 140px);
}

.checklist-strikethrough {
  height: 40px;
  /* background: linear-gradient(90deg, #5982ff 0%, rgb(150, 175, 250) 100%); */
  background: #f1f1f1;
  border-radius: 2px 3px 2px 2px;
  mix-blend-mode: multiply;
  /* -webkit-transform: skewX(-10deg); */
  /* transform: skewX(-10deg); */
  /* opacity: 0.3; */
  width: 0;
  transition: width 0.35s ease-in-out, opacity 0.25s ease-in-out;
  position: absolute;
  margin-left: -51px;
  top: 0;
  pointer-events: none;
}

.custom-checkbox-label {
  background: inherit;
  width: 100%;
}

.custom-checkbox-label:focus {
  outline: none;
}
.fluent-Progress-bar-normal {
  background-color: #1983bf !important;
  height: 8px;
}
table,
tr td {
}
tbody {
  display: block;
  height: 400px;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
thead {
  width: calc(100% - 1em);
}
table {
  width: 400px;
  margin: 0 0 20px 0;
}
input[type="text"] {
  outline: none;
}
input[type="text"]::-moz-focus-inner {
  border: 0;
}
</style>
