<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-80
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white rounded shadow-lg w-3/6 text-gray-600">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <div class="font-medium text-white text-lg flex w-3/6">
            <div class="flex-grow w-12/12 font-bold mt-2">All Users</div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click.stop="$emit('close')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div class="flex">
          <div
            style="height: calc(100vh - 200px); overflow: auto"
            class="p-3 w-full"
          >
            <div class="sm:flex sm:items-center px-2 py-4">
              <div class="flex-grow">
                <input
                  type="text"
                  v-model="keyword"
                  placeholder="Search  members"
                  class="
                    my-2
                    w-full
                    text-sm
                    bg-grey-light
                    text-grey-darkest
                    border-b
                    h-10
                    p-3
                  "
                />

                <div class="w-full" v-if="searchUserList?.length !== 0">
                  <div
                    class="
                      flex
                      cursor-pointer
                      my-1
                      hover:bg-blue-lightest
                      rounded
                      w-full
                    "
                    v-for="(user, index) in searchUserList"
                    :key="index"
                  >
                  
                    <div class="w-4/5 h-12 py-3 px-1">
                      <div class="flex items-center">
                        <div class="mr-2">
                          <img class="w-8 h-8 rounded-full" :src="user.image" />
                        </div>
                        <span>{{ user.name }} 
                        <div class="text-gray-400 text-xs leading-3">{{ user.email }}</div>
                        </span>
                      </div>
                    </div>
                    <!-- <div class="w-1/5 h-10 text-right p-3">
                      <p class="text-sm text-grey-dark">Member</p>
                    </div> -->
                  </div>
                </div>
                <div v-else> 
                    No User Found
                </div>
              </div>
            </div>
            <!-- <div class="sm:flex bg-grey-light sm:items-center px-2 py-4">
              <div class="flex-grow text-right">
                <button
                  class="
                   text-base
                   
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-indigo-600
                    transition
                    float-right
                  "
                >
                  Got It
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "UserListModal",
  components: {},
  props: {
    allMembers: Array,
  },
  emits: ["close"],
  data() {
    return {
        keyword : ''
    };
  },
  methods: {
     
  },
  computed: {
    allUsers() {
      return this.allMembers?.filter(
        (tag, index, array) =>
          array.findIndex((t) => t.email?.toLowerCase() === tag.email?.toLowerCase()) == index
      );
    },
     searchUserList() {
      return this.allUsers.filter((entry) => {
        return this.keyword.toLowerCase().split(' ').every(v => entry.name.toLowerCase().includes(v) || entry.email.toLowerCase().includes(v));
      });
    }
  },
  mounted: function () {},
  created: function () {},
  beforeUpdate() {},
};
</script>
<style >
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.height-100 {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  /* margin: 10px 0; */
}

.fluent-Progress-bar-normal {
  background-color: #28a745 !important;
  height: 5px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>