const project = [
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10112",
        "id": "10112",
        "key": "EL",
        "name": "Education Lab ",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10112&avatarId=10409",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10112&avatarId=10409",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10112&avatarId=10409",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10112&avatarId=10409"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10000",
        "id": "10000",
        "key": "AS",
        "name": "Symphony",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10000&avatarId=10601",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10000&avatarId=10601",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10000&avatarId=10601",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10000&avatarId=10601"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10115",
        "id": "10115",
        "key": "WMI",
        "name": "Web Component Project",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10115&avatarId=10415",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10115&avatarId=10415",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10115&avatarId=10415",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10115&avatarId=10415"
        },
        "projectTypeKey": "software",
        "simplified": true,
        "style": "next-gen",
        "isPrivate": false,
        "properties": {},
        "entityId": "d04536c6-7e06-4b6c-a9b3-c57113ba447c",
        "uuid": "d04536c6-7e06-4b6c-a9b3-c57113ba447c"
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10116",
        "id": "10116",
        "key": "CETI",
        "name": "Cisco ET&I Integration Test",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10116&avatarId=10646",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10116&avatarId=10646",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10116&avatarId=10646",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10116&avatarId=10646"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10121",
        "id": "10121",
        "key": "HAD",
        "name": "Henshaws App Development",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10121&avatarId=10407",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10121&avatarId=10407",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10121&avatarId=10407",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10121&avatarId=10407"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10106",
        "id": "10106",
        "key": "ROAD",
        "name": "Aspenify Roadmap",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10106&avatarId=10603",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10106&avatarId=10603",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10106&avatarId=10603",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10106&avatarId=10603"
        },
        "projectTypeKey": "software",
        "simplified": true,
        "style": "next-gen",
        "isPrivate": false,
        "properties": {},
        "entityId": "9b758616-21ac-4b56-b675-d9dc0cfeab4a",
        "uuid": "9b758616-21ac-4b56-b675-d9dc0cfeab4a"
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10123",
        "id": "10123",
        "key": "CA",
        "name": "CISCO Application",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10123&avatarId=10416",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10123&avatarId=10416",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10123&avatarId=10416",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10123&avatarId=10416"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10107",
        "id": "10107",
        "key": "COM",
        "name": "Community",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10107&avatarId=10606",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10107&avatarId=10606",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10107&avatarId=10606",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10107&avatarId=10606"
        },
        "projectTypeKey": "software",
        "simplified": true,
        "style": "next-gen",
        "isPrivate": false,
        "properties": {},
        "entityId": "926c22a6-cfb1-4af7-a1bc-970f07c84e3b",
        "uuid": "926c22a6-cfb1-4af7-a1bc-970f07c84e3b"
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10124",
        "id": "10124",
        "key": "TC",
        "name": "Test CA",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10124&avatarId=10419",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10124&avatarId=10419",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10124&avatarId=10419",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10124&avatarId=10419"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10125",
        "id": "10125",
        "key": "CIM",
        "name": "CISCO Idea Management",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10125&avatarId=10410",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10125&avatarId=10410",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10125&avatarId=10410",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10125&avatarId=10410"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10110",
        "id": "10110",
        "key": "KLM",
        "name": "KLMS",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10110&avatarId=10605",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10110&avatarId=10605",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10110&avatarId=10605",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10110&avatarId=10605"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    },
    {
        "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
        "self": "https://aspenplatform.atlassian.net/rest/api/2/project/10111",
        "id": "10111",
        "key": "APS",
        "name": "ASPENIFY PRODUCTION SUPPORT",
        "avatarUrls": {
            "48x48": "https://aspenplatform.atlassian.net/secure/projectavatar?pid=10111&avatarId=10415",
            "24x24": "https://aspenplatform.atlassian.net/secure/projectavatar?size=small&s=small&pid=10111&avatarId=10415",
            "16x16": "https://aspenplatform.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10111&avatarId=10415",
            "32x32": "https://aspenplatform.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10111&avatarId=10415"
        },
        "projectTypeKey": "software",
        "simplified": false,
        "style": "classic",
        "isPrivate": false,
        "properties": {}
    }
]

export default project;