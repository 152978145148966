<template>
  <TransitionRoot appear :show="requestRejectedReasonModalFlag" as="template">
    <Dialog as="div" :open="requestRejectedReasonModalFlag">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-80">
        <div class="min-h-screen text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                overflow-hidden
                text-left
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
                relative
                top-40
              "
            >
              <!-- <img src="@/assets/images/balloon-cloud.png" /> -->
              <div class="m-10">
                <h1 class="">Are you sure you want to reject this request?</h1>
                <p class="text-sm text-gray-500">
                  Please provide the reason for rejecting the request
                </p>
                <!-- <div class="content-start items-start">Reason</div>
                <textarea
                  name="reason"
                  id="reason"
                  cols="30"
                  rows="10"
                  placeholder="add your reason"
                  v-model="reason"
                ></textarea> -->
                <div class="mt-6">
                  <label for="comment" class="text-sm font-semibold block mb-1"
                    >Reason</label
                  >
                  <textarea
                    name="comment"
                    id="comment"
                    rows="6"
                    placeholder="Add your reason"
                    v-model="reason"
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                  ></textarea>
                </div>
              </div>
              <div class="ml-10 mb-5">
                <button
                @click="onSubmit"
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                >
                  Send
                </button>
                <button
                  type="button"
                  @click="onCancel"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "IdeaSubmittedInfo",
  beforeUpdate() {},
  props: {
    requestRejectedReasonModalFlag: Boolean,
    page: String,
    eachSingleRequest: Object,
  },
  data() {
    return {
      pageInfo: {
        heading: "a",
        subHeading: "b",
      },
    };
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {
    async onSubmit(selectedInitiative, comment) {
      await this.$emit(
        "openRejectedRequestModal",
        true,
        this.reason,
        this.eachSingleRequest
      );
      this.reason = "";
    },
    async onCancel() {
      await this.$emit("requestRejected", false);
      this.reason = "";
    },
  },
};
</script>
