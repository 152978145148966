const status = [
    {
        "value": 1,
        "label": "Active"
    },
    {
        "value": 6,
        "label": "Inactive"
    }
]

const wsoStatus = [
    {
        "value": 1,
        "label": "Active" 
    },
    {
        "value": 6,
        "label": "Inactive"
    },
    {
        "value": -1,
        "label": "Draft"
    },
    {
        "value": 7,
        "label": "Merged"
    }
]
export  {status,wsoStatus};