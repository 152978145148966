<template>
<section class="w-full text-gray-900 py-96 bg-center bg-cover bg-no-repeat"
    style="background:url('https://images.unsplash.com/photo-1623479322729-28b25c16b011?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=1280')">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-4 flex items-center justify-center">
        <div class="lg:w-3/6 lg:pr-0 pr-0">
            <h1 class="font-medium text-5xl text-white">Page not found!</h1>
            <p class="leading-relaxed mt-4 text-white">
                Please contact administrator.</p>
        </div>
    </div>
</section>
</template>