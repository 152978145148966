<template>
    <div 
      class="absolute bg-cover h-screen w-full bg-left-bottom"
      style="
        background-image: url('https://res.cloudinary.com/dqddllkvx/image/upload/v1652676496/idea-image_bmiyvk.jpg');
      "
    >
      <div class="h-screen flex">
        <div class="flex w-1/2"></div>
        <div class="flex w-1/2 justify-center items-center">
          <div class="px-10 lg:px-32 xl:px-40 text-center text-white">
              <div class="mb-4 leading-relaxed">
                <p class="text-2xl md:text-2xl leading-normal mb-4">
                  Sorry! Submission window is closed!
                </p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { mapGetters } from "vuex";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
export default {
  components: {
  LoaderView
  },
  computed: {
    ...mapGetters(["user", "playbook", "loadingFlag"]),
  },
  methods: {
    postNewIdea: function () {
      this.$emit("postNewIdea");
    }
  },
};
</script>
