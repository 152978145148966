<template>
  <TransitionRoot appear :show="openInfoModal" as="template">
    <Dialog as="div" :open="openInfoModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-80">
        <div class="min-h-screen text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
                text-center
              "
            >
              <img src="@/assets/images/balloon-cloud.png" />
              <div class="m-10">
                <h1 class="font-bold">{{ pageInfo.heading }}</h1>
                <h3 class="font-bold">{{ pageInfo.subHeading }}</h3>
                <p>{{ pageInfo.text }}</p>

                <button
                  type="button"
                  @click="$emit('submittedInfoModalflag', false)"
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-600
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-teal-600
                    transition
                    mt-5
                    mb-2
                  "
                >
                  Got It</button
                ><br />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "IdeaSubmittedInfo",
  beforeUpdate() {},
  props: {
    openInfoModal: Boolean,
    page: String,
  },
  data() {
    return {
      pageInfo: {
        heading: "a",
        subHeading: "b",
      },
    };
  },
  created: function () {
    if (this.page == "allIdea" || this.page == "myIdea") {
      this.pageInfo = {
        heading: null,
        subHeading: "Your idea is in Process.",
        text: "The submission process might take a few minutes. We will notify you once your submission is created successfully. ",
      };
    } else if (this.page == "newIdea") {
      this.pageInfo = {
        heading: "Congratulations!",
        subHeading: "Your idea is submitted.",
        text: "The submission process might take a few minutes. We will  notify you once your submission is created successfully. Once it is finished you can view your it under ‘My Submissions’.",
      };
    }
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {},
};
</script>
