<template>
  <div class="p-2 font-semibold text-sm">
    <!-- :class="{ 'bg-blue-50': activity.isActivityDetailOpen }" -->
    <!-- <div class="p-2 font-semibold text-sm"> -->
    {{ activity.name }}

    <div class="float-right mt-0.5 mr-0">
      <span
        class="float-right"
        :class="{ 'rotate-icon-90': activity.isActivityDetailOpen }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          :class="{ 'opacity-30': activity?.references?.length === 0 }"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>
    <div
      v-if="activity?.references?.length !== 0"
      class="
        border border-gray-300
        rounded-lg
        pl-2
        pr-2
        w-auto
        mr-2
        text-center
        float-right
        cursor-pointer
        hover:bg-blue-50
      "
      @click="toggleActivity(activity)"
      :class="{ 'bg-blue-300': activity.isActivityDetailOpen }"
    >
      <span class="float-left pt-1 pr-0.5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3 float-left"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
      <span class="float-left">{{ activity?.references?.length }}</span>
    </div>
    <div
      class="float-right mt-0.5 mr-0.5"
      v-if="activity.isMilestone"
      title="milestone activity"
    >
      <img src="@/assets/images/diamond.png" width="20" />
    </div>
  </div>
  <div class="flex flex-col" v-show="activity.isActivityDetailOpen">
    <!-- <nav class="flex flex-col sm:flex-row">
      <button
        type="button"
        @click="setActive('setTab1')"
        class="
          flex-grow
          text-gray-600
          py-2
          px-4
          block
          hover:text-blue-500
          focus:outline-none
          border-b-2
          text-left
        "
        :class="{
          'text-blue-500 border-b-2 font-medium border-blue-500':
            activeTabItem === 'setTab1',
        }"
      >
        Reference ({{ reference.length }})
      </button>
      <button
        type="button"
        @click="setActive('setTab2')"
        class="
          flex-grow
          text-gray-600
          py-2
          px-4
          block
          hover:text-blue-500
          focus:outline-none
          border-b-2
        "
        :class="{
          'text-blue-500 border-b-2 font-medium border-blue-500':
            activeTabItem === 'setTab2',
        }"
      >
        Detail
      </button>
    </nav> -->
    <div class="text-sm" v-if="activeTabItem === 'setTab1'" id="setTab1">
      <h3 class="p-2 font-bold bg-gray-50 border-b">
        References ({{ activity.references ? activity.references.length : 0 }})
      </h3>
      <div
        class="border-b"
        v-for="(cont, index) in activity.references"
        :key="index"
      >
        <div class="flex p-2 w-8 bg-gray-50 w-full" v-if="index === 0">
          <div class="w-12">Type</div>
          <div class="flex-1">Name</div>
          <div class="flex-1">Description</div>
        </div>
        <div class="flex p-2 w-8 w-full">
          <div class="w-12">
            <icon-with-type :type="cont.id.type" />
          </div>
          <div class="flex-1">{{ cont.id.name }}</div>
          <div class="flex-1">
            <span
              v-html="
                fullTextIndex === index
                  ? cont.id.description
                  : cont.id.description.substring(0, 120)
              "
            />
            <span
              class="text-blue-700 cursor-pointer hover:opacity-70"
              v-if="fullTextIndex === index"
              @click="fullTextIndex = null"
            >
              Read Less
            </span>
            <span
              v-else
              class="text-blue-700 cursor-pointer hover:opacity-70"
              @click="fullTextIndex = index"
            >
              {{ cont.id.description.length > 120 ? " ...Read More" : "" }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="p-2" v-if="activeTabItem === 'setTab2'" id="setTab2">
      Details
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as timeago from "timeago.js";
import IconWithType from "../../../components/IconWithType.vue";

export default {
  name: "MyProgressActivityDetails",
  props: {
    activity: Object,
  },
  components: {
    IconWithType,
  },
  data() {
    return {
      timeago,
      activeTabItem: "setTab1",

      fullTextIndex: null,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    toggleActivity: function (item) {
      item.isActivityDetailOpen = !item.isActivityDetailOpen;
    },
    setClass: function (item) {
      if (item.isActivityDetailOpen == true) {
        return "open";
      }
      return "close";
    },
    setActive(menuItem) {
      this.activeTabItem = menuItem;
    },
  },
};
</script>
<style scoped>
</style>