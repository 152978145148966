<template>
  <div class="px-6 py-6">
    <h3 class="mb-3">Logs</h3>
    <div>
      <div class="">
        <table
          class="
            min-w-full
            divide-y divide-gray-200
            table-auto
            bg-white
            shadow-md
            rounded
            text-sm text-gray-600
          "
        >
          <thead class="bg-gray-100 text-gray-500">
            <tr>
              <td colspan="5">
                <!-- v-on:keyup.enter="onEnter" -->
                <input
                  autofocus
                  type="text"
                  name=""
                  id=""
                  placeholder="Search by Name and Subject"
                  v-model="search"
                  class="w-full p-3 focus:outline-none"
                  @change="pageChanged(1)"
                />
              </td>
              <td class="w-60">
                <v-date-picker
                  v-model="createdAt"
                  is-range
                  :max-date="new Date()"
                  @change="pageChanged(1)"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex relative bg-white">
                      <div class="relative">
                        <svg
                          class="
                            text-gray-600
                            w-4
                            h-full
                            mx-2
                            absolute
                            pointer-events-none
                          "
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          ></path>
                        </svg>
                        <input
                          style="min-height: 40px"
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          placeholder="From"
                          class="
                            border
                            pl-8
                            pr-2
                            py-1
                            rounded
                            w-full
                            focus:outline-none focus:border-indigo-300
                            border border-white
                          "
                        />
                      </div>
                      <div>
                        <!-- arrow svg -->
                        <svg
                          class="w-4 h-4 mx-2 mt-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                          />
                        </svg>
                      </div>
                      <div class="relative ml-5">
                        <!-- calender Icon for TO -->
                        <svg
                          class="
                            text-gray-600
                            w-4
                            h-full
                            mx-2
                            absolute
                            pointer-events-none
                          "
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          ></path>
                        </svg>
                        <input
                          style="min-height: 40px"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          placeholder="To"
                          class="
                            border
                            pl-8
                            pr-2
                            py-1
                            w-full
                            rounded
                            focus:outline-none focus:border-indigo-300
                            border border-white
                          "
                        />
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </td>
            </tr>
            <tr>
              <th scope="col" class="text-left p-2">Originator</th>
              <th scope="col" class="text-left p-2">
                <div
                  class="flex cursor-pointer hover:opacity-80"
                  @click="
                    orderBy == 1 ? (orderBy = -1) : (orderBy = 1);
                    sortBy = 'fromMail';
                    pageChanged(1);
                  "
                >
                  <div class="flex-grow text-left">From</div>
                  <div v-if="orderBy === 1 && sortBy === 'fromMail'">
                    <IconArrowDown />
                  </div>
                  <div v-if="orderBy === -1 && sortBy === 'fromMail'">
                    <IconArrowUp />
                  </div>
                </div>
              </th>
              <th scope="col" class="text-left p-2">To</th>
              <th scope="col" class="text-left p-2">Name</th>
              <th scope="col" class="text-left p-2">
                <div
                  class="flex cursor-pointer opacity-80"
                  @click="
                    orderBy == 1 ? (orderBy = -1) : (orderBy = 1);
                    sortBy = 'subject';
                    pageChanged(1);
                  "
                >
                  <div class="flex-grow text-left">Subject</div>

                  <div v-if="orderBy === 1 && sortBy === 'subject'">
                    <IconArrowDown />
                  </div>
                  <div v-if="orderBy === -1 && sortBy === 'subject'">
                    <IconArrowUp />
                  </div>
                </div>
              </th>
              <th scope="col" class="text-right p-2 group">
                <div
                  class="flex cursor-pointer opacity-80"
                  @click="
                    orderBy == 1 ? (orderBy = -1) : (orderBy = 1);
                    sortBy = 'createdAt';
                    pageChanged(1);
                  "
                >
                  <div class="flex-grow text-right">Created On</div>

                  <div v-if="orderBy === 1 && sortBy === 'createdAt'">
                    <IconArrowDown />
                  </div>
                  <div v-if="orderBy === -1 && sortBy === 'createdAt'">
                    <IconArrowUp />
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200 text-sm">
            <tr
              class="cursor-pointer hover:bg-gray-50"
              v-for="(item, index) in emailLogs?.data"
              :key="{ index }"
              @click="view(item)"
            >
              <td class="p-2 text-left">
                <div v-if="item?.createdBy?.firstName && item?.createdBy?.lastName">{{ `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}` }}</div>
                <div v-else-if="item?.createdBy?.firstName">{{ `${item?.createdBy?.firstName}` }}</div>
                <div v-else>NA</div>
              </td>
              <td class="p-2 text-left">{{ item.fromEmail }}</td>
              <td class="p-2 text-left">
                {{ item.toEmail[0] }}
                <span v-if="item.toEmail?.length > 1"
                  >, +{{ item.toEmail?.length - 1 }}</span
                >
              </td>
              <td class="p-2 text-left">
                <div v-if="item?.name">{{ `${item?.name}` }}</div>
                <div v-else>NA</div>
              </td>
              <td class="p-2 text-left">
                {{ item.subject }}
              </td>
              <td class="p-2 text-right">{{ formatDate(item.createdAt) }}</td>
            </tr>
          </tbody>
          <!-- <tbody v-else>
            <div class="p-2">No data available !</div>
          </tbody> -->
        </table>
        <div class="py-5 transition-all flex float-right">
          <div class="relative mr-4">
            <select
              class="
                appearance-none
                h-full
                rounded-md
                border
                block
                appearance-none
                w-full
                bg-white
                text-gray-700
                py-2
                px-4
                pr-8
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
              v-model="limit"
              @change="pageChanged(1)"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
          <div>
            <pagination
              v-if="emailLogs?.total > 8"
              :total-pages="totalPages"
              :current-page="currentPage"
              @pagechanged="pageChanged"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="viewDetail"
    class="
      modal
      h-screen
      w-full
      fixed
      left-0
      top-0
      flex
      justify-center
      items-center
      bg-black bg-opacity-40
      g-opacity-50
      z-100
    "
  >
    <div class="bg-white shadow-lg w-6/12 text-gray-600">
      <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
        <div class="font-medium text-white text-lg flex w-6/12">
          <div class="flex-grow w-1/12 font-bold mt-2">Enter Email Details</div>
        </div>
        <button
          class="
            bg-blue-400
            text-sm text-white
            py-2
            px-2
            rounded-full
            focus:outline-none
          "
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div class="p-5">
        <!-- <pre>{{ selectedLog }}</pre> -->
        <div>
          <div class="grid grid-cols-3 gap-4 mb-2">
            <div>
              <h3 class="text-sm">Originator</h3>
              <div class="text-xs font-semibold text-gray-600">
                <div v-if="selectedLog?.createdBy?.firstName">{{ `${selectedLog?.createdBy?.firstName} ${selectedLog?.createdBy?.lastName}` }}</div>
                <div v-else>NA</div>
              </div>
            </div>
            <div>
              <h3 class="text-sm">From</h3>
              <div class="text-xs font-semibold text-gray-600">
                {{ selectedLog.fromEmail }}
              </div>
            </div>
            <div class="text-right">
              <h3 class="text-sm">Name</h3>
              <div class="text-xs font-semibold text-gray-600">
                <div v-if="selectedLog?.name">{{ `${selectedLog?.name}` }}</div>
                <div v-else>NA</div>
              </div>
            </div>
          </div>
          <div class="flex mb-1">
            <div class="flex-grow">
              <h3 class="text-sm">To</h3>
              <div class="text-xs font-semibold text-gray-600">
                <!-- <span> {{ selectedLog?.toEmail }} </span> -->
                <ul>
                  <li :key="to" v-for="to in selectedLog.toEmail">{{to}}</li>
                </ul>
              </div>
            </div>
            <div>
              <h3 class="text-sm text-right">Created At</h3>
              <div class="text-xs font-semibold text-gray-600">
                {{ formatDate(selectedLog.createdAt) }}
              </div>
            </div>
          </div>
          <div>
            <h3 class="text-sm mt-2">Subject</h3>
            <div class="text-xs font-semibold text-gray-600">
              {{ selectedLog.subject }}
            </div>
          </div>
          <h3 class="text-sm mt-2">Message</h3>
          <div
            class="
              text-xs
              font-semibold
              text-gray-600
              p-3
              border
              rounded
              mt-2
              overflow-auto
              h-40
            "
          >
            <div v-if="selectedLog.headerText">
              {{ selectedLog.headerText }}
            </div>
            <div
              class="py-3"
              v-if="selectedLog.message"
              v-html="selectedLog.message"
            ></div>
            <div v-if="selectedLog.footerText">
              {{ selectedLog.footerText }}
            </div>
          </div>
          <div class="text-left mt-3">
            <button
              class="
                text-white
                bg-indigo-500
                border-0
                py-1
                px-3
                focus:outline-none
                hover:bg-indigo-600
                rounded
                text-sm
                disabled:opacity-30
              "
              @click="close()"
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { mapGetters } from "vuex";
import { format } from "fecha";
import pagination from "../../components/Pagination.vue";
import IconArrowDown from "../../components/Icon/Icon-Arrow-Down.vue";
import ArrowRight from "../../components/Icon/Arrow-Right.vue";
import IconArrowUp from "../../components/Icon/Icon-arrow-Up.vue";
import moment from "moment";
export default {
  data() {
    return {
      list: [1, 2, 3, 4, 5],
      viewDetail: false,
      selectedLog: null,
      currentPage: 1,
      sortBy: "createdAt",
      orderBy: -1,
      limit: 10,
      search: "",
      createdAt:""
    };
  },
  components: {
    pagination,
    IconArrowDown,
    ArrowRight,
    IconArrowUp,
  },
  computed: {
    ...mapGetters(["user", "emailLogs"]),
    totalPages() {
      return this.emailLogs?.total % Number(this.limit) == 0
        ? parseInt(this.emailLogs?.total / Number(this.limit))
        : parseInt(this.emailLogs?.total / Number(this.limit)) + 1;
    },
  },
  watch: {
    createdAt: function (newValue, oldValue) {
      this.pageChanged(1);
    },
    search: function (newValue, oldValue) {
      this.pageChanged(1);
    }
  },
  created: async function () {
    this.pageChanged(1);
  },
  methods: {
    formatDate(date) {
      if (date) {
        return format(new Date(date), "MMM D, YYYY [at] HH:mm");
      } else {
        return "NA";
      }
    },
    view(data) {
      this.selectedLog = data;
      this.viewDetail = true;
    },
    close() {
      this.selectedLog = null;
      this.viewDetail = false;
    },
    async pageChanged(page) {
      this.currentPage = page;
      let data = {
        templateName: "customNotificationTemplate",
        currentPage: page,
        sortBy: this.sortBy,
        orderBy: this.orderBy,
        limit: this.limit,
        search: this.search
      }
      if(this.createdAt){
        let startDate = new Date(moment(this.createdAt?.start).startOf('day'));
        let endDate = new Date(moment(this.createdAt?.end).endOf('day'));
        data.createdAt = { $gte: startDate, $lt: endDate }
      }
      await this.$store.dispatch("getLogs", data);
    },
  },
};
</script>
<style scoped>
.subject-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
</style>
