<template>
  <!-- <div class="bg-gray-100 p-2 rounded-lg">
    Main idea - <b>{{ initiative?.name }}</b
    ><br />
    Judge idea - <b>{{ selectedIdea?.name }}</b
    ><br />
    Idea Phase - <b>{{ ideaPhaseIndex + 1 }}</b
    ><br />
    Loader - <b>{{ loader ? "True" : "False" }}</b
    ><br />
  </div> -->
  <div class="inline-block float-left w-full" :key="componentKey">
    <!-- <div>{{selectedIdea}}</div> -->
    <dynamic-form 
      :fieldsData="selectedIdea.metaDataSchema"
      :selectedIdea="selectedIdea"
      :markReviewedData="markReviewedData"
      :showAsterisk="true"
      :maxLimit="maxLimit"
      @openCloseJudgingPanel="openCloseJudgingPanel"
      @save="save"
    >
    </dynamic-form><br/><br/>
    <button v-if="selectedIdea?.metaDataSchema?.length>0 && selectedIdea.status!=2 && (this.$route.params.status=='toreview' || this.$route.params.status=='notepad')"
      @click="confirmReviewed()"
      :disabled="!isEnable"
      class="
        inline-flex
        text-white
        bg-indigo-500
        border-0
        py-2
        px-4
        my-4
        focus:outline-none
        hover:bg-indigo-600
        rounded
        text-md
        disabled:opacity-30
      "
    >
      Submit Feedback
      </button>
      <button v-if="selectedIdea?.metaDataSchema?.length>0 && selectedIdea.status!=2 && (this.$route.params.status=='allcompleted' )"
      @click="confirmReviewed()"
      :disabled="!isEnable"
      class="
        inline-flex
        text-white
        bg-indigo-500
        border-0
        py-2
        px-4
        my-4
        focus:outline-none
        hover:bg-indigo-600
        rounded
        text-md
        disabled:opacity-30
      "
    >
      Update Feedback
    </button>
    <!-- <div v-for="(data, index) in selectedIdea.metaDataSchema" :key="{ index }">
        {{data.title}}
    </div> -->
  </div>
  <loader-view v-if="loader" :loaderFlag="loader" loadingTitle="Updating...">
  </loader-view>

  <JudgingSubmitAck v-if="openJudgingSubmitAck"
    :openJudgingSubmitAck="openJudgingSubmitAck"
    @openJudgingSubmitAckModel="openJudgingSubmitAckModel"
    @judging="judging"
    @submitAck="submitAck"
    @cancelAck="cancelAck"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
    :headerText="headerText"
    :infoText="infoText"
    :footerText="footerText"
  />

</template>
<script>
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import IconCheck from "../Icon/Check.vue";
import IconExclimation from "../Icon/Exclimation.vue";
import LoaderView from "../progressView/Activities/LoaderView.vue";
import DynamicForm from "../DynamicForm";
import JudgingSubmitAck from "../confirmationModal/JudgingSubmitAck.vue";
export default {
  props: {
    user: Object,
    selectedIdea: Object,
    initiative: Object,
    from: String,
    status: Number,
    ideaPhaseIndex: Number,
  },

  data(props) {
    return {
      AIActions: [
        {
          name: "Move to Inactive",
          value: "moveToInactive",
          label: "Move to Inactive",
          icon: "check",
          isMerged: false,
        },
        {
          name: "Move to Inactive(and merge)",
          value: "moveToInactiveAndMerge",
          label: "Move to Inactive(and merge)",
          icon: "check",
          disabled: false,
          isMerged: true,
        },
        {
          name: "Get More Input through open Mic",
          value: "getMoreInputThroughOpenmic",
          label: "Get More Input through open Mic ",
          icon: "info",
          disabled: false,
          isMerged: false,
        },
        {
          value: "getMoreInputAndAskTeam",
          label: "Get More Input(ask team)",
          icon: "info",
          disabled: false,
          isMerged: false,
        },
        {
          name: "Recommend to BU/ Function",
          value: "recommendToBUFunction",
          label: "Recommend to BU/ Function",
          icon: "check",
          disabled: false,
          isMerged: false,
        },
      ],
      AIAction: "",
      AIComment: "",
      bogFramework: "",
      // messageToTeam: "",
      formData: {},
      componentKey: 0,
      BUAction: [
        { value: "AppDynamics", label: "AppDynamics" },
        { value: "Cloud Services", label: "Cloud Services" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Core Hardware Platforms", label: "Core Hardware Platforms" },
        {
          value: "Customer and Partner Services",
          label: "Customer and Partner Services",
        },
        { value: "Customer Experience", label: "Customer Experience" },
        { value: "Data and Analytics", label: "Data and Analytics" },
        { value: "Enterprise Networking", label: "Enterprise Networking" },
        { value: "IoT", label: "IoT" },
        { value: "IT", label: "IT" },
        { value: "Legal", label: "Legal" },
        {
          value: "Mass-Scale Infrastructure",
          label: "Mass-Scale Infrastructure",
        },
        { value: "Meraki", label: "Meraki" },
        { value: "Operations", label: "Operations" },
        { value: "People & Communities", label: "People & Communities" },
        { value: "Sales and Marketing", label: "Sales and Marketing" },
        { value: "Security", label: "Security" },
        { value: "Supply Chain", label: "Supply Chain" },
      ],
      loader: false,
      isEnable: false,
      markReviewedData: {

      },
      openJudgingSubmitAck:false,
      maxLimit:500
    };
  },
  name: "ReviewForm",
  created: function () {
    // this.$store.dispatch("getJudgingTeamDetails", "");
    this.setData();
    // this.addValueLabel()
  },
  watch: {
    selectedIdea() {
      this.componentKey += 1;
      this.setData();
    },
  },
  computed: {
    ...mapGetters(["bogFrameworks", "playbook", "user", "enableJudging"]),
    disableDraft() {
      return this.AIAction?.length === 0 || this.AIComment?.length === 0;
    },
    disableSubmit() {
      return this.AIAction?.length === 0 || this.AIComment?.length === 0;
    },
    disableComplete() {
      // At least 3 review condition commented due to client feedback
      // let threereview =
      //   this.selectedIdea?.activities[0]?.activities?.filter((f) => f.metaData)
      //     .length < 3;
      // return false
      return this.AIAction?.length === 0 || this.AIComment?.length === 0;
    },
    primaryBtn() {
      return "inline-flex text-white bg-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-600 disabled:opacity-30 rounded";
    },
    isManager() {
      return this.user.roles.includes("manager");
    }
  },
  components: { Multiselect, IconCheck, IconExclimation, LoaderView, DynamicForm, JudgingSubmitAck},
  methods: {
    async confirmReviewed (){
      await this.$store.dispatch("checkJudgingEnableStatus");
      if(this.enableJudging){
        this.openJudgingSubmitAck = true;
      } else {
        alert("Judging is turned off")
      }
    },
    openCloseJudgingPanel(payload){
      this.$emit("openPanel", payload);
    },
    submitAck(){
        this.$emit("openPanel", false);
        this.openJudgingSubmitAck = false;
        this.markAsReviewed();
      },

    cancelAck(){
        this.openJudgingSubmitAck = false;
        this.$emit("openJudgingPanel", true);
      },

    async markAsReviewed(){
      if(this.markReviewedData?.comments && this.markReviewedData?.comments.length>0){
        this.markReviewedData.comments=this.markReviewedData?.comments?.substring(0,this.maxLimit)
      }
      let payloadData = {...this.markReviewedData};
      if(Object.keys(payloadData).length > 0){
        payloadData.lastUpdatedBy = this.user.aspenifyUserId;
        payloadData.updatedAt = new Date();
        let reviews = {};
        let fieldName = 'reviews';
        let objectToUpdate = 'metaData.'+fieldName;
        let keyString = 'metaData.'+fieldName+'.lastUpdatedBy';
        reviews = {
          $push: { [objectToUpdate] : payloadData}
        }
        if (this.selectedIdea.metaData && this.selectedIdea.metaData[fieldName] && this.selectedIdea.metaData[fieldName]?.length>0) {
            let record = this.selectedIdea.metaData[fieldName].filter(data => {
              if (data.lastUpdatedBy == this.user.aspenifyUserId) {
                return data;
              }
            })
            if(record?.length>0){
              let payload = {};
              payload.id = this.selectedIdea._id;
              payload.fieldToMatch = keyString;
              payload.objectToUpdate = objectToUpdate;
              payload.dataToUpdate = payloadData;
              this.$store.dispatch("updateJudgingIdea", payload );
            } else {
              this.markReviewedData.updateData = reviews;
              this.markReviewedData.id = this.selectedIdea._id;
              await this.$store.dispatch("updateIdea", this.markReviewedData );
            }
        } else {
          this.markReviewedData.updateData = reviews;
          this.markReviewedData.id = this.selectedIdea._id;
          await this.$store.dispatch("updateIdea", this.markReviewedData );
        }
      }
      this.$emit("openPanel", false);
      this.$emit("showToaster", true);
      setTimeout(() => {
        this.$emit("showToaster", false);
      }, 3000);
      await this.$emit("getAllIdeasCount");
    },
    save(data){
      let enablesArray = [];
      this.markReviewedData = data;
      let schemaData = this.selectedIdea.metaDataSchema.filter((entity)=> entity.isRequired == true);
      for(let data of schemaData){
        if(data.type == 'comment'){
          data.model=data.model?.substring(0,this.maxLimit)
          if(data.model && !this.markReviewedData[data.name]){
            enablesArray.push(true);
          } else if(!this.markReviewedData[data.name]){
            enablesArray.push(false);
          } else if(this.markReviewedData[data.name] && this.markReviewedData[data.name] == '') {
            enablesArray.push(false);
          } else {
            enablesArray.push(true);
          }
          if(data.model?.length>this.maxLimit){
            enablesArray.push(false);
          }
          if(data.isRequired===true && !data?.model?.trim()){
            enablesArray.push(false);
          }
        } else if(data.type == 'radiogroup'){
          if(data.model){
            enablesArray.push(true);
          }else if(!this.markReviewedData[data.name]){
            enablesArray.push(false);
          } else if(this.markReviewedData[data.name] && this.markReviewedData[data.name] == '') {
            enablesArray.push(false);
          }
        } else if(!data.model) {
          if(this.markReviewedData[data.name]){
            enablesArray.push(true);
          } else {
            enablesArray.push(false);
          }
        } else {
          enablesArray.push(true);
        }
      }
      if(enablesArray.includes(false)){
        this.isEnable = false;
      } else {
        this.isEnable = true;
      }
    },
    // updateReview(actionType) {
    //   let payload = {
    //     actionType: actionType,
    //     type: this.AIAction ? this.AIAction : "NA",
    //     comment: this.AIComment ? this.AIComment : "NA",
    //     bogFramework: this.bogFramework,
    //     // messageToTeam: this.messageToTeam,
    //     icon: this.AIActions?.filter((f) => f.value === this.AIAction)[0]?.icon,
    //   };
    //   console.log(payload);
    //   // this.$emit("updateReview", payload);
    // },

    async updateReview(actionType) {
      this.loader = true;
      // await this.pageChanged(1);
      // if (feedback.actionType === "end") {
      //   this.openPanel(false);
      // }
      let updateFeedback = {};
      updateFeedback.actionType = actionType;
      updateFeedback.type = this.AIAction ? this.AIAction : "NA";
      updateFeedback.comment = this.AIComment ? this.AIComment : "NA";
      updateFeedback.bogFramework = this.bogFramework;

      updateFeedback.icon = this.AIActions?.filter(
        (f) => f.value === this.AIAction
      )[0]?.icon;
      updateFeedback.actionLabel = this.AIActions?.filter(
        (f) => f.value === this.AIAction
      )[0]?.label;
      updateFeedback.isMerged = this.AIActions?.filter(
        (f) => f.value === this.AIAction
      )[0]?.isMerged;
      updateFeedback.date = new Date();
      updateFeedback.updatedBy = this.user.aspenifyUserId;
      updateFeedback.phase = this.selectedIdea.phase;
      updateFeedback.phaseIndex = this.selectedIdea.phaseIndex;

      if (this.user.roles.includes("manager")) {
        await this.managerUpdate(updateFeedback);
      } else {
        await this.memberUpdate(updateFeedback);
      }
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    },
    async memberUpdate(updateFeedback) {
      if (this.selectedIdea?.activities?.length !== 0) {
        let findTeam = this.selectedIdea?.activities[0].activities.filter(
          (f) => f?.raci?.a?.teamId?._id === this.user?.associatedTeam?._id
        );
        let memberPayload = {
          idea: this.selectedIdea,
          id: findTeam[0]?._id || findTeam[0]?.id,
          updateFeedback: updateFeedback,
        };
        await this.$store.dispatch(
          "updateJudgingIdeaStatusActivities",
          memberPayload
        );
        let payload = {
          id: this.selectedIdea?.id
        }
        let result = await this.$store.dispatch(
          "getJudgingInitiativeById",
          payload
        );
        this.selectedIdea = result;
      }
    },
    async managerUpdate(updateFeedback) {
      // Update metadata in main initiative
      let payload = {
        _id: this.initiative?._id,
        metaData: updateFeedback,
      };
      await this.$store.dispatch("updateInitiativeMeta", payload);

      // set initiative
      let initiative = this.initiative;
      initiative.id = this.initiative?._id;

      if (this.bogFramework?.length !== 0) {
        let data = {
          id: this.initiative?._id,
          data: {
            key: "metaData.bogFramework",
            value: this.bogFramework,
          },
        };
        await this.$store.dispatch("updateInitiativeById", data);
      }

      if (updateFeedback.type === "recommendToBUFunction") {
        let data = {
          id: this.initiative?._id,
          data: {
            key: "metaData.managementFields.buAction",
            value: this.selectedBUAction,
          },
        };
        await this.$store.dispatch("updateInitiativeById", data);

        let obj = {
          selectedInitiative: initiative,
          comment: updateFeedback.comment,
        };
        await this.$store.dispatch("inactivateIdeaJudgingIdea", obj);
      }

      if (updateFeedback.actionType === "end") {
        if (updateFeedback.type === "moveToInactive") {
          let obj = {
            selectedInitiative: initiative,
            comment: updateFeedback.comment,
          };
          await this.$store.dispatch("inactivateIdeaJudgingIdea", obj);
        }
        if (updateFeedback.type === "moveToInactiveAndMerge") {
          let obj = {
            selectedInitiative: initiative,
            comment: updateFeedback.comment,
          };
          await this.$store.dispatch("mergeJudgingIdea", obj);
        }

        if (updateFeedback.type === "promoteToNextPhase") {
          let promoteObj = {
            initiative: this.initiative,
            phaseIndex: this.ideaPhaseIndex,
            comment: "",
            isCloseFlag: false,
          };
          await this.$store.dispatch("promoteInitiative", promoteObj);
        }

        if (updateFeedback.type === "promoteToNextPhaseAndMerge") {
          let promoteObj = {
            initiative: this.initiative,
            phaseIndex: this.ideaPhaseIndex,
            comment: "",
            isCloseFlag: false,
            selectedInitiative: initiative,
            comment: updateFeedback.comment,
          };
          await this.$store.dispatch("promoteInitiative", promoteObj);
          await this.$store.dispatch("inactivateIdeaJudgingIdea", promoteObj);
        }

        if (updateFeedback.type === "getMoreInputAndAskTeam") {
        }

        if (updateFeedback.type === "getMoreInputThroughOpenmic") {
        }

        if (updateFeedback.type === "recommendToBUFunction") {
          let payObj = {
            initiative: this.initiative,
            phaseIndex: this.ideaPhaseIndex,
            comment: "",
            isCloseFlag: false,
            selectedInitiative: initiative,
            comment: updateFeedback.comment,
          };
          await this.$store.dispatch("inactivateIdeaJudgingIdea", payObj);
        }

        let judgingIdea = {
          _id: this.selectedIdea?._id,
          status: 3,
        };
        await this.$store.dispatch("updateInitiativeStatus", judgingIdea);

        if (this.from === "judging") {
          await this.$emit("getAllIdeasCount");
        }

        if (this.from === "progress") {
          let query = {
            status: [1],
          };
          let payload = {
            currentPage: 1,
            searchQuery: query,
            isFilter: true,
          };
          await this.$store.dispatch("getInitiativesByPhase", payload);
        }
      }
    },
    minReview() {
      return false;
    },
    reset() {
      this.AIAction = "";
      this.AIComment = "";
    },
    setData() {
      if (this.initiative && this.selectedIdea) {
        let meta = this.selectedIdea.activities[0].activities.filter(
          (f) => f?.raci?.a?.teamId?._id === this.user?.associatedTeam?._id
        );

        if (
          this.user.roles.includes("manager") &&
          this.initiative?.metaData?.action
        ) {
          let isAction = this.initiative?.metaData?.action;

          this.formData = isAction;
          // this.AIAction = isAction?.type
          //   ? isAction?.type
          //   : this.selectedIdea?.subInitiatives?.metaData?.type;
          // this.AIComment = isAction?.comment
          //   ? isAction?.comment
          //   : this.selectedIdea?.subInitiatives?.metaData?.comment;
          this.bogFramework = isAction?.bogFramework;
          // this.messageToTeam = this.selectedIdea?.metaData?.messageToTeam;
        } else if (
          (this.user.roles.includes("judgeMember") ||
            this.user.roles.includes("judgeManager")) &&
          meta &&
          meta.length !== 0 &&
          meta[0].metaData
        ) {
          this.formData = meta[0].metaData;
          this.AIAction = meta[0].metaData.type;
          this.AIComment = meta[0].metaData.comment;
        } else {
          this.formData = {};
          this.AIAction = "";
          this.AIComment = "";
        }
        let nextPhaseDisplayIndex = 0;

        if (this.playbook?.activities?.length !== this.ideaPhaseIndex + 1) {
          nextPhaseDisplayIndex = this.ideaPhaseIndex + 1;

          let p = {
            name: `Promote to ${this.playbook?.activities[nextPhaseDisplayIndex]?.name}`,
            value: `promoteToNextPhase`,
            label: `Promote to ${this.playbook?.activities[nextPhaseDisplayIndex]?.name}`,
            icon: "check",
            isMerged: false,
          };

          let pAndM = {
            value: `Promote to ${this.playbook?.activities[nextPhaseDisplayIndex]?.name}`,
            value: `promoteToNextPhaseAndMerge`,
            label: `Promote to ${this.playbook?.activities[nextPhaseDisplayIndex]?.name} (and merge)`,
            icon: "check",
            isMerged: true,
          };

          this.AIActions.splice(2, 0, p);
          this.AIActions.splice(2, 0, pAndM);
        }

        this.selectedBUAction =
          this.initiative?.metaData?.managementFields?.buAction;
      }
    },
  },
};
</script>
<style scoped>
.character-option-icon {
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}
</style>
