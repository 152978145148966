<template>
  <span v-if="name">
    <span class="inline-block align-bottom" v-if="lock">
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </span>
    {{ title }}
  </span>
  <span v-if="description">
    {{ detail }}
  </span>
</template>
<script>
export default {
  name: "SetTitle",
  props: {
    name: String,
    description: String,
    metaDataSchema: Array,
    defaultText: String,
    lock: Boolean,
  },

  computed: {
    title() {
      if (this.metaDataSchema) {
        let checkData = this.metaDataSchema.filter((item) => {
          return item.name === this.name;
        });
        if (checkData && checkData.length !== 0) {
          return checkData[0].title ? checkData[0].title : this.defaultText;
        } else {
          return this.defaultText;
        }
      } else {
        return "*** Set Title ***";
      }
    },
    detail() {
      if (this.metaDataSchema) {
        let checkData = this.metaDataSchema.filter((item) => {
          return item.name === this.description;
        });

        if (checkData && checkData.length !== 0) {
          return checkData[0].description
            ? checkData[0].description
            : checkData[0].detail
            ? checkData[0].detail
            : this.defaultText;
        } else {
          return this.defaultText;
        }
      } else {
        return "*** Set Description ***";
      }
    },
  },
  methods: {},
};
</script>


<style scoped>
</style>
