import feathersClient from "../services/feathersClient";
import { format } from "fecha";
import { stripHtml } from "string-strip-html";
import { isEmpty, cloneDeep } from "lodash";

const state = {};

const mutations = {};

const actions = {
  async jiraAPI(context, {routeParameters, integrationId}) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {

      const tokenString = `Bearer ${accessToken}`;
      const jiraService = feathersClient.service("jira/list");
      // const jiraId = process.env.VUE_APP_JIRA_KEY;
      const jiraId = integrationId;
      let payload = {
        // jiraInstance: "https://aspenplatform.atlassian.net",
        // email: "kunal@aspenify.com",
        // apiToken: "KlS6VxQ1WKICiLNGhEJ67661",
        organizationId:context.rootState.user.user.organizationId,
        jiraId:jiraId,
        apiRequest: true,
        queryParameter: routeParameters,

      };
      let query = {
        payload: payload,
      };
      const result = await jiraService.find(
        { query: query },
        {
          headers: {
            "x-api-key": apiKey,
            "oauth-accesstoken": tokenString,
          },
        }
      );
      return result;
     
    } catch (error) {
      console.log(error);

      return [];
    }
  },
};

const getters = {};

const jiraModule = {
  state,
  mutations,
  actions,
  getters,
};

export default jiraModule;
