import { createRouter, createWebHistory } from "vue-router";

import ProgressView from "./views/ProgressView.vue";
import Login from "./views/Login.vue";
import Home from "./views/Home.vue";
import Dashboard from "./views/Dashboard.vue";
import Logout from "./views/Logout.vue";
import SubmissionLibrary from "./views/SubmissionLibrary.vue";
import PlaybookLibrary from "./views/PlaybookLibrary.vue";
import Checklist from "./views/Checklist.vue";
import Knowledgebase from "./views/Knowledgebase.vue";

import UnAuthorized from './views/403.vue';
import NotFound from './views/404'
import ShowInfo from './views/showInfo.vue';
import SubmissionClosed from './views/submissionClosed.vue';
import MyIdeas from './views/MyIdeas.vue';
import NewIdea from './views/NewIdea.vue';
import IdeaDetails from './views/IdeaDetails.vue';
import MyIdeaDetails from './views/MyIdeaDetails.vue';
import IdeaProcess from './views/IdeaProcess.vue';
import Judging from "./views/Judging.vue";
import MyProfile from './views/MyProfile'

import Setting from './views/admin/Setting';
import WebPages from './views/admin/WebPages';
import PageView from './views/admin/PageView';
import LeaderBoard from './views/admin/LeaderBoard';
import CommentSettings from './views/admin/CommentSettings';
import Logs from './views/admin/Logs';
import PagePreviewView from './views/admin/PagePreviewView'
import AddUpdateWebpage from './views/admin/AddUpdateWebpage';
import { routeGuard } from './services/auth0.js';
import Access from './views/admin/Access';


const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { layout: "empty" },
  },
  {
    path: '/login',
    component: Login,
    meta: { layout: "empty" },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: routeGuard,
  },
  {
    path: "/web/:page",
    name: "PageView",
    component: PageView,
    beforeEnter: routeGuard,
  },
  {
    path: "/web/preview/:page",
    name: "PagePreviewView",
    component: PagePreviewView,
    beforeEnter: routeGuard,
  },
  {
    path: "/web",
    name: "NotFound",
    component: NotFound,
    beforeEnter: routeGuard,
  },
  {
    path: "/myProfile",
    name: "myProfile",
    component: MyProfile,
    beforeEnter: routeGuard,
  },
  {
    path: "/profile/:userId/:userName",
    name: "UserProfile",
    component: MyProfile,
    beforeEnter: routeGuard,
  },

  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/webPages",
    name: "WebPages",
    component: WebPages,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/webPages/:type",
    name: "AddUpdateWebpage",
    component: AddUpdateWebpage,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/leaderBoard",
    name: "LeaderBoard",
    component: LeaderBoard,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/comments",
    name: "Comments",
    component: CommentSettings,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/logs",
    name: "Logs",
    component: Logs,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/submissions",
    name: "Access",
    component: Access,
    beforeEnter: routeGuard,
  },
  {
    path: "/progressview",
    name: "ProgressView",
    component: ProgressView,
    beforeEnter: routeGuard,
    meta: {
      authorize: {
        role: ['manager', 'executive']
      },
    },
  },
  {
    path: "/myideas",
    name: "MyIdeas",
    component: MyIdeas,
    beforeEnter: routeGuard,
  },
  {
    path: "/submitIdea",
    name: "NewIdea",
    component: NewIdea,
    beforeEnter: routeGuard,
  },
  {
    path: "/myideas/edit",
    name: "EditIdea",
    component: NewIdea,
    beforeEnter: routeGuard,
  },
  // {
  //   path: "/myideas/details",
  //   name: "MyIdeaDetails",
  //   component: MyIdeaDetails,
  //   beforeEnter: routeGuard,
  // },
  {
    path: "/myideas/details/:id",
    name: "MyIdeaDetails",
    component: MyIdeaDetails,
    beforeEnter: routeGuard,
  },
  {
    path: "/allideas",
    name: "SubmissionLibrary",
    component: SubmissionLibrary,
    beforeEnter: routeGuard,
  },
  {
    path: "/allideas/details/:id",
    name: "IdeaDetails",
    component: IdeaDetails,
    beforeEnter: routeGuard,
  },
  {
    path: "/playbooks",
    name: "PlaybookLibrary",
    component: PlaybookLibrary,
    beforeEnter: routeGuard,
  },
  {
    path: "/knowledgebase",
    name: "Knowledgebase",
    component: Knowledgebase,
    beforeEnter: routeGuard,
  },
  {
    path: "/checklist",
    name: "Checklist",
    component: Checklist,
    beforeEnter: routeGuard,
  },
  {
    path: '/unauthorized',
    component: UnAuthorized,
  },
  {
    path: '/showinfo',
    component: ShowInfo,
  },
  {
    path: '/submissionclosed',
    component: SubmissionClosed,
  },
  {
    path: '/logout',
    name: "Logout",
    component: Logout,
    beforeEnter: routeGuard,
  },
  {
    path: "/processflow",
    name: "IdeaProcess",
    component: IdeaProcess,
    beforeEnter: routeGuard,
  },

  {
    path: "/judging",
    name: "Judging",
    component: Judging,
    beforeEnter: routeGuard,
    meta: {
      authorize: {
        role: ['judgeManager', 'judgeMember', 'manager'],
      },
    },
  },
  {
    path: "/judging/:status",
    name: "Judging",
    component: Judging,
    beforeEnter: routeGuard,
    meta: {
      authorize: {
        role: ['judgeManager', 'judgeMember', 'manager'],
      },
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: routeGuard,
    meta: {
      authorize: {
        role: ['executive','judgeManager', 'judgeMember','manager'],
      },
    },
  },
  { path: '/notFound', component: <NotFound /> },
  { path: '/:pathMatch(.*)*', component: <NotFound /> }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, SavedPosition) {
    setTimeout(() => {
      if (to.hash) {
        const el = window.location.href.split("#")[1];
        if (el.length) {
          document.getElementById(el).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
          // document.getElementsByClassName(el)[0].scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center'});
        }
      } else if (SavedPosition) {
        return SavedPosition;
      } else {
        document.getElementById("app").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
    }, 1000);
  },
});

export default router;