<template>
  <div class="px-8 py-5">
    <div v-if="selectedInitiative == null">
      <loader-view
        v-if="loadingFlag"
        :loaderFlag="loadingFlag"
        loadingTitle="Loading..."
      >
      </loader-view>
    </div>
    <div v-if="!loadingFlag && selectedInitiative">
      <div>
        <div class="flex mb-4 w-full mx-auto">
          <a
            class="
              mr-1
              cursor-pointer
              text-indigo-500
              inline-flex
              items-left
              md:mb-2
              lg:mb-0
            "
            @click.prevent="backToList"
          >
            My Submissions
          </a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mt-0.5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="ml-1">{{ selectedInitiative.name }}</span>
        </div>

        <div class="overflow-x-hidden">
          <div class="">
            <div class="flex justify-between container mx-auto">
              <div class="w-full lg:w-8/12">
                <div class="">
                  <div class="max-w-4xl bg-white pb-2 rounded-lg shadow-md">
                    <div class="flex w-full border-b px-10 py-6">
                      <div class="flex-1 pr-14" v-if="selectedInitiative.phase && !isJudgeOrUser">
                        <div class="text-gray-400 text-sm font-bold">
                          Submission's Current Phase
                        </div>
                        <div class="text-gray-700">
                          {{ selectedInitiative.phase }}
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="float-left">
                          <div class="text-gray-400 text-sm font-bold">
                            Submission Submitted
                          </div>
                          <div class="text-gray-700">
                            {{ selectedInitiative.date }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="float-right">
                          <div class="text-gray-400 text-sm font-bold">
                            Updated
                          </div>
                          <div class="text-gray-700">
                            {{
                              timeago.format(
                                new Date(selectedInitiative.updatedAt)
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-1" v-if="userAccess"></div>
                    </div>

                    <div class="w-full px-10 py-6">
                      <div class="mb-3 text-2xl text-gray-700 font-bold">
                        <div class="text-gray-400 text-sm font-bold">
                          Submission Title<span v-if="submitInProgress && label === 'name'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                        </div>
                        <div v-if="editField === 'name'">
                          <inline-edit
                            len="100"
                            component="text"
                            label="name"
                            :value="selectedInitiative.name"
                            @editMe="editMe"
                            @updateValue="updateValue"
                          ></inline-edit>
                        </div>

                        <div
                          v-else
                          @click="
                            editAccess && checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('name')
                              : ''
                          "
                          :class="hoverClass"
                          class="
                            text-2xl text-gray-700
                            font-bold
                            py-1
                            break-words
                          "
                        >
                          {{ selectedInitiative.name }}
                        </div>
                      </div>
                      <div class="mb-3">
                        <div class="text-gray-400 text-sm font-bold">
                          <set-title
                            v-if="playbook"
                            :metaDataSchema="playbook.metaDataSchema"
                            name="challengeType"
                            defaultText="Theme"
                          />
                        </div>
                        <div class="" v-if="editField === 'Theme'">
                          <div class="w-full">
                            <inline-edit
                              component="singleSelect"
                              :value="selectedInitiative.challengeType"
                              @editMe="editMe"
                              label="challengeType"
                              @updateValue="updateValue"
                              :options="challengeTypes"
                            >
                            </inline-edit>
                          </div>
                        </div>

                        <div
                          class="flex py-2 upppercase text-gray-700"
                          v-else
                          :class="hoverClassDropdown"
                          @click="
                            editAccess && checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('Theme')
                              : ''
                          "
                        >
                          <span
                            v-if="
                              typeof selectedInitiative.challengeType ===
                              'object'
                            "
                          >
                            <span
                              v-for="type in selectedInitiative.challengeType"
                              :key="type"
                              class="
                                mr-1
                                inline-block
                                px-2
                                py-1
                                leading-none
                                 border-blue-500
                                text-blue-500
                                rounded-full
                                font-semibold
                                tracking-wide
                                text-xs
                              "
                              >{{ type }}</span
                            ><br />
                          </span>
                          <span
                            v-if="
                              typeof selectedInitiative.challengeType ===
                              'string'
                            "
                          >
                            <span
                              class="
                                mr-1
                                inline-block
                                px-2
                                py-1
                                leading-none
                                border border-blue-500
                                text-blue-500
                                rounded-full
                                font-semibold
                                tracking-wide
                                text-xs
                              "
                              >{{ selectedInitiative.challengeType }}</span
                            ><br />
                          </span>
                        </div>
                      </div>

                      <div class="mb-3">
                        <div class="text-gray-400 text-sm font-bold">
                          Tags
                          <span class="text-gray-400 text-xs font-normal">
                            (After typing a tag below, press “Enter” to save the tag.)</span
                          >
                        </div>
                        <div class="" v-if="editField === 'tagging'">
                          <div class="w-full">
                            <inline-edit
                              isRequired="true"
                              component="tagging"
                              :value="selectedInitiative.tags"
                              @editMe="editMe"
                              label="tags"
                              @updateValue="updateValue"
                              :options="selectedInitiative.tags"
                            >
                            </inline-edit>
                          </div>
                        </div>

                        <div
                          class="flex py-2 upppercase text-gray-700"
                          v-else
                          :class="hoverClassDropdown"
                          @click="
                            editAccess && checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('tagging')
                              : ''
                          "
                        >
                          <span
                            v-if="
                              selectedInitiative.tags &&
                              selectedInitiative.tags.length !== 0
                            "
                          >
                            <span
                              v-for="tag in selectedInitiative.tags"
                              :key="tag"
                              class="
                                mr-1
                                inline-block
                                px-2
                                py-1
                                leading-none
                                border border-blue-500
                                text-blue-500
                                rounded-full
                                font-semibold
                                tracking-wide
                                text-xs
                                break-all
                              "
                              >{{ tag }}</span
                            >
                          </span>
                          <span v-else>NA</span><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Problem Statement -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="mt-2">
                      <div class="text-xl text-gray-700 font-bold">
                        Problem Statement<span v-if="submitInProgress && label === 'description'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                      </div>

                      <div class="mt-3">
                        <div v-if="editField === 'description'">
                          <inline-edit
                            len="3000"
                            :minLen=1000
                            component="textarea"
                            label="description"
                            :value="selectedInitiative.description"
                            @editMe="editMe"
                            @updateValue="updateValue"
                          >
                          </inline-edit>
                        </div>
                        <div
                          v-else
                         
                          @click="
                            editAccess && checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('description')
                              : ''
                          "
                          :class="hoverClass"
                          class="mt-2 text-base text-gray-600 py-1"
                        >
                        <div v-html="selectedInitiative.description" class="prose space-y-0 html-text word-wrap"></div>
                          <!-- <RichText :text="selectedInitproseiative.description" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Problem Description -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="mt-2">
                      <div class="text-xl text-gray-700 font-bold">
                        <set-title
                          v-if="playbook"
                          :metaDataSchema="playbook.metaDataSchema"
                          name="problemDescription"
                          defaultText="Problem Description"
                        /><span v-if="submitInProgress && label === 'problemDescription'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                      </div>

                      <div class="mt-3">
                        <div v-if="editField === 'problemDescription'">
                          <inline-edit
                            len="3000"
                            :minLen=1000
                            component="textarea"
                            :value="selectedInitiative.problemDescription"
                            @editMe="editMe"
                            label="problemDescription"
                            @updateValue="updateValue"
                          >
                          </inline-edit>
                        </div>
                        <div
                          v-else
                          @click="
                            editAccess && checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('problemDescription')
                              : ''
                          "
                          class="mt-2 text-base text-gray-600 py-1"
                          :class="hoverClass"

                        >
                        <P v-html="selectedInitiative.problemDescription" class="prose space-y-0 html-text word-wrap"></P>
                          <!-- <RichText
                            :text="selectedInitiative.problemDescription"
                          /> -->
                        </div>
                      </div>

                      <div class="mt-2">
                        <div v-if="!getProblemDescriptionImage">
                          <submission-attachment
                            metaField="problemDescription"
                            :accessToken="accessToken"
                            :attachments="this.selectedInitiative.attachments"
                            @fileUploaded="fileUploaded"
                          />
                        </div>
                        <div v-if="getProblemDescriptionImage" class="mt-6">
                          <div
                            class="flex bg-white flex-col rounded-lg py-1 px-3"
                          >
                            <div class="w-full flex p-1">
                              <div class="mr-3 text-gray-500 relative flex">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div class="flex-grow overflow-hidden">
                                <div
                                  class="
                                    font-semibold
                                    text-sm text-gray-700
                                    underline
                                    text-blue-600
                                    hover:text-blue-800
                                    visited:text-purple-600
                                    break-all
                                    cursor-pointer
                                  "
                                  @click="
                                    downloadAttachment(
                                      getProblemDescriptionImage
                                    )
                                  "
                                >
                                  {{
                                    getProblemDescriptionImage.documentName.split(
                                      ".problemDescription"
                                    )[0]
                                  }}
                                </div>
                              </div>
                              <div class="">
                                <div
                                  @click="
                                    deleteAttachmentById(
                                      getProblemDescriptionImage.documentId
                                    )
                                  "
                                  class="cursor-pointer blue-400 text-blue-600"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="
                                      feather feather-trash-2
                                      hover:opacity-50
                                    "
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                      x1="10"
                                      y1="11"
                                      x2="10"
                                      y2="17"
                                    ></line>
                                    <line
                                      x1="14"
                                      y1="11"
                                      x2="14"
                                      y2="17"
                                    ></line>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Problem potentialMarket -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="text-xl text-gray-700 font-bold">
                      <set-title
                        v-if="playbook"
                        :metaDataSchema="playbook.metaDataSchema"
                        name="potentialMarket"
                        defaultText="Potential First Market"
                      /><span v-if="submitInProgress && label === 'potentialMarket'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                    </div>

                    <div class="mt-3">
                      <div v-if="editField === 'potentialMarket'">
                        <inline-edit
                          len="3000"
                          :minLen=1000
                          component="textarea"
                          :value="selectedInitiative.potentialMarket"
                          @editMe="editMe"
                          label="potentialMarket"
                          @updateValue="updateValue"
                        >
                        </inline-edit>
                      </div>
                      <div
                        v-else
                        @click="
                          editAccess && checkRoleBasedOnCreateInitiativeFlag
                            ? editMe('potentialMarket')
                            : ''
                        "
                        class="mt-2 text-base text-gray-600 py-1"
                        :class="hoverClass"
                      >
                      <P v-html="selectedInitiative.potentialMarket" class="prose space-y-0 html-text word-wrap"></P>
                        <!-- <RichText :text="selectedInitiative.potentialMarket" /> -->
                      </div>
                    </div>

                    <div class="mt-2">
                      <div v-if="!getPotentialMarketImage">
                        <submission-attachment
                          metaField="potentialMarket"
                          :accessToken="accessToken"
                          :attachments="this.selectedInitiative.attachments"
                          @fileUploaded="fileUploaded"
                        />
                      </div>
                      <div v-if="getPotentialMarketImage" class="mt-6">
                        <div
                          class="flex bg-white flex-col rounded-lg py-1 px-3"
                        >
                          <div class="w-full flex p-1">
                            <div class="mr-3 relative flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div class="flex-grow overflow-hidden">
                              <div
                                class="
                                  font-semibold
                                  text-sm text-gray-700
                                  underline
                                  text-blue-600
                                  hover:text-blue-800
                                  visited:text-purple-600
                                  break-all
                                  cursor-pointer
                                "
                                @click="
                                  downloadAttachment(getPotentialMarketImage)
                                "
                              >
                                {{
                                  getPotentialMarketImage.documentName.split(
                                    ".potentialMarket"
                                  )[0]
                                }}
                              </div>
                              
                            </div>
                            <div class="">
                              <div
                                @click="
                                  deleteAttachmentById(
                                    getPotentialMarketImage.documentId
                                  )
                                "
                                class="cursor-pointer blue-400 text-blue-600"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="
                                    feather feather-trash-2
                                    hover:opacity-50
                                  "
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="mt-4" v-if="selectedInitiative?.metaData?.fcsDate">
                        <div class="mt-4">
                        <h3 class="font-bold mt-8">Launch/GA Date<span v-if="submitInProgress && label === 'fcsDate'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span></h3>
                        </div>
                        <div v-if="editField === 'fcsDate'">
                          <inline-edit
                            component="date"
                            :value="selectedInitiative.metaData.fcsDate"
                            @editMe="editMe"
                            label="fcsDate"
                            @updateValue="updateValue"
                          ></inline-edit>
                        </div>
                        <div
                          v-else
                          @click="
                            editAccess &&
                            !executiveAccess &&
                            checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('fcsDate')
                              : ''
                          "
                          :class="hoverClass"
                          class=" text-base text-gray-600  w-48"
                        >
                          <!-- <RichText
                            :text="selectedInitiative.problemDescription"
                          /> -->
                          <P v-if="selectedInitiative?.metaData?.fcsDate" >{{ formattedDate(selectedInitiative?.metaData?.fcsDate) }}</P>
                          <P v-else>NA</P>
                        </div>
                      </div>
                      <div class="mt-6" v-if="selectedInitiative?.metaData?.revenueDate">
                        <h3 class="font-bold">Revenue to-date<span v-if="submitInProgress && label === 'revenueDate'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span></h3>
                        <div v-if="editField === 'revenueDate'">
                          <inline-edit
                            component="currency"
                            :value="selectedInitiative.metaData.revenueDate"
                            @editMe="editMe"
                            label="revenueDate"
                            @updateValue="updateValue"
                          ></inline-edit>
                        </div>
                        <div
                          v-else
                          @click="
                            editAccess &&
                            !executiveAccess &&
                            checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('revenueDate')
                              : ''
                          "
                          :class="hoverClass"
                          class=" text-base text-gray-600  w-48"
                        >
                          <!-- <RichText
                            :text="selectedInitiative.problemDescription"
                          /> -->
                          <!-- <P v-html="selectedInitiative.metaData.revenueDate"></P> -->
                          <div v-if="selectedInitiative?.metaData?.revenueDate">
                              <P>{{ formatInput(selectedInitiative.metaData.revenueDate) }}</P>
                          </div>
                          <P v-else>NA</P>
                        </div>
                        <p class="text-xs text-gray-500">(Currency in USD)</p>
                      </div>
                      <!-- <div class="mt-6" v-if="selectedInitiative?.metaData?.activationDate">
                        <h3 class="font-bold">User Count/Activation to-date</h3>
                        <div v-if="editField === 'activationDate'">
                          <inline-edit
                            component="number"
                            :value="selectedInitiative.metaData.activationDate"
                            @editMe="editMe"
                            label="activationDate"
                            @updateValue="updateValue"
                          ></inline-edit>
                        </div>
                        <div
                          v-else
                          @click="
                            editAccess &&
                            !executiveAccess &&
                            checkRoleBasedOnCreateInitiativeFlag
                              ? editMe('activationDate')
                              : ''
                          "
                          :class="hoverClass"
                          class=" text-base text-gray-600  w-48"
                        >
                           <RichText
                            :text="selectedInitiative.problemDescription"
                          /> 
                          <P v-if="selectedInitiative.metaData.activationDate" v-html="selectedInitiative.metaData.activationDate" class="prose word-wrap"></P>
                          <P v-else>NA</P>
                        </div>
                      </div> -->
                      
                      </div>
                     
                      
                    </div>
                  </div>
                </div>

                <!-- Problem elevatorPitch -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="text-xl text-gray-700 font-bold">
                      <set-title
                        v-if="playbook"
                        :metaDataSchema="playbook.metaDataSchema"
                        name="elevatorPitch"
                        defaultText="Elevator Pitch"
                      /><span v-if="submitInProgress && label === 'elevatorPitch'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                    </div>

                    <div class="mt-3">
                      <div v-if="editField === 'elevatorPitch'">
                        <inline-edit
                          len="1500"
                          component="textarea"
                          :value="selectedInitiative.elevatorPitch"
                          @editMe="editMe"
                          label="elevatorPitch"
                          @updateValue="updateValue"
                        >
                        </inline-edit>
                      </div>
                      <div
                        v-else
                        @click="
                          editAccess && checkRoleBasedOnCreateInitiativeFlag
                            ? editMe('elevatorPitch')
                            : ''
                        "
                        class="mt-2 text-base text-gray-600 py-1"
                        :class="hoverClass"
                      >
                      <P v-html="selectedInitiative.elevatorPitch" class="prose space-y-0 html-text word-wrap"></P>
                        <!-- <RichText :text="selectedInitiative.elevatorPitch" /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Problem aboutTeam -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="text-xl text-gray-700 font-bold">
                      <set-title
                        v-if="playbook"
                        :metaDataSchema="playbook.metaDataSchema"
                        name="aboutTeam"
                        defaultText="About You / Your Team"
                      /><span v-if="submitInProgress && label === 'aboutTeam'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                    </div>

                    <div class="mt-3">
                      <div v-if="editField === 'aboutTeam'">
                        <inline-edit
                          len="1500"
                          component="textarea"
                          :value="selectedInitiative.aboutTeam"
                          @editMe="editMe"
                          label="aboutTeam"
                          @updateValue="updateValue"
                        >
                        </inline-edit>
                      </div>
                      <div
                        v-else
                        @click="
                          editAccess && checkRoleBasedOnCreateInitiativeFlag
                            ? editMe('aboutTeam')
                            : ''
                        "
                        class="mt-2 text-base text-gray-600 py-1"
                        :class="hoverClass"
                      >
                      <P v-html="selectedInitiative.aboutTeam" class="prose space-y-0 html-text word-wrap"></P>
                        <!-- <RichText :text="selectedInitiative.aboutTeam" /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Problem testimonialAwardsIpAndOpenSource -->
                <div class="mt-6">
                  <div
                    class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                  >
                    <div class="text-xl text-gray-700 font-bold">
                      <set-title
                        v-if="playbook"
                        :metaDataSchema="playbook.metaDataSchema"
                        name="testimonialAwardsIpAndOpenSource"
                        defaultText="Testimonial, Awards, IP and Open Source"
                      /><span v-if="submitInProgress && label === 'testimonialAwardsIpAndOpenSource'" class="fa fa-circle-o-notch text-md ml-4 fa-spin"></span>
                    </div>

                    <div class="mt-3">
                      <div
                        v-if="editField === 'testimonialAwardsIpAndOpenSource'"
                      >
                        <inline-edit
                          len="1500"
                          component="textarea"
                          :value="
                            selectedInitiative.testimonialAwardsIpAndOpenSource
                          "
                          @editMe="editMe"
                          label="testimonialAwardsIpAndOpenSource"
                          @updateValue="updateValue"
                        >
                        </inline-edit>
                      </div>
                      <div
                        v-else
                        @click="
                          editAccess && checkRoleBasedOnCreateInitiativeFlag
                            ? editMe('testimonialAwardsIpAndOpenSource')
                            : ''
                        "
                        class="mt-2 text-base text-gray-600 py-1"
                        :class="hoverClass"
                      >
                        <!-- <RichText
                          :text="
                            selectedInitiative.testimonialAwardsIpAndOpenSource
                          "
                        /> -->
                        <P v-html="selectedInitiative.testimonialAwardsIpAndOpenSource" class="prose space-y-0 html-text word-wrap"></P>
                      </div>
                    </div>

                    <div class="mt-2">
                      <div v-if="!getTestimonialAwardsIpAndOpenSourceImage">
                        <submission-attachment
                          metaField="testimonialAwardsIpAndOpenSource"
                          :accessToken="accessToken"
                          :attachments="this.selectedInitiative.attachments"
                          @fileUploaded="fileUploaded"
                        />
                      </div>
                      <div
                        v-if="getTestimonialAwardsIpAndOpenSourceImage"
                        class="mt-6"
                      >
                        <div
                          class="flex bg-white flex-col rounded-lg py-1 px-3"
                        >
                          <div class="w-full flex p-1">
                            <div class="mr-3 text-gray-500 relative flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div class="flex-grow overflow-hidden">
                              <div
                                class="
                                  font-semibold
                                  text-sm text-gray-700
                                  underline
                                  text-blue-600
                                  hover:text-blue-800
                                  visited:text-purple-600
                                  break-all
                                  cursor-pointer
                                "
                                @click="
                                  downloadAttachment(
                                    getTestimonialAwardsIpAndOpenSourceImage
                                  )
                                "
                              >
                                {{
                                  getTestimonialAwardsIpAndOpenSourceImage.documentName.split(
                                    ".testimonialAwardsIpAndOpenSource"
                                  )[0]
                                }}
                              </div>
                            </div>
                            <div class="">
                              <div
                                @click="
                                  deleteAttachmentById(
                                    getTestimonialAwardsIpAndOpenSourceImage.documentId
                                  )
                                "
                                class="cursor-pointer blue-400 text-blue-600"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="
                                    feather feather-trash-2
                                    hover:opacity-50
                                  "
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-6">
                  <div class="text-xl text-gray-700 mb-5 font-bold">
                    <div>Comments ({{ allMessagesCount }})</div>
                  </div>
                  <div class="max-w-4xl">
                    <comments
                      :comments="comments"
                      :isParticipant="isParticipant"
                      :initiativeId="selectedInitiative.id"
                      :user="user"
                      @commentAdded="commentAdded"
                      commentsFor="ideaDetails"
                      @commentsCount="commentsCount"
                      :isWso="isWso"
                    ></comments>
                    <!-- <div class="text-xl text-gray-700 mb-5 font-bold">
                      <div>Comments ({{comments?.length}})</div>
                    </div> -->
                  </div>
                </div>
                <!-- //comments section ended -->

                <!-- add comment start  -->
                <div
                  class="
                    mt-5
                    max-w-4xl
                    px-10
                    py-6
                    bg-white
                    rounded-lg
                    shadow-md
                  "
                  v-if="
                    selectedInitiative.status != 2 &&
                    selectedInitiative.status != 3 &&
                    selectedInitiative.status != 5 &&
                    selectedInitiative.status != 6
                  "
                >
                  <!-- <label for="note" class="text-sm font-semibold block mb-2"
                        >Add a note</label
                      > -->
                  <textarea
                    name="note"
                    placeholder="Add a comment"
                    id="note"
                    rows="6"
                    v-model="note"
                    class="
                      bg-white
                      appearance-none
                      border border-gray-200
                      rounded
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:bg-white focus:border-gray-500
                    "
                  ></textarea>
                  <div class="flex mt-2 justify-end">
                    <button
                      v-if="checkRoleBasedOnCreateInitiativeFlag"
                      class="
                        inline-flex
                        text-white
                        bg-indigo-500
                        border-0
                        py-1
                        px-3
                        focus:outline-none
                        hover:bg-indigo-600
                        rounded
                        text-md
                        disabled:opacity-30
                      "
                      type="button"
                      :disabled="note.length === 0"
                      @click="postNote"
                    >
                      <span class="ml-1">Add Comment</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="mx-8 w-4/12 hidden lg:block">
                <div v-if="!userAccess" class="mb-6">
                  <div
                    class="
                      flex
                      relative
                      bg-white
                      shadow
                      rounded-md
                      px-10
                      py-6
                      sm:pr-3
                    "
                  >
                    <notifications-bar v-if="showMessage" />
                    <div class="flex-1">
                      <div class="text-gray-400 text-sm font-bold">Status</div>
                      <div v-if="isJudgeOrUser  && (selectedInitiative.status == 1 ||selectedInitiative.status == 6)" class="text-green-500">
                        {{ "Submitted" }}
                      </div>
                      <div v-else
                        class=""
                        :class="{
                          'text-green-500': selectedInitiative.status === -1,
                          'text-yellow-500': selectedInitiative.status === 0,
                          'text-green-500': selectedInitiative.status === 1,
                          'text-blue-500': selectedInitiative.status === 2,
                          'text-gray-500': selectedInitiative.status === 3,
                          'text-red-500': selectedInitiative.status === 5,
                          'text-gray-400': selectedInitiative.status === 6,
                          'text-gray-500': selectedInitiative.status === 7,
                        }"
                      >
                        {{ selectedInitiative.statusLabel }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-0">
                  <div
                    class="
                      c-card
                      block
                      bg-white
                      shadow-md
                      ease
                      rounded-lg
                      overflow-hidden
                    "
                  >
                    <div class="group relative overflow-hidden">
                      <div
                        v-if="imageUploading"
                        wire:loading
                        class="
                          absolute
                          top-0
                          left-0
                          right-0
                          bottom-0
                          z-50
                          overflow-hidden
                          bg-gray-700
                          opacity-75
                          flex flex-col
                          items-center
                          justify-center
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-10 w-10 animate-spin text-white"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div class="" v-if="editAccess">
                        <label
                          for="file-input"
                          class="
                            group-hover:block
                            hidden
                            absolute
                            z-30
                            bottom-2
                            right-2
                            p-1
                            rounded-full
                            bg-white
                            hover:bg-gray-300
                            cursor-pointer
                            shadow-lg
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                            />
                          </svg>
                        </label>

                        <input
                          id="file-input"
                          type="file"
                          class="hidden"
                          ref="file"
                          v-on:change="onAddFiles()"
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                      </div>

                      <img
                        class="inset-0 h-full w-full object-cover z-10"
                        :src="selectedInitiative.image"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div class="mt-10">
                  <div
                    class="my-3 flex flex-wrap items-center"
                    v-if="selectedInitiative.status !== 6"
                  >
                    <action-list
                      @openSignUp="openSignUp"
                      @openRequestModal="openRequestModal"
                      @openConfigureDateModal="openConfigureDateModal"
                      @openMarkAsCompletedModal="openMarkAsCompletedModal"
                      @viewMyProgress="viewMyProgress"
                      @viewProgress="viewProgress"
                      :selectedInitiative="selectedInitiative"
                      :user="user"
                    />
                  </div>
                </div>

                <div class="mt-10">
                  <div class="flex space-x-5 ...">
                    <h2 class="mb-4 text-xl font-bold text-gray-700 flex-1">
                      <set-title
                        v-if="playbook"
                        :metaDataSchema="playbook.metaDataSchema"
                        name="teamMembers"
                        defaultText="Team Members"
                      />
                      ({{ setTeamMembers.length }})
                    </h2>
                  </div>
                  <div class="flex flex-col mx-auto">
                    <ul class="">
                      <li
                        class="flex items-center"
                        v-bind:class="{ 'mt-1': index > 0 }"
                        v-for="(user, index) in setTeamMembers"
                        :key="index"
                      >
                        <user-card :selectUser="user" />
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- manage team -->
                <div
                  v-if="
                    selectedInitiative.status !== 5 &&
                    selectedInitiative.status !== 3 &&
                    editAccess &&
                    checkRoleBasedOnCreateInitiativeFlag
                  "
                  class="flex bg-white mt-4"
                >
                  <button
                    class="
                      w-full
                      justify-center
                      px-6
                      py-2
                      bg-indigo-500
                      rounded
                    "
                    @click="manageMyTeam"
                  >
                    <span class="text-white">Manage Team</span>
                  </button>
                </div>

                <!-- manage team -->
                <!-- proposer new card -->
                <div
                  class="mt-10"
                  v-if="
                    selectedInitiative.status !== 6 &&
                    isWso &&
                    checkRoleBasedOnCreateInitiativeFlag
                  "
                >
                  <h2 class="mb-4 text-xl font-bold text-gray-700">
                    <set-title
                      v-if="playbook"
                      :metaDataSchema="playbook.metaDataSchema"
                      name="Proposer()"
                      defaultText="Submitter"
                    />
                  </h2>
                  <div class="flex flex-col mx-auto">
                    <ul class="">
                      <li
                        class="flex items-center"
                        v-bind:class="{ 'mt-1': index > 0 }"
                        v-for="(user, index) in proposer"
                        :key="index"
                      >
                        <user-card :selectUser="user" />
                      </li>
                    </ul>
                    <div class="flex bg-white mt-4">
                      <button
                        class="
                          w-full
                          justify-center
                          px-6
                          py-2
                          bg-indigo-500
                          rounded
                        "
                        @click="changeProposer"
                      >
                        <span class="text-white">Change</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-3" v-if="selectedInitiative?.metaData?.coreTeamSize">
                      <h2 class="mb-4 text-xl font-bold text-gray-700 mt-8">
                        Core Team Size
                      </h2>
                      <div class="h-12 pt-3 text-lg ml-4" v-if="editField === 'coreTeamSize'">
                        <inline-edit
                          len="3000"
                          component="number"
                          label="coreTeamSize"
                          :value="selectedInitiative.metaData.coreTeamSize"
                          @editMe="editMe"
                          @updateValue="updateValue"
                        ></inline-edit>
                      </div>
                      <div
                        v-else
                        @click="
                          editAccess &&
                          !executiveAccess &&
                          checkRoleBasedOnCreateInitiativeFlag
                            ? editMe('coreTeamSize')
                            : ''
                        "
                        :class="hoverClass"
                        class="mt-2 text-base text-gray-600 py-1 bg-white rounded-md "
                      >
                        <!-- <RichText :text="selectedInitiative.description" /> -->
                        <h2 v-if="selectedInitiative?.metaData?.coreTeamSize" class="h-12 pt-3 text-lg ml-4">{{ selectedInitiative?.metaData?.coreTeamSize}}</h2>
                        <h2 v-else class="h-12 pt-3 text-lg ml-4">NA</h2>
                      </div>
                    </div>
                <!-- Attachment -->
                <div class="mt-10">
                  <div class="mb-3 flex flex-wrap items-center">
                    <div class="relative w-full max-w-full flex-grow flex-1">
                      <h2 class="text-xl font-bold text-gray-700">
                        Attachments
                      </h2>
                    </div>
                  </div>

                  <div
                    v-if="selectedInitiative?.attachments?.length > 0"
                    class="flex flex-col mx-auto rounded-lg bg-white"
                  >
                    <div
                      v-for="(item, index) in selectedInitiative.attachments"
                      :key="index"
                      class="flex border-b"
                    >
                      <div class="ml-2 mr-2 relative flex pt-3">
                        <svg
                          v-if="item.documentUrl"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <svg
                          v-if="!item.documentUrl"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div class="w-full pr-4 py-2 overflow-hidden">
                        <div
                          class="text-gray-700 break-all truncate block"
                          :class="{
                            ' hover:underline cursor-pointer':
                              !item.documentUrl,
                          }"
                          @click="
                            !item.documentUrl ? downloadAttachment(item) : null
                          "
                        >
                          {{
                            item.documentName.substring(
                              0,
                              item.documentName.lastIndexOf(".")
                            )
                          }}
                        </div>
                        <div
                          class="
                            text-gray-400
                            break-all
                            -mt-1
                            truncate
                            block
                            hover:underline
                            cursor-pointer
                          "
                          @click="goToUrl(item)"
                        >
                          {{ item.documentUrl }}
                        </div>
                        <div class="block text-sm mb-2 mt-1 text-gray-500">
                          {{ timeago.format(new Date(item.uploadedAt)) }} .
                          <span
                            class="hover:underline cursor-pointer"
                            @click="goToProfile(item?.uploadedBy)"
                          >
                            {{
                              item.uploadedBy.firstName
                                ? item.uploadedBy.firstName +
                                  " " +
                                  item.uploadedBy.lastName
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="
                          w-5
                          relative
                          inline-block
                          text-left
                          dropdown
                          pt-1
                        "
                      >
                        <span class="float-right text-right p-2 right-0"
                          ><button
                            class="
                              inline-flex
                              justify-center
                              w-full
                              font-medium
                              leading-5
                              text-gray-700
                              transition
                              duration-150
                              ease-in-out
                              bg-white
                              hover:text-gray-400
                              focus:outline-none
                              focus:border-blue-300
                              focus:shadow-outline-blue
                              active:bg-gray-50 active:text-gray-800
                            "
                            type="button"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            <svg
                              class="w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                              />
                            </svg></button
                        ></span>
                        <div
                          class="
                            opacity-0
                            invisible
                            dropdown-menu
                            transition-all
                            duration-300
                            transform
                            origin-top-right
                            -translate-y-2
                            scale-95
                            z-100
                            relative
                          "
                        >
                          <div
                            class="
                              absolute
                              right-0
                              w-36
                              p-2
                              mt-2
                              origin-top-right
                              bg-white
                              border border-gray-200
                              divide-y divide-gray-100
                              rounded-md
                              shadow-lg
                              outline-none
                            "
                            aria-labelledby="headlessui-menu-button-1"
                            role="menu"
                          >
                            <a
                              target="_blank"
                              v-if="item.documentUrl"
                              :href="
                                item.documentUrl.indexOf('://') === -1
                                  ? 'http://' + item.documentUrl
                                  : item.documentUrl
                              "
                              class="
                                block
                                px-4
                                py-2
                                text-sm text-gray-800
                                hover:bg-gray-100
                              "
                              >View</a
                            >
                            <a
                              v-if="item.documentUrl && editAccess"
                              href="javascript:void(0)"
                              class="
                                block
                                px-4
                                py-2
                                text-sm text-gray-800
                                hover:bg-gray-100
                              "
                              @click="editFile(item)"
                              >Edit</a
                            >
                            <a
                              href="javascript:void(0)"
                              class="
                                block
                                px-4
                                py-2
                                text-sm text-gray-800
                                hover:bg-gray-100
                              "
                              @click="downloadAttachment(item)"
                              v-if="!item.documentUrl"
                              >Download</a
                            >
                            <a
                              href="javascript:void(0)"
                              class="
                                block
                                px-4
                                py-2
                                text-sm text-gray-800
                                hover:bg-gray-100
                              "
                              v-if="editAccess"
                              @click="deleteAttachment(index)"
                              >Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <info-card message="No files uploaded!" />
                  </div>
                  <div
                    class="mt-2 float-right text-right mr-2"
                    v-if="editAccess && checkRoleBasedOnCreateInitiativeFlag"
                  >
                    <add-files
                      :openAddFile="openAddFile"
                      @openAddFileModal="openAddFileModal"
                      @fileUploaded="fileUploaded"
                      :files="this.selectedInitiative.attachments"
                      :fileObj="fileObj"
                      :todo="todo"
                    ></add-files>
                  </div>
                </div>

                <div class="mt-10">
                  <!-- //shifting Submission history -->
                  <div class="mt-16">
                    <div v-if="!userAccess"
                      class="max-w-4xl px-10 py-6 bg-white rounded-lg shadow-md"
                    >
                      <div class="text-xl text-gray-700 mb-10 font-bold">
                        <div>Submission History</div>
                      </div>

                      <section class="text-gray-600 body-font">
                        <div class="container mx-auto">
                          <div class="flex flex-wrap w-full">
                            <div>
                              <div
                                v-for="(
                                  item, index
                                ) in selectedInitiative?.initativeLogs?.slice()"
                                :key="index"
                                class="flex relative pb-12"
                              >
                                <div
                                  class="
                                    h-full
                                    w-10
                                    absolute
                                    inset-0
                                    flex
                                    items-center
                                    justify-center
                                  "
                                >
                                  <div
                                    class="
                                      h-full
                                      w-1
                                      bg-gray-200
                                      pointer-events-none
                                    "
                                    v-if="item.comment"
                                  ></div>
                                </div>
                                <div
                                  class="
                                    flex-shrink-0
                                    w-10
                                    h-10
                                    rounded-full
                                    bg-indigo-500
                                    inline-flex
                                    items-center
                                    justify-center
                                    text-white
                                    relative
                                    z-10
                                  "
                                  v-if="item.comment"
                                >
                                  <svg
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    class="w-5 h-5"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M22 11.08V12a10 10 0 11-5.93-9.14"
                                    ></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                  </svg>
                                </div>
                                <div class="flex-grow pl-4" v-if="item.comment">
                                  <h2
                                    class="
                                      font-medium
                                      title-font
                                      uppercase
                                      text-sm text-gray-900
                                    "
                                  >
                                    {{ formatDate(item.commentDate) }}
                                  </h2>

                                  <div
                                    class="leading-relaxed"
                                    v-if="item.comment.includes('Note:')"
                                  >
                                    {{
                                      item.comment.substring(
                                        0,
                                        item.comment.indexOf("Note:")
                                      )
                                    }}<br />
                                    <span class="text-gray-400 italic text-md break-all"
                                      >Comment :
                                      {{
                                        item.comment.substring(
                                          item.comment.indexOf("Note:") + 5
                                        )
                                      }}</span
                                    >
                                  </div>
                                  <span class="leading-relaxed" v-else>
                                    <div
                                      class="leading-relaxed"
                                      v-if="
                                        item.comment.includes(
                                          'Initiative started'
                                        )
                                      "
                                    >
                                      Submission Received
                                    </div>
                                    <div class="leading-relaxed" v-else>
                                      {{
                                        item.comment.replace(
                                          /<\/?[^>]+>/gi,
                                          " "
                                        )
                                      }}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MyTeamManagementModal
      v-if="isModalVisible"
      :initiative="selectedInitiative"
      :msGraphAccessToken="msGraphAccessToken"
      @close="closeModal"
      @updateTeam="updateTeam"
    >
    </MyTeamManagementModal>
    <ChangeProposerModal
      v-if="isChangeProposer"
      :initiative="selectedInitiative"
      :msGraphAccessToken="msGraphAccessToken"
      :proposer="proposer"
      @close="closeProposarModal"
      @updateProposer="updateProposer"
      @deletePropopser="deletePropopser"
    >
    </ChangeProposerModal>
    <deleteAttachment
      :openClose="openClose"
      @openCloseModal="openCloseModal"
      @done="done"
      :todo="todo"
    >
    </deleteAttachment>
    <EditDeleteNote
      :openClose="openCloseNote"
      @openCloseModal="openCloseNoteModal"
      @editDeleteNotes="editDeleteNotes"
      :noteObj="noteObj"
      :todo="todo"
    >
    </EditDeleteNote>
    <schedule-for-openmic
      v-if="signUpOpen"
      :actionType="actionType"
      @closeSignUp="closeSignUp"
      @submitComment="submitComment"
    ></schedule-for-openmic>
    <ViewOpenMicRequest
      :actionType="actionType"
      v-if="viewOpenmicRequest"
      @closeRequestModal="closeRequestModal"
      :selectedInitiative="selectedInitiative"
    >
    </ViewOpenMicRequest>
    <ConfigureDateForOpenMic
      :actionType="actionType"
      v-if="configureDateOpen"
      @closeConfigureDateModal="closeConfigureDateModal"
      @submitConfigureSchedule="submitConfigureSchedule"
      :selectedInitiative="selectedInitiative"
      :configureOrScheduleEvent="configureOrScheduleEvent"
    >
    </ConfigureDateForOpenMic>
    <OpenmicScheduledMarkAsCompleted
      v-if="markAsCompletedOpen"
      @closeMarkAsCompletedModal="closeMarkAsCompletedModal"
      @submitMarkAsCompletedModal="submitMarkAsCompletedModal"
      :selectedInitiative="selectedInitiative"
    >
    </OpenmicScheduledMarkAsCompleted>
    <MyProgressModal
      v-if="openMyProgress && selectedInitiative"
      @closeMyProgress="closeMyProgress"
      :initiative="selectedInitiative"
      :allInitiatives="initiatives"
      :activitiesToUpdate="activitiesToUpdate"
      @getSeletedPhaseData="getSeletedPhaseData"
    ></MyProgressModal>
    <request-accepted
      :requestAcceptedModalFlag="requestAcceptedModalFlag"
      @acceptRequest="acceptRequest"
      :selectedInitiative="selectedInitiative"
    ></request-accepted>
    <request-details
      :requestDetailsFlag="requestDetailsFlag"
      @openDetailsPgae="openDetailsPgae"
      :selectedInitiative="selectedInitiative"
      :requestDetail="requestDetail"
    ></request-details>
    <request-rejectedreason
      :requestRejectedReasonModalFlag="requestRejectedReasonModalFlag"
      @requestRejected="requestRejected"
      @openRejectedRequestModal="openRejectedRequestModal"
      :selectedInitiative="selectedInitiative"
      :eachSingleRequest="eachSingleRequest"
    ></request-rejectedreason>
    <request-rejected
      :requestRejectedflag="requestRejectedflag"
      @openRejectedRequestModal="openRejectedRequestModal"
      :selectedInitiative="selectedInitiative"
      :requestRejectedReson="requestRejectedReson"
    ></request-rejected>
    <request-sentsuccess
      :requestSentSuccessModal="requestSentSuccessModal"
      @closerequestsentsuccessmodal="closerequestsentsuccessmodal"
      :selectedInitiative="selectedInitiative"
      :requestRejectedReson="requestRejectedReson"
    ></request-sentsuccess>
    <phase-modal
      v-if="isProgressModalVisible && selectedInitiative"
      :initiative="selectedInitiative"
      :allInitiatives="initiatives"
      :activitiesToUpdate="activitiesToUpdate"
      @close="closeProgressModal"
      @markComplete="completeActivity"
    ></phase-modal>
    <attachment-popup
    :openCloseAttachment="attachmentPopUpFlag"
    :documentData="documentData"
    >
    </attachment-popup>
    <bulk-info
    :openBulkInfo="openBulkInfoModal"
    @openBulkInfoModel="openBulkInfoModel"
    :headerText="headerText"
    :infoText="infoText"
    :footerText="footerText"
  ></bulk-info>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import MyTeamManagementModal from "../components/MyTeamManagementModal.vue";
import ChangeProposerModal from "../components/ChangeProposerModal.vue";
import InfoCard from "../components/InfoCard.vue";
import { format } from "date-fns";
import { mapGetters } from "vuex";
import feathersClient from "../services/feathersClient";
import * as timeago from "timeago.js";
import deleteAttachment from "../components/confirmationModal/DeleteAttachmentModal.vue";
import EditDeleteNote from "../components/confirmationModal/EditDeleteNote.vue";
import InlineEdit from "../components/InlineEdit.vue";
import FileUpload from "../components/FileUpload.vue";
import AddFiles from "../components/Modal/AddFiles.vue";
import objectId from "../helpers/objectId";
import NotificationsBar from "../components/NotificationsBar.vue";
import SetTitle from "../components/SetTitle";
import RichText from "../components/RichText";
import ActionList from "../components/ActionList.vue";
import ScheduleForOpenmic from "../components/Modal/ScheduleForOpenmic.vue";
import ViewOpenMicRequest from "../components/Modal/ViewOpenMicRequest.vue";
import ConfigureDateForOpenMic from "../components/Modal/ConfigureDateForOpenMic.vue";
import OpenmicScheduledMarkAsCompleted from "../components/Modal/OpenmicScheduledMarkAsCompleted.vue";
import Checkbox from "../components/Checkbox.vue";
import RequestAccepted from "../components/Modal/RequestAccepted.vue";
import RequestRejectedreason from "../components/Modal/RequestRejectedreason.vue";
import RequestRejected from "../components/Modal/RequestRejected.vue";
import RequestSentsuccess from "../components/Modal/RequestSentsuccess.vue";
import RequestDetails from "../components/Modal/RequestDetails.vue";
import Comments from "../components/IdeaSections/Comments.vue";
import MyProgressModal from "../components/progressView/MyProgress/MyProgressModal.vue";
import PhaseModal from "../components/progressView/PhaseModal.vue";
import UserCard from "../components/UserCard";
import AvatarGrouping from "../components/AvatarGrouping.vue";
import IconLike from "../components/Icon/Like.vue";
import IconFollow from "../components/Icon/Follow.vue";
import LikeFallowShare from "../components/LikeFallowShare.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import SubmissionAttachment from "../components/SubmissionAttachment.vue";
import AttachmentPopup from "../components/AttachmentPopup.vue";
import BulkInfo from "../components/confirmationModal/BulkInfo.vue";
export default {
  components: {
    Multiselect,
    SubmissionAttachment,
    LoaderView,
    MyTeamManagementModal,
    InfoCard,
    deleteAttachment,
    EditDeleteNote,
    InlineEdit,
    FileUpload,
    AddFiles,
    NotificationsBar,
    SetTitle,
    RichText,
    ChangeProposerModal,
    ActionList,
    ScheduleForOpenmic,
    ViewOpenMicRequest,
    ConfigureDateForOpenMic,
    OpenmicScheduledMarkAsCompleted,
    Checkbox,
    RequestAccepted,
    RequestRejectedreason,
    RequestRejected,
    RequestSentsuccess,
    RequestDetails,
    Comments,
    MyProgressModal,
    PhaseModal,
    UserCard,
    AvatarGrouping,
    IconLike,
    IconFollow,
    LikeFallowShare,
    AttachmentPopup,
    BulkInfo
  },
  data() {
    return {
      isModalVisible: false,
      note: "",
      timeago,
      openClose: false,
      todo: null,
      noteObj: null,
      fileObj: null,
      openCloseNote: false,
      index: null,
      editField: "",
      imageUploading: false,
      cloudinaryImageUrl: null,
      attachments: [],
      openAddFile: false,
      showMessage: false,
      proposer: [],
      // teamMembers: [],
      setTeamMembers: [],
      isChangeProposer: false,
      signUpOpen: false,
      viewOpenmicRequest: false,
      configureDateOpen: false,
      markAsCompletedOpen: false,
      configureOrScheduleEvent: "",
      cancelOrMarkAsCompletedEvent: "",
      requestSentSuccessModal: false,
      mentorOrMember: "",
      openAddTeamModal: false,
      activeItem: "tab1",
      participantsChanged: 0,
      requestDetail: {},
      requestDetailsFlag: false,
      requestRejectedReasonModalFlag: false,
      requestRejectedReson: "",
      requestRejectedflag: false,
      requestAcceptedModalFlag: false,
      eachSingleRequest: {},
      comments: [],
      allMessagesCount: 0,
      openMyProgress: false,
      isProgressModalVisible: false,
      documentData: {},
      openBulkInfoModal:false,
      errorFlag : false,
      judgingMember : {},
      submitInProgress:false,
      label:"",
    };
  },
  watch: {
    selectedInitiative: function (newValue, oldValue) {
      if (newValue !== null) {
        this.getAttachments();
      }
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "selectedInitiative",
      "loadingFlag",
      "marketHorizons",
      "challengeTypes",
      "playbook",
      "bogFrameworks",
      "teamMembers",
      "msGraphAccessToken",
      "allPendingRequestsToAccept",
      "activitiesToUpdate",
      "initiatives",
      "attachmentPopUpFlag",
      "enableSubmission",
      "selectedTeamMembersPhase"
    ]),
    accessToken() {
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      return tokenString;
    },
    isParticipant() {
      let metaData = this.selectedInitiative.metaData;
      let user = this.user;
      let allMembers = [
        ...metaData?.teamLead,
        ...metaData?.teamMembers,
        ...metaData?.mentors,
        ...metaData?.submitter,
      ];
      return (
        allMembers?.filter(
          (f) => f.email?.toLowerCase() === user.email.toLowerCase()
        )?.length !== 0
      );
    },
    editAccess() {
      if (
        (this.selectedInitiative.createdBy === this.user.aspenifyUserId ||
          this.user.roles.includes("manager") ||
          this.isParticipant) &&
        (this.selectedInitiative.status === 0 ||
          this.selectedInitiative.status === 1)
      ) {
        return true;
      }
      return false;
    },
    editInactiveAccess() {
      if (
        (this.selectedInitiative.createdBy === this.user.aspenifyUserId ||
          this.user.roles.includes("manager")) &&
        this.selectedInitiative.status === 6
      ) {
        return true;
      }
      return false;
    },
    currentPageUrl() {
      return (
        window.location.origin +
        "/allideas/details/" +
        this.selectedInitiative.id
      );
    },
    hoverClass() {
      return this.editAccess
        ? "hover:bg-gray-200  rounded  hover:shadow-md -mx-2 px-2 "
        : "";
    },
    hoverClassDropdown() {
      return this.editAccess
        ? "hover:bg-gray-200 rounded hover:shadow-md -mx-2 px-2 "
        : "";
    },
    checkIfUserAlreadyPresent() {
      const selectedInitiativeId = this.selectedInitiative.id;
      var result =
        this.selectedInitiative &&
        this.selectedInitiative.users &&
        this.selectedInitiative.users.filter((user) => {
          return user.email.toLowerCase() === this.user.email.toLowerCase();
        });
      if (result && result.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    checkIfRequestAlreadyPresent() {
      // var result =
      //   this.selectedInitiative &&
      //   this.selectedInitiative.requests &&
      //   this.selectedInitiative.requests.filter((user) => {
      //     return user.accountUserId === this.user.aspenifyUserId;
      //   });
      let result = this.allPendingRequestsToAccept?.filter((user) => {
        return (
          user?.requestFrom?.toString() === this.user.aspenifyUserId?.toString()
        );
      });
      if (result && result.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    checkIfUserIsProposerOrTeamLead() {
      const selectedInitiativeId = this.selectedInitiative.id;
      var result =
        this.selectedInitiative &&
        this.selectedInitiative.users &&
        this.selectedInitiative.users.filter((user) => {
          return (
            (user.role === "Submitter | Team Lead" ||
              user.role === "Submitter" ||
              user.role === "Team Lead") &&
            user.email.toLowerCase() === this.user.email.toLowerCase()
          );
        });
      if (result && result.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isWso() {
      return this.user.roles.includes("manager");
    },
    checkRoleBasedOnCreateInitiativeFlag() {
      if (this.user.roles.includes("manager")) {
        return true;
      }
      if (this.playbook?.createInitiative) {
        return true;
      }
      return false;
    },
    getProblemDescriptionImage() {
      if (this.selectedInitiative && this.selectedInitiative.attachments) {
        const image = this.selectedInitiative.attachments.find((rec) =>
          rec.documentName.endsWith(".problemDescription")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getTestimonialAwardsIpAndOpenSourceImage() {
      if (this.selectedInitiative && this.selectedInitiative.attachments) {
        const image = this.selectedInitiative.attachments.find((rec) =>
          rec.documentName.endsWith(".testimonialAwardsIpAndOpenSource")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getAboutTeamImage() {
      if (this.selectedInitiative && this.selectedInitiative.attachments) {
        const image = this.selectedInitiative.attachments.find((rec) =>
          rec.documentName.endsWith(".aboutTeam")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    getPotentialMarketImage() {
      if (this.selectedInitiative && this.selectedInitiative.attachments) {
        const image = this.selectedInitiative.attachments.find((rec) =>
          rec.documentName.endsWith(".potentialMarket")
        );
        if (image) {
          return image;
        }
      }
      return null;
    },
    userAccess() {
      return this.user && this.user.roles.length===1 && this.user.roles.includes("user");
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
  },
  beforeUpdate() {},
  created: async function () {
    const selectedInitiativeId = this?.$route?.params?.id;
    if (!selectedInitiativeId) {
      this.$router.push({ name: "MyIdeas" });
    } else {
      //load initiative data
      await this.$store.dispatch(
        "getInitiativeDetailsById",
        selectedInitiativeId
      );
      this.checkUserRole();
      this.$store.dispatch("getAllPendingRequests", selectedInitiativeId);
    }
    this.comments = await this.$store.dispatch(
      "getAllComments",
      selectedInitiativeId
    );

  },

  async beforeMount() {
    let checkUserAccesToIdea = false;
    let userEmail = this.user.email;
    if(this.selectedInitiative == null && this.$route.params.id){
      await this.$store.dispatch("getInitiativeDetailsById", this.$route.params.id);
    }
    if(this.selectedInitiative && userEmail){
      if(this.selectedInitiative?.submitter?.length>0 && this.selectedInitiative.submitter.some(e => e.email === userEmail)){
        checkUserAccesToIdea = true;
      }
      if(!checkUserAccesToIdea && this.selectedInitiative?.teamMembers?.length>0 && this.selectedInitiative.teamMembers.some(e => e.email === userEmail)){
        checkUserAccesToIdea = true;
      }
      if(!checkUserAccesToIdea && this.selectedInitiative?.teamLead?.length>0 && this.selectedInitiative.teamLead.some(e => e.email === userEmail)){
        checkUserAccesToIdea = true;
      }
      if(!checkUserAccesToIdea && this.selectedInitiative?.mentors?.length>0 && this.selectedInitiative.mentors.some(e => e.email === userEmail)){
        checkUserAccesToIdea = true;
      }
    }
    if(!checkUserAccesToIdea){
      this.$router.push(`/myideas/details/${this.$route.params.id}`);
    }
    
  },
  mounted: async function(){ 
    await this.$store.dispatch("getInitiativesByPhase");  
  },
  methods: {
    setFilter: function (option) {
      this.isFilter = option;
    },
    backToList: function () {
      this.$router.push({ name: "MyIdeas" });
    },
    formatDate: function (date) {
      return format(new Date(date), "d MMMM, yyyy");
    },
    async manageMyTeam() {
      await this.$store.dispatch("getAccessToken", "");
      this.isModalVisible = true;
    },
    async changeProposer() {
      await this.$store.dispatch("getAccessToken", "");
      this.isChangeProposer = true;
    },
    formattedDate(value) {
      if(value){
        return format(new Date(value), "MM/dd/yyyy");
      }
    },
    formatInput(value) {
      if(!value) return ""
      let converValue=value.replace(/[$,]/g, "")
      if (!isNaN(converValue)) {
        // Format as $USD
        const numberValue = parseFloat(converValue);
        this.formattedValue = numberValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } 
      return this.formattedValue;
    },
    closeModal({ originalMentors, originalTeamMembers, originalTeamLead }) {
      this.selectedInitiative.mentors = originalMentors;
      this.selectedInitiative.teamMembers = originalTeamMembers;
      this.selectedInitiative.teamLead = originalTeamLead;
      this.isModalVisible = false;
    },
    async closeProposarModal(originalProposer) {
      this.proposer = originalProposer;
      await this.$store.dispatch(
        "getInitiativeDetailsById",
        this.selectedInitiative.id
      );
      this.isChangeProposer = false;
    },
    openBulkInfoModel(value,judgingMember) {
      this.editField=null;
      if(judgingMember?.label==="category"){
        this.headerText = `Error`
        this.infoText = `<p>Sorry you cannot change to ${judgingMember?.challengeType}, since team participant ${judgingMember?.member?.name} is part of judging team for this category.</p>`;
        this.footerText = ``;
      }
      if(judgingMember?.label==="judgeWithSameCategory"){
        this.headerText = `Error`
        this.infoText = `<p>Sorry you cannot change to ${judgingMember?.challengeType} as the judge ${judgingMember?.member?.firstName? judgingMember?.member?.firstName : judgingMember?.member?.name} ${judgingMember?.member?.lastName ?judgingMember?.member?.lastName :""} for this submission is a team participant of submission with category ${judgingMember?.challengeType}</p>`;
        this.footerText = ``;
      }
      if(judgingMember?.label==="judge"){
        this.headerText = `Error`
        this.infoText = `<p>Sorry you cannot add ${judgingMember?.member?.name} as a team participant since ${judgingMember?.member?.name} is part of Juding team for ${judgingMember?.challengeType}</p>`;
        this.footerText = ``;
      }
      if(judgingMember?.label==="judgeWithSameTeamMembers"){
        this.headerText = `Error`
        this.infoText = `<p>Sorry you cannot add ${judgingMember?.member?.firstName} ${judgingMember?.member?.lastName} as a team participant as ${judgingMember?.member?.firstName} ${judgingMember?.member?.lastName} is the judge  for this submission</p>`;
        this.footerText = ``;
      }
      this.openBulkInfoModal = value;
    },
    async updateTeam({originalMentors, originalTeamMembers, originalTeamLead}) {
      await this.$store.dispatch('checkSubmissionEnableStatus');
      if(this.enableSubmission){
        let payload = {};
        payload.teamId = this.selectedInitiative.teamId;
        payload.selectedTeamUsersArray = this.selectedInitiative.teamLead
          .concat(this.selectedInitiative.teamMembers)
          .concat(this.selectedInitiative.mentors);
        payload.originalTeamUsersArray = originalTeamLead
          .concat(originalTeamMembers)
          .concat(originalMentors);
        let addedUsers = await this.$store.dispatch(
          "processIdeaParticipants",
          payload
        );
        for (let userData of addedUsers) {
          this.selectedInitiative.teamLead.map((tl) => {
            if (userData.email?.toLowerCase() == tl.email?.toLowerCase()) {
              tl.accountUserId = userData._id;
            }
            delete tl.firstName;
            delete tl.lastName;
            delete tl.accountId;
            delete tl.organizationId;
            delete tl.teamId;
          });
          this.selectedInitiative.teamMembers.map((member) => {
            if (userData.email?.toLowerCase() == member.email?.toLowerCase()) {
              member.accountUserId = userData._id;
            }
            delete member.firstName;
            delete member.lastName;
            delete member.accountId;
            delete member.organizationId;
            delete member.teamId;
          });
          this.selectedInitiative.mentors.map((mentor) => {
            if (userData.email?.toLowerCase() == mentor.email?.toLowerCase()) {
              mentor.accountUserId = userData._id;
            }
            delete mentor.firstName;
            delete mentor.lastName;
            delete mentor.accountId;
            delete mentor.organizationId;
            delete mentor.teamId;
          });
        }
        this.updateValue();
        this.isModalVisible = false;
      } else {
        alert("“Sorry! The submission window has closed and edits are no longer permitted. Thank you!”");
      }
    },
    postNote: async function () {
      let payload = {
        user: this.user,
        initiative: this.selectedInitiative,
        initiativeId: this.selectedInitiative?.id,
        comment: this.note,
        recipients: [],
      };
      await this.$store.dispatch("addComment", payload);
      await this.commentAdded();
      this.note = "";
    },
    commentAdded: async function () {
      this.comments = await this.$store.dispatch(
        "getAllComments",
        this.selectedInitiative?.id
      );
    },
    openCloseModal(value) {
      this.openClose = value;
    },
    openCloseNoteModal(value) {
      this.openCloseNote = value;
    },
    addFilesModal: function (item) {
      this.fileObj = {
        documentName: "",
        documentUrl: "",
        documentId: objectId(),
      };
      this.todo = "add";
      this.openAddFileModal(true);
    },
    editFile: function (item) {
      this.fileObj = { ...item };
      this.todo = "edit";
      this.openAddFileModal(true);
    },
    editNote: function (item) {
      this.noteObj = { ...item };
      this.todo = "edit";
      this.openCloseNoteModal(true);
    },
    deleteNote: function (item) {
      this.noteObj = { ...item };
      this.todo = "delete";
      this.openCloseNoteModal(true);
    },
    editDeleteNotes: function () {
      this.openCloseNoteModal(false);
      this.$store.dispatch("addNote", {
        initiativeId: this.selectedInitiative.id,
        note: this.noteObj,
        todo: this.todo,
      });
    },
    done: async function () {
      this.openCloseModal(false);
      let payload = {};
      const initiativeService = feathersClient.service("initiatives");
      payload.idToUpdate = this.selectedInitiative.id;
      payload.attachments = this.selectedInitiative.attachments;
      payload.index = this.index;
      payload.service = initiativeService;
      await this.$store.dispatch("deleteAttachment", payload);
      //await this.getAttachments();
    },
    downloadAttachment: function (payload) {
      this.documentData = payload;
      this.$store.dispatch("viewAttachment", payload);
    },
    deleteAttachment: async function (index) {
      await this.$store.dispatch('checkSubmissionEnableStatus');
      if(this.enableSubmission){
        console.log("🚀 ~ file: MyIdeaDetails.vue ~ line 2139 ~ deleteAttachment")
        this.openCloseModal(true);
        this.todo = "delete";
        this.index = index;
      } else {
        alert("“Sorry! The submission window has closed and edits are no longer permitted. Thank you!”");
      }
    },
    deleteAttachmentById(id) {
      const imageIndex = this.selectedInitiative.attachments.findIndex(
        (rec) => rec.documentId == id
      );
      if (imageIndex > -1) {
        this.deleteAttachment(imageIndex);
      }
    },
    editMe(name) {
      this.editField = name;
    },
    onAddFiles() {
      if (this.$refs.file.files[0].size < 4000000) {
        this.errorText = "";
        this.imageUploading = true;
        this.uploadFileToCloudinary(this.$refs.file.files[0])
          .then((fileResponse) => {
            this.cloudinaryImageUrl = fileResponse.secure_url;
            this.imageUploading = false;

            this.updateValue(null);
          })
          .catch((err) => {
            this.imageUploading = false;
          });
      } else {
        this.errorText = "Upload Image below than 4 MB";
      }
    },
    uploadFileToCloudinary(file) {
      return new Promise((resolve, reject) => {
        // const CLOUDINARY_URL =  "https://api.cloudinary.com/v1_1/dqj2q4qee/image/upload";
        // const CLOUDINARY_UPLOAD_PRESET = "xptgrebi";

        const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
        const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_PRESET;

        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        //formData.append("folder", "cloudinary-demo");
        formData.append("file", file);

        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }

          // Not successfull, let find our what happened
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files : " + error;
            this.isError = true;
            reject(error);
          }
        };

        request.onerror = (err) => {
          //  alert("error: " + err);
          reject(err);
        };

        request.send(formData);
      });
    },
    async updateValue(data) {

      let filterProposer = this.selectedInitiative.users.filter(
        (f) => f.role === "Submitter" || f.role === "Submitter | Team Lead"
      );
      filterProposer.role = "Submitter";
      this.selectedInitiative.teamMembers.map((member) => {
        member.role = "Team Member";
      });

      this.selectedInitiative.mentors.map((member) => {
        member.role = "Mentor";
      });

      const payload = {
        initiativeId: this.selectedInitiative.id,
        name:
          data && data.label === "name"
            ? data.value
            : this.selectedInitiative.name,
        description:
          data && data.label === "description"
            ? data.value
            : this.selectedInitiative.description,
        picture: this.cloudinaryImageUrl
          ? this.cloudinaryImageUrl
          : this.selectedInitiative.cloudinaryImageUrl,
        tags:
          data && data.label === "tags"
            ? data.value
            : this.selectedInitiative.tags,
        status: this.selectedInitiative.status,
        practiceId: this.selectedInitiative.practiceId,
        attachments: this.selectedInitiative.attachments,
        metaData: {
          challengeType:
            data && data.label === "challengeType"
              ? data.value
              : this.selectedInitiative.challengeType,
          source:
            data && data.label === "source"
              ? data.value
              : this.selectedInitiative.source,
          marketHorizon:
            data && data.label === "marketHorizon"
              ? data.value
              : this.selectedInitiative.marketHorizon,
          aboutTeam:
            data && data.label === "aboutTeam"
              ? data.value
              : this.selectedInitiative.aboutTeam,
          coreTeamSize:
            data && data.label === "coreTeamSize"
              ? data.value
              : this.selectedInitiative.metaData.coreTeamSize,
          fcsDate:
            data && data.label === "fcsDate"
              ? data.value
              : this.selectedInitiative.metaData.fcsDate,
          // activationDate:
          //   data && data.label === "activationDate"
          //     ? data.value
          //     : this.selectedInitiative.metaData.activationDate,
          revenueDate:
            data && data.label === "revenueDate"
              ? data.value
              : this.selectedInitiative.metaData.revenueDate,
          talent:
            data && data.label === "talent"
              ? data.value
              : this.selectedInitiative.talent,
          isTalentRequired:
            data && data.label === "isTalentRequired"
              ? data.value
              : this.selectedInitiative.isTalentRequired,
          progress:
            data && data.label === "progress"
              ? data.value
              : this.selectedInitiative.progress,
          elevatorPitch:
            data && data.label === "elevatorPitch"
              ? data.value
              : this.selectedInitiative.elevatorPitch,
          problemDescription:
            data && data.label === "problemDescription"
              ? data.value
              : this.selectedInitiative.problemDescription,
          potentialMarket:
            data && data.label === "potentialMarket"
              ? data.value
              : this.selectedInitiative.potentialMarket,
          testimonialAwardsIpAndOpenSource:
            data && data.label === "testimonialAwardsIpAndOpenSource"
              ? data.value
              : this.selectedInitiative.testimonialAwardsIpAndOpenSource,

          mentors: this.selectedInitiative.mentors,
          teamLead: this.selectedInitiative.teamLead,
          submitter: this.selectedInitiative?.metaData?.submitter,
          execSponsor: this.selectedInitiative?.metaData?.execSponsor
        },
      };

      payload.metaData.teamMembers = [
        // ...filterProposer,
        ...this.selectedInitiative.teamMembers,
      ];

      payload.metaData.signUpForOpenmic =
        this.selectedInitiative.metaData.signUpForOpenmic;
      payload.metaData.registerArchitecture =
        this.selectedInitiative.metaData.registerArchitecture;
      payload.metaData.registerBuildingBlock =
        this.selectedInitiative.metaData.registerBuildingBlock;
      payload.metaData.registerExtendedStaff =
        this.selectedInitiative.metaData.registerExtendedStaff;
      payload.metaData.managementFields =
        this.selectedInitiative.metaData.managementFields;

      this.errorFlag = false;
      this.judgingMember={};
      if(data && data.label && (data.label === "name" || data.label === "description" 
      || data.label === "priority" || data.label === "tags" || data.label === "source" || 
      data.label === "marketHorizon" || data.label === "bogFramework" || data.label === "aboutTeam" || 
      data.label === "coreTeamSize" || data.label === "fcsDate" || data.label === "revenueDate" || data.label === "testimonialAwardsIpAndOpenSource" ||
      data.label === "talent" || data.label === "isTalentRequired" || data.label === "progress" || data.label === "elevatorPitch" ||
      data.label === "problemDescription" || data.label === "potentialMarket")){
        this.submitInProgress = true;
        this.label = data.label;
        this.selectedInitiative[data.label] = data.value;
        await this.$store.dispatch("updateInitiative", payload);
          this.editField = null;
        await this.$store.dispatch(
          "getInitiativeDetailsById",
          this.selectedInitiative.id
        );
        this.submitInProgress = false;
        this.label = ""
        return;
      }
      const judges=[];
      let initiative = this.initiatives.find((item)=>item._id === this.selectedInitiative.id);
      await this.checkJudges(initiative,payload); 
      this.initiatives.forEach(initiative=>{
        if(initiative?.seletedPhaseJudgesDetails){
          if(initiative._id === this.selectedInitiative.id){
          initiative.seletedPhaseJudgesDetails.members.forEach((member)=>{
            judges.push(member)
          })
         }
        }
        })
        judges.forEach((judge)=>{
          this.initiatives.forEach((initiative)=>{
            initiative.metaData.teamLead.forEach((tl)=>{
              if(judge.accountUserId._id === tl.accountUserId && initiative.metaData.challengeType === payload?.metaData?.challengeType){
                  this.judgingMember.member=tl
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.judgingMember.label= data?.label ==="challengeType" ?"judgeWithSameCategory" : "judge"
                  this.errorFlag = true;   
                }
            })
            initiative.metaData.teamMembers.forEach((tl)=>{
              if(judge.accountUserId._id === tl.accountUserId && initiative.metaData.challengeType === payload?.metaData?.challengeType){
                  this.judgingMember.member=tl
                  this.judgingMember.label= data?.label ==="challengeType"?"judgeWithSameCategory":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;   
                }
            })
            initiative.metaData.submitter.forEach((tl)=>{
              if(judge.accountUserId.email === tl.email && initiative.metaData.challengeType === payload?.metaData?.challengeType){
                  this.judgingMember.member=tl
                  this.judgingMember.label= data?.label ==="challengeType"?"judgeWithSameCategory":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;   
                }
            })
          })
        })
          this.initiatives.forEach((initiative)=>{
            if(initiative?.seletedPhaseJudgesDetails){
              if(initiative._id === this.selectedInitiative.id){
                initiative?.seletedPhaseJudgesDetails?.members?.forEach(member=>{
                  if(payload?.metaData?.teamLead?.length>0){
                payload?.metaData?.teamLead.forEach((tl)=>{
                if(member.accountUserId._id === tl.accountUserId){
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.member=tl
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
              }
              if(payload?.metaData?.teamMembers?.length>0){
              payload?.metaData?.teamMembers?.forEach((tm)=>{
                if(member.accountUserId._id === tm.accountUserId){
                  this.judgingMember.member=tm
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
              }
              if(payload?.metaData?.submitter?.length>0){
              payload?.metaData?.submitter?.forEach((tm)=>{
                if(member.accountUserId.email === tm.email && payload?.metaData?.challengeType === initiative?.metaData?.challengeType){
                  this.judgingMember.member=tm
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
            }            
                })  
              }
              initiative?.seletedPhaseJudgesDetails?.members?.forEach(member=>{
              if(payload?.metaData?.teamLead?.length>0){
                payload?.metaData?.teamLead.forEach((tl)=>{
                if(member.accountUserId._id === tl.accountUserId && payload?.metaData?.challengeType === initiative?.metaData?.challengeType){
                  this.judgingMember.member=tl
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
              }
              if(payload?.metaData?.teamMembers?.length>0){
              payload?.metaData?.teamMembers?.forEach((tm)=>{
                if(member.accountUserId._id === tm.accountUserId && payload?.metaData?.challengeType === initiative?.metaData?.challengeType){
                  this.judgingMember.member=tm
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
              }
              if(payload?.metaData?.submitter?.length>0){
              payload?.metaData?.submitter?.forEach((tm)=>{
                if(member.accountUserId.email === tm.email && payload?.metaData?.challengeType === initiative?.metaData?.challengeType){
                  this.judgingMember.member=tm
                  this.judgingMember.label=data?.label ==="challengeType"?"category":"judge"
                  this.judgingMember.challengeType = initiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
            }
          })
         }
        })
        if(!this.errorFlag){
        await this.$store.dispatch("updateInitiative", payload);
          this.editField = null;
        await this.$store.dispatch("getInitiativeDetailsById",this.selectedInitiative.id);
        this.errorFlag=false;
        } else {
          await this.$store.dispatch("getInitiativeDetailsById",this.selectedInitiative.id);
          this.openBulkInfoModel(true,this.judgingMember)
        }
      this.checkUserRole();
    },
    async checkJudges(initiative,payload){
      let filteredCategoryArray = [];
      let filteredMemberArray = [];
      if(initiative?.nextPhaseInfo && initiative?.nextPhaseInfo?.metaData && initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
        await this.$store.dispatch("getTeamMembers",initiative);
        let teamPayload = {
          teamId: initiative.nextPhaseInfo.metaData.selectedJudgesTeamId,
          mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
        } 
        await this.$store.dispatch("getTeamMembersById", teamPayload);
        const members = [];
        this.initiatives?.forEach((item)=>{
            if(item?.metaData?.challengeType === payload?.metaData?.challengeType){
              item?.metaData?.teamLead?.forEach((lead)=>{members.push(lead.email)})
              item?.metaData?.teamMembers?.forEach((member)=>{members.push(member.email)})
              item?.metaData?.submitter?.forEach((submitter)=>{members.push(submitter.email)})
            }
          })
          filteredCategoryArray = this.selectedTeamMembersPhase.filter(judge => members.includes(judge.email))
           if(filteredCategoryArray.length>0){
            this.judgingMember.member=filteredCategoryArray[0];
            this.judgingMember.challengeType = payload?.metaData?.challengeType
            this.judgingMember.label="judgeWithSameCategory"
            this.errorFlag = true;
           }
            this.initiatives?.forEach((item)=>{
              if(item?._id === payload.initiativeId){
              payload?.metaData?.teamLead?.forEach((lead)=>{members.push(lead.email)})
              payload?.metaData?.teamMembers?.forEach((member)=>{members.push(member.email)})
            }
          })
          filteredMemberArray = this.selectedTeamMembersPhase.filter(judge => members.includes(judge.email))
          if(filteredMemberArray.length>0 && filteredCategoryArray.length === 0){  
            this.judgingMember.member=filteredMemberArray[0];
            this.judgingMember.challengeType = payload?.metaData?.challengeType
            this.judgingMember.label="judgeWithSameTeamMembers"
            this.errorFlag = true;
          }    
        }
      },
    fileUploaded: async function (uploadedFiles) {
      const initiativeService = feathersClient.service("initiatives");
      let accountUserId = this.user.aspenifyUserId;
      const { name } = this.user;
      let token = `Bearer ${this.user.accessToken}`;
      try {
        let initiativeLog = {
          accountUserId: accountUserId,
          commentDate: new Date(),
        };
        let action;
        if (this.todo === "edit") {
          action = "edited";
        } else if (this.todo === "delete") {
          action = "deleted";
        } else {
          action = "added";
        }
        initiativeLog.comment = `${name} has ${action} an attachment.`;
        const initiativeDetailsData = await initiativeService.get(
          this.selectedInitiative.id,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        initiativeDetailsData.initativeLogs.unshift(initiativeLog);
        await initiativeService.patch(
          this.selectedInitiative.id,
          {
            $set: {
              attachments: uploadedFiles,
            },
            initativeLogs: initiativeDetailsData.initativeLogs,
            string: "attachmentUploaded",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        await this.getAttachments();
      } catch (err) {
        console.log(err);
      }
    },
    getAttachments: async function () {
      const initiativeService = feathersClient.service("initiatives");

      const query = {
        $select: ["attachments", "initativeLogs"],
        $populate: [
          {
            path: "attachments.uploadedBy",
            select: "firstName lastName profilePic _id",
          },
        ],
      };
      let token = `Bearer ${this.user.accessToken}`;
      let initiative = await initiativeService.get(
        this.selectedInitiative?.id,
        {
          query: query,
          headers: {
            Authorization: token,
          },
        }
      );
      this.selectedInitiative.attachments = initiative?.attachments;
      this.selectedInitiative.initativeLogs = initiative.initativeLogs;
    },
    openAddFileModal(flag) {
      this.openAddFile = flag;
    },
    addFiles(file) {
      this.openAddFileModal(false);
    },
    copyUrl() {
      let copySelector = document.querySelector("#copy-current-page-url");
      copySelector.setAttribute("type", "text");
      copySelector.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 3000);
      } catch (err) {
        alert("Oops, unable to copy");
      }
      /* unselect the range */
      copySelector.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async checkUserRole() {
      let filterProposer = {};
      filterProposer = this.selectedInitiative?.users?.filter(
        (f) => f.role === "Submitter" || f.role === "Submitter | Team Lead"
      );
      filterProposer.role = "Submitter";
      this.proposer = filterProposer;

      let filterUsers = this.selectedInitiative?.users?.filter(
        (f) => f.role !== "Submitter"
      );
      filterUsers = filterUsers?.map((user) =>
        user.role === "Submitter | Team Lead" &&
        !this.user.roles.includes("manager")
          ? { ...user, role: "Team Lead" }
          : user
      );
      this.setTeamMembers = filterUsers;
    },
    deletePropopser(originalProposer) {
      this.proposer = [];
    },
    async updateProposer({proposer,originalProposer}) {
      // let removeProposerFromTeamMembers =
      //   this.selectedInitiative?.users?.filter((teamMembers) => {
      //     return teamMembers.role === "Team Member";
      //   });
      let newSubmitter = [];
      let subPayload = {};
        subPayload.teamId = this.selectedInitiative.teamId;
        subPayload.selectedTeamUsersArray = proposer
        subPayload.originalTeamUsersArray = originalProposer
        let addedUsers = await this.$store.dispatch(
          "processIdeaParticipants",
          subPayload
        );
        for (let userData of addedUsers) {
          proposer.map((tl) => {
            if (userData.email?.toLowerCase() == tl.email?.toLowerCase()) {
              tl.accountUserId = userData._id;
            }
            delete tl.firstName;
            delete tl.lastName;
            delete tl.accountId;
            delete tl.organizationId;
            delete tl.teamId;
          });
        }
      let proposerDetails = {
        name: proposer[0].name,
        image: proposer[0].image,
        email: proposer[0].email?.toLowerCase(),
        accountUserId:proposer[0].accountUserId
        // role: proposer.role,
      };
      newSubmitter.push(proposerDetails);
      // removeProposerFromTeamMembers.unshift(proposerDetails);
      // return false;
      const initiativeService = feathersClient.service("initiatives");
      let token = `Bearer ${this.user.accessToken}`;
      let payload = {};
      payload.eventData = {
        type: "changeProposer",
        ideaName: this.selectedInitiative?.name,
        proposerName: proposer[0].name,
      };
      this.errorFlag = false;
      this.judgingMember={};
      let selectInitiative = this.initiatives.find((item)=>item._id === this.selectedInitiative.id);
      if(selectInitiative?.nextPhaseInfo && selectInitiative?.nextPhaseInfo?.metaData && selectInitiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
        await this.$store.dispatch("getTeamMembers",selectInitiative);
        let teamPayload = {
          teamId: selectInitiative.nextPhaseInfo.metaData.selectedJudgesTeamId,
          mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
        }
        await this.$store.dispatch("getTeamMembersById", teamPayload);
        let selectedJudgeSubmittor = this.selectedTeamMembersPhase.filter(judge => judge.email === newSubmitter?.[0]?.email)
        if(selectedJudgeSubmittor.length>0){
          this.judgingMember.member=selectedJudgeSubmittor[0]
          this.judgingMember.label = "judgeWithSameTeamMembers"
          this.judgingMember.challengeType = this.selectedInitiative?.metaData?.challengeType
          this.errorFlag = true;
      }
      }  
          this.initiatives.forEach((initiative)=>{
            if(initiative?.seletedPhaseJudgesDetails){
              if(initiative._id === this.selectedInitiative.id){
                initiative?.seletedPhaseJudgesDetails?.members?.forEach(member=>{
                  if(newSubmitter.length>0){
                    newSubmitter?.forEach((ns)=>{
                      if(member.accountUserId.email === ns.email){
                      this.judgingMember.member=ns
                      this.judgingMember.label="judge"
                      this.judgingMember.challengeType = this.selectedInitiative?.metaData?.challengeType
                      this.errorFlag = true;
                      }     
                    })    
                  }      
                })  
              }
              initiative?.seletedPhaseJudgesDetails?.members?.forEach(member=>{
            if(newSubmitter.length > 0){
              newSubmitter?.forEach((ns)=>{
                if(member.accountUserId.email === ns.email && initiative?.metaData?.challengeType === this.selectedInitiative?.metaData?.challengeType){
                  this.judgingMember.member=ns
                  this.judgingMember.label="judge"
                  this.judgingMember.challengeType = this.selectedInitiative?.metaData?.challengeType
                  this.errorFlag = true;
                }  
              })
            }
        })
        }
        })
      try {
        if(!this.errorFlag){
        let result = await initiativeService.patch(
          this.selectedInitiative.id,

          { $set: { "metaData.submitter": newSubmitter }, payload },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        await this.$store.dispatch(
          "getInitiativeDetailsById",
          this.selectedInitiative.id
        );
        this.errorFlag=false;
        } else {
          this.openBulkInfoModel(true,this.judgingMember)
        }
        await this.checkUserRole();
        this.isChangeProposer = false;
      } catch (err) {
        console.log(err);
      }
    },
    openSignUp(event) {
      this.actionType = event;
      this.signUpOpen = true;
    },
    closeSignUp() {
      this.signUpOpen = false;
    },

    openRequestModal(type) {
      this.actionType = type;
      this.viewOpenmicRequest = true;
    },
    closeRequestModal() {
      this.viewOpenmicRequest = false;
    },
    openConfigureDateModal(type, schedule) {
      this.configureOrScheduleEvent = schedule;
      this.actionType = type;
      this.configureDateOpen = true;
    },
    closeConfigureDateModal() {
      this.configureDateOpen = false;
    },
    openMarkAsCompletedModal(event, type) {
      this.cancelOrMarkAsCompletedEvent = type;
      this.actionType = event;
      this.markAsCompletedOpen = true;
    },

    closeMarkAsCompletedModal() {
      this.markAsCompletedOpen = false;
    },
    submitComment(comment) {
      let userDetailWithComment = {
        comment: comment,
        date: new Date(),
        commentBy: {
          name: this.user.name,
          email: this.user.email?.toLowerCase(),
          profilePic: this.user.profilePic,
          role: this.user.role,
        },
      };

      let mData = this.selectedInitiative?.metaData;
      if (this.actionType === "signUpOpenMic") {
        if (!mData?.signUpForOpenmic) {
          mData.signUpForOpenmic = [];
        }
        mData.signUpForOpenmic.push({
          userComment: userDetailWithComment,
        });
      }

      if (this.actionType === "registerArchitecture") {
        if (!mData?.registerArchitecture) {
          mData.registerArchitecture = [];
        }
        mData.registerArchitecture.push({
          userComment: userDetailWithComment,
        });
      }
      if (this.actionType === "registerBuildingBlock") {
        if (!mData?.registerBuildingBlock) {
          mData.registerBuildingBlock = [];
        }
        mData.registerBuildingBlock.push({
          userComment: userDetailWithComment,
        });
      }
      if (this.actionType === "registerExtendedStaff") {
        if (!mData?.registerExtendedStaff) {
          mData.registerExtendedStaff = [];
        }
        mData.registerExtendedStaff.push({
          userComment: userDetailWithComment,
        });
      }

      let pay = this.selectedInitiative;
      pay._id = this.selectedInitiative.id;
      pay.id = this.selectedInitiative.id;
      pay.metaData = mData;
      pay.user = this.user;
      pay.event = this.actionType;
      pay.actionType = this.actionType;
      this.$store.dispatch("openmicAction", pay);
      this.signUpOpen = false;
    },
    submitConfigureSchedule(date, from, to) {
      let confg = {
        scheduledOn: {
          date: date,
          from: from,
          to: to,
        },
        date: new Date(),
        scheduledBy: {
          name: this.user.name,
          email: this.user.email?.toLowerCase(),
          profilePic: this.user.profilePic,
          role: this.user.role,
        },
      };

      let mData = this.selectedInitiative.metaData;
      let length;
      if (this.actionType === "configureSignUp") {
        length = mData.signUpForOpenmic?.length - 1;
        mData.signUpForOpenmic[length].configure = confg;
      }
      if (this.actionType === "configureArchitecture") {
        length = mData.registerArchitecture?.length - 1;
        mData.registerArchitecture[length].configure = confg;
      }

      if (this.actionType === "configureBuildingBlock") {
        length = mData.registerBuildingBlock?.length - 1;
        mData.registerBuildingBlock[length].configure = confg;
      }

      if (this.actionType === "configureStaff") {
        length = mData.registerExtendedStaff?.length - 1;
        mData.registerExtendedStaff[length].configure = confg;
      }

      let pay = this.selectedInitiative;
      pay._id = this.selectedInitiative.id;
      pay.id = this.selectedInitiative.id;
      pay.metaData = mData;
      pay.user = this.user;
      pay.event = this.configureOrScheduleEvent;
      pay.actionType = this.actionType;
      this.$store.dispatch("openmicAction", pay);
      this.configureDateOpen = false;
    },
    submitMarkAsCompletedModal(comment) {
      let markComplete = {
        comment: comment,
        date: new Date(),
        commentBy: {
          name: this.user.name,
          email: this.user.email?.toLowerCase(),
          profilePic: this.user.profilePic,
          role: this.user.role,
        },
      };

      let mData = this.selectedInitiative.metaData;
      // let l = mData.signUpForOpenmic?.length - 1;
      // mData.signUpForOpenmic[l].markComplete = markComplete;

      let length;
      if (
        this.actionType === "signUpCompleted" ||
        this.actionType === "signUpCancelled"
      ) {
        length = mData.signUpForOpenmic?.length - 1;
        mData.signUpForOpenmic[length].markComplete = markComplete;
      }
      if (
        this.actionType === "ArchitectureComplete" ||
        this.actionType === "ArchitectureCancelled"
      ) {
        length = mData.registerArchitecture?.length - 1;
        mData.registerArchitecture[length].markComplete = markComplete;
      }
      if (
        this.actionType === "BuildingBlockComplete" ||
        this.actionType === "BuildingBlockCancelled"
      ) {
        length = mData.registerBuildingBlock?.length - 1;
        mData.registerBuildingBlock[length].markComplete = markComplete;
      }
      if (
        this.actionType === "StaffComplete" ||
        this.actionType === "StaffCancelled"
      ) {
        length = mData.registerExtendedStaff?.length - 1;
        mData.registerExtendedStaff[length].markComplete = markComplete;
      }

      let pay = this.selectedInitiative;
      pay._id = this.selectedInitiative.id;
      pay.id = this.selectedInitiative.id;
      pay.metaData = mData;
      pay.user = this.user;
      pay.event = this.cancelOrMarkAsCompletedEvent;
      pay.actionType = this.actionType;
      this.$store.dispatch("openmicAction", pay);
      this.markAsCompletedOpen = false;
    },
    onChangeCheckbox(event) {
      this.updateValue({
        label: "isTalentRequired",
        value: event.target.checked,
      });
    },
    openAddTeamModalfunction(flag, value, message) {
      if (message === "sentSuccessfully") {
        this.requestSentSuccessModal = true;
      }
      this.mentorOrMember = value;
      this.openAddTeamModal = flag;
    },
    closerequestsentsuccessmodal(flag) {
      this.requestSentSuccessModal = flag;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    openDetailsPgae(flag, value) {
      if (value) {
        this.requestDetail = value;
      }
      this.requestDetailsFlag = flag;
    },
    async acceptRequest(flag, value, request) {
      if (value === "acceptrequest") {
        let payload = {
          role: request.role,
          initiative: this.selectedInitiative,
          loggedinUser: this.user,
          request: request,
        };
        await this.$store.dispatch("acceptRequest", payload);
        await this.$store.dispatch(
          "getInitiativeDetailsById",
          this.selectedInitiative.id
        );
        await this.$store.dispatch(
          "getAllPendingRequests",
          this.selectedInitiative.id
        );
      }
      this.checkUserRole();
      await this.$store.dispatch(
        "getInitiativeDetailsById",
        this.selectedInitiative.id
      );

      this.requestAcceptedModalFlag = flag;
    },
    requestRejected(flag, request) {
      this.eachSingleRequest = request;
      this.requestRejectedReasonModalFlag = flag;
    },
    async openRejectedRequestModal(flag, value, request) {
      if (request) {
        let payload = {
          senderName: this.user.name,
          senderEmail: this.user.email?.toLowerCase(),
          role: this.mentorOrMember,
          initiativeId: this.selectedInitiative.id,
          reasontoreject: value,
          image: this.user.profilePic,
          email: this.user.email?.toLowerCase(),
          ideaName: this.selectedInitiative.name,
          request: request,
          selectedInitiative: this.selectedInitiative,
          loggedinUser: this.user,
        };
        await this.$store.dispatch("rejectRequest", payload);
        await this.$store.dispatch(
          "getAllPendingRequests",
          this.selectedInitiative.id
        );
        this.requestRejectedReasonModalFlag = false;
      }
      if (value) {
        this.requestRejectedReson = value;
      }
      this.requestRejectedflag = flag;
    },
    changeTodo() {
      this.todo = "add";
    },
    commentsCount(allComments) {
      this.allMessagesCount = allComments?.length;
    },
    viewMyProgress() {
      this.$store.dispatch("getInitiativeLogs", this.selectedInitiative?.id);
      this.$store.dispatch("getInitiativeById", this.selectedInitiative?.id);
      this.$store.dispatch("getInitiativesByPhase");
      this.openMyProgress = true;
    },
    closeMyProgress() {
      this.$store.dispatch(
        "getInitiativeDetailsById",
        this.selectedInitiative?.id
      );
      this.openMyProgress = false;
    },
    async viewProgress() {
      this.$store.dispatch(
        "getInitiativeLogs",
        this.selectedInitiative?.id
      );
      this.$store.dispatch(
        "getInitiativeById",
        this.selectedInitiative?.id
      );
      this.$store.dispatch("getInitiativesByPhase");
      this.isProgressModalVisible = true;
    },
    async closeProgressModal() {
      // await this.$store.dispatch("updateLogs");
      await this.$store.dispatch(
        "getInitiativeDetailsById",
        this.selectedInitiative?.id
      );
      this.isProgressModalVisible = false;
    },
    async completeActivity(activity) {
      await this.$store.dispatch("updateActivity", activity);
      this.$store.dispatch("getInitiativeEvents", activity.id);
    },
    goToUrl(item) {
      let redirectUrl =
        item.documentUrl.indexOf("://") === -1
          ? "http://" + item.documentUrl
          : item.documentUrl;
      window.open(redirectUrl, "_blank");
    },
    goToProfile(user) {
      var name = user?.email
        ?.toLowerCase()
        ?.toLowerCase()
        ?.substring(0, user?.email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?._id, userName: name },
      });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.multi-tag {
  background: #ffffff;
  border-radius: 5px;
}
.word-wrap {
  overflow-wrap: break-word; 
  word-wrap: break-word;
}
.fa-spin {
  animation: spin 0.5s linear infinite;
  font-size:1rem  !important;
  color: #827f7f !important;
  margin-top:5px;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
