const mentors = [
  {
    name: "Sachin Sawant",
    email: "sachin@aspenify.com",
    image:
      "https://avatars.githubusercontent.com/u/11733994?s=400&u=e6b6edacde9c76844785e21d0568a4ba7c7f9aa4&v=4",
  },
  {
    name: "Prasad Gore",
    email: "prasad@aspenify.com",
    image:
      "https://avatars.githubusercontent.com/u/603846?s=400&u=655ceb95d4ba66d327fcce5252ef44d5e7f8a13f&v=4",
  },
  {
    name: "Yogini Upadhye",
    email: "yogini@aspenify.com",
    image:
      "https://avatars.githubusercontent.com/u/62683734?s=400&u=93c0e4fd72c8775af567c2729eaf294cd2623e90&v=4",
  }
]
export default mentors;