<template>
    <div v-if="openCloseEditNote" class="modal-backdrop z-[100]">
     <div class="modal">
      <section class="modal-body">
       <slot name="body">
        <div :class="{'w-3/5': openPanelFlag, 'w-full': !openPanelFlag}" class=" 
          z-20  
          h-screen         
          fixed
          top-8
          flex
          justify-center
          items-center
          g-opacity-50">
          <div :class="{'ml-60': openPanelFlag, 'w-4/5': openPanelFlag, 'w-10/12 md:w-2/4': !openPanelFlag}" class="bg-white z-99 rounded shadow-lg">
          <div class="
               border-b
               p-5
               flex
               justify-between
               items-center
               rounded-t
             ">
           <h3 class="color font-medium text-white text-lg">
            Edit Note  
            <!-- {{documentData.documentName.substring(0,documentData.documentName.lastIndexOf("."))}} -->
           </h3>
           <button class="
                 backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-2
                 rounded-full
                 focus:outline-none
               " @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
             <line x1="18" y1="6" x2="6" y2="18"></line>
             <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
           </button>
          </div>
          <div class="modal-body z-20 mt-3 mb-3 ml-2 mr-2">
            <AppTextEditor 
              :content="note"
              @update-content="updateContent"
              maxLimit = "2000"
            />
          </div>
          <!-- <div class="
               w-full
               text-right
               float-right
               items-center
               justify-between
               p-5
               bg-white
               rounded-bl-lg rounded-br-lg
               border-t
             ">
           <button type="button" @click="close()" class="
             footerButton mr-4
               "> Cancel </button>
           <button  @click="editNote()" class="
           disabled:opacity-50 primaryButton
               "> Update </button>
          </div> -->
         </div>
        </div>
       </slot>
      </section>
     </div>
    </div>
   </template>
  
  <script>
 import AppTextEditor from "../AppTextEditor.vue" 
 import { mapGetters } from 'vuex';
  export default {
    name: "ConfirmationDialog",
    data() {
      return {
        maxLimitCount: 2000,
        RemainCountTotal: 2000,
        displayError: false,
        value:"",
        updatedValue:'',
      };
    },
    computed: {
        ...mapGetters(["user" ]),
    },
    props: {
      openCloseEditNote: Boolean,
      note: String,
      index: Number,
      openPanelFlag: Boolean,
    },
    components: {
        AppTextEditor,
    },
    methods: {
      updateContent(updatedValue,label){ 
        this.value=updatedValue
        this.updatedValue=updatedValue
    },
      editNote: async function(){
       if(this.value.length==0){
        this.value=this.note
       }
        
      let payload = {
        user: this.user,
        index:this.index, 
        data: this.value,
        }
      
     this.$store.dispatch("editNote", payload);
      // await this.$store.dispatch("updateProfile", query);
      this.$emit('setOpenCloseEditNote', false);
    },
      close: function(){
        this.editNote();
        this.$emit('setOpenCloseEditNote', false);
      },
      displayLiveCountDown: function() {
        this.RemainCountTotal = this.maxLimitCount - this.note.length;
        this.displayError = this.RemainCountTotal < 0;
      },
    },
  };
  </script>
  <style scoped>
  .modal-backdrop {
      /* position: fixed; */
      /* top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center; */
    }
  
    .modal {
      background: #FFFFFF;
      box-shadow: 2px 2px 20px 1px;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      left: -20px
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: #4AAE9B;
      justify-content: space-between;
    }
  
    .modal-footer {
      border-top: 1px solid #eeeeee;
      flex-direction: column;
      justify-content: flex-end;
    }
  
    .modal-body {
      position: relative;
      padding: 20px 10px;
    }
  
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4AAE9B;
      background: transparent;
    }
  
    .btn-green {
      color: white;
      background: #4AAE9B;
      border: 1px solid #4AAE9B;
      border-radius: 2px;
    }
    .footerButton {
      font-size: 12px;
      font-style: normal;
      border-width: 1px;
      border-color: #4CB0D6;
      color: #4CB0D6;
      border-radius: 100px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 8px;
      height: 32px;
      width: 100px
    }
    .footerButton:hover {
      background-color: white;
      color: #4CB0D6;
    }
    .backgroundColor{
      background-color: #4CB0D6;
    }
    .color{
      color: #08526D;
    }

    .primaryButton{
    font-size: 12px;
    font-style: normal;
    border-width: 1px;
    border-color: #4CB0D6;
    color:white;
    border-radius: 100px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 8px;
    height: 32px;
    width: 100px;
    background-color:#4CB0D6;
  }
  </style>
  