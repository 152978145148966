<template>
      <svg class="w-4 h-4 mr-3 fill-current" viewBox="0 0 366.569 366.569">
        <g>
          <g id="Layer_5_75_">
            <path
              d="M346.417,35.742c-12.994-12.995-30.298-20.151-48.724-20.151s-35.729,7.156-48.724,20.151L66.397,218.313l-1.625,1.661
			l0.098,0.093c-7.043,8.332-10.898,18.838-10.898,29.828c0,12.355,4.811,23.97,13.546,32.703
			c8.723,8.722,20.337,13.523,32.704,13.523s23.981-4.802,32.703-13.523L252.72,162.47c0,0,5.352-5.211,0.986-9.999
			c-1.594-1.749-4.898-5.198-6.856-6.527c-5.116-3.473-9.575,1.082-9.575,1.082L117.481,267.154
			c-4.6,4.601-10.728,7.134-17.255,7.134c-6.529,0-12.659-2.534-17.261-7.134c-4.61-4.609-7.149-10.739-7.149-17.259
			c0-6.503,2.526-12.62,7.111-17.22L264.413,51.186c8.874-8.872,20.693-13.759,33.279-13.759s24.406,4.887,33.28,13.759
			c8.875,8.875,13.761,20.693,13.761,33.28c0,12.586-4.887,24.404-13.761,33.279l-182.6,182.549l-8.598,8.65
			c-13.023,13.026-30.37,20.199-48.843,20.199c-18.475,0-35.825-7.173-48.855-20.199c-13.047-13.051-20.233-30.401-20.233-48.852
			c0-18.453,7.185-35.8,20.232-48.848l106.052-106.75c0,0,4.446-4.1,0.508-8.414c-2.237-2.449-5.99-6.416-8.244-8.272
			c-3.913-3.223-7.803,1.337-7.803,1.337L26.632,195.801C9.458,212.969,0,235.803,0,260.093c0,24.288,9.458,47.122,26.632,64.296
			c17.146,17.146,39.979,26.589,64.293,26.589c24.313,0,47.147-9.443,64.294-26.589l191.198-191.198
			c12.995-12.994,20.152-30.299,20.152-48.724C366.569,66.041,359.412,48.736,346.417,35.742z"
            />
          </g>
        </g>
      </svg>
</template>