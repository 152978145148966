<template>
  <div
    class="flex justify-center items-center h-screen bg-gray-200 px-6 login bg-cover"
  >
  </div>
  <loader-view :loaderFlag="true" />
</template>

<script>
import { defineComponent } from "vue";
import { $auth0 } from "../services/auth0.js";
import LoaderView from '../components/progressView/Activities/LoaderView.vue'

export default defineComponent({
  setup() {
    let returnUrl = null;
      if (window.location.search) {
        const params = window.location.search
          .slice(1)
          .split("&")
          .reduce((acc, s) => {
            const [k, v] = s.split("=");
            return Object.assign(acc, { [k]: v });
          }, {});
        returnUrl = params.returnUrl;
      }

      let redirecturl = "";
      if (returnUrl) {
        redirecturl = window.location.pathname + returnUrl.substring(1);
      } else {
        redirecturl = window.location.pathname;
      }

      $auth0.client.loginWithRedirect({
        appState: { targetUrl: redirecturl },
        connection: process.env.VUE_APP_AUTH0_CONNECTION_NAME
      });
    return {};
  },
  components:{
    LoaderView
  }
});
</script>
<style scoped>
.login {
  /* background: url("~@/assets/images/target2.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  background: rgb(130,96,192);
background: linear-gradient(90deg, rgba(130,96,192,1) 0%, rgba(109,120,182,1) 26%, rgba(95,193,144,1) 100%);
}
</style>