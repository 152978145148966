


<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M5.722,3.066.621-3.055a1.379,1.379,0,0,1,0-1.766l5.1-6.121a1.379,1.379,0,0,1,1.942-.177,1.379,1.379,0,0,1,.177.177l5.1,6.121a1.379,1.379,0,0,1,0,1.766l-5.1,6.121a1.379,1.379,0,0,1-2.119,0Z"
          transform="translate(-0.302 11.438)"
          fill="rgba(0,0,0,0)"
          clip-rule="evenodd"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_2"
          data-name="Path 2"
          d="M-801,742.559H1185.032V-347H-801Z"
          transform="translate(801 347)"
          fill="rgba(0,0,0,0)"
        />
      </clipPath>
      <clipPath id="clip-Artboard_1">
        <rect width="15" height="15" />
      </clipPath>
    </defs>
    <g
      id="Artboard_1"
      data-name="Artboard – 1"
      clip-path="url(#clip-Artboard_1)"
    >
      <g id="Group_5" data-name="Group 5" transform="translate(0.698 11.438)">
        <g
          id="Group_4"
          data-name="Group 4"
          transform="translate(0.302 -11.438)"
          clip-path="url(#clip-path)"
        >
          <g
            id="Group_3"
            data-name="Group 3"
            transform="translate(-1105.146 -462.803)"
            clip-path="url(#clip-path-2)"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M-4.7-16.438H22.053V12.354H-4.7Z"
              transform="translate(1102.948 472.345)"
              fill="#4a494a"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-milestone",
  props: {},
};
</script>
