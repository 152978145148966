
<template>
  <div style="width:350px" class="mb-4 fixed right-4 top-4 z-100" v-if="header || text">
    <div
      v-if="displayToaster"
      class="
        flex
        max-w-sm
        w-full
        bg-white
        shadow-md
        rounded-lg
        overflow-hidden
        mx-auto
      "
    >
      
      <div v-if="type === 'success'" class="w-2 bg-green-600"></div>
      <div v-else-if="type === 'error'" class="w-2 bg-red-600"></div>
      <div v-else-if="type === 'warning'" class="w-2 bg-yellow-600"></div>
      <div v-else-if="type === 'info'" class="w-2 bg-blue-600"></div>
      <div v-else class="w-2 bg-blue-500"></div>
      <div class="w-full flex justify-between items-start px-3 py-5">
        <div class="flex flex-col ml-2">
          <label class="text-gray-800 mr-5 font-semibold mb-2" v-if="header">
            {{ header }}
          </label>
          <div class="text-gray-500 pr-3 font-light text-xl font-semibold" v-if="subheader">
            {{ subheader }}
          </div>
          <div class="text-gray-500 pr-3" v-if="text">
            {{ text }}
          </div>
        </div>
        <a href="#" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Toaster",
  props: {
    type: String,
    header: String,
    subheader: String,
    text: String,
  },
  components: {},
  mounted() {},
  data() {
    return {
      displayToaster: true,
    };
  },
  computed: {},
  methods: {
    close() {
      this.displayToaster = false;
      this.$emit("showToaster", false)
    },
  },
};
</script>

<style scoped>
</style>


