<template>
  <transition name="modal">
    <div
      class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black g-opacity-50"
    >
      <div class="bg-white rounded shadow-lg w-10/12 md:w-2/4">
        <div
          class="border-b p-5 bg-blue-900 flex justify-between items-center rounded-t"
        >
          <h3 class="font-medium text-white text-lg">
             {{ activity.name }}
          </h3>
          <button
            class="bg-blue-400 hover:bg-blue-500 text-sm text-white py-2 px-2 rounded-full focus:outline-none"
            @click="$emit('close')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div class="modal-body p-5 font-sans">
          <div class="flex z-20">
              <div v-if="activity.description" v-html="activity.description"></div>
              <div v-else>NA</div>
          </div>

        </div>

        <div
          class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg"
        >

        
          <button @click="$emit('close')" class="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
            Close
          </button>
        </div>

      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "ActivityDetailsModal",
  props: {
    activity: Object,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped>
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>