<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-40
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white shadow-lg w-5/12 text-gray-600">
        <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
          <div class="font-medium text-white text-lg flex w-6/12">
            <div class="flex-grow w-1/12 font-bold mt-2">
              {{ isActionComplete ? "Complete Event" : "Cancel Event" }}
            </div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('closeMarkAsCompletedModal')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div class="p-5">
          <div>
            <h4 class="font-bold">Comments (optional)</h4>
            <textarea
              name="comment"
              placeholder="Add a comment"
              id="comment"
              rows="6"
              v-model="comment"
              class="
                bg-gray-100
                appearance-none
                border-2 border-gray-200
                rounded
                shadow-md
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
            ></textarea>
            <div class="text-right mt-3">
              <button
                class="
                  inline-flex
                  text-gray-700
                  bg-gray-100
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-gray-200 hover:border hover:border-gray-700
                  rounded
                  text-base
                  mx-2
                "
                @click="$emit('closeMarkAsCompletedModal')"
              >
                Cancel
              </button>
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
                @click="$emit('submitMarkAsCompletedModal', comment)"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "OpenmicScheduledMarkAsCompleted",
  components: {},
  props: {
    actionType: Object,
  },
  emits: ["closeMarkAsCompletedModal"],
  data() {
    return {
      comment: "",
    };
  },
  methods: {},
  computed: {
    isActionComplete() {
      return (
        this.actionType === "signUpCompleted" ||
        this.actionType === "ArchitectureComplete" ||
        this.actionType === "BuildingBlockComplete" ||
        this.actionType === "StaffComplete"
      );
    },
  },
  mounted: function () {},
  created: function () {},
  beforeUpdate() {},
};
</script>
