<template>
  <div class="px-6 py-3">
    <circularloader />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
export default defineComponent({
  data(){
    return{
     
    }
  },
   created: async function () {
    let result = await this.$store.dispatch("getPages");
   
    let defaultPage = result?.find(f => f?.metaData?.isIndexPage)
    let payload = {
      pageId: defaultPage?.metaData?.pageId,
    };
   
    await this.$store.dispatch("getPageById", payload);
    if(defaultPage){
      this.$router.push({ name: "PageView", params: { page: defaultPage?.metaData?.pageId } });
    } else{ 
      this.$router.push({ name: "SubmissionLibrary" });
    }
  },
   computed: {

  },
  setup() {
  },
});
</script>
<style scoped></style>
