<template>
  <div v-if="loading">
    <div><v-chart class="chart" :loading="loading" :option="option" /></div>
  </div>
  <div v-else>
    <div v-if="data?.length !== 0">
      <v-chart class="chart" @click="handleClick" :option="option" />
    </div>
    <div v-else>
      <div
        class="
          container
          mx-auto
          flex
          px-5
          py-10
          items-center
          justify-center
          flex-col
        "
      >
        <img src="@/assets/images/bog.png" width="300" />
        <div class="text-center lg:w-2/3 w-full">
          <div class="mb-8 mt-8 leading-relaxed">No data available !</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import { boolean } from "yup/lib/locale";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: "Pie",
  components: {
    VChart,
  },
  props: {
    heading: String,
    data: Array,
    loading: Boolean,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  setup(props) {
    const option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "bottom",
      },
      dataset: {
        source: props.data,
      },
      emphasis: {
        label: {
          show: true,
          fontSize: "20",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      color: ["#00AB50", "#107AA3", "#FC9D27", "#B2B2B2", "#1AA1DA"],
      series: [
        {
          name: props.heading,
          type: "pie",
          data: props.data,
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 8,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    return { option };
  },
  methods: {
    handleClick(...args) {
      //this.$emit("viewDetails", args);
    },
  },
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>


