<template>
  <div class="px-5 text-right pt-5">
    <button
      class="
        inline-flex
        text-white
        bg-indigo-500
        border-0
        py-2
        px-4
        focus:outline-none
        hover:bg-indigo-600
        rounded
        text-md
        ml-3
      "
      @click="createTable"
      v-if="!rows && !loading"
    >
      Auto Generate 10 records
    </button>
    <!-- <div
      class="
        inline-block
        relative
        inline-flex
        border-0
        pl-2
        focus:outline-none
        rounded
        text-md
      "
    >
      <import-file @importIdeas="importIdeas" />
    </div> -->

    <!-- <button
      class="
        inline-flex
        text-white
        bg-indigo-500
        border-0
        py-2
        px-4
        focus:outline-none
        hover:bg-indigo-600
        rounded
        text-md
        ml-3
      "
      @click="exportUserActivity"
    >
      Import
    </button> -->
  </div>
  <div class="block p-6" :key="componentKey">
    <div v-if="loading"><circularloader /></div>
    <div
      class="border border-gray-300"
      style="height: calc(100vh - 90px); overflow: auto"
      v-else-if="
        rows?.length !== 0 && this.leaderBoardData?.type === 'leaderboard'
      "
    >
      <v-grid
        rowClass="myRowClass"
        theme="compact"
        :source="rows"
        :columns="columns"
        @afteredit="afteredit"
      ></v-grid>
    </div>
    <div v-else>
      <!-- <button
        class="
          bg-blue-500
          hover:bg-blue-500
          text-sm text-white
          py-2
          px-2
          focus:outline-none
        "
        @click="createTable"
      >
        Generate 10 records
      </button> -->
    </div>
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
import VGrid, { VGridVueTemplate } from "@revolist/vue3-datagrid";
import BoardData from "./LeaderBoardData.vue";
import ImportFile from "../../components/ImportFile.vue";
export default {
  name: "Leader board",

  data() {
    return {
      componentKey: 0,
      columns: [
        {
          name: "Name",
          size: 300,
          prop: "name",
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
        },
        {
          name: "Participants",
          prop: "participants",
          size: 150,
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
        {
          name: "Ideate",
          size: 100,
          prop: "ideate",
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
        {
          name: "Validate",
          prop: "validate",
          size: 100,
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
        {
          name: "Scope",
          size: 100,
          prop: "scope",
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
        {
          name: "Incubate",
          prop: "incubate",
          size: 100,
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
        {
          name: "Total",
          prop: "total",
          size: 100,
          cellTemplate: VGridVueTemplate(BoardData),
          data: this.leaderBoard,
          columnType: "numeric",
        },
      ],
      rows: null,
      loading: false,
    };
  },
  created: async function () {
    this.loading = true;
    await this.$store.dispatch("getLeaderBoardData");
    this.loading = false;
  },
  watch: {
    leaderBoard() {
      this.componentKey += 1;
    },
  },
  computed: {
    ...mapGetters(["leaderBoardData", "user"]),
    leaderBoard() {
      return (this.rows =
        this.$store.getters?.leaderBoardData?.metaData?.tableData);
    },
  },
  methods: {
    async afteredit(e) {
      // prop: "name"
      // rowIndex: 0
      // type: "rgRow"
      // val: "Liz Centoniaasdasd"
      // console.log(e.detail.prop);
      this.leaderBoard?.map((m, i) => {
        if (i === e.detail.rowIndex) {
          m[e.detail.prop] = e.detail.val;
        }
      });

      let payload = {
        id: this.leaderBoardData._id,
        user: this.user,
        updatedTableData: this.leaderBoard,
      };
      await this.$store.dispatch("updateLeaderBoardData", payload);
    },
    async createTable() {
      let dummyData = [];
      for (let i = 0; i < 10; i++) {
        let element = {
          name: "Leader Name " + (i + 1),
          participants: null,
          ideate: null,
          validate: null,
          scope: null,
          incubate: null,
          total: null,
        };
        dummyData.push(element);
      }

      let payload = {
        tableData: dummyData,
        user: this.user,
      };
      await this.$store.dispatch("createLeaderBoard", payload);
    },
    rowdragstart(PositionItem, text) {
      console.log({ PositionItem }, { text });
    },
    importIdeas: async function (collection) {
      this.$store.dispatch("importIdeas", collection);
    },
  },
  components: {
    VGrid,
    BoardData,
    ImportFile,
  },
};
</script>
<style >
.rowHeaders {
  background-color: #f7faff;
}
.header-rgRow {
  border-bottom: 1px solid #ccc !important;
}
.rgCell {
  border-collapse: collapse !important;
  border-bottom: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}
.edit-input-wrapper input {
  padding: 0 5px 0 15px !important;
}
</style>