<template>
  <div></div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {};
  },
  computed: {},
});
</script>

<style scoped>
</style>