<template>
  <TransitionRoot appear :show="openHelp" as="template">
    <Dialog as="div" @close="closeModal" :open="openHelp">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-800 opacity-20" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 p-3 text-white bg-blue-700"
              >
                Help Request 
              </DialogTitle>
              <div v-if="!isFormSubmitted">
                <div class="mt-2 p-3">
                  <!-- <pre>{{formData}}</pre> -->
                  <div class="mb-2">
                    <h3 class="mb-0.5 text-sm font-bold">Send Request To</h3>
                    <div class="flex">
                      <div class="flex-grow">
                        <input
                          type="radio"
                          id="programemanagement"
                          name="helpTo"
                          value="programemanagement"
                          checked
                          v-model="formData.helpTo"
                        />
                        <label for="programemanagement" class="ml-2"
                          >Program Management</label
                        >
                      </div>
                      <div class="flex-grow">
                        <input
                          type="radio"
                          id="technicalsupport"
                          name="helpTo"
                          value="technicalsupport"
                          v-model="formData.helpTo"
                        />
                        <label for="technicalsupport" class="ml-2"
                          >Technical Support</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <h3 class="mb-0.5 text-sm font-bold">
                      Provide a short summary of your question/problem <span></span>
                    </h3>
                    <div>
                      <div class="">
                        <input
                          class="
                            bg-white
                            appearance-none
                            border border-gray-200
                            rounded
                            w-full
                            py-2
                            px-4
                            mb-1
                            text-gray-700
                            leading-tight
                            focus:outline-none
                            focus:bg-white
                            focus:border-gray-500
                          "
                          id="title"
                          placeholder=""
                          v-model.trim="formData.subject"
                          type="text"
                        />
                        <text-area-error
                        class="mb-5"
                          :value="formData.subject"
                          :validationErros="validationErros"
                          validate="subject"
                          :count="100"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <h3 class="mb-0.5 text-sm font-bold">
                      Describe in detail your question/problem <span></span>
                    </h3>
                    <div class="">
                      <textarea
                        name=""
                        placeholder=""
                        rows="6"
                        v-model.trim="formData.issue"
                        class="
                          bg-white
                          appearance-none
                          border border-gray-200
                          rounded
                          w-full
                          py-2
                          px-4
                          text-gray-700
                          leading-tight
                          focus:outline-none
                          focus:bg-white
                          focus:border-gray-500
                        "
                        id="inline-full-name"
                      >
                      </textarea>

                      <text-area-error
                      class="mb-5"
                        :value="formData.issue"
                        :validationErros="validationErros"
                        validate="issue"
                        :count="2000"
                      />
                    </div>
                  </div>
                  <!-- <div class="mt-8">
                    <h3 class="mb-0.5 text-sm font-bold">Attachments</h3>
                    <div class="flex">
                      <file-upload
                        :accessToken="accessToken"
                        :attachments="this.formData.attachments"
                        @fileUploaded="fileUploaded"
                      />

                      <button
                        class="
                          bg-blue-500
                          text-white
                          hover:text-white
                          py-1
                          px-2
                          border border-blue-500
                          hover:border-transparent
                          rounded
                          text-sm
                          font-semibold
                          ml-2
                        "
                        @click="addFilesModal()"
                      >
                        Add Link
                      </button>
                    </div>
                    <add-files
                      :openAddFile="openAddFile"
                      @openAddFileModal="openAddFileModal"
                      @fileUploaded="fileUploaded"
                      :files="this.formData.attachments"
                      :fileObj="fileObj"
                      :todo="todo"
                    ></add-files>
                    <div class="mt-4" v-if="formData?.attachments?.length > 0">
                      <div class="flex bg-white flex-col rounded-lg py-1 px-3">
                        <ul class="">
                          <li
                            class="flex pt-1 pb-2"
                            v-for="(attachment, index) in formData?.attachments"
                            :key="index"
                          >
                            <div class="w-full flex">
                              <div
                                class="mr-3 relative flex"
                                :class="{
                                  'pt-2': attachment.documentUrl,
                                }"
                              >
                                <svg
                                  v-if="attachment.documentUrl"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                    clip-rule="evenodd"
                                  />
                                </svg>

                                <svg
                                  v-if="!attachment.documentUrl"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div class="flex-grow overflow-hidden">
                                <div
                                  class="
                                    font-semibold
                                    text-sm text-gray-700
                                    break-all
                                  "
                                >
                                  {{ attachment?.documentName }}
                                </div>
                                <div
                                  class="
                                    text-sm text-gray-500
                                    block
                                    truncate
                                    pr-5
                                    -mt-1
                                  "
                                >
                                  {{ attachment.documentUrl }}
                                </div>
                              </div>
                              <div class="">
                                <div
                                  @click="deleteAttachment(index)"
                                  class="cursor-pointer blue-400 text-blue-600"
                                  :class="{
                                    'pt-2': attachment.documentUrl,
                                  }"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="
                                      feather feather-trash-2
                                      hover:opacity-50
                                    "
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                      x1="10"
                                      y1="11"
                                      x2="10"
                                      y2="17"
                                    ></line>
                                    <line
                                      x1="14"
                                      y1="11"
                                      x2="14"
                                      y2="17"
                                    ></line>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="mb-2">
                  <h3 class="mb-0.5 text-sm font-bold">Attachments</h3>
                  <div></div>
                </div> -->
                  <!-- <div class="">
                  <div
                    v-for="item in attach"
                    :key="item"
                    class="flex mb-1 bg-gray-200 rounded pt-2 pb-1 px-2"
                  >
                    <div class="flex-grow">File {{ item }}</div>
                    <div>
                      <button
                        type="button"
                        class="
                          inline-flex
                          justify-center
                          text-sm
                          font-medium
                          hover:scale-110
                          focus:outline-none
                          rounded
                          cursor-pointer
                          hover:opacity-70
                          duration-200
                          ease-in-out
                          transition
                          disabled:opacity-30
                        "
                      >
                        <Close />
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="text-gray-500 text-sm">All fields are mandatory</div>
                </div>

                <div class="p-3 pt-0 right-0 text-right border-t pt-3">
                  <button
                    type="button"
                    @click="onCancel"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      duration-200
                      ease-in-out
                      transition
                      mr-3
                    "
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    :disabled="submitDisabled"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-indigo-600
                      bg-indigo-500
                      text-white
                      duration-200
                      ease-in-out
                      transition
                      disabled:opacity-30
                    "
                    @click="onSubmit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div v-else class="p-3 m-auto w-full block text-center mt-5">
                <div class="inline-block">
                  <img src="@/assets/images/help-confirmation.png" width="200" />
                </div>
                <h3 class="font-semibold text-lg mt-5 mb-1">
                  Your help request has been sent.
                </h3>
                <div>
                  <!-- <pre>{{ formData }}</pre> -->
                  We have received your help request. <br />
                  Someone from our team will be in touch soon.
                </div>
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    my-5
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  @click="onCancel"
                >
                  Got it
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script >
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import TextAreaError from "../TextAreaError.vue";
import Close from "../Icon/Close.vue";
import FileUpload from "../FileUpload.vue";
import AddFiles from "../Modal/AddFiles.vue";
import objectId from "../../helpers/objectId";

export default {
  name: "HelpRequestModal",
  data() {
    return {
      formData: {
        helpTo: "programemanagement",
        subject: "",
        issue: "",
        attachments: [],
      },
      attach: [1, 2, 3, 4, 5],
      openAddFile: false,
      fileObj: null,
      isFormSubmitted: false,
    };
  },
  props: {
    openHelp: Boolean,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TextAreaError,
    Close,
    FileUpload,
    AddFiles,
  },
  computed: {
    submitDisabled() {
      return this.formData.subject === "" || this.formData.issue === "";
    },
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch("sendHelpRequest",this.formData );
      this.isFormSubmitted = true;
    },
    async onCancel() {
      await this.$emit("helpRequest", false);
      this.formData = {
        helpTo: "programemanagement",
        subject: "",
        issue: "",
        attachments: [],
      };
      this.isFormSubmitted = false;
    },
    openAddFileModal(flag) {
      this.openAddFile = flag;
    },
    addFilesModal: function (item) {
      this.fileObj = {
        documentName: "",
        documentUrl: "",
        documentId: objectId(),
      };
      this.todo = "add";
      this.openAddFileModal(true);
    },
    // editFile: function (item) {
    //   this.fileObj = { ...item };
    //   this.todo = "edit";
    //   this.openAddFileModal(true);
    // },
    fileUploaded: function (uploadedFiles) {
      this.formData.attachments = uploadedFiles;
    },
  },
  created: function () {},
};
</script>
