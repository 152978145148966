<template>
  <!-- <pre>{{ initiative }}</pre> -->
  <div class="text-gray-600" :key="componentKey">
    <h3 class="font-bold mb-2">
      Activities

      <div
        class="
          border border-gray-300
          rounded-lg
          pl-2
          pr-2
          w-auto
          mr-2
          text-center
          float-right
        "
        v-if="initiative?.totalTasks?.length !== 0"
      >
        <span class="float-left pt-1 pr-0.5">
          <svg
            width="20"
            height="20"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m266.88 463.23c0 18.754-10.008 36.086-26.25 45.465s-36.258 9.3789-52.5 0-26.25-26.711-26.25-45.465c0-18.758 10.008-36.09 26.25-45.469 16.242-9.3789 36.258-9.3789 52.5 0 16.242 9.3789 26.25 26.711 26.25 45.469zm52.5-297.5v35h227.5v-35zm0 175h227.5v-35h-227.5zm0 140h227.5v-35h-227.5zm-105-210c-18.914 0-36.391 10.09-45.844 26.469-9.457 16.379-9.457 36.559 0 52.938 9.4531 16.379 26.93 26.469 45.844 26.469 18.977-0.09375 36.461-10.305 45.867-26.785 9.4062-16.48 9.3047-36.727-0.26563-53.113-9.5664-16.387-27.152-26.422-46.125-26.328zm0-63.352-47.602-47.773c-7.8789 16.27-6.832 35.449 2.7656 50.766 9.6016 15.316 26.41 24.617 44.484 24.617 18.078 0 34.887-9.3008 44.484-24.617 9.6016-15.316 10.648-34.496 2.7656-50.766zm-37.102-61.949c10.148 10.324 26.773 26.426 37.273 36.926l113.05-112.35-24.676-25.551-86.102 86.102c-1.5039-0.26172-3.043-0.26172-4.5508 0-8.2227-8.0508-25.547-25.551-33.949-33.777l-24.676 25.727c5.25 4.375 18.375 17.5 23.625 22.926z"
            />
          </svg>
        </span>
        <span class="float-left">
          {{ initiative.completed }} / {{ initiative.totalTasks }}</span
        >
      </div>
    </h3>
    <div class="w-full my-2 bg-gray-200 rounded-full">
      <div
        class="fluent-Progress-bar-normal rounded-full transition"
        :style="{
          width: (initiative.completed / initiative.totalTasks) * 100 + '%',
        }"
      ></div>
    </div>

    <div class="" v-for="(stage, index) in stages" :key="index">
      <div class="rounded border border-gray-300 mb-3">
        <div class="border-b border-b-0 p-2 bg-gray-200 font-semibold text-sm">
          {{ stage.name }}
          <span
            v-if="stage?.references?.length !== 0"
            class="float-right pt-0.5"
            :class="{
              'rotate-icon-90': !stage.isopen || stage.isOpenReference,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>

          <div
            class="
              border border-gray-300
              rounded-lg
              pl-2
              pr-2
              w-auto
              mr-2
              text-center
              float-right
              cursor-pointer
              hover:bg-blue-50
            "
            :class="{ 'bg-blue-300': !stage.isopen }"
            @click="openActivity(stage)"
          >
            <!-- :class="{ 'bg-green-100': !stage.isopen }" -->
            <span class="float-left pt-1 pr-0.5">
              <svg
                width="16"
                height="16"
                version="1.1"
                viewBox="0 0 700 700"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m266.88 463.23c0 18.754-10.008 36.086-26.25 45.465s-36.258 9.3789-52.5 0-26.25-26.711-26.25-45.465c0-18.758 10.008-36.09 26.25-45.469 16.242-9.3789 36.258-9.3789 52.5 0 16.242 9.3789 26.25 26.711 26.25 45.469zm52.5-297.5v35h227.5v-35zm0 175h227.5v-35h-227.5zm0 140h227.5v-35h-227.5zm-105-210c-18.914 0-36.391 10.09-45.844 26.469-9.457 16.379-9.457 36.559 0 52.938 9.4531 16.379 26.93 26.469 45.844 26.469 18.977-0.09375 36.461-10.305 45.867-26.785 9.4062-16.48 9.3047-36.727-0.26563-53.113-9.5664-16.387-27.152-26.422-46.125-26.328zm0-63.352-47.602-47.773c-7.8789 16.27-6.832 35.449 2.7656 50.766 9.6016 15.316 26.41 24.617 44.484 24.617 18.078 0 34.887-9.3008 44.484-24.617 9.6016-15.316 10.648-34.496 2.7656-50.766zm-37.102-61.949c10.148 10.324 26.773 26.426 37.273 36.926l113.05-112.35-24.676-25.551-86.102 86.102c-1.5039-0.26172-3.043-0.26172-4.5508 0-8.2227-8.0508-25.547-25.551-33.949-33.777l-24.676 25.727c5.25 4.375 18.375 17.5 23.625 22.926z"
                />
              </svg>
            </span>

            <span class="float-left">
              {{ stage.activityList.filter((f) => f.isCompleted).length }} /
              {{ stage.activityList.length }}
            </span>
          </div>

          <div
            v-if="stage?.references?.length !== 0"
            class="
              border border-gray-300
              rounded-lg
              pl-2
              pr-2
              w-auto
              mr-2
              text-center
              float-right
              cursor-pointer
              hover:bg-blue-50
            "
            @click="openReference(stage)"
            :class="{ 'bg-blue-300': stage.isOpenReference }"
          >
            <!-- -->
            <span class="float-left pt-1 pr-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 float-left"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <span class="float-left"> {{ stage?.references?.length }}</span>
          </div>
        </div>
        <div class="flex flex-col text-sm" v-show="stage.isOpenReference">
          <h3 class="p-2 font-semibold bg-gray-50 border-b">
            References ({{ stage.references ? stage.references.length : 0 }})
          </h3>
          <div
            class="border-b"
            v-for="(cont, index) in stage.references"
            :key="index"
          >
            <div class="flex p-2 w-8 bg-gray-50 w-full" v-if="index === 0">
              <div class="w-12">Type</div>
              <div class="flex-1">Name</div>
              <div class="flex-1">Description</div>
            </div>
            <div class="flex p-2 w-8 w-full">
              <div class="w-12">
                <icon-with-type :type="cont.id.type" />
              </div>
              <div class="flex-1">{{ cont.id.name }}</div>
              <div class="flex-1">
                <span
                  v-html="
                    fullTextIndex === index
                      ? cont.id.description
                      : cont.id.description.substring(0, 120)
                  "
                />

                <span
                  class="text-blue-700 cursor-pointer hover:opacity-70"
                  v-if="fullTextIndex === index"
                  @click="fullTextIndex = null"
                >
                  Read Less
                </span>
                <span
                  v-else
                  class="text-blue-700 cursor-pointer hover:opacity-70"
                  @click="fullTextIndex = index"
                >
                  {{ cont.id.description.length > 120 ? " ...Read More" : "" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col" v-show="!stage.isopen">
          <form class="checklist">
            <div
              v-for="activity in stage.activityList"
              :key="activity._id"
              class="custom-control custom-checkbox col border-b"
            >
              <div class="inline-block w-full">
                <div class="float-left mt-1">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :checked="activity.isCompleted"
                    @click="$emit('markComplete', activity)"
                    :id="activity._id"
                    :disabled="isActivityUpdateInProgress || !hasReadWriteAccess(activity) || initiative.status===6"
                  />
                  <!-- :disabled="noAccess || noAccessToPreviousPhaseData" -->
                  <div class="checklist-strikethrough pt-5 mb-2"></div>
                  <label
                    class="custom-control-label cursor-pointer"
                    :class="{
                      'cursor-wait': isActivityUpdateInProgress && hasReadWriteAccess(activity),
                      'cursor-not-allowed': !hasReadWriteAccess(activity) || initiative.status===6,
                    }"
                    :htmlFor="activity._id"
                  ></label>
                </div>
                <div class="border-l abcde">
                  <MyProgressActivityDetails :activity="activity" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconMilestone from "../../Icon/Icon-Milestone.vue";
import IconRightArrow from "../../Icon/Icon-Arrow-Right.vue";
import IconDownArrow from "../../Icon/Icon-Arrow-Down.vue";
import MyProgressActivityDetails from "./MyProgressActivityDetails.vue";
import IconWithType from "../../../components/IconWithType.vue";

export default {
  name: "MyProgressActivities",
  props: {
    stages: Array,
    initiative: Object,
    isActivityUpdateInProgress: Boolean,
    currentPhaseIndex: String,
  },
  components: {
    IconMilestone,
    IconRightArrow,
    IconDownArrow,
    MyProgressActivityDetails,
    IconWithType,
  },
  emits: ["markComplete"],
  data() {
    return {
      componentKey: 0,
      fullTextIndex: null,
    };
  },
  async beforeMount() {
    for (let stage of this.stages) {
      let stageData = await this.$store.dispatch(
        "getReferencesDetails",
        stage._id
      );
      stage.references = stageData.references;
      for (let activity of stage.activityList) {
        let activityData = await this.$store.dispatch(
          "getReferencesDetails",
          activity._id
        );
        activity.references = activityData.references;
      }
    }
  },
  created: async function () {},
  computed: {},
  methods: {
    openActivity: async function (stage) {
      stage.isopen = !stage.isopen;
    },
    openReference: async function (stage) {
      stage.isOpenReference = !stage.isOpenReference;
    },
    setClass: function (item) {
      if (item.isopen == true) {
        return "open";
      }
      if (item.isOpenReference == true) {
        return "open";
      }
      return "close";
    },
    firstOpen: function () {
      this.stages[0].isopen = true;
    },
    hasReadWriteAccess: function(activity){
      if(activity.action == 'readOnly'){
        return false;
      } else {
        return true;
      }
    }
  },
};
</script>
<style scoped>
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.2rem;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1983bf;
  background-color: #1983bf;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e9ecef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #1983bf;
  background-color: #1983bf;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.checklist .custom-control-input input:checked + .checklist-strikethrough {
  width: 100%;
}
.checklist .custom-checkbox input:checked > label {
  opacity: 0.7;
}
.-w-100 {
  width: calc(100% - 140px);
}

.checklist-strikethrough {
  height: 40px;
  /* background: linear-gradient(90deg, #5982ff 0%, rgb(150, 175, 250) 100%); */
  background: #f1f1f1;
  border-radius: 2px 3px 2px 2px;
  mix-blend-mode: multiply;
  /* -webkit-transform: skewX(-10deg); */
  /* transform: skewX(-10deg); */
  /* opacity: 0.3; */
  width: 0;
  transition: width 0.35s ease-in-out, opacity 0.25s ease-in-out;
  position: absolute;
  margin-left: -51px;
  top: 0;
  pointer-events: none;
}

.custom-checkbox-label {
  background: inherit;
  width: 100%;
}

.custom-checkbox-label:focus {
  outline: none;
}
.fluent-Progress-bar-normal {
  background-color: #1983bf !important;
  height: 8px;
}

.checklist .custom-control-input input[type="checkbox"]:checked + p + div {
  background: green;
  width: 100%;
}

.checklist .custom-control-input input[type="checkbox"]:checked + div {
  background: blue;
  width: 100%;
}
</style>