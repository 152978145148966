<template>
  <!-- <pre>{{ selectedIdea?.parentInitiativeId?._id }}</pre> -->
  <div class="w-full">
    <div class="mb-3 float-left w-full">
      <div class="float-left w-8/12">
        <div class="text-xs text-gray-500">Submission Name</div>
        <div class="leading-3 break-words leading-4">{{ selectedIdea?.parentInitiativeId?.name }}</div>
      </div>

      <div class="float-right text-right">
        <button
          class="
            inline-flex
            text-indigo-500
            bg-white
            border border-indigo-500
            py-1
            px-2
            hover:bg-indigo-600 hover:text-white
            rounded
            text-xs
          "
          @click.self="selectInitiative(selectedIdea.parentInitiativeId)"
        >
          View Submission Details
        </button>
      </div>
    </div>
    <div class="float-left w-full">
      <!-- <div class="float-left mr-3 pr-3 border-r">
        <div class="text-xs text-gray-500">Idea Creation</div>
        <div class="leading-3">{{ selectedIdea.users[0].name }}</div>
      </div> -->
      <!-- <pre>{{selectedIdea._id}}</pre> -->
      <div class="float-left">
        <div class="text-xs text-gray-500">Submission Review Started</div>
        <div class="leading-3">
          {{ timeago.format(new Date(selectedIdea.createdAt)) }}
        </div>
      </div>

      <div class="float-right text-right">
        <div class="text-xs">
          <status-track
            :teams="selectedIdea.activities[0].activities"
           :initiative="selectedIdea?.parentInitiativeId?._id"
          />
        </div>
        
      </div>
    </div>
    <!-- <div>
      <div v-if="selectedIdea?.parentInitiativeId.metaData.fcsDate" class="text-xs text-gray-500">Project Duration</div>
      {{ getProjectDuration }}
    </div>
    <div>
      <div v-if="selectedIdea?.parentInitiativeId?.metaData?.revenueDate" class="text-xs text-gray-500">Revenue</div>
      {{ selectedIdea?.parentInitiativeId?.metaData?.revenueDate }}
    </div>
    <div>
      <div v-if="selectedIdea?.parentInitiativeId?.metaData?.activationDate" class="text-xs text-gray-500">Activations</div>
      {{ selectedIdea?.parentInitiativeId?.metaData?.activationDate }}
    </div>
    <div>
      <div v-if="selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize" class="text-xs text-gray-500">Number of Team Members</div>
      {{ selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize }}
    </div>
    <div
      v-if="selectedIdea?.parentInitiativeId?.metaData?.revenueDate && selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize">
      <div class="text-xs text-gray-500">Average Revenue per Team Member</div>
      {{ Math.round(selectedIdea?.parentInitiativeId?.metaData?.revenueDate.substring(1) /
        selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize)
      }}
    </div>
    <div
      v-if="selectedIdea?.parentInitiativeId?.metaData?.activationDate && selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize">
      <div class="text-xs text-gray-500">Average Users/Activations per Team Member</div>
      {{ Math.round(selectedIdea?.parentInitiativeId?.metaData?.activationDate /
        selectedIdea?.parentInitiativeId?.metaData?.coreTeamSize) }}
    </div> -->
  </div>
</template>
<script>
import * as timeago from "timeago.js";
import StatusTrack from "../statusTrack.vue";
export default {
  data() {
    return {
      timeago,
    };
  },
  props: {
    selectedIdea: Object,
    activitiesToUpdate: Array,
    selectedInitiative: Object,
  },
  name: "ReviewForm",
  components: { StatusTrack },
  mounted: function () {
  },
  created:function(){
  },
  computed:{
    getProjectDuration(){
      let startDate=new Date(this.selectedIdea?.parentInitiativeId.metaData?.fcsDate)
      if(startDate=="Invalid Date") return ""
      let newDate= new Date()
      var diff =(newDate.getTime() - startDate.getTime()) / 1000;
      diff /= (60 * 60 * 24);
      let duration = Math.round(diff/36.5)/10;
      return duration +" Year"
    },
  },
  methods: {
    selectInitiative(id) {
      this.$emit("viewDetail", id);
      this.isModalVisible = true;
    },
  },
};
</script>
<style scoped></style>
