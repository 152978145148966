<template>
  <TransitionRoot appear :show="openClose" as="template">
    <Dialog as="div" :open="openClose">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ this.title }}
              </DialogTitle>
              <div class="mt-0">
                <p class="text-sm text-gray-500">
                  {{ this.heading }}
                </p>
                <span class="text-sm text-yellow-400">{{ this.info }}</span>
              </div>

              <div v-if="this.showTextBox" class="mt-2">
                <textarea
                  name="comment"
                  id="comment"
                  rows="6"
                  placeholder="Comment"
                  v-model="noteObj.content"
                  class="
                    bg-gray-200
                    appearance-none
                    border-2 border-gray-200
                    rounded
                    shadow-md
                    w-full
                    py-2
                    px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none
                    focus:bg-white
                    focus:border-gray-500
                  "
                ></textarea>
              </div>
              <!-- <p v-if="this.showTextBox" class="-mb-1 text-sm text-gray-500">max. 2000 characters</p> -->

              <div class="mt-4">
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  :disabled="
                    !(noteObj?.content)||(noteObj &&
                    noteObj?.content &&
                    noteObj?.content?.length === 0)
                  "
                  @click="
                    $emit(
                      `${this.todo === 'add' ? 'postNote' : 'editDeleteNotes'}`,
                      {}
                    )
                  "
                >
                  {{ this.buttonText }}
                </button>
                <button
                  type="button"
                  @click="$emit('openCloseModal', false)"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "ConfirmationDialog",
  data() {
    return {
      comment: "",
      title: "",
      showTextBox: false,
      heading: "",
      buttonText: "",
      info: "",
    };
  },
  beforeUpdate() {
    if (this.todo == "add") {
      this.title = "Add Comment";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Add";
      this.info = "";
    } else if (this.todo == "edit") {
      this.title = "Edit Comment";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Update";
      this.info = "";
    } else if (this.todo == "delete") {
      this.title = "Delete Comment";
      this.showTextBox = false;
      this.heading = "Are you sure that you wish to delete this comment ?";
      this.buttonText = "Delete";
      this.info = "This action is not reversible";
    }
  },
  props: {
    phaseIndex: Number,
    openClose: Boolean,
    openCloseModal: Boolean,
    noteObj: Object,
    todo: String,
    phaseIndex: Number,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {},
};
</script>
