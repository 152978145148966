<template>
  <div >
    <div class="h-screen" v-if="logoutFlag">
      <Loading />
    </div>
    <div v-else  class="scroller">
      <div class="flex h-screen bg-gray-200 font-roboto">
        <div class="flex-1 flex flex-col overflow-hidden">
          <Header @openSideBarAction="openSideBarAction" />
          <main class="flex  bg-gray-200  " 
          style="margin-top:60px; height:calc(100vh - 60px)">
            <Sidebar :openSideBar="openSideBar" />
            <SubMenu v-if="$route.path.startsWith('/setting')" />
           <!-- <div class=" mx-auto px-6 py-3"> -->
            <div class="w-full relative pt-0  overflow-auto " >
              <slot />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { mapGetters } from "vuex";

import Sidebar from "./Sidebar.vue";
import SubMenu from "./SubMenu.vue";
import Header from "./Header.vue";
import Loading from "./Loading.vue";
import Footer from "./Footer.vue";
export default defineComponent({
  data() {
    return {
      openSideBar: true,
    };
  },
  computed: {
    ...mapGetters(["logoutFlag"]),
  },
  components: {
    Header,
    Sidebar,
    Loading,
    Footer,
    SubMenu
  },
  methods: {
    openSideBarAction(isOpen) {
      this.openSideBar = isOpen;
    },
  },
});
</script>

<style scoped>
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.5s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>
