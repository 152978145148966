<template>
  <svg
        width="20"
        height="20"
        version="1.1"
        viewBox="0 0 700 700"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :fill="fill ? fill : currentColor"
      >
        <g>
          <path
            d="m516.24 214.23h-75.73c6.2852-25.199 23.215-34.789 23.215-69.977 0-64.223-73.148-118.98-91.602-20.238-5.4883 26.59-66.008 122.82-92.926 124.94-9.0625 0.85938-16.668 8.4648-16.668 17.527v175.27c0 9.5898 8.2031 17.195 17.461 17.461 9.5898 0.33203 25.992 6.0195 43.188 12.039 29.562 10.121 66.469 23.016 105.56 23.016 50.73 0 108.2 0.79297 102.52-70.836 14.816-14.152 20.57-38.164 14.816-57.672 13.227-17.727 14.617-37.965 7.6719-58.27 0 0 11.973-93.258-37.5-93.258z"
            fill-rule="evenodd"
          />
          <path
            d="m245 266.74v175c0 9.5234-7.9375 17.461-17.527 17.461h-78.707c-9.5898 0-17.527-7.8711-17.527-17.461v-175c0-9.5898 7.9375-17.527 17.527-17.527h78.707c9.5898 0 17.527 7.9375 17.527 17.527z"
            fill-rule="evenodd"
          />
        </g>
      </svg>
</template>
<script>
export default {
  name: "Icon-Like",
  props:{
    fill : String
  }
};
</script>

