<template>
    <TransitionRoot appear :show="openSwapJudges" as="template">
        <Dialog as="div" :open="openSwapJudges" @close="closeModal">
            <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
                <div class="h-96 px-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0"
                        enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                        <DialogOverlay class="fixed inset-0" />
                    </TransitionChild>

                    <span class="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <div class="
                  inline-block
                  w-full
                  max-w-4xl
                  min-w-4xl
                  p-6
                  my-8
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-xl
                  rounded-md
                  h-[500px]
                ">
                            <div class="flex  justify-between font-semibold text-xl mt-2 mb-3"> <p>Swap a Judge</p>
                                <button
                   class="bg-blue-400 hover:bg-blue-500 text-sm text-white py-2 px-2 rounded-full focus:outline-none"
                   @click="this.cancel()"
                  >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                   </svg>
                 </button>
                            </div>
                            <DialogTitle as="h4" class="
                    text-lg
                    font-medium
                    leading-6
                    text-gray-900
                    pb-3
                  ">
                                Select a judge to swap for <span class="font-bold">{{ `${this.selectedUser?.firstName}
                                                                    ${this.selectedUser?.lastName}` }}</span>
                            </DialogTitle>
                            <div class="relative mb-2">
                            <input
                            v-model="searchQuery"
                            type="search"
                            class="w-full py-2 pl-10 pr-4 rounded-full bg-gray-50 border border-gray-300 focus:outline-none"
                            placeholder="Search Judges"
                            />
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18.002" viewBox="0 0 14 14.002">
                                 <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M13.81,12.106,11.083,9.38a.656.656,0,0,0-.465-.191h-.446a5.685,5.685,0,1,0-.984.984v.446a.656.656,0,0,0,.191.465l2.726,2.726a.654.654,0,0,0,.927,0l.774-.774a.659.659,0,0,0,0-.93ZM5.688,9.188a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,5.688,9.188Z" fill="#878787"/>
                              </svg>
                            </div>
                           </div>	
                            <div class="flex font-lg mt-2 mb-3">Judges <span
                                    class="numberCircle ml-2 mt-0.5">{{ this.filterPhaseTeamMembersOnCategory?.length }}</span>
                            </div>
                            <div class="flex mt-2 mb-3">Select 1 Judge
                            </div>
                            <div v-if="this.filterPhaseTeamMembersOnCategory?.length == 0" class="text-red-500">
                                No Judges Found!
                            </div>
                            <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-1 pr-4 mb-6"
                                style="max-height: 320px; overflow: auto">
                                <!-- <div class="flex"> -->
                                <div v-for="(member, index) in this.filterPhaseTeamMembersOnCategory" :key="index"
                                    class="custom-control custom-checkbox col pl-9">
                                    <div class="flex p-2 mt-2"
                                       >
                                       <input
                                         type="radio"
                                         class="mr-4"
                                         :class="{'cursor-pointer':member.isAvailable}"
                                         :checked="member.isChecked"
                                         @click.stop="memberSelected(member)"
                                         :id="'memberRadio' + index"
                                         :value="member._id"
                                         :disabled="!member.isAvailable"
                                         />                  
                                        <img class="
                                            mr-2
                                            mt-0.5
                                            userImage
                                            h-5
                                            w-5
                                            rounded-full
                                            ring-2 ring-white
                                            shadow
                                            hover:ring-4
                                            cursor-pointer
                                            " :src="member.profilePic" alt="users" />
                                        <div :class="{ 'text-gray-300':!member.isAvailable }">{{ `${member?.firstName} ${member?.lastName}` }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 text-right">
                                <button :disabled="!isEnable" type="button" class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      mr-3
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-indigo-600
                      bg-indigo-500
                      text-white
                      duration-200
                      ease-in-out
                      transition
                      disabled:opacity-30
                    " @click="this.select()">
                                    Save
                                </button>
                                <button type="button" @click="this.cancel()" class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      duration-200
                      ease-in-out
                      transition
                    ">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
} from "@headlessui/vue";
export default {
    name: "SwapJudges",
    props: {
        openSwapJudges: Boolean,
        currentPhaseIndex: Number,
        initiative: Object,
        allInitiativesForSwapJuges:Array
    },
    data() {
        return {
            selectedCount: 0,
            isEnable: false,
            user: null,
            searchQuery:""
        };
    },
    computed: {
        ...mapGetters(["selectedUser", "user", "phaseNames", "currentPhaseTeamMembers",, "selectedTeamMembersPhase"]),
        accessToken() {
            const { accessToken } = this.user;
            const tokenString = `Bearer ${accessToken}`;
            return tokenString;
        },
        filterPhaseTeamMembersOnCategory: function () {
            const query = this.searchQuery.trim().toLowerCase();
            const members = [];
            if (this.initiative && this.initiative?.judgingInitiativeDetails && this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy) {
                this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy?.forEach((member) => {
                    members.push(member?.accountUserId?.email)
                })
                if(this.initiative?.judgingInitiativeDetails?.reviewCompletedBy){
                    this.initiative?.judgingInitiativeDetails?.reviewCompletedBy?.forEach((member) => {
                        members.push(member?.accountUserId?.email)
                })
                }
            }
            if (this.allInitiativesForSwapJuges?.length > 0) {
                this.allInitiativesForSwapJuges?.forEach((item) => {
                    if (item?.metaData && item?.metaData?.challengeType && this?.initiative && this?.initiative?.metaData && this?.initiative?.metaData?.challengeType) {
                        if (item?.metaData?.challengeType === this.initiative?.metaData?.challengeType) {
                            item?.metaData?.teamLead?.forEach((lead) => { members.push(lead.email) })
                            item?.metaData?.teamMembers?.forEach((member) => { members.push(member.email) })
                            item?.metaData?.submitter?.forEach((member) => { members.push(member.email) })
                        }
                    }
                })
            }
            for (let initiative of this.allInitiativesForSwapJuges){
                if(initiative?.metaData?.challengeType !== this.initiative?.metaData?.challengeType && initiative?.judgingTeamMembers?.length > 0){
                for(let member of initiative?.judgingTeamMembers){
                    if(!members.includes(member.email)){
                    members.push(member.email)
                    }
                }
                }
            }
            let teamMembers = this.currentPhaseTeamMembers?.map((member)=>{
                member.isAvailable = !members.includes(member.email)  
                return member;
            });
            if (query !== '') {
                teamMembers = teamMembers.filter(member => {
                const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
                return fullName.includes(query);
            });
            }
            return teamMembers;
        }
    },
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle
    },
    methods: {
        getTeamMembers: async function () {
      if(this.initiative.phaseInfo.metaData.teamId){
        let payload = {
          teamId: this.initiative.phaseInfo.metaData.teamId,
          mutation: 'CURRENT_PHASE_TEAM_MEMBERS'
        }
        await this.$store.dispatch("getTeamMembersById", payload);
      }
      if(this.initiative.phaseInfo.metaData.selectedJudgesTeamId){
        let payload = {
          teamId: this.initiative.phaseInfo.metaData.selectedJudgesTeamId,
          mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
        }
        await this.$store.dispatch("getTeamMembersById", payload);
      }
      if(this.selectedTeamMembersPhase){
        this.currentPhaseTeamMembers.map((member)=>{
          this.selectedTeamMembersPhase.map((selectedMember)=>{
            if(member._id == selectedMember._id){
              member.isChecked = true;
            }
          })
        })
      }
    },
        memberSelected: async function (user) {
            this.selectedCount = 0;
            this.currentPhaseTeamMembers.map((member) => {
                if (member._id == user._id) {
                    if ('isChecked' in user && !member.isChecked == false) {
                        delete member.isChecked;
                        this.user = null;
                    } else {
                        member.isChecked = true;
                        this.user = user;
                    }
                } else {
                    if (member.isChecked) {
                        delete member.isChecked;
                    }
                }
                if (member.isChecked) {
                    this.selectedCount++;
                }
            });
            if (this.selectedCount === 1) {
                this.isEnable = true;
            } else {
                this.isEnable = false;
            }
        },
        select:async function(){
            this.user=null;
            this.isEnable=false;
            this.selectedCount = 0;
            this.searchQuery="";
            await this.$emit('openConfirmationSwapModal', true)
        },
        cancel: async function () {
            this.user=null;
            this.isEnable=false;
            this.selectedCount = 0;
            this.searchQuery="";
            await this.getTeamMembers();
            await this.$emit('openSwapJudgesModal', false);
        },
    },
};
</script>
<style scoped>
.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
}

.custom-control-input {
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #1983bf;
    background-color: #1983bf;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #e9ecef;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

/* .custom-control-input:disabled ~ .custom-control-label::before {
    
     color: #e4e4e4 !important;
     border-color: #e4e4e4 !important;
    background-color: #e4e4e4 !important;
  } */
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-label:indeterminate+label {
    background: lime;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #1983bf;
    background-color: #1983bf;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-control-label::before {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before {
        transition: none;
    }
}

.checklist .custom-control-input input:checked+.checklist-strikethrough {
    width: 100%;
}

.checklist .custom-checkbox input:checked>label {
    opacity: 0.7;
}

.-w-100 {
    width: calc(100% - 140px);
}

.checklist-strikethrough {
    height: 40px;
    /* background: linear-gradient(90deg, #5982ff 0%, rgb(150, 175, 250) 100%); */
    /* background: #f1f1f1; */
    border-radius: 2px 3px 2px 2px;
    mix-blend-mode: multiply;
    /* -webkit-transform: skewX(-10deg); */
    /* transform: skewX(-10deg); */
    /* opacity: 0.3; */
    width: 0;
    transition: width 0.35s ease-in-out, opacity 0.25s ease-in-out;
    position: absolute;
    margin-left: -51px;
    top: 0;
    pointer-events: none;
}

.custom-checkbox-label {
    background: inherit;
    width: 100%;
}

.custom-checkbox-label:focus {
    outline: none;
}

.fluent-Progress-bar-normal {
    background-color: #1983bf !important;
    height: 8px;
}

.checklist .custom-control-input input[type="checkbox"]:checked+p+div {
    background: green;
    width: 100%;
}

/* .checklist .custom-control-input input[type="checkbox"]:checked + div {
    background: blue;
    width: 100%;
  } */

.numberCircle {
    float: right;
    font-size: 12px;
    color: white;
    border-radius: 1rem;
    padding: 2px 10px;
    width: 34px;
    text-align: center;
    background-color: #1983bf;
}
input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}
</style>
  