<template>
  <div class="clear-both">
    <div>
      <button
        @click="checkSubmissionStatus"
        class="
          inline-flex
          items-center
          h-8
          px-3
          text-gray-700
          border border-gray-400
          transition-colors
          duration-150
          rounded-lg
          focus:shadow-outline
          hover:bg-gray-200
        "
      >
        <attachment-icon />
        <span>Attachment</span>
      </button>
      <p class="text-xs text-gray-500">(.png, .jpg or .jpeg image only)</p>
    </div>
    <dashboard-modal
      :uppy="uppy"
      :open="isUppyOpen"
      :props="{
        trigger: '#uppy-trigger',
        onRequestCloseModal: handleClose,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
      }"
    />
  </div>
</template>
<script>
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/vue";
import Tus from "@uppy/tus";
import { mapGetters } from "vuex";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import AttachmentIcon from "../components/Icon/AttachmentIcon.vue";

export default {
  props: {
    maxFileSizeInBytes: Number,
    accessToken: String,
    attachments: Array,
    metaField: String,
  },
  data() {
    return {
      previewPath: null,
      isUppyOpen: false,
      disabled: true,
    };
  },
  computed: {
    ...mapGetters(["user", "enableSubmission"]),
  },
  components: {
    DashboardModal,
    AttachmentIcon,
  },
  beforeDestroy() {
    this.resetUploader();
    this.uppy.close();
  },
  created() {
    this.instantiateUppy();
  },
  methods: {
    async instantiateUppy() {
      this.$emit("changeTodo");
      this.uppy = Uppy({
        debug: false,
        autoProceed: false,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          allowedFileTypes: ["image/png", "image/jpg", "image/jpeg"],
        },
        meta: { accountId: this.user.accountId },
      }).use(Tus, {
        headers: {
          Authorization: this.accessToken,
        },
        removeFingerprintOnSuccess: true,
        endpoint: `${process.env.VUE_APP_FILEUPLOADURL}/files/`,
      });

      this.uppy.on("complete", async (event) => {
        this.$emit("changeTodo");
        if (event.successful[0] !== undefined) {
          // let attachments = event.successful ? event.successful : [];
          for (let index = 0; index < event.successful.length; index++) {
            const element = event.successful[index];
            let document = {};
            document.documentName = `${element.name}.${this.metaField}`;
            let documentNameFromS3 = element.uploadURL.split("/").pop();
            document.documentId = documentNameFromS3.split(".").slice(0, -1)[0];
            document.uploadedBy = this.user.aspenifyUserId;
            document.uploadedAt = new Date();
            this.attachments.unshift(document);
          }
          this.$emit("fileUploaded", this.attachments);
          this.disabled = false;
        }
      });
    },
    handleClose() {
      this.isUppyOpen = false;
      this.uppy.reset();
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;

      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
    confirmUpload() {},
    async checkSubmissionStatus() {
      await this.$store.dispatch('checkSubmissionEnableStatus');
      if(this.enableSubmission){
        this.isUppyOpen = !this.isUppyOpen
      } else {
        alert("“Sorry! The submission window has closed and edits are no longer permitted. Thank you!”");
      }
    }
  },
};
</script>

<style scoped>
</style>