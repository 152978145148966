<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-80
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white rounded shadow-lg w-6/12 text-gray-600">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <h3 class="font-medium text-white text-lg w-11/12 break-words">
            <b>{{ initiative.phase }}</b> - {{ initiative.name }}
          </h3>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('endJudgingModal')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div class="flex">
          <div
            style="height: calc(100vh - 150px); overflow: auto"
            class="px-5 flex-col w-full"
          >
            <!-- <pre class="text-xs"> {{ user }}</pre>-->
            <!--<pre class="text-xs"> {{ selectedIdea }}</pre> -->
            <div
              class="
                mt-3
                border-b border-gray-200
                inline-block
                float-left
                w-full
                pb-5
              "
            >
           
              <!-- <pre>{{selectedIdea}}</pre> -->
              <ReviewForm
                :selectedIdea="initiative?.subInitiatives[initiative?.subInitiatives.length - 1]"
                :initiative="initiative" 
                :ideaPhaseIndex="initiative?.phaseIndex" 
                :user="user"
                from="progress"
                :status="status"
              />
            </div>
            <feedback-history
              :selectedIdea="selectedIdea"
              :initiative="initiative"
              :user="user"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import FeedbackHistory from "../JudgingPanel/FeedbackHistory.vue";
import ReviewForm from "../JudgingPanel/ReviewForm.vue";
export default {
  name: "JudgingEnd",
  components: {
    FeedbackHistory,
    ReviewForm,
  },
  props: {
    phaseIndex: Number,
    initiative: Object,
    selectedIdea: Object,
    initiative: Object,
    user: Object,
    status: Number,
  },
  emits: ["endJudging"],
  data() {
    return {};
  },
  methods: {
    // async updateReview(feedback) {
    //   let updateFeedback = feedback;
    //   updateFeedback.date = new Date();
    //   updateFeedback.updatedBy = this.user.aspenifyUserId;
    //   updateFeedback.phase = this.selectedIdea.phase;
    //   updateFeedback.phaseIndex = this.selectedIdea.phaseIndex;

    //   let payload = this.selectedIdea;
    //   payload.id = this.selectedIdea?._id;
    //   payload._id = this.selectedIdea?._id;
    //   payload.metaData = updateFeedback;

    //   if (updateFeedback?.bogFramework?.length !== 0) {
    //     let data = {
    //       id: this.initiative?._id,
    //       data: {
    //         key: "metaData.bogFramework",
    //         value: feedback?.bogFramework,
    //       },
    //     };
    //     await this.$store.dispatch("updateInitiativeById", data);
    //   }

      

    //   if (feedback.actionType === "save") {
    //     let payload = this.selectedIdea;
    //     payload.metaData = updateFeedback;
    //     await this.$store.dispatch("updateInitiativeStatus", payload);
    //     await this.$store.dispatch("getInitiativesByPhase");
    //   } else if (feedback.actionType === "end") {
    //     if (feedback.type === "Get More Input") {
    //       alert("Get More Input");
    //     } else if (feedback.type === "Promote") {
    //       let payObj = {};
    //       payObj.initiative = this.initiative;
    //       payObj.phaseIndex = this.initiative?.phaseIndex;
    //       payObj.comment = "";
    //       payObj.isCloseFlag = false;
    //       console.log({ payObj });
    //       await this.$store.dispatch("promoteInitiative", payObj);
    //       payload.status = 3;
    //       await this.$store.dispatch("updateInitiativeStatus", payload);
    //     } else if (feedback.type === "Move To Validate") {
    //       let payObj = {};
    //       payObj.initiative = this.selectedIdea?.parentInitiativeId;
    //       payObj.phaseIndex = this.selectedIdea?.phaseIndex;
    //       payObj.comment = "";
    //       payObj.isCloseFlag = false;

    //       // if(this.selectedIdea?.phaseIndex ===0){
    //       await this.$store.dispatch("promoteInitiative", payObj);
    //       // }
    //       // else{
    //       // for (let i = 0; i < this.selectedIdea?.phaseIndex-1; i++) {
    //       // await this.$store.dispatch("unpromoteInitiative", {...payObj, phaseIndex:i});
    //       // }
    //       // }
    //       // alert("Move To Validate");
    //     } else if (feedback.type === "Move to Validate(and do open Mic)") {
    //       alert("Move to Validate(and do open Mic)");
    //     } else if (feedback.type === "Get More Input(through open Mic)") {
    //       alert("Get More Input(through open Mic)");
    //     } else if (feedback.type === "Get More Input(ask team)") {
    //       alert("Get More Input(ask team)");
    //     } else if (feedback.type === "Recommend to BE/ Function") {
    //       alert("Recommend to BE/ Function");
    //     } else if (feedback.type === "Move to Inactive(and merge)") {
    //       alert("Move to Inactive(and merge)");
    //     } else if (feedback.type === "Move to Inactive") {
    //       // alert("Move to Inactive");
    //       // payload.status = 6;
    //       await this.$store.dispatch("updateInitiativeStatus", payload);
    //       let init = this.initiative;
    //       init.id = this.initiative._id;
    //       let obj = {
    //         selectedInitiative: init,
    //         comment: feedback.comment,
    //       };

    //       await this.$store.dispatch("inactivateIdeaJudgingIdea", obj);
    //     } else {
    //       // alert("Else -------------------------------");
    //     }
    //     await this.$store.dispatch("getInitiativesByPhase");
    //   }

    //   // let updateFeedback = payload;
    //   // updateFeedback.date = new Date();
    //   // updateFeedback.updatedBy = "manager";

    //   // let pay = this.selectedIdea;
    //   // pay.id = this.selectedIdea._id;
    //   // pay.metaData = updateFeedback;
    //   // pay.status = this.status;
    //   // this.$store.dispatch("updateInitiativeStatus", pay);
    //   this.$emit("endJudgingModal");
    // },
  },
  computed: {},
  mounted: function () {},
  created: function () {},
};
</script>
<style scoped>
</style>