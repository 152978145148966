<template>
  <div
    class="card shadow-sm bg-white rounded-lg hover:shadow-lg border-l-4"
    :style="{
      'border-color': categoryColor,
    }"
    :class="{ 'bg-gray-300': initiative.status === 6 }"
  >
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        :style="{
          width: (initiative.completed / initiative.totalTasks) * 100 + '%',
        }"
      ></div>
    </div>
    <div
      class="p-4 cursor-pointer"
      @click.self="$emit('selectInitiative', initiative._id)"
    >
      <div
        class="
          card-title
          text-gray-700
          mb-5
          leading-none
          flex
          justify-between
          w-full
          relative
          break-words
        "
      >
        <div
          style="width: inherit"
          class="line-clamp-2"
          @click.self="$emit('selectInitiative', initiative?._id)"
        >
          {{ initiative.name }}
        </div>
        <!-- <pre>{{initiative.name   +"  ----" + initiative.status}}</pre> -->
        <div
          class="relative inline-block text-left dropdown"
          v-if="isViewAccess"
        >
          <span class="rounded-md shadow-sm">
            <button
              class="
                inline-flex
                justify-center
                w-full
                font-medium
                leading-5
                text-gray-700
                transition
                duration-150
                ease-in-out
                bg-white
                hover:text-gray-400
                focus:outline-none
                focus:border-blue-300
                focus:shadow-outline-blue
                active:bg-gray-50 active:text-gray-800
              "
              :class="{ 'bg-gray-300': initiative.status === 6 }"
              type="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                />
              </svg>
            </button>
          </span>
          <div
            class="
              opacity-0
              invisible
              dropdown-menu
              transition-all
              duration-300
              transform
              origin-top-right
              -translate-y-2
              scale-95
              relative
              z-20
            "
          >
            <div
              class="
                absolute
                right-0
                w-48
                p-2
                mt-2
                origin-top-right
                bg-white
                border border-gray-200
                divide-y divide-gray-100
                rounded-md
                shadow-lg
                outline-none
              "
              aria-labelledby="headlessui-menu-button-1"
              role="menu"
            >
              <!-- <button
                v-if="isJudgingStarted && initiative.status !== 6 && initiative.phaseSelectedJudgesTeamId"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openJudgingModal(true)"
              >
                Start Judging Process
              </button> -->
              <!-- <button
                v-if="!isJudgingStarted && initiative.status !== 6"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openCancelJudgingModal(true)"
              > -->
                <!-- :disabled="isThreeReview" -->
                <!-- Cancel Judging Process
              </button> -->
              <!-- <button
                v-if="!isJudgingStarted && initiative.status !== 6"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="endJudgingModal({ open: true, status: 3 })"
              > -->
                <!-- :disabled="isThreeReview" -->
                <!-- End Judging Process
              </button> -->
              <button
                v-if="!isLastPhase && initiative.status !== 6 && allActivitiesChecked && checkJudgesAssigned()"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openPromoteModal(true)"
              >
                Promote
              </button>
              <button
                v-if="!isLastPhase && initiative.status !== 6 && (!allActivitiesChecked || !checkJudgesAssigned())"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openJudgingInfoModel(true)"
              >
                Promote
              </button>

              <button
                v-if="phaseIndex != 0 && initiative.status !== 6"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openUnpromoteModal(true)"
              >
                Unpromote
              </button>
              <!-- <button
                v-if="isLastPhase && initiative.status !== 6"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openCloseModal(true)"
              >
                Close
              </button> -->
              <!-- <button
                :disabled="!isJudgingEnded"
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openArchiveModal(true)"
              >
                Archive
              </button> -->
              <button
                href="javascript:void(0)"
                class="
                  w-full
                  text-left
                  block
                  px-4
                  py-2
                  text-sm text-gray-800
                  hover:bg-gray-100
                  disabled:opacity-30
                "
                @click="openArchiveModal(true)"
              >
                {{
                  initiative.status === 6
                    ? "Mark as Active"
                    : "Mark as Inactive"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="initiative.metaData"
        class="flex"
        @click="$emit('selectInitiative', initiative._id)"
      >
        <div class="flex-1" v-if="totalMembers?.length !== 0">
          <!-- <div class="flex-grow">
            <img
              v-for="(item, index) in totalMembers.slice(0, 3)"
              :key="index"
              class="
                inline-block
                w-8
                h-8
                object-cover
                rounded-full
                ring-1 ring-white
              "
              :class="{ '-mr-1': totalMembers.length > 1 }"
              :alt="item.name"
              :src="item.image"
              :title="item.name"
            />
            <div
              v-if="totalMembers.length - 3 > 0"
              class="
                inline-block
                rounded-full
                w-8
                h-8
                bg-gray-500
                p-0
                border-0
                inline-flex
                items-center
                justify-center
                text-gray-500
              "
            >
              <p
                class="
                  sm:text-base
                  text-base
                  font-medium
                  title-font
                  text-gray-200
                "
              >
                +{{ totalMembers.length - 3 }}
              </p>
            </div>
          </div> -->
          <avatar-grouping
            position="right"
            :dataObj="initiative?.metaData"
          />

          <!-- <div class="flex-1 float-right text-right"> -->
            <!-- {{ initiative?.subInitiatives[0]?.metaData?.actionType }} -->
            <!-- {{ initiative }} -->
            <!-- <status-track
              v-if="initiative?.subInitiatives"
              :initiative="initiative"
              :teams="
                initiative?.subInitiatives[
                  initiative?.subInitiatives?.length - 1
                ]?.activities[0]?.activities
              "
            />
          </div> -->
        </div>
        <div v-if="initiative?.phaseInfo?.metaData?.isJudgingRequired" class="flex-1">
            <div class="text-sm line-height">Review Status</div>
          <!-- <div class="text-sm"></div> -->
          <div class="font-semibold line-height text-indigo-500">
              {{this.getCounts()}}
              <!-- {{initiative?.subInitiatives[0]?.metaData?.reviews?.length?initiative?.subInitiatives[0]?.metaData?.reviews?.length:0}}/{{initiative?.seletedPhaseJudgesDetails?.members?.length}} -->
          </div>
            <!-- <div class="flex-1 text-gray-500 text-right">{{initiative?.subInitiatives[0]?.metaData?.reviews?.length?initiative?.subInitiatives[0]?.metaData?.reviews?.length:0}}/{{initiative?.seletedPhaseJudgesDetails?.members?.length}}</div> -->
        </div>
        <!-- <div v-if="initiative.metaData.mentors" class="flex">
          <img
            v-for="(item, index) in initiative.metaData.mentors.slice(0, 5)"
            :key="index"
            class="w-7 h-7 object-cover rounded-full ring-1 ring-white -mr-1"
            :alt="item.name"
            :src="item.image"
          />
        </div> -->
        <input
            v-show="onManageClicked"
            class="mr-2"
            :disabled="checkBoxDisabled"
            type="checkbox"
            @click.stop=""
            @change="onChangeCheckboxForInitiative"
            :id="initiative._id"
            v-model="checkBoxValue"
          />
      </div>
      <div
        v-if="initiative.metaData"
        class="
          text-center text-gray-500
          mt-3
          leading-none
          flex
          justify-between
          w-full
        "
        @click="$emit('selectInitiative', initiative._id)"
      >
        <div class="mr-3 flex items-center leading-none py-1">
          <div class="flex mr-2">
            <!-- watch svg -->
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              />
            </svg> -->

            <!-- calender svg -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              />
            </svg>
            <div
              class="float-center text-center"
              v-tooltip="{ theme: { placement: 'right', width: '200px' } }"
            >
              <span
                v-tooltip="{ text: calculateDays.dateInfo }"
                class="mt-0.5 ml-1"
              >
                {{ calculateDays.diffDays }}
              </span>
            </div>
          </div>

          <!-- if integration present, show jira Icon -->
          <div class="mr-2" v-if="initiative.isSmartStage">
            <!-- <svg
              class="fill-current"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z"
              />
            </svg> -->
            <span
              ><img
                src="@/assets/images/jiraIcon.jpeg"
                alt="My profile"
                class="w-5 h-5 rounded-full"
              />
            </span>
          </div>

          <div class="flex">
            <svg
              class="svgIcon fill-current"
              width="24"
              height="24"
              version="1.1"
              viewBox="0 0 700 700"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m266.88 463.23c0 18.754-10.008 36.086-26.25 45.465s-36.258 9.3789-52.5 0-26.25-26.711-26.25-45.465c0-18.758 10.008-36.09 26.25-45.469 16.242-9.3789 36.258-9.3789 52.5 0 16.242 9.3789 26.25 26.711 26.25 45.469zm52.5-297.5v35h227.5v-35zm0 175h227.5v-35h-227.5zm0 140h227.5v-35h-227.5zm-105-210c-18.914 0-36.391 10.09-45.844 26.469-9.457 16.379-9.457 36.559 0 52.938 9.4531 16.379 26.93 26.469 45.844 26.469 18.977-0.09375 36.461-10.305 45.867-26.785 9.4062-16.48 9.3047-36.727-0.26563-53.113-9.5664-16.387-27.152-26.422-46.125-26.328zm0-63.352-47.602-47.773c-7.8789 16.27-6.832 35.449 2.7656 50.766 9.6016 15.316 26.41 24.617 44.484 24.617 18.078 0 34.887-9.3008 44.484-24.617 9.6016-15.316 10.648-34.496 2.7656-50.766zm-37.102-61.949c10.148 10.324 26.773 26.426 37.273 36.926l113.05-112.35-24.676-25.551-86.102 86.102c-1.5039-0.26172-3.043-0.26172-4.5508 0-8.2227-8.0508-25.547-25.551-33.949-33.777l-24.676 25.727c5.25 4.375 18.375 17.5 23.625 22.926z"
              />
            </svg>
            <span class="mt-0.5 ml-1"
              >{{ initiative.completed }}/{{ initiative.totalTasks }}</span
            >
          </div>
        </div>
        <div class="flex items-center leading-none capitalize text-sm">
          <span>
            <div>
              <span
                class="
                  inline-block
                  px-2
                  py-1
                  leading-none
                  border border-blue-500
                  text-blue-500
                  rounded-full
                  font-semibold
                  text-xs
                "
                >{{ initiative.metaData.challengeType }}</span
              >
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <promote-confirm
    :openPromote="openPromote"
    @openPromoteModal="openPromoteModal"
    @promote="promote"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></promote-confirm>
  <unpromote-confirm
    :openUnpromote="openUnpromote"
    @openUnpromoteModal="openUnpromoteModal"
    @unpromote="unpromote"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></unpromote-confirm>
  <judging-confirm
    :openJudging="openJudging"
    @openJudgingModal="openJudgingModal"
    @judging="judging"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></judging-confirm>
  <judging-info
    :openJudgingInfo="openJudgingInfo"
    @openJudgingInfoModel="openJudgingInfoModel"
    @judging="judging"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
    :headerText="headerText"
    :infoText="infoText"
    :footerText="footerText"
  ></judging-info>
  <judging-cancel
    :openCancelJudging="openCancelJudging"
    @openCancelJudgingModal="openCancelJudgingModal"
    @canceljudging="canceljudging"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></judging-cancel>
  <judging-end
    v-if="endJudgingFlag?.open"
    :status="endJudgingFlag?.status"
    @endJudgingModal="endJudgingModal"
    :initiative="initiative"
    :user="user"
    :phaseIndex="phaseIndex"
    :selectedIdea="
      initiative?.subInitiatives[initiative?.subInitiatives?.length - 1]
    "
  ></judging-end>
  <!-- <archive-confirm
    :openArchive="openArchive"
    @openArchiveModal="openArchiveModal"
    @archive="archive"
    :initiative="initiative"
  ></archive-confirm> -->

  <inactive-confirm
    :openInactive="openArchive"
    @openInactiveModal="openArchiveModal"
    @inactive="inactive"
    :selectedInitiative="initiative"
    :markType="markType"
  ></inactive-confirm>

  <closeConfirm
    :openClose="openClose"
    @openCloseModal="openCloseModal"
    @close="close"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></closeConfirm>

  <!-- <info-modal
    :openInfo="openInfo"
    :headerText="headerText"
    :infoText="infoText"
    @openInfoModal="openInfoModal"
    @close="close"
    :initiative="initiative"
    :phaseIndex="phaseIndex"
  ></info-modal> -->

</template>
<script>
import * as timeago from "timeago.js";
import PromoteConfirm from "../confirmationModal/PromoteConfirm.vue";
import UnpromoteConfirm from "../confirmationModal/UnpromoteConfirm.vue";
import JudgingConfirm from "../confirmationModal/JudgingConfirm.vue";
import JudgingCancel from "../confirmationModal/JudgingCancel.vue";
import JudgingInfo from "../confirmationModal/JudgingInfo.vue";
import JudgingEnd from "../confirmationModal/JudgingEnd.vue";
import ArchiveConfirm from "../confirmationModal/ArchiveConfirm.vue";
import CloseConfirm from "../confirmationModal/CloseConfirm.vue";
// import InfoModal from "../confirmationModal/InfoModal.vue";
import StatusTrack from "../statusTrack.vue";
import AvatarGrouping from "../AvatarGrouping.vue";
import InactiveConfirm from "../../components/confirmationModal/InactiveConfirm.vue";

export default {
  components: {
    PromoteConfirm,
    UnpromoteConfirm,
    ArchiveConfirm,
    JudgingConfirm,
    JudgingCancel,
    JudgingInfo,
    JudgingEnd,
    CloseConfirm,
    StatusTrack,
    AvatarGrouping,
    InactiveConfirm,
    // InfoModal
  },
  name: "InitiativeCard",
  props: {
    initiative: Object,
    phaseIndex: Number,
    isLastPhase: Boolean,
    selectedInitiative: Object,
    user: Object,
    checkBoxDisabled: Boolean,
    isChecked: Boolean,
    onManageClicked:Boolean
  },
  emits: [
    "selectInitiative",
    "promote",
    "unpromote",
    "archive",
    "close",
    "judging",
    "canceljudging",
    "endJudging",
  ],
  data() {
    return {
      openPromote: false,
      openUnpromote: false,
      openArchive: false,
      openClose: false,
      openInfo: false,
      openJudgingInfo:false,
      openJudging: false,
      openCancelJudging: false,
      endJudgingFlag: { open: false },
      timeago,
      headerText: "",
      infoText: "",
      footerText:"",
      allActivitiesChecked: false,
      markType: "",
      checkBoxValue: false,
    };
  },
  mounted:function(){
    this.checkBoxValue=this.isChecked
  },
  watch: {
    isChecked: function (newValue, oldValue) {
      this.checkBoxValue=newValue
    },
  },
  methods: {
    checkJudgesAssigned(){
      if(this.initiative?.nextPhaseInfo?.metaData?.isJudgingRequired){
        if(!this.initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    openPromoteModal(value) {
      this.openPromote = value;
    },
    getCounts(){
      if(this.initiative?.subInitiatives?.length>0){
        for(let subIdea of this.initiative.subInitiatives){
          if(subIdea.teamId == this.initiative?.seletedPhaseJudgesDetails?._id){
            return `${subIdea?.metaData?.reviews?.length>0?subIdea.metaData.reviews.length:0}/${this.initiative?.seletedPhaseJudgesDetails?.members?.length}`
          }
        }
      }
    },
    openUnpromoteModal(value) {
      this.openUnpromote = value;
    },
    openArchiveModal(value) {
      this.openArchive = value;
      if(this.initiative?.status && this.initiative.status == 1 || this.initiative.status == 2){
        this.markType = 'inactive';
      } else if(this.initiative?.status && this.initiative.status == 6){
        this.markType = 'active';
      } else {
        this.markType = "";
      }
    },
    openJudgingModal(value) {
      this.openJudging = value;
    },
    openJudgingInfoModel(value) {
      if(!this.checkJudgesAssigned() && !this.allActivitiesChecked){
        this.headerText = `Error`
        this.infoText = `Please select judges for ${this.initiative?.nextPhaseInfo?.name} and select all activities before promoting`;
        this.footerText = ``;
      } else if(!this.checkJudgesAssigned()) {
        this.headerText = `Error`
        this.infoText = `Please select judges for ${this.initiative?.nextPhaseInfo?.name} before promoting`;
        this.footerText = ``;
      } else {
        this.headerText = `Error`
        this.infoText = `Please select all activities before promoting`;
        this.footerText = ``;
      }
      this.openJudgingInfo = value;
    },
    openCancelJudgingModal(value) {
      this.openCancelJudging = value;
    },
    endJudgingModal(value) {
      this.endJudgingFlag = value;
    },
    openCloseModal(value) {
      this.openClose = value;
    },
    promote(initiative) {
      this.$emit("promote", initiative);
    },
    unpromote(initiative) {
      this.$emit("unpromote", initiative);
      this.openUnpromoteModal(false);
    },
    judging(initiative) {
      this.$emit("judging", initiative);
      this.openJudgingModal(false);
    },
    canceljudging(initiative) {
      this.$emit("canceljudging", initiative);
      this.openCancelJudgingModal(false);
    },

    // archive(initiative) {
    //   this.$emit("archive", initiative);
    // },
    async inactive(data) {
      data.selectedInitiative.id = data.selectedInitiative._id;
      if (data.selectedInitiative.status === 1) {
        data.status = 6;
        let payload = {
          _id: data.selectedInitiative._id,
          metaData: {
            isMerged: false,
            actionLabel: "active",
            comment: data.comment,
            date: new Date(),
            type: "active",
            updatedBy: this.user.aspenifyUserId,
          },
        };
        await this.$store.dispatch("updateInitiativeMeta", payload);
      }
      if (data.selectedInitiative.status === 6) {
        data.status = 1;
        let payload = {
          _id: data.selectedInitiative._id,
          metaData: {
            isMerged: false,
            actionLabel: "inactive",
            comment: data.comment,
            date: new Date(),
            type: "inactive",
            updatedBy: this.user.aspenifyUserId,
          },
        };
        await this.$store.dispatch("updateInitiativeMeta", payload);
      }
      await this.$store.dispatch("inactivateIdeaMainIdea", data);
      this.openArchive = false;
      await this.$emit("getByPhaseDetails");
      // await this.$emit("getByPhaseDetails");
      // await this.$store.dispatch("getInitiativesByPhase",payload);
    },
    close(initiative) {
      this.$emit("close", initiative);
    },
    onChangeCheckboxForInitiative(){
      this.$nextTick(() => {
        this.$emit('onChangeCheckboxForInitiative',this.initiative,this.checkBoxValue,this.phaseIndex)
      })
    }
  },
  computed: {
    isViewAccess() {
      return this.user?.roles.includes("manager");
    },
    categoryColor() {
      //"#00AB50", "#107AA3", "#FC9D27"
      let color = "";
      switch (this.initiative?.metaData.challengeType) {
        case "Strategic Impact":
          color = "#00AB50";
          break;
        case "Market Impact":
          color = "#107AA3";
          // code block
          break;
        case "Velocity Impact":
          color = "#FC9D27";
          // code block
          break;
        default:
        // code block
      }
      return color;
    },
    totalMembers() {
      let users = [];
      if (this.initiative?.metaData?.teamMembers) {
        users = [...this.initiative?.metaData?.teamMembers];
      }
      if (this.initiative?.metaData?.mentors) {
        users = [...users, this.initiative?.metaData?.mentors];
      }
      return users;
    },
    // isJudgingNotStard() {
    //   return (
    //     this.initiative.subInitiatives &&
    //     this.initiative.subInitiatives.length === 0
    //   );
    // },
    isJudgingStarted() {
      return (
        !this.initiative?.metaData?.action ||
        this.initiative?.metaData?.action?.actionType === "end" ||
        this.initiative?.metaData?.action?.actionType === "cancel"
      );
    },

    isThreeReview() {
      if (
        this.initiative &&
        this.initiative?.subInitiatives &&
        this.initiative?.subInitiatives.length !== 0 &&
        this.initiative?.subInitiatives[this.initiative?.subInitiatives - 1]
          ?.activities &&
        this.initiative?.subInitiatives[this.initiative?.subInitiatives - 1]
          ?.activities.length !== 0 &&
        this.initiative?.subInitiatives[this.initiative?.subInitiatives - 1]
          ?.activities[0]?.activities
      ) {
        return (
          this.initiative?.subInitiatives[
            this.initiative?.subInitiatives - 1
          ]?.activities[0]?.activities.filter((f) => f.metaData).length < 3
        );
      } else {
        return false;
      }
    },
    calculateDays() {
      let daysData = {};
      let formattedDate;
      let phaseActualStartDate =
        this.initiative.activities[this.phaseIndex].actualStartDate;
      let phaseName = this.initiative.activities[this.phaseIndex].name;
      let date1 = new Date(phaseActualStartDate).setUTCHours(0, 0, 0, 0);
      let date2 = new Date().setUTCHours(0, 0, 0, 0);
      daysData.diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      if (phaseActualStartDate) {
        formattedDate = new Date(phaseActualStartDate)
          .toLocaleDateString()
          .substring(0, 10);
      }
      if (!daysData.diffDays) {
        daysData.diffDays = 0;
        formattedDate = new Date().toLocaleDateString().substring(0, 10);
      }
      daysData.dateInfo = `This Submission is in ${phaseName} since ${formattedDate}.`;
      return daysData;
    },
  },
  created: function () {
    if(this.initiative.completed == this.initiative.totalTasks){
      this.allActivitiesChecked = true;
    }
  },
};
</script>
<style scoped>
.progress {
  display: flex;
  height: 0.375rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #dee2e6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1983bf;
  transition: width 0.6s ease;
}

.progress1 {
  display: flex;
  height: 0.55rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #dee2e6;
  border-radius: 0.5rem;
}

.progress-bar1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #65A765;
  transition: width 0.6s ease;
}

.card-title {
  font-size: 1rem;
  font-weight: 600;
}
.phase-title {
  font-size: 1.25rem;
}
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
.numberCircle {
  float: right;
  font-size: 12px;
  color: white;
  border-radius: 1rem;
  padding: 1px 4px;
  width: 34px;
  text-align: center;
  background-color: #1983bf;
}
.line-height{
  line-height: 1rem;
}
</style>