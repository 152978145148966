const state = {
  loadingFlag: false,
  initiativePromoteInAction: false,
  initiativeUnpromoteInAction: false,
  initiativeEventLoading: false,
  addNoteFlag: false,
  logoutFlag: false,
  autoInitiativeInAction: false
}

const mutations = {
  SET_LOADING_FLAG(state, newLoadigStatus) {
    state.loadingFlag = newLoadigStatus;
  },
  SET_PROMOTE_IN_ACTION_FLAG(state, newInitiativePromoteInAction) {
    state.initiativePromoteInAction = newInitiativePromoteInAction;
  },
  SET_UNPROMOTE_IN_ACTION_FLAG(state, newInitiativeUnpromoteInAction) {
    state.initiativeUnpromoteInAction = newInitiativeUnpromoteInAction;
  },
  SET_AUTO_INITIATIVE_ACTION_FLAG(state, newAutoInitiativeInAction) {
    state.autoInitiativeInAction = newAutoInitiativeInAction;
  },
  SET_ADD_NOTE_FLAG(state, newValue) {
    state.addNoteFlag = newValue;
  },
  SET_LOGOUT_FLAG(state, newValue) {
    state.logoutFlag = newValue;
  },
  SET_INITIATIVE_EVENTS_LOADING_FLAG(state, newValue) {
    state.initiativeEventLoading = newValue;
  }
}

const actions = {
  setLoadingFlag(context, flagValue) {
    context.commit('SET_LOADING_FLAG', flagValue);
  },
  setPromoteInActionFlag(context, flagValue) {
    context.commit('SET_PROMOTE_IN_ACTION_FLAG', flagValue);
  },
  setAutoInitativeInActionFlag(context, flagValue) {
    context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', flagValue);
  },
  setAddNoteFlag(context, flagValue) {
    context.commit('SET_ADD_NOTE_FLAG', flagValue);
  },
  setLogoutFlag(context, flagValue) {
    context.commit('SET_LOGOUT_FLAG', flagValue);
  },
  setInitiativeEventsLoading(context, flagValue) {
    context.commit('SET_INITIATIVE_EVENTS_LOADING_FLAG', flagValue);
  }
}

const getters = {
  loadingFlag: (state) => state.loadingFlag,
  initiativePromoteInAction: (state) => state.initiativePromoteInAction,
  initiativeUnpromoteInAction: (state) =>  state.initiativeUnpromoteInAction,
  autoInitiativeInAction: (state) =>  state.autoInitiativeInAction,
  addNoteFlag: (state) => state.addNoteFlag,
  logoutFlag: (state) => state.logoutFlag,
  initiativeEventLoading: (state) => state.initiativeEventLoading
}

const LoadingFlagModule = {
  state,
  mutations,
  actions,
  getters
}

export default LoadingFlagModule;