<template>

  <!-- <div style="display:none;"
    class="newtab"
    @click.prevent="$emit('cardClickNewtab', item)"
    v-tooltip="{
      text: 'Open Submission in New Tab',
      theme: {
        placement: 'left',
      },
    }"
  >

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" x="0px" y="0px">
      <g data-name="Layer 2"><path d="M32.923,1.618A1,1,0,0,0,32,1H20a1,1,0,0,0,0,2h9.586L16.293,16.293a1,1,0,1,0,1.414,1.414L31,4.414V14a1,1,0,0,0,2,0V2A1.01,1.01,0,0,0,32.923,1.618Z"/><path d="M28,15a1,1,0,0,0-1,1V28.856A2.146,2.146,0,0,1,24.856,31H5.144A2.146,2.146,0,0,1,3,28.856V9.144A2.146,2.146,0,0,1,5.144,7H18a1,1,0,0,0,0-2H5.144A4.148,4.148,0,0,0,1,9.144V28.856A4.148,4.148,0,0,0,5.144,33H24.856A4.148,4.148,0,0,0,29,28.856V16A1,1,0,0,0,28,15Z"/></g>
    </svg>

  </div> -->

</template>
<script>
import LikeFallowShare from "./LikeFallowShare.vue";
import AvatarGrouping from "./AvatarGrouping.vue";
import ActionSignUpIcon from "./ActionSignUpIcon.vue";
export default {
  data() {
    return {
      showType: "",
    };
  },
  components: {
    LikeFallowShare,
    AvatarGrouping,
    ActionSignUpIcon,
  },
  props: {
    item: Object,
    user: Object,
  },
  methods: {
    showTypeOver: function (item) {
      this.showType = item;
    },
    showTypeOut: function (item) {
      this.showType = null;
    },
  },
};
</script>

<style scoped>
.newtab {
  margin-top: 0.3rem;
  height: 16px;
  width: 16px;
  position: absolute;
  float: right;
  right: 1rem;
}
</style>

