<template>
  <div>
    <button
      @click="isUppyOpen = !isUppyOpen"
      class="
        bg-blue-500
        text-white
        hover:text-white
        py-1
        px-2
        border border-blue-500
        hover:border-transparent
        rounded
        text-sm
        font-semibold
      "
    >
      Upload File
    </button>

    <dashboard-modal
      :uppy="uppy"
      :open="isUppyOpen"
      :props="{
        trigger: '#uppy-trigger',
        onRequestCloseModal: handleClose,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
      }"
    />
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/vue";
import Tus from "@uppy/tus";
import { mapGetters } from "vuex";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    maxFileSizeInBytes: Number,
    accessToken: String,
    attachments: Array,
  },
  data() {
    return {
      previewPath: null,
      isUppyOpen: false,
      disabled: true,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    DashboardModal,
  },
  beforeDestroy() {
    this.resetUploader();
    this.uppy.close();
  },
  created() {
    this.instantiateUppy();
  },
  methods: {
    async instantiateUppy() {
      this.$emit("changeTodo");
      this.uppy = Uppy({
        debug: false,
        autoProceed: false,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 10,
          allowedFileTypes: [
            "image/png",
            "image/jpg",
            "image/jpeg",
          ],
        },
        meta: { accountId: this.user.accountId },
      }).use(Tus, {
        headers: {
          Authorization: this.accessToken,
        },
        removeFingerprintOnSuccess: true,
        endpoint: `${process.env.VUE_APP_FILEUPLOADURL}/files/`,
      });

      this.uppy.on("complete", async (event) => {
        this.$emit("changeTodo");
        if (event.successful[0] !== undefined) {
          // let attachments = event.successful ? event.successful : [];
          for (let index = 0; index < event.successful.length; index++) {
            const element = event.successful[index];
            let document = {};
            document.documentName = element.name;
            let documentNameFromS3 = element.uploadURL.split("/").pop();
            document.documentId = documentNameFromS3.split(".").slice(0, -1)[0];
            document.uploadedBy = this.user.aspenifyUserId;
            document.uploadedAt = new Date();
            this.attachments.unshift(document);
          }
          this.$emit("fileUploaded", this.attachments);
          this.disabled = false;
          this.attachments = [];
        }
      });
    },
    handleClose() {
      this.isUppyOpen = false;
      this.uppy.reset();
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;

      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
    confirmUpload() {},
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container > img {
  width: inherit;
  height: inherit;
}
</style>