<template>
  <div :key="componentKey">
    <div v-if="loading">
      <div>
        <v-chart
          :loading="loading"
          class="chart"
          :option="option"
          @click="handleClick"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="phaseNames?.length !== 0">
        <v-chart class="chart" :option="option" @click="handleClick" />
      </div>
      <div v-else>
        <div
          class="
            container
            mx-auto
            flex
            px-5
            py-10
            items-center
            justify-center
            flex-col
          "
        >
          <img src="@/assets/images/idea_pipeline.png" width="200" />
          <div class="text-center lg:w-2/3 w-full">
            <div class="mb-8 mt-8 leading-relaxed">
              <h3 class="text-sm text-blue-900">No Data available!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  BarChart,
]);

export default defineComponent({
  name: "Bar",
  components: {
    VChart,
  },
  data() {
    return {
      loader: true,
      componentKey: 0,
    };
  },
  watch: {
    data() {
      this.componentKey += 1;
      this.loader = false;
    },
  },
  props: {
    heading: String,
    data: Array,
    categoryNames: Array,
    phaseNames: Array,
    phaseInactiveDataValues: Array,
    loading: Boolean,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  created: async function () {
    this.loader = false;
  },
  setup(props) {
    const config = {
      rotate: 90,
      align: "left",
      verticalAlign: "middle",
      position: "insideBottom",
      distance: 15,
    };

    /*
    const labelOption = {
      show: true,
      position: config.position,
      distance: config.distance,
      align: config.align,
      verticalAlign: config.verticalAlign,
      rotate: config.rotate,
      formatter: " {name|{a}} ({c})",
      fontSize: 16,
      rich: {
        name: {},
      },
    };
    */

    const option = {
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: props.phaseNames,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      legend: {
        top: "bottom",
      },
      tooltip: {},
      color: ["#00AB50", "#107AA3", "#FC9D27", "#B2B2B2", "#1AA1DA"],
      series: [
        {
          name: props.data[0].name,
          type: "bar",
          barGap: 0,
          label: {
            show: false,
          },
          data: props.data[0].data,
        },
        {
          name: props.data[1].name,
          type: "bar",
          label: {
            show: false,
          },
          data: props.data[1].data,
        },
        {
          name: props.data[2].name,
          type: "bar",
          label: {
            show: false,
          },
          data: props.data[2].data,
        },
      ],
    };
    return { option };
  },
  methods: {
    handleClick(...args) {
      //this.$emit("viewDetails", args);
    },
  },
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>


