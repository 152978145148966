<template>
  <div>
    <aspenify-content :api-Key="apikey"> </aspenify-content>
  </div>
</template>
<script>
const API_KEY = process.env.VUE_APP_API_KEY;
export default {
  data() {
    return {
      apikey: API_KEY,
    };
  },
};
</script>