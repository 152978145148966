<template>
  <section class="text-gray-600 body-font" :style="[nodeMargin, nodeMargin]">
    <div class="">
      <div
        v-for="(subComment, subIndex) in subComments"
        :key="subIndex"
        class="
          container
          mx-auto
          inline-block
          w-full
          float-left
          border-t
          border-l-2 border-gray-100
        "
      >
        <div
          class="float-left w-full text-sm p-4 pb-1 rounded place-items-center"
        >
          <div class="float-left pr-2">
            <div class="">
              <img
                class="w-8 h-8 rounded-full cursor-pointer"
                :src="subComment?.notes?.accountUserId?.profilePic"
                @click="goToProfile(subComment?.notes?.accountUserId)"
              />
            </div>
          </div>
          <div class="float-left">
            <div
              class="text-base font-semibold leading-4"
              @click="goToProfile(subComment?.notes?.accountUserId)"
            >
              {{
                subComment?.notes?.accountUserId?.firstName +
                " " +
                subComment?.notes?.accountUserId?.lastName
              }}
            </div>
          </div>

          <div class="float-right mt-2 ml-3">
            <div
              class="relative inline-block text-left dropdown"
              v-if="!showAction"
            >
              <span class="rounded-md hover:shadow-lg">
                <button
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                  @click="subMenuId(subIndex)"
                  v-if="
                    isWso ||
                    subComment?.notes?.accountUserId?._id ===
                      user?.aspenifyUserId && !executiveAccess
                  "
                >
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                    />
                  </svg>
                </button>
              </span>
              <div
                v-if="subMenuIndex === subIndex"
                class="
                  opacity-0
                  invisible
                  dropdown-menu
                  transition-all
                  duration-300
                  transform
                  origin-top-right
                  -translate-y-2
                  scale-95
                  relative
                  z-20
                "
              >
                <div
                  class="
                    absolute
                    right-0
                    w-48
                    p-2
                    mt-2
                    origin-top-right
                    bg-white
                    border border-gray-200
                    divide-y divide-gray-100
                    rounded-md
                    shadow-lg
                    outline-none
                  "
                  aria-labelledby="headlessui-menu-button-1"
                  role="menu"
                >
                  <button
                    @click="editComment(subComment)"
                    href="javascript:void(0)"
                    :class="dotMenuButtonCss"
                    v-if="
                      subComment?.notes?.accountUserId?._id ===
                      user?.aspenifyUserId
                    "
                  >
                    Edit
                  </button>
                  <button
                    @click="deleteComment(subComment)"
                    href="javascript:void(0)"
                    :class="dotMenuButtonCss"
                    v-if="
                      isWso ||
                      subComment?.notes?.accountUserId?._id ===
                        user?.aspenifyUserId
                    "
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-2">
            {{ timeago.format(new Date(subComment?.notes?.notedate)) }}
          </div>
        </div>

        <div class="border-gray-200 float-left w-full">
          <div class="w-2 h-1 border-b-2 border-gray-300 absolute " style="margin:10px -20px 0 0 "></div>
          <div class="inline-block px-4 pt-0">
            {{ subComment.notes.content }}
          </div>
        </div>

        <!-- starts -->

        <div>
          <div class="p-4" v-if="textreply === subIndex">
            <textarea
              autofocus
              rows="6"
              v-model="message"
              name="elevatorPitch"
              id="elevatorPitch"
              class="
                bg-gray-200
                appearance-none
                border-2 border-gray-200
                rounded
                shadow-md
                w-full
                py-2
                px-2
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
                mb-2
              "
            />
            <div
              class="
                w-full
                text-right
                float-right
                items-center
                justify-between
                pt-2
                pb-4
              "
            >
              <button
                type="button"
                class="
                  mr-3
                  inline-flex
                  justify-center
                  hover:scale-110
                  focus:outline-none
                  px-4
                  py-2
                  rounded
                  cursor-pointer
                  hover:bg-gray-200
                  bg-gray-100
                  text-gray-700
                  duration-200
                  ease-in-out
                  transition
                  text-sm
                "
                @click="textareaReply(null)"
              >
                Cancel
              </button>

              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  px-4
                  py-2
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  text-sm
                  disabled:opacity-30
                "
                :disabled="message?.length === 0"
                @click="updateReply(subComment, $event, index)"
              >
                Save
              </button>
            </div>
          </div>
          <div
            v-else
            class="
              lex
              w-full
              py-1
              px-4
              text-blue-700 text-sm
              place-items-center
              border-b border-gray-100
            "
          >
            <div class="flex-1" v-if="!showAction && !executiveAccess">
              <button
                @click="textareaReply(subComment, $event, subIndex)"
                class="cursor-pointer hover:bg-blue-100 px-3 rounded"
              >
                Reply
              </button>
            </div>
            <div class="flex-1 text-right">
              <!-- <pre>{{
              showSubreplyIndex + "   " + subIndex + "        " + showSubreply
            }}</pre> -->
              <button
                @click="showReplys(subIndex, subComment.subComments)"
                class="cursor-pointer hover:bg-blue-100 px-3 py-1 rounded"
                v-if="subComment.subComments?.length > 0"
              >
                {{
                  showSubreplyIndex === subIndex && showSubreply
                    ? "Hide"
                    : "Show"
                }}
                {{ subComment?.subComments?.length }}
                reply

                <!-- <div v-if="showSubreplyIndex !== subIndex && !showSubreply">
                  show {{ subComment?.subComments?.length }} reply
                </div> -->
              </button>
            </div>
          </div>
        </div>
        <!-- ends -->
        <div v-if="hasChildren">
          <!-- <SubComments
        
          v-for="child in subComment.subComment"
          :key="child._id"
          :node="child"
          :subComments="child"
        /> -->
        </div>
        <subComments
          v-if="hasChildren && showSubreplyIndex === subIndex"
          v-show="showChildren"
          :subComments="allSubComments"
          @openCloseModal="openCloseNoteModal"
          @editDeleteNotes="editDeleteNotes"
          :noteObj="noteObj"
          :todo="todo"
          :spacing="Number(this.spacing) + 15"
          :initiativeId="initiativeId"
          :user="user"
          @commentAdded="commentAdded"
        >
        </subComments>
      </div>
    </div>
  </section>
  <EditDeleteNote
    :openClose="openCloseNote"
    @openCloseModal="openCloseNoteModal"
    @editDeleteNotes="editDeleteNotes"
    :noteObj="noteObj"
    :todo="todo"
  >
  </EditDeleteNote>
</template>

<script>
import { defineComponent, ref } from "vue";
import * as timeago from "timeago.js";
import EditDeleteNote from "../../components/confirmationModal/EditDeleteNote.vue";

export default {
  name: "SubComments",
  props: {
    comments: Array,
    isParticipant: Boolean,
    user: Object,
    initiativeId: String,
    commentsFor: String,
    isWso: Boolean,
    isActionVisible: Boolean,
    subComments: Array,
    spacing: Number,
    initiativeId: String,
    user: Object,
    // todo:''
  },
  data() {
    return {
      menuIndex: null,
      subMenuIndex: null,
      showSubreply: null,
      showSubreplyIndex: null,
      textreply: null,
      message: "",
      openCloseNote: false,
      timeago,
      noteObj: null,
      hasChildren: false,
      showChildren: false,
    };
  },
  computed: {
    showAction() {
      return this.isActionVisible ? this.isActionVisible : false;
    },
    dotMenuButtonCss() {
      return "w-full text-left block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-30";
    },
    nodeMargin() {
      return {
        "margin-left": `${this.spacing}px`,
      };
    },
    topMargin() {
      return {
        "margin-top": `${this.spacing}px`,
      };
    },
    executiveAccess() {
      return this.user && this.user.roles.includes("executive");
    },
  },
  beforeUpdate: function () {},
  created: function () {
  },
  components: { EditDeleteNote },
  emits: ["commentAdded", "commentsCount"],
  methods: {
    menuId(index) {
      this.menuIndex = index;
    },
    subMenuId(index) {
      this.subMenuIndex = index;
    },
    async showReplys(index, subComments) {
      this.allSubComments = subComments;
      this.showChildren = !this.showChildren;
      this.hasChildren = !this.hasChildren;
      // if (subComments.length > 0) {
      //   this.hasChildren = true;
      // } else {
      //   this.hasChildren = false;
      // }
      if (this.showSubreplyIndex === index) {
        this.showSubreply = !this.showSubreply;
      } else {
        this.showSubreply = true;
      }
       this.showSubreplyIndex = index;
    },
    textareaReply(comment, event, index) {
      this.textreply = index;
    },
    async updateReply(comment, event, index) {
      // console.log("commentcommentcomment", comment);
      // return false;
      // console.log("message", this.message);
      // console.log("indexindexindexindex", this.user);
      // console.log("initiativeIdinitiativeId",this.initiativeId);
      // return false;
      //   return false;
      // let subComment=[
      //   "notes"={
      //     "content":this.message,
      //     "parentCommentId":comment._id
      //   }
      // ]
      // comment?.subComments.push({
      //   subComment
      // })
      let payload = {
        user: this.user,
        initiativeId: this.initiativeId,
        comment: this.message,
        recipients: [],
        parentCommentId: comment._id,
      };

      await this.$store.dispatch("addComment", payload);
      await this.$emit("commentAdded", "");
    //   await this.$store.dispatch(
    //   "getAllComments",
    //   this.initiativeId
    // );
      this.textreply = null;
      this.message = "";
      this.showReplys(index);
    },
    async deleteComment(comment) {
      await this.$store.dispatch("deleteComment", comment);
      await this.$emit("commentAdded", "");
      this.$router.go(this.$router.currentRoute);
    },
    openCloseNoteModal(value) {
      this.openCloseNote = value;
    },
    editComment(comment) {
      this.todo = "edit";
      let singleComment = comment;
      singleComment.content = comment.notes.content;
      this.noteObj = { ...singleComment };
      this.openCloseNoteModal(true);
    },
    editDeleteNotes: function () {
      this.$store.dispatch("updateComments", this.noteObj);
      this.$emit("commentAdded", "");
      this.openCloseNoteModal(false);
      this.$router.go(this.$router.currentRoute);
    },
    goToProfile(user) {
      var name = user?.email
        ?.toLowerCase()
        ?.toLowerCase()
        ?.substring(0, user?.email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?._id, userName: name },
      });
    },
    commentAdded(){
      this.$emit("commentAdded", "");
    }
  },
};
</script>

<style scoped></style>
