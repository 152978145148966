<template>
  <div class="mr-20 ml-20">
    <h3
      class="
        rounded-t
        p-3
        text-xl
        font-semibold
        bg-indigo-500
        text-white text-center
      "
    >
      Submission Pipeline
    </h3>
    <div>
      <table
        class="
          min-w-full
          divide-y divide-gray-200
          table-auto
          bg-white
          shadow-md
          rounded
          text-sm text-gray-600
        "
      >
        <thead class="bg-gray-100 text-gray-500">
          <tr>
            <th scope="col" class="text-left p-2">Phase</th>
            <th
              v-for="(category, index) in categoryNames"
              :key="index"
              scope="col"
              class="p-2"
            >
              {{ category }}
            </th>
            <!-- <th scope="col" class="text-right p-2">Active Submissions</th>
            <th scope="col" class="text-right p-2">Rate of Conversion</th> -->
            <!-- <th scope="col" class="text-right p-2">Wind Down</th>
          <th scope="col" class="text-right p-2">Progressed</th> -->
          </tr>
        </thead>

        <tbody
          class="bg-white divide-y divide-gray-200"
          v-if="judgingProgressTableData?.length > 0"
        >
          <tr
            v-for="(phase, index) in judgingProgressTableData"
            :key="{ index }"
            class="cursor-pointer hover:bg-gray-50"
            @click="goToIdea(phase)"
          >
            <td class="p-2">{{ phase.phaseName }}</td>
            <td
              v-for="(category, index) in categoryNames"
              :key="index"
              scope="col"
              class="text-center p-2"
            >
            <span>{{ phase[category] }}</span>
            <span>{{ phase[`${categoryNames[index-1]} ${category}`] }}</span>
            </td>
            <!-- <td class="p-2 text-right">
              <span class="">{{ phase.totalInitiatives }} </span>
            </td> -->
          </tr>
          <!-- <tr>
            <td colspan="1" class="p-2 font-bold text-xl">Total Submissions</td>
            <td
              v-for="(category, index) in categoryNames"
              :key="index"
              class="p-2 text-right font-black text-xl"
            >
              {{ phaseCategorySummary[category] }}
            </td>
            <td class="p-2 text-right font-black text-xl">{{ totalIdeas }}</td>
          </tr> -->
        </tbody>
        <tbody v-else>
          <div class="p-2">No data available !</div>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TotalIdeas",
  props: {
    data: Array,
    categoryNames: Array,
    phaseCategorySummary: Object,
    judgingProgressTableData: Array
  },
  computed: {
    ...mapGetters(["phaseNames"]),

    updateData() {
      let dataArr = this.data.map((m) => {
        m.totalPer =
          (m.value /
            this.data
              .map((item) => item.value)
              .reduce((prev, curr) => prev + curr, 0)) *
            100 || 0;
        m.activePer =
          (m.active /
            this.data
              .map((item) => item.value && item.active)
              .reduce((prev, curr) => prev + curr, 0)) *
            100 || 0;
        m.inactivePercentage =
          (m.inactive /
            this.data
              .map((item) => item.value && item.inactive)
              .reduce((prev, curr) => prev + curr, 0)) *
            100 || 0;
        return m;
      });
      return dataArr;
    },
    totalIdeas() {
      let totalIdeas = 0;
      this.data.map((ideaData) => {
        totalIdeas = totalIdeas + ideaData.totalInitiatives;
      });
      return totalIdeas;
    },
  },
  methods: {
    goToIdea(data) {
      // this.$emit("viewDetails", data);
    },
  },
};
</script>