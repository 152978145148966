<template>
  <div class="px-6 py-6">
    <div v-if="loadingFlag">
      <circularloader />
      <!-- <h1>loading Idea details</h1> -->
    </div>
    <div v-else class="">
      <div class="w-full h-screen font-sans">
        <header class="flex z-10">
          <div class="flex items-center flex-1">
            <div class="text-lg text-gray-700">
              Process flow diagram for - {{ playbook.name }}
            </div>
          </div>
        </header>

        <div class="block items-start overflow-x-scroll overflow-x-visible">
          <div
            v-for="phase in formatedPlaybook.processedActivities"
            :key="phase._id"
          >
            <div
              class="
                p-4
                mr-3
                mt-3
                mb-3
                bg-white
                rounded
                shadow-xs
                dark:bg-gray-800
              "
            >
              <h4
                class="font-semibold text-gray-600 dark:text-gray-300 truncate"
              >
                {{ phase.name }}
              </h4>
            </div>

            <div class="flex pb-3 items-start overflow-x-auto">
              <div
                v-for="stage in phase.activities"
                :key="stage._id"
                class="rounded bg-white flex-no-shrink w-64 p-2 mr-3"
              >
                <div class="flex justify-between py-1 mb-4">
                  <h3
                    class="
                      text-sm
                      mr-4
                      subpixel-antialiased
                      font-semibold
                      truncate
                      pr-6
                    "
                  >
                    {{ stage.name }}
                  </h3>
                </div>
                <div class="text-sm mt-2 card-list hide-scroll">
                  <div
                    v-for="activity in stage.activities"
                    :key="activity._id"
                    class="flex flex-col"
                  >
                    <label
                      class="
                        text-sm
                        px-2
                        py-1
                        bg-blue-50
                        border border-blue-200
                        rounded
                        hover:bg-blue-100 hover:shadow
                        p-2
                        mb-2
                        rounded
                        transition
                        duration-500
                        ease-in-out
                      "
                      :for="activity._id"
                      @click="selectActivity(activity)"
                      >{{ activity.name }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActivityDetailsModal
      v-if="isModalVisible"
      :activity="selectedActivity"
      @close="closeModal"
    >
    </ActivityDetailsModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

import ActivityDetailsModal from "../components/ActivityDetailsModal.vue";

export default {
  components: {
    ActivityDetailsModal,
  },
  data() {
    return {
      isModalVisible: false,
      selectedActivity: null,
    };
  },
  computed: {
    ...mapGetters(["playbook", "loadingFlag"]),
    formatedPlaybook() {
      const playbookCp = cloneDeep(this.playbook);

      playbookCp.processedActivities.forEach((phase) => {
        const emptyStage = {
          name: "",
          activities: [],
        };

        let index = 0;
        for (index = phase.activities.length - 1; index >= 0; index -= 1) {
          const currentActivity = phase.activities[index];
          if (currentActivity.activities && currentActivity.activities.length > 0) {
            //stage has activities and no action required
          } else {
            //stage is an activity so need to wrap it in empty stage
            //delete the activity once it is pushed to stage
            emptyStage.activities.push(currentActivity);
            phase.activities.splice(index, 1);
          }
        }

        if (emptyStage.activities.length > 0) {
          emptyStage.activities = emptyStage.activities.reverse();
          phase.activities.push(emptyStage);
        }
      });

      return playbookCp;
    },
  },
  created: function () {
    this.$store.dispatch("readPlaybook");
  },
  methods: {
    selectActivity(activity) {
      this.selectedActivity = activity;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
input.chk-btn:not(:checked) + label:hover {
  background-color: #dbeafe;
}
input.chk-btn + label:active,
input.chk-btn:checked + label {
  background-color: #f8b878;
}

.card-list {
  overflow: auto;
  max-height: calc(100vh - 18rem);
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.board-container {
  padding: 20px;
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
}

.list-container {
  width: 270px;
  min-width: 270px;
  height: 100%;
  margin-right: 20px;
  overflow: auto;
}

.list {
  padding: 10px;
}

/* Following recommendations on modal styling from https://css-tricks.com/considerations-styling-modal/ */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
header {
  min-height: 40px;
  max-height: 40px;
}
</style>