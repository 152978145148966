<template>
  <TransitionRoot appear :show="openAddTeamModal" as="template">
    <Dialog as="div" :open="openAddTeamModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
              "
            >
              <DialogTitle
                as="h3"
                class="
                  text-lg
                  font-medium
                  leading-6
                  text-gray-900
                  border-b
                  pb-3
                "
              >
                Join as a {{mentorOrMember}}
              </DialogTitle>

              <div class="mt-4">
                <div class="w-full mr-5 pb-2">
                  <label class="block text-gray-700 text-xs mb-1" for="linkname"
                    >Name<span class="text-red-500"
                      >&nbsp;*</span
                    ></label
                  >
                  <input
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                    id="fileName"
                    :value="user.name"
                    placeholder="Enter Name"
                    type="text"
                    disabled
                  />
                </div>

                <div class="w-full mr-5">
                  <label class="block text-gray-700 text-xs mb-1" for="username"
                    >Email<span class="text-red-500">&nbsp;*</span></label
                  >
                  <input
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                    :value="user.email"
                    id="filePath"
                    type="email"
                    placeholder="Enter Email"
                    disabled
                  />
                </div>
                <!-- <span
                  class="text-sm text-red-500"
                  
                >
                  Please Enter valid URL
                </span> -->

                <!-- comment box -->
                <div class="w-full mr-5">
                  <label class="block text-gray-700 text-xs mb-1" for="username"
                    >Comment</label
                  >
                  <textarea
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                    v-model="comment"
                    id="filePath"
                    type="textarea"
                    placeholder="Enter Comment"
                  />
                </div>
                
              </div>

              <!-- <p v-if="this.showTextBox" class="-mb-1 text-sm text-gray-500">max. 2000 characters</p> -->

              <div class="mt-4">
                <button
                  type="button"
                  
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  @click="sendRequest"
                >
                   Send Request
                </button>
                <button
                  type="button"
                  @click="$emit('openAddTeamModalfunction', false)"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { object } from "yup/lib/locale";
import objectId from "../../helpers/objectId";

export default {
  name: "AddTeam",
  beforeUpdate() {
    if (this.todo == "add") {
      this.title = "Add ";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Add";
      this.info = "";
    } else if (this.todo == "edit") {
      this.title = "Edit ";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Update";
      this.info = "";
    }
  },
  props: {
    openAddTeamModal: Boolean,
    mentorOrMember: String,
   
    selectedInitiative:Object,
    allPendingRequests:Array
  },
  data() {
    return {
      comment: "",
      title: "",
      showTextBox: false,
      heading: "",
      buttonText: "",
      info: "",
      comment:""
    };
  },
  computed: {
    ...mapGetters(["user"]),
    accessToken() {
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      return tokenString;
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  created() {
  },
  methods: {
    async sendRequest() {
      let payload = {
        senderName:this.user.name,
        senderEmail:this.user.email?.toLowerCase(),
        role:this.mentorOrMember,
        initiativeId:this.selectedInitiative.id,
        comment:this.comment,
        image:this.user.profilePic,
        email:this.user.email?.toLowerCase(),
        ideaName:this.selectedInitiative.name,
        selectedInitiative:this.selectedInitiative,
        currentLoggedInUser:this.user
      }
      await this.$store.dispatch("sendRequestToBecomeMentorOrMember", payload);
      await this.$emit('openAddTeamModalfunction', false,"","sentSuccessfully");
      await this.$store.dispatch("getInitiativeDetailsById", this.selectedInitiative.id);
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator

      return !!pattern.test(str);
    },
  },
};
</script>
