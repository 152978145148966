<template>
  <div class="flex justify-between w-full mb-3">
    <div class="flex mb-6 lg:mb-0">
      <div class="mb-2">
        <h2
          class="
            inline-block
            sm:text-xl
            text-lg
            font-medium
            title-font
            text-gray-900
          "
        >
          Judging
        </h2>
        <!-- <div
          v-if="pagination && pagination.totalRecords"
          class="
            inline-block
            rounded-full
            bg-indigo-500
            px-2
            border-0
            inline-flex
            items-center
            justify-center
            text-white
            ml-2
            sm:text-base
            text-base
            font-medium
            title-font
          "
        >
          {{totalIDeasCount}}
        </div> -->
      </div>
      <div>
      </div>  
    </div>

    <div v-if="this.$route.params.status == 'toreview' && initiatives.length>0 || this.$route.params.status == 'allcompleted' && initiatives.length>0 || this.$route.params.status == 'history'&& initiatives.length>0">
      <div
        class="
          list-style
          bg-gray-200
          text-sm text-gray-500
          leading-none
          border-2 border-gray-200
          rounded-full
          inline-flex
        "
      >
        <button
          v-if="layout === 'list'"
          class="
            inline-flex
            items-center
            transition-colors
            duration-300
            ease-in
            focus:outline-none
            hover:text-indigo-400
            focus:text-indigo-400
            rounded-l-full
            px-4
            py-2
            active
          "
          v-bind:class="{
            'text-indigo-700': layout == 'grid',
          }"
          id="grid"
          @click="updateLayout('grid')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            />
          </svg>
        </button>
        <button
          v-if="layout === 'grid'"
          class="
            inline-flex
            items-center
            transition-colors
            duration-300
            ease-in
            focus:outline-none
            hover:text-indigo-400
            focus:text-indigo-400
            rounded-r-full
            px-4
            py-2
          "
          v-bind:class="{
            'text-indigo-700': layout === 'list',
          }"
          id="list"
          @click="updateLayout('list')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="
            inline-flex
            items-center
            transition-colors
            duration-300
            ease-in
            focus:outline-none
            hover:text-indigo-400
            rounded-r-full
            px-4
            py-2
          "
          v-bind:class="{
            'text-indigo-700': openFilter,
          }"
          v-if="openFilter"
          @click="openFilter = !openFilter"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="
            inline-flex
            items-center
            transition-colors
            duration-300
            ease-in
            focus:outline-none
            hover:text-indigo-400
            rounded-r-full
            px-4
            py-2
          "
          v-bind:class="{
            'text-indigo-700': openFilter,
          }"
          v-if="!openFilter"
          @click="
            openFilter = !openFilter;
            $router.push({
              name: 'Judging',
              params: { status: $route.params.status },
            });
            ideaStatus = [$route.params.status]
          "
        >
       
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="dropdown inline-block relative z-30">
        <button
          class="
            text-gray-700
            font-semibold
            py-2
            px-4
            rounded
            inline-flex
            items-center
            mr-16
          "
          @mouseover="sordDropdownOver"
          @mouseout="sortDropdownOut"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
            />
          </svg>
        </button>
        <ul
          v-show="sortDropdown"
          @click="sortDropdown = false"
          @mouseover="sordDropdownOver"
          @mouseout="sortDropdownOut"
          class="dropdown-menu absolute text-gray-700 right-12 w-48 z-50"
        >
          <li class="">
            <a
              class="rounded-t bg-white hover:bg-gray-300 py-2 px-3 block"
              href="#"
              @click="
                sortBy = { name: 1 };
                submitSearch();
              "
              >Name - A - Z</a
            >
          </li>
          <li class="">
            <a
              class="rounded-t bg-white hover:bg-gray-300 py-2 px-3 block"
              href="#"
              @click="
                sortBy = { name: -1 };
                submitSearch();
              "
              >Name - Z - A</a
            >
          </li>
          <li class="">
            <a
              class="bg-white hover:bg-gray-300 py-2 px-3 block"
              href="#"
              @click="
                sortBy = { createdAt: 1 };
                submitSearch();
              "
              >Date - Oldest first</a
            >
          </li>
          <li class="">
            <a
              class="bg-white hover:bg-gray-300 py-2 px-3 block"
              href="#"
              @click="
                sortBy = { createdAt: -1 };
                submitSearch();
              "
              >Date - Newest first</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="flex mb-5 border-b-2 border-gray-300">
    <nav class="flex flex-col sm:flex-row">
      <button
        @click="setData('toreview',toBeReviewdIdeas.length)"
        class="
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        v-bind:class="{
          'text-blue-500': currentRoundTabSelected,
          'border-b-2': currentRoundTabSelected,
          'font-medium border-blue-500': currentRoundTabSelected,
        }"
      >
      Current Round : {{currentRoundName ? currentRoundName : 'NA'}}
      </button>
      <button
        @click="setData('history',toBeReviewdIdeas.length,'history')"
        class="
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        v-bind:class="{
          'text-blue-500': judgingHistoryTabSelected,
          'border-b-2': judgingHistoryTabSelected,
          'font-medium border-blue-500': judgingHistoryTabSelected,
        }"
      >
      History
      </button>
      <button
        @click="onNoteButtonIconClick()"
        class="
          text-gray-600
          pr-2
          pl-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        v-bind:class="{
          'text-blue-500': notepadTabSelected,
          'border-b-2': notepadTabSelected,
          'font-medium border-blue-500': notepadTabSelected,
        }"
      >
      Notepad
      </button>
      <!-- <button v-if="this.initiatives.length>0" @click="checkEditorOpen(true)" class="text-lg">
      +
      </button> -->
    </nav>
  </div>
  <div class="flex mb-5 h-10" v-if="this.currentRound > 0 && this.$route.params.status != 'history' && this.$route.params.status != 'notepad'">
    <div>
      <div
        class="
          place-items-center
          rounded-full
          border border-gray-300
          mr-2
          cursor-pointer
          hover:opacity-70
          flex
          bg-white
        "
      >
        <div
          class="bg-white p-1.5 rounded-full flex"
          :class="{
            'bg-blue-500 text-white': this.$route.params.status === 'toreview',
          }"
          @click="setData('toreview',toBeReviewdIdeas.length)"
        >
          <div class="inline-block px-2">To be Reviewed</div>
          <div
            class="
              rounded-full
              bg-blue-700
              text-white
              h-6
              float-right
              px-2
              text-center
            "
          >
            {{ toBeReviewdIdeas.length}}
          </div>
        </div>

        <!-- <div
          class="bg-white p-0.5 rounded-full"
          :class="{
            'bg-blue-500 text-white':
              this.$route.params.status === 'allInreview',
          }"
          @click="setData('allInreview')"
        >
          <div class="inline-block px-2 text-sm">All</div>
          <div
            class="
              rounded-full
              bg-blue-700
              text-white
              h-6
              float-right
              px-2
              text-center
            "
          >
            {{ pagination.totalRecords }}
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <div
        class="
          place-items-center
          rounded-full
          border border-gray-300
          mr-2
          cursor-pointer
          hover:opacity-70
          flex
          bg-white
        "
      >
        <div
          class="bg-white p-1.5 rounded-full flex"
          :class="{
            'bg-blue-500 text-white': this.$route.params.status === 'allcompleted',
          }"
          @click="setData('allcompleted',reviewCompletedIdeas.length)"
        >
          <div class="inline-block px-2">Review Completed</div>
          <div
            class="
              rounded-full
              bg-blue-700
              text-white
              h-6
              float-right
              px-2
              text-center
            "
          >
            {{ reviewCompletedIdeas.length }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="this.$route.params.status=='allcompleted'" class="bg-white w-40">
        <Multiselect
          class="z-20"
          trackBy="label"
          label="label"
          v-model="selectedOption"
          placeholder="Judging History"
          :options='judgingRounds'
          ref="multiselect"
          @select="onSelectRound('allcompleted', reviewCompletedIdeas.length, selectedOption)"
        ></Multiselect>
      </div> -->
  </div>
  <div class="flex mb-5 h-10" v-if="!openFilter && this.currentRound > 0 && this.$route.params.status == 'history' && this.initiatives.length>0">
    <div v-for="(round, index) in judgingHistoryRounds">
      <div
        class="
          place-items-center
          rounded-full
          border border-gray-300
          mr-2
          cursor-pointer
          hover:opacity-70
          flex
          bg-white
        "
      >
        <div
          class="bg-white p-1.5 rounded-full flex"
          :class="{
            'bg-blue-500 text-white': round.selected,
          }"
          @click="roundClicked(round)"
        >
          <div class="inline-block px-2">{{round.label}}</div>
          <div
            class="
              rounded-full
              bg-blue-700
              text-white
              h-6
              float-right
              px-2
              text-center
            "
          >
            {{ round.count}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-5 h-10 flex" v-if="openFilter">
    <!-- <div v-if="this.$route.params.status != 'history'" class="">
      <Multiselect
        class="bg-white rounded-l-full w-60 search-with-dropdown"
        v-model="ideaStatus"
        placeholder="Select..."
        label="name"
        :options="toBeReviewdIdeasCount"
      >
        <template v-slot:singlelabel="{ value }">
          <div class="multiselect-single-label w-full block">
            {{ value.name }}
            <div
              class="
                inline-block
                rounded-full
                bg-indigo-500
                px-2
                border-0
                float-right
                items-center
                text-white
                ml-2
                sm:text-base
                text-base
                font-medium
                title-font
              "
            >
              {{ value.count >= 1000 ? "999+" : value.count }}
            </div>
          </div>
        </template>

        <template v-slot:option="{ option }">
          <div
            class="w-full block"
            @click="
              $router.push({
                name: 'Judging',
                params: { status: option.value },
              })
            "
          >
            {{ option.name }}
            <div class="float-right text-right">
              <div
                class="
                  inline-block
                  rounded-full
                  bg-indigo-500
                  px-2
                  border-0
                  inline-flex
                  items-center
                  justify-center
                  text-white
                  ml-2
                  sm:text-base
                  text-base
                  font-medium
                  title-font
                "
              >
                {{ option.count >= 1000 ? "999+" : option.count }}
              </div>
            </div>
          </div>
        </template>
      </Multiselect>
    </div> -->
    <div class="w-full flex relative">
      <input
        type="text"
        name=""
        id=""
        placeholder="Search..."
        v-model="name"
        class="
          w-full
          rounded-r-full
          text
          outline-none
          focus:outline-none
          bg-white
          px-4
          py-1.5
          border border-gray-200
        "
        @input="submitSearch()"
      />
      <button
        class="
          absolute
          right-0
          inline-flex
          justify-center
          text-sm
          font-medium
          hover:scale-110
          focus:outline-none
          px-2.5
          py-2.5
          rounded-full
          cursor-pointer
          hover:bg-indigo-600
          bg-indigo-500
          text-white
          duration-200
          ease-in-out
          transition
          disabled:opacity-30
        "
        v-if="name?.length !== 0"
        v-on:click="clearSearchInput()"
      >
        <icon-close />
      </button>
    </div>
  </div>
  
  <div class=" mb-5 h-10" v-if="!openFilter && this.$route.params.status == 'notepad' ">
        <div class="w-100%"> 
         <!-- <button @click="checkEditorClose(false)" class="mx-8">x</button>
         <button @click="createNote(value)" class="mx-8">Add comment</button> 
         <button @click="deleteComment()" class="mx-8">Remove comment</button> -->
        </div>
        <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-3
                 rounded-full
                 focus:outline-none
               " @click="checkEditorOpen(true)">
            Add New Note
           </button>
      <JudgingNote class="w-full"
       :openPanelFlag="openPanelFlag"
       :notes="userNotes"
       @refreshData=refreshData
       />
  </div>
  <div v-if="editorOpen" class="modal-backdrop z-[100]">
     <div class="modal">
      <section class="modal-body">
       <slot name="body">
        <div :class="{'w-1/2': openPanelFlag, 'w-full': !openPanelFlag}" class=" 
          z-20  
          h-screen         
          fixed
          top-8
          ml-10
          flex
          justify-center
          items-center
          g-opacity-50">
         <div :class="{'ml-60': openPanelFlag, 'w-4/5': openPanelFlag, 'w-10/12 md:w-2/4': !openPanelFlag,}" class=" bg-white z-99 rounded shadow-lg relative">
          <div class="
               border-b
               px-5
               py-2
               flex
               justify-between
               items-center
               rounded-t
             ">
            
           <h3 class="color font-medium text-white text-lg ">
            Add Note
            <!-- {{documentData.documentName.substring(0,documentData.documentName.lastIndexOf("."))}} -->
           </h3>
           <!-- <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-2
                 rounded-full
                 focus:outline-none
               " @click="checkEditorClose(value)">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
             <line x1="18" y1="6" x2="6" y2="18"></line>
             <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            Ok
           </button> -->
           <!-- <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-2
                 rounded-full
                 focus:outline-none
                 text-right
                 float-right
                 mb-4
                 mr-4
               " @click="checkEditorClose(value)">
            OK
           </button> -->
          </div>
          <div class="max-h-96 pt-6 px-4 overflow-y-auto">
            <JudgingCommentsTextEditor 
            :content="value"
            label="notes"
             @update-content="updateContent"
            />
          </div>
          <div class="mt-4">
          <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-1
                 px-1
                 rounded-full
                 focus:outline-none
                 text-center
                 float-right
                 mb-2
                 mr-4
                 w-7
               " @click="checkEditorClose(value)">
            OK
           </button>
           </div>
          <!-- <button class="
                   backgroundColor
                 hover:bg-blue-500
                 text-sm text-white
                 py-2
                 px-2
                 rounded-full
                 focus:outline-none
                 text-right
                 float-right
                 mb-4
                 mr-4
               " @click="checkEditorClose(value)">
            Add Note
           </button> -->
          <!-- <div class="
               z-20
               w-full
               text-right
               float-right
               items-center
               justify-between
               p-5
               bg-white
               border-t
               rounded-bl-lg rounded-br-lg
             ">
           <button type="button" @click="checkEditorClose(false)" class="
             footerButton
               "> Cancel </button>
           <button  @click="createNote(value)" class="
           disabled:opacity-50 primaryButton
               "> Add </button>
          </div> -->
         </div>
        </div>
       </slot>
      </section>
     </div>
    </div>
        
        
 
</template>
<script>
import Multiselect from "@vueform/multiselect";
import SetTitle from "../../components/SetTitle";
import { mapGetters } from "vuex";
import moment from "moment";
import IconClose from "../Icon/Close.vue";
import InfoCard from "../../components/InfoCard.vue";
import AppTextEditor from "../../components/AppTextEditor";
import NotesPopUp from "./NotesPopUp.vue"
import JudgingNote from "./JudgingNote.vue"
import JudgingCommentsTextEditor from "../JudgingCommentsTextEditor.vue";

export default {
  name: "FilterCards",
  props: {
    total: Number,
    selectedIdea: Object,
    heading: String,
    filterLabels: Array,
    column: Number,
    reviewCompletedIdeas:Array,
    toBeReviewdIdeas:Array,
    reviewCompletedInLastWeek:Array,
    judgingRounds: Array,
    judgingHistoryRounds: Array,
    judgingHistoryTabSelected: Boolean,
    currentRoundTabSelected: Boolean,
    notepadTabSelected: Boolean,
    openPanelFlag: Boolean
  },
  data() {
    return {  
      layout: "grid",
      openFilter: false,
      isFilter: false,
      sortDropdown: false,
      search: {},
      sort: {},
      name: "",
      selectedOption: "",
      statusOptions: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 3,
          label: "Closed",
        },
        {
          value: 6,
          label: "Inactive",
        },
      ],
      ideaStatus: ["allcompleted"],
      dataOptions: [
        {
          value: "allcompleted",
          name: "All Completed",
          label: "All Completed",
          count: 3038,
        },
        {
          value: "toreview",
          name: "To Be Reviewed",
          label: "To Be Reviewed",
          count: 5,
        },
        {
          value: "completed",
          name: "Review Completed",
          label: "Review Completed",
          count: 325,
        },
      ],
      range: {},
      totalIDeasCount:this.toBeReviewdIdeas.length,
      currentRoundTabSelected: false,
      judgingHistoryTabSelected: false,
      notepadTabSelected: false,
      editorOpen:false,
      value:"",
      notes:[],
    };
  },
  components: {
    Multiselect,
    SetTitle,
    IconClose,
    InfoCard,
    AppTextEditor,
    NotesPopUp,
    JudgingNote,
    JudgingCommentsTextEditor
},
  mounted: function () {
    this.$store.dispatch("setSelectedRound", this.selectedOption);
    let payload = {
          params: this.$route.params,
          aspenifyUserId: this.user.aspenifyUserId,
          user: this.user, 
        };
    let Notes= this.$store.dispatch("getUserMetaData", payload);
    this.$store.dispatch("getAllNotes", payload);
  },
  beforeMount:function(){
  // if(this.$route.params.status == 'history'){
  //   this.setData('history',this.toBeReviewdIdeas.length)
  // }
    this.totalIDeasCount=this.toBeReviewdIdeas.length + this.reviewCompletedIdeas.length
  },
  beforeUpdate:function(){
    if(this.$route.params.status == 'history'){
  this.judgingHistoryTabSelected = true;
  this.currentRoundTabSelected = false;
  this.notepadTabSelected = false;
 } else if(this.$route.params.status == 'notepad'){
  this.judgingHistoryTabSelected = false;
  this.currentRoundTabSelected = false;
  this.notepadTabSelected = true;
 } else {
  this.judgingHistoryTabSelected = false;
  this.currentRoundTabSelected = true;
  this.notepadTabSelected = false;
 }

    this.totalIDeasCount=this.toBeReviewdIdeas.length + this.reviewCompletedIdeas.length
  }, 
  emits: ["updateLayout", "queryFilter", "loader"],
  created: async function () {
    if(this.selectedRoundOption){
      this.selectedOption = this.selectedRoundOption;
    }
    this.totalIDeasCount=this.toBeReviewdIdeas.length + this.reviewCompletedIdeas.length
  },
  unmounted:function(){
    this.$store.dispatch("setSelectedRound", "");
  },
  methods: {
    setFilter(option) {
      this.isFilter = option;
    },
    sordDropdownOver: function () {
      this.sortDropdown = true;
    },
    updateContent(updatedValue,label){ 
    this.value=updatedValue
    },
    sortDropdownOut: function () {
      this.sortDropdown = false;
    },
    updateLayout: function (layout) {
      this.layout = layout;
      this.$emit("updateLayout", layout);
    },
    sortByObj(sortMe) {
      this.sort = sortMe;
    },
    // submitSearch: function (payload) {
    //   this.query = {
    //     search: this.search,
    //     sort: this.sort,
    //   };
    //   this.$emit("queryFilter", this.query);
    // },
    clearSearchInput: function () {
      this.name = "";
      this.submitSearch();
    },
    checkEditorOpen(value){
      this.editorOpen=value
    },
    editorClose(){
      this.editorOpen=false
      this.value=''
    },
    checkEditorClose(value){
      this.createNote(value)
      this.editorOpen=false
      this.value=''
    },
    clearFilter() {
      this.search = {};
      this.sort = {};
      this.status = [];
      this.range = {};
      this.submitSearch();
    },
    setFilter: function (option) {
      this.isFilter = option;
      this.marketHorizons.map((data) => {
        data.disabled = false;
      });
      this.sources.map((data) => {
        data.disabled = false;
      });
      this.priorityOptions?.map((data) => {
        data.disabled = false;
      });
      this.bogFrameworks?.map((data) => {
        data.disabled = false;
      });
    },
    submitSearch: _.debounce(async function () {
      let searchedString = "";
      let searchedKeywords = [];
      this.$emit("loader", false);
      // let currentDate=new Date();
      // 2021-10-25T14:49:32.268Z
      // 2021-10-27T11:46:39.395Z
      let startDate;
      let endDate;
      if (Object.keys(this.range).length > 0) {
        startDate = new Date(moment(new Date(this.range?.start), "DD-MM-YYYY"));
        endDate = new Date(moment(new Date(this.range?.end), "DD-MM-YYYY"));
      }
      let query = {
        status: this.status,
        "metaData.challengeType": { $in: this.challengeType },
        "metaData.marketHorizon": { $in: this.marketHorizon },
        "metaData.source": { $in: this.source },
        "metaData.bogFramework": { $in: this.bogFramework },
        priority: { $in: this.priority },
        createdAt: { $gte: startDate, $lt: endDate },
      };

      if (this.name.startsWith('"') && this.name.endsWith('"')) {
        searchedString = this.name;
        searchedString = searchedString.trim();
        searchedString = searchedString.slice(1, -1);
        query.name = searchedString;
      } else {
        if (this.name?.length > 0) {
          searchedString = this.name;
          searchedString = searchedString.trim();
          searchedKeywords = searchedString.split(" ");
          query.name = searchedKeywords;
        }
      }
      this.$emit("setSearchQuery", query);
      this.submitSearchQuery= query;
      let filteredCurrentRound = this.totalJudgingRounds.filter(round=>round.id==this.currentRound);
      let payload = {
        currentPage: 1,
        searchQuery: query,
        sortBy: this.sortBy,
        isJudging: true,
        status: this.$route.params.status,
      }
      if(filteredCurrentRound?.length>0){
        let baseId = filteredCurrentRound[0].baseId;
        if(this.$route.params.status == 'toreview' && baseId){
          payload = {
            currentPage: 1,
            searchQuery: query,
            sortBy: this.sortBy,
            isJudging: true,
            baseId: baseId
          }
        }
      }
      if(this.$route.params.status == 'history'){
        let baseId = this.selectedRoundOption ? this.selectedRoundOption : this.judgingHistoryRounds[0].baseId;
        payload.roundBaseId = baseId;
      }
      await this.$emit("pageChangeQuery", payload);
      await this.$store.dispatch("getAllInitiatives", payload);
    }, 500),
    async onNoteButtonIconClick() {
      if(this.$route.params.status == 'notepad'){
        this.$router.go(-1)
      } else {
      this.setData('notepad',this.toBeReviewdIdeas.length,'notepad')
      }
    },
    async setData(payload, ideasCount,status) {
      this.openFilter = false;
      this.name = "";
      let flag=true;
      if(this.$route.params.status == 'notepad'){
      if(this.selectedIdea?.baseId == this.toBeReviewdIdeas?.[0]?.baseId && payload == "toreview"){flag=false};
      if(this.selectedIdea?.baseId == this.reviewCompletedIdeas?.[0]?.baseId && payload == "allcompleted") {flag=false};
      if(this.selectedIdea?.baseId == this.judgingHistoryRounds?.[0]?.baseId && status == "history") {flag=false};
      }
      let filteredCurrentRound = this.totalJudgingRounds.filter(round=>round.id==this.currentRound);
      if(flag && (payload == "toreview" || payload == "allcompleted"|| status == 'history')){
        this.$emit("openPanel", false);
      }
      if(this.$refs.multiselect){
        this.$refs.multiselect.clear();
      }
      this.totalIDeasCount =ideasCount
      this.$emit("loader", false);
      // setTimeout(async () => {
        // if(ideasCount>0){
          if(!flag){
          await this.$router.push({
            name: "Judging",
            params: { status: payload },
            hash:`#${this.selectedIdea?._id}`
          });
        }else {
          await this.$router.push({
            name: "Judging",
            params: { status: payload },
          });
        }
          if(this.$route.params.status == 'history'){
            if(this.judgingHistoryRounds.length <= 0 && !this.currentRoundName){
              await this.$store.dispatch("setUpdateInitiatives", []);
            }
            else if(this.judgingHistoryRounds.length <= 0){
              await this.$store.dispatch("setUpdateInitiatives", []);
            } else {
              let roundIds = [];
              let baseId = this.selectedRoundOption ? this.selectedRoundOption : this.judgingHistoryRounds[0].baseId;
              this.judgingHistoryRounds.map((round, index)=>{
                if(baseId == round.baseId){
                  round.selected = true;
                } else {
                  round.selected = false;
                }
                round.count = 0;
                roundIds.push(round.baseId);
              });
              let ideas = await this.$store.dispatch("getInitiativesByBaseId", {baseIds: roundIds, currentPage: 1});
              if(ideas?.data?.length>0)
              ideas.data.map((idea)=>{
                this.judgingHistoryRounds.map((round)=>{
                  if(round.baseId == idea.baseId){
                    round.count++;
                  }
                })
              })
              let payload = {
                currentPage: 1,
                sortBy: this.sortBy,
                isJudging: true,
                status: this.$route.params.status,
                roundBaseId: baseId
              };
              await this.$emit("pageChangeQuery", payload);
              await this.$store.dispatch("getAllInitiatives", payload);
            }
          }
          else if(this.$route.params.status == 'notepad'){
      console.log("in set data function in else if notepad");
     }
          else {
            let payload = {
              currentPage: 1,
              // searchQuery: query,
              sortBy: this.sortBy,
              isJudging: true,
              status: this.$route.params.status,
            }
            //await this.$store.dispatch("setUpdateInitiatives", []);
            if(filteredCurrentRound?.length>0){
              let baseId = filteredCurrentRound[0].baseId;
              if(this.$route.params.status == 'toreview' && baseId){
                payload = {
                  currentPage: 1,
                  // searchQuery: query,
                  sortBy: this.sortBy,
                  isJudging: true,
                  baseId: baseId
                }
              }
            }
            await this.$emit("pageChangeQuery", payload);
            await this.$store.dispatch("getAllInitiatives", payload);
          }
        // }
      // }, 500);
    },
    async createNote(value){
      let payload = {
        user: this.user, 
        data: {
          key: "notes",
          label: "metadata." + "notes",
          value: {
            accountUserId: this.user.aspenifyUserId,
            content: value,
            notedate: new Date(),
          },
        },
      }
     await this.$store.dispatch("createNote", payload);
     await this.refreshData()

      // await this.$store.dispatch("updateProfile", query);
      this.editorOpen=false
    },
    async refreshData(){
      let payload = {
          params: this.$route.params,
          aspenifyUserId: this.user.aspenifyUserId,
          user: this.user, 
      };
     await this.$store.dispatch("getAllNotes", payload);
    },
    async AllComment(){
      let payload = {
          params: this.$route.params,
          aspenifyUserId: this.$route.params.userId,
        };
        let result=await this.$store.dispatch("getUserMetaData", payload);
     
      // await this.$store.dispatch("updateProfile", query);
    },
    async onSelectRound(payload, ideasCount, value){
      this.totalIDeasCount =ideasCount
      this.$emit("loader", false);
      await this.$router.push({
        name: "Judging",
        params: { status: payload },
      });
      let selectedRound = this.judgingRounds.filter((round)=>{return round.value == value})
      let query = {
        currentPage: 1,
        sortBy: this.sortBy,
        isJudging: true,
        status: this.$route.params.status,
        roundBaseId: selectedRound[0].baseId
      }
      await this.$emit("pageChangeQuery", query);
      await this.$store.dispatch("getAllInitiatives", query);
      this.$store.dispatch("setSelectedRound", value);
    },
    async judgingHistoryClicked(){
      let roundIds = [];
      this.judgingHistoryRounds[0].selected = true;
      this.judgingHistoryRounds.map((round)=>{
        roundIds.push(round.baseId);
      })
      let payload = {
        currentPage: 1,
        sortBy: this.sortBy,
        isJudging: true,
        status: this.$route.params.status,
        roundBaseId: roundIds
      }
      await this.$emit("pageChangeQuery", payload);
      await this.$store.dispatch("getAllInitiatives", payload);
    },
    async roundClicked(payload){
      this.$emit("openPanel", false);
      this.judgingHistoryRounds.map((round)=>{
        if(payload.baseId == round.baseId){
          round.selected = true;
        } else {
          round.selected = false;
        }
      })
      let query = {
        currentPage: 1,
        sortBy: this.sortBy,
        isJudging: true,
        status: this.$route.params.status,
        roundBaseId: payload.baseId
      }
      await this.$emit("pageChangeQuery", query);
      await this.$store.dispatch("getAllInitiatives", query);
      this.$store.dispatch("setSelectedRound", payload.baseId);
    }
  },

  computed: {
    ...mapGetters([
      "initiatives",
      "loadingFlag",
      "pagination",
      "user",
      "phaseNames",
      "marketHorizons",
      "challengeTypes",
      "sources",
      "bogFrameworks",
      "playbook",
      "autoInitiativeInAction",
      "currentRound",
      "currentRoundName",
      "selectedRoundOption",
      "judgingHistoryRounds",
      "totalJudgingRounds",
      "userMetaData",
      "userNotes"
    ]),
    // managerAccess() {
    //   return this.user?.role === "manager";
    // },
    // userAccess() {
    //   return this.user?.role === "user";
    // },
    // judgeTeamLead() {
    //   return this.user?.role === "judgeManager";
    // },
    // judgeTeamMember() {
    //   return this.user?.role === "judgeMember";
    // },
    teamLead() {
      let managers = this.user?.associatedTeam?.members.filter((f) =>
        f.role.includes("Manager")
      );
      return managers;
    },
    teamMember() {
      let members = this.user?.associatedTeam?.members.filter((f) =>
        f.role.includes("Member")
      );
      return members;
    },
    toBeReviewdIdeasCount(){
      return[
        // {
        //   value: "allcompleted",
        //   name: "All Completed",
        //   label: "All Completed",
        //   count: this.reviewCompletedIdeas.length,
        // },
        {
          value: "toreview",
          name: "To Be Reviewed",
          label: "To Be Reviewed",
          count: this.toBeReviewdIdeas.length,
        },
        {
          value: "completed",
          name: "Review Completed",
          label: "Review Completed",
          count: this.reviewCompletedInLastWeek.length,
        }
      ]
      //  return [];
    }
  },
};
</script>
<style scoped>
.multiselect-input {
  border-width: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  font-size: 30px !important;
}
.search-with-dropdown .multiselect-single-label {
  display: block;
  width: 100%;
  padding: 6px 32px 6px 12px;
}

.ellipsis-team {
  width: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-backdrop {
    /* position: fixed; */
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center; */
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    left: -20px
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
  .footerButton {
    font-size: 12px;
    font-style: normal;
    border-width: 1px;
    border-color: #4CB0D6;
    color: #4CB0D6;
    border-radius: 100px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 8px;
    height: 32px;
    width: 100px
  }
  .footerButton:hover {
    background-color: white;
    color: #4CB0D6;
  }
  .backgroundColor{
    background-color: #4CB0D6;
  }
  .color{
    color: #08526D;
  }

  .primaryButton{
    font-size: 12px;
    font-style: normal;
    border-width: 1px;
    border-color: #4CB0D6;
    color:white;
    border-radius: 100px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 8px;
    height: 32px;
    width: 100px;
    background-color:#4CB0D6;
  }
</style>