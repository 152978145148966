<template>
  <!-- <pre>{{ user.roles }}</pre>-->
  <!-- <pre>{{ selectedIdea?.name }}</pre>  -->
  <!-- <section
    class="text-gray-600 body-font px-6 py-6 block"
    v-if="isPostIdeaFlag && initiatives.length === 0"
  >
    <div v-if="loadingFlag">
      <h1>loading Idea</h1>
      <circularloader />
    </div>
    <div
      v-else
      class="
        container
        mx-auto
        flex
        px-5
        py-10
        items-center
        justify-center
        flex-col
        mt-10
      "
    >
      <img src="@/assets/images/judging.png" width="300" style="" />
      <div class="text-center lg:w-2/3 w-full">
        <div class="mb-8 mt-8 leading-relaxed">
          <h3 class="text-2xl text-blue-900">There are no ideas for this week</h3>
          You will be able to view ideas here once <br />
          they are ready for judging.
        </div>
      </div>
    </div>
  </section> -->
  <!-- <pre>{{selectedIdea?.phaseIndex}}</pre> -->
  <div
    class="px-6 py-6 block"
    :key="componentKey"
    :class="{
      'w-7/12 overflow-auto ': openPanelFlag,
      'w-full container mx-auto': !openPanelFlag,
    }"
    style="height: calc(100vh - 48px)"
  >
    <div class="block">
      <div
        class="transition-all"
        :class="{
          'w-full ': openPanelFlag,
          'w-full': !openPanelFlag,
        }"
      >
        <comman-filter
          heading="Judging Submission"
          :total="filterChallengeTypeInitiatives.length"
          :column="2"
          @updateLayout="updateLayout"
          @queryFilter="queryFilter"
          :filterLabels="filterLabels"
          @loader="loader"
          @setSearchQuery="setSearchQuery"
          :toBeReviewdIdeas="toBeReviewdIdeas"
          :selectedIdea="selectedIdea"
          :reviewCompletedIdeas="reviewCompletedIdeas"
          :reviewCompletedInLastWeek="reviewCompletedInLastWeek"
          :judgingRounds="judgingRounds"
          :judgingHistoryRounds="judgingHistoryRounds"
          :judgingHistoryTabSelected = "judgingHistoryTabSelected"
          :currentRoundTabSelected = "currentRoundTabSelected"
          @openPanel="openPanel"
          :openPanelFlag="openPanelFlag"
          @pageChangeQuery="pageChangeQuery"
        />
        <!-- <div v-if="initiatives.length == 0 && !loadingFlag">
        <section class="text-gray-600 body-font w-full">
          <info-card message="No Ideas to display!" />
        </section>
      </div> -->
      </div>
      <div v-if="loadingFlag">
        <!-- <h1>loading Idea</h1> -->
        <circularloader />
      </div>
      <div v-else-if="filterChallengeTypeInitiatives?.length !== 0 && this.$route.params.status != 'notepad'">
        <div
          class="transition-all"
          
        >
          <!-- :class="{
            'w-6/12': openPanelFlag && layout === 'grid',
            'w-3/12': !openPanelFlag && layout === 'grid',
            'w-full': layout === 'list',
          }" -->

          <div
          :class="{
            'w-full': layout === 'list',
            'drawer-container-width': layout === 'grid' && openPanelFlag,
            'container-width': layout === 'grid' && !openPanelFlag,
          }"
            class=" p-3"
            v-for="singleInitiative in filterChallengeTypeInitiatives"
            :key="singleInitiative.id"
          >
            <div class="bg-white rounded shadow hover:shadow-2xl">
              <idea-card
                :item="singleInitiative"
                @openPanel="openPanel"
                @setParentIdea="setParentIdea"
                :selectedIdea="selectedIdea"
                @selectedIdeaAction="selectedIdeaAction"
                :layout="layout"
                :isJudgeOrUser="isJudgeOrUser"
               
              />
            </div>
          </div>
        </div>
        <div
          class="py-5 transition-all"
          v-if="pagination.totalRecords > 8"
          :class="{
            'w-full ': openPanelFlag,
            'w-full': !openPanelFlag,
          }"
        >
          <pagination
            :total-pages="pagination.totalPages"
            :current-page="pagination.currentPage"
            @pagechanged="pageChanged"
          >
          </pagination>
        </div>
      </div>
      <div v-else-if ="this.$route.params.status != 'notepad'">
        <section class="text-gray-600 body-font px-6 py-6 block">
          <div
            class="
              container
              mx-auto
              flex
              px-5
              py-10
              items-center
              justify-center
              flex-col
              mt-5
            "
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="298" height="222.075" viewBox="0 0 298 222.075">
            <g id="Group_17281" data-name="Group 17281" transform="translate(0 -54.953)">
              <g id="Background_Complete" data-name="Background Complete" transform="translate(0 54.953)">
                <g id="Group_17261" data-name="Group 17261">
                  <rect id="Rectangle_58699" data-name="Rectangle 58699" width="298" height="0.149" transform="translate(0 195.157)" fill="#ebebeb"/>
                  <rect id="Rectangle_58700" data-name="Rectangle 58700" width="19.741" height="0.149" transform="translate(31.266 200.833)" fill="#ebebeb"/>
                  <rect id="Rectangle_58701" data-name="Rectangle 58701" width="32.101" height="0.149" transform="translate(101.999 200.908)" fill="#ebebeb"/>
                  <rect id="Rectangle_58702" data-name="Rectangle 58702" width="11.439" height="0.149" transform="translate(63.042 203.91)" fill="#ebebeb"/>
                  <rect id="Rectangle_58703" data-name="Rectangle 58703" width="11.464" height="0.149" transform="translate(254.352 203.835)" fill="#ebebeb"/>
                  <rect id="Rectangle_58704" data-name="Rectangle 58704" width="18.054" height="0.149" transform="translate(230.992 203.835)" fill="#ebebeb"/>
                  <rect id="Rectangle_58705" data-name="Rectangle 58705" width="25.363" height="0.149" transform="translate(195.687 202.85)" fill="#ebebeb"/>
                  <path id="Path_21463" data-name="Path 21463" d="M156.7,223.53H41.609a3.406,3.406,0,0,1-3.4-3.4V58.354a3.406,3.406,0,0,1,3.4-3.4H156.7a3.406,3.406,0,0,1,3.4,3.4V220.128A3.406,3.406,0,0,1,156.7,223.53ZM41.609,55.1a3.256,3.256,0,0,0-3.253,3.252V220.128a3.256,3.256,0,0,0,3.253,3.253H156.7a3.256,3.256,0,0,0,3.252-3.253V58.354A3.256,3.256,0,0,0,156.7,55.1Z" transform="translate(-15.436 -54.953)" fill="#ebebeb"/>
                  <path id="Path_21464" data-name="Path 21464" d="M372.993,223.53H257.906a3.406,3.406,0,0,1-3.4-3.4V58.354a3.405,3.405,0,0,1,3.4-3.4H372.993a3.406,3.406,0,0,1,3.4,3.4V220.128A3.406,3.406,0,0,1,372.993,223.53ZM257.906,55.1a3.256,3.256,0,0,0-3.252,3.252V220.128a3.256,3.256,0,0,0,3.252,3.253H372.993a3.257,3.257,0,0,0,3.254-3.253V58.354a3.257,3.257,0,0,0-3.254-3.252Z" transform="translate(-102.82 -54.953)" fill="#ebebeb"/>
                </g>
                <g id="Group_17271" data-name="Group 17271" transform="translate(28.226 13.822)">
                  <g id="Group_17265" data-name="Group 17265" transform="translate(148.971)">
                    <g id="Group_17264" data-name="Group 17264" transform="translate(0)">
                      <g id="Group_17262" data-name="Group 17262" transform="translate(3.857 7.674)">
                        <rect id="Rectangle_58706" data-name="Rectangle 58706" width="69.194" height="82.618" fill="#e6e6e6"/>
                        <rect id="Rectangle_58707" data-name="Rectangle 58707" width="69.194" height="82.618" transform="translate(1.727)" fill="#f5f5f5"/>
                        <path id="Path_21519" data-name="Path 21519" d="M0,0H75.652V62.993H0Z" transform="translate(67.821 3.483) rotate(90)" fill="#d3d3d3"/>
                        <rect id="Rectangle_58709" data-name="Rectangle 58709" width="75.652" height="1.306" transform="translate(67.821 3.483) rotate(90)" fill="#e6e6e6"/>
                        <rect id="Rectangle_58710" data-name="Rectangle 58710" width="66.629" height="4.676" transform="translate(3.01 61.211)" fill="#f5f5f5"/>
                      </g>
                      <g id="Group_17263" data-name="Group 17263">
                        <path id="Path_21465" data-name="Path 21465" d="M375.469,81.348H297.787a.478.478,0,0,0-.477.477h0a.478.478,0,0,0,.477.477h77.682a.479.479,0,0,0,.477-.477h0A.479.479,0,0,0,375.469,81.348Z" transform="translate(-297.31 -79.438)" fill="#e6e6e6"/>
                        <circle id="Ellipse_134" data-name="Ellipse 134" cx="2.387" cy="2.387" r="2.387" transform="translate(76.25)" fill="#e6e6e6"/>
                        <path id="Path_21466" data-name="Path 21466" d="M304.426,84.822h0a.646.646,0,0,1-.644-.645V82.793a.646.646,0,0,1,.644-.645h0a.646.646,0,0,1,.644.645v1.384A.646.646,0,0,1,304.426,84.822Z" transform="translate(-299.925 -79.761)" fill="#e6e6e6"/>
                        <path id="Path_21467" data-name="Path 21467" d="M415.934,84.822h0a.646.646,0,0,1-.644-.645V82.793a.646.646,0,0,1,.644-.645h0a.646.646,0,0,1,.644.645v1.384A.646.646,0,0,1,415.934,84.822Z" transform="translate(-344.974 -79.761)" fill="#e6e6e6"/>
                      </g>
                    </g>
                    <rect id="Rectangle_58711" data-name="Rectangle 58711" width="71.14" height="34.696" transform="translate(6.867 2.387)" fill="#e0e0e0" opacity="0.5"/>
                    <circle id="Ellipse_135" data-name="Ellipse 135" cx="2.387" cy="2.387" r="2.387" transform="translate(9.791)" fill="#e6e6e6"/>
                  </g>
                  <g id="Group_17267" data-name="Group 17267" transform="translate(0 69.623)">
                    <g id="Group_17266" data-name="Group 17266">
                      <path id="Path_21468" data-name="Path 21468" d="M72.6,224.5H47.359L49.6,194.961H70.366Z" transform="translate(-47.359 -194.961)" fill="#ebebeb"/>
                      <path id="Path_21469" data-name="Path 21469" d="M76.855,224.5h-4.34L71.6,194.961h4Z" transform="translate(-57.151 -194.961)" fill="#f5f5f5"/>
                    </g>
                    <rect id="Rectangle_58712" data-name="Rectangle 58712" width="2.524" height="82.17" transform="translate(11.36 29.542)" fill="#f0f0f0"/>
                    <rect id="Rectangle_58713" data-name="Rectangle 58713" width="5.269" height="9.568" rx="2.635" transform="translate(9.987 41.646)" fill="#f0f0f0"/>
                    <rect id="Rectangle_58714" data-name="Rectangle 58714" width="5.269" height="9.568" rx="2.635" transform="translate(9.987 56.975)" fill="#f0f0f0"/>
                    <path id="Path_21470" data-name="Path 21470" d="M69.824,381.243H61.83a1.7,1.7,0,0,1,1.7-1.7h4.587a1.7,1.7,0,0,1,1.7,1.7Z" transform="translate(-53.205 -269.531)" fill="#f0f0f0"/>
                    <rect id="Rectangle_58715" data-name="Rectangle 58715" width="2.524" height="1.543" transform="translate(11.36 29.542)" fill="#e0e0e0"/>
                  </g>
                  <g id="Group_17268" data-name="Group 17268" transform="translate(27.974 4.709)">
                    <rect id="Rectangle_58716" data-name="Rectangle 58716" width="69.755" height="69.944" transform="translate(70.96 69.944) rotate(180)" fill="#f0f0f0"/>
                    <rect id="Rectangle_58717" data-name="Rectangle 58717" width="1.205" height="69.944" fill="#e0e0e0"/>
                    <rect id="Rectangle_58718" data-name="Rectangle 58718" width="57.439" height="61.108" transform="translate(64.802 65.526) rotate(180)" fill="#b1b1b1"/>
                    <rect id="Rectangle_58719" data-name="Rectangle 58719" width="31.983" height="34.026" rx="15.992" transform="translate(52.074 51.985) rotate(180)" fill="#f5f5f5"/>
                  </g>
                  <g id="Group_17270" data-name="Group 17270" transform="translate(82.216 100.903)">
                    <g id="Group_17269" data-name="Group 17269">
                      <rect id="Rectangle_58720" data-name="Rectangle 58720" width="26.552" height="80.433" transform="translate(105.888)" fill="#e6e6e6"/>
                      <path id="Path_21471" data-name="Path 21471" d="M376.558,374.872H389.52v-11.1H362.968Z" transform="translate(-257.081 -294.439)" fill="#afafaf"/>
                      <rect id="Rectangle_58721" data-name="Rectangle 58721" width="26.552" height="80.433" fill="#e6e6e6"/>
                      <rect id="Rectangle_58722" data-name="Rectangle 58722" width="117.2" height="76.611" transform="translate(15.24)" fill="#d3d3d3"/>
                      <path id="Path_21472" data-name="Path 21472" d="M223.836,374.872H210.875v-11.1h26.552Z" transform="translate(-195.635 -294.439)" fill="#afafaf"/>
                      <rect id="Rectangle_58723" data-name="Rectangle 58723" width="97.089" height="19.172" transform="translate(25.295 28.548)" fill="#f0f0f0"/>
                      <rect id="Rectangle_58724" data-name="Rectangle 58724" width="97.089" height="19.172" transform="translate(25.295 51.817)" fill="#f0f0f0"/>
                      <path id="Path_21473" data-name="Path 21473" d="M314.871,296.267H264.028a3.71,3.71,0,0,1-3.71-3.71h58.263A3.71,3.71,0,0,1,314.871,296.267Z" transform="translate(-215.61 -265.67)" fill="#d3d3d3"/>
                      <rect id="Rectangle_58725" data-name="Rectangle 58725" width="97.089" height="19.172" transform="translate(25.295 5.278)" fill="#f0f0f0"/>
                      <path id="Path_21474" data-name="Path 21474" d="M314.871,257.224H264.028a3.71,3.71,0,0,1-3.71-3.71h58.263A3.71,3.71,0,0,1,314.871,257.224Z" transform="translate(-215.61 -249.897)" fill="#fafafa"/>
                      <path id="Path_21475" data-name="Path 21475" d="M314.871,335.311H264.028a3.71,3.71,0,0,1-3.71-3.71h58.263A3.71,3.71,0,0,1,314.871,335.311Z" transform="translate(-215.61 -281.444)" fill="#d3d3d3"/>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Shadow" transform="translate(33.442 263.53)">
                <ellipse id="_Path_" data-name="&lt;Path&gt;" cx="115.558" cy="6.749" rx="115.558" ry="6.749" fill="#d3d3d3"/>
              </g>
              <g id="Complete_list" data-name="Complete list" transform="translate(33.102 97.914)">
                <g id="Group_17275" data-name="Group 17275">
                  <g id="Group_17273" data-name="Group 17273" transform="translate(0 17.62)">
                    <path id="Path_21478" data-name="Path 21478" d="M67.468,192.892h79.164v0h9.782a10.514,10.514,0,0,1-10.361-9.782L144.484,156.6H55.54l1.569,26.513A10.511,10.511,0,0,0,67.468,192.892Z" transform="translate(-55.54 -156.599)" fill="#407bff"/>
                    <path id="Path_21479" data-name="Path 21479" d="M67.468,192.892h79.164v0h9.782a10.514,10.514,0,0,1-10.361-9.782L144.484,156.6H55.54l1.569,26.513A10.511,10.511,0,0,0,67.468,192.892Z" transform="translate(-55.54 -156.599)" fill="#fff" opacity="0.6"/>
                  </g>
                  <path id="Path_21480" data-name="Path 21480" d="M171.088,127.035v0H91.924a9.113,9.113,0,0,0-9.2,9.78l2.033,34.349a9.116,9.116,0,0,1-9.2,9.782H164.5a9.112,9.112,0,0,0,9.2-9.78l.221,3.718-2.253-38.067a9.117,9.117,0,0,1,9.2-9.782Z" transform="translate(-63.625 -127.035)" fill="#407bff"/>
                  <g id="Group_17274" data-name="Group 17274" transform="translate(28.299)">
                    <path id="Path_21481" data-name="Path 21481" d="M191.967,127.037H112.8v0h-9.782a10.516,10.516,0,0,1,10.361,9.782l7.643,129.127h88.944l-7.643-129.127A10.51,10.51,0,0,0,191.967,127.037Z" transform="translate(-103.021 -127.035)" fill="#407bff"/>
                    <path id="Path_21482" data-name="Path 21482" d="M191.967,127.037H112.8v0h-9.782a10.516,10.516,0,0,1,10.361,9.782l7.643,129.127h88.944l-7.643-129.127A10.51,10.51,0,0,0,191.967,127.037Z" transform="translate(-103.021 -127.035)" fill="#fff" opacity="0.8"/>
                  </g>
                  <path id="Path_21483" data-name="Path 21483" d="M158.814,177.49h-16.5l-.977-16.5h16.5Zm-14.675-1.94h12.617l-.747-12.62H143.392Z" transform="translate(-90.201 -140.753)" fill="#fff"/>
                  <path id="Path_21484" data-name="Path 21484" d="M162.668,242.6h-16.5l-.976-16.5h16.5Zm-14.675-1.941H160.61l-.747-12.62H147.246Z" transform="translate(-91.758 -167.058)" fill="#fff"/>
                  <path id="Path_21485" data-name="Path 21485" d="M166.521,307.711h-16.5l-.977-16.5h16.5Zm-14.675-1.94h12.617l-.747-12.62H151.1Z" transform="translate(-93.314 -193.362)" fill="#fff"/>
                  <rect id="Rectangle_58726" data-name="Rectangle 58726" width="37.943" height="1.94" transform="translate(76.668 20.237)" fill="#fff"/>
                  <rect id="Rectangle_58727" data-name="Rectangle 58727" width="33.486" height="1.94" transform="translate(77.098 27.517)" fill="#fff"/>
                  <rect id="Rectangle_58728" data-name="Rectangle 58728" width="37.943" height="1.94" transform="translate(77.529 34.797)" fill="#fff"/>
                  <rect id="Rectangle_58729" data-name="Rectangle 58729" width="37.943" height="1.94" transform="translate(78.964 59.043)" fill="#fff"/>
                  <rect id="Rectangle_58730" data-name="Rectangle 58730" width="22.271" height="1.94" transform="translate(79.395 66.323)" fill="#fff"/>
                  <rect id="Rectangle_58731" data-name="Rectangle 58731" width="11.498" height="1.94" transform="translate(79.826 73.603)" fill="#fff"/>
                  <rect id="Rectangle_58732" data-name="Rectangle 58732" width="18.972" height="1.94" transform="translate(98.797 73.603)" fill="#fff"/>
                  <rect id="Rectangle_58733" data-name="Rectangle 58733" width="37.944" height="1.94" transform="translate(81.261 97.849)" fill="#fff"/>
                  <rect id="Rectangle_58734" data-name="Rectangle 58734" width="37.944" height="1.94" transform="translate(81.692 105.129)" fill="#fff"/>
                  <rect id="Rectangle_58735" data-name="Rectangle 58735" width="20.984" height="1.94" transform="translate(82.123 112.409)" fill="#fff"/>
                  <path id="Path_21486" data-name="Path 21486" d="M154.791,174.514a1.615,1.615,0,0,1-1.11-.441l-4.053-3.827a1.617,1.617,0,1,1,2.22-2.351l2.876,2.715,6.466-6.872a1.617,1.617,0,1,1,2.355,2.216l-7.576,8.052a1.614,1.614,0,0,1-1.129.508Z" transform="translate(-93.347 -141.657)" fill="#407bff"/>
                  <path id="Path_21487" data-name="Path 21487" d="M158.644,239.625a1.617,1.617,0,0,1-1.11-.442l-4.053-3.826A1.617,1.617,0,0,1,155.7,233l2.876,2.715,6.466-6.872a1.617,1.617,0,1,1,2.355,2.215l-7.576,8.053a1.616,1.616,0,0,1-1.13.508Z" transform="translate(-94.903 -167.962)" fill="#407bff"/>
                  <path id="Path_21488" data-name="Path 21488" d="M162.5,304.735a1.617,1.617,0,0,1-1.11-.441l-4.053-3.827a1.617,1.617,0,1,1,2.22-2.351l2.876,2.715,6.465-6.872a1.617,1.617,0,1,1,2.355,2.216l-7.576,8.052a1.613,1.613,0,0,1-1.129.508Z" transform="translate(-96.46 -194.266)" fill="#407bff"/>
                </g>
              </g>
              <g id="Character" transform="translate(173.041 87.11)">
                <g id="Group_17280" data-name="Group 17280">
                  <g id="Group_17277" data-name="Group 17277" transform="translate(0 16.869)">
                    <rect id="Rectangle_58736" data-name="Rectangle 58736" width="11.92" height="144.716" transform="translate(0 15.89) rotate(-13.814)" fill="#407bff"/>
                    <rect id="Rectangle_58737" data-name="Rectangle 58737" width="3.63" height="144.716" transform="translate(8.051 13.91) rotate(-13.814)" opacity="0.2"/>
                    <g id="Group_17276" data-name="Group 17276" transform="translate(34.99 155.342)">
                      <rect id="Rectangle_58738" data-name="Rectangle 58738" width="11.92" height="8.973" rx="2.248" transform="translate(0 2.846) rotate(-13.815)" fill="#407bff"/>
                      <rect id="Rectangle_58739" data-name="Rectangle 58739" width="11.92" height="8.973" rx="2.248" transform="translate(0 2.846) rotate(-13.815)" fill="#fff" opacity="0.7"/>
                    </g>
                    <rect id="Rectangle_58740" data-name="Rectangle 58740" width="13.807" height="6.601" rx="2.075" transform="translate(33.125 154.555) rotate(-13.815)" fill="#e0e0e0"/>
                    <path id="Path_21489" data-name="Path 21489" d="M301.912,150.256,290.337,153.1l1.357-9.681.872-6.211,3.653,5.1Z" transform="translate(-290.337 -137.21)" fill="#e0e0e0"/>
                    <path id="Path_21490" data-name="Path 21490" d="M297.14,142.309l-4.526,1.113.872-6.211Z" transform="translate(-291.257 -137.21)" fill="#407bff"/>
                  </g>
                  <path id="Path_21491" data-name="Path 21491" d="M352.4,143.682c-.057,3.552-.749,10.316,1.136,12.523a26.084,26.084,0,0,0-11.26,7.321c-2.578-4.22.784-6.489.784-6.489,3.716-1.2,3.487-3.987,2.708-6.56Z" transform="translate(-310.925 -122.956)" fill="#ffb573"/>
                  <path id="Path_21492" data-name="Path 21492" d="M341.086,166c.985-3.652-1.777-4.159,2.835-5.987,3.611-1.432,10.435-1.635,12.035-.484.893.642-.333,2.9-.333,2.9Z" transform="translate(-310.839 -129.057)" fill="#263238"/>
                  <path id="Path_21493" data-name="Path 21493" d="M370.293,168.444c.266.431.459.759.671,1.131s.4.726.588,1.088c.379.726.737,1.457,1.084,2.192.692,1.471,1.293,2.981,1.849,4.508a48.5,48.5,0,0,1,2.374,9.51l.16,1.227c.027.173.052.482.072.752s.019.51.02.752a21.2,21.2,0,0,1-.2,2.625c-.2,1.65-.525,3.205-.869,4.75A77.026,77.026,0,0,1,373.377,206a1.747,1.747,0,0,1-3.385-.73l0-.031c.238-3.029.519-6.08.737-9.06.109-1.487.193-2.973.215-4.382.008-.7-.017-1.389-.066-1.971l-.05-.4-.079-.387-.222-1.04a53.806,53.806,0,0,0-2.562-8.185c-.535-1.339-1.116-2.66-1.722-3.967-.306-.652-.621-1.3-.95-1.932l-.5-.941-.471-.865-.083-.153a3.5,3.5,0,0,1,6.048-3.51Z" transform="translate(-320.023 -132.29)" fill="#ffb573"/>
                  <path id="Path_21494" data-name="Path 21494" d="M371.746,232.525l.494-.822,1.393-2.348L376.2,230.8a6.054,6.054,0,0,1-1.093,4.49l-.722.6a1.285,1.285,0,0,1-1.754-.066l-.552-.511A2.336,2.336,0,0,1,371.746,232.525Z" transform="translate(-323.099 -157.568)" fill="#ffb573"/>
                  <path id="Path_21495" data-name="Path 21495" d="M310.233,230.932l-.41-1.49a1.391,1.391,0,0,0-1.682-.98l-1.816.458-1.1.272a2.768,2.768,0,0,0-2.066,2.606l.032.89a1.523,1.523,0,0,0,1.411,1.528l1.1.108a7.427,7.427,0,0,0,4.3-2.312A1.2,1.2,0,0,0,310.233,230.932Z" transform="translate(-295.515 -157.19)" fill="#ffb573"/>
                  <path id="Path_21496" data-name="Path 21496" d="M366.686,164.965c3.133,1.387,6,4.7,8.339,10.814-.752,2.52-8.5,6.653-8.5,6.653S356.853,161.926,366.686,164.965Z" transform="translate(-319.399 -131.43)" fill="#407bff"/>
                  <path id="Path_21497" data-name="Path 21497" d="M325.246,172.352c.027,2.276-3.733,7.537.6,10.587.375,4.6.883,9.121,1.868,16.659h23.143c-2.2-8.329-.3-13.949,3.231-28.618a5.647,5.647,0,0,0-4.915-7.016c-.921-.093-1.9-.17-2.9-.219a73.633,73.633,0,0,0-10.509,0c-1.138.1-2.315.247-3.441.4A8.258,8.258,0,0,0,325.246,172.352Z" transform="translate(-303.839 -130.985)" fill="#407bff"/>
                  <path id="Path_21498" data-name="Path 21498" d="M326.874,172.3c.049,1.709.024,3.342-.008,5.011s-.107,3.32-.212,4.987a81.346,81.346,0,0,1-1.219,10.086c-.074.423-.185.861-.284,1.293s-.19.858-.329,1.308l-.193.666-.1.332a4.459,4.459,0,0,1-.145.441,7.093,7.093,0,0,1-1.012,1.8,19.51,19.51,0,0,1-1.9,2.124c-.626.626-1.264,1.2-1.908,1.758a67.7,67.7,0,0,1-8.061,5.959,1.749,1.749,0,0,1-2.255-2.628l.023-.026c2.127-2.428,4.322-4.837,6.361-7.218.5-.6.995-1.2,1.458-1.782a14.61,14.61,0,0,0,1.167-1.674c.146-.225.235-.49.154-.342-.025.089.014-.076.027-.145l.055-.259.111-.517c.083-.334.131-.714.193-1.076s.125-.724.164-1.109c.4-3.01.589-6.177.707-9.348s.184-6.4.2-9.536v-.02a3.5,3.5,0,0,1,7-.079Z" transform="translate(-297.804 -133.145)" fill="#ffb573"/>
                  <path id="Path_21499" data-name="Path 21499" d="M330.183,165.172c-2.858,1.415-5.688,4.148-6.864,10.478.744,2.495,10.947,7.306,10.947,7.306S339.946,161.522,330.183,165.172Z" transform="translate(-303.662 -131.469)" fill="#407bff"/>
                  <path id="Path_21500" data-name="Path 21500" d="M335.144,139.371c.075.451-.1.864-.4.922s-.6-.259-.673-.71.1-.864.4-.923S335.069,138.92,335.144,139.371Z" transform="translate(-307.998 -120.924)" fill="#263238"/>
                  <path id="Path_21501" data-name="Path 21501" d="M334.207,138.6l-1.142-.1S333.765,139.236,334.207,138.6Z" transform="translate(-307.599 -120.863)" fill="#263238"/>
                  <path id="Path_21502" data-name="Path 21502" d="M334.931,140.7a12.646,12.646,0,0,1-1.137,3.2,1.988,1.988,0,0,0,1.668-.018Z" transform="translate(-307.893 -121.751)" fill="#ed893e"/>
                  <path id="Path_21503" data-name="Path 21503" d="M333.721,133.781a.261.261,0,0,1-.185-.073.27.27,0,0,1,0-.381,2.746,2.746,0,0,1,2.329-.882.26.26,0,0,1,.21.312.281.281,0,0,1-.319.22,2.189,2.189,0,0,0-1.836.718A.28.28,0,0,1,333.721,133.781Z" transform="translate(-307.757 -118.408)" fill="#263238"/>
                  <path id="Path_21504" data-name="Path 21504" d="M350.2,127.8c.588,5.6.955,7.958-1.33,11.345-3.438,5.094-10.348,4.777-12.573-.492-2-4.742-2.629-13.108,2.352-16.368A7.405,7.405,0,0,1,350.2,127.8Z" transform="translate(-308.304 -113.789)" fill="#ffb573"/>
                  <path id="Path_21505" data-name="Path 21505" d="M336.74,127.008c-3.576-.629-4.437-4.735-3.112-6.523-6.027.265-10.086-6.476-3.576-10.317s12.251,2.106,11.787,4.357c8.013-.728,12.715,5.869,8.344,10.777,5.762.934,14.395,7.431,8.058,14.253,8.564,1.26,9.491,28.08-13.819,23.312-24.583-5.028-3.245-26.422-3.245-26.422S334.966,134.689,336.74,127.008Z" transform="translate(-305.039 -108.907)" fill="#263238"/>
                  <path id="Path_21506" data-name="Path 21506" d="M375.463,152.214l-.508-.312c2.162-3.516,2.52-6.714,1.063-9.505-1.879-3.6-6.435-5.685-9.965-5.886l.033-.6c3.7.212,8.479,2.411,10.46,6.206C378.085,145.068,377.71,148.559,375.463,152.214Z" transform="translate(-320.926 -119.818)" fill="#263238"/>
                  <path id="Path_21507" data-name="Path 21507" d="M364.734,134.132c2.061-.34,1.085-3.694-.428-4.587s-8.766.56-7.966,2.868S361.975,134.588,364.734,134.132Z" transform="translate(-316.977 -117.137)" fill="#fff"/>
                  <path id="Path_21508" data-name="Path 21508" d="M366.075,133.08c2.051-.4,1.225-3.116-.312-3.968s-7.874-.221-7.011,2.064S363.329,133.611,366.075,133.08Z" transform="translate(-317.944 -116.923)" fill="#407bff"/>
                  <path id="Path_21509" data-name="Path 21509" d="M330.839,122.663a6.171,6.171,0,0,1-3.776-1.354,5.73,5.73,0,0,1-2.405-5.069c.157-1.116.855-2.649,3.288-3.621l.221.554a4.147,4.147,0,0,0-2.919,3.15,5.134,5.134,0,0,0,2.181,4.516,5.245,5.245,0,0,0,5.233.87l.227.551A5.382,5.382,0,0,1,330.839,122.663Z" transform="translate(-304.187 -110.407)" fill="#263238"/>
                  <path id="Path_21510" data-name="Path 21510" d="M343.221,138.794c-.268,1.463-.354,2.087-1.116,2.777a1.619,1.619,0,0,1-2.821-1.055c-.115-1.351.343-3.517,1.708-3.975A1.808,1.808,0,0,1,343.221,138.794Z" transform="translate(-310.104 -120.033)" fill="#ffb573"/>
                  <path id="Path_21511" data-name="Path 21511" d="M340.03,146.8l-.481-.859a.242.242,0,0,1,0-.235l.481-.86a.24.24,0,0,1,.42,0l.481.86a.242.242,0,0,1,0,.235l-.481.859A.24.24,0,0,1,340.03,146.8Z" transform="translate(-310.206 -123.376)" fill="#fff"/>
                  <path id="Path_21512" data-name="Path 21512" d="M327.319,224.03s-.818,29.882-1.906,49.54c-1.156,18.039-2.64,60.23-2.64,60.23l4.555.237s10.09-35.5,9.662-56.951c11.84-30,7.582-52.156,7.582-52.156Z" transform="translate(-303.441 -155.417)" fill="#ffb573"/>
                  <g id="Group_17278" data-name="Group 17278" transform="translate(9.575 176.382)">
                    <path id="Path_21513" data-name="Path 21513" d="M316.5,404.963a.628.628,0,0,0-.7-.015,12.751,12.751,0,0,1-7.567,2.518c-3.028.134-2.279,1.8,2.053,2.34,5.093.636,6.185,1.68,7.83,2.095,1.586.4,2.95-.842,2.922-4.3a.6.6,0,0,0-.384-.543A19.965,19.965,0,0,1,316.5,404.963Z" transform="translate(-306.401 -404.85)" fill="#263238"/>
                  </g>
                  <path id="Path_21514" data-name="Path 21514" d="M340.55,224.03s8.338,35.364,13.179,51.821c5.084,17.283,9.109,56.727,9.081,58.036h3.944s5.095-31.971-1.219-58.053c-.58-42.3-7.89-51.8-7.89-51.8Z" transform="translate(-310.623 -155.417)" fill="#ffb573"/>
                  <g id="Group_17279" data-name="Group 17279" transform="translate(45.458 177)">
                    <path id="Path_21515" data-name="Path 21515" d="M373.234,405.929a.5.5,0,0,0-.6.156,9.875,9.875,0,0,1-4.77,4.075c-2.488,1.063-1.114,2.519,2.785,1.984,4.584-.629,4.646.117,6.194.117,1.492,0,2.27-1.538,1.144-4.879a.641.641,0,0,0-.5-.433A18.142,18.142,0,0,1,373.234,405.929Z" transform="translate(-366.608 -405.887)" fill="#263238"/>
                  </g>
                  <path id="Path_21516" data-name="Path 21516" d="M328.072,223.453s-3.937,26.187-3.382,61.966a126.462,126.462,0,0,0,36.7-5.662s.177-46.378-10.175-56.3C345.238,221.844,328.208,222.913,328.072,223.453Z" transform="translate(-304.194 -154.84)" fill="#263238"/>
                  <path id="Path_21517" data-name="Path 21517" d="M330.887,281.7a.149.149,0,0,1-.149-.142c-1.113-22.857,1.589-55.125,1.616-55.448a.143.143,0,0,1,.161-.136.149.149,0,0,1,.136.161c-.027.323-2.728,32.571-1.615,55.408a.149.149,0,0,1-.142.156Z" transform="translate(-306.55 -156.201)" fill="#fff" opacity="0.2"/>
                  <path id="Path_21518" data-name="Path 21518" d="M357.3,268.94l3.387-17.964,1.368,4.3Z" transform="translate(-317.392 -166.303)" opacity="0.2"/>
                </g>
              </g>
            </g>
          </svg>
            <!-- <img src="@/assets/images/judging.png" width="300" style="" /> -->
            <div class="text-center lg:w-2/3 w-full">
              <div class="mb-8 mt-8 leading-relaxed">
                <h3 v-if="this.$route.params.status=='toreview' && this.currentRound == 0 && user.userRole===`workspaceOwner`" class="text-2xl text-gray-900">No Judging Round In Progress as all the submissions need to be in the same judging round to judge them</h3>
                <h3 v-else-if="this.$route.params.status=='toreview' && this.currentRound == 0" class="text-2xl text-gray-900">No Judging Round In Progress</h3>
                <!-- <h3 v-else-if="this.$route.params.status=='toreview' && user.userRole===`workspaceOwner`" class="text-2xl text-gray-900">All the reviews in this round are completed</h3> -->
                <h3 v-else-if="this.$route.params.status=='toreview'" class="text-2xl text-gray-900">Nothing To Review</h3>
                <h3 v-if="this.$route.params.status=='allcompleted'" class="text-2xl text-gray-900">Nothing To Review</h3>
                <h3 v-if="this.$route.params.status=='history'" class="text-2xl text-gray-900">No History Available</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      v-if="openPanelFlag && selectedIdea"
      class="
        flex-grow
        fixed
        -right-5
        top-0
        bg-white
        pr-5
        ml-20
        z-30
        h-screen
        transition-all
        w-5/12
      "
    >
      <judging-panel-layout
        @openPanel="openPanel"
        :selectedIdea="selectedIdea"
        :selectedInitiative="selectedInitiative"
        :activitiesToUpdate="activitiesToUpdate"
       @getAllIdeasCount="getAllIdeasCount"
        @pageChanged="pageChanged"
        @viewDetail="viewDetail"
        :user="user"
        :parentInitiative="parentInitiative"
        :allComments="allComments"
        @updateComments="updateComments"
        @showToaster="showToaster"
      />
    </div>
  </div>
  <phase-modal
    v-if="isModalVisible"
    :initiative="selectedInitiative"
    :activitiesToUpdate="activitiesToUpdate"
    @close="closeModal"
    @markComplete="completeActivity"
  ></phase-modal>
  <toaster
    v-if="showToasterFlag"
    text="Your judging feedback has been saved. Thank you."
    @showToaster = "showToaster"
  />
</template>
<script>
import { mapGetters } from "vuex";
import feathersClient from "../services/feathersClient";
import IdeaCard from "../components/JudgingPanel/IdeaCard.vue";
import JudgingPanelLayout from "../components/JudgingPanel/JudgingPanelLayout.vue";
import CommanFilter from "../components/JudgingPanel/CommanFilter.vue";
import PhaseModal from "../components/progressView/PhaseModal.vue";
import pagination from "../components/Pagination.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import InfoCard from "../components/InfoCard.vue";
import Toaster from "../components/Toaster.vue";
import { isEmpty } from "lodash";
export default {
  data() {
    return {
      openPanelFlag: false,
      showToasterFlag: false,
      selectedIdea: null,
      layout: "grid",
      isModalVisible: false,
      judgingHistoryTabSelected: false,
      currentRoundTabSelected: false,
      myData: [
        {
          value: "challengeType",
          label: "Theme",
          type: "null",
          options: this.challengeTypes,
        },
        {
          value: "marketHorizon",
          label: "Market Horizon",
          type: "tags",
          options: this.marketHorizons,
        },
        {
          value: "source",
          label: "Source",
          type: "tags",
          options: this.sources,
        },
        {
          value: "status",
          label: "Status",
          type: "tags",
          options: ["In design", "Active"],
        },
      ],
      parentInitiative: {},
      allComments: [],
      componentKey: 0,
      isPostIdeaFlag: true,
      toBeReviewdIdeas: [],
      toBeReviewdIdeasInLastWeek: [],
      reviewCompletedIdeas: [],
      reviewCompletedInLastWeek: [],
      judgingRounds: [],
      pageChangePayload: {},
      categories:[],
    };
  },

  components: {
    IdeaCard,
    JudgingPanelLayout,
    CommanFilter,
    PhaseModal,
    pagination,
    LoaderView,
    InfoCard,
    Toaster
  },
  computed: {
    ...mapGetters([
      "initiatives",
      "selectedInitiative",
      "activitiesToUpdate",
      "playbook",
      "marketHorizons",
      "challengeTypes",
      "sources",
      "user",
      "selectedJudgingInitiative",
      "pagination",
      "loadingFlag",
      "currentRound",
      "judgingHistoryRounds",
      "totalJudgingRounds"
    ]),
    filterLabels: function () {
      return this.myData;
    },
    filterChallengeTypeInitiatives:function (){
      if(this.initiatives.length>0){
          let filteredData = []
          this.initiatives.forEach((initiative)=>{
            if(initiative?.parentInitiativeId && initiative?.parentInitiativeId?.metaData && initiative?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(initiative?.parentInitiativeId?.metaData?.challengeType)){
              if(this.$route.params.status !== 'history' && (initiative.status == 6 || initiative.status == 7)){
              let foundRecord = initiative?.metaData?.reviews?.filter((review)=> review?.lastUpdatedBy == this.user.aspenifyUserId);
                    if(foundRecord?.length>0){
                      filteredData.push(initiative)                  
                  }
                }else{
                  filteredData.push(initiative)
                }     
            }
          })
          return filteredData;
      }
      return this.initiatives;
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
  },
  created: async function () {
    await this.getAllIdeasCount();
    if(this.$route.params.status == 'history' && this.judgingHistoryRounds.length <= 0){      
    } else if(this.$route.params.status == 'allcompleted'){
      this.pageChangePayload = {
        currentPage: 1,
        searchQuery: this.filterQuery,
        sortBy: this.sortBy,
        isJudging: true,
        status: "allcompleted"
      }
      this.pageChanged(1);
    } else {
      this.pageChanged(1);
    }
  },
  beforeUpdate: function () {
    if(this.$route.params.status == 'history'){
      this.judgingHistoryTabSelected = true;
      this.currentRoundTabSelected = false;
    } else {
      this.judgingHistoryTabSelected = false;
      this.currentRoundTabSelected = true;      
    }
  },
  mounted: function () {
    this.getFilterChallengeTypes();
    this.$nextTick(async function () {
      this.$store.dispatch("resetActivitiesToUpdateList");
    });
  },
  watch: {
    // wheeen searchQuery is reset
    searchQuery: function (newValue, oldValue) {
      if (isEmpty(newValue)) {
        this.pageChanged(1);
      }
    },
    selectedIdea() {
      this.componentKey += 1;
    },
  },
  // pageChanged(page) {
  //   this.$store.dispatch("getAllInitiatives", {
  //     currentPage: page,
  //     searchQuery: this.searchQuery,
  //     sortBy: this.sortBy,
  //     isJudging: true,
  //     status: this.$route.params.status,
  //   });
  // },

  methods: {
    loader() {
      this.isPostIdeaFlag = false;
    },
    async openPanel(flag) {
      this.openPanelFlag = flag;
    },
    async showToaster(flag){
      this.showToasterFlag = flag;
    },
    async setParentIdea(idea) {
      this.parentInitiative = idea;
    },
    async pageChangeQuery(payload){
      this.pageChangePayload = payload;
    },
    async pageChanged(page) {
    await this.$store.dispatch("setUpdateInitiatives", []);
      if (!isEmpty(this.pageChangePayload)) {
        this.pageChangePayload.currentPage = page;
        this.$store.dispatch("getAllInitiatives", this.pageChangePayload);
      } else {
        let filteredCurrentRound = this.totalJudgingRounds.filter(round=>round.id==this.currentRound);
        if(filteredCurrentRound?.length>0){
          let baseId = filteredCurrentRound[0].baseId;
          let query = {
            currentPage: page,
            searchQuery: this.filterQuery,
            sortBy: this.sortBy,
            isJudging: true,
            baseId: baseId
          }  
          await this.$store.dispatch("getAllInitiatives", query);
        } else {
          await this.$store.dispatch("setUpdateInitiatives", []);
        }
      }
    },
    async selectedIdeaAction(initiative) {
      let payload = {
        id: initiative.id
      }
      let result = await this.$store.dispatch(
        "getJudgingInitiativeById",
        payload
      );
      this.selectedIdea = result;
      this.updateComments(initiative.parentInitiativeId._id);
    },
    async updateComments(initiativeId) {
      this.allComments = await this.$store.dispatch(
        "getAllComments",
        initiativeId._id
      );
    },
    
    updateLayout(layout) {
      this.layout = layout;
    },
    async viewDetail(id) {
      await this.$store.dispatch("getInitiativeLogs", id);
      await this.$store.dispatch("getInitiativeById", id);
      this.$store.dispatch("setPhaseActivitiesFlag", false);
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      console.log("🚀 ~ file: Judging.vue ~ line 363 ~ closeModal ~ isModalVisible", this.isModalVisible)
    },
    queryFilter(queryParams) {},
    setSearchQuery(query) {
      this.filterQuery = query;
    },
    async getFilterChallengeTypes(){
      const initiatives = feathersClient.service("initiatives");
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      const myIdeaQuery={
        $and: [
          {
            status: { $in: [0, 1, 2] },
          },
        ],
        $or:[{
          "metaData.teamMembers": {
            $elemMatch: { email: { $regex: this.user?.email?.toLowerCase(), $options: 'i' } },
          },
        },
        {
          "metaData.mentors": { $elemMatch: { email: { $regex: this.user?.email?.toLowerCase(), $options: 'i' } }, },
        },
        {
          "metaData.teamLead": { $elemMatch: { email: { $regex: this.user?.email?.toLowerCase(), $options: 'i' } }, },
        },
        {
          "metaData.submitter": { $elemMatch: { email: { $regex: this.user?.email?.toLowerCase(), $options: 'i' } }, },
        }]
      }
      const myIdeas = await initiatives.find({
        query: myIdeaQuery,
        headers: {
          Authorization: tokenString,
        },
      });
      myIdeas?.data?.map((idea)=>{
        if (!this.categories.includes(idea?.metaData?.challengeType)){
          this.categories.push(idea?.metaData?.challengeType)
        }
      })
    },
    async getAllIdeasCount() {
      await this.$store.dispatch("readInitiative");
      this.toBeReviewdIdeas = [];
      this.toBeReviewdIdeasInLastWeek = [];
      this.reviewCompletedIdeas = [];
      this.reviewCompletedInLastWeek = [];

      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives");
      let aspenifyUserId = this.user.aspenifyUserId;
      let organizationId = this.user.organizationId;
      let phaseJudgesTeamId = process.env.VUE_APP_PHASE_JUDGES_TEAM_ID
      const subOrgService = feathersClient.service("suborganizations");
      const playbookPhasesData = this.playbook.activities;
      let judgingPlaybookBaseIds = [];
      for(let phase of playbookPhasesData){
        if(phase?.metaData?.judgingPlaybookBaseId){
          judgingPlaybookBaseIds.push(phase.metaData.judgingPlaybookBaseId);
        }
      }
      const query = {
        $and: [
          {
            status: { $in: [0, 1, 2, 6, 7] },
            parentInitiativeId: { $exists: true },
            "baseId": { $in: judgingPlaybookBaseIds },
          },
        ],
        $populate: [{
          path: "parentInitiativeId",
          populate: {
            "path": "parentInitiativeId",
            "select": "name metaData"
          }
        },
        {
          path: "teamId",
          populate: {
            "path": "members.accountUserId",
            "select": "firstName lastName email profilePic lastLoginDate"
          }
        }]
      };
      query.isJudging = true;
      query.phaseJudgesTeamId = phaseJudgesTeamId;
      const result = await initiatives.find({
        query: query,
        headers: {
          Authorization: tokenString,
        },
      });
      var now = new Date();
      var daysAfterLastThursday = -7 + 4 - now.getDay(); // 7 = number of days in week, 4 = the thursdayIndex (0= sunday)
      var currentMs = now.getTime();
      var lastThursday = new Date(
        currentMs + daysAfterLastThursday * 24 * 60 * 60 * 1000
      );
      for(let i=this.currentRound-1; i>=0; i--){
        if(this.judgingHistoryRounds[i]){
          this.judgingRounds.push(this.judgingHistoryRounds[i]);
        }
      }
      if(this.judgingRounds.length==1){
        this.judgingRounds = [];
      }
      if (result?.data?.length > 0) {
        let filteredCurrentRound = this.totalJudgingRounds.filter(round=>round.id==this.currentRound);
        if(filteredCurrentRound?.length>0){
          result?.data.forEach((record) => {
            if(record.baseId == filteredCurrentRound[0].baseId){
              if((record.status == 0 || record.status == 1)){
                if (!record.hasOwnProperty("metaData")) {
                  if(record?.parentInitiativeId && record?.parentInitiativeId?.metaData && record?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(record?.parentInitiativeId?.metaData?.challengeType)){
                  this.toBeReviewdIdeas.push(record);
                  }
                } else if(record.hasOwnProperty("metaData") && !record.metaData.reviews){
                  if(record?.parentInitiativeId && record?.parentInitiativeId?.metaData && record?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(record?.parentInitiativeId?.metaData?.challengeType)){
                  this.toBeReviewdIdeas.push(record);
                  }
                } else if(record?.metaData?.reviews?.length>0){
                  let foundRecord = record.metaData.reviews.filter((review)=> review.lastUpdatedBy == aspenifyUserId);
                  if(foundRecord?.length>0){
                    if(record?.parentInitiativeId && record?.parentInitiativeId?.metaData && record?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(record?.parentInitiativeId?.metaData?.challengeType)){
                    this.reviewCompletedIdeas.push(record);
                  }
                    if (new Date(record.createdAt) >= new Date(lastThursday) && new Date(record.createdAt) < new Date()) {
                      if(record?.parentInitiativeId && record?.parentInitiativeId?.metaData && record?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(record?.parentInitiativeId?.metaData?.challengeType)){
                      this.reviewCompletedInLastWeek.push(record);
                  }
                    }
                  } else {
                    if(record?.parentInitiativeId && record?.parentInitiativeId?.metaData && record?.parentInitiativeId?.metaData?.challengeType && !this.categories?.includes(record?.parentInitiativeId?.metaData?.challengeType)){
                    this.toBeReviewdIdeas.push(record);
                    }
                  }
                }
              } else if(record.status == 2) {
                this.reviewCompletedIdeas.push(record);  
                // let foundRecord = record.metaData.reviews.filter((review)=> review.lastUpdatedBy == aspenifyUserId);
                  // if(foundRecord?.length>0){
                  //   this.reviewCompletedIdeas.push(record);
                  //   if (new Date(record.createdAt) >= new Date(lastThursday) && new Date(record.createdAt) < new Date()) {
                  //     this.reviewCompletedInLastWeek.push(record);
                  //   }
                  // }
                  // if(foundRecord?.length>0){
                  //   // let currentRoundDetails = this.judgingHistoryRounds.filter(round=>round.id == this.currentRound);
                  //   if(filteredCurrentRound.length > 0 && filteredCurrentRound[0].baseId == record.baseId){
                  //     this.reviewCompletedIdeas.push(record);
                  //     if (new Date(record.createdAt) >= new Date(lastThursday) && new Date(record.createdAt) < new Date()) {
                  //       this.reviewCompletedInLastWeek.push(record);
                  //     }
                  //   }
                  // }
                } else if(record.status == 6 || record.status == 7) {
                    let foundRecord = record?.metaData?.reviews?.filter((review)=> review?.lastUpdatedBy == aspenifyUserId);
                    if(foundRecord?.length>0){
                    this.reviewCompletedIdeas.push(record);                  
                  }
                } else if(record.status == 2 && record?.teamId?.members?.length>0 && this.judgingRounds.length > 0) {
                let userInTeam = record.teamId.members.filter(member=>member.accountUserId._id == aspenifyUserId);
                if(userInTeam?.length>0){
                  for (let round of this.judgingRounds) {
                    if (round.baseId == record.baseId) {
                      round.count++;
                    }
                  }
                }
              }
            }
          });
        }
        // if(this.judgingRounds?.length>0){
        //   this.judgingRounds = this.judgingRounds.filter((round)=> round.count>0);
        //   let newJudgingRounds = [...this.judgingRounds];
        //   this.judgingRounds = [];
        //   for(let round of newJudgingRounds){
        //     let foundRound = this.judgingRounds.filter(uniqueRound=>uniqueRound.baseId == round.baseId);
        //     if(foundRound.length == 0){
        //       this.judgingRounds.push(round);
        //     }
        //   }
        // }
      }
      this.openPanelFlag = false
    },
  },
};
</script>
<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 640px) {
  .container-width {
    width: 100%;
    display: inline-block;
  }
  .drawer-container-width{
    width : 100%;
    display:inline-block
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
  .container-width {
    width: 100%;
    display: inline-block;
  }
  .drawer-container-width{
    width : 100%;
    display:inline-block
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1024px) {
  .container-width {
    width: 50%;
    display: inline-block;
  }
  .drawer-container-width{
    width : 100%;
    display:inline-block
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1280px) {
  .container-width {
    width: 25%;
    display: inline-block;
  }
  .drawer-container-width{
    width : 46%;
    display:inline-block
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1536px) {
  .container-width {
    width: 25%;
    display: inline-block;
  }
  .drawer-container-width{
    width : 47%;
    display:inline-block
  }
}
@media only screen and (min-width: 1920px) {
  .container-width {
    width: 25%;
    display: inline-block;
  }
  .drawer-container-width {
    width: 31.7%;
    display: inline-block;
  }
}
@media only screen and (min-width: 2560px) {
  .container-width {
    width: 25%;
    display: inline-block;
  }
  .drawer-container-width {
    width: 32.2%;
    display: inline-block;
  }
}
@media only screen and (min-width: 3840px) {
  .container-width {
    width: 25%;
    display: inline-block;
  }
  .drawer-container-width {
    width: 16.66%;
    display: inline-block;
  }
}
/* 'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
      '4xl': '2560px',
      '5xl': '3840px', */
</style>
