<template>
    <TransitionRoot appear :show="openConfirmDeleteJudge" as="template">
      <Dialog as="div" :open="openConfirmDeleteJudge" @close="closeModal">
        <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
              leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>
  
            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
  
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
              <div class="
                    inline-block
                    w-full
                    max-w-xl
                    p-6
                    my-8
                    overflow-hidden
                    text-left
                    align-middle
                    transition-all
                    transform
                    bg-white
                    shadow-xl
                    rounded-md
                  ">
                <DialogTitle as="h3" class="text-xl font-lg leading-6 text-gray-900">
                  Delete a Judge
                </DialogTitle>
                <div class="mt-0">
                    <div class="p-4 mt-2 mb-4 text-sm text-red-500 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                  <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  This process is irreversible
                </div>  
                  <p class="text-sm text-gray-500">
                    Do you want to remove <b>{{ `${this.selectedUser?.firstName} ${this.selectedUser?.lastName}` }} </b> from judging team on submission <b>{{ initiative.name }} ?</b>
                  </p>
                </div>
                <div class="mt-4">
                  <button type="button" class="
                        inline-flex
                        justify-center
                        text-sm
                        font-medium
                        mr-3
                        hover:scale-110
                        focus:outline-none
                        px-4
                        py-2
                        rounded
                        cursor-pointer
                        hover:bg-indigo-600
                        bg-indigo-500
                        text-white
                        duration-200
                        ease-in-out
                        transition
                        disabled:opacity-30
                      " @click="this.updateJudge">
                    Confirm
                  </button>
                  <button type="button" @click="this.cancel()" class="
                        inline-flex
                        justify-center
                        text-sm
                        font-medium
                        hover:scale-110
                        focus:outline-none
                        px-4
                        py-2
                        rounded
                        cursor-pointer
                        hover:bg-gray-200
                        bg-gray-100
                        text-gray-700
                        duration-200
                        ease-in-out
                        transition
                      ">
                    Cancel
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  <script>
  import { ref } from "vue";
  import { mapGetters } from "vuex";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  
  export default {
    name: "DeleteJudgeConfirm",
    data() {
      return {
  
      };
    },
    props: {
      openConfirmDeleteJudge: Boolean,
      currentPhaseIndex: Number,
      initiative: Object
    },
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    computed: {
      ...mapGetters(["phaseNames", "selectedUser", "currentPhaseTeamMembers"]),
    },
    methods: {
      updateJudge: async function () {
        this.$emit('createJudgingTeamForPhase', {
          initiative: this.initiative,
          currentPhaseIndex: this.currentPhaseIndex,
          phaseTeamMembers: this.currentPhaseTeamMembers
        })
      },
      getTeamMembers: async function () {
        if(this.initiative.phaseInfo.metaData.teamId){
          let payload = {
            teamId: this.initiative.phaseInfo.metaData.teamId,
            mutation: 'CURRENT_PHASE_TEAM_MEMBERS'
          }
          await this.$store.dispatch("getTeamMembersById", payload);
        }
        if(this.initiative.phaseInfo.metaData.selectedJudgesTeamId){
          let payload = {
            teamId: this.initiative.phaseInfo.metaData.selectedJudgesTeamId,
            mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
          }
          await this.$store.dispatch("getTeamMembersById", payload);
        }
        if(this.selectedTeamMembersPhase){
          this.filterPhaseTeamMembersOnCategory.map((member)=>{
            this.selectedTeamMembersPhase.map((selectedMember)=>{
              if(member._id == selectedMember._id){
                member.isChecked = true;
              }
            })
          })
        }
      },
      cancel: async function () {
              await this.getTeamMembers();
              await this.$emit('openConfirmationDeleteJudgeModal', false);
          },
    }
  };
  </script>
    