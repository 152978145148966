<template>
  <div class="px-6 py-6" :key="componentKey">
    <div class="flex">
      <h3 class="flex-grow text-blue-800 font-bold">Manage webpages</h3>
      <div>
        <button
          class="
            inline-flex
            text-white
            bg-indigo-500
            border-0
            py-1
            px-3
            focus:outline-none
            hover:bg-indigo-600
            rounded
            text-sm
          "
          @click="addNewPage"
        >
          Add New Tabs
        </button>
      </div>
    </div>

    <div class="w-full" v-if="list?.length !== 0">
      <draggable
        :list="list"
        item-key="name"
        ghost-class="ghost"
        :move="checkMove"
        @end="onEnd"
        v-model="myList"
      >
        <template #item="{ element }">
          <div
            class="
              flex
              bg-white
              rounded
              px-4
              py-3
              mt-3
              place-items-center
              hover:shadow-lg
              cursor-no-drop
            "
          >
            <!-- <pre>{{element.data}}</pre> -->
            <div class="flex-shrink pr-2 text-gray-300 grabCursor">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block -ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block -ml-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                />
              </svg>
            </div>
            <div class="flex-grow">{{ element.list.name }}</div>
            <div class="place-items-center gap-4 text-sm">
              <div class="flex items-center place-items-center">
                <div
                  class="text-blue-400 pr-3 text-xs"
                  v-if="element.list.isIndexPage"
                >
                  Home Page
                </div>
                <div
                  class="w-36 flex items-center border-l pr-3"
                  v-tooltip="{
                    text:
                      element.data?.createdBy?.firstName +
                      ' ' +
                      element.data?.createdBy?.lastName,
                    theme: {
                      placement: 'left',
                    },
                  }"
                >
                  <img
                    :src="element.data?.createdBy?.profilePic"
                    alt="avatar"
                    class="w-8 h-8 object-cover rounded-full mx-3"
                  />
                  <div class="w-20 text-xs">
                    <div class="text-gray-500">Published On</div>
                    <div>{{ formatDate(element.data.createdAt, $event) }}</div>
                  </div>
                </div>

                <div
                  class="w-36 flex items-center border-l pr-3"
                  v-tooltip="{
                    text:
                      element.data?.lastUpdatedBy?.firstName +
                      ' ' +
                      element.data?.lastUpdatedBy?.lastName,
                    theme: {
                      placement: 'left',
                    },
                  }"
                >
                  <img
                    :src="element.data?.lastUpdatedBy?.profilePic"
                    alt="avatar"
                    class="w-8 h-8 object-cover rounded-full mx-3"
                  />
                  <div class="w-24 text-xs">
                    <div class="text-gray-500">Updated On</div>
                    <div>{{ formatDate(element.data.updatedAt, $event) }}</div>
                  </div>
                </div>

                <!-- <div class="text-xs w-20 items-center">
                  <div>Publisd On</div>
                  <div>{{ formatDate(element.data.createdAt, $event) }}</div>
                </div> -->

                <div class="relative inline-block text-left dropdown">
                  <span class="rounded-md shadow-sm"
                    ><button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="true"
                      @click="showMenu = true"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                        />
                      </svg></button
                  ></span>
                  <div
                    v-if="showMenu"
                    class="
                      opacity-0
                      invisible
                      dropdown-menu
                      transition-all
                      duration-300
                      transform
                      origin-top-right
                      -translate-y-2
                      scale-95
                      relative
                      z-20
                    "
                  >
                    <div
                      class="
                        absolute
                        right-0
                        w-48
                        p-2
                        mt-2
                        origin-top-right
                        bg-white
                        border border-gray-200
                        divide-y divide-gray-100
                        rounded-md
                        shadow-lg
                        outline-none
                      "
                      aria-labelledby="headlessui-menu-button-1"
                      role="menu"
                    >
                      <button
                        href="javascript:void(0)"
                        :class="dotMenuButtonCss"
                        :disabled="element.data?.metaData?.isIndexPage"
                        @click="updatePage(element, $event, 'setHomePage')"
                      >
                        Set as Homepage
                      </button>
                      <button
                        href="javascript:void(0)"
                        :class="dotMenuButtonCss"
                        :disabled="false"
                        @click="redirectToEdit(element, $event, 'edit')"
                      >
                        Edit
                      </button>
                      <button
                        href="javascript:void(0)"
                        :class="dotMenuButtonCss"
                        :disabled="element.data?.metaData?.isIndexPage"
                        @click="removePage(element, $event)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else class="mt-5">
      <info-card message="No Pages available!" />
    </div>
  </div>
</template>

<script >
import draggable from "vuedraggable";
import { format } from "fecha";
import { mapGetters } from "vuex";
import feathersClient from "../../services/feathersClient";
import InfoCard from "../../components/InfoCard.vue";
export default {
  data() {
    return {
      drag: false,
      showMenu: false,
      list: [],
      dragging: false,
    };
  },
  watch: {
    userMetaData() {
      this.componentKey += 1;
    },
  },

  created: async function () {
    await this.$store.dispatch("getPages");
    await this.allPages();
  },

  components: {
    draggable,
    InfoCard,
  },
  computed: {
    ...mapGetters(["user", "webPages"]),
    dotMenuButtonCss() {
      return "w-full text-left block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-30";
    },
  },
  methods: {
    onEnd: async function (e) {
      const TeamResourceService = feathersClient.service("teamResources");
      let oldIndex=e.oldIndex+1;
      let newIndex=e.newIndex+1;
      for (let i = 0; i < this.list.length; i++) {
        if(oldIndex>=newIndex){
          if (this.list[i].data.metaData.id < oldIndex && this.list[i].data.metaData.id >= newIndex ) {
          await TeamResourceService.patch(this.list[i].data._id, {
            $set: {
              "metaData.id": this.list[i].data.metaData.id + 1,
            },
          });
        }
        }else{
           if (this.list[i].data.metaData.id > oldIndex && this.list[i].data.metaData.id <= newIndex ) {
          await TeamResourceService.patch(this.list[i].data._id, {
            $set: {
              "metaData.id": this.list[i].data.metaData.id - 1,
            },
          });
        }
        }
      }
      let result = await TeamResourceService.patch(
        e.item._underlying_vm_.data._id,
        {
          $set: {
            "metaData.id": e.newIndex + 1,
          },
        }
      );
      await this.$store.dispatch("getPages");
      await this.allPages();
    },
    checkMove: function (e) {},
    formatDate(date) {
      return format(new Date(date), "MMM D, YYYY");
    },
    addNewPage() {
      this.$router.push({ name: "AddUpdateWebpage", params: { type: "new" } });
    },
    redirectToEdit(element) {
      this.$router.push({
        name: "AddUpdateWebpage",
        params: { type: element.data.metaData.pageId },
      });
    },
    async removePage(element) {
      await this.$store.dispatch("removePage", element);
      await this.$store.dispatch("getPages");
      this.showMenu = false;
    },
    async updatePage(element, event, page) {
      let payload = {
        user: this.user,
        id: element.data._id,
        page: page,
      };

      if (page === "setHomePage") {
        payload.key = "metaData.isIndexPage";
        payload.value = true;
      }
      if (page === "edit") {
        payload.key = "metaData.id";
        payload.value = 1;
      }

      await this.$store.dispatch("updatePage", payload);
      await this.$store.dispatch("getPages");
      this.showMenu = false;
    },
    allPages() {
      let pages = this.$store.getters.webPages;
      let page = [];
      for (let index = 0; index < pages.length; index++) {
        page.push({
          data: pages[index],
          list: pages[index].metaData,
        });
      }
      this.list = page;
      // return page.sort((a, b) => {
      //   return new Date(a.createdAt) - new Date(b.createdAt);
      // });
    },
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
.grabCursor {
  cursor: grabbing !important;
}
</style>
