<template>
  <div>
    <div class="font-bold mb-2">
      Attachments
      <div class="float-right text-right" v-if="isEditAccess">
        <file-upload
          :accessToken="accessToken"
          :attachments="formData.attachments"
          @fileUploaded="fileUploaded"
        />
      </div>
      <div class="float-right text-right mr-2" v-if="isEditAccess">
        <button
          class="
            bg-blue-500
            text-white
            hover:text-white
            py-1
            px-2
            border border-blue-500
            hover:border-transparent
            rounded
            text-sm
            font-semibold
            ml-2
          "
          @click="addFilesModal()"
        >
          Add Link
        </button>
        <add-files
          :openAddFile="openAddFile"
          @openAddFileModal="openAddFileModal"
          @fileUploaded="fileUploaded"
          :files="formData.attachments"
          :fileObj="fileObj"
          :todo="todo"
        ></add-files>
      </div>
    </div>

    <div class="mt- text-gray-600 text-md">
      <div class="mt-6" v-if="formData.attachments.length === 0">
        <info-card message="No files uploaded!" />
      </div>

      <div v-else class="mt-0 w-full float-left">
        <ul class="mt-4 list-reset flex flex-col">
          <li
            v-for="(attachment, index) in formData.attachments"
            :key="index"
            class="
              rounded-md
              relative
              block
              border
              p-4
              border-grey
              mb-2
              bg-white
            "
          >
            <div class="flex flex-row justify-between relative">
              <div class="flex flex-row">
                <!-- <img
                  class="w-10 h-10 mr-3 rounded-full"
                  :src="attachment.uploadedBy.profilePic"
                  alt=""
                /> -->

                <div class="ml-2 mr-2 relative flex pt-1">
                  <svg
                    v-if="attachment.documentUrl"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <svg
                    v-if="!attachment.documentUrl"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="overflow-hidden grid">
                  <div class="truncate block" 
                  :class="{' hover:underline cursor-pointer' : !attachment.documentUrl}"
                  @click="!attachment.documentUrl ? downloadAttachment(attachment) : null"
                  >
                    {{ attachment.documentName }}
                  </div>
                  <div
                    :title="attachment.documentUrl"
                    class="
                      truncate
                      block
                      text-gray-400 text-sm
                      -mt-1
                      hover:underline
                      cursor-pointer
                    "
                    @click="goToUrl(attachment)"
                  >
                    {{ attachment.documentUrl }}
                  </div>
                  <div class="text-gray-500">
                    {{ timeago.format(new Date(attachment.uploadedAt)) }} .

                    <span
                      class="hover:underline cursor-pointer"
                      @click="goToProfile(attachment?.uploadedBy)"
                      v-if="attachment?.uploadedBy?.firstName"
                    >
                      {{
                        attachment.uploadedBy.firstName +
                        " " +
                        attachment.uploadedBy.lastName
                      }}
                    </span>
                    <span
                      class="hover:underline cursor-pointer"
                      v-else
                      @click="goToProfile(attachment?.uploadedBy)"
                    >
                      {{ user.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-sm text-gray-400">
                <div class="dropdown float-right">
                  <span class="rounded-md shadow-sm" v-if="isEditAccess">
                    <button
                      class="
                        float-right
                        text-right
                        font-medium
                        leading-5
                        text-gray-700
                        transition
                        duration-150
                        ease-in-out
                        bg-white
                        hover:text-gray-400
                        focus:outline-none
                        focus:border-blue-300
                        focus:shadow-outline-blue
                        active:bg-gray-50 active:text-gray-800
                        mt-0.5
                        ml-2
                      "
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                        />
                      </svg>
                    </button>
                  </span>

                  <div
                    class="
                      opacity-0
                      invisible
                      dropdown-menu
                      transition-all
                      duration-300
                      transform
                      origin-top-right
                      -translate-y-2
                      scale-95
                      z-100
                      relative
                    "
                  >
                    <div
                      class="
                        absolute
                        right-0
                        w-36
                        p-2
                        mt-2
                        origin-top-right
                        bg-white
                        border border-gray-200
                        divide-y divide-gray-100
                        rounded-md
                        shadow-lg
                        outline-none
                      "
                      aria-labelledby="headlessui-menu-button-1"
                      role="menu"
                    >
                      <a
                        target="_blank"
                        v-if="attachment.documentUrl"
                        :href="
                          attachment.documentUrl.indexOf('://') === -1
                            ? 'http://' + attachment.documentUrl
                            : attachment.documentUrl
                        "
                        class="
                          block
                          px-4
                          py-2
                          text-sm text-gray-800
                          hover:bg-gray-100
                        "
                        >View</a
                      >
                      <a
                        v-if="attachment.documentUrl"
                        href="javascript:void(0)"
                        class="
                          block
                          px-4
                          py-2
                          text-sm text-gray-800
                          hover:bg-gray-100
                        "
                        @click="editFile(attachment)"
                        >Edit</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="
                          block
                          px-4
                          py-2
                          text-sm text-gray-800
                          hover:bg-gray-100
                        "
                        v-if="!attachment.documentUrl"
                        @click="downloadAttachment(attachment)"
                        >Download</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="
                          block
                          px-4
                          py-2
                          text-sm text-gray-800
                          hover:bg-gray-100
                        "
                        v-if="
                          user.roles.includes('manager') &&
                          selectedInitiative.status != 2 &&
                          selectedInitiative.status != 3 &&
                          selectedInitiative.status != 5
                        "
                        @click="deleteAttachment(index)"
                        >Delete</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <deleteAttachment
    :openClose="openClose"
    @openCloseModal="openCloseModal"
    @done="done"
    :todo="todo"
  >
  </deleteAttachment>
</template>
<script>
import { mapGetters } from "vuex";
import FileUpload from "../../components/FileUpload.vue";
import * as timeago from "timeago.js";
import { format } from "date-fns";
import InfoCard from "../../components/InfoCard.vue";
import feathersClient from "../../services/feathersClient";
import deleteAttachment from "../../components/confirmationModal/DeleteAttachmentModal.vue";
import AddFiles from "../../components/Modal/AddFiles.vue";
import objectId from "../../helpers/objectId";
const initiativeActivitiesService = feathersClient.service(
  "initiatives/activities"
);
export default {
  name: "PhaseAttachment",
  props: {
    phaseData: Object,
    currentPhaseIndex: Number,
    initiative: Object,
  },
  data() {
    return {
      formData: {
        attachments: [],
      },
      openClose: false,
      todo: null,
      index: null,
      timeago,
      openAddFile: false,
      fileObj: null,
    };
  },
  computed: {
    ...mapGetters(["user", "selectedInitiative"]),
    accessToken() {
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      return tokenString;
    },
    isEditAccess() {
      return (
        this.user?.roles.includes("manager") &&
        this.initiative.phaseIndex === this.currentPhaseIndex &&
        this.initiative.status !== 6
      );
    },
  },
  mounted: function () {
    this.$nextTick(async function () {
      this.getAttachments();
    });
  },
  emits: ["getAttachments"],
  methods: {
    fileUploaded: async function (uploadedFiles) {
      let token = `Bearer ${this.user.accessToken}`;
      try {
        await initiativeActivitiesService.patch(
          this.phaseData._id,
          {
            $set: {
              attachments: uploadedFiles,
            },
            string: "attachmentUploaded",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        this.$emit("getAttachments");
      } catch (e) {
        console.log("e ", e);
      }
    },
    openCloseModal(value) {
      this.openClose = value;
    },
    deleteAttachment: async function (index) {
      this.todo = "delete";
      this.index = index;
      await this.$emit("getAttachments");
      this.openCloseModal(true);
    },
    done: async function () {
      this.openCloseModal(false);
      let payload = {};
      payload.idToUpdate = this.phaseData._id;
      payload.attachments = this.formData.attachments;
      payload.index = this.index;
      payload.service = initiativeActivitiesService;

      await this.$store.dispatch("deleteAttachment", payload);

      await this.$emit("getAttachments");
    },
    downloadAttachment: function (payload) {
      this.$store.dispatch("downloadAttachment", payload);
    },
    getAttachments: async function () {
      const query = {
        $select: ["attachments"],
        $populate: [
          {
            path: "attachments.uploadedBy",
            select: "firstName lastName profilePic _id email",
          },
        ],
      };
      let token = `Bearer ${this.user.accessToken}`;
      let initiativeActivity = await initiativeActivitiesService.get(
        this.phaseData._id,
        {
          query: query,
          headers: {
            Authorization: token,
          },
        }
      );
      this.formData.attachments = initiativeActivity.attachments;
    },
    openAddFileModal(flag) {
      this.openAddFile = flag;
    },
    addFiles(file) {
      this.openAddFileModal(false);
    },
    addFilesModal: function (item) {
      this.fileObj = {
        documentName: "",
        documentUrl: "",
        documentId: objectId(),
      };
      this.todo = "add";
      this.openAddFileModal(true);
    },
    editFile: function (item) {
      this.fileObj = { ...item };
      this.todo = "edit";
      this.openAddFileModal(true);
    },
    goToProfile(user) {
      var name = user?.email
        ?.toLowerCase()
        ?.toLowerCase()
        ?.substring(0, user?.email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?._id, userName: name },
      });
    },
    goToUrl(item) {
      let redirectUrl =
        item.documentUrl.indexOf("://") === -1
          ? "http://" + item.documentUrl
          : item.documentUrl;
      window.open(redirectUrl, "_blank");
    },
  },
  components: {
    FileUpload,
    deleteAttachment,
    InfoCard,
    AddFiles,
  },
};
</script>
<style scoped>
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>

<!-- <ul class="mt-4 list-reset flex flex-col">
          <li
            class="rounded-t relative block border p-4 border-grey mb-2"
            v-for="(attachment, index) in formData.attachments"
            :key="index"
          >
            <div class="bg-white w-full flex rounded-xl">
              <div class="px-3 w-20">
                <img
                  class="h-10 w-10 rounded-full"
                  :src="attachment.uploadedBy.profilePic"
                  alt=""
                />
              </div>
              <div class="w-full">
                <span class="font-semibold text-gray-900 block"
                  >{{
                    attachment.uploadedBy.firstName +
                    " " +
                    attachment.uploadedBy.lastName
                  }}
                  <div class="float-right dropdown">
                    <span class="rounded-md shadow-sm"
                      ><button
                        class="
                          float-right
                          text-right
                          font-medium
                          leading-5
                          text-gray-700
                          transition
                          duration-150
                          ease-in-out
                          bg-white
                          hover:text-gray-400
                          focus:outline-none
                          focus:border-blue-300
                          focus:shadow-outline-blue
                          active:bg-gray-50
                          active:text-gray-800
                        "
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
                          />
                        </svg></button
                    ></span>
                    <div
                      class="
                        opacity-0
                        invisible
                        dropdown-menu
                        transition-all
                        duration-300
                        transform
                        origin-top-right
                        -translate-y-2
                        scale-95
                      "
                    >
                      <div
                        class="
                          absolute
                          right-0
                          w-36
                          p-2
                          mt-2
                          origin-top-right
                          bg-white
                          border border-gray-200
                          divide-y divide-gray-100
                          rounded-md
                          shadow-lg
                          outline-none
                        "
                        aria-labelledby="headlessui-menu-button-1"
                        role="menu"
                      >
                        <a
                          href="javascript:void(0)"
                          class="
                            block
                            px-4
                            py-2
                            text-sm text-gray-800
                            hover:bg-gray-100
                          "
                          @click="downloadAttachment(attachment)"
                          >Download</a
                        >
                        <a
                          href="javascript:void(0)"
                          class="
                            block
                            px-4
                            py-2
                            text-sm text-gray-800
                            hover:bg-gray-100
                          "
                          v-if="
                            user.role == 'manager' &&
                            selectedInitiative.status != 2 &&
                            selectedInitiative.status != 3 &&
                            selectedInitiative.status != 5
                          "
                          @click="deleteAttachment(index)"
                          >Delete</a
                        >
                      </div>
                    </div>
                  </div>
                </span>
                <span class="font-semibold text-gray-700 break-words">{{
                  attachment.documentName
                }}</span>
                <span class="block text-sm mb-2 mt-1"
                  >{{
                    timeago.format(new Date(attachment.uploadedAt))
                  }}
                  &middot;</span
                >
              </div>
            </div>
          </li>
        </ul> -->
