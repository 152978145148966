<template lang="html">
  <nav class="flex flex-col sm:flex-row text-sm">
    <button
      @click="setActive('tab1')"
      class="flex-grow text-gray-600 py-2 px-4 block hover:text-blue-500 focus:outline-none border-b-2"
      :class="{
        'text-blue-500 border-b-2 font-medium border-blue-500':
          activeItem === 'tab1',
      }"
    >
      Epic Details
    </button>
    <button
      @click="setActive('tab2')"
      class="flex-grow text-gray-600 py-2 px-4 block hover:text-blue-500 focus:outline-none border-b-2"
      :class="{
        'text-blue-500 border-b-2 font-medium border-blue-500':
          activeItem === 'tab2',
      }"
    >
      Linked Issues
    </button>
  </nav>
  <div class=" text-sm">
    <div class="p-3" v-if="activeItem === 'tab1'" id="tab1">
      <div class="flex flex-row justify-between">
        <div class="flex flex-row">
          <img
            :src="epic.creatorsProfilePic['48x48']"
            class="w-10 h-10 mr-3 rounded-full"
            alt=""
          />
          <div class="flex flex-col">
            <p class="text-sm text-gray-400 mb-1">
              Creator
            </p>
            <p class="-mt-1">{{ epic.creatorName }}</p>
          </div>
        </div>
      </div>
      <div class="my-4">
        <h1 class="text-sm text-gray-400 mb-1">Name</h1>
        <p>{{ epic.name }}</p>
      </div>
      <div class="mt-4">
        <h1 class="text-sm text-gray-400 mb-1">Description</h1>
        <p>
          {{ epic.description }}
        </p>
      </div>
    </div>
    <div v-if="activeItem === 'tab2'" id="tab2">
      <div class="">
        <div>
          <table class="min-w-full divide-y divide-gray-200 w-full border border-gray-200 ">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                >
                  Key
                </th>
                <th
                  scope="col"
                  class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                >
                  Creator
                </th>
                <!-- <th
                  scope="col"
                  class="
                        px-2
                        py-2
                        text-right text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                >
                  SELECT ALL
                </th> -->
                <th scope="col" class="relative px-2 py-2">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(epicIssue, index) in epicRelatedIssues.issues"
                @click="openNewTab(epicIssue)"
                class="cursor-pointer"
              >
                <td class="px-2 py-2 " :key="index">
                  <div class="flex items-center">
                    <div class="flex">
                      <div>
                        <input
                          type="checkbox"
                          :checked="
                            epicIssue.fields.status.statusCategory.colorName ===
                              'green'
                          "
                          disabled
                          class="w-5 mr-2 mt-2 custom-control-input"
                        />
                      </div>
                      <div class="text-sm font-medium text-gray-900">
                        {{ epicIssue.fields.summary }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-2 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ epicIssue.key }}
                  </div>
                </td>
                <!-- <td class="px-2 py-2 whitespace-nowrap">
                      <span class="text-sm text-gray-900">
                        {{ epicIssue.id }}
                      </span>
                    </td> -->
                <td class="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                  {{ epicIssue.fields.creator.displayName }}
                </td>
                <!-- <td
                  class="
                        px-2
                        py-2
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                      "
                >
                  <button
                    class="
                   bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded
                  "
                  >
                    Select
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="activeItem === 'tab3'" id="tab3">tab3 content</div>
  </div>
</template>

<script>
export default {
  props: {
    epic: Object,
    epicRelatedIssues: Object,
  },
  data() {
    return {
      activeItem: "tab1",
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    openNewTab(data) {
      // return false;
      let splitRoute = data.self.split("//");
      let apiROute =
        "https://" + splitRoute[1].split("/")[0] + "/jira/browse/" + data.key;
      // return false;
      window.open(apiROute, "_blank");
    },
  },
  created: function () {},
};
</script>
<style scoped>
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.2rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1983bf;
  background-color: #1983bf;
}
</style>
