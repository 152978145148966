<template>
  <TransitionRoot appear :show="openJudgingDetails" as="template">
    <Dialog as="div" :open="openJudgingDetails" @close="closeModal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        overflow-x-hidden
        justify-center
        items-center
        bg-black
        g-opacity-50
      "
    >
      <div class="bg-white rounded shadow-lg w-10/12 md:w-2/4">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <h3 class="font-medium text-white text-lg">
          {{this.initiative.phase}} - {{initiative.name}}
          </h3>
          <button
            class="
              bg-blue-400
              hover:bg-blue-500
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="
              $emit('close')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div class="modal-body p-3 font-sans">
          
          <div class="text-lg font-bold mb-2">Judges</div>
            <div class="grid grid-cols-3 gap-3">
            <span class=""
            v-for="(user, index) in initiative?.judgingInitiativeDetails?.reviewCompletedBy.concat(initiative?.judgingInitiativeDetails?.reviewNotCompletedBy)"
            v-bind:key="index">
            <div class="bg-white p-1.5 inline-block px-2 rounded-full flex bg-blue-500 mr-3"
            :style="user.style">
              <img
              class="
                mr-2
                mt-0.5
                userImage
                h-5
                w-5
                rounded-full
                ring-2 ring-white
                shadow
                hover:ring-4
                cursor-pointer
              "
              :src="user?.accountUserId?.profilePic"
              alt="users"
              @click.stop="userDetail(user)"
            />
            <button 
              :disabled="isDisabled(user.accountUserId._id)"
              class="disabled:opacity-30"
              :class="{'cursor-default': isDisabled(user.accountUserId._id)}"
              @click="this.userSelected(user.accountUserId._id)">{{ `${user?.accountUserId?.firstName} ${user?.accountUserId?.lastName}` }}</button>
            </div>
            </span>
          </div>

          <div class="flex w-full mt-6">
            <!-- <div class="flex-1">
              <div class="text-gray-700">
                Idea Name
              </div>
              <div class="font-bold pt-2">{{initiative.name}}
              </div>
            </div> -->
            <div class="flex-1">
              <div class="text-gray-700 font-bold">
                Submission Review Completed
              </div>
              <div class="">{{ timeago.format(new Date(this?.selectedUserData?.updatedAt)) }}</div>
            </div>
          </div>

          <div class="flex-shrink-0 mt-2 space-y-2 border-b"></div>
          <div class="text-lg font-bold mb-2 pt-2">Feedback History</div>
          <dynamic-form 
            :fieldsData="selectedUserData.metaDataSchema"
            :selectedIdea="selectedUserData"
            :markReviewedData="markReviewedData"
            :showAsterisk="false"
            @save="save">
          </dynamic-form>
        </div>

        <div
          class="
            w-full
            text-right
            float-right
            items-center
            justify-between
            p-5
            bg-white
            border-gray-200
            rounded-bl-lg rounded-br-lg
          "
        >

          <!-- <button
            @click="
              $emit('promote')
            "
            class="
              inline-flex
              text-white
              bg-indigo-500
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-600
              rounded
              text-md
              disabled:opacity-30
            "
          >
            Promote
          </button> -->
        </div>
      </div>
    </div>
  </Dialog>
  </TransitionRoot>
</template>
<script>
import { mapGetters } from "vuex";
import * as timeago from "timeago.js";
import DynamicForm from "./DynamicForm";

export default {
  name: "JudgingDetailsModal",
  props: {
    initiative: Object,
    openJudgingDetails: Boolean,
    phaseData: Object
  },
  components: {
    DynamicForm
  },
  data() {
    return {
     selectedUserId: "",
     selectedUserData: {},
     timeago
    };
  },
  created: function () {
  },
  async beforeMount(){
    console.log("this.initiative ",this.initiative);
    for(let [i, user] of (this.initiative?.judgingInitiativeDetails?.reviewCompletedBy.concat(this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy)).entries()){
      if(i==0){
        let reviewData = this.initiative?.judgingInitiativeDetails?.metaData?.reviews.filter(review=>review.lastUpdatedBy==user.accountUserId._id);
        if(reviewData?.length>0){
          let payload = {
            id: this.initiative.judgingInitiativeDetails._id,
            userId: user.accountUserId._id
          }
          this.selectedUserData = await this.$store.dispatch('getJudgingInitiativeById', payload)
          this.selectedUserData.updatedAt = reviewData[0].updatedAt;
          this.selectedUserData.status = 2;
          user.style = {'background-color': '#00BCEB', 'color': 'white'};
        }
      } else {
        this.selectedUserData.status = 2;
        user.style = {'background-color': '#E5E7EB'};;
      }
    }
  },
  computed: {
    ...mapGetters(["teamMembers"]),
  },
  methods: {
    isDisabled: function(userId){
      let foundUser = this.initiative.judgingInitiativeDetails.reviewNotCompletedBy.filter(user=> user.accountUserId._id == userId);
      if(foundUser.length>0){
        return true;
      } else {
        return false;
      }
    },
    userSelected: async function(userId){
      let reviewData = this.initiative?.judgingInitiativeDetails?.metaData?.reviews.filter(review=>review.lastUpdatedBy==userId);
      let payload = {
        id: this.initiative.judgingInitiativeDetails._id,
        userId: userId
      }
      this.selectedUserData = await this.$store.dispatch('getJudgingInitiativeById', payload)
      this.selectedUserData.updatedAt = reviewData[0].updatedAt;
      this.selectedUserData.status = 2;
      for(let user of this.initiative?.judgingInitiativeDetails?.reviewCompletedBy){
        if(user.accountUserId._id == userId){
          user.style = {'background-color': '#00BCEB', 'color': 'white'};
        } else {
          user.style = {'background-color': '#E5E7EB'};;
        }
      }
    }
  }
};
</script>
<style scoped>
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>