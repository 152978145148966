<template>
  <div class="flex justify-between w-full mb-2">
    <div class="mb-6 lg:mb-0">
      <div class="mb-2">
        <h2
          class="
            inline-block
            sm:text-xl
            text-lg
            font-medium
            title-font
            text-gray-900
          "
        >
          Progress
        </h2>
        <div
          class="
            inline-block
            rounded-full
            bg-indigo-500
            px-2
            border-0
            inline-flex
            items-center
            justify-center
            text-gray-500
            ml-2
          "
        >
          <div
            class="sm:text-base text-base font-medium title-font text-gray-200"
          >
            {{ initiatives?.length }}
          </div>
        </div>
      </div>
      <div class="h-1 w-20 bg-indigo-500 rounded"></div>
    </div>
    <div>
      <div
        class="
          list-style
          bg-gray-200
          text-sm text-gray-500
          leading-none
          border-2 border-gray-200
          rounded-full
          inline-flex
        "
      >
        <button
          class="
            inline-flex
            items-center
            transition-colors
            duration-300
            ease-in
            focus:outline-none
            hover:text-indigo-400
            rounded-r-full
            px-4
            py-2
          "
          v-bind:class="{
            'text-indigo-700': openFilter,
          }"
          id="list"
          @click="openFilterMethod"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clip-rule="evenodd"
            />
          </svg>
          <!-- <span>Filter</span> -->
        </button>
      </div>
    </div>
  </div>

  <div v-if="openFilter" class="relative z-20">
    <nav class="flex flex-col sm:flex-row border-b border-gray-400">
      <button
        v-on:click="setFilter(false)"
        class="
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        v-bind:class="{
          'text-blue-500': !isFilter,
          'border-b-2': !isFilter,
          'font-medium border-blue-500': !isFilter,
        }"
      >
        Search
      </button>
      <button
        v-on:click="setFilter(true)"
        class="
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        v-bind:class="{
          'text-blue-500': isFilter,
          'border-b-2': isFilter,
          'font-medium border-blue-500': isFilter,
        }"
      >
        Filter
      </button>
    </nav>

    <div v-if="!isFilter">
      <div class="box pt-6 mb-4">
        <div class="box-wrapper">
          <div
            class="
              bg-white
              rounded-md
              flex
              items-center
              w-full
              p-2
              shadow-sm
              border border-gray-200
            "
          >
            <button class="outline-none focus:outline-none">
              <svg
                class="ml-2 w-5 text-gray-600 h-5 cursor-pointer"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
            <input
              v-on:input="submitSearch()"
              type="text"
              name=""
              id=""
              placeholder="Search by keyword"
              v-model="name"
              class="
                w-full
                pl-4
                pr-5
                text
                outline-none
                focus:outline-none
                bg-transparent
              "
            />
            <span
              v-if="name.length > 0"
              class="
                cursor-pointer
                float-right
                hover:bg-gray-300 hover:rounded-r-full
              "
              @click="clearSearchInput"
            >
              <IconClose />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="inline-grid grid-cols-3 gap-3 w-full py-3">
          <!-- datepicker for created Date -->
          <div class="">
            <label
              class="block text-gray-500 font-bold mb-2"
              for="inline-full-name"
            >
              Created On
            </label>
            <v-date-picker v-model="createdAt" is-range :max-date="new Date()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex relative bg-white">
                  <div class="relative">
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                      style="min-height: 40px"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      placeholder="From"
                      class="
                        border
                        pl-8
                        pr-2
                        py-1
                        rounded
                        w-full
                        focus:outline-none focus:border-indigo-300
                        border border-white
                      "
                    />
                  </div>
                  <div>
                    <!-- arrow svg -->
                    <svg
                      class="w-4 h-4 mx-2 mt-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </div>
                  <div class="relative ml-5">
                    <!-- calender Icon for TO -->
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                      style="min-height: 40px"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      placeholder="To"
                      class="
                        border
                        pl-8
                        pr-2
                        py-1
                        w-full
                        rounded
                        focus:outline-none focus:border-indigo-300
                        border border-white
                      "
                    />
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>

          <!-- themes filter  -->

          <div id="theme" class="flex-1">
            <label
              class="block text-gray-500 font-bold mb-2 pr-4"
              for="inline-full-name"
            >
              <set-title
                :metaDataSchema="playbook.metaDataSchema"
                name="challengeType"
                defaultText="Theme"
              />
            </label>
            <Multiselect
              class="z-100 multi-tag"
              mode="tags"
              trackBy="label"
              label="label"
              v-model="challengeType"
              placeholder="Select "
              :options="challengeTypes"
            ></Multiselect>
          </div>

          <!-- status filter -->
          <div>
            <div id="status" class="flex-1">
              <label
                class="block text-gray-500 font-bold mb-2 pr-4"
                for="inline-full-name"
              >
                Status
              </label>

              <Multiselect
                class="z-100 multi-tag"
                mode="tags"
                trackBy="label"
                label="label"
                v-model="status"
                placeholder="Select "
                :options="statusOptions"
              />
            </div>
          </div>

          <!-- updated div  -->

          <div>
            <label
              class="block text-gray-500 font-bold mb-2 pr-4"
              for="inline-full-name"
            >
              Updated On
            </label>
            <v-date-picker v-model="updatedAt" is-range :max-date="new Date()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex relative bg-white">
                  <div class="relative">
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                      style="min-height: 40px"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      placeholder="From"
                      class="
                        border
                        pl-8
                        pr-2
                        py-1
                        rounded
                        w-full
                        focus:outline-none focus:border-indigo-300
                        border border-white
                      "
                    />
                  </div>
                  <div>
                    <svg
                      class="w-4 h-4 mx-2 mt-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </div>
                  <div class="relative ml-5">
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                      style="min-height: 40px"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      placeholder="To"
                      class="
                        border
                        pl-8
                        pr-2
                        py-1
                        w-full
                        rounded
                        focus:outline-none focus:border-indigo-300
                        border border-white
                      "
                    />
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div id="theme" class="flex-1">
            <label
                class="block text-gray-500 font-bold mb-2 pr-4"
                for="inline-full-name"
              >
                Judging Status
              </label>
              <Multiselect
              class="multi-tag"
              mode="tags"
              trackBy="label"
              label="label"
              v-model="judgingStatus"
              placeholder="Select "
              :options="judgingStatusOptions"
            ></Multiselect>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-between ">
        <div>
        <button
          @click="submitSearch()"
          class="
            mr-1
            bg-transparent
            hover:bg-blue-500
            text-white
            bg-indigo-500
            font-semibold
            hover:text-white
            py-2
            px-4
            border border-blue-500
            hover:border-transparent
            rounded
          "
        >
          Apply
        </button>
        <button
          @click="clearFilter()"
          class="
            text-base
            ml-1
            mb-5
            hover:scale-110
            focus:outline-none
            justify-center
            px-4
            py-2
            rounded
            font-bold
            cursor-pointer
            hover:bg-gray-200
            bg-gray-100
            text-gray-700
            border
            duration-200
            ease-in-out
            border-gray-600
            transition
          "
        >
          Reset
        </button>
        </div>
        <div class="flex flex-row ">
          <div class="flex flex-row mx-4 bg-white h-10" v-show="onManageClicked">
            <div class="cursor-pointer hover:bg-gray-300 pt-2 px-3" @click="onMakeActiveClick" v-tooltip="'Make Active'"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Active" transform="translate(-4 -4)">
      <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(4 4)" fill="#fff" stroke="#6b7280" stroke-width="2">
        <circle cx="11" cy="11" r="11" stroke="none"/>
        <circle cx="11" cy="11" r="10" fill="none"/>
      </g>
      <path id="Path_21509" data-name="Path 21509" d="M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z" transform="translate(9 9)" fill="#5cd92f"/>
    </g>
  </svg>
  </div>
  <div class="cursor-pointer hover:bg-gray-300 pt-2 px-3" @click="onMakeInactiveClick" v-tooltip="'Make Inactive'"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Inactive" transform="translate(-1592.461 -360.82)">
      <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(1592.461 360.82)" fill="#fff" stroke="#6b7280" stroke-width="2">
        <circle cx="11" cy="11" r="11" stroke="none"/>
        <circle cx="11" cy="11" r="10" fill="none"/>
      </g>
      <circle id="Ellipse_22" data-name="Ellipse 22" cx="6" cy="6" r="6" transform="translate(1597.461 365.82)" fill="#6b7280"/>
    </g>
  </svg>
  </div>
  <div class="cursor-pointer hover:bg-gray-300 pt-2 px-3" @click="onAssignClick" v-tooltip="'Assign Judges'"><svg  xmlns="http://www.w3.org/2000/svg" width="24.171" height="24.36" viewBox="0 0 24.171 24.36">
    <g id="Judging" transform="translate(-0.265 -0.137)">
      <g id="Group_18273" data-name="Group 18273" transform="translate(0.265 0.137)">
        <path id="Path_21517" data-name="Path 21517" d="M9.347.137A2.333,2.333,0,0,0,7.692.819L3.28,5.228a2.347,2.347,0,0,0,0,3.31l1.105,1.1a.39.39,0,0,0,.55,0L12.1,2.471a.39.39,0,0,0,0-.55L11,.819A2.327,2.327,0,0,0,9.347.137Zm0,.774a1.552,1.552,0,0,1,1.1.458L9.177.321l-6.1,6.7.757.964C3.22,7.373,5.065,7.472,5.677,6.86L8.245,1.369a1.552,1.552,0,0,1,1.1-.458Z" transform="translate(0.84 -0.137)" fill="#6b7280"/>
        <path id="Path_21518" data-name="Path 21518" d="M14.381,4.752,7.214,11.921a.39.39,0,0,0,0,.55l1.1,1.1a2.344,2.344,0,0,0,3.307,0l4.412-4.409a2.347,2.347,0,0,0,0-3.31l-1.1-1.1a.39.39,0,0,0-.553,0Zm.276.829.826.826c.612.612-.619-1.622-1.232-1.01L7.873,11.774c-.612.612,1.605,1.861.993,1.249l-.826-.829Z" transform="translate(2.972 1.994)" fill="#6b7280"/>
        <path id="Path_21519" data-name="Path 21519" d="M8.663,1.908l-.55.553,4.962,4.962.55-.553Z" transform="translate(3.452 0.702)" fill="#6b7280"/>
        <path id="Path_21520" data-name="Path 21520" d="M10.156,2.418,4.371,7.734,9.333,12.7l5.382-5.88Z" transform="translate(1.68 0.944)" fill="#6b7280"/>
        <path id="Path_21521" data-name="Path 21521" d="M7.668,6.57.882,13.406a2.036,2.036,0,0,0-.055,2.864,2.032,2.032,0,0,0,2.864-.055l0,0,6.749-6.876-.555-.544L.827,14.358c-.511.508,1.019,1.825.553,1.358S9.84,9.306,10.351,8.792L8.224,7.12Z" transform="translate(-0.265 2.91)" fill="#6b7280"/>
        <path id="Path_21522" data-name="Path 21522" d="M8.426,14.287a2.346,2.346,0,0,0-2.34,2.34v.78a.39.39,0,0,0,.389.389H21.291a.39.39,0,0,0,.389-.389v-.78a2.344,2.344,0,0,0-2.337-2.34Zm0,.78L20.9,17.016c.866,0,0-1.254,0-.389v.389h0c0-.866-13.339-1.948-12.474-1.948Z" transform="translate(2.492 6.565)" fill="#6b7280"/>
        <path id="Path_21523" data-name="Path 21523" d="M9.748,12.436a2.346,2.346,0,0,0-2.34,2.34v.78h9.9c0-1.646-7.743-1.646-7.743,0H19.1v-.78a2.346,2.346,0,0,0-2.34-2.34Z" transform="translate(3.118 5.688)" fill="#6b7280"/>
      </g>
    </g>
  </svg>
  </div>
  <div class="cursor-pointer hover:bg-gray-300 pt-2 px-3" @click="onUnpromoteClick" v-tooltip="'Unpromote'" ><svg xmlns="http://www.w3.org/2000/svg" width="18.27" height="24.36" viewBox="0 0 18.27 24.36">
    <g id="Demote" transform="translate(-5.875 -2.82)">
      <path id="Icon_ionic-md-bulb" data-name="Icon ionic-md-bulb" d="M12.95,27.591a1.272,1.272,0,0,0,1.269,1.269H19.8a1.272,1.272,0,0,0,1.269-1.269V26.449H12.95ZM17.01,4.5c-5.056,0-9.135,3.8-9.135,8.5a8.389,8.389,0,0,0,3.9,7.016V22.77a1.268,1.268,0,0,0,1.307,1.218h7.86a1.264,1.264,0,0,0,1.307-1.218V20.017A8.38,8.38,0,0,0,26.145,13C26.145,8.3,22.066,4.5,17.01,4.5Z" transform="translate(-2 -1.68)" fill="#6b7280"/>
      <g id="Arrow" transform="translate(19.5 8.248) rotate(90)">
        <line id="Line_2689" data-name="Line 2689" y1="9" transform="translate(3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
        <line id="Line_2690" data-name="Line 2690" x1="3" y1="3" transform="translate(0 6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
        <line id="Line_2692" data-name="Line 2692" y1="3" x2="3" transform="translate(3 6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
      </g>
    </g>
  </svg>
  </div>
  <div class="cursor-pointer hover:bg-gray-300 pt-2 px-3" @click="onPromoteClick" v-tooltip="'Promote'" ><svg  xmlns="http://www.w3.org/2000/svg" width="18.27" height="24.36" viewBox="0 0 18.27 24.36">
    <g id="Promote" transform="translate(-5.865 -2.82)">
      <path id="Icon_ionic-md-bulb" data-name="Icon ionic-md-bulb" d="M12.95,27.591a1.272,1.272,0,0,0,1.269,1.269H19.8a1.272,1.272,0,0,0,1.269-1.269V26.449H12.95ZM17.01,4.5c-5.056,0-9.135,3.8-9.135,8.5a8.389,8.389,0,0,0,3.9,7.016V22.77a1.268,1.268,0,0,0,1.307,1.218h7.86a1.264,1.264,0,0,0,1.307-1.218V20.017A8.38,8.38,0,0,0,26.145,13C26.145,8.3,22.066,4.5,17.01,4.5Z" transform="translate(-2.01 -1.68)" fill="#6b7280"/>
      <g id="Arrow" transform="translate(29.426 -10.148) rotate(90)">
        <line id="Line_2689" data-name="Line 2689" y2="9" transform="translate(21.398 9.926)" fill="none" stroke="#74e652" stroke-linecap="round" stroke-width="1"/>
        <line id="Line_2690" data-name="Line 2690" x1="3" y2="3" transform="translate(18.398 9.926)" fill="none" stroke="#74e652" stroke-linecap="round" stroke-width="1"/>
        <line id="Line_2692" data-name="Line 2692" x2="3" y2="3" transform="translate(21.398 9.926)" fill="none" stroke="#74e652" stroke-linecap="round" stroke-width="1"/>
      </g>
    </g>
  </svg>
  </div>
  
          </div> 
          <button
            @click="onManageClick()"
            class="
              mr-1
              bg-transparent
              hover:bg-blue-500
              text-white
              bg-indigo-500
              font-semibold
              hover:text-white
              py-2
              px-4
              border border-blue-500
              hover:border-transparent
              rounded
              w-28
              h-10
            "
          >
            Manage
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import SetTitle from "../../components/SetTitle";
import { mapGetters } from "vuex";
import moment from "moment";
import IconClose from "../Icon/Close.vue";
export default {
  name: "FilterCards",
  data() {
    return {
      layout: "grid",
      openFilter: false,
      isFilter: false,
      sortDropdown: false,
      search: {},
      sort: {},
      isFilter: false,
      name: "",
      statusOptions: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 6,
          label: "Inactive",
        }
        // ,
        // {
        //   value: 3,
        //   label: "Closed",
        // },
      ],
      judgingStatusOptions: [
        {
          value: "Not Started",
          label: "Not Started",
        },
        {
          value: "In Progress",
          label: "In Progress",
        },
        {
          value: "Completed",
          label: "Completed",
        }
      ],
      challengeType: [],
      createdAt: "",
      updatedAt: "",
      status: [1],
      phase: [],
    };
  },
  components: {
    Multiselect,
    SetTitle,
    IconClose,
  },
  mounted: function () {},
  props: {
    total: Number,
    heading: String,
    filterLabels: Array,
    column: Number,
    changeInputText: Boolean,
    onManageClicked: Boolean,
    judgingStatus:Array,
    
  },
  created: function () {},
  beforeUpdate() {
    if (this.changeInputText) {
      this.openFilter = false;
      this.name = "";
      this.search = {};
      this.sort = {};
      this.createdAt = "";
      this.updatedAt = "";
      this.challengeType = [];
      this.$emit("setJudgingStatusFilter",[])
    }
  },
  emits: [
    "removeFilterNameAfterModalClose",
    "updateLayout",
    "queryFilter",
    "removeLoaderOnSeach",
    "setLoader",
  ],
  methods: {
    setFilter(option) {
      this.isFilter = option;
    },
    sordDropdownOver: function () {
      this.sortDropdown = true;
    },
    sortDropdownOut: function () {
      this.sortDropdown = false;
    },
    updateLayout: function (layout) {
      this.layout = layout;
      this.$emit("updateLayout", layout);
    },
    sortByObj(sortMe) {
      this.sort = sortMe;
    },
    // submitSearch: function (payload) {
    //   this.query = {
    //     search: this.search,
    //     sort: this.sort,
    //   };
    //   this.$emit("queryFilter", this.query);
    // },
    async clearFilter() {
      this.search = {};
      this.sort = {};
      this.createdAt = "";
      this.updatedAt = "";
      this.challengeType = [];
      this.marketHorizon = [];
      this.source = [];
      this.priority = [];
      this.phase = [];
      this.bogFramework = [];
      this.status = [];
      this.$emit("setJudgingStatusFilter",[])
      await this.submitSearch();
      // await this.$store.dispatch("getInitiativesByPhase", payload);
      // this.$emit("queryFilter", this.query);
    },
    setFilter: function (option) {
      this.isFilter = option;
      this.marketHorizons.map((data) => {
        data.disabled = false;
      });
      this.sources.map((data) => {
        data.disabled = false;
      });

      this.bogFrameworks?.map((data) => {
        data.disabled = false;
      });
    },
    submitSearch: _.debounce(async function () {
      let searchedString = "";
      let searchedKeywords = [];
      this.$emit("removeFilterNameAfterModalClose", false);
      this.$emit("setLoader", false);
      this.$emit("resetBulkOperationObject");
      let createdStartDate, createdEndDate, updatedStartDate, updatedEndDate;
      if (this.createdAt) {
        createdStartDate = moment(this.createdAt?.start).toDate();
        createdStartDate.setHours(0);
        createdStartDate.setMinutes(0);
        createdStartDate.setSeconds(0);
        createdStartDate.setMilliseconds(0);
        // createdStartDate = new Date(
        //   moment(new Date(this.createdAt?.start), "DD-MM-YYYY")
        // );

        createdEndDate = moment(this.createdAt?.end).toDate();
        createdEndDate.setHours(23);
        createdEndDate.setMinutes(59);
        createdEndDate.setSeconds(59);
        createdEndDate.setMilliseconds(59);
        // createdEndDate = new Date(
        //   moment(new Date(this.createdAt?.end), "DD-MM-YYYY")
        // );
      }
      if (this.updatedAt) {
        updatedStartDate = moment(this.updatedAt?.start).toDate();
        updatedStartDate.setHours(0);
        updatedStartDate.setMinutes(0);
        updatedStartDate.setSeconds(0);
        updatedStartDate.setMilliseconds(0);
        // updatedStartDate = new Date(
        //   moment(new Date(this.updatedAt?.start), "DD-MM-YYYY")
        // );
        updatedEndDate = moment(this.updatedAt?.end).toDate();
        updatedEndDate.setHours(23);
        updatedEndDate.setMinutes(59);
        updatedEndDate.setSeconds(59);
        updatedEndDate.setMilliseconds(59);

        // updatedEndDate = new Date(
        //   moment(new Date(this.updatedAt?.end), "DD-MM-YYYY")
        // );
      }
      let query = {
        status: this.status,
        phase: this.phase,
        "metaData.challengeType": { $in: this.challengeType },
        "metaData.marketHorizon": { $in: this.marketHorizon },
        "metaData.source": { $in: this.source },
        "metaData.bogFramework": { $in: this.bogFramework },
        priority: { $in: this.priority },
        createdAt: { $gte: createdStartDate, $lt: createdEndDate },
        updatedAt: { $gte: updatedStartDate, $lt: updatedEndDate },
      };

      if (this.name.length > 0) {
        searchedString = this.name;
        searchedString = searchedString.trim();
        searchedKeywords = searchedString.split(' ')
        query.name = searchedKeywords;
      }
      let payload = {
        currentPage: 1,
        searchQuery: query,
        sortBy: this.sortBy,
        isFilter: true,
      };
      
      await this.$emit("setSearchQuery",query);
      await this.$store.dispatch("getInitiativesByPhase", payload);
      await this.$store.dispatch("setPayloadForProgressFilterQuery", payload);
      this.$emit("setFilterForJudgingStatus",this.judgingStatus);
      this.$emit("setJudgingStatusFilter",this.judgingStatus);
    }, 500),
    clearSearchInput: function () {
      this.name = "";
      this.submitSearch();
    },
    openFilterMethod: function () {
      this.$emit("removeFilterNameAfterModalClose", false);
      this.$emit("removeLoaderOnSeach", !this.openFilter);
      this.openFilter = !this.openFilter;
    },
    onManageClick: function () {
      this.$emit("onManageClick");
    },
    onMakeActiveClick: function () {
      this.$emit("onMakeActiveClick");
    },
    onMakeInactiveClick: function () {
      this.$emit("onMakeInactiveClick");
    },
    onAssignClick: function () {
      this.$emit("onAssignClick");
    },
    onUnpromoteClick: function () {
      this.$emit("onUnpromoteClick");
    },
    onPromoteClick: function () {
      this.$emit("onPromoteClick");
    },
  },
  computed: {
    ...mapGetters([
      "initiatives",
      "loadingFlag",
      "pagination",
      "user",
      "phaseNames",
      "marketHorizons",
      "challengeTypes",
      "sources",
      "bogFrameworks",
      "playbook",
      "autoInitiativeInAction",
    ]),
    // managerAccess() {
    //   return this.user?.role === "manager";
    // },
    // userAccess() {
    //   return this.user?.role === "user";
    // },
    // judgeTeamLead() {
    //   return this.user?.role === "judgeManager";
    // },
    // judgeTeamMember() {
    //   return this.user?.role === "judgeMember";
    // },
  },
};
</script>
<style scoped></style>
