<template>
  <TransitionRoot appear :show="requestRejectedflag" as="template">
    <Dialog as="div" :open="requestRejectedflag">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-80">
        <div class="min-h-screen text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
                text-center
              "
            >
              <!-- <img src="@/assets/images/balloon-cloud.png" /> -->
              <div class="p-2 border-b text-left">
                Request Rejected
                <!-- <span class="float-right">X</span> -->
              </div>
              <div class="m-10">
                <div
                  class="
                    w-12
                    h-12
                    bg-red-400
                    text-white
                    rounded-full
                    text-center
                    m-auto
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 ml-1 mt-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <h1 class="font-bold text-red-400">
                  Request has been rejected
                </h1>
                <p>You have rejected this request</p>
                <p>Reason - {{this.requestRejectedReson}}</p>

                <button
                  type="button"
                  @click="$emit('openRejectedRequestModal', false)"
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-600
                    text-indigo-100
                    border
                    duration-200
                    ease-in-out
                    border-teal-600
                    transition
                    mt-5
                    mb-2
                  "
                >
                  Got It</button
                ><br />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "IdeaSubmittedInfo",
  beforeUpdate() {},
  props: {
    requestRejectedflag: Boolean,
    page: String,
    requestRejectedReson:String
  },
  data() {
    return {
      pageInfo: {
        heading: "a",
        subHeading: "b",
      },
    };
  },
  created: function () {
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {},
};
</script>
