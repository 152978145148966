<template>
  <div class="flex">
    <div class="w-3/5">
      <h3 class="font-bold mb-5">
        Overview
        <span
          class="
            float-right
            text-right
            pr-5
            text-gray-500
            font-normal
            text-sm
            italic
          "
          >Updated {{ timeago.format(new Date(initiative.updatedAt)) }}</span
        >
      </h3>

      <div class="flex mt-3">
        <div class="flex-1">
          <div>
            <div class="font-bold text-sm">Submission's current phase</div>
            <div>{{ initiative.phase }}</div>
          </div>
        </div>

        <div class="flex-1">
          <div class="">
            <div class="font-bold text-sm">Submission Submitted</div>
            <div>{{ initiative.createdAt }}</div>
          </div>
        </div>
        <div class="flex-1">
          <div class="float-right w-32">
            <div class="font-bold text-sm">Status</div>
            <div v-if="isJudgeOrUser && (initiative.status == 1 ||initiative.status == 6)" class="text-green-500">
              {{ "Submitted" }}
            </div>
            <div v-else
              :class="{
                'text-green-500': initiative.status === -1,
                'text-yellow-500': initiative.status === 0,
                'text-green-500': initiative.status === 1,
                'text-blue-500': initiative.status === 2,
                'text-gray-500': initiative.status === 3,
                'text-red-500': initiative.status === 5,
                'text-gray-400': initiative.status === 6,
                'text-gray-500': initiative.status === 7,
              }"
            >
              {{ initiative.statusLabel }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-3 mb-3">
        <div>
          <div class="font-bold text-sm">
            <set-title
              v-if="metaDataSchema"
              :metaDataSchema="metaDataSchema"
              name="challengeType"
              defaultText="Theme"
            />
          </div>
          <div>
            <span
              class="
                mr-2
                mb-1
                inline-block
                px-2
                py-1
                leading-none
                border border-blue-500
                text-blue-500
                rounded-full
                font-semibold
                tracking-wide
                text-xs
              "
              >{{ initiative.metaData.challengeType }}</span
            >
          </div>
        </div>
      </div>

      <div class="flex mt-3 mb-3">
        <div>
          <div class="font-bold text-sm">Tags</div>
          <div>
            <span v-if="initiative.tags && initiative.tags.length !== 0">
              <span
                v-for="tag in initiative.tags"
                :key="tag"
                class="
                  mr-2
                  mb-1
                  inline-block
                  px-2
                  py-1
                  leading-none
                  border border-blue-500
                  text-blue-500
                  rounded-full
                  font-semibold
                  tracking-wide
                  text-xs
                  break-all
                "
                >{{ tag }}</span
              >
            </span>
            <span v-else>NA</span>
          </div>
        </div>
      </div>

      <!-- Problem Statement  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">Problem Statement</h3>
        <p class="text-md">
          <!-- <RichText :showFull="true" :text="initiative.description" /> -->
          <P v-html="initiative.description" class="prose space-y-0 html-text word-wrap"></P>
        </p>
      </div>

      <!-- Value Proposition & Top 3 Innovations  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="problemDescription"
            defaultText="Problem Description"
          />
        </h3>
        <p class="text-md">
          <!-- <RichText
            :showFull="true"
            :text="initiative.metaData.problemDescription"
          /> -->
          <P v-html="initiative.metaData.problemDescription" class="prose space-y-0 html-text word-wrap"></P>
        </p>
      </div>

      <!-- Results  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="potentialMarket"
            defaultText="Potential First Market"
          />
        </h3>
           <div>
              <div>
                <p class="text-md">
                   <!-- <RichText
                    :showFull="true"
                    :text="initiative.metaData.potentialMarket"
                    /> -->
                 <P v-html="initiative.metaData.potentialMarket" class="prose space-y-0 html-text word-wrap"></P>
                </p>
              </div>
              <div class="flex mt-4">
                 <div class="w-1/2">
                    <h3 class="font-bold">Launch/GA Date</h3> 
                    <P class="prose word-wrap" v-if="initiative.metaData.fcsDate">{{formattedDate(initiative.metaData.fcsDate)}}</P>
                    <P class="" v-else>NA</P>
                 </div>
                 <div  class="w-1/2 mx-1">
                    <h3 class="font-bold"> Revenue to-date</h3> 
                    <P class="" v-if="selectedInitiative.metaData.revenueDate">{{ formatInput(selectedInitiative.metaData.revenueDate) }}</P>
                    <P class="" v-else>NA</P>
                 </div>
                 <!-- <div  class="w-1/3">
                    <h3 class="font-bold">User Count/Activation to-date</h3> 
                    <P v-if="initiative.metaData.activationDate" v-html="initiative.metaData.activationDate" class="prose word-wrap"></P>  
                    <P v-else>NA</P> 
                 </div> -->
              </div>
           </div>
      </div>

      <!-- Risks  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="elevatorPitch"
            defaultText="Elevator Pitch"
          />
        </h3>
        <p class="text-md">
          <!-- <RichText
            :showFull="true"
            :text="initiative.metaData.elevatorPitch"
          /> -->
          <P v-html="initiative.metaData.elevatorPitch" class="prose space-y-0 html-text word-wrap"></P>
        </p>
      </div>

      <!-- Teamwork, Partnership, Reuse of technology / Open Source  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="aboutTeam"
            defaultText="About You / Your Team"
          />
        </h3>
        <p class="text-md">
          <!-- <RichText :showFull="true" :text="initiative.metaData.aboutTeam" /> -->
          <P v-html="initiative.metaData.aboutTeam" class="prose space-y-0 html-text word-wrap"></P>

        </p>
      </div>

      <!-- Testimonial, Awards, IP and Open Source -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="testimonialAwardsIpAndOpenSource"
            defaultText="Testimonial, Awards, IP and Open Source"
          />
        </h3>
        <p class="text-md">
          <!-- <RichText
            :showFull="true"
            :text="initiative.metaData.testimonialAwardsIpAndOpenSource"
          /> -->
          <P v-html="initiative.metaData.testimonialAwardsIpAndOpenSource" class="prose space-y-0 html-text word-wrap"></P>

        </p>
      </div>


      <!-- Comments  -->
      <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">Comments ({{ comments.length }})</h3>
        <div class="mt-3" v-if="comments?.length !== 0">
          <comments
            :comments="comments"
            :isParticipant="isParticipant"
            :initiativeId="initiative.id"
            :user="user"
            @commentAdded="commentAdded"
            commentsFor=""
            @commentsCount="commentsCount"
            :isActionVisible="true"
            :isWso="isWso"
          ></comments>
        </div>
        <div class="mt-2" v-else>
          <info-card message="No Comments Available" />
        </div>
      </div>
      <!-- Judging Module - Judging Details -->
      <div class="flex border-t border-gray-300 pt-2 pb-5 mr-3 mt-1">
        <div v-if="initiative?.judgingInitiativeDetails">
            <h3 class="font-bold float-left">Judging Module</h3>
        </div>
        <div v-if="initiative?.judgingInitiativeDetails?.reviewCompletedBy?.length>0">
          <button
            class="
              inline-flex
              text-white
              bg-indigo-500
              border-0
              py-0.5
              px-2
              focus:outline-none
              hover:bg-indigo-600
              rounded
              text-md
              ml-4
            "
            @click="showJudgingDetailsPopup"
            >
            Judging Details
          </button>
        </div>
      </div>
      <div v-if="initiative?.judgingInitiativeDetails" class="flex justify-between">
        <div>
          <div class="font-semibold flex">Not Completed <span class="numberCircle ml-2 mt-1 mb-2">{{initiative?.judgingInitiativeDetails?.reviewNotCompletedBy?.length}}</span></div>
          <div class="flex w-full mb-1"
            v-for="(user, index) in initiative?.judgingInitiativeDetails?.reviewNotCompletedBy"
            :key="index">
            <div class="flex">
            <img
              class="
                mr-2
                mt-0.5
                userImage
                h-5
                w-5
                rounded-full
                ring-2 ring-white
                shadow
                hover:ring-4
                cursor-pointer
              "
              :src="user?.accountUserId?.profilePic"
              alt="users"
              @click.stop="userDetail(user)"
            />
            <span>{{ `${user?.accountUserId?.firstName} ${user?.accountUserId?.lastName}` }}</span> 
            <span v-if="initiative.status !== 6" v-tooltip="'Swap Judge'"><svg class="mt-1 mx-2 cursor-pointer" @click.stop="openSwapJudgesModal(true,user)" xmlns="http://www.w3.org/2000/svg" width="18" height="15.569" viewBox="0 0 18 15.569" v-tooltip="'Swap Judge'">
               <g id="Swap_Users" data-name="Swap Users" transform="translate(-14211.8 -4094.16)">
               <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M3.692,4.154A2.077,2.077,0,1,0,1.615,2.077,2.078,2.078,0,0,0,3.692,4.154Zm1.846.462H4.744a2.511,2.511,0,0,1-2.1,0H1.846A1.846,1.846,0,0,0,0,6.462v.231a.693.693,0,0,0,.692.692h6a.693.693,0,0,0,.692-.692V6.462A1.846,1.846,0,0,0,5.539,4.616Z" transform="translate(14211.8 4102.344)" fill="#4b5563"/>
               <path id="Icon_awesome-user-alt-2" data-name="Icon awesome-user-alt" d="M3.692,4.154A2.077,2.077,0,1,0,1.615,2.077,2.078,2.078,0,0,0,3.692,4.154Zm1.846.462H4.744a2.511,2.511,0,0,1-2.1,0H1.846A1.846,1.846,0,0,0,0,6.462v.231a.693.693,0,0,0,.692.692h6a.693.693,0,0,0,.692-.692V6.462A1.846,1.846,0,0,0,5.539,4.616Z" transform="translate(14222.415 4094.16)" fill="#4b5563"/>
               <path id="Path_21586" data-name="Path 21586" d="M34.169,34.109a.37.37,0,0,0-.523,0l-1.107,1.107a.369.369,0,1,0,.522.522l.38-.38a5.176,5.176,0,0,1-5.072,4.181.369.369,0,0,0,0,.738A5.912,5.912,0,0,0,34.212,35.2l.542.542a.369.369,0,0,0,.522-.522Z" transform="translate(14191.967 4068.344)" fill="#4b5563" fill-rule="evenodd"/>
               <path id="Path_21587" data-name="Path 21587" d="M11.215,14.169a.37.37,0,0,0,.523,0l1.107-1.107a.369.369,0,1,0-.522-.522l-.38.38a5.176,5.176,0,0,1,5.072-4.181.369.369,0,1,0,0-.738,5.912,5.912,0,0,0-5.843,5.081l-.542-.542a.369.369,0,1,0-.522.522Z" transform="translate(14204.262 4087.268)" fill="#4b5563" fill-rule="evenodd"/>
               </g>
            </svg></span>
            <span v-if="CheckJudgeCount() && initiative.status !== 6" v-tooltip="'Delete Judge'"><svg class="mt-1 w-4 h-4 cursor-pointer" @click.stop="openConfirmationDeleteJudgeModal(true,user)" fill="#4B5563" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
              <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
            </svg></span>
          </div>     
          </div>
        </div>
        <div>
          <div class="font-semibold flex">Review Completed <span class="numberCircle ml-2 mt-1 mb-2">{{initiative?.judgingInitiativeDetails?.reviewCompletedBy?.length}}</span></div>
          <div class="flex w-full mb-1" 
            v-for="(user, index) in initiative?.judgingInitiativeDetails?.reviewCompletedBy"
            :key="index">
            <img
              class="
                mr-2
                mt-0.5
                userImage
                h-5
                w-5
                rounded-full
                ring-2 ring-white
                shadow
                hover:ring-4
                cursor-pointer
              "
              :src="user?.accountUserId?.profilePic"
              alt="users"
              @click.stop="userDetail(user)"
            />
          <div>{{ `${user?.accountUserId?.firstName} ${user?.accountUserId?.lastName}` }}</div>       
          </div>
        </div>
      </div>
      <!-- <div
        v-if="initiative.metaData.talent"
        class="border-t border-gray-300 pt-2 pb-5 mr-3"
      >
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="talent"
            defaultText="Looking for Talent"
          />
        </h3>
        <p class="text-md">
          <RichText :showFull="true" :text="initiative.metaData.talent" />
        </p>
      </div> -->

      <!-- <div class="border-t border-gray-300 pt-2 pb-5 mr-3">
        <h3 class="font-bold">
          <set-title
            v-if="metaDataSchema"
            :metaDataSchema="metaDataSchema"
            name="progress"
            defaultText="Progress"
          />
        </h3>
        <p class="text-md">
          <RichText :showFull="true" :text="initiative.metaData.progress" />
        </p>
      </div> -->
    </div>

    <div class="w-2/5 p-3 float-right">
      <!-- <div class="bg-gray-200 mb-3 p-3 rounded-lg shadow-md">
        <div>
          <div class="font-bold text-sm">Updated</div>
          <div>{{ timeago.format(new Date(initiative.updatedAt)) }}</div>
        </div>
      </div> -->
      <div class="sticky">
        <img
          class="shadow-md rounded-lg w-full object-cover"
          :src="initiative.picture"
        />
      </div>
      <!-- <div class="mt-2">
        <nav class="flex flex-col sm:flex-row">
          <button
            @click="setActive('tab1')"
            class="
              flex-grow
              text-gray-600
              py-4
              px-6
              block
              hover:text-blue-500
              focus:outline-none
              border-b-2
            "
            :class="{
              'text-blue-500 border-b-2 font-medium border-blue-500':
                activeItem === 'tab1',
            }"
          >
            Team Members ({{ totalMembers.length }})
          </button>
          <button
            @click="setActive('tab2')"
            class="
              flex-grow
              text-gray-600
              py-4
              px-6
              block
              hover:text-blue-500
              focus:outline-none
              border-b-2
            "
            :class="{
              'text-blue-500 border-b-2 font-medium border-blue-500':
                activeItem === 'tab2',
            }"
          >
            Attachments ({{ initiative.attachments.length }})
          </button>
        </nav>
        <div
          class="p-3 overflow-y-auto overflow-x-hidden bg-gray-50"
          style="height: calc(100vh - 210px)"
        >
          <div v-if="activeItem === 'tab1'" id="tab1">
            <ul v-if="totalMembers.length === 0">
              <li>
                <info-card message="No Attachments Available!" />
              </li>
            </ul>
            <ul v-else >
              <li
                class="flex items-center"
                v-bind:class="{ 'mt-0': index > 0 }"
                v-for="(user, index) in totalMembers"
                :key="index"
              >
              <user-card :user="user" />
             </li>
            </ul>
          </div>
          <div v-if="activeItem === 'tab2'" id="tab2">
            <ul v-if="initiative.attachments.length === 0">
              <li>
                <info-card message="No Attachments Available!" />
              </li>
            </ul>
            <ul class="-mx-4" v-else>
              <li
                class="flex mb-2"
                v-bind:class="{ 'mt-0': index > 0 }"
                v-for="(item, index) in initiative.attachments"
                :key="index"
              >
                <div class="ml-2 mr-2 relative flex pt-1">
                  <svg
                    v-if="item.documentUrl"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <svg
                    v-if="!item.documentUrl"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="w-full pr-4 overflow-hidden">
                  <div class="text-gray-700 break-all truncate block">
                    {{ item.documentName }}
                  </div>
                  <div class="text-gray-400 break-all -mt-1 truncate block">
                    <a
                      target="_blank"
                      v-if="item.documentUrl"
                      :href="
                        item.documentUrl.indexOf('://') === -1
                          ? 'http://' + item.documentUrl
                          : item.documentUrl
                      "
                      >{{ item.documentUrl }}</a
                    >
                    //{{ item.documentUrl }}
                  </div>
                  <div class="block text-sm mb-2 mt-0 text-gray-500">
                    {{ timeago.format(new Date(item.uploadedAt)) }} .
                    {{
                      item.uploadedBy.firstName
                        ? item.uploadedBy.firstName +
                          " " +
                          item.uploadedBy.lastName
                        : ""
                    }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
                <div v-if="(user.roles.includes('manager') || user.userRole===`workspaceOwner`)" class="mt-10">
                  <div class="flex space-x-5 ...">
                    <h3 class="mb-4 font-bold text-gray-700 flex-1">
                      <label>Exec Sponsor</label>
                      ({{ selectedInitiative?.metaData?.execSponsor?.length ? selectedInitiative.metaData.execSponsor.length : 0 }})
                    </h3>
                  </div>
                  <div class="flex flex-col mx-auto">
                    <ul class>
                      <li
                        class="flex items-center"
                        v-bind:class="{ 'mt-1': index > 0 }"
                        v-for="(user, index) in selectedInitiative?.metaData?.execSponsor"
                        :key="index"
                      >
                        <user-card :selectUser="user" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="(user.roles.includes('manager') || user.userRole===`workspaceOwner`) && selectedInitiative?.metaData?.execSponsor?.length>0 && (initiative.status !=6)" class="flex flex-col mx-auto">
                  <div class="flex bg-white mt-4">
                    <button
                      class="
                        w-full
                        justify-center
                        px-6
                        py-2
                        bg-indigo-500
                        rounded
                      "
                      @click="changeExecSponsor"
                    >
                      <span class="text-white">Change</span>
                    </button>
                  </div>
                </div>
                <div v-else-if="((user.roles.includes('manager') || user.userRole===`workspaceOwner`) && (initiative.status !=6))" class="flex flex-col mx-auto">
                  <div class="flex bg-white mt-4">
                    <button
                      class="
                        w-full
                        justify-center
                        px-6
                        py-2
                        bg-indigo-500
                        rounded
                      "
                      @click="changeExecSponsor"
                    >
                      <span class="text-white">Add</span>
                    </button>
                  </div>
                </div>
      <div>
        <h3 class="pt-5 pb-2 font-bold">
          Team Members ({{ totalMembers.length }})
        </h3>
        <div>
          <div>
            <ul v-if="totalMembers.length === 0">
              <li>
                <info-card message="No Attachments Available!" />
              </li>
            </ul>
            <ul v-else>
              <li v-for="(user, index) in totalMembers" :key="index">
                <user-card :selectUser="user" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h3 class="pt-5 pb-2 font-bold">
          Attachments ({{ initiative.attachments.length }})
        </h3>
        <div>
          <ul v-if="initiative?.attachments?.length === 0">
            <li>
              <info-card message="No Attachments Available!" />
            </li>
          </ul>
          <ul v-else>
            <li
              v-for="(item, index) in initiative.attachments"
              :key="index"
              class="
                mb-2
                rounded-lg
                p-2
                bg-white
                shadow
                mb-0.5
                border border-gray-100
              "
            >
            <div class="p-2 font-bold text-blue-700">{{getAttachmentTitle(item.documentName)}}</div>
            <div 
              class="
                w-full
                flex
              ">
              <div class="ml-2 mr-2 relative flex pt-1">
                <svg
                  v-if="item.documentUrl"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clip-rule="evenodd"
                  />
                </svg>

                <svg
                  v-if="!item.documentUrl"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="w-full pr-4 overflow-hidden">
                <div
                  class="text-gray-700 break-all truncate block"
                  :class="{
                    ' hover:underline cursor-pointer': !item.documentUrl,
                  }"
                  @click="!item.documentUrl ? downloadAttachment(item) : null"
                >
                  {{
                    item.documentName.substring(
                      0,
                      item.documentName.lastIndexOf(".")
                    )
                  }}
                </div>
                <div
                  class="
                    text-gray-400
                    break-all
                    -mt-1
                    truncate
                    block
                    hover:underline
                    cursor-pointer
                  "
                >
                  <a
                    target="_blank"
                    v-if="item.documentUrl"
                    :href="
                      item.documentUrl.indexOf('://') === -1
                        ? 'http://' + item.documentUrl
                        : item.documentUrl
                    "
                    >{{ item.documentUrl }}</a
                  >
                </div>
                <div class="block text-sm mb-2 mt-0 text-gray-500">
                  {{ timeago.format(new Date(item.uploadedAt)) }} .

                  <span
                    class="hover:underline cursor-pointer"
                    @click="goToProfile(item?.uploadedBy)"
                  >
                    {{
                      item.uploadedBy.firstName
                        ? item.uploadedBy.firstName +
                          " " +
                          item.uploadedBy.lastName
                        : ""
                    }}
                  </span>
                </div>
              </div>
            </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
          <h2 class="pt-5 pb-2 font-bold">
             Core Team Size
          </h2>
          <div class="bg-white rounded-lg shadow border border-gray-100">
              <h2 v-if="selectedInitiative?.metaData?.coreTeamSize" class="h-12 pt-3 text-lg ml-4 ">{{ selectedInitiative?.metaData?.coreTeamSize}}</h2>
              <h2 v-else class="h-12 pt-3 text-lg ml-4 ">NA</h2>
          </div>
      </div>
    </div>
  </div>

  <JudgingDetailsModal v-if="openJudgingDetails"
  :openJudgingDetails="openJudgingDetails"
  @showJudgingDetailsPopup="showJudgingDetailsPopup"
  @close="closeModal"
  :initiative="initiative"
  :phaseData="phaseData"
  ></JudgingDetailsModal>
  <attachment-popup
    :openCloseAttachment="attachmentPopUpFlag"
    :documentData="documentData"
  >
  </attachment-popup>
  <ChangeExecSponsorModal
    v-if="isChangeExecSponsor"
    :initiative="selectedInitiative"
    :msGraphAccessToken="msGraphAccessToken"
    :proposer="proposer"
    @close="closeExecSponsorModal"
  ></ChangeExecSponsorModal>
  <SwapJudges
  :openSwapJudges="openSwapJudges"
  :allInitiativesForSwapJuges="allInitiativesForSwapJuges"
  @openSwapJudgesModal="openSwapJudgesModal"
  :initiative="initiative"
  @openConfirmationSwapModal="openConfirmationSwapModal"
></SwapJudges>
<SwapConfirmation
    :openConfirmSwap="openConfirmSwap"
    @openConfirmationSwapModal="openConfirmationSwapModal"
    :initiative="initiative"
    :currentPhaseIndex="currentPhaseIndex"
    @createJudgingTeamForPhase="createJudgingTeamForPhase"
  ></SwapConfirmation>
  <DeleteJudgeConfirm
    :openConfirmDeleteJudge="openConfirmDeleteJudge"
    @openConfirmationDeleteJudgeModal="openConfirmationDeleteJudgeModal"
    :initiative="initiative"
    :currentPhaseIndex="currentPhaseIndex"
    @createJudgingTeamForPhase="createJudgingTeamForPhase"
  ></DeleteJudgeConfirm>  
</template>
<script>
import * as timeago from "timeago.js";
import { mapGetters } from "vuex";
import SetTitle from "../../components/SetTitle";
import RichText from "../../components/RichText";
import InfoCard from "../../components/InfoCard.vue";
import UserCard from "../../components/UserCard";
import { format } from "date-fns";
import Comments from "../../components/IdeaSections/Comments.vue";
import JudgingDetailsModal from "../JudgingDetailsModal.vue";
import AttachmentPopup from "../../components/AttachmentPopup.vue";
import ChangeExecSponsorModal from "../../components/ChangeExecSponsorModal.vue";
import SwapJudges from "../Modal/SwapJudges.vue"
import SwapConfirmation from "../confirmationModal/SwapConfirmation.vue"
import DeleteJudgeConfirm from "../confirmationModal/DeleteJudgeConfirm.vue"
export default {
  name: "PhaseOverview",
  components: {
    SetTitle,
    RichText,
    InfoCard,
    UserCard,
    Comments,
    JudgingDetailsModal,
    AttachmentPopup,
    ChangeExecSponsorModal,
    SwapJudges,
    SwapConfirmation,
    DeleteJudgeConfirm
  },
  data() {
    return {
      timeago,
      activeItem: "tab1",
      comments: [],
      openJudgingDetails: false,
      documentData: {},
      isChangeExecSponsor: false,
      openSwapJudges:false,
      openConfirmSwap:false,
      openConfirmDeleteJudge:false,
      allInitiativesForSwapJuges:[],
    };
  },
  props: {
    initiative: Object,
    activitiesToUpdate: Object,
    phaseData: Object,
    metaDataSchema: Object,
    currentPhaseIndex: Number,
  },
  emits: [
    "selectInitiative"
  ],
  methods: {
    async changeExecSponsor(){
      await this.$store.dispatch("getAccessToken", "");
      this.isChangeExecSponsor = true;
    },
    async closeExecSponsorModal() {
      this.isChangeExecSponsor = false;
      await this.$store.dispatch("getInitiativeById", this.selectedInitiative.id)
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    goToProfile(user) {
      var name = user?.email
        ?.toLowerCase()
        ?.toLowerCase()
        ?.substring(0, user?.email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?._id, userName: name },
      });
    },
    formattedDate(value) {
      if(value){
        return format(new Date(value), "MM/dd/yyyy");
      }
    },
    formatInput(value) {
      if(!value) return ""
      let converValue=value?.replace(/[$,]/g, "")
      if (!isNaN(converValue)) {
        // Format as $USD
        const numberValue = parseFloat(converValue);
        this.formattedValue = numberValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        this.formattedValue = "Invalid input. Please enter a numerical value.";
      } 
      return this.formattedValue;
    },
    userDetail(user) {
      var email = user?.accountUserId?.email?.toLowerCase();
      var name = email.substring(0, email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: user?.accountUserId?._id, userName: name },
      });
    },
    CheckJudgeCount(){
      if(this.initiative?.judgingInitiativeDetails && this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy && this.initiative?.judgingInitiativeDetails?.reviewCompletedBy?.length){
        const judgeCount= this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy?.length+this.initiative?.judgingInitiativeDetails?.reviewCompletedBy?.length
        if(judgeCount > 1){
          return true;
        }
      }else if(this.initiative?.judgingInitiativeDetails && this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy){
        const judgeCount= this.initiative?.judgingInitiativeDetails?.reviewNotCompletedBy?.length
        if(judgeCount > 1){
          return true;
        }
      }
      return false;
    },
    downloadAttachment: function (payload) {
      this.documentData = payload;
      this.$store.dispatch("viewAttachment", payload);
    },
    
    async showJudgingDetailsPopup() {
      this.openJudgingDetails = true;
    },

    closeModal() {
      this.openJudgingDetails = false;
    },
    getAttachmentTitle(name) {
      if (this.playbook.metaDataSchema) {
        let type = name.split(".").pop()
        let checkData;
        checkData= this.playbook.metaDataSchema.filter((item) => {
          return item.name === type;
        });
        return checkData[0].title
      }
    },
    async openSwapJudgesModal(value,judge) {
      if(value){
      this.allInitiativesForSwapJuges =  await this.$store.dispatch("getAllInitiativesForSwapJudges");
        let email = judge?.accountUserId?.email?.toLowerCase();
        let name = email.substring(0, email.lastIndexOf("@"));
        let payload={
          params: { userId: judge?.accountUserId?._id, userName: name },
          aspenifyUserId: judge?.accountUserId?._id,
        }
        await this.$store.dispatch("getUserDetails", payload);
      }
      this.openSwapJudges = value;
    },
    openConfirmationSwapModal(value) {
      this.openConfirmSwap = value;
      this.openSwapJudgesModal(false)  
      },
    async openConfirmationDeleteJudgeModal(value,judge) {
      if(value){
      let email = judge?.accountUserId?.email?.toLowerCase();
        let name = email?.substring(0, email.lastIndexOf("@"));
        let payload={
          params: { userId: judge?.accountUserId?._id, userName: name },
          aspenifyUserId: judge?.accountUserId?._id,
        }
        await this.$store.dispatch("getUserDetails", payload);
      }
      this.openConfirmDeleteJudge = value;
      },  
    async createJudgingTeamForPhase(data){
      this.openConfirmationSwapModal(false);
      this.openConfirmationDeleteJudgeModal(false);
      let payload = {
        "name" : data?.initiative?.phaseInfo?.name,
        "ideaId" : data?.initiative?.id,
        "phaseId" : data?.initiative?.phaseInfo?._id,
        "selectedJudgesTeamId" : data?.initiative?.phaseInfo?.metaData?.selectedJudgesTeamId
      }
      data.phaseTeamMembers = data.phaseTeamMembers.map((member)=>{
        if(data?.initiative && data?.initiative?.seletedPhaseJudgesDetails && data?.initiative?.seletedPhaseJudgesDetails?.members){
          data?.initiative?.seletedPhaseJudgesDetails?.members.forEach((judge)=>{
            if(judge?.accountUserId?._id === member?._id && member?._id !== this.selectedUser?._id){
              member.isChecked = true;
            }
          })
        }
        return member;
      })
      let users = data.phaseTeamMembers.filter((member)=>{
        return member.isChecked;
      })
      data.currentPhaseIndex=data?.currentPhaseIndex-1;
      payload.users = users;
      let record = {};
      record.data = data;
      record.payload = payload;
      await this.$store.dispatch("manageTeam", record);
      this.$store.dispatch("getInitiativeById", data?.initiative?.id);
      this.$store.dispatch("getInitiativeLogs", data?.initiative?.id);
    },
  },
  created: async function () {
    this.comments = await this.$store.dispatch(
      "getAllComments",
      this.initiative.id
    );
    this.comments = this.comments.filter((singleRecord) => {
      return !singleRecord.isPrivateMessage;
    });
  },
  computed: {
    ...mapGetters(["user","selectedUser", "attachmentPopUpFlag", "selectedInitiative", "msGraphAccessToken", "playbook"]),
    totalMembers() {
      if (this.initiative && this.initiative.metaData) {
        const teamLead = [{
          name: this.initiative.metaData.teamLead[0].name,
          image: this.initiative.metaData.teamLead[0].image,
          role: "Team Lead",
        }];

        return [
          ...teamLead,
          ...this.initiative.metaData?.teamMembers];
      }
    },
    isParticipant() {
      let metaData = this.initiative.metaData;
      let user = this.user;
      let allMembers = [
        ...metaData?.teamLead,
        ...metaData?.teamMembers,
        ...metaData?.mentors,
        ...metaData?.submitter,
      ];

      return (
        allMembers?.filter(
          (f) => f?.email?.toLowerCase() === user.email?.toLowerCase()
        )?.length !== 0
      );
    },
    isWso() {
      return this.user.roles.includes("manager");
    },
  },
};
</script>
<style scoped>
.numberCircle {
  float: right;
  font-size: 12px;
  color: white;
  border-radius: 1rem;
  padding: 1px 5px;
  width: 34px;
  text-align: center;
  background-color: #1983bf;
}
.word-wrap {
  overflow-wrap: break-word; 
  word-wrap: break-word;
}
</style>
