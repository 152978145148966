<template>
  <TransitionRoot appear :show="openConfirmSwap" as="template">
    <Dialog as="div" :open="openConfirmSwap" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
            leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <div class="
                  inline-block
                  w-full
                  max-w-xl
                  p-6
                  my-8
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-xl
                  rounded-md
                ">
              <DialogTitle as="h3" class="text-xl font-lg leading-6 text-gray-900">
                Swap a Judge
              </DialogTitle>
              <div class="mt-0">
                <p class="text-sm text-gray-500">
                  <b>{{ `${this.selectedUser?.firstName} ${this.selectedUser?.lastName}` }} </b> swapping with
                  <b>{{`${this.selectedJudge?.firstName} ${this.selectedJudge?.lastName}` }}</b> as a judge on submission <b>{{ initiative.name }} ?</b>
                </p>
              </div>
              <div class="mx-20 my-4 p-4 border text-gray-600 border-gray-600 rounded-md">
                <p class=" font-bold text-lg">Changes to Judges</p>
                <div class="flex justify-between">
                  <div class="m-2 w-52">
                    <p class="mb-1">Current Judge</p>
                    <div class="flex">
                      <img class="
                              mr-2
                              mt-0.5
                              userImage
                              h-5
                              w-5
                              rounded-full
                              ring-2 ring-white
                              shadow
                              hover:ring-4
                              cursor-pointer
                            " :src="this.selectedUser?.profilePic" alt="users" />
                      <div class="text-black font-semibold">{{ `${this.selectedUser?.firstName} ${this.selectedUser?.lastName}` }}</div>
                    </div>
                  </div>
                  <div>
                    <svg class="mt-10 mx-5" xmlns="http://www.w3.org/2000/svg" width="20.243" height="13.5" viewBox="0 0 20.243 13.5">
  <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z" transform="translate(-7.875 -11.252)" fill="#4b5563"/>
</svg>

                  </div>
                  <div class="m-2 float-right w-52">
                    <p class="mb-1">New Judge</p>
                    <div class="flex">
                      <img class="
                              mr-2
                              mt-0.5
                              userImage
                              h-5
                              w-5
                              rounded-full
                              ring-2 ring-white
                              shadow
                              hover:ring-4
                              cursor-pointer
                            " :src="this.selectedJudge?.profilePic" alt="users" />
                      <div class="text-black font-semibold">{{ `${this.selectedJudge?.firstName} ${this.selectedJudge?.lastName}` }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <button type="button" class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      mr-3
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-indigo-600
                      bg-indigo-500
                      text-white
                      duration-200
                      ease-in-out
                      transition
                      disabled:opacity-30
                    " @click="this.updateJudge">
                  Confirm
                </button>
                <button type="button" @click="this.cancel()" class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      duration-200
                      ease-in-out
                      transition
                    ">
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "SwapConfirmation",
  data() {
    return {

    };
  },
  props: {
    openConfirmSwap: Boolean,
    currentPhaseIndex: Number,
    initiative: Object
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  computed: {
    ...mapGetters(["phaseNames", "selectedUser", "currentPhaseTeamMembers"]),
    selectedJudge: function () {
      let selectedUser;
      this.currentPhaseTeamMembers?.forEach((member)=>{
        if(member.isChecked){
          selectedUser = member;
        }
      })
      return selectedUser;
    }
  },
  methods: {
    updateJudge: async function () {
      this.$emit('createJudgingTeamForPhase', {
        initiative: this.initiative,
        currentPhaseIndex: this.currentPhaseIndex,
        phaseTeamMembers: this.currentPhaseTeamMembers
      })
    },
    getTeamMembers: async function () {
      if(this.initiative.phaseInfo.metaData.teamId){
        let payload = {
          teamId: this.initiative.phaseInfo.metaData.teamId,
          mutation: 'CURRENT_PHASE_TEAM_MEMBERS'
        }
        await this.$store.dispatch("getTeamMembersById", payload);
      }
      if(this.initiative.phaseInfo.metaData.selectedJudgesTeamId){
        let payload = {
          teamId: this.initiative.phaseInfo.metaData.selectedJudgesTeamId,
          mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
        }
        await this.$store.dispatch("getTeamMembersById", payload);
      }
      if(this.selectedTeamMembersPhase){
        this.filterPhaseTeamMembersOnCategory.map((member)=>{
          this.selectedTeamMembersPhase.map((selectedMember)=>{
            if(member._id == selectedMember._id){
              member.isChecked = true;
            }
          })
        })
      }
    },
    cancel: async function () {
            await this.getTeamMembers();
            await this.$emit('openConfirmationSwapModal', false);
        },
  }
};
</script>
  