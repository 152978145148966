<template>
  <div
    class="absolute bg-cover h-screen w-full bg-left-bottom"
    style="
      background-image: url('https://res.cloudinary.com/dqddllkvx/image/upload/v1652676496/idea-image_bmiyvk.jpg');
    "
  >
    <div class="h-screen flex">
      <div class="flex w-1/2"></div>
      <div class="flex w-1/2 justify-center items-center">
        <div class="px-10 lg:px-32 xl:px-40 text-center text-white">
          <div
            v-if="
              !user.roles.includes('contractor') && playbook?.createInitiative
            "
          >
            <p class="mb-4 leading-relaxed">
              Looks like you do not have any submissions!<br />
              Do you want to create one?
            </p>
            <div class="flex justify-center">
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  text-lg
                "
                @click="postNewIdea"
              >
                Create New Submission
              </button>
            </div>
          </div>
          <div v-else>
            <div class="mb-4 leading-relaxed">
              <p class="text-2xl md:text-2xl leading-normal mb-4">
                Sorry! Submission window is closed!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user", "playbook"]),
  },
  methods: {
    postNewIdea: function () {
       this.$emit("postNewIdea");
    }
  },
};
</script>
