<template>
  <div> {{data[rowIndex][prop]}}</div>
  <!-- <button class="bg-white" v-on:click="iAmClicked">You clicked me {{ count }} times.</button> -->
</template>
<script>
// myVue.vue

// your vue component
export default {
  // access any cell properties here
  props: ["rowIndex", "prop", "data"],
  name: "BoardData",
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    iAmClicked(data) {
      alert(data);
     
    },
  },
};
</script>
