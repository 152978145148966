<template>
  <TransitionRoot appear :show="openUnpromote" as="template">
    <Dialog as="div" :open="openUnpromote" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg text-medium leading-6 font-semibold text-red-500"
              >
                Unpromote Submission
              </DialogTitle>
              <div class="mt-0">
                <p class="text-sm text-gray-500">
                  Do you want to unpromote submission
                  <b>{{ initiative.name }} </b> from
                  <b>{{ initiative.phase }}</b> to <b>{{ previousPhase }} ?</b>
                </p>
              </div>

              <div class="mt-2" v-if="isCommentsEnabled">
                <label for="comment" class="text-sm font-semibold block mb-1"
                  >Comment</label
                >
                <textarea
                  name="comment"
                  id="comment"
                  rows="6"
                  placeholder="Reason"
                  v-model="comment"
                  class="
                    bg-gray-200
                    appearance-none
                    border-2 border-gray-200
                    rounded
                    shadow-md
                    w-full
                    py-2
                    px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none
                    focus:bg-white
                    focus:border-gray-500
                  "
                ></textarea>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  :disabled="loader"
                  @click="this.loader=true;
                    $emit('unpromote', {
                      initiative,
                      phaseIndex,
                      comment,
                      isCloseFlag: false,
                    })
                  "
                >
                <svg v-if="loader" class="animate-spin mt-1 -ml-1 mr-3 h-3 w-3 text-white"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                  </circle>
                  <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                  Unpromote
                </button>
                <button
                  type="button"
                  @click="$emit('openUnpromoteModal', false)"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "ConfirmationDialog",
  data() {
    return {
      comment: "",
      loader:false
    };
  },
  props: {
    phaseIndex: Number,
    openUnpromote: Boolean,
    initiative: Object,
    phaseIndex: Number,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  computed: {
    ...mapGetters(["phaseNames","playbook"]),
    previousPhase() {
      let playbookPhases = this.phaseNames.map((phase)=>{
        return phase.label;
      })
      return playbookPhases[this.initiative.phaseIndex - 1];
    },
    isCommentsEnabled:function(){
      if(this.playbook?.hasOwnProperty("metaData") &&
        this.playbook.metaData.enableUnpromoteComments){
          return this.playbook.metaData.enableUnpromoteComments
        }
      return false;  
    },
  },
};
</script>
