<template>
  <!-- <pre>{{ selectedIdea }}</pre> -->
  <div
    class="overflow-auto sticky top-0 ease-in-in duration-500"
    style="height: calc(100vh - 60px); margin-top: 60px"
  >
    <h3
      class="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200"
    >
      Judging Panel
      <span
        class="
          cursor-pointer
          float-right
          hover:bg-gray-300 hover:rounded-r-full
        "
        @click="closePanel"
      >
        <IconClose />
      </span>
    </h3>
    <div class="float-left w-full p-5">
      <IdeaDetail
        :selectedIdea="selectedIdea"
        :activitiesToUpdate="activitiesToUpdate"
        :selectedInitiative="selectedInitiative"
        @viewDetail="viewDetail"
      />
    </div>

    <!-- tab structure  started-->
    <!-- <nav class="flex flex-col sm:flex-row text-sm">
    <button
      @click="setActive('tab1')"
      class="
        flex-grow
        text-gray-600
        py-2
        px-4
        block
        hover:text-blue-500
        focus:outline-none
        border-b-2
      "
      :class="{
        'text-blue-500 border-b-2 font-medium border-blue-500':
          activeItem === 'tab1',
      }"
    >
      Feedback History
    </button>
    <button
      @click="setActive('tab2')"
      class="
        flex-grow
        text-gray-600
        py-2
        px-4
        block
        hover:text-blue-500
        focus:outline-none
        border-b-2
      "
      :class="{
        'text-blue-500 border-b-2 font-medium border-blue-500':
          activeItem === 'tab2',
      }"
    >
      Private Messages
    </button>
  </nav> -->

    <!-- tab structure ended -->

    <!-- <div
      class="float-left w-full p-5 border-b border-gray-200"
      v-if="!hideForm"
    >
      <ReviewForm
        :selectedIdea="selectedIdea"
       
        :user="user"
        from="judging"
      />
    </div> -->
    <div class="float-left w-full p-5" v-if="activeItem === 'tab1'">
      <feedback-history
        :selectedIdea="selectedIdea"
        :selectedInitiative="selectedInitiative"
        :user="user"
        :parentInitiative="parentInitiative"
        :allComments="allComments"
        @updateComments="updateComments"
        @getAllIdeasCount="getAllIdeasCount"
        @openPanel="openPanel"
        @showToaster="showToaster"
      />

      <JudgingLostConfirm v-if="judgingLostConfirmFlag"
        :judgingLostConfirmFlag="judgingLostConfirmFlag"
        @openJudgingLostConfirmModel="openJudgingLostConfirmModel"
        @judging="judging"
        @lostChanges="lostChanges"
        @cancelChanges="cancelChanges"
        :initiative="initiative"
        :phaseIndex="phaseIndex"
        :headerText="headerText"
        :infoText="infoText"
        :footerText="footerText"
      />
    </div>
  </div>
</template>
<script>
import FeedbackHistory from "./FeedbackHistoryWithPrivateMessages.vue";
import IdeaDetail from "./IdeaDetail.vue";
import ReviewForm from "./ReviewForm.vue";
import IconClose from "../Icon/Close.vue";
import JudgingLostConfirm from "../confirmationModal/JudgingLostConfirm.vue";
import JudgingSubmitAck from "../confirmationModal/JudgingSubmitAck.vue";
import { mapGetters } from "vuex";

export default {
  name: "JudgingPanelLayout",
  components: {
    FeedbackHistory,
    IconClose,
    IdeaDetail,
    ReviewForm,
    JudgingLostConfirm,
  },
  props: {
    selectedIdea: Object,
    activitiesToUpdate: Array,
    selectedInitiative: Object,
    user: Object,
    parentInitiative: Object,
    allComments: Array,
  },
  data() {
    return {
      currentInitiativeId: "",
      activeItem: "tab1",
      judgingLostConfirmFlag: false,
    };
  },
  mounted: function () {},
  computed: {
    ...mapGetters(['playbook']),
    hideForm() {
      return false;
      return (
        this.selectedIdea.status === 3 || this.selectedIdea?.metaData?.updatedBy
      );
    },
  },
  created: function () {},
  methods: {
    openPanel() {
        this.$emit("openPanel", false);
    },

    openJudgingPanel(data) {
        this.$emit("openPanel", data);
    },

    closePanel() {
      if(this.selectedIdea.status == 2 || !this.checkEnableJudging()){
        this.$emit("openPanel", false);
      } else {
        this.judgingLostConfirmFlag = true
      }
    },

    checkEnableJudging: function(){
      if (this.playbook?.hasOwnProperty("metaData") && this.playbook.metaData.enableJudging) {
        return true;
      } else if(this.playbook?.hasOwnProperty("metaData") && !this.playbook.metaData.enableJudging) {
        return false;
      } else if(!this.playbook?.hasOwnProperty("metaData")) {
        return true;
      }
    },

    lostChanges(){
      this.$emit("openPanel", false);
    },

    cancelChanges(){
       this.judgingLostConfirmFlag = false,
       this.$emit("openPanel", true);
    },

    async showToaster(data) {
      this.$emit("showToaster", data);
    },
    viewDetail(id) {
      this.$emit("viewDetail", id);
    },
    async updateComments(initiativeId) {
      this.$emit("updateComments", initiativeId);
    },
     async getAllIdeasCount() {
      this.$emit("getAllIdeasCount");
      this.$emit("pageChanged")
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
};
</script>
<style scoped></style>
