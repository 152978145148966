<template>
  <div class="relative">
    <div
      class="mr-2 cursor-pointer"
      @mouseover="showTooltip"
      @mouseout="hideTooltip"
    >
      <svg
        aria-haspopup="true"
        xmlns="http://www.w3.org/2000/svg"
        class="w-4 h4"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#A0AEC0"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12.01" y2="8" />
        <polyline points="11 12 12 12 12 16 13 16" />
      </svg>
    </div>
    <div
      id="tooltip1"
      role="tooltip"
      class="
        -mt-20
        absolute
        left-0
        ml-8
        shadow-lg
        bg-white
        p-4
        rounded
        z-200
      "
      :class="{ 'opacity-0': !flag, 'w-72': flag, 'w-0' : !flag, 'hidden' : !flag }"
    >
      <svg
        class="absolute left-0 -ml-2 bottom-0 top-0 h-full"
        width="9px"
        height="16px"
        viewBox="0 0 9 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            transform="translate(-874.000000, -1029.000000)"
            fill="#FFFFFF"
          >
            <g
              id="Group-3-Copy-16"
              transform="translate(850.000000, 975.000000)"
            >
              <g id="Group-2" transform="translate(24.000000, 0.000000)"></g>
            </g>
          </g>
        </g>
      </svg>
      <!--
        <p class="text-sm font-bold text-gray-800 pb-1">
          Keep track of follow ups
        </p>
        -->
      <p class="text-xs font-normal leading-4 text-gray-600 pb-3">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WithStepsAlternate",
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    showTooltip() {
      this.flag = true;
    },
    hideTooltip(flag) {
      this.flag = false;
    },
  },
};
</script>