<template>
  <TransitionRoot appear :show="projectModal" as="template">
    <Dialog as="div" :open="projectModal" @close="closeModal">
      <div
        class="
          modal
          h-screen
          w-full
          fixed
          left-0
          top-0
          flex
          justify-center
          items-center
          bg-black bg-opacity-80
          g-opacity-50
          z-30
        "
      >
        <div class="bg-white rounded shadow-lg w-11/12 text-gray-600 h-5/6">
          <div
            class="
              border-b
              p-5
              bg-blue-900
              flex
              justify-between
              items-center
              rounded-t
            "
          >
            <h3 class="font-medium text-white text-lg">
              {{ initiative.phase }} - {{ initiative.name }}
            </h3>
            <button
              class="
                bg-blue-400
                text-sm text-white
                py-2
                px-2
                rounded-full
                focus:outline-none
              "
              @click="$emit('close')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div class="p-3">
            <div>
              <input
                class="border-4 rounded-2xl from_Input pl-1 mb-2"
                type="text"
                placeholder="Search Project"
                v-model="searchText"
              />
              <table class="min-w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sort('name')"
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                        w-1/3
                      "
                    >
                      Name
                    </th>
                    <th
                      @click="sort('key')"
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Key
                    </th>
                    <th
                      @click="sort('projectTypeKey')"
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Type
                    </th>
                    <th
                      @click="sort('style')"
                      scope="col"
                      class="
                        px-2
                        py-2
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Style
                    </th>
                    <th scope="col" class="relative px-2 py-2">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <div v-for="project in sortedProjects" :key="project.id">
                    <tr @click="showVersionsForProject(project)">
                      <td class="px-2 py-3 w-1/3">
                        <div class="flex items-center">
                          <div class="flex-shrink-0 h-8 w-8">
                            <img
                              class="h-8 w-8 rounded-full"
                              :src="project.avatarUrls['48x48']"
                              alt=""
                            />
                          </div>
                          <div class="ml-4 hover:cursor-pointer">
                            <div
                              class="text-sm font-medium text-gray-900"
                              :class="{
                                'font-bold text-blue-500 text-lg':
                                  project.self === previouslySelectedProject,
                              }"
                            >
                              {{ project.name }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3 whitespace-nowrap">
                        <div
                          class="text-sm text-gray-900"
                          :class="{
                            'font-bold text-blue-500 text-lg':
                              project.self === previouslySelectedProject,
                          }"
                        >
                          {{ project.key }}
                        </div>
                      </td>
                      <td class="px-2 py-3 whitespace-nowrap">
                        <span
                          class="text-sm text-gray-900"
                          :class="{
                            'font-bold text-blue-500 text-lg':
                              project.self === previouslySelectedProject,
                          }"
                        >
                          {{ project.projectTypeKey }}
                        </span>
                      </td>
                      <td
                        class="
                          px-2
                          py-3
                          whitespace-nowrap
                          text-sm text-gray-500
                        "
                        :class="{
                          'font-bold text-blue-500 text-lg':
                            project.self === previouslySelectedProject,
                        }"
                      >
                        {{ project.style }}
                      </td>
                      <td
                        class="
                          px-2
                          py-2
                          whitespace-nowrap
                          text-right text-sm
                          font-medium
                        "
                      >
                        <a
                          href="#"
                          class="text-indigo-600 hover:text-indigo-900"
                        ></a>
                      </td>
                    </tr>
                    <div
                      v-if="
                        project.isEpicTabOpen && openProjecttabId === project.id
                      "
                    >
                      <tr
                        v-for="(release, index) in projectReleases"
                        v-bind:key="index"
                        @click="showJiraEpicModal(true, project, release)"
                      >
                        <td class="px-2 py-2 w-1/3" :key="index">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 h-8 w-8"></div>
                            <div class="ml-4">
                              <div class="text-sm font-medium text-gray-900">
                                <button></button>
                                Release : {{ release.name }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-2 py-2 whitespace-nowrap">
                          <div class="text-sm text-gray-900"></div>
                        </td>
                        <td class="px-2 py-2 whitespace-nowrap">
                          <span class="text-sm text-gray-900"> </span>
                        </td>
                        <td
                          class="
                            px-2
                            py-2
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        ></td>
                        <td
                          class="
                            px-2
                            py-2
                            whitespace-nowrap
                            text-right text-sm
                            font-medium
                          "
                        >
                          <!-- <a href="#" class="text-indigo-600 hover:text-indigo-900"
                        >Edit</a
                      > -->
                        </td>
                      </tr>
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  
  <jira-epic
    v-if="jiraEpic"
    :jiraEpic="jiraEpic"
    :selectedProject="selectedProject"
    :selectedRelease="selectedRelease"
    :previouslySelectedEpics="previouslySelectedEpics"
    :allEpics="allEpics"
    :changeAndViewEpicsFlag="changeAndViewEpicsFlag"
    :initiative="initiative"
    @close="closeModal"
    @closeModalOnEpicSelection="closeModalOnEpicSelection"
    @changechangeAndViewEpicsFlagValue="changechangeAndViewEpicsFlagValue"
  ></jira-epic>
  <loader-view v-if="loaderFlag" :loaderFlag="loaderFlag" loadingTitle=""> </loader-view>
</template>
<script>
import { mapGetters } from "vuex";
import JiraEpic from "./JiraEpic.vue";
import Project from "../../../services/jira/projects";
import LoaderView from "./LoaderView.vue";

export default {
  name: "jira-project",
  props: {
    projectModal: Boolean,
    previouslySelectedEpics: Array,
    previouslySelectedProject: String,
    previouslySelectedRelease: String,
    viewEpicsWithoutProjectModalFlag: Boolean,
    selectedIntegrationId: String,
    initiative: Object,
  },
  data() {
    return {
      jiraEpic: false,
      people: Project,
      projectReleases: [],
      jiraProjects: [],
      projectKey: "",
      allEpics: {},
      selectedProject: {},
      selectedRelease: {},
      openProjecttabId: "",
      changeAndViewEpicsFlag: false,
      beforeUpdateFlag: false,
      loaderFlag: false,
      searchText: "",
      // below is for table filter
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  async updated() {},
  created: async function () {
    let payload = {
      routeParameters: "/rest/api/2/project",
      integrationId: this.selectedIntegrationId,
    };
    this.loaderFlag = true;
    this.jiraProjects = await this.$store.dispatch("jiraAPI", payload);
    this.loaderFlag = false;
  },
  computed: {
    ...mapGetters([
      // "jiraProjects",
    ]),
    sortedProjects: function () {
      this.jiraProjects = this.jiraProjects.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      let allProjects = this.jiraProjects;
      if (this.searchText.length > 0) {
        return allProjects.filter((singleProject) => {
          return singleProject.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        return allProjects;
      }
    },
  },
  methods: {
    showVersionsForProject: async function (project) {
      this.loaderFlag = true;
      if (project.self === this.previouslySelectedProject) {
        this.changeAndViewEpicsFlag = true;
      }
      this.openProjecttabId = project.id;
      this.selectedProject = project;
      // item.isEpicTabOpen = !item.isEpicTabOpen;
      project.isEpicTabOpen = true;
      let routeParameters = "/rest/api/2/project/" + project.key + "/versions";
      let payload = {
        routeParameters: routeParameters,
        integrationId: this.selectedIntegrationId,
      };
      this.projectReleases = await this.$store.dispatch("jiraAPI", payload);
      this.loaderFlag = false;
      if (this.projectReleases && this.projectReleases.length === 0) {
        this.showJiraEpicModal(true, project, project);
      }
    },
    // openItem: function (item) {
    //   item.isEpicTabOpen = !item.isEpicTabOpen;
    // },
    setClass: function (item) {
      if (item.isEpicTabOpen == true) {
        return "open";
      }
      return "close";
    },
    showJiraEpicModal: async function (value, project, release) {
      this.loaderFlag = true;
      this.selectedRelease = release;
      let routeParams = `/rest/api/2/search?jql=issuetype = Epic AND project = ${project.key}`;
      // let routeParams = `/rest/api/2/search?jql=project = ${project.name} AND issuetype = Epic AND fixVersion = ${release.name}  ORDER BY Key`;
      let payload = {
        routeParameters: routeParams,
        integrationId: this.selectedIntegrationId,
      };
      this.allEpics = await this.$store.dispatch("jiraAPI", payload);
      this.jiraEpic = value;
      this.loaderFlag = false;
    },
    closeModal: function () {
      this.jiraEpic = false;
    },
    closeModalOnEpicSelection: function (
      selectedEpics,
      selectedProject,
      selectedRelease
    ) {
      this.$emit(
        "closeModalOnEpicSelection",
        selectedEpics,
        selectedProject,
        selectedRelease
      );
      this.jiraEpic = false;
    },
    changechangeAndViewEpicsFlagValue: function () {
      this.changeAndViewEpicsFlag = false;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
  },
  mounted: function () {},
  components: {
    JiraEpic,
    LoaderView,
  },
  async beforeUpdate() {
    if (this.viewEpicsWithoutProjectModalFlag) {
      await this.$emit("loaderFlagMethod", true);
      await this.$emit("viewEpicsWithoutProjectModal", false);
      let projectSplitKeys = this.previouslySelectedProject.split("/");
      let releaseSplitKeys = this.previouslySelectedRelease.split("/");
      let projectKey = projectSplitKeys[projectSplitKeys.length - 1];
      let releaseKey = releaseSplitKeys[releaseSplitKeys.length - 1];
      let routeParametersforProject = "/rest/api/2/project/" + projectKey;
      let routeParametersforRelease = "/rest/api/2/version/" + releaseKey;
      let projectInfoPayload = {
        routeParameters: routeParametersforProject,
        integrationId: this.selectedIntegrationId,
      };
      let releaseInfoPayload = {
        routeParameters: routeParametersforRelease,
        integrationId: this.selectedIntegrationId,
      };
      let projectInfo = await this.$store.dispatch(
        "jiraAPI",
        projectInfoPayload
      );
      let releaseInfo = await this.$store.dispatch(
        "jiraAPI",
        releaseInfoPayload
      );
      await this.showJiraEpicModal(true, projectInfo, releaseInfo);
      await this.showVersionsForProject(projectInfo);
      await this.$emit("loaderFlagMethod", false);
      // this.jiraEpic = true;
    }
  },
};
</script>

<style scoped>
table,
tr td {
}
tbody {
  display: block;
  height: 400px;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
  /* even columns width , fix width of table too*/
}
thead {
  width: calc(
    100% - 1em
  ); /* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
  width: 400px;
  margin: 0 0 20px 0;
}

.from_Input:active {
  border: none;
  outline: none;
}
input[type="text"] {
  outline: none;
}
input[type="text"]::-moz-focus-inner {
  border: 0;
}
</style>
