<template>
  <TransitionRoot appear :show="openAddFile" as="template">
    <Dialog as="div" :open="openAddFile">
      <div  class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
              "
            >
              <DialogTitle
                as="h3"
                class="
                  text-lg
                  font-medium
                  leading-6
                  text-gray-900
                  border-b
                  pb-3
                "
              >
                Attachments : {{ this.title }} Link
              </DialogTitle>

              <div class="mt-4" v-if="fileObj">
                <div class="w-full mr-5 pb-2">
                  <label class="block text-gray-700 text-xs mb-1" for="linkname"
                    >Add Link Name<span class="text-red-500"
                      >&nbsp;*</span
                    ></label
                  >
                  <input
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                    id="fileName"
                    v-model="fileObj.documentName"
                    type="text"
                  />
                </div>

                <div class="w-full mr-5">
                  <label class="block text-gray-700 text-xs mb-1" for="username"
                    >Add Link<span class="text-red-500">&nbsp;*</span></label
                  >
                  <input
                    class="
                      bg-gray-200
                      appearance-none
                      border-2 border-gray-200
                      rounded
                      shadow-md
                      w-full
                      py-2
                      px-4
                      text-gray-700
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                    "
                    id="filePath"
                    v-model="fileObj.documentUrl"
                    type="url"
                    placeholder="Enter URL"
                  />
                </div>
                <span
                  class="text-sm text-red-500"
                  v-if="
                    fileObj.documentUrl.length !== 0 &&
                    !validURL(fileObj.documentUrl)
                  "
                >
                  Please Enter valid URL
                </span>
              </div>

              <!-- <p v-if="this.showTextBox" class="-mb-1 text-sm text-gray-500">max. 2000 characters</p> -->

              <div class="mt-4" v-if="fileObj">
                <button
                  type="button"
                  :disabled="
                    fileObj.documentName.length === 0 ||
                    fileObj.documentUrl.length === 0 ||
                    !validURL(fileObj.documentUrl)
                  "
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  @click="submitFile"
                >
                  {{ this.buttonText }} Link
                </button>
                <button
                  type="button"
                  @click="$emit('openAddFileModal', false)"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { object } from "yup/lib/locale";
import objectId from "../../helpers/objectId";

export default {
  name: "AddFiles",
  beforeUpdate() {
    if (this.todo == "add") {
      this.title = "Add ";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Add";
      this.info = "";
    } else if (this.todo == "edit") {
      this.title = "Edit ";
      this.showTextBox = true;
      this.heading = "";
      this.buttonText = "Update";
      this.info = "";
    }
  },
  props: {
    openAddFile: Boolean,
    files: Array,
    fileObj: Object,
    todo: String,
  },
  data() {
    return {
      comment: "",
      title: "",
      showTextBox: false,
      heading: "",
      buttonText: "",
      info: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    accessToken() {
      const { accessToken } = this.user;
      const tokenString = `Bearer ${accessToken}`;
      return tokenString;
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {
    submitFile() {
      let payload = {
        documentId: objectId(),
        uploadedBy: this.user.aspenifyUserId,
        uploadedAt: new Date(),
        documentName: this.fileObj.documentName,
        documentUrl: this.fileObj.documentUrl,
      };

      if (this.todo === "edit") {
        var foundIndex = this.files.findIndex(
          (x) => x.uploadedAt == this.fileObj.uploadedAt
        );
        this.files.slice(0, foundIndex);
        this.files[foundIndex] = payload;
      } else {
        this.files.unshift(payload);
      }

      this.$emit("fileUploaded", this.files);
      this.$emit("openAddFileModal", false);
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*"  // port and path
      ); // fragment locator

      return !!pattern.test(str);
    },
  },
};
</script>
