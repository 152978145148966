<template>
  <div class="px-6 py-6" :key="componentKey">
    <h3 class="mb-3">Submission</h3>
    <div class="
      flex
      bg-white
      border-l-4 border-gray-300
      rounded-lg
      shadow
      text-gray-800
      px-4
      py-3
    " role="alert">
      <div class="ml-3 flex-grow font-bold">Submissions</div>
      <div class="mr-2">
        <Checkbox :label="Submission" theme="primary" text="" position="left"
          @onChangeCheckbox="onChangeCheckbox('Submission')" :defaultValue="value" :id="Submission" />
      </div>
    </div><br />
    <div class="
      flex
      bg-white
      border-l-4 border-gray-300
      rounded-lg
      shadow
      text-gray-800
      px-4
      py-3
    " role="alert">
      <div class="ml-3 flex-grow font-bold">Judging</div>
      <div class="mr-2">
        <Checkbox :label="Judging" theme="primary" text="" position="left"
          @onChangeCheckbox="onChangeCheckbox('Judging')" :defaultValue="judgingValue" :id="Judging" />
      </div>
    </div>
  </div>
</template>

<script >
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      componentKey: 0,
      value: false,
      judgingValue: false
    };
  },
  beforeMount() {
    if (
      this.playbook?.hasOwnProperty("createInitiative") &&
      this.playbook.createInitiative
    ) {
      this.value = true;
    } else {
      this.value = false;
    }
    if (
      this.playbook?.hasOwnProperty("metaData") &&
      this.playbook.metaData.enableJudging
    ) {
      this.judgingValue = true;
    } else {
      this.judgingValue = false;
    }
  },
  beforeUpdate: function () {
    if (
      this.playbook?.hasOwnProperty("createInitiative") &&
      this.playbook.createInitiative
    ) {
      this.value = true;
    } else {
      this.value = false;
    }
    if (
      this.playbook?.hasOwnProperty("metaData") &&
      this.playbook.metaData.enableJudging
    ) {

      this.judgingValue = true;
    } else {
      this.judgingValue = false;
    }
  },
  components: {},
  computed: {
    ...mapGetters(["playbook"]),
  },
  watch: {
    playbook: function (newValue, oldValue) {
      this.componentKey += 1;
    },
  },
  created: async function () {},
  methods: {
    async onChangeCheckbox(key) {
      let payload = {
        id: this.playbook._id
      };
      if(key == 'Submission'){
        this.value = !this.value;
        payload.updateData = { 'createInitiative': this.value }
      }
      if(key == 'Judging'){
        this.judgingValue = !this.judgingValue;
        payload.updateData = { 'metaData.enableJudging': this.judgingValue }
      }
      await this.$store.dispatch("updatePlaybook", payload);
      await this.$store.dispatch("readPlaybook");
    }
  },
};
</script>
<style scoped>
</style>