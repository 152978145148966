<template>
  <div
    :class="{
      richTextFull: showFull,
      richText: !showFull,
    }"
    v-if="text"
  >
    <span
      v-for="(line, lineNumber) of text.split('\n')"
      v-bind:key="lineNumber"
    >
      {{ line }}<br />
    </span>
  </div>
</template>
<script>
export default {
  name: "RichText",
  props: {
    text: String,
    showFull: Boolean,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
.richText {
  /* overflow: auto;
  max-height: 220px; */
  word-break: break-word;
  text-align: justify;
}
.richText:hover {
  overflow: auto;
}
.richTextFull {
  word-break: break-word;
}
</style>
