<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-40
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white shadow-lg w-5/12 text-gray-600">
        <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
          <div class="font-medium text-white text-lg flex w-6/12">
            <div class="flex-grow w-1/12 font-bold mt-2">
              Self-terminate Submission
            </div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('openSelfTerminateModal', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div class="p-5">
          <!-- <p class="font-bold">
            Please confirm you intrest in signing up for an Open Mic session.
          </p>
          <p class="my-3">
            Once submitted you will receive a message from the Open Mic program
            team indicating the scheduled date for your Open Mic session.
          </p> -->
          <div>
            <div>
              <h4 class="font-bold">Do you want to terminate your idea?</h4>
              <p>
                Your idea will be self-terminated and will not be active
                anymore.
              </p>
            </div>
            <div class="mt-5">
              <h4 class="font-bold">Share your learnings</h4>
              <p>
                You can download and fill-in a Powerpoint template to share your
                learnings and upload it as an attachement to your idea
              </p>
            </div>
            <div class="flex mt-5 border border-red p-5">
              <div class="items-center justify-center">
                <button
                  @click="downloadAttachment"
                  class="
                    inline-flex
                    text-white
                    bg-indigo-500
                    border-0
                    py-1
                    px-3
                    focus:outline-none
                    hover:bg-indigo-600
                    rounded
                  "
                >
                  <a
                    class="btn btn-danger"
                    role="button"
                    :href="attachmentUrl"
                    download="proposed_file_name"
                    target="_blank"
                  >
                    Share Learnings
                  </a>
                </button>
              </div>
            </div>

            <div class="text-right mt-3">
              <button
                class="
                  inline-flex
                  text-gray-700
                  bg-gray-100
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-gray-200 hover:border hover:border-gray-700
                  rounded
                  text-base
                  mx-2
                "
                @click="$emit('openSelfTerminateModal', false)"
              >
                Cancel
              </button>
              <button
                :disabled="!enableSave"
                class="
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  text-sm
                  disabled:opacity-30
                  ml-2
                "
                @click="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import feathersClient from "../../services/feathersClient";

export default {
  name: "OpenmicScheduledMarkAsCompleted",
  components: {},
  props: {
    selectedInitiative: Object,
  },
  emits: ["closeMarkAsCompletedModal"],
  data() {
    return {
      comment: "",
      enableSave: false,
    };
  },
  methods: {
    downloadAttachment: function () {
      this.enableSave = !this.enableSave;
    },
    submit: async function () {
      let initiativeService = feathersClient.service("initiatives");
      let initiativeDetails = await initiativeService.patch(
        this.selectedInitiative.id,
        { status: 6 }
      );
      this.$emit("openSelfTerminateModal", false);
      await this.$store.dispatch(
        "getInitiativeDetailsById",
        this.selectedInitiative.id
      );
    },
  },
  computed: {
    attachmentUrl(){
      return process.env.VUE_APP_IDEA_ATTACHMENT_URL
    }
  },
  mounted: function () {},
  created: function () {},
  beforeUpdate() {},
};
</script>
