<template>
  <div v-if="notes?.length>0">
        <div class="mt-3" v-for="(data, index) in notes" :key="index">
          <div :class="{'w-11/12': openPanelFlag, 'w-full': !openPanelFlag}" class="
            bg-white
            shadow
            text-gray-800
              rounded-2xl">
              <div class="
                mt-2
                bg-white
                text-gray-800
                px-4
                py-3
                h-full
                w-full
                rounded-2xl">
                 <!-- <div class="w-11/12" v-html="note.content" @click="openEditNote(note, index)"></div> -->
                 
                 <div class="w-full  mt-5">
                        <div v-if="editField==index">
                          <inline-edit
                            component="textarea"
                            label="notes"
                            :value="data.metaData.content"
                            @editMe="editMe"
                            @updateValue="updateValue"
                            :index="index"
                            :noteID="data._id"
                            :isJudgingComments=true
                          >
                          </inline-edit>
                        </div>
                        <div
                          v-else
                          @click=" editMe(index)   
                          "
                          :class="hoverClass"
                          class="mt-2 text-base text-gray-600 py-1 hover:bg-gray-200 hover:shadow-md rounded  -mx-2 px-2"
                        >
                        <div v-html="data.metaData.content" class="prose space-y-0 html-text word-wrap"></div>
                          <!-- <RichText :text="selectedInitiative.description" /> -->
                        </div>
                      </div>
                 <div >
                </div>
              </div>
              <div class="flex justify-between ">
                    <div class="pl-4">
                      <span class="text-sm">{{ formattedDate(data.metaData.notedate) }}</span>&nbsp;
                      <span class="text-sm ">{{ formattedTime(data.metaData.notedate) }}</span>
                    </div>  
                     <div class=" flex pr-4  cursor-pointer blue-400 text-blue-600">
                         <div v-if="editField==index" class="cursor-pointer  ml-2">
                          <button class="
                               backgroundColor
                             hover:bg-blue-500
                             text-sm text-white
                             py-1
                             px-1
                             rounded-full
                             focus:outline-none
                             text-center
                             float-right
                             mb-2
                             mr-4
                             w-7   
                           " @click="closeEditor()">
                          OK
                        </button>
                         </div>
                         <div class="mt-1">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" @click="openDeleteNote(index,data._id)" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 hover:opacity-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>      
                         </div>
                      <!-- <svg  style="color: blue" xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="blue"></path> <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="blue"></path> </svg> -->
                     </div> 
                </div>     
          </div>
        </div>
        <JudgingDeleteNote 
            :openCloseDeleteNote="openCloseDeleteNote" 
            :index="index"
            @setOpenCloseDeleteNote="setOpenCloseDeleteNote"
            :noteID="id"
            :openPanelFlag="openPanelFlag"
            >
        </JudgingDeleteNote> 
            <!-- <JudgingEditNote 
      :openCloseEditNote="openCloseEditNote" 
      :note="editNoteContent"
      :index="index"
      :openPanelFlag="openPanelFlag"
      @setOpenCloseEditNote="setOpenCloseEditNote">
    </JudgingEditNote> -->
    </div>  
</template>
<script>

import JudgingDeleteNote from "./JudgingDeleteNote.vue"
import JudgingEditNote from "./JudgingEditNote.vue"
import moment from "moment";
import InlineEdit from "../InlineEdit.vue";
export default {
  data() {
    return {
      showMenu: false,
      openCloseEditNote: false,
      openCloseDeleteNote: false,
      editNoteContent: "",
      index:null,
      componentKey:0,
      editField:null
    };
  },
props:{
  notes:Array,
  openPanelFlag:Array
},
components:{
   JudgingDeleteNote,
   JudgingEditNote,
   InlineEdit 
},
methods: {
    openEditNote(payload, index){
      console.log(payload.content);
      console.log(index);
      this.openCloseEditNote = true;
      this.editNoteContent = payload.content;
      this.index = index;
      this.showMenu = false;
    },
    setOpenCloseEditNote(payload){
      this.openCloseEditNote = payload;
      this.$emit('save', this?.selectedActivity?._id);
    },
    openDeleteNote(index,id){
      this.openCloseDeleteNote = true;
      this.index = index;
      this.id = id;
      this.showMenu = false;
    },
    setOpenCloseDeleteNote(payload){
      this.openCloseDeleteNote = payload;
      this.$emit('deleteNote', );
      this.$emit('refreshData');
    },
    editMe(index) {
      this.$emit('refreshData');
      this.editField = index;
    },
    showDropdownList(payload, index){
      this.selectedNoteIndex = index;
      this.showMenu = !this.showMenu;
    },
    updateContent(updatedValue,label){ 
        this.value=updatedValue
        this.updatedValue=updatedValue
    },
    editNote: async function(){
       if(this.value.length==0){
        this.value=this.note
       }
        
      let payload = {
        user: this.user,
        index:this.index, 
        data: this.value,
        }
      
     this.$store.dispatch("editNote", payload);
      // await this.$store.dispatch("updateProfile", query);
      this.$emit('setOpenCloseEditNote', false);
    },
    formattedDate(value) {
    // Use moment.js to format the date
    return moment(value).format('DD/MM/YYYY');
  },
  closeEditor(){
    this.$emit('refreshData');
    this.editField=null
  },
  formattedTime(value) {
    // Use moment.js to format the date
    return moment(value).format('hh:mm A');
  },
  },

}
</script>
<style scoped>
.marginDropdown{
margin-top: -40px;
}
.backgroundColor{
    background-color: #4CB0D6;
  }
.word-wrap {
  overflow-wrap: break-word; 
  word-wrap: break-word;
}  
</style>
