<template>
    <div :key="componentKey">
        <div v-if="loading">
            <div>
                <v-chart :loading="loading" class="chart" :option="option" @click="handleClick" />
            </div>
        </div>
        <div v-else>
            <div v-if="dates.length !== 0">
                <v-chart class="chart" :option="option" @click="handleClick" />
            </div>
            <div v-else>
                <div class="
              container
              mx-auto
              flex
              px-5
              py-10
              items-center
              justify-center
              flex-col
            ">
                    <img src="@/assets/images/idea_pipeline.png" width="200" />
                    <div class="text-center lg:w-2/3 w-full">
                        <div class="mb-8 mt-8 leading-relaxed">
                            <h3 class="text-sm text-blue-900">No Data available!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import moment from "moment";
import { GridComponent } from "echarts/components";
use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

export default defineComponent({
    name: "loginBar",
    components: {
        VChart,
    },
    data() {
        return {
            loader: true,
            componentKey: 0,
        };
    },
    watch: {
        data() {
            this.componentKey += 1;
            this.loader = false;
        },
    },
    props: {
        heading: String,
        data: Array,
        loading: Boolean,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    setup(props) {
        const dateCounts = {};
        props.data.forEach((login) => {
            const date = moment(new Date(login.createdAt)).format('MM-DD-YYYY');
            dateCounts[date] = (dateCounts[date] || 0) + 1;
        });

        const dates = getLastMonthDates();
        const loginCounts = dates.map((date) => dateCounts[date] || 0);
        const option = {
            xAxis: [
                {
                    type: "category",
                    axisTick: { show: false },
                    data: dates,
                    axisLabel: {
                        rotate: 60,
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                },
            ],
            legend: {
                top: "top",
            },
            tooltip: {},
            color: ["#00AB50"],
            series: [
                {
                    name: "User Logins",
                    type: "bar",
                    barGap: 4,
                    label: {
                        show: true,
                        position: "top",
                    },
                    data: loginCounts,
                },
            ],
        };

        return { option, dates };
    },
    methods: {
        handleClick(...args) {
            // this.$emit("viewDetails", args);
        }
    },
});
function getLastMonthDates() {
    const today = moment();
    const startDate = moment(today).subtract(1, "months").set("date", today.date()+1);
    const endDate = today.clone().endOf("day"); 
    const dates = [];
    while (startDate.isSameOrBefore(endDate)) {
        dates.push(startDate.format("MM-DD-YYYY"));
        startDate.add(1, "day");
    }
  return dates;
}
</script>
  
<style scoped>
.chart {
    height: 400px;
}
</style>
  