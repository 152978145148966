<template>
  <TransitionRoot appear :show="openInactive" as="template">
    <Dialog as="div" :open="openInactive" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
              "
            >
              <DialogTitle 
                v-if="(markType=='inactive' || !markType) && (selectedInitiative.status != 6)"
                as="h3"
                class="text-lg text-medium font-semibold text-red-500 leading-6"
              >
                Mark as Inactive
              </DialogTitle>
              <DialogTitle 
                v-if="markType=='active' && (selectedInitiative.status == 6 || selectedInitiative.status == 7)"
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Mark as Active
              </DialogTitle>
              <DialogTitle 
                v-if="markType=='merge'"
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Move to Merge
              </DialogTitle>
              <div class="mt-0">
                <p  v-if="(markType=='inactive' || !markType) && (selectedInitiative.status != 6)" class="text-sm text-gray-500">
                  Are you sure you want to mark this submission as Inactive ?
                  <!-- <b>{{ initiative.name }} </b> from
                  <b>{{ initiative.phase }}</b> to <b>{{ nextPhase }} ?</b> -->
                </p>
                <p  v-if="markType=='active' && (selectedInitiative.status == 6 || selectedInitiative.status == 7)" class="text-sm text-gray-500">
                  Are you sure you want to mark this submission as Active ?
                  <!-- <b>{{ initiative.name }} </b> from
                  <b>{{ initiative.phase }}</b> to <b>{{ nextPhase }} ?</b> -->
                </p>
                <p  v-if="markType=='merge'" class="text-sm text-gray-500">
                  Are you sure you want to move this submission to Merge ?
                  <!-- <b>{{ initiative.name }} </b> from
                  <b>{{ initiative.phase }}</b> to <b>{{ nextPhase }} ?</b> -->
                </p>
              </div>
              <div class="mt-2" v-if="isCommentsEnabled">
                <label for="comment" class="text-sm font-semibold block mb-1"
                  >Add your comment <span v-if="markType=='merge'" class="text-red-500">*</span></label
                >
                <textarea
                  name="comment"
                  id="comment"
                  rows="6"
                  placeholder="Reason"
                  v-model="comment"
                  class="
                    bg-gray-200
                    appearance-none
                    border-2 border-gray-200
                    rounded
                    shadow-md
                    w-full
                    py-2
                    px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none
                    focus:bg-white
                    focus:border-gray-500
                  "
                ></textarea>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    mr-3
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-indigo-600
                    bg-indigo-500
                    text-white
                    duration-200
                    ease-in-out
                    transition
                    disabled:opacity-30
                  "
                  :disabled="(markType=='merge' && comment.length === 0) || loader"
                  @click="this.loader=true;onSubmit(selectedInitiative,comment)"
                >
                <svg v-if="loader" class="animate-spin mt-1 -ml-1 mr-3 h-3 w-3 text-white"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                  </circle>
                  <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                  Update
                </button>
                <button
                  type="button"
                  @click="onCancel"
                  class="
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "InactiveConfirm",
  data() {
    return {
      comment: "",
      loader:false
    };
  },
  props: {
    phaseIndex: Number,
    openInactive: Boolean,
    selectedInitiative: Object,
    phaseIndex: Number,
    markType: String
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  computed: {
    ...mapGetters(["playbook"]),
    nextPhase() {
      let findIndex = this.initiative.allPhase.indexOf(this.initiative.phase);
      return this.initiative.allPhase[findIndex + 1];
    },
    isCommentsEnabled:function(){
      if(this.playbook?.hasOwnProperty("metaData")){ 
        if(this.markType === "active"){
          return this.playbook.metaData.enableActiveComments
        }
        if(this.markType === "inactive"){
          return this.playbook.metaData.enableInactiveComments
        }         
      }
      return true;  
    },
  },
  methods:{
    async onSubmit(selectedInitiative, comment) {
      await this.$emit('inactive', { selectedInitiative, comment})
      this.comment = "";
    },
    async onCancel(){
      await this.$emit('openInactiveModal', false);
      this.comment = "";

    }
  },
  created:function(){
  }
};
</script>
