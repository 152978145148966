import feathersClient from '../services/feathersClient';
import { format } from "fecha";
import { stripHtml } from "string-strip-html";
import { isEmpty, cloneDeep } from 'lodash';
import objectId from "../helpers/objectId";
import slugify from "../helpers/slugify";
import moment from "moment";
const axios = require('axios');

const processNotes = function (notesArray) {

  const processedNotes = [];

  if (notesArray && notesArray.length > 0) {
    notesArray.forEach(function (noteRecord) {
      const noteItem = {
        content: noteRecord.content,
        notedate: noteRecord.notedate,
        _id: noteRecord._id,
        accountUserId: noteRecord.accountUserId,
        uploadedBy: noteRecord.uploadedBy,
        user: {
          name: `${noteRecord.accountUserId.firstName} ${noteRecord.accountUserId.lastName}`,
          profilePic: noteRecord.accountUserId.profilePic
        }
      }

      processedNotes.push(noteItem);

    });
  }

  return processedNotes;
}

const state = {
  initiatives: [],
  pagination: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  },
  selectedInitiative: null,
  selectedInitiativeEvents: [],
  totalActivitiesToComplete: 0,
  jiraProjects: [],
  completedActivities: 0,
  activitiesToUpdate: [],
  ideaHistory: [],
  teamMembers: [],
  usersByDisplayName: [],
  allPendingRequestsToAccept: [],
  msGraphAccessToken: "",
  openActivitiesFlag: false,
  selectedJudgingInitiative: {},
  initiativeDataUpdated: false,
  ideaProcessStatus: {},
  menuClicked: null,
  initiativeMetaData: {},
  attachmentPopUpFlag: false,
  viewAttachmentBase64: "",
  currentRound: 0,
  currentRoundName: "",
  judgingHistoryRounds: [],
  selectedRoundOption: "",
  execSponsorForSelectedSubmission: [],
  enableJudging: true,
  enableSubmission: true,
  totalJudgingRounds:[],
  rankArrayByRevenue:{},
  rankArrayByActivations:{},
  rankArrayByRevenueHistory:{},
  rankArrayByActivationsHistory:{},
  workspaceUsers:[]
}

const mutations = {
  UPDATE_INITIATIVES(state, payload) {
    state.initiatives = [...payload];
  },
  ENABLE_JUDGING(state, payload) {
    state.enableJudging = payload;
  },
  ENABLE_SUBMISSION(state, payload) {
    state.enableSubmission = payload;
  },
  SET_SELECTED_INITIATIVE(state, payload) {
    state.selectedInitiative = Object.assign({}, payload);
  },
  SET_WORKSPACE_USERS(state, payload) {
    state.workspaceUsers = payload;
  },
  UPDATE_SELECTED_INITIATIVE_NEXTPHASEINFO(state, payload) {
    state.selectedInitiative.nextPhaseInfo = Object.assign({}, payload);
  },
  SET_LOGS_FOR_INITIATIVE(state, payload) {
    state.ideaHistory = payload.initativeLogs;
  },
  SET_NOTES_FOR_INITIATIVE(state, payload) {
    state.selectedInitiative.notes = payload;
  },
  UPDATE_INITIATE_FOR_DELETED_ATTACHMENTS(state, payload) {
    state.selectedInitiative.attachments.splice(payload.index, 1);
  },
  SET_INIITIATIVELOGS_FOR_INITIATIVE(state, payload) {
    state.selectedInitiative.initativeLogs = payload;
  },
  SET_EXPORT_LOADING(state, payload) {
    state.exportLoading = payload;
  },
  UPDATE_SELECTED_INITIATIVE_RUNSTATUS(state, payload) {
    const initiativeToUpdate = cloneDeep(state.selectedInitiative);
    const activityToUpdate = initiativeToUpdate.activitiesDataMap.get(payload._id);
    activityToUpdate.runStatus = payload.runStatus;
    if (payload.runStatus == 2) {
      activityToUpdate.isCompleted = true;
      activityToUpdate.isCancelled = false;
    } else if (payload.runStatus == 3) {
      activityToUpdate.isCompleted = false;
      activityToUpdate.isCancelled = true;
    } else {
      activityToUpdate.isCompleted = false;
      activityToUpdate.isCancelled = false;
    }

    let completedCount = 0;
    for (let element of initiativeToUpdate.activitiesDataMap.values()) {
      if (element.hasOwnProperty('runStatus') && element.runStatus == 2) {
        completedCount = completedCount + 1;
      }
    }

    initiativeToUpdate.completed = completedCount;

    state.initiatives.forEach(function (initiative) {
      if (initiative._id == initiativeToUpdate._id) {
        initiative.completed = initiativeToUpdate.completed;
      }
    });

    state.selectedInitiative = initiativeToUpdate;

  },
  RESET_INITIATIVE_SELECTION(state) {
    state.selectedInitiative = null;
  },
  SET_TOTAL_ACTIVITIES_COUNT(state, count) {
    state.totalActivitiesToComplete = count;
  },
  SET_ALL_JIRA_PROJECTS(state, projectList) {
    state.jiraProjects = projectList;
  },

  SET_COMPLETED_ACTIVITIES_COUNT(state, count) {
    state.completedActivities = count;
  },
  SET_PAGINATION_DATA(state, payload) {
    state.pagination.currentPage = payload.currentPage;
    state.pagination.totalPages = payload.totalPages;
    state.pagination.totalRecords = payload.totalRecords;
  },
  SET_INITIATIVE_EVENTS(state, payload) {
    state.selectedInitiativeEvents = payload;
  },
  SET_INITIATIVE_LIKES(state, payload) {
    let initiative = state.initiatives.find(idea => payload.initiativeId == idea.id);
    if (initiative) {
      initiative.ideaLikedBy = payload.accountUserIds;
    }
  },
  SET_USER_FOLLOWS(state, payload) {
    if (payload?.initiativeIds?.length > 0) {
      for (let initiative of state.initiatives) {
        if (payload.initiativeIds.includes(initiative.id)) {
          initiative.ideaFollowedBy.push(payload.accountUserId);
        }
      }
    }
  },
  RESET_ACTIVITIES_TO_UPDATE_LIST(state) {
    state.activitiesToUpdate = []
  },
  ADD_TO_ACTIVITIES_TO_UPDATE_LIST(state, payload) {
    const newActivityList = [...state.activitiesToUpdate];
    newActivityList.push(payload);
    state.activitiesToUpdate = newActivityList;
  },
  REMOVE_FROM_ACTIVITIES_TO_UPDATE_LIST(state, payload) {
    const updatedActivityList = state.activitiesToUpdate.filter(function (activity) {
      return activity.id !== payload.id
    });
    state.activitiesToUpdate = updatedActivityList;
  },
  SET_TEAM_MEMBERS(state, result) {
    state.teamMembers = Object.assign({}, result);
  },
  SET_USERS_BY_DISPLAYNAME(state, result) {
    state.usersByDisplayName = Object.assign({}, result);
  },
  SET_PENDING_REQUESTS(state, result) {
    state.allPendingRequestsToAccept = result;
  },
  SET_MS_GRAPH_ACCESS_TOKEN(state, result) {
    state.msGraphAccessToken = result;
  },
  SET_FLAG_TO_OPEN_ACTIVITIES_TAB(state, result) {
    state.openActivitiesFlag = result;
  },
  SET_JUDGING_INTIATIVE(state, result) {
    state.selectedJudgingInitiative = result;
  },
  SET_JIRA_INITIATIVE_UPDATED(state, payload) {
    state.initiativeDataUpdated = payload
  },
  SET_IDEA_PROCESS_STATUS(state, payload) {
    state.ideaProcessStatus = payload
  },
  UPDATE_MENU_CLICKED(state, payload) {
    state.menuClicked = payload
  },
  INITIATIVE_METADATA(state, payload) {
    state.initiativeMetaData = payload
  },
  PHASE_TEAM_MEMBERS(state, payload) {
    state.phaseTeamMembers = payload
  },
  CURRENT_PHASE_TEAM_MEMBERS(state, payload) {
    state.currentPhaseTeamMembers = payload
  },
  SELECTED_TEAM_MEMBERS_PHASE(state, payload) {
    state.selectedTeamMembersPhase = payload
  },
  UPDATE_INITIATIVES_SELETED_PHASE_JUDGES(state, payload) {
    let initiative = state.initiatives.find(idea => payload.initiativeId == idea._id);
    if (initiative && state.selectedInitiative) {
      if(payload.seletedPhaseJudgesDetails){
        state.selectedInitiative.seletedPhaseJudgesDetails = payload.seletedPhaseJudgesDetails;
      }
      if(payload.phaseInfo){
        state.selectedInitiative.phaseInfo = payload.phaseInfo;
      }
      if(payload.judgingInitiativeData){
        state.selectedInitiative.judgingInitiativeData = payload.judgingInitiativeData;
      }
    }
  },
  UPDATE_JUDGING_INITIATIVE_DETAILS(state, payload) {
    let initiative = state.initiatives.find(idea => payload.initiativeId == idea._id);
    if (initiative) {
      delete payload.initiativeId;
      state.selectedInitiative.judgingInitiativeDetails = payload;
    }
  },
  UPDATE_ATTACHMENT_POPUP_FLAG(state, payload) {
    state.attachmentPopUpFlag = payload;
  },
  VIEW_ATTACHMENT_BASE64(state, payload) {
    state.viewAttachmentBase64 = payload;
  },
  SET_CURRENT_ROUND(state, payload){
    state.currentRound = payload;
  },
  SET_CURRENT_ROUND_NAME(state, payload){
    state.currentRoundName = payload;
  },
  SELECT_JUDGING_HISTORY_ROUND(state, payload){
    state.judgingHistoryRounds = payload;
  },
  SELECTED_ROUND_OPTION(state, payload){
    state.selectedRoundOption = payload;
  },
  SET_EXECSPONSOR_FOR_SELECTED_SUBMISSION(state, payload){
    state.execSponsorForSelectedSubmission = payload;
  },
  TOTAL_JUDGING_ROUNDS(state, payload){
    state.totalJudgingRounds = payload;
  },
  SET_RANK_BY_REVENUE(state, payload){
    state.rankArrayByRevenue = payload;
  },
  SET_RANK_BY_ACTIVATION(state, payload){
    state.rankArrayByActivations = payload;
  },
  SET_RANK_BY_REVENUE_HISTORY(state, payload){
    state.rankArrayByRevenueHistory = payload;
  },
  SET_RANK_BY_ACTIVATION_HISTORY(state, payload){
    state.rankArrayByActivationsHistory = payload;
  },
  SET_PAYLOAD_FOR_PROGRESS_FILTER_QUERY(state, payload){
    state.payloadForProgressFilterQuery = payload;
  },
}

const actions = {
  setUpdateInitiatives(context, payload) {
    context.commit('UPDATE_INITIATIVES', payload);
  },
  totalJudgingRounds(context, payload) {
    context.commit('TOTAL_JUDGING_ROUNDS', payload);
  },
  async checkJudgingEnableStatus(context){
    const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
    if(userRole == "workspaceOwner" || roles.includes("manager")){
      await context.commit('ENABLE_JUDGING', true);
    } else {
      const tokenString = `Bearer ${accessToken}`;
      const playbookService = feathersClient.service("playbooks");
      const baseId = process.env.VUE_APP_BASE_ID;
      const playbookQuery = {
        baseId: baseId,
        status: 4,
        $select: ['metaData']
      };
      var result = await playbookService.find({
        query: playbookQuery,
        headers: {
          "Authorization": tokenString
        },
      });
      if(result?.length>0 && result[0]?.metaData?.enableJudging){
        await context.commit('ENABLE_JUDGING', true);
      } else {
        await context.commit('ENABLE_JUDGING', false);
      }
    }
  },
  async checkSubmissionEnableStatus(context){
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
      await context.commit('ENABLE_SUBMISSION', true);
    } else {
      await context.commit('ENABLE_SUBMISSION', false);
    }
  },
  async readInitiative(context){
    const { accessToken } = context.rootState.user.user;
    const baseId = process.env.VUE_APP_BASE_ID;
    let playbookPhasesData;
    let judgingRounds = [];
    let phase = "";
    let isLastPhase = false;
    const query = {
      $select: ["name", "activities"],
      $and: [
        { status: {"$in": [0, 1]} },
        { baseId: process.env.VUE_APP_BASE_ID },
      ],
      $populate: [
        {
          path: "activities",
          select: "name runStatus",
        },
      ]
    };
    const tokenString = `Bearer ${accessToken}`;
    const initiatives = feathersClient.service("initiatives");
    let initiative = await initiatives.find({
      query: query,
      headers: {
        "Authorization": tokenString
      },
    });
    playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
    if(!playbookPhasesData){
      const playbooks = feathersClient.service("playbooks");
      try {
        const query = {
          // _id: playbookId,
          baseId: baseId,
          status: 4,
          $select: ['name', 'description', 'status', 'activities', 'metaDataSchema', 'createInitiative', 'baseId', 'version', 'createInitiativeEndDate', 'createInitiativeStartDate'],
          $populate: [{
            path: 'activities',
            populate: {
              path: 'activities',
              populate: {
                path: 'activities',
                select: 'name description sequence'
              },
              select: 'name description sequence'
            },
            select: 'name description sequence metaData'
          }, {
            path: 'communities',
            select: 'name communities',
            populate: [{
              path: 'communities',
              select: 'name raci'
            }]
          }]
        };
        const tokenString = `Bearer ${accessToken}`;
        var result = await playbooks.find({
          query: query,
          headers: {
            "Authorization": tokenString
          },
        });
        playbookPhasesData = result[0].activities;
      } catch (err) {
        console.log("Error in fetching playbook ", err);
      }
    }
    let counter = 0;
    for(let phase of playbookPhasesData){
      if(phase?.metaData?.judgingPlaybookBaseId){
        counter++;
        judgingRounds.push({"id": counter, value: phase.name, label: phase.name, baseId: phase.metaData.judgingPlaybookBaseId, count: 0});
      }
    }
    let judgingSetArray = [];
    for(let round of judgingRounds){
      judgingSetArray.push({...round});
    }
    await context.commit('TOTAL_JUDGING_ROUNDS', judgingSetArray);
    await context.commit('SELECT_JUDGING_HISTORY_ROUND', judgingRounds);
    let ideaInPhases = [];
    if(initiative?.data?.length > 0){
      for(let idea of initiative.data){
        for (let i = idea.activities.length - 1; i >= 0; i--) {
          if(idea.activities[i].runStatus == 1){
            ideaInPhases.push(i);
          }
        }
      }
    }
    let uniqueSortedIdeaInPhase = [...new Set(ideaInPhases)].sort()
    let phaseIndex = uniqueSortedIdeaInPhase[0];
    let systemInPhase = playbookPhasesData[phaseIndex];
      let roundFound = false;
      for(let round of judgingRounds){
        if(systemInPhase?.metaData?.judgingPlaybookBaseId && round.baseId == systemInPhase.metaData.judgingPlaybookBaseId){
          context.commit('SET_CURRENT_ROUND', round.id);
          context.commit('SET_CURRENT_ROUND_NAME', round.label);
          getInitiativeRanks(context,systemInPhase?.metaData?.judgingPlaybookBaseId)
          roundFound=true;
        }
      }
      if(!roundFound){
        let round = judgingRounds.length;
        context.commit('SET_CURRENT_ROUND', 0);
        context.commit('SET_CURRENT_ROUND_NAME', 'NA');
      }
    // }
    if(context.state.currentRound == 0){
      context.commit('SELECT_JUDGING_HISTORY_ROUND', []);
    } else {
      context.state.judgingHistoryRounds.splice(context.state.currentRound-1);
    }
  },
  resetSelectedInitiative(context) {
    context.commit('RESET_INITIATIVE_SELECTION');
  },
  setAttachmentPopupFlag(context, payload){
    context.commit('UPDATE_ATTACHMENT_POPUP_FLAG', payload);
  },
  setAttachmentBase64(context, payload){
    context.commit('VIEW_ATTACHMENT_BASE64', payload);
  },
  async getInitiatives(context, payload) {
    const initiativesList = [];
    if(payload.isJudging && context.state.currentRound<1){
      context.commit('UPDATE_INITIATIVES', initiativesList);
      return false;
    }
    const { accessToken, roles, userRole } = context.rootState.user.user;
    context.commit('SET_LOADING_FLAG', true);
    context.commit('UPDATE_INITIATIVES', initiativesList);
    context.commit('UPDATE_MENU_CLICKED', "allIdeas");
    let aspenifyUserId = context.rootState.user.user.aspenifyUserId;
    let organizationId = context.rootState.user.user.organizationId;
    const subOrgService = feathersClient.service("suborganizations");
    let phaseJudgesTeamId = process.env.VUE_APP_PHASE_JUDGES_TEAM_ID
    // const playbookId = process.env.VUE_APP_PRACTICE_ID;
    const baseId = process.env.VUE_APP_BASE_ID;
    let playbookPhasesData;
    playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
    if(!playbookPhasesData){
      const playbooks = feathersClient.service("playbooks");
      try {
        const query = {
          // _id: playbookId,
          baseId: baseId,
          status: 4,
          $select: ['name', 'description', 'status', 'activities', 'metaDataSchema', 'createInitiative', 'baseId', 'version', 'createInitiativeEndDate', 'createInitiativeStartDate'],
          $populate: [{
            path: 'activities',
            populate: {
              path: 'activities',
              populate: {
                path: 'activities',
                select: 'name description sequence'
              },
              select: 'name description sequence'
            },
            select: 'name description sequence metaData'
          }, {
            path: 'communities',
            select: 'name communities',
            populate: [{
              path: 'communities',
              select: 'name raci'
            }]
          }]
        };
        const tokenString = `Bearer ${accessToken}`;
        var result = await playbooks.find({
          query: query,
          headers: {
            "Authorization": tokenString
          },
        });
        playbookPhasesData = result[0].activities;
      } catch (err) {
        console.log("Error in fetching playbook ", err);
      }
    }
    let judgingPlaybookBaseIds = [];
    for(let phase of playbookPhasesData){
      if(phase?.metaData?.judgingPlaybookBaseId){
        judgingPlaybookBaseIds.push(phase.metaData.judgingPlaybookBaseId);
      }
    }
    const initiatives = feathersClient.service("initiatives");
    const likeService = feathersClient.service("initiativeLikes");
    const followService = feathersClient.service("userFollows");
    let initiativeIDs = [];
    const andCondition = [];
    if (payload?.isJudging) {
      andCondition.push({
        "parentInitiativeId": { $exists: true },
        "baseId": { $in: judgingPlaybookBaseIds },
      });
      andCondition.push({"status":{"$in": [0, 1]}})
    } else {
      andCondition.push({
        "parentInitiativeId": { $exists: false },
        "metaData": { $exists: true },
        "baseId": baseId
      });
    }

    //don't select initiatives in Edit mode i.e. 3
    var orCondition = []

    //if condition is for my submission 
    if (payload?.teamLeadName?.length > 0) {
      for (let user of payload.teamLeadName) {
        orCondition.push({
          "metaData.teamLead": { $elemMatch: { email: user.toLowerCase() } },
        });
      }
    }
    if (payload?.teamMemberName?.length > 0) {
      for (let user of payload.teamMemberName) {
        orCondition.push({
          "metaData.teamMembers": { $elemMatch: { email: user.toLowerCase() } },
        });
      }
    }
    if (payload?.teamMentorName?.length > 0) {
      for (let user of payload.teamMentorName) {
        orCondition.push({
          "metaData.mentors": { $elemMatch: { email: user.toLowerCase() } },
        });
      }
    }

    if (payload?.opsPoc?.length > 0) {
      for (let opsPoc of payload.opsPoc) {
        orCondition.push({
          "metaData.managementFields.opsPOC.email": opsPoc.toLowerCase(),
        });
      }
    } if (payload?.pmPoc?.length > 0) {
      for (let pmPoc of payload.pmPoc) {
        orCondition.push({
          "metaData.managementFields.pmPOC.email": pmPoc.toLowerCase(),
        });
      }
    } if (payload?.execSponsors?.length > 0) {
      for (let execSponsors of payload.execSponsors) {
        orCondition.push({
          "metaData.managementFields.execSponsors": { $elemMatch: { email: execSponsors.toLowerCase() } },
        });
      }
    }
    if (payload.externalRefId) {
      orCondition.push(
        {
          "metaData.teamMembers": {
            $elemMatch: { email: { $regex: payload.externalRefId, $options: 'i' } },
          },
        },
        {
          "metaData.mentors": { $elemMatch: { email: { $regex: payload.externalRefId, $options: 'i' } }, },
        },
        {
          "metaData.teamLead": { $elemMatch: { email: { $regex: payload.externalRefId, $options: 'i' } }, },
        },
        {
          "metaData.submitter": { $elemMatch: { email: { $regex: payload.externalRefId, $options: 'i' } }, },
        }
      );
      andCondition.push({
        // "externalRefId": payload.externalRefId,
        "status": { $in: [-1, 0, 1, 2, 3, 5, 6] }

      });
      //for judging, by default only show active ideas and which are review completed
    } else if (payload.isJudging) {
      if(payload.baseId){
        andCondition[0].baseId.$in = [payload.baseId];
        andCondition[1].status.$in = [0, 1];
        andCondition[0]['metaData.reviews.lastUpdatedBy'] = {"$ne":aspenifyUserId};
      } else if(payload.roundBaseId){
        andCondition[0].baseId.$in = [payload.roundBaseId];
        andCondition[1].status.$in = [2, 6, 7];
        getInitiativeRanks(context,payload.roundBaseId,true)
      } else if(payload.status == 'completed'){
        andCondition[0]['metaData.reviews.lastUpdatedBy'] = aspenifyUserId;
        andCondition[0]["createdAt"] = {
          $gte: new Date(new Date().setDate(new Date().getDate() - 7)),
          $lt: new Date()
        }
      } else if(payload.status == 'allcompleted'){
        let filteredCurrentRound = context.state.totalJudgingRounds.filter(round=>round.id==context.state.currentRound);
        let baseId = filteredCurrentRound[0].baseId;
        if(baseId){
          andCondition[0].baseId.$in = [baseId];
        }
        delete andCondition[1].status;
        andCondition[1].$or = [];
        andCondition[1].$or.push({"status": 0,'metaData.reviews.lastUpdatedBy' : aspenifyUserId});
        andCondition[1].$or.push({"status": 1, 'metaData.reviews.lastUpdatedBy' : aspenifyUserId});
        andCondition[1].$or.push({"status": 2});
        andCondition[1].$or.push({"status": 6});
        andCondition[1].$or.push({"status": 7});
        // andCondition[0]['metaData.reviews.lastUpdatedBy'] = aspenifyUserId;
      } else {
        andCondition[0]['metaData.reviews.lastUpdatedBy'] = {"$ne":aspenifyUserId};
      }
    } else {
      // select only initiative those are active and in-design
      if (isEmpty(payload.searchQuery.status)) {
        // if workspace owner, show draft ideas also
        if (context.rootState.user.user?.userRole === "workspaceOwner") {
          andCondition.push({
            "status": { $in: [-1, 0, 1, 2, 3, 5, 6, 7] }
          });
        } else {
          andCondition.push({
            "status": { $in: [0, 1, 2, 3, 5, 6, 7] }
          });
        }
      }
      else {
        andCondition.push({
          "status": { $in: payload.searchQuery.status }
        });
      }
    }

    // let searchQuery= payload.searchQuery;
    //remove status from searchquery if merge present, as we already pass status in OR CONDITION
    // if(searchQuery?.status?.includes(10)){
    //   delete searchQuery.status;
    // }


    const numberOfRecordsToSkip = (payload.currentPage - 1) * process.env.VUE_APP_LIST_PAGE_LIMIT
    const query = {
      $and: andCondition,
      $or: orCondition,
      $limit: process.env.VUE_APP_LIST_PAGE_LIMIT,
      $skip: numberOfRecordsToSkip,
      $sort: {
        createdAt: -1,
      },
      $select: [
        "name",
        "description",
        "status",
        "activities",
        "picture",
        "externalRefId",
        "createdAt",
        "updatedAt",
        "attachments",
        "metaData",
        "priority",
        "parentInitiativeId",
        "subInitiatives",
        "createdBy",
        "teamId"
      ],
      $populate: [
        {
          path: "activities",
          populate: {
            path: "activities",
            populate: { path: "activities ", select: "name runStatus" },
            select: "name runStatus activities metaData raci",
            populate: {
              select: "_id name",
              path: "raci.a.teamId",
            }
          },
          select: "name runStatus activities raci",
        },
        {
          path: "parentInitiativeId",
          select: "_id name metaData status activities description",
          populate: {
            path: "activities",
            populate: { path: "activities ", select: "name runStatus" },
          },

        },
        {
          path: "subInitiatives",
          select: "_id name metaData status activities",
          populate: {
            path: "activities",
            populate: { path: "activities ", select: "name runStatus" },
          },

        }

      ],
    };

    if (payload.sortBy) {
      query.$sort = payload.sortBy
    } else {
      query.$sort = { createdAt: -1 }
    }
    if (!isEmpty(payload.searchQuery)) {
      andCondition.push(payload.searchQuery)
    }
    if (payload?.isJudging) {
      query.isJudging = true;
      query.phaseJudgesTeamId = phaseJudgesTeamId;
    }
    try {
      const tokenString = `Bearer ${accessToken}`;
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      // if(payload.status == 'allcompleted'){
      //   let updatedResult = result.data.filter((initiative)=>{
      //     if(initiative.status===2){
      //       return true
      //     } else if(initiative?.metaData?.reviews?.length>0){
      //       let reviewed = initiative?.metaData?.reviews?.filter(review=>{
      //         if (review.lastUpdatedBy==aspenifyUserId){
      //           return true
      //         }
      //       })
      //       if(reviewed?.length>0) return true        
      //     }
      //     return false
      //   })
      //   result.data = updatedResult
      //   result.total=updatedResult.length
      // }
      const totalRecords = result.total;
      const totalPages = result.total % Number(process.env.VUE_APP_LIST_PAGE_LIMIT) == 0 ?
        parseInt(result.total / Number(process.env.VUE_APP_LIST_PAGE_LIMIT)) :
        parseInt(result.total / Number(process.env.VUE_APP_LIST_PAGE_LIMIT)) + 1;
      //parseInt(result.total / process.env.VUE_APP_LIST_PAGE_LIMIT) + 1;

      context.commit('SET_PAGINATION_DATA', {
        currentPage: payload.currentPage ? payload.currentPage : 1,
        totalPages,
        totalRecords
      });

      if (result.data && result.data.length > 0) {

        // result.data.forEach(async function (initiative) {
        for (let initiative of result.data) {
          let users = [];
          let ideaLikedBy = [];
          let ideaFollowedBy = [];
          if (initiative.metaData && initiative.metaData.teamMembers) {
            initiative.metaData.teamMembers.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
              let name;
              if (member.name.includes("(")) {
                let memberName = member.name.split("(");
                let userName = memberName[1].split(")");
                name = memberName[0] + " (" + userName[0].trim() + ")";
              } else {
                name = member.name
              }
              const user = {
                name: name,
                image: member.image,
                role: "Team Member",
                email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
              };
              if (member.accountUserId) {
                user.accountUserId = member.accountUserId;
              }
              users.push(user);
            });
          }
          //submitter 
          if (initiative.metaData && initiative.metaData.submitter) {
            initiative.metaData.submitter.forEach(function (member) {
              const submitter = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(submitter){
                  member.firstName = submitter?.firstName;
                  member.lastName = submitter?.lastName;
                  member.email = submitter?.email;
                  member.image = submitter.profilePic;
                  member.name = submitter?.name;
                }
              let name;
              if (member.name.includes("(")) {
                let memberName = member?.name?.split("(");
                let userName = memberName[1]?.split(")");
                name = memberName[0] + " (" + userName[0]?.trim() + ")";
              } else {
                name = member.name
              }
              const user = {
                name: name,
                image: member.image,
                role: "Submitter",
                email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
              };
              if (member.accountUserId) {
                user.accountUserId = member.accountUserId;
              }
              users.push(user);
            });
          }
          if (initiative.metaData && initiative.metaData.mentors) {
            initiative.metaData.mentors.forEach(function (member) {
              const mentor = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(mentor){
                  member.firstName = mentor?.firstName;
                  member.lastName = mentor?.lastName;
                  member.email = mentor?.email;
                  member.image = mentor.profilePic;
                  member.name = mentor?.name;
                }
              let name;
              if (member.name.includes("(")) {
                let memberName = member.name.split("(");
                let userName = memberName[1].split(")");
                name = memberName[0] + " (" + userName[0].trim() + ")";
              } else {
                name = member.name
              }
              const user = {
                name: name,
                image: member.image,
                role: "Mentor",
                email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
              };
              if (member.accountUserId) {
                user.accountUserId = member.accountUserId;
              }
              users.push(user);
            });
          }
          if (result.data[0].metaData && result.data[0].metaData.teamLead) {
            result.data[0].metaData.teamLead.forEach(function (lead) {
              // if (lead && users && users.length !== 0 && users[0].name && lead.name == users[0].name) {
              //   users[0].role = "Submitter | Team Lead"
              // } else {
              let name;
              if (lead.name.includes("(")) {
                let memberName = lead.name.split("(");
                let userName = memberName[1].split(")");
                name = memberName[0] + " (" + userName[0].trim() + ")";
              } else {
                name = lead.name
              }
              const user = {
                name: name,
                image: lead.image,
                role: "Team Lead",
                email: lead.email?.toLowerCase() ? lead.email?.toLowerCase() : ""
              };
              if (lead.accountUserId) {
                user.accountUserId = lead.accountUserId;
              }
              users.splice(1, 0, user);
              // }
            });
          }
          if (initiative.metaData && initiative.metaData.teamLead) {
            initiative.metaData.teamLead.forEach(function (lead) {
              const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
                if(teamLead){
                  lead.firstName = teamLead?.firstName;
                  lead.lastName = teamLead?.lastName;
                  lead.email = teamLead?.email;
                  lead.image = teamLead.profilePic;
                  lead.name = teamLead?.name;
                }
            });
          }

          let phase = "";
          let idea = payload.isJudging ? initiative?.parentInitiativeId : initiative
          switch (idea.status) {
            case 1:
              idea?.activities.every(function (phaseActivity) {
                if (phaseActivity.runStatus === 1) {

                  phase = phaseActivity.name;
                  return false; //break from the loop
                }
                return true;
              });
              break;
            case 2:
              idea?.activities.every(function (phaseActivity) {
                if (phaseActivity.runStatus === 1) {

                  phase = phaseActivity.name;
                  return false; //break from the loop
                }
                return true;
              });
              break;
            case 6:
              idea?.activities.every(function (phaseActivity) {
                if (phaseActivity.runStatus === 1) {

                  phase = phaseActivity.name;
                  return false; //break from the loop
                }
                return true;
              });
              break;
            default:
            // code block
          }
          if(initiative?.parentInitiativeId?.activities?.length>0 && initiative.teamId){
            for(let activity of initiative.parentInitiativeId.activities){
              if(activity?.metaData?.selectedJudgesTeamId && activity.metaData.selectedJudgesTeamId == initiative.teamId){
                phase = activity.name;
              }
            }
          }

          let statusLabel = ""
          switch (initiative.status) {
            case -1:
              statusLabel = "Draft";
              break;
            case 0:
              statusLabel = "Submitted";
              break;
            case 1:
              statusLabel = "Active";
              break;
            case 2:
              statusLabel = "Complete";
              break;
            case 3:
              statusLabel = "Closed";
              break;
            case 5:
              statusLabel = "Archived";
              break;
            case 6:
              statusLabel = "Inactive";
              break
            case 7:
              statusLabel = "Merged";
              break;
            default:
            // code block
          }
          initiativeIDs.push(initiative._id);

          if (Array.isArray(initiative.metaData?.challengeType) && initiative.metaData?.challengeType.length > 0) {
            initiative.metaData.challengeType = initiative.metaData.challengeType[0];
          }
          if(initiative?.parentInitiativeId){
            if (initiative?.parentInitiativeId?.metaData && initiative?.parentInitiativeId?.metaData?.teamLead) {
              initiative?.parentInitiativeId.metaData.teamLead.forEach(function (lead) {
                const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
                  if(teamLead){
                    lead.firstName = teamLead?.firstName;
                    lead.lastName = teamLead?.lastName;
                    lead.email = teamLead?.email;
                    lead.image = teamLead.profilePic;
                    lead.name = teamLead?.name;
                  }
              });
            }
            if (initiative?.parentInitiativeId?.metaData && initiative?.parentInitiativeId?.metaData?.teamMembers) {
              initiative?.parentInitiativeId.metaData.teamMembers.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
            if (initiative?.parentInitiativeId?.metaData && initiative?.parentInitiativeId?.metaData?.submitter) {
              initiative?.parentInitiativeId.metaData.submitter.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
            if (initiative?.parentInitiativeId.metaData && initiative?.parentInitiativeId?.metaData?.mentors) {
              initiative?.parentInitiativeId?.metaData?.mentors.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
          }
          //create total users   
          //pass whole result 
          initiativesList.push({
            id: initiative._id,
            name: initiative.name,
            metaData: initiative.metaData,
            status: initiative.status,
            createdBy: initiative.createdBy,
            teamId: initiative.teamId,
            statusLabel: statusLabel,
            description: initiative.description ? initiative.description : "",
            phase: phase,
            date: initiative.createdAt ? format(new Date(initiative.createdAt), "mediumDate") : "",
            createdAt: initiative.createdAt ? format(new Date(initiative.createdAt), "mediumDate") : "",
            updatedAt: initiative.updatedAt ? format(new Date(initiative.updatedAt), "mediumDate") : "",
            type: initiative.metaData?.challengeType,
            image: initiative.picture,
            tags: initiative.tags,
            attachments: initiative.attachments,
            initativeLogs: initiative.initativeLogs,
            users: users,
            teamMembers: initiative.metaData?.teamMembers,
            mentors: initiative.metaData?.mentors,
            teamLead: initiative.metaData?.teamLead,
            challengeType: initiative.metaData?.challengeType,
            marketHorizon: initiative.metaData?.marketHorizon,
            bogFramework: initiative.metaData?.bogFramework,
            aboutTeam: initiative.metaData?.aboutTeam,
            testimonialAwardsIpAndOpenSource: initiative.metaData?.testimonialAwardsIpAndOpenSource,
            talent: initiative.metaData?.talent,
            isTalentRequired: initiative.metaData?.isTalentRequired,
            progress: initiative.metaData?.progress,
            source: initiative.metaData?.source,
            priority: initiative.priority,
            activities: initiative.activities,
            parentInitiativeId: initiative.parentInitiativeId,
            subInitiatives: initiative.subInitiatives,
            ideaLikedBy: ideaLikedBy,
            ideaFollowedBy: ideaFollowedBy
          });
        };
      }
      context.commit('UPDATE_INITIATIVES', initiativesList);
      context.commit('SET_LOADING_FLAG', false);
      let likedData = await likeService.find({
        "query": {
          "initiativeId": { "$in": initiativeIDs },
          "accountId": context.rootState.user.user.accountId,
          "organizationId": context.rootState.user.user.organizationId,
          "$select": ["accountUserIds", "initiativeId"],
          "$populate": [{ "path": "accountUserIds", "model": "accountUsers", "select": "firstName lastName email" }]
        }
      })
      let followData = await followService.find({
        "query": {
          "initiativeIds": { "$in": initiativeIDs },
          "accountId": context.rootState.user.user.accountId,
          "organizationId": context.rootState.user.user.organizationId,
          "$select": ["initiativeIds", "accountUserId"],
          "$populate": [{ "path": "accountUserId", "model": "accountUsers", "select": "firstName lastName email" }]
        }
      });
      for (let like of likedData.data) {
        context.commit('SET_INITIATIVE_LIKES', like);
      }
      for (let follow of followData.data) {
        context.commit('SET_USER_FOLLOWS', follow);
      }
    } catch (err) {
      context.commit('SET_LOADING_FLAG', false);
      console.log(err);
    }

  },
  async getInitiativesByBaseId(context, payload) {
    const { accessToken, roles, userRole } = context.rootState.user.user;
    let aspenifyUserId = context.rootState.user.user.aspenifyUserId;
    let organizationId = context.rootState.user.user.organizationId;
    let phaseJudgesTeamId = process.env.VUE_APP_PHASE_JUDGES_TEAM_ID;
    const initiatives = feathersClient.service("initiatives");
    try {
      let query = {
        "$and": [{
            "parentInitiativeId": {
              "$exists": true
            },
            "baseId": {
              "$in": payload.baseIds
            }
          },
          {
            "status": {"$in": [2, 6, 7]}
          }
        ],
        "$select": ["name", "baseId"],
        "isJudging": true,
        "phaseJudgesTeamId": phaseJudgesTeamId
      }
      const tokenString = `Bearer ${accessToken}`;
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      return result;
    } catch (err) {
      console.log(err);
    }
  },
  getAllInitiatives(context, payload) {
    context.dispatch('getInitiatives', payload)
  },
  async getMyInitiatives(context, payload) {
    payload.externalRefId = context.rootState.user.user.email?.toLowerCase();
   await context.dispatch('getInitiatives', payload);
  },
  setSelectedInitiative(context, initiative) {
    context.commit('SET_SELECTED_INITIATIVE', initiative);
  },
  setPayloadForProgressFilterQuery(context, payload) {
    context.commit('SET_PAYLOAD_FOR_PROGRESS_FILTER_QUERY', payload);
  },
  setExecSponsorForSelectedSubmission(context, payload) {
    context.commit('SET_EXECSPONSOR_FOR_SELECTED_SUBMISSION', payload);
  },
  async createInitiative(context, initiativeData) {
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
      let dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 1/6",
        "text": 'Creating team for idea',
        "type": 'warning',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
      let usersArray = initiativeData.metaData.teamLead
        .concat(initiativeData.metaData.teamMembers)
        .concat(initiativeData.metaData.submitter)
        .concat(initiativeData.metaData.mentors);
      if (initiativeData.createdBy) {
        usersArray.push(initiativeData.createdBy);
      }
      let uniqueUsersArray = [...new Map(usersArray.map(user => [user['email'], user])).values()];
      const { accessToken, email } = context.rootState.user.user;
      const initiatives = feathersClient.service("initiatives/onbehalf");
      const playbooks = feathersClient.service("playbooks");
      const subOrgService = feathersClient.service("suborganizations");
      const addUsersService = feathersClient.service('/accountusers/apiusers');
      const baseId = process.env.VUE_APP_BASE_ID;
      const query = {
        baseId: baseId,
        status: 4,
      };
      const tokenString = `Bearer ${accessToken}`;
      var playbookresult = await playbooks.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      let ideaId = objectId() //change variable name
      let teamObj = {
        name: 'Team ' + ideaId,
        summary: 'Automatic team created for initiative',
        organizationId: context.rootState.user.user.organizationId,
        parentOrganizationId: process.env.VUE_APP_IDEA_TEAM_ID,
        active: true,
        accountId: context.rootState.user.user.accountId,
        createdBy: context.rootState.user.user.aspenifyUserId
      }
      teamObj.slug = slugify(teamObj.name)
      let createdTeam = await subOrgService.create(teamObj, { user: context.rootState.user.user });
      dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 2/6",
        "text": 'Team created successfully',
        "type": 'warning',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
      for (let userObj of uniqueUsersArray) {
        userObj.firstName = userObj.name.split(' ').slice(0, -1)[0];
        userObj.lastName = userObj.name.split(' ').slice(0, -1)[1];
        userObj.accountId = context.rootState.user.user.accountId;
        userObj.organizationId = context.rootState.user.user.organizationId;
        userObj.teamId = createdTeam._id;
        if (userObj.role == "TeamLead") {
          userObj.userRole = 'Manager'
        }
      }
      dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 3/6",
        "text": 'Creating and adding users to team',
        "type": 'warning',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
      let addedUsers = await addUsersService.create(uniqueUsersArray, {
        headers: {
          "Authorization": tokenString
        },
      }, {
        user: context.rootState.user.user
      });
      dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 4/6",
        "text": 'Users created and added to team successfully',
        "type": 'warning',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
      // let proposerData = {};
      for (let userData of addedUsers) {
        initiativeData.metaData.teamLead.map((tl) => {
          if (userData.email?.toLowerCase() == tl.email?.toLowerCase()) {
            tl.accountUserId = userData._id;
            if (userData.profilePic) {
              tl.image = userData.profilePic;
            }
          }
          delete tl.firstName;
          delete tl.lastName;
          delete tl.accountId;
          delete tl.organizationId;
          delete tl.teamId;
        })
        initiativeData.metaData.teamMembers.map((member) => {
          if (userData.email?.toLowerCase() == member.email?.toLowerCase()) {
            member.accountUserId = userData._id;
            if (userData.profilePic) {
              member.image = userData.profilePic;
            }
          }
          delete member.firstName;
          delete member.lastName;
          delete member.accountId;
          delete member.organizationId;
          delete member.teamId;
        })
        initiativeData.metaData.mentors.map((mentor) => {
          if (userData.email?.toLowerCase() == mentor.email?.toLowerCase()) {
            mentor.accountUserId = userData._id;
            if (userData.profilePic) {
              mentor.image = userData.profilePic;
            }
          }
          delete mentor.firstName;
          delete mentor.lastName;
          delete mentor.accountId;
          delete mentor.organizationId;
          delete mentor.teamId;
        })
        initiativeData.metaData.submitter.map((proposer) => {
          if (userData.email?.toLowerCase() == proposer.email?.toLowerCase()) {
            proposer.accountUserId = userData._id;
            if (userData.profilePic) {
              proposer.image = userData.profilePic;
            }
          }
          delete proposer.firstName;
          delete proposer.lastName;
          delete proposer.accountId;
          delete proposer.organizationId;
          delete proposer.teamId;
        })
        // if (initiativeData.createdBy && (initiativeData.createdBy.email?.toLowerCase() == userData.email?.toLowerCase())) {
        //   initiativeData.createdBy = userData._id;
        //   if (userData) {
        //     proposerData = {
        //       "name": userData.firstName + " " + userData.lastName,
        //       "email": userData.email?.toLowerCase(),
        //       "image": userData.profilePic,
        //       "role": "Proposer",
        //       "accountUserId": userData._id
        //     };
        //   }
        // }
      }
      // if (Object.keys(proposerData).length > 0) {
      //   initiativeData.metaData.teamMembers.unshift(proposerData);
      // }
      let payload = {
        _id: ideaId,
        name: initiativeData.name,
        description: initiativeData.description,
        tags: initiativeData.tags,
        status: initiativeData.status,
        slug: slugify(initiativeData.name),
        picture: initiativeData.picture,
        practiceId: playbookresult[0]._id,
        isSequential: false,
        workingDays: [false, true, true, true, true, true, false],
        externalRefId: email?.toLowerCase(),
        metaData: initiativeData.metaData,
        attachments: initiativeData.attachments,
        comment: "Submission created",
        apiKey: process.env.VUE_APP_API_KEY,
        teamId: createdTeam._id,
        createdBy: initiativeData.createdBy,
        teamRoleMapping: [
          { "Submitter": createdTeam._id },
          { "Submission POC / Team Lead": createdTeam._id },
          { "Team Member": createdTeam._id },
          { "Program Lead": process.env.VUE_APP_POWERUSER_TEAM_ID },
          { "Executive - Judge": process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID },
          { "Executive - General": process.env.VUE_APP_EXECUTIVE_TEAM_ID },
          { "General User - Employee": createdTeam._id },
          { "General User - Contractor": createdTeam._id }
        ]
      }

      if (initiativeData.phase >= 0) {
        payload.phase = initiativeData.phase
      }
      dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 5/6",
        "text": 'Creating Submission',
        "type": 'warning',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);

      try {
        if (payload.status == -1) {
          context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', "Submission " + initiativeData.name + " saving as a draft");
        } else {
          context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', "Submission " + initiativeData.name + " is being created");
        }
        await initiatives.create(payload, {
          headers: {
            "Authorization": tokenString
          },
        });
      } catch (err) {
        console.log(err);
      }
      dataPayload = {
        "header": initiativeData.name,
        "subheader": "Step 6/6",
        "text": 'Submission created successfully',
        "type": 'success',
        "idea": initiativeData
      }
      context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
      context.dispatch('getMyInitiatives', { currentPage: 1 });
      context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', false);
      setTimeout(() => {
        context.commit('SET_IDEA_PROCESS_STATUS', {});
      }, 3000);
    } else {
      alert("Submissions are turned off");
    }
  },
  async createImportInitiative(context, initiativeData) {
    let usersArray = initiativeData.metaData.teamLead
      .concat(initiativeData.metaData.teamMembers)
      .concat(initiativeData.metaData.mentors)
      .concat(initiativeData.metaData.submitter)
    if (initiativeData.createdBy) {
      usersArray.push(initiativeData.createdBy);
    }
    let uniqueUsersArray = [...new Map(usersArray.map(user => [user['email'], user])).values()];
    const { accessToken, email } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");
    const playbooks = feathersClient.service("playbooks");
    const subOrgService = feathersClient.service("suborganizations");
    const addUsersService = feathersClient.service('/accountusers/apiusers');
    const baseId = process.env.VUE_APP_BASE_ID;
    const query = {
      baseId: baseId,
      status: 4,
    };
    const tokenString = `Bearer ${accessToken}`;
    var playbookresult = await playbooks.find({
      query: query,
      headers: {
        "Authorization": tokenString
      },
    });
    let ideaId = objectId() //change variable name
    let teamObj = {
      name: 'Team ' + ideaId,
      summary: 'Automatic team created for initiative',
      organizationId: context.rootState.user.user.organizationId,
      parentOrganizationId: process.env.VUE_APP_IDEA_TEAM_ID,
      active: true,
      accountId: context.rootState.user.user.accountId,
      createdBy: context.rootState.user.user.aspenifyUserId
    }
    teamObj.slug = slugify(teamObj.name)
    let createdTeam = await subOrgService.create(teamObj, { user: context.rootState.user.user });
    for (let userObj of uniqueUsersArray) {
      userObj.firstName = userObj.name.split(' ').slice(0, -1)[0];
      userObj.lastName = userObj.name.split(' ').slice(0,-1)[1];
      userObj.accountId = context.rootState.user.user.accountId;
      userObj.organizationId = context.rootState.user.user.organizationId;
      userObj.teamId = createdTeam._id;
      if (userObj.role == "TeamLead") {
        userObj.userRole = 'Manager'
      }
    }
    let addedUsers = await addUsersService.create(uniqueUsersArray, {
      headers: {
        "Authorization": tokenString
      },
    }, {
      user: context.rootState.user.user
    });
    // let proposerData = {};
    for (let userData of addedUsers) {
      initiativeData.metaData.teamLead.map((tl) => {
        if (userData.email?.toLowerCase() == tl.email?.toLowerCase()) {
          tl.accountUserId = userData._id;
          if (userData.profilePic) {
            tl.image = userData.profilePic;
          }
        }
        delete tl.firstName;
        delete tl.lastName;
        delete tl.accountId;
        delete tl.organizationId;
        delete tl.teamId;
      })
      initiativeData.metaData.teamMembers.map((member) => {
        if (userData.email?.toLowerCase() == member.email?.toLowerCase()) {
          member.accountUserId = userData._id;
          if (userData.profilePic) {
            member.image = userData.profilePic;
          }
        }
        delete member.firstName;
        delete member.lastName;
        delete member.accountId;
        delete member.organizationId;
        delete member.teamId;
      })
      initiativeData.metaData.mentors.map((mentor) => {
        if (userData.email?.toLowerCase() == mentor.email?.toLowerCase()) {
          mentor.accountUserId = userData._id;
          if (userData.profilePic) {
            mentor.image = userData.profilePic;
          }
        }
        delete mentor.firstName;
        delete mentor.lastName;
        delete mentor.accountId;
        delete mentor.organizationId;
        delete mentor.teamId;
      })
      initiativeData.metaData.submitter.map((proposer) => {
        if (userData.email?.toLowerCase() == proposer.email?.toLowerCase()) {
          proposer.accountUserId = userData._id;
          if (userData.profilePic) {
            proposer.image = userData.profilePic;
          }
        }
        delete proposer.firstName;
        delete proposer.lastName;
        delete proposer.accountId;
        delete proposer.organizationId;
        delete proposer.teamId;
      })
      // if (initiativeData.createdBy && (initiativeData.createdBy.email?.toLowerCase() == userData.email?.toLowerCase())) {
      //   initiativeData.createdBy = userData._id;
      //   if (userData) {
      //     proposerData = {
      //       "name": userData.firstName + " " + userData.lastName,
      //       "email": userData.email?.toLowerCase(),
      //       "image": userData.profilePic,
      //       "role": "Submitter",
      //       "accountUserId": userData._id
      //     };
      //   }
      // }
    }
    // if (Object.keys(proposerData).length > 0) {
    //   initiativeData.metaData.teamMembers.unshift(proposerData);
    // }
    let payload = {
      _id: ideaId,
      name: initiativeData.name,
      description: initiativeData.description,
      tags: initiativeData.tags,
      status: initiativeData.status,
      slug: initiativeData.name.toLowerCase(),
      picture: initiativeData.picture,
      practiceId: playbookresult[0]._id,
      isSequential: false,
      workingDays: [false, true, true, true, true, true, false],
      externalRefId: email?.toLowerCase(),
      metaData: initiativeData.metaData,
      attachments: initiativeData.attachments,
      comment: "Submission created",
      apiKey: process.env.VUE_APP_API_KEY,
      teamId: createdTeam._id,
      createdBy: initiativeData.createdBy,
      teamRoleMapping: [
        { "Submitter": createdTeam._id },
        { "Submission POC / Team Lead": createdTeam._id },
        { "Team Member": createdTeam._id },
        { "Program Lead": process.env.VUE_APP_POWERUSER_TEAM_ID },
        { "Executive - Judge": process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID },
        { "Executive - General": process.env.VUE_APP_EXECUTIVE_TEAM_ID },
        { "General User - Employee": createdTeam._id },
        { "General User - Contractor": createdTeam._id },
      ]
    }
    if (initiativeData.phase >= 0) {
      payload.phase = initiativeData.phase
    }
    if (initiativeData.createdAt) {
      payload.createdAt = initiativeData.createdAt
    }
    if (initiativeData.updatedAt) {
      payload.updatedAt = initiativeData.updatedAt
    }
    if (initiativeData.ideaStatus) {
      payload.ideaStatus = initiativeData.ideaStatus
    }
    try {
      await initiatives.create(payload, {
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getInitiativesByPhase(context, payload) {
    context.commit('SET_LOADING_FLAG', true);
    context.commit('UPDATE_INITIATIVES', []);
    context.commit('UPDATE_MENU_CLICKED', "progress");
    // const playbookId = process.env.VUE_APP_PRACTICE_ID;
    const baseId = process.env.VUE_APP_BASE_ID;

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const phaseNames = context.rootState.playbook.phaseNames;
    const playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
    let playbookPhases = phaseNames.map((phase) => {
      return phase.label;
    })
    let andCondition = [];
    if (isEmpty(payload?.searchQuery?.status)) {
      andCondition.push({
        "status": { $in: [1, 6] }
      });
    }
    else {
      andCondition.push({
        "status": { $in: payload.searchQuery.status }
      });
    }
    andCondition.push({
      'baseId': baseId
    })
    if (!isEmpty(payload?.searchQuery)) {
      andCondition.push(payload.searchQuery)
    }
    // if(andCondition?.length>0){
    //   for(let parameter of andCondition){
    //     if(parameter?.status?.$in?.length>0 && parameter.status.$in.includes(1)){
    //       parameter.status.$in.push(2);
    //     }
    //   }      
    // }
    const query = {
      $and: andCondition,
      $select: ['_id', 'name', 'status', 'description', 'metaData', 'activities', 'subInitiatives', 'createdAt', 'createdBy', 'picture', 'raci', 'teamId'],
      $sort: { "_id": -1 },
      $populate: [{
        path: 'activities',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            select: 'name runStatus duration integration actualStartDate'
          },
          select: 'name runStatus duration activities integration actualStartDate'
        },
        select: 'name runStatus duration activities integration actualStartDate metaData'
      },
      {
        path: "subInitiatives",
        select: ' name activities metaData status raci teamId',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name runStatus'
            },
            populate: {
              path: 'raci.a.teamId',
              select: 'name '
            },
            select: 'name metaData',
          },
          select: 'name metaData raci',
        },
      },
        // {
        //   path: 'subInitiatives', 
        //    select: ' name activities metaData status',
        //   populate: {
        //     path: 'activities',
        //     populate: { 
        //    path: 'activities metaData', select: 'name metaData' 
        //  },
        //     select: 'name metaData '
        //   }
        // }
      ]
    };

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      // if (context.state.menuClicked == "progress") {
      //   context.commit('UPDATE_INITIATIVES', result.data);
      // }
      if (result.data && result.data.length > 0) {

        let allPhase = []
        result.data[0].activities.forEach(function (item, i) {
          allPhase.push(item.name)
        });
        let subOrgDataMap = new Map();
        const subOrgService = feathersClient.service("suborganizations");
        try {
          let subOrgQuery = {
            "parentOrganizationId" : process.env.VUE_APP_PHASE_JUDGES_TEAM_ID,
            "$select": ['name', '_id', 'members'],
            "$populate": [
              {
                "path": "members.accountUserId",
                "select": "firstName lastName email profilePic "
              }
            ]
          }
          let subOrgData = await subOrgService.find({
            query: subOrgQuery,
            headers: {
              "Authorization": tokenString
            },
          });
          if(subOrgData?.data?.length>0){
            for(let subOrg of subOrgData.data){
              subOrgDataMap.set(subOrg._id, subOrg);
            }
          }
        } catch(error){
          console.log("Error in fetching team data for selectedJudgesTeamId ",error)
        }
        for(let initiative of result.data){
        // result.data.forEach(async function (initiative) {
          if (initiative.metaData && initiative.metaData.teamLead) {
            initiative.metaData.teamLead.forEach(function (lead) {
              const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
                if(teamLead){
                  lead.firstName = teamLead?.firstName;
                  lead.lastName = teamLead?.lastName;
                  lead.email = teamLead?.email;
                  lead.image = teamLead.profilePic;
                  lead.name = teamLead?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.teamMembers) {
            initiative.metaData.teamMembers.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.submitter) {
            initiative.metaData.submitter.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.mentors) {
            initiative.metaData.mentors.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
          let lastPhaseId = initiative.activities.slice(-1)[0]._id;
          initiative.activities.every(function (phaseActivity, phaseIndex) {
            let runStatuses = [-1, 0, 1, 6];
            if (lastPhaseId == phaseActivity._id) {
              runStatuses = [-1, 0, 1, 2, 6];
            }
            //find first phase which is n progress 
            //Waiting/In-Queue = -1,Not started = 0  In Progress / Active = 1,  Completed  = 2, Cancelled = 3
            if (runStatuses.includes(phaseActivity.runStatus)) {
              // if(phaseActivity?.metaData?.selectedJudgesTeamId){
              //   initiative.phaseSelectedJudgesTeamId = phaseActivity.metaData.selectedJudgesTeamId;
              // }
              if(playbookPhasesData?.length>0){
                let currentActivity = playbookPhasesData[phaseIndex];
                if(currentActivity?.metaData?.judgingPlaybookBaseId){
                  initiative.judgingPlaybookBaseId = currentActivity.metaData.judgingPlaybookBaseId;
                }
              }
              initiative.phase = playbookPhases[phaseIndex];
              initiative.allPhase = allPhase;
              initiative.phaseIndex = phaseIndex;
              initiative.totalTasks = 0;
              initiative.completed = 0;
              initiative.totalDuration = 0;
              initiative.completedDuration = 0;
              phaseActivity.activities.forEach(function (stageActvity) {
                if (stageActvity?.integration && Object.keys(stageActvity?.integration).length > 0) {
                  initiative.isSmartStage = true
                }
                // else{
                //   initiative.isSmartStage= false
                // }
                stageActvity.activities.forEach(function (activity) {
                  initiative.totalTasks++;
                  initiative.totalDuration += activity.duration
                  if (activity.runStatus == 2) {
                    initiative.completed++;
                    initiative.completedDuration += activity.duration
                  }
                })
              })
              return false; //break from the loop
            }
            return true;
          });
          if(initiative?.activities[initiative?.phaseIndex+1]){
            let nextPhaseInfo = initiative.activities[initiative.phaseIndex+1];
            initiative.nextPhaseInfo = nextPhaseInfo;
          }
          if(initiative?.activities[initiative?.phaseIndex]){
            let phaseInfo = initiative.activities[initiative.phaseIndex];
            initiative.phaseInfo = phaseInfo;
            if(phaseInfo?.metaData?.isJudgingRequired && phaseInfo?.metaData?.selectedJudgesTeamId){
              let selectedJudgesTeamId = phaseInfo.metaData.selectedJudgesTeamId;
              if(subOrgDataMap){
                initiative.seletedPhaseJudgesDetails = subOrgDataMap.get(selectedJudgesTeamId);
                initiative.initiativeId = initiative._id;
              }
              context.commit('UPDATE_INITIATIVES_SELETED_PHASE_JUDGES', initiative)
            }
        }
        // });
        }
      }
      if (context.state.menuClicked == "progress") {
        context.commit('UPDATE_INITIATIVES', result.data);
      }
      context.commit('SET_LOADING_FLAG', false);
    } catch (err) {
      console.log(err);
      context.commit('SET_LOADING_FLAG', false);
    }
  },
  async getAllInitiativesForSwapJudges(context,payload){
    //  context.commit('UPDATE_INITIATIVES', []);
      const baseId = process.env.VUE_APP_BASE_ID;
      const { accessToken } = context.rootState.user.user;
      const initiatives = feathersClient.service("initiatives");
      const phaseNames = context.rootState.playbook.phaseNames;
      const playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
      let playbookPhases = phaseNames.map((phase) => {
        return phase.label;
      })
      let andCondition = [];
      if (isEmpty(payload?.searchQuery?.status)) {
        andCondition.push({
          "status": { $in: [1, 6] }
        });
      }
      else {
        andCondition.push({
          "status": { $in: payload.searchQuery.status }
        });
      }
      andCondition.push({
        'baseId': baseId
      })
      if (!isEmpty(payload?.searchQuery)) {
        andCondition.push(payload.searchQuery)
      }
      const query = {
        $and: andCondition,
        $select: ['_id', 'name', 'status', 'description', 'metaData', 'activities', 'subInitiatives', 'createdAt', 'createdBy', 'picture', 'raci', 'teamId'],
        $sort: { "_id": -1 },
        $populate: [{
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name runStatus duration integration actualStartDate'
            },
            select: 'name runStatus duration activities integration actualStartDate'
          },
          select: 'name runStatus duration activities integration actualStartDate metaData'
        },
        {
          path: "subInitiatives",
          select: ' name activities metaData status raci teamId',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              populate: {
                path: 'activities',
                select: 'name runStatus'
              },
              populate: {
                path: 'raci.a.teamId',
                select: 'name '
              },
              select: 'name metaData',
            },
            select: 'name metaData raci',
          },
        },
        ]
      };
  
      const tokenString = `Bearer ${accessToken}`;
      try {
        const result = await initiatives.find({
          query: query,
          headers: {
            "Authorization": tokenString
          },
        });
        if (result.data && result.data.length > 0) {
  
          let allPhase = []
          result.data[0].activities.forEach(function (item, i) {
            allPhase.push(item.name)
          });
          let subOrgDataMap = new Map();
          const subOrgService = feathersClient.service("suborganizations");
          try {
            let subOrgQuery = {
              "parentOrganizationId" : process.env.VUE_APP_PHASE_JUDGES_TEAM_ID,
              "$select": ['name', '_id', 'members'],
              "$populate": [
                {
                  "path": "members.accountUserId",
                  "select": "firstName lastName email profilePic "
                }
              ]
            }
            let subOrgData = await subOrgService.find({
              query: subOrgQuery,
              headers: {
                "Authorization": tokenString
              },
            });
            if(subOrgData?.data?.length>0){
              for(let subOrg of subOrgData.data){
                subOrgDataMap.set(subOrg._id, subOrg);
              }
            }
          } catch(error){
            console.log("Error in fetching team data for selectedJudgesTeamId ",error)
          }
          for(let initiative of result.data){
            if (initiative.metaData && initiative.metaData.teamLead) {
              initiative.metaData.teamLead.forEach(function (lead) {
                const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
                  if(teamLead){
                    lead.firstName = teamLead?.firstName;
                    lead.lastName = teamLead?.lastName;
                    lead.email = teamLead?.email;
                    lead.image = teamLead.profilePic;
                    lead.name = teamLead?.name;
                  }
              });
            }
            if (initiative.metaData && initiative.metaData.teamMembers) {
              initiative.metaData.teamMembers.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
            if (initiative.metaData && initiative.metaData.submitter) {
              initiative.metaData.submitter.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
            if (initiative.metaData && initiative.metaData.mentors) {
              initiative.metaData.mentors.forEach(function (member) {
                const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
              });
            }
          // result.data.forEach(async function (initiative) {
            let lastPhaseId = initiative.activities.slice(-1)[0]._id;
            initiative.activities.every(function (phaseActivity, phaseIndex) {
              let runStatuses = [-1, 0, 1, 6];
              if (lastPhaseId == phaseActivity._id) {
                runStatuses = [-1, 0, 1, 2, 6];
              }
              //find first phase which is n progress 
              //Waiting/In-Queue = -1,Not started = 0  In Progress / Active = 1,  Completed  = 2, Cancelled = 3
              if (runStatuses.includes(phaseActivity.runStatus)) {
                if(playbookPhasesData?.length>0){
                  let currentActivity = playbookPhasesData[phaseIndex];
                  if(currentActivity?.metaData?.judgingPlaybookBaseId){
                    initiative.judgingPlaybookBaseId = currentActivity.metaData.judgingPlaybookBaseId;
                  }
                }
                initiative.phase = playbookPhases[phaseIndex];
                initiative.allPhase = allPhase;
                initiative.phaseIndex = phaseIndex;
                initiative.totalTasks = 0;
                initiative.completed = 0;
                initiative.totalDuration = 0;
                initiative.completedDuration = 0;
                phaseActivity.activities.forEach(function (stageActvity) {
                  if (stageActvity?.integration && Object.keys(stageActvity?.integration).length > 0) {
                    initiative.isSmartStage = true
                  }
                  stageActvity.activities.forEach(function (activity) {
                    initiative.totalTasks++;
                    initiative.totalDuration += activity.duration
                    if (activity.runStatus == 2) {
                      initiative.completed++;
                      initiative.completedDuration += activity.duration
                    }
                  })
                })
                return false; //break from the loop
              }
              return true;
            });
            if(initiative?.activities[initiative?.phaseIndex+1]){
              let nextPhaseInfo = initiative.activities[initiative.phaseIndex+1];
              initiative.nextPhaseInfo = nextPhaseInfo;
            }
            if(initiative?.activities[initiative?.phaseIndex]){
              let phaseInfo = initiative.activities[initiative.phaseIndex];
              initiative.phaseInfo = phaseInfo;
              if(phaseInfo?.metaData?.isJudgingRequired && phaseInfo?.metaData?.selectedJudgesTeamId){
                let selectedJudgesTeamId = phaseInfo.metaData.selectedJudgesTeamId;
                if(subOrgDataMap){
                  initiative.seletedPhaseJudgesDetails = subOrgDataMap.get(selectedJudgesTeamId);
                  initiative.initiativeId = initiative._id;
                }
              //  context.commit('UPDATE_INITIATIVES_SELETED_PHASE_JUDGES', initiative)
              }
          }
          if(initiative?.activities?.length > 0){
            for(let activity of initiative?.activities){
              let judgingTeamMembers = []
              if(activity?.metaData?.isJudgingRequired && activity?.metaData?.selectedJudgesTeamId){
                let selectedJudges=[];
                if(subOrgDataMap){
                  selectedJudges = subOrgDataMap.get(activity?.metaData?.selectedJudgesTeamId);
                  for (let member of selectedJudges.members){
                    if(!judgingTeamMembers.some(judge=> judge?._id === member?.accountUserId?._id)){
                      judgingTeamMembers.push(member?.accountUserId)
                    }
                  }
                }
                initiative.judgingTeamMembers = judgingTeamMembers;
              }
            }
          }
          // });
          }
        }
        return result.data;
      } catch (err) {
        console.log(err);
      }
  },
  async getInitiativesByPhaseForBulkAssignment(context, payload) {
    // const playbookId = process.env.VUE_APP_PRACTICE_ID;
    const baseId = process.env.VUE_APP_BASE_ID;

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const phaseNames = context.rootState.playbook.phaseNames;
    const playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
    let playbookPhases = phaseNames.map((phase) => {
      return phase.label;
    })
    let andCondition = [];
    if (isEmpty(payload?.searchQuery?.status)) {
      andCondition.push({
        "status": { $in: [1, 6] }
      });
    }
    else {
      andCondition.push({
        "status": { $in: payload.searchQuery.status }
      });
    }
    andCondition.push({
      'baseId': baseId
    })
    if (!isEmpty(payload?.searchQuery)) {
      andCondition.push(payload.searchQuery)
    }
    // if(andCondition?.length>0){
    //   for(let parameter of andCondition){
    //     if(parameter?.status?.$in?.length>0 && parameter.status.$in.includes(1)){
    //       parameter.status.$in.push(2);
    //     }
    //   }      
    // }
    const query = {
      $and: andCondition,
      $select: ['_id', 'name', 'status', 'description', 'metaData', 'activities', 'subInitiatives', 'createdAt', 'createdBy', 'picture', 'raci', 'teamId'],
      $sort: { "_id": -1 },
      $populate: [{
        path: 'activities',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            select: 'name runStatus duration integration actualStartDate'
          },
          select: 'name runStatus duration activities integration actualStartDate'
        },
        select: 'name runStatus duration activities integration actualStartDate metaData'
      },
      {
        path: "subInitiatives",
        select: ' name activities metaData status raci teamId',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name runStatus'
            },
            populate: {
              path: 'raci.a.teamId',
              select: 'name '
            },
            select: 'name metaData',
          },
          select: 'name metaData raci',
        },
      },
        // {
        //   path: 'subInitiatives', 
        //    select: ' name activities metaData status',
        //   populate: {
        //     path: 'activities',
        //     populate: { 
        //    path: 'activities metaData', select: 'name metaData' 
        //  },
        //     select: 'name metaData '
        //   }
        // }
      ]
    };

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      if (result.data && result.data.length > 0) {

        let allPhase = []
        result.data[0].activities.forEach(function (item, i) {
          allPhase.push(item.name)
        });
        let subOrgDataMap = new Map();
        const subOrgService = feathersClient.service("suborganizations");
        try {
          let subOrgQuery = {
            "parentOrganizationId" : process.env.VUE_APP_PHASE_JUDGES_TEAM_ID,
            "$select": ['name', '_id', 'members'],
            "$populate": [
              {
                "path": "members.accountUserId",
                "select": "firstName lastName email profilePic "
              }
            ]
          }
          let subOrgData = await subOrgService.find({
            query: subOrgQuery,
            headers: {
              "Authorization": tokenString
            },
          });
          if(subOrgData?.data?.length>0){
            for(let subOrg of subOrgData.data){
              subOrgDataMap.set(subOrg._id, subOrg);
            }
          }
        } catch(error){
          console.log("Error in fetching team data for selectedJudgesTeamId ",error)
        }
        for(let initiative of result.data){
          if (initiative.metaData && initiative.metaData.teamLead) {
            initiative.metaData.teamLead.forEach(function (lead) {
              const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
                if(teamLead){
                  lead.firstName = teamLead?.firstName;
                  lead.lastName = teamLead?.lastName;
                  lead.email = teamLead?.email;
                  lead.image = teamLead.profilePic;
                  lead.name = teamLead?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.teamMembers) {
            initiative.metaData.teamMembers.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.submitter) {
            initiative.metaData.submitter.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
          if (initiative.metaData && initiative.metaData.mentors) {
            initiative.metaData.mentors.forEach(function (member) {
              const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                if(teamMember){
                  member.firstName = teamMember?.firstName;
                  member.lastName = teamMember?.lastName;
                  member.email = teamMember?.email;
                  member.image = teamMember.profilePic;
                  member.name = teamMember?.name;
                }
            });
          }
        // result.data.forEach(async function (initiative) {
          let lastPhaseId = initiative.activities.slice(-1)[0]._id;
          initiative.activities.every(function (phaseActivity, phaseIndex) {
            let runStatuses = [-1, 0, 1, 6];
            if (lastPhaseId == phaseActivity._id) {
              runStatuses = [-1, 0, 1, 2, 6];
            }
            //find first phase which is n progress 
            //Waiting/In-Queue = -1,Not started = 0  In Progress / Active = 1,  Completed  = 2, Cancelled = 3
            if (runStatuses.includes(phaseActivity.runStatus)) {
              // if(phaseActivity?.metaData?.selectedJudgesTeamId){
              //   initiative.phaseSelectedJudgesTeamId = phaseActivity.metaData.selectedJudgesTeamId;
              // }
              if(playbookPhasesData?.length>0){
                let currentActivity = playbookPhasesData[phaseIndex];
                if(currentActivity?.metaData?.judgingPlaybookBaseId){
                  initiative.judgingPlaybookBaseId = currentActivity.metaData.judgingPlaybookBaseId;
                }
              }
              initiative.phase = playbookPhases[phaseIndex];
              initiative.allPhase = allPhase;
              initiative.phaseIndex = phaseIndex;
              initiative.totalTasks = 0;
              initiative.completed = 0;
              initiative.totalDuration = 0;
              initiative.completedDuration = 0;
              phaseActivity.activities.forEach(function (stageActvity) {
                if (stageActvity?.integration && Object.keys(stageActvity?.integration).length > 0) {
                  initiative.isSmartStage = true
                }
                // else{
                //   initiative.isSmartStage= false
                // }
                stageActvity.activities.forEach(function (activity) {
                  initiative.totalTasks++;
                  initiative.totalDuration += activity.duration
                  if (activity.runStatus == 2) {
                    initiative.completed++;
                    initiative.completedDuration += activity.duration
                  }
                })
              })
              return false; //break from the loop
            }
            return true;
          });
          if(initiative?.activities[initiative?.phaseIndex+1]){
            let nextPhaseInfo = initiative.activities[initiative.phaseIndex+1];
            initiative.nextPhaseInfo = nextPhaseInfo;
          }
          if(initiative?.activities[initiative?.phaseIndex]){
            let phaseInfo = initiative.activities[initiative.phaseIndex];
            initiative.phaseInfo = phaseInfo;
            if(phaseInfo?.metaData?.isJudgingRequired && phaseInfo?.metaData?.selectedJudgesTeamId){
              let selectedJudgesTeamId = phaseInfo.metaData.selectedJudgesTeamId;
              if(subOrgDataMap){
                initiative.seletedPhaseJudgesDetails = subOrgDataMap.get(selectedJudgesTeamId);
                initiative.initiativeId = initiative._id;
              }
              // context.commit('UPDATE_INITIATIVES_SELETED_PHASE_JUDGES', initiative)
            }
        }
        if(initiative?.activities?.length > 0){
          for(let activity of initiative?.activities){
            let judgingTeamMembers = []
            if(activity?.metaData?.isJudgingRequired && activity?.metaData?.selectedJudgesTeamId){
              let selectedJudges=[];
              if(subOrgDataMap){
                selectedJudges = subOrgDataMap.get(activity?.metaData?.selectedJudgesTeamId);
                for (let member of selectedJudges.members){
                  if(!judgingTeamMembers.some(judge=> judge?._id === member?.accountUserId?._id)){
                    judgingTeamMembers.push(member?.accountUserId)
                  }
                }
              }
              initiative.judgingTeamMembers = judgingTeamMembers;
            }
          }
        }


        // });
        }
      }
        return result.data;
    } catch (err) {
      console.log(err);
    }
  },
  //get initiative and it's phases, stages and activities
  async getInitiativeById(context, id) {
    let previousId = id;
    if (typeof id === "object") {
      id = id.id?id.id:id._id?id._id:"";
    }
    context.commit('RESET_INITIATIVE_SELECTION');

    const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const phaseNames = context.rootState.playbook.phaseNames;
    const likeService = feathersClient.service("initiativeLikes");
    const followService = feathersClient.service("userFollows")
    let playbookPhases = phaseNames.map((phase) => {
      return phase.label;
    })
    const query = {
      _id: id,
      $select: ['name', 'status', 'description', 'metaData', 'activities', 'notes', 'updatedAt', 'initativeLogs', 'picture', 'createdAt', 'priority', 'attachments', 'tags', 'subInitiatives', 'createdBy', 'teamId'],
      $populate: [
        {
          path: 'activities',
          select: 'name runStatus activities notes integration metaData',
          populate: [
            {
              path: 'notes.accountUserId',
              select: '_id firstName lastName profilePic email'
            },
            {
              path: 'activities',
              select: 'name runStatus activities integration',
              populate:
              {
                path: 'activities',
                select: 'name runStatus isMilestone raci integration'
              },
            }],
        }, {
          path: 'communities',
          select: 'name communities',
          populate: [
            {
              path: 'communities',
              select: 'name raci'
            }]
        },
        {
          path: 'notes.accountUserId',
          select: '_id firstName lastName profilePic email',
        },
        {
          path: "attachments.uploadedBy",
          select: "firstName lastName profilePic email"
        },
        {
          path: 'subInitiatives', select: ' name activities metaData status',
          populate: {
            path: 'activities',
            populate: { path: 'activities metaData', select: 'name metaData' },
            select: 'name metaData '
          }
        }
      ]
    };



    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      let statusLabel = ""
      switch (result.data && result.data[0].status) {
        case -1:
          statusLabel = "Draft";
          break;
        case 0:
          statusLabel = "Submitted";
          break;
        case 1:
          statusLabel = "Active";
          break;
        case 2:
          statusLabel = "Complete";
          break;
        case 3:
          statusLabel = "Closed";
          break;
        case 5:
          statusLabel = "Archived";
          break;
        case 6:
          statusLabel = "Inactive";
          break;
        case 7:
          statusLabel = "Merged";
          break;
        default:
        // code block
      }
      if (result.data && result.data[0]) {
        let ideaLikedBy = [];
        let ideaFollowedBy = [];
        let subOrgDataMap = new Map();
        const subOrgService = feathersClient.service("suborganizations");
        try {
          let subOrgQuery = {
            "parentOrganizationId" : process.env.VUE_APP_PHASE_JUDGES_TEAM_ID,
            "$select": ['name', '_id', 'members'],
            "$populate": [
              {
                "path": "members.accountUserId",
                "select": "firstName lastName email profilePic "
              }
            ]
          }
          let subOrgData = await subOrgService.find({
            query: subOrgQuery,
            headers: {
              "Authorization": tokenString
            },
          });
          if(subOrgData?.data?.length>0){
            for(let subOrg of subOrgData.data){
              subOrgDataMap.set(subOrg._id, subOrg);
            }
          }
        } catch(error){
          console.log("Error in fetching team data for selectedJudgesTeamId ",error)
        }
        ideaLikedBy = await getInitiativeLikes(likeService, id);
        ideaFollowedBy = await getInitiativeFollowers(followService, id);
        const initiative = {
          id: result.data[0]._id,
          name: result.data[0].name,
          status: result.data[0].status,
          statusLabel: statusLabel,
          picture: result.data[0].picture,
          tags: result.data[0].tags,
          description: result.data[0].description ,
          metaData: result.data[0].metaData,
          activitiesDataMap: new Map(),
          createdBy: result.data[0].createdBy,
          teamId: result.data[0].teamId,
          updatedAt: result.data[0].updatedAt,
          priority: result.data[0].priority,
          attachments: result.data[0].attachments,
          createdAt: format(new Date(result.data[0].createdAt), "mediumDate"),
          initativeLogs: result.data[0].initativeLogs,
          requests: result.data[0].metaData.requests,
          subInitiatives: result.data[0].subInitiatives,
          ideaLikedBy: ideaLikedBy,
          ideaFollowedBy: ideaFollowedBy
        };
        if (initiative.metaData && initiative.metaData.teamLead) {
          initiative.metaData.teamLead.forEach(function (lead) {
            const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
              if(teamLead){
                lead.firstName = teamLead?.firstName;
                lead.lastName = teamLead?.lastName;
                lead.email = teamLead?.email;
                lead.image = teamLead.profilePic;
                lead.name = teamLead?.name;
              }
          });
        }
        if (initiative.metaData && initiative.metaData.teamMembers) {
          initiative.metaData.teamMembers.forEach(function (member) {
            const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
              if(teamMember){
                member.firstName = teamMember?.firstName;
                member.lastName = teamMember?.lastName;
                member.email = teamMember?.email;
                member.image = teamMember.profilePic;
                member.name = teamMember?.name;
              }
          });
        }
        if (initiative.metaData && initiative.metaData.submitter) {
          initiative.metaData.submitter.forEach(function (member) {
            const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
              if(teamMember){
                member.firstName = teamMember?.firstName;
                member.lastName = teamMember?.lastName;
                member.email = teamMember?.email;
                member.image = teamMember.profilePic;
                member.name = teamMember?.name;
              }
          });
        }
        if (initiative.metaData && initiative.metaData.mentors) {
          initiative.metaData.mentors.forEach(function (member) {
            const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
              if(teamMember){
                member.firstName = teamMember?.firstName;
                member.lastName = teamMember?.lastName;
                member.email = teamMember?.email;
                member.image = teamMember.profilePic;
                member.name = teamMember?.name;
              }
          });
        }
        let userTitle = null;
        let isIdeaParticipant = false;
        // Checks whether logged in user is workspace owner or is part of power users team.
        if (userRole == 'workspaceOwner' || roles.includes('manager')) {
          userTitle = 'Program Lead';
        }
        else if (initiative.metaData.teamLead || initiative.metaData.teamMembers || initiative.metaData.mentors) {
          // Check whether logged in user team lead in any of initiative.
          var leadIndex = initiative.metaData.teamLead.findIndex(lead => lead.accountUserId == aspenifyUserId);
          if (leadIndex >= 0) {
            userTitle = 'Team Lead';
            isIdeaParticipant = true;
          }
          // Check whether logged in user team member/ mentor in any of initiative. 
          else if (leadIndex == -1) {
            var memberIndex = initiative.metaData.teamMembers.findIndex(member => member.accountUserId == aspenifyUserId);
            var mentorIndex = initiative.metaData.mentors.findIndex(mentor => mentor.accountUserId == aspenifyUserId);
            if (memberIndex >= 0 || mentorIndex >= 0) {
              userTitle = 'Team Member';
              isIdeaParticipant = true;
            }
          }
        }
        // Checks whether logged in user is part of executive team.
        if (roles.includes('executive') && !isIdeaParticipant) {
          userTitle = 'Executive - General';
        }
        // Checks whether logged in user is part of judging team or its sub team.
        if ((roles.includes('judgeManager') || roles.includes('judgeMember')) && !isIdeaParticipant) {
          userTitle = 'Executive - Judge';
        }
        let roleActivityMap = new Map();
        if (result.data[0]?.communities[0]?.communities) {
          let initiativeCommunitiesData = result.data[0].communities[0].communities;
          for (let community of initiativeCommunitiesData) {
            let newCommunity = {};
            let responsibleAccountable = community.raci.r.concat(community.raci.a);
            let consultedInformed = community.raci.c.concat(community.raci.i);
            newCommunity.ra = [...new Set(responsibleAccountable)];
            newCommunity.ci = [...new Set(consultedInformed)];
            roleActivityMap.set(community.name, newCommunity)
          }
        }
        let roleTeamActivityMap = roleActivityMap.get(userTitle);
        context.commit('SET_JIRA_INITIATIVE_UPDATED', !context.state.initiativeDataUpdated);
        let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
        result.data[0].activities.every(function (phaseActivity, phaseIndex) {
          //find first phase which is in progress  / Active
          //Waiting/In-Queue = -1,Not started = 0  In Progress / Active = 1,  Completed  = 2, Cancelled = 3
          processInitiativeActivities(phaseActivity, roleTeamActivityMap.ra, roleTeamActivityMap.ci, feathersClient, context, createInitiativeStatus);
          if (previousId && previousId.isPhaseChanged && phaseIndex === previousId.currentPhaseIndex) {
            initiative.phase = playbookPhases[phaseIndex];
            initiative.phaseIndex = phaseIndex;
            initiative._id = phaseActivity._id;
            initiative.totalTasks = 0;
            initiative.completed = 0;
            initiative.phaseData = [];
            initiative.notes = phaseActivity.notes
            phaseActivity.activities.forEach(async function (stageActvity) {
              let initiativeActivitiesData = {};
              let initiativeActivitiesMetaData;
              if (stageActvity.integration) {
                let initiativeActivities = feathersClient.service("initiatives/activities");
                initiativeActivitiesData = await initiativeActivities.get(stageActvity._id, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
              }
              let projectInfo;

              if (Object.keys(initiativeActivitiesData).length > 0 && initiativeActivitiesData.metaData) {
                if (stageActvity.integration) {
                  let routeParams = initiativeActivitiesData?.metaData?.selectedProject?.split("project/");
                  let projectInfoPayload = {
                    routeParameters: "/rest/api/2/project/" + routeParams[1],
                    integrationId: stageActvity.integration._id,
                  };
                  projectInfo = await context.dispatch(
                    "jiraAPI",
                    projectInfoPayload
                  );
                  initiativeActivitiesMetaData = { ...initiativeActivitiesData.metaData, projectName: projectInfo.name };
                }
              }
              const stateData = {
                _id: stageActvity._id,
                name: stageActvity.name,
                activityList: [],
                integration: stageActvity.integration ? stageActvity.integration : null,
                initiativeActivitiesMetaData: initiativeActivitiesMetaData
              }
              if (stageActvity?.action) {
                stateData.action = stageActvity.action;
              }
              stageActvity.activities.forEach(function (activity) {

                const activityData = {
                  _id: activity._id,
                  name: activity.name,
                  runStatus: activity.runStatus,
                  isCompleted: false,
                  isCancelled: false,
                  isMilestone: activity.isMilestone
                }
                if (activity?.action) {
                  activityData.action = activity.action;
                }
                // if (activity.runStatus>0) { //activity.runStatus===2, as per requirement if we go to previous phase, nedd to show all activities as marked !  
                //   initiative.completed++;
                //   activityData.isCompleted = true;
                // }
                if (activityData.action != 'hide') {
                  stateData.activityList.push(activityData);
                  initiative.totalTasks++;
                  if (activity.runStatus === 2) { //activity.runStatus===2, as per requirement if we go to previous phase, nedd to show all activities as marked !  
                    initiative.completed++;
                    activityData.isCompleted = true;
                  }
                  if (activity.runStatus === 3) { //activity.runStatus===2, as per requirement if we go to previous phase, nedd to show all activities as marked !  
                    initiative.cancelled++;
                    activityData.isCancelled = true;
                  }
                }
                initiative['activitiesDataMap'].set(activityData._id, activityData);
              });
              if (stateData.action != 'hide') {
                initiative.phaseData.push(stateData);
              }
              initiative['activitiesDataMap'].set(stateData._id, stateData);
              context.commit('SET_JIRA_INITIATIVE_UPDATED', !context.state.initiativeDataUpdated);

            })
            return false;
          }
          if ([-1, 0, 1, 6].includes(phaseActivity.runStatus)) {
            initiative.phase = playbookPhases[phaseIndex];
            initiative.phaseIndex = phaseIndex;
            initiative._id = phaseActivity._id;
            initiative.totalTasks = 0;
            initiative.completed = 0;
            initiative.phaseData = [];
            initiative.notes = phaseActivity.notes
            phaseActivity.activities.forEach(async function (stageActvity) {
              let initiativeActivitiesData = {};
              let initiativeActivitiesMetaData;
              if (stageActvity.integration) {
                let initiativeActivities = feathersClient.service("initiatives/activities");
                initiativeActivitiesData = await initiativeActivities.get(stageActvity._id, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
              }
              let projectInfo;

              if (Object.keys(initiativeActivitiesData).length > 0 && initiativeActivitiesData.metaData) {
                if (stageActvity.integration) {
                  let routeParams = initiativeActivitiesData?.metaData?.selectedProject?.split("project/");
                  let projectInfoPayload = {
                    routeParameters: "/rest/api/2/project/" + routeParams[1],
                    integrationId: stageActvity.integration._id,
                  };
                  projectInfo = await context.dispatch(
                    "jiraAPI",
                    projectInfoPayload
                  );
                  initiativeActivitiesMetaData = { ...initiativeActivitiesData.metaData, projectName: projectInfo.name };
                }
              }
              const stateData = {
                _id: stageActvity._id,
                name: stageActvity.name,
                activityList: [],
                integration: stageActvity.integration ? stageActvity.integration : null,
                initiativeActivitiesMetaData: initiativeActivitiesMetaData
              }
              if (stageActvity?.action) {
                stateData.action = stageActvity.action;
              }
              stageActvity.activities.forEach(function (activity) {

                const activityData = {
                  _id: activity._id,
                  name: activity.name,
                  runStatus: activity.runStatus,
                  isCompleted: false,
                  isMilestone: activity.isMilestone
                }
                if (activity?.action) {
                  activityData.action = activity.action;
                }
                // if (activity.runStatus == 2) {
                //   initiative.completed++;
                //   activityData.isCompleted = true;
                // }
                if (activityData.action != 'hide') {
                  stateData.activityList.push(activityData);
                  initiative.totalTasks++;
                  if (activity.runStatus == 2) {
                    initiative.completed++;
                    activityData.isCompleted = true;
                  }
                }
                initiative['activitiesDataMap'].set(activityData._id, activityData);
              });
              if (stateData.action != 'hide') {
                initiative.phaseData.push(stateData);
              }
              initiative['activitiesDataMap'].set(stateData._id, stateData);
              context.commit('SET_JIRA_INITIATIVE_UPDATED', !context.state.initiativeDataUpdated);

            })

            return false;
          }
          return true;
        });

        context.commit('SET_SELECTED_INITIATIVE', initiative);
        if (typeof previousId === "object" && !previousId.isRedirectToActivities) {
          context.dispatch('setPhaseActivitiesFlag', true);
          // context.commit('SET_FLAG_TO_OPEN_ACTIVITIES_TAB', true);
        }
        if(result.data[0]?.activities[initiative?.phaseIndex]){
          let phaseInfo = result.data[0].activities[initiative.phaseIndex];
          initiative.phaseInfo = phaseInfo;
          if(phaseInfo?.metaData?.isJudgingRequired && phaseInfo?.metaData?.selectedJudgesTeamId){
            let selectedJudgesTeamId = phaseInfo.metaData.selectedJudgesTeamId;
            if(subOrgDataMap){
              initiative.seletedPhaseJudgesDetails = subOrgDataMap.get(selectedJudgesTeamId);
              initiative.initiativeId = initiative._id;
            }
          } 
      }
        if(result.data[0].activities[initiative.phaseIndex+1]){
          let nextPhaseInfo = result.data[0].activities[initiative.phaseIndex+1];
          initiative.nextPhaseInfo = nextPhaseInfo;
          context.commit('SET_SELECTED_INITIATIVE', initiative);
          context.dispatch("getTeamMembers", initiative)
        }      
        let idea = result.data[0];
        idea.phaseIndex = initiative.phaseIndex;
        context.dispatch("getJudgingTeamDetails", idea)
      }
      // Exec Sponsor
      if (result.data[0].metaData && result.data[0].metaData.execSponsor) {
        result.data[0].metaData.execSponsor.forEach(function (member) {
            const sponsor = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
              if(sponsor){
                member.firstName = sponsor?.firstName;
                member.lastName = sponsor?.lastName;
                member.email = sponsor?.email;
                member.image = sponsor.profilePic;
                member.name = sponsor?.name;
              }
          });
        result.data[0].metaData.execSponsor.forEach(function (member) {
          let name;
          if (member.name.includes("(")) {
            let memberName = member?.name?.split("(");
            let userName = memberName[1]?.split(")");
            name = memberName[0] + " (" + userName[0]?.trim() + ")";
          } else {
            name = member.name
          }
          const user = {
            name: name,
            image: member.image,
            role: "Exec Sponsor",
            email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
          };
          if (member.accountUserId) {
            user.accountUserId = member.accountUserId;
          }
          let execSponsors = [];
          execSponsors.push(user);
          context.commit('SET_EXECSPONSOR_FOR_SELECTED_SUBMISSION', execSponsors);
        });
      } else {
        context.commit('SET_EXECSPONSOR_FOR_SELECTED_SUBMISSION', []);
      }
    } catch (err) {
      console.log(err);
    }
  },

  async getJudgingInitiativeById(context, payload) {
    let id = payload.id;
    let userId = payload.userId;
    const { accessToken } = context.rootState.user.user;
    let aspenifyUserId = context.rootState.user.user.aspenifyUserId;
    if(userId){
      aspenifyUserId = userId;
    }
    const initiatives = feathersClient.service("initiatives");
    const playbookData = context.rootState.playbook.playbook;
    const query = {
      _id: id,
      $select: ['name', 'status', 'description', 'metaData', 'activities', 'notes', 'updatedAt', 'initativeLogs', 'picture', 'createdAt', 'priority', 'attachments', 'tags', 'parentInitiativeId', 'practiceId', 'baseId'],
      $populate: [
        {
          path: "activities",
          populate: {
            path: "activities",
            populate: { path: "activities ", select: "name runStatus" },
            select: "name runStatus activities metaData raci",
            populate: {
              select: "_id name",
              path: "raci.a.teamId",
            }
          },
          select: "name runStatus activities raci",
        },
        {
          path: "parentInitiativeId",
          select: "_id name metaData status activities image",
          populate: {
            path: "activities",
            populate: {
              path: "activities",
              populate: { path: "activities", select: "name runStatus" },
              select: "name runStatus activities metaData raci",

            },
            select: "name runStatus activities raci",
          },

        }
      ]
    };
    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      let payload = result.data[0]

      let phase = "";
      let phaseIndex = "";
      switch (result?.data[0]?.parentInitiativeId?.status) {
        // case -1:
        //   phase = "Draft";
        //   break;
        // case 0:
        //   phase = "In Review";
        //   break;
        case 1:
          result?.data[0]?.parentInitiativeId?.activities.every(function (phaseActivity, i) {
            if (phaseActivity.runStatus === 1) {
              phase = phaseActivity.name;
              phaseIndex = i
              return false; //break from the loop
            }
            return true;
          });
          break;
        case 2:
          result?.data[0]?.parentInitiativeId?.activities.every(function (phaseActivity, i) {
            if (phaseActivity.runStatus === 1) {
              phase = phaseActivity.name;
              phaseIndex = i
              return false; //break from the loop
            }
            return true;
          });
          break;
        case 6:
          result?.data[0]?.parentInitiativeId?.activities.every(function (phaseActivity, i) {
            if (phaseActivity.runStatus === 1) {
              phase = phaseActivity.name;
              phaseIndex = i
              return false; //break from the loop
            }
            return true;
          });
          break;

        default:

      }


      payload.phase = phase;
      payload.phaseIndex = phaseIndex;
      let index = -1;
      playbookData.activities.map((activity, i)=>{
        if(activity?.metaData?.isJudgingRequired && activity?.metaData?.judgingPlaybookBaseId && payload.baseId == activity.metaData.judgingPlaybookBaseId){
          index = i;
        }
      })
      if(index<0){
        payload.phaseIndex = phaseIndex;
      } else {
        payload.phaseIndex = index;
      }
      let playbookPhaseData = playbookData.activities[payload.phaseIndex];
        if(!playbookPhaseData?.metaData?.judgingPlaybookBaseId){
          for(let [i, activity] of playbookData.activities.entries()){
            if(activity?.metaData?.judgingPlaybookBaseId && activity.metaData.judgingPlaybookBaseId == payload.baseId){
              playbookPhaseData = playbookData.activities[i];
            }
          }
        }
        if(playbookPhaseData?.metaData?.judgingPlaybookBaseId){
          let query = {
            "baseId": playbookPhaseData.metaData.judgingPlaybookBaseId,
            "status":4,
            "$select":['metaDataSchema']
          }
          if(payload.status == 2){
            query = {
              "_id": payload.practiceId,
              "$select":['metaDataSchema']
            }
          }
          const playbooks = feathersClient.service("playbooks");
          const tokenString = `Bearer ${accessToken}`;
          var judgingPlaybookData = await playbooks.find({
            query: query,
            headers: {
              "Authorization": tokenString
            },
          });
          if(judgingPlaybookData?.[0]?.metaDataSchema?.length>0){
            payload.metaDataSchema = judgingPlaybookData[0].metaDataSchema;
              if(payload?.metaDataSchema?.length>0){
                for(let schema of payload.metaDataSchema){
                  schema.modifiedChoices=[]
                  if(schema?.choices?.length>0){
                    for(let choice of schema.choices){
                      schema.modifiedChoices.push({ "value": choice, "label": choice });                    
                    }
                  }
                }
              }
              if(payload?.metaData){
              for(let reviewDataKey in payload.metaData){
                if(reviewDataKey.startsWith('review')){
                  let userReview = payload.metaData[reviewDataKey].filter((review) => review.lastUpdatedBy == aspenifyUserId);
                  if(userReview?.length>0){
                    for(let reviewField in userReview[0]){
                      if(payload?.metaDataSchema?.length>0){
                        for(let schema of payload.metaDataSchema){
                          if(schema.name == reviewField){
                            schema.model = userReview[0][reviewField];
                            if(schema?.modifiedChoices?.length>0){
                              for(let modifiedChoice of schema.modifiedChoices){
                                if((userReview[0][reviewField]).includes(modifiedChoice.label)){
                                  modifiedChoice.model = true;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      return payload;
    } catch (err) {
      console.log(err);
    }
  },
  //get initiative and it's phases, stages and activities
  async getInitiativeLogs(context, id) {
    // context.commit('SET_LOADING_FLAG', true);
    if (typeof id === "object") {
      id = id.id?id.id:id._id?id._id:"";
    }
    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const query = {
      _id: id,
      $select: ['initativeLogs'],

    };
    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      if (result.data && result.data[0]) {
        let sortedData = result.data[0].initativeLogs.sort((a, b) => a.commentDate - b.commentDate)

        const initiative = {
          initativeLogs: sortedData,
        };
        context.commit('SET_LOGS_FOR_INITIATIVE', initiative);
      }
    } catch (err) {
      console.log(err);
    }
  },
  //get initiative details
  async getInitiativeDetailsById(context, id) {
    // context.commit('SET_LOADING_FLAG', true);
    // context.commit('RESET_INITIATIVE_SELECTION');

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const likeService = feathersClient.service("initiativeLikes");
    const followService = feathersClient.service("userFollows");
    const query = {
      _id: id,
      $select: ['name', 'status', 'description', 'metaData', 'picture', 'createdAt', 'updatedAt', 'activities', 'initativeLogs', 'notes', 'attachments', 'createdBy', 'teamId', 'priority', 'tags', 'subInitiatives'],
      $populate: [{
        path: 'activities',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            select: 'name runStatus integration'
          },
          select: 'name runStatus activities integration'
        },
        select: 'name runStatus activities integration'
      },
      {
        path: "notes.accountUserId",
        select: "firstName lastName profilePic email"
      },
      {
        path: "attachments.uploadedBy",
        select: "firstName lastName profilePic email"
      },
      {
        path: "subInitiatives",
        select: ' name activities metaData status raci',
        populate: {
          path: 'activities',
          populate: {
            path: 'activities',
            populate: {
              path: 'activities',
              select: 'name '
            },
            populate: {
              path: 'raci.a.teamId',
              select: 'name '
            },
            select: 'name metaData',
          },
          select: 'name metaData raci',
        },
      },
      ]
    };

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      if (result.data && result.data[0]) {

        let users = [];
        const teamMembers = [];
        if (result.data[0].metaData && result.data[0].metaData.teamMembers) {
          result.data[0].metaData.teamMembers.forEach(function (member, index) {
            const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Team Member",
              // role: member?.role,
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            // if (index != 0) {
            //   teamMembers.push(user);
            // }
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }

        if (result.data[0].metaData && result.data[0].metaData.mentors) {
          result.data[0].metaData.mentors.forEach(function (member) {
            const mentor = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(mentor){
                    member.firstName = mentor?.firstName;
                    member.lastName = mentor?.lastName;
                    member.email = mentor?.email;
                    member.image = mentor.profilePic;
                    member.name = mentor?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Mentor",
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }
        //submitter 
        if (result.data[0].metaData && result.data[0].metaData.submitter) {
          result.data[0].metaData.submitter.forEach(function (member) {
            const submitter = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(submitter){
                    member.firstName = submitter?.firstName;
                    member.lastName = submitter?.lastName;
                    member.email = submitter?.email;
                    member.image = submitter.profilePic;
                    member.name = submitter?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member?.name?.split("(");
              let userName = memberName[1]?.split(")");
              name = memberName[0] + " (" + userName[0]?.trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Submitter",
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }

        if (result.data[0].metaData && result.data[0].metaData.teamLead) {
          result.data[0].metaData.teamLead.forEach(function (lead) {
            // if (lead && users && users.length !== 0 && users[0].name && lead.name == users[0].name) {
            //   users[0].role = "Submitter | Team Lead"
            // } else {
              const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
              if(teamLead){
                lead.firstName = teamLead?.firstName;
                lead.lastName = teamLead?.lastName;
                lead.email = teamLead?.email;
                lead.image = teamLead.profilePic;
                lead.name = teamLead?.name;
              }  
            let name;
            if (lead.name.includes("(")) {
              let memberName = lead.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = lead.name
            }
            const user = {
              name: name,
              image: lead.image,
              role: "Team Lead",
              email: lead.email?.toLowerCase() ? lead.email?.toLowerCase() : ""
            };
            if (lead.accountUserId) {
              user.accountUserId = lead.accountUserId;
            }
            users.splice(1, 0, user);
          });
        }

        let phase = "";
        let phaseIndex = "";
        switch (result.data[0].status) {
          // case -1:
          //   phase = "Draft";
          //   break;
          // case 0:
          //   phase = "In Review";
          //   break;
          case 1:
            result.data[0].activities.every(function (phaseActivity, i) {
              if (phaseActivity.runStatus === 1) {
                phase = phaseActivity.name;
                phaseIndex = i + 1
                return false; //break from the loop
              }
              return true;
            });
            break;
          case 2:
            result.data[0].activities.every(function (phaseActivity, i) {
              if (phaseActivity.runStatus === 1) {
                phase = phaseActivity.name;
                phaseIndex = i + 1
                return false; //break from the loop
              }
              return true;
            });
            break;
          case 6:
            result.data[0].activities.every(function (phaseActivity, i) {
              if (phaseActivity.runStatus === 1) {
                phase = phaseActivity.name;
                phaseIndex = i + 1
                return false; //break from the loop
              }
              return true;
            });
            break;
          // case 2:
          //   phase = "Complete";
          //   break;
          // case 3:
          //   phase = "Closed";
          //   break;
          // case 5:
          //   phase = "Archived";
          //   break;
          default:
          // code block
        }

        let statusLabel = ""
        switch (result.data[0].status) {
          case -1:
            statusLabel = "Draft";
            break;
          case 0:
            statusLabel = "Submitted";
            break;
          case 1:
            statusLabel = "Active";
            break;
          case 2:
            statusLabel = "Complete";
            break;
          case 3:
            statusLabel = "Closed";
            break;
          case 5:
            statusLabel = "Archived";
            break;
          case 6:
            statusLabel = "Inactive";
            break;
          case 7:
            statusLabel = "Merged";
            break;
          default:
          // code block
        }
        let ideaLikedBy = [];
        let ideaFollowedBy = [];
        ideaLikedBy = await getInitiativeLikes(likeService, id);
        ideaFollowedBy = await getInitiativeFollowers(followService, id);
        const initiative = {
          id: result.data[0]._id,
          name: result.data[0].name,
          status: result.data[0].status,
          createdBy: result.data[0].createdBy,
          teamId: result.data[0].teamId,
          updatedAt: result.data[0].updatedAt,
          description: result.data[0].description ,
          statusLabel: statusLabel,
          phase: phase,
          phaseIndex: phaseIndex,
          date: format(new Date(result.data[0].createdAt), "mediumDate"),
          type: result.data[0].metaData.challengeType,
          image: result.data[0].picture,
          tags: result.data[0].tags,
          initativeLogs: result.data[0].initativeLogs,
          users: users,
          teamMembers: result.data[0].metaData.teamMembers,
          mentors: result.data[0].metaData.mentors,
          teamLead: result.data[0].metaData.teamLead,
          attachments: result.data[0].attachments,
          notes: processNotes(result.data[0].notes),
          challengeType: result.data[0].metaData.challengeType,
          marketHorizon: result.data[0].metaData.marketHorizon,
          bogFramework: result.data[0].metaData.bogFramework,
          aboutTeam: result.data[0].metaData.aboutTeam,
          testimonialAwardsIpAndOpenSource: result.data[0].metaData.testimonialAwardsIpAndOpenSource,
          isTalentRequired: result.data[0].metaData.isTalentRequired,
          talent: result.data[0].metaData.talent,
          progress: result.data[0].metaData.progress,
          source: result.data[0].metaData.source,
          priority: result.data[0].priority,
          elevatorPitch: result.data[0].metaData.elevatorPitch,
          problemDescription: result.data[0].metaData.problemDescription,
          potentialMarket: result.data[0].metaData.potentialMarket,
          ventureTermsCondition: result.data[0].metaData.ventureTermsCondition,
          origIdeaTermsCodition: result.data[0].metaData.origIdeaTermsCodition,
          requests: result.data[0].metaData.requests,
          metaData: result.data[0].metaData,
          subInitiatives: result.data[0]?.subInitiatives,
          ideaLikedBy: ideaLikedBy,
          ideaFollowedBy: ideaFollowedBy,
          teamId: result.data[0].teamId
        };

        context.commit('SET_SELECTED_INITIATIVE', initiative);
        context.commit('SET_LOADING_FLAG', false);
      }
    } catch (err) {
      console.log(err);
      context.commit('SET_LOADING_FLAG', false);
    }
  },
  //get initiative details for in-draft initiative
  async getInDraftInitiativeDetailsById(context, id) {
    context.commit('SET_LOADING_FLAG', true);
    context.commit('RESET_INITIATIVE_SELECTION');

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");

    const query = {
      _id: id,
      $select: ['name', 'status', 'description', 'metaData', 'picture', 'attachments', 'tags', 'subInitiatives', 'teamId']
    };

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      if (result.data && result.data[0]) {

        let users = [];

        if (result.data[0].metaData && result.data[0].metaData.teamMembers) {
          result.data[0].metaData.teamMembers.forEach(function (member) {
            const teamMember = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(teamMember){
                    member.firstName = teamMember?.firstName;
                    member.lastName = teamMember?.lastName;
                    member.email = teamMember?.email;
                    member.image = teamMember.profilePic;
                    member.name = teamMember?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Team Member",
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }
        //submitter 
        if (result.data[0].metaData && result.data[0].metaData.submitter) {
          result.data[0].metaData.submitter.forEach(function (member) {
            const submitter = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(submitter){
                    member.firstName = submitter?.firstName;
                    member.lastName = submitter?.lastName;
                    member.email = submitter?.email;
                    member.image = submitter.profilePic;
                    member.name = submitter?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member?.name?.split("(");
              let userName = memberName[1]?.split(")");
              name = memberName[0] + " (" + userName[0]?.trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Submitter",
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }
        if (result.data[0].metaData && result.data[0].metaData.mentors) {
          result.data[0].metaData.mentors.forEach(function (member) {
            const mentors = context.state.workspaceUsers?.find(user=>user._id == member.accountUserId);
                  if(mentors){
                    member.firstName = mentors?.firstName;
                    member.lastName = mentors?.lastName;
                    member.email = mentors?.email;
                    member.image = mentors.profilePic;
                    member.name = mentors?.name;
                  }
            let name;
            if (member.name.includes("(")) {
              let memberName = member.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = member.name
            }
            const user = {
              name: name,
              image: member.image,
              role: "Mentor",
              email: member.email?.toLowerCase() ? member.email?.toLowerCase() : ""
            };
            if (member.accountUserId) {
              user.accountUserId = member.accountUserId;
            }
            users.push(user);
          });
        }
        if (result.data[0].metaData && result.data[0].metaData.teamLead) {
          result.data[0].metaData.teamLead.forEach(function (lead) {
            // if (lead && users && users.length !== 0 && users[0].name && lead.name == users[0].name) {
            //   users[0].role = "Submitter | Team Lead"
            // } else {
              const teamLead = context.state.workspaceUsers?.find(user=>user._id == lead.accountUserId);
              if(teamLead){
                lead.firstName = teamLead?.firstName;
                lead.lastName = teamLead?.lastName;
                lead.email = teamLead?.email;
                lead.image = teamLead.profilePic;
                lead.name = teamLead?.name;
              }  
            let name;
            if (lead.name.includes("(")) {
              let memberName = lead.name.split("(");
              let userName = memberName[1].split(")");
              name = memberName[0] + " (" + userName[0].trim() + ")";
            } else {
              name = lead.name
            }
            const user = {
              name: name,
              image: lead.image,
              role: "Team Lead",
              email: lead.email?.toLowerCase() ? lead.email?.toLowerCase() : ""
            };
            if (lead.accountUserId) {
              user.accountUserId = lead.accountUserId;
            }
            users.splice(1, 0, user);
          });
        }

        const initiative = {
          id: result.data[0]._id,
          name: result.data[0].name,
          status: result.data[0].status,
          description: result.data[0].description ? result.data[0].description : "",
          type: result.data[0].metaData.challengeType,
          image: result.data[0].picture,
          tags: result.data[0].tags,
          users: users,
          attachments: result.data[0].attachments,
          teamMembers: result.data[0].metaData.teamMembers,
          mentors: result.data[0].metaData.mentors,
          teamLead: result.data[0].metaData.teamLead,
          challengeType: result.data[0].metaData.challengeType,
          marketHorizon: result.data[0].metaData.marketHorizon,
          bogFramework: result.data[0].metaData.bogFramework,
          aboutTeam: result.data[0].metaData.aboutTeam,
          testimonialAwardsIpAndOpenSource: result.data[0].metaData?.testimonialAwardsIpAndOpenSource,
          isTalentRequired: result.data[0].metaData.isTalentRequired,
          talent: result.data[0].metaData.talent,
          progress: result.data[0].metaData.progress,
          elevatorPitch: result.data[0].metaData.elevatorPitch,
          problemDescription: result.data[0].metaData.problemDescription,
          potentialMarket: result.data[0].metaData.potentialMarket,
          ventureTermsCondition: result.data[0].metaData.ventureTermsCondition,
          origIdeaTermsCodition: result.data[0].metaData.origIdeaTermsCodition,
          requests: result.data[0].metaData.requests,
          teamId: result.data[0].teamId
        };

        context.commit('SET_SELECTED_INITIATIVE', initiative);
        context.commit('SET_LOADING_FLAG', false);
      }
    } catch (err) {
      console.log(err);
      context.commit('SET_LOADING_FLAG', false);
    }
  },
  async updateActivity(context, activity) {
    const { apiKey, accessToken } = context.rootState.user.user;
    const selectedInitiative = context.state.selectedInitiative;
    const initiativeActivitiesService = feathersClient.service('initiatives/activities');
    const currentActivityStatus = activity.runStatus;
    const currentActivityMilestone = activity.isMilestone;

    const activityPayload = {
      _id: activity._id,
      name: activity.name
    }
    context.commit('ADD_TO_ACTIVITIES_TO_UPDATE_LIST', activityPayload);

    let payload = {};

    if (currentActivityStatus == 2) {
      //reopen the activity
      payload = {
        runStatus: 0,
        progress: 0,
        string: "reopenActivity",
        actualStartDate: null,
        actualEndDate: null,
        triggerEvent: true
      }
    } else {
      //Complete the activity
      payload = {
        runStatus: 2,
        progress: 100,
        completeActivitystring: "MarkAsComplete",
        actualStartDate: new Date(),
        actualEndDate: new Date(),
        triggerEvent: true
      }
    }
    try {
      // ***no need to populate references after patch***
      const tokenString = `Bearer ${accessToken}`;
      await initiativeActivitiesService.patch(activity._id, payload, {
        query: {
          $populate: [
            { path: 'references.id', populate: 'contentData', select: '_id name scope contentpath type' }
          ]
        },
        headers: {
          "Authorization": tokenString
        },
      });


      // If milestone activity complete or reopen add log
      if (currentActivityMilestone) {
        const initiatives = feathersClient.service("initiatives/onbehalf");
        let message = ''
        if (currentActivityStatus == 2) {
          message = `${activity.name} has been Reopened.`
        } else {
          message = `${activity.name} has been Completed.`
        }
        const payload = {
          addLog: {
            comment: message
          }
        }
        await initiatives.patch(selectedInitiative.id, payload, {
          headers: {
            "x-api-key": apiKey,
            "oauth-accesstoken": tokenString,
          },
        });
      }


      context.commit('UPDATE_SELECTED_INITIATIVE_RUNSTATUS', {
        _id: activity._id,
        runStatus: payload.runStatus
      });

      context.commit('REMOVE_FROM_ACTIVITIES_TO_UPDATE_LIST', activityPayload);

    } catch (err) {
      //restore the old status
      context.commit('UPDATE_SELECTED_INITIATIVE_RUNSTATUS', {
        _id: activity._id,
        runStatus: activity.runStatus
      });
      context.commit('REMOVE_FROM_ACTIVITIES_TO_UPDATE_LIST', activityPayload);
      console.log(err);
    }
  },
  async updateLogs(context) {

    const { apiKey, accessToken } = context.rootState.user.user;
    const selectedInitiative = context.state.selectedInitiative;
    const initiativeActivitiesService = feathersClient.service('initiatives/activities');

    try {

      const tokenString = `Bearer ${accessToken}`;

      let payloadNotes = {}
      if (selectedInitiative.totalTasks === selectedInitiative.completed) {
        payloadNotes.notes = {
          content: `Completed '${selectedInitiative.phase}' phase`,
          accountUserId: context.rootState.user.user.aspenifyUserId,
          notedate: new Date()
        }
        await initiativeActivitiesService.patch(selectedInitiative._id, payloadNotes, {
          headers: {
            "Authorization": tokenString
          },
        });
      }

      //if last activity in the phase
      if (selectedInitiative.totalTasks === selectedInitiative.completed) {
        const initiatives = feathersClient.service("initiatives/onbehalf");
        const payload = {
          addLog: {
            comment: `Completed '${selectedInitiative.phase}' phase`
          }
        }
        let patchedInitiative = await initiatives.patch(selectedInitiative.id, payload, {
          headers: {
            "x-api-key": apiKey,
            "oauth-accesstoken": tokenString,
          },
        });
        // if (patchedInitiative.status == 2) {
        //   const statusPayload = {
        //     statusUpdate: {
        //       comment: "Closing the idea",
        //       status: 3
        //     }
        //   }
        //   await initiatives.patch(selectedInitiative.id, statusPayload, {
        //     headers: {
        //       "x-api-key": apiKey,
        //       "oauth-accesstoken": tokenString,
        //     }
        //   });
        // }
      }
    } catch (err) {


      console.log(err);
    }
  },

  async judgingTeamReview(context, { initiative, feedback }) {

    const user = context.rootState.user.user;
    const { apiKey, accessToken } = context.rootState.user.user;
    const selectedInitiative = context.state.selectedInitiative;
    const initiativeActivitiesService = feathersClient.service('initiatives/activities');

    try {

    } catch (err) {
      console.log(err);
    }
  },
  async archiveInitiative(context, { initiative, comment }) {

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");

    const payload = {
      statusUpdate: {
        comment: `Submission is archived. Note: ${comment}`,
        status: 5
      },
      eventData: {
        type: 'archiveIdea'
      }
    }
    //"Idea is Archived"

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.patch(initiative._id, payload, {
        headers: {
          "Authorization": tokenString
        },
      });

      if (result) {
        let payload = {
          currentPage: 1,
          searchQuery: { status: [1] },
          isFilter: true,
        };
        await context.dispatch('getInitiativesByPhase', payload);
      }
    } catch (err) {
      console.log(err);
      context.dispatch('getInitiativesByPhase');
    }
  },
  async promoteInitiative(context, { initiative, phaseIndex, comment, isCloseFlag}) {
    const { accessToken } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    const initiativeService = feathersClient.service("initiatives/onbehalf");
    const initiativeActivitiesService = feathersClient.service("initiatives/activities");
    try {
      let payload = {
        "initiative": initiative,
        "phaseIndex": phaseIndex,
        "comment": comment,
        "isCloseFlag": isCloseFlag,
      }
      const promoteInitiativeService = feathersClient.service('/initiatives/promote');
      let result = await promoteInitiativeService.create(payload);
      if (result) {
        context.commit('SET_PROMOTE_IN_ACTION_FLAG', false);
        // let payload = {
        //   currentPage: 1,
        //   searchQuery: { status: [1] },
        //   isFilter: true,
        // };
        // if(!isBulkPromote){
        // await context.dispatch('getInitiativesByPhase', payload);
        // }  
        let allInitiatives =await context.dispatch('getAllInitiativesForSwapJudges')
        if(initiative?.subInitiatives?.length > 0){
          for(let subInitiative of initiative.subInitiatives){
            if(subInitiative.status == 1){
              let subInitiativePhases = subInitiative.activities[0];
              let subInitiativeActivities = subInitiative.activities[0].activities;
              let updatedPayload = {
                progress: 0,
                completeActivitystring: "MarkAsComplete",
                actualEndDate: new Date(),
                triggerEvent: true
              }
                updatedPayload.runStatus = 2;
                await initiativeActivitiesService.patch(subInitiativeActivities[0]._id, updatedPayload, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
                await initiativeActivitiesService.patch(subInitiativePhases._id, updatedPayload, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
                let statusPayload = {
                  statusUpdate: {
                    status: 2
                  }
                }
                await initiativeService.patch(subInitiative._id, statusPayload,{"user":context.rootState.user.user});
            }
          }
        }
        if (isCloseFlag && initiative?.subInitiatives?.length > 0) {
          let activeSubInitiatives = initiative.subInitiatives.filter(
            (initiative) => initiative.status == 1
          );
          if (activeSubInitiatives?.length > 0) {
            for (let activeSubInitiative of activeSubInitiatives) {
              await initiativeService.patch(activeSubInitiative._id, {
                status: 3,
                "metaData": {
                  "comment": "Judging is closed as its parent initiative is closed",
                  "date": new Date(),
                  "updatedBy": context.rootState.user.user.aspenifyUserId,
                  "actionType": "closed",
                  "lastUpdatedBy": context.rootState.user.user.aspenifyUserId
                }
              }, {
                headers: {
                  "Authorization": tokenString
                },
              });
            }
          }
        }
        if(allInitiatives?.length>0){
          let filteredIdea = (allInitiatives).filter((idea)=>{
            if(initiative._id == idea._id){
              return idea;
            }
          })[0];
          if(filteredIdea?.activities && filteredIdea?.phaseIndex && filteredIdea.activities[filteredIdea.phaseIndex]){
            if(initiative?.nextPhaseInfo?.metaData?.isJudgingRequired && initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
              filteredIdea.baseId = filteredIdea.judgingPlaybookBaseId;
              filteredIdea.selectedJudgesTeamId = initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId;
              let initiativeData = {
                "initiative": filteredIdea,
                "phaseIndex": filteredIdea.phaseIndex,
                "comment":"",
                isCloseFlag: false
              }
              await context.dispatch("startJudgingInitiative", initiativeData);
              let payload = {
                _id: initiativeData?.initiative?._id,
                metaData: {
                  comment: "Judging is Started!",
                  date: new Date(),
                  updatedBy: context.rootState.user.user.aspenifyUserId,
                  actionType: "start",
                },
              };
              await context.dispatch("updateInitiativeMeta", payload);
            }
          }
        }
      }
      await context.dispatch("readInitiative");
    } catch (err) {
      console.log(err);
      context.commit('SET_PROMOTE_IN_ACTION_FLAG', false);
      context.dispatch('getInitiativesByPhase');
    }
  },
  async unpromoteInitiative(context, { initiative, phaseIndex, comment, isCloseFlag,isBulkUnPromote}) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      let payload = {
        "initiative": initiative,
        "phaseIndex": phaseIndex,
        "comment": comment,
        "isCloseFlag": isCloseFlag,
      }
      const initiativeService = feathersClient.service("initiatives/onbehalf");
      const initiativeActivitiesService = feathersClient.service("initiatives/activities");
      const unpromoteInitiativeService = feathersClient.service('/initiatives/unpromote');
      const subOrgService = feathersClient.service('suborganizations');
      let result = await unpromoteInitiativeService.create(payload);
      if (result) {
        context.commit('SET_UNPROMOTE_IN_ACTION_FLAG', false);
        // let payload = {
        //   currentPage: 1,
        //   searchQuery: { status: [1] },
        //   isFilter: true,
        // };
        // if(!isBulkUnPromote){
        // await context.dispatch('getInitiativesByPhase', payload);
        // }
      }
      let allInitiatives =await context.dispatch('getAllInitiativesForSwapJudges')
      let updatedInitiative = allInitiatives.filter(idea=>idea._id == initiative._id)[0]
      if(updatedInitiative?.subInitiatives?.length > 0){
        for(let subInitiative of updatedInitiative.subInitiatives){
          if(subInitiative.status == 1){
            let subInitiativePhases = subInitiative.activities[0];
            let subInitiativeActivities = subInitiative.activities[0].activities;
            let updatedPayload = {
              progress: 0,
              completeActivitystring: "MarkAsComplete",
              actualEndDate: new Date(),
              triggerEvent: true
            }
            let statusPayload = {
              statusUpdate: {
                comment: "Closing the idea",
                status: 3
              }
            }
            updatedPayload.runStatus = 3;
            await initiativeActivitiesService.patch(subInitiativeActivities[0]._id, updatedPayload, {
              headers: {
                "Authorization": tokenString
              },
            });
            await initiativeService.patch(subInitiative._id, statusPayload,{"user":context.rootState.user.user});
            let data = {     
              "$unset": { "metaData.selectedJudgesTeamId": "" }      
            }
            if(initiative?.phaseInfo?._id){
              let phaseId = initiative.phaseInfo._id;
              let deletedIA = await initiativeActivitiesService.patch(phaseId, data,{
                headers: {
                  "Authorization": tokenString
                }
              });
              let deletdSubOrg = await subOrgService.remove(subInitiative.teamId, {
                query: {
                  "accountId": context.rootState.user.user.accountId,
                  "organizationId": context.rootState.user.user.organizationId,
                },
              });
            }
            if(initiative?.nextPhaseInfo?._id && initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
              let nextPhaseId = initiative.nextPhaseInfo._id;
              let deletedIA = await initiativeActivitiesService.patch(nextPhaseId, data,{
                headers: {
                  "Authorization": tokenString
                }
              });
              let deletdSubOrg = await subOrgService.remove(initiative.nextPhaseInfo.metaData.selectedJudgesTeamId, {
                query: {
                  "accountId": context.rootState.user.user.accountId,
                  "organizationId": context.rootState.user.user.organizationId,
                },
              });
            }
          }
          if(updatedInitiative?.seletedPhaseJudgesDetails?._id){
            if(subInitiative.teamId == updatedInitiative.seletedPhaseJudgesDetails._id){
              let judgesReviewPhaseActivityId = subInitiative?.activities[0]?._id;
              let judgesReviewActivityId = subInitiative?.activities[0]?.activities[0]?._id;
              if(judgesReviewActivityId){
                let payload = {
                  runStatus: 1,
                  string: "reopenActivity"
                }
                await initiativeActivitiesService.patch(judgesReviewActivityId, payload, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
                await initiativeActivitiesService.patch(judgesReviewPhaseActivityId, payload, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
                let statusPayload = {
                  statusUpdate: {
                    status: 1
                  }
                }
                await initiativeService.patch(subInitiative._id, statusPayload, {"user":context.rootState.user.user});
              }
            }
          }
        }
      }
      // if(!isBulkUnPromote){
      // context.dispatch('getInitiativesByPhase', {
      //   currentPage: 1,
      //   searchQuery: { status: [1] },
      //   isFilter: true,
      // });
      // }
      await context.dispatch("readInitiative");
    } catch (err) {
      console.log("Error in unpromote submission ", err);
      context.commit('SET_UNPROMOTE_IN_ACTION_FLAG', false);
      context.dispatch('getInitiativesByPhase');
    }
  },

  async startJudgingInitiative(context, initiativeData) {

    const { apiKey, accessToken } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    const subInitiativeService = feathersClient.service("/initiatives/sub");
    const user = context.rootState.user.user;
    initiativeData.subInitiativeBaseId = initiativeData.initiative.judgingPlaybookBaseId
    initiativeData.subInitiativeTeamId = initiativeData.initiative.selectedJudgesTeamId

    let isSub = initiativeData?.initiative?.subInitiatives?.length !== 0 ? initiativeData?.initiative?.subInitiatives?.length : ''

    let initiative = {
      "id": initiativeData.initiative._id,
      "name": initiativeData.initiative.name + ' Judging ' + isSub,
      "baseId": initiativeData.subInitiativeBaseId,
      "teamId": initiativeData.subInitiativeTeamId,
    }
    await subInitiativeService.create(initiative, {
      headers: {
        "Authorization": tokenString
      },
    });
    // let payload = {
    //   currentPage: 1,
    //   searchQuery: { status: [1] },
    //   isFilter: true,
    // };
    // await context.dispatch('getInitiativesByPhase', payload);
  },
  async closeInitiative(context, id) {

    const { apiKey, accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");

    const payload = {
      statusUpdate: {
        comment: "Cancelling the submission",
        status: 3
      }
    }

    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiatives.patch(id, payload, {
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });

      if (result) {
        context.dispatch('getInitiativesByPhase');
      }
    } catch (err) {
      console.log(err);
      context.dispatch('getInitiativesByPhase');
    }
  },
  async updateInitiative(context, initiativeData) {
    if(initiativeData.newIdea){
      context.commit('SET_LOADING_FLAG', true);
    }
    const { accessToken } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    const initiatives = feathersClient.service("initiatives/onbehalf");
    const addUsersService = feathersClient.service('/accountusers/apiusers');
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
        let payload = {
          updateInitiative: {
            name: initiativeData.name,
            description: initiativeData.description,
            status: initiativeData.status,
            slug: slugify(initiativeData.name),
            picture: initiativeData.picture,
            tags: initiativeData.tags,
            metaData: initiativeData.metaData,
            attachments: initiativeData.attachments,
            priority: initiativeData.priority,
          }
        }
        if (initiativeData.status == 0) {
          let usersArray = initiativeData.metaData.teamLead
            .concat(initiativeData.metaData.teamMembers)
            .concat(initiativeData.metaData.submitter)
            .concat(initiativeData.metaData.mentors);
          let uniqueUsersArray = [...new Map(usersArray.map(user => [user['email'], user])).values()];
          for (let userObj of uniqueUsersArray) {
            userObj.firstName = userObj.name.split(' ').slice(0, -1)[0];
            userObj.lastName = userObj.name.split(' ').slice(0,-1)[1];
            userObj.accountId = context.rootState.user.user.accountId;
            userObj.organizationId = context.rootState.user.user.organizationId;
            userObj.teamId = initiativeData.teamId;
            if (userObj.role == "TeamLead") {
              userObj.userRole = 'Manager'
            }
          }
          let addedUsers = await addUsersService.create(uniqueUsersArray, {
            headers: {
              "Authorization": tokenString
            },
          }, {
            user: context.rootState.user.user
          });
          for (let userData of addedUsers) {
            initiativeData.metaData.teamLead.map((tl) => {
              if (userData.email?.toLowerCase() == tl.email?.toLowerCase()) {
                tl.accountUserId = userData._id;
              }
              delete tl.firstName;
              delete tl.lastName;
              delete tl.accountId;
              delete tl.organizationId;
              delete tl.teamId;
            })
            initiativeData.metaData.teamMembers.map((member) => {
              if (userData.email?.toLowerCase() == member.email?.toLowerCase()) {
                member.accountUserId = userData._id;
              }
              delete member.firstName;
              delete member.lastName;
              delete member.accountId;
              delete member.organizationId;
              delete member.teamId;
            })
            initiativeData.metaData.mentors.map((mentor) => {
              if (userData.email?.toLowerCase() == mentor.email?.toLowerCase()) {
                mentor.accountUserId = userData._id;
              }
              delete mentor.firstName;
              delete mentor.lastName;
              delete mentor.accountId;
              delete mentor.organizationId;
              delete mentor.teamId;
            })
          }
          if (initiativeData?.teamId) {
            payload.teamRoleMapping = [
              { "Submitter": initiativeData.teamId },
              { "Submission POC / Team Lead": initiativeData.teamId },
              { "Team Member": initiativeData.teamId },
              { "Program Lead": process.env.VUE_APP_POWERUSER_TEAM_ID },
              { "Executive - Judge": process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID },//judging team id
              { "Executive - General": process.env.VUE_APP_EXECUTIVE_TEAM_ID },//executive team id
              { "General User - Employee": initiativeData.teamId },
              { "General User - Contractor": initiativeData.teamId },
            ]
          }
        }
        try {
          if (payload.updateInitiative.status == -1) {
            context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', "Submission " + initiativeData.name + " saving as a draft");
            let dataPayload = {
              "header": initiativeData.name,
              "text": 'saved as draft',
              "type": 'success',
              "idea": initiativeData
            }
            context.commit('SET_IDEA_PROCESS_STATUS', dataPayload);
            setTimeout(() => {
              context.commit('SET_IDEA_PROCESS_STATUS', {});
            }, 3000);
          } else if (payload.updateInitiative.status == 0) {
            context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', "Submission " + initiativeData.name + " is being created");
          }
          await initiatives.patch(initiativeData.initiativeId, payload, {
            headers: {
              "Authorization": tokenString
            },
          });
          if(initiativeData.newIdea){
            initiativeData?.router.push({ name: "MyIdeas" })
          await context.dispatch('getMyInitiatives', { currentPage: 1 });
          }
          context.commit('SET_LOADING_FLAG', false);
          console.log("here")
        } catch (err) {
          console.log(err);
          context.commit('SET_LOADING_FLAG', false);
        }
        // context.dispatch('getInitiativesByPhase');
        context.commit('SET_AUTO_INITIATIVE_ACTION_FLAG', false);
    } else {
      alert("“Sorry! The submission window has closed and edits are no longer permitted. Thank you!”");
    }
  },
  async addNote(context, initiativeData) {

    context.commit('SET_ADD_NOTE_FLAG', true);
    const { accessToken } = context.rootState.user.user;
    const { name } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    let accountUserId = context.rootState.user.user.aspenifyUserId;
    const initiativeService = feathersClient.service("initiatives");

    const initiativeDetailsData = await initiativeService.get(initiativeData.initiativeId, {
      headers: {
        "Authorization": tokenString
      },
    });

    const query = {
      _id: initiativeData.initiativeId,
      $select: ['notes', 'initativeLogs'],
      $populate: [
        {
          path: "notes.accountUserId",
          select: "firstName lastName profilePic email",
        },
      ],
    };
    let initiativeLog = {
      "accountUserId": accountUserId,
      "commentDate": new Date()
    }
    // initiativeData.todo contains action to be performed on initiative phase like add/edit/delete.
    if (initiativeData.todo == 'add') {
      //Here we are adding note object at top of the activityDetailsData.notes array.
      let notesPayload = {
        content: initiativeData.note,
        accountUserId: accountUserId,
        notedate: new Date()
      }
      initiativeDetailsData.notes.unshift(notesPayload);
      initiativeLog.comment = `${name} has added comment.`; //Note: ${notesPayload.content}
      initiativeDetailsData.notificationText = `${name} has added comment in an initiative ${initiativeDetailsData.name}.`;
    } else if (initiativeData.todo == 'edit') {
      //Here we are editing note if id matches attaching new edited note to the matching note in initiativeDetailsData.notes array.
      initiativeData.note.notedate = new Date();
      initiativeDetailsData.notes = initiativeDetailsData.notes.map(note => note._id !== initiativeData.note._id ? note : initiativeData.note);
      initiativeLog.comment = `${name} has updated comment.`; //Note: ${initiativeData.note.content}
      initiativeDetailsData.notificationText = `${name} has updated comment in an initiative ${initiativeDetailsData.name}.`;
    } else if (initiativeData.todo == 'delete') {
      //Here we are deleteing note object, finding index in initiativeDetailsData.notes array and then splicing it.
      var removeIndex = initiativeDetailsData.notes.map(note => note._id).indexOf(initiativeData.note._id);
      let comment = initiativeDetailsData.notes[removeIndex].content;
      initiativeDetailsData.notes.splice(removeIndex, 1);
      initiativeLog.comment = `${name} has deleted comment.`; //Note: ${comment}
      initiativeDetailsData.notificationText = `${name} has deleted comment in an initiative ${initiativeDetailsData.name}.`;
    }
    initiativeDetailsData.initativeLogs.unshift(initiativeLog);
    try {
      await initiativeService.patch(initiativeData.initiativeId, initiativeDetailsData, {
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (err) {
      context.commit('SET_ADD_NOTE_FLAG', false);
      console.log(err);
    }
    try {
      const result = await initiativeService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });


      context.commit('SET_ADD_NOTE_FLAG', false);
      if (result.data && result.data[0]) {
        const notes = processNotes(result.data[0].notes);
        const initiativeLogs = result.data[0].initativeLogs;
        context.commit('SET_NOTES_FOR_INITIATIVE', notes);
        context.commit('SET_INIITIATIVELOGS_FOR_INITIATIVE', initiativeLogs);
      }

    } catch (err) {
      console.log(err);
      context.commit('SET_ADD_NOTE_FLAG', false);
    }

  },
  async addEditDeleteNoteInActivity(context, activityData) {

    context.commit('SET_ADD_NOTE_FLAG', true);

    const { accessToken } = context.rootState.user.user;
    const selectedInitiative = context.rootState.initiative.selectedInitiative;
    const { aspenifyUserId } = context.rootState.user.user;
    const { name } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    const activity = feathersClient.service("initiatives/activities");
    let accountUserId = context.rootState.user.user.aspenifyUserId;
    let allInitiatives = context.state.initiatives;
    const query = {
      $select: ['notes'],
      $populate: [{
        path: "notes.accountUserId",
        select: "firstName lastName profilePic email",
      }],
    };

    const activityDetailsData = await activity.get(activityData._id, {
      headers: {
        "Authorization": tokenString
      },
    });
    if (allInitiatives.length > 0) {
      const initiativeSelected = allInitiatives.filter(
        (initiative) => initiative._id == activityDetailsData.initiativeId
      );
      activityDetailsData.initiativeId = initiativeSelected[0];
    }
    let eventData = {
      "userId": aspenifyUserId,
      "initiativeId": activityDetailsData.initiativeId,
      "activityId": activityDetailsData._id,
      "event": "customEvent"
    }
    // activityData.todo contains action to be performed on initiative phase like add/edit/delete.
    if (activityData.todo == 'add') {
      //Here we are adding note object at top of the activityDetailsData.notes array.
      let notesPayload = {
        content: activityData.note,
        accountUserId: accountUserId,
        notedate: new Date()
      }
      activityDetailsData.notes.unshift(notesPayload);
      activityDetailsData.string = 'commentAdded';
      activityDetailsData.text = activityData.note;
      eventData.type = 'commentAdded';
      eventData.notificationText = `${name} has added comment under ${activityDetailsData.name} in an initiative ${selectedInitiative.name}`;
    } else if (activityData.todo == 'edit') {
      //Here we are editing note if id matches attaching new edited note to the matching note in activityDetailsData.notes array.
      activityData.note.notedate = new Date();
      activityDetailsData.notes = activityDetailsData.notes.map(note => note._id !== activityData.note._id ? note : activityData.note);
      activityDetailsData.string = 'commentUpdated';
      activityDetailsData.text = activityData.note.content;
      eventData.type = 'commentUpdated';
      eventData.notificationText = `${name} has updated comment under ${activityDetailsData.name} in an initiative ${selectedInitiative.name}`;
    } else if (activityData.todo == 'delete') {
      //Here we are deleting note object, finding index in activityDetailsData.notes array and then splicing it.
      var removeIndex = activityDetailsData.notes.map(note => note._id).indexOf(activityData.note._id);
      activityDetailsData.text = activityDetailsData.notes[removeIndex].content;
      activityDetailsData.notes.splice(removeIndex, 1);
      activityDetailsData.string = 'commentDeleted';
      eventData.type = 'commentDeleted';
      eventData.notificationText = `${name} has deleted comment under ${activityDetailsData.name} in an initiative ${selectedInitiative.name}`;
    }
    activityDetailsData.eventData = eventData;
    try {
      await activity.patch(activityData._id, activityDetailsData, {
        headers: {
          "Authorization": tokenString
        },
      });

      const result = await activity.get(activityDetailsData._id, {
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      context.commit('SET_ADD_NOTE_FLAG', false);
      if (result && result.notes) {
        const notes = processNotes(result.notes);
        context.commit('SET_NOTES_FOR_INITIATIVE', notes);
      }

    } catch (err) {
      context.commit('SET_ADD_NOTE_FLAG', false);
      console.log(err);
    }

  },
  async updateIdeaTeam(context, initiativeData) {

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");

    let payload = {
      updateMetaData: {
        metaData: initiativeData.metaData
      }
    }

    const tokenString = `Bearer ${accessToken}`;

    try {
      await initiatives.patch(initiativeData.initiativeId, payload, {
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (err) {
      console.log(err);
      context.dispatch('getInitiativeDetailsById', initiativeData.initiativeId)
    }
  },
  async updateInitiativeStatus(context, initiativeData) {

    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");

    let payload = {
      updateMetaData: {
        metaData: {}
      }
    }
    if (initiativeData.status) {
      payload.status = initiativeData.status
    }

    const tokenString = `Bearer ${accessToken}`;
    try {

      await initiatives.patch(initiativeData._id, payload, {
        headers: {
          "Authorization": tokenString
        },
      });

      // await context.dispatch("getInitiativeDetailsById", initiativeData._id);
      // await context.dispatch("getInitiatives");
      if (initiativeData.refreshBoard) {
        let payload = {
          currentPage: 1,
          searchQuery: { status: [1] },
          isFilter: true,
        };
        await context.dispatch('getInitiativesByPhase', payload);
      }

    } catch (err) {
      console.log(err);
      context.dispatch('getInitiativeDetailsById', initiativeData.initiativeId)
    }
  },
  async updateJudgingIdeaStatusActivities(context, payload) {

    const { accessToken } = context.rootState.user.user;
    const initiativeActivitiesService = feathersClient.service("initiatives/activities"
    );

    const tokenString = `Bearer ${accessToken}`;
    try {

      await initiativeActivitiesService.patch(payload.id, {
        $set: {
          metaData: payload.updateFeedback
        }
      }, { headers: { Authorization: tokenString } }
      );

      // await context.dispatch("getInitiativeDetailsById", payload?.idea?._id);
      // if (initiativeData.refreshBoard) {
      //   await context.dispatch('getInitiativesByPhase');
      // }

    } catch (err) {
      console.log(err);
      // context.dispatch('getInitiativeDetailsById', initiativeData.initiativeId)
    }
  },

  async openmicAction(context, initiativeData) {
    const { apiKey, accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives/onbehalf");
    const organizationsWso = feathersClient.service("organizations/owners");
    let organizationId = context.rootState.user.user.organizationId;
    let payload = {
      updateMetaData: {
        metaData: initiativeData.metaData
      }
    }
    // const wsoDetails = await organizationsWso.get(organizationId);
    let eventDataType = initiativeData?.event
    payload.eventData = {
      "type": eventDataType,
      "senderName": initiativeData?.user?.name,
      "ideaName": initiativeData.name
    }


    let msg = ''
    let su = initiativeData?.metaData?.signUpForOpenmic;
    let rA = initiativeData?.metaData?.registerArchitecture;
    let rB = initiativeData?.metaData?.registerBuildingBlock;
    let rS = initiativeData?.metaData?.registerExtendedStaff;

    switch (initiativeData.actionType) {
      case 'signUpOpenMic':
        msg = `Open Mic is Sign-up by ${su[su.length - 1]?.userComment?.commentBy?.name} Note:${su[su.length - 1]?.userComment?.comment}`
        break;
      case 'configureSignUp':
        msg = `Open Mic is ${initiativeData.event === 'rescheduleOpenMic' ? 'Rescheduled' : 'Scheduled'} on ${format(new Date(su[su.length - 1]?.configure?.scheduledOn?.date), "mediumDate")} from ${format(new Date(su[su.length - 1]?.configure?.scheduledOn?.from), "hh:mm A")} to ${format(new Date(su[su.length - 1]?.configure?.scheduledOn?.to), "hh:mm A")} by ${su[su.length - 1]?.configure?.scheduledBy?.name}`
        break;
      case 'signUpCancelled':
        msg = `Open Mic is Cancelled by ${su[su.length - 1]?.userComment?.commentBy?.name} Note:${su[su.length - 1]?.userComment?.comment}`
        break;
      case 'signUpCompleted':
        msg = `Open Mic is Marked completed by  ${su[su.length - 1]?.userComment?.commentBy?.name} Note:${su[su.length - 1]?.markComplete?.comment}`
        break;

      case 'registerArchitecture':
        msg = `Architecture review is register by ${rA[rA.length - 1]?.userComment?.commentBy?.name} Note:${rA[rA.length - 1]?.userComment?.comment}`
        break;
      case 'configureArchitecture':
        msg = `Architecture review is ${initiativeData.event === 'rescheduleArchitecture' ? 'Rescheduled' : 'Scheduled'} on ${format(new Date(rA[rA.length - 1]?.configure?.scheduledOn?.date), "mediumDate")} from ${format(new Date(rA[rA.length - 1]?.configure?.scheduledOn?.from), "hh:mm A")} to ${format(new Date(rA[rA.length - 1]?.configure?.scheduledOn?.to), "hh:mm A")} by ${rA[rA.length - 1]?.configure?.scheduledBy?.name}`
        break;
      case 'ArchitectureCancelled':
        msg = `Architecture review is Cancelled by ${rA[rA.length - 1]?.userComment?.commentBy?.name} Note:${rA[rA.length - 1]?.userComment?.comment}`
        break;
      case 'ArchitectureComplete':
        msg = `Architecture review Marked completed by  ${rA[rA.length - 1]?.userComment?.commentBy?.name} Note:${rA[rA.length - 1]?.markComplete?.comment}`
        break;

      case 'registerBuildingBlock':
        msg = `Building block is register by ${rB[rB.length - 1]?.userComment?.commentBy?.name} Note:${rB[rB.length - 1]?.userComment?.comment}`
        break;
      case 'configureBuildingBlock':
        msg = `Building block is ${initiativeData.event === 'rescheduleBuildingBlock' ? 'Rescheduled' : 'Scheduled'} on ${format(new Date(rB[rB.length - 1]?.configure?.scheduledOn?.date), "mediumDate")} from ${format(new Date(rB[rB.length - 1]?.configure?.scheduledOn?.from), "hh:mm A")} to ${format(new Date(rB[rB.length - 1]?.configure?.scheduledOn?.to), "hh:mm A")} by ${rB[rB.length - 1]?.configure?.scheduledBy?.name}`
        break;
      case 'BuildingBlockCancelled':
        msg = `Building block is Cancelled by ${rB[rB.length - 1]?.userComment?.commentBy?.name} Note:${rB[rB.length - 1]?.userComment?.comment}`
        break;
      case 'BuildingBlockComplete':
        msg = `Building block Marked completed by  ${rB[rB.length - 1]?.userComment?.commentBy?.name} Note:${rB[rB.length - 1]?.markComplete?.comment}`
        break;


      case 'registerExtendedStaff':
        msg = `Building block is register by ${rS[rS.length - 1]?.userComment?.commentBy?.name} Note:${rS[rS.length - 1]?.userComment?.comment}`
        break;
      case 'configureStaff':
        msg = `Building block is ${initiativeData.event === 'rescheduleStaff' ? 'Rescheduled' : 'Scheduled'} on ${format(new Date(rS[rS.length - 1]?.configure?.scheduledOn?.date), "mediumDate")} from ${format(new Date(rS[rS.length - 1]?.configure?.scheduledOn?.from), "hh:mm A")} to ${format(new Date(rS[rS.length - 1]?.configure?.scheduledOn?.to), "hh:mm A")} by ${rS[rS.length - 1]?.configure?.scheduledBy?.name}`
        break;
      case 'StaffCancelled':
        msg = `Building block is Cancelled by ${rS[rS.length - 1]?.userComment?.commentBy?.name} Note:${rS[rS.length - 1]?.userComment?.comment}`
        break;
      case 'StaffComplete':
        msg = `Building block Marked completed by  ${rS[rS.length - 1]?.userComment?.commentBy?.name} Note:${rS[rS.length - 1]?.markComplete?.comment}`
        break;

      default:
        break;
    }

    let payload2 = {
      addLog: {
        comment: msg
      }
    }

    const tokenString = `Bearer ${accessToken}`;
    try {
      await initiatives.patch(initiativeData._id, payload, {
        headers: {
          "Authorization": tokenString
        },
      });

      await initiatives.patch(initiativeData._id, payload2, {
        headers: {
          "Authorization": tokenString
        },
      });

      await initiatives.patch(initiativeData._id, { payload }, {
        headers: {
          "Authorization": tokenString
        },
      });

    } catch (err) {
      console.log(err);
      context.dispatch('getInitiativeDetailsById', initiativeData.initiativeId)
    }
  },
  async viewAttachment(context, payload) {
    try {
      const { apiKey, accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const metaFields = ["potentialMarket","aboutTeam","elevatorPitch","testimonialAwardsIpAndOpenSource","problemDescription"];
      payload.isPublic = true;
      payload.accountId = context.rootState.user.user.accountId;

      let documentName = payload.documentName;
      let metaFieldFile = false;
      let metaFieldName = null;
      metaFields.forEach((field)=> {
          if(documentName.endsWith(field)){
            metaFieldFile = true;
            metaFieldName = "." + field;
          }
      });

      if(metaFieldFile){
        documentName = documentName.split(metaFieldName)[0]
      }

      let fileExtension = documentName.split('.').pop().toLowerCase();

      let fileName = payload.documentId + "." + fileExtension;
      let query = {
        fileName: documentName,

      }
      if (payload.isPublic) {
        query.accountId = "public";
        query.isPublic = true;
      }
      query.fileName = documentName;
      query.documentId = fileName;
      // const downloadFileService = feathersClient.service('downloads');
      //   let fileData = await downloadFileService.find({
      //   query: query
      // });
      let fileUploadUrl = process.env.VUE_APP_FILEUPLOADURL
      let url = `${fileUploadUrl}/${payload.accountId}/${query.fileName}/${query.documentId}`
      let fileData = await axios.get(url,
        {
          headers: {
            Authorization: tokenString,
          },
        });
      let data = `data:image/png;base64, ${fileData.data}`;
      context.commit('VIEW_ATTACHMENT_BASE64', data);
      context.commit('UPDATE_ATTACHMENT_POPUP_FLAG', true);
    } catch (e) {
      console.warn("Error while downloading attachment -: " + e);
      throw e;
    }
  },
  async getAttachmentForPdf(context, payload) {
    try {
      const { apiKey, accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const metaFields = ["potentialMarket","aboutTeam","elevatorPitch","testimonialAwardsIpAndOpenSource","problemDescription"];
      payload.isPublic = true;
      payload.accountId = context.rootState.user.user.accountId;

      let documentName = payload.documentName;
      let metaFieldFile = false;
      let metaFieldName = null;
      metaFields.forEach((field)=> {
          if(documentName.endsWith(field)){
            metaFieldFile = true;
            metaFieldName = "." + field;
          }
      });

      if(metaFieldFile){
        documentName = documentName.split(metaFieldName)[0]
      }

      let fileExtension = documentName.split('.').pop().toLowerCase();

      let fileName = payload.documentId + "." + fileExtension;
      let query = {
        fileName: documentName,

      }
      if (payload.isPublic) {
        query.accountId = "public";
        query.isPublic = true;
      }
      query.fileName = documentName;
      query.documentId = fileName;
      // const downloadFileService = feathersClient.service('downloads');
      //   let fileData = await downloadFileService.find({
      //   query: query
      // });
      let fileUploadUrl = process.env.VUE_APP_FILEUPLOADURL
      let url = `${fileUploadUrl}/${payload.accountId}/${query.fileName}/${query.documentId}`
      let fileData = await axios.get(url,
        {
          headers: {
            Authorization: tokenString,
          },
        });
      let data = `data:image/png;base64, ${fileData.data}`;
      return data;
    } catch (e) {
      console.warn("Error while downloading attachment -: " + e);
      throw e;
    }
  },

  async downloadAttachment(context, payload) {
    try {
      const metaFields = ["potentialMarket","aboutTeam","elevatorPitch","testimonialAwardsIpAndOpenSource","problemDescription"];
      payload.isPublic = true;
      payload.accountId = context.rootState.user.user.accountId;

      let documentName = payload.documentName;
      console.log("🚀 ~ file: initiative.js ~ line 3376 ~ downloadAttachment ~ documentName", documentName)
      let metaFieldFile = false;
      let metaFieldName = null;
      metaFields.forEach((field)=> {
          if(documentName.endsWith(field)){
            metaFieldFile = true;
            metaFieldName = "." + field;
          }
      });

      if(metaFieldFile){
        documentName = documentName.split(metaFieldName)[0]
      }

      let fileExtension = documentName.split('.').pop().toLowerCase();

      let fileName = payload.documentId + "." + fileExtension;
      let query = {
        fileName: documentName,

      }
      if (payload.isPublic) {
        query.accountId = "public";
        query.isPublic = true;
      }
      const downloadFileService = feathersClient.service('downloads');
      let fileData = await downloadFileService.get(fileName, {
        query: query
      });

      let a = document.createElement('a');
      a.href = fileData.uri.href;
      a.download = documentName;
      a.click();
      context.commit('UPDATE_ATTACHMENT_POPUP_FLAG', false);
      context.commit('VIEW_ATTACHMENT_BASE64', "");
    } catch (e) {
      console.warn("Error while downloading attachment -: " + e);
      context.commit('UPDATE_ATTACHMENT_POPUP_FLAG', false);
      context.commit('VIEW_ATTACHMENT_BASE64', "");
      throw e;
    }
  },
  async deleteAttachment(context, payload) {
    try {
      let service = payload.service;
      // payload.attachments.splice(payload.index, 1);
      let filteredAttachments = payload.attachments.filter((item,index) => index !== payload.index);
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const { name } = context.rootState.user.user;
      //
      let initiativeLog = {
        "accountUserId": context.rootState.user.user.aspenifyUserId,
        "commentDate": new Date()
      }
      let action;

      initiativeLog.comment = `${name} has deleted an attachment.`;
      const initiativeDetailsData = await service.get(
        payload.idToUpdate,
        {
          headers: {
            Authorization: tokenString,
          },
        }
      );
      initiativeDetailsData.initativeLogs.unshift(initiativeLog);
      service.patch(payload.idToUpdate, {
        "$set": {
          "attachments": filteredAttachments
        },
        initativeLogs: initiativeDetailsData.initativeLogs,
        "string": "attachmentDeleted"
      }, {
        headers: {
          "Authorization": tokenString
        }
      });
      context.commit('UPDATE_INITIATE_FOR_DELETED_ATTACHMENTS', payload);
    } catch (err) {
      console.log(err)
    }
  },
  async getInitiativeEvents(context, id) {

    context.commit('SET_INITIATIVE_EVENTS', []);
    context.commit('SET_INITIATIVE_EVENTS_LOADING_FLAG', true);

    const { accessToken } = context.rootState.user.user;
    const eventLogsService = feathersClient.service("eventlogs");

    const tokenString = `Bearer ${accessToken}`;
    try {

      const selectedInitiative = context.state.selectedInitiative;
      const activityIds = [...selectedInitiative.activitiesDataMap.keys()];

      const eventLogsQuery = {
        "data.initiative": selectedInitiative.id,
        $select: ['type', 'targetId', 'createdAt', 'data'],
        $sort: {
          createdAt: 1,
        },
        $populate: [
          {
            path: 'userId',
            select: '_id firstName lastName profilePic email',
          }
        ]
      };

      const events = await eventLogsService.find({
        query: eventLogsQuery,
        headers: {
          "Authorization": tokenString
        },
      });


      if (events.data && events.data.length > 0) {

        const eventsData = [];

        events.data.forEach(function (event) {
          const activityId = event.targetId;
          if (activityIds.includes(activityId) || selectedInitiative._id) {
            const activityDetails = context.state.selectedInitiative.activitiesDataMap.get(activityId);
            const eventData = {
              activityId,
              activityName: activityDetails ? activityDetails.name : selectedInitiative.phase ? selectedInitiative.phase : null,
              type: event.type,
              createdAt: event.createdAt,
              user: {
                userName: `${event.userId.firstName} ${event.userId.lastName}`,
                profilePic: event.userId.profilePic,
                email: event.userId.email?.toLowerCase(),
                id: event.userId._id,
              },
              displayText: event.data && event.data.text ? event.data.text : ""
            }
            eventsData.push(eventData);
          }
        });
        context.commit('SET_INITIATIVE_EVENTS', eventsData);
      }
      context.commit('SET_INITIATIVE_EVENTS_LOADING_FLAG', false);

    } catch (err) {
      console.log(err);
      context.commit('SET_INITIATIVE_EVENTS_LOADING_FLAG', false);
    }
  },
  resetActivitiesToUpdateList(context) {
    context.commit('RESET_ACTIVITIES_TO_UPDATE_LIST');
  },
  addToActivitiesToUpdateList(context, payload) {
    context.commit('ADD_TO_ACTIVITIES_TO_UPDATE_LIST', payload);
  },
  removeFromActivitiesToUpdateList(context, payload) {
    context.commit('REMOVE_FROM_ACTIVITIES_TO_UPDATE_LIST', payload);
  },
  async exportIdeas(context, payload) {
    try {
      context.commit('SET_EXPORT_LOADING', true);
      const baseId = process.env.VUE_APP_BASE_ID;
      const {accessToken} = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const initiativeService = feathersClient.service("initiatives");
      const ExportExcel = feathersClient.service("/export/excel");

      let initiatives = await initiativeService.find({
        "query": {
          'baseId': baseId,
          'metaData': { "$exists": true },
          'status': { $in: [-1, 0, 1, 2, 3, 5, 6] },
          "$populate": [{
            "path": "activities",
            "select": "name runStatus"
          }]
        },
        "headers": {
          "Authorization": tokenString
        }
      });
      let dataToExport = [];
      for (let initiative of initiatives.data) {
        let initiativeActivity = initiative.activities.filter(activity=>activity.runStatus==1);
        initiative.phase = initiativeActivity.length>0 ? initiativeActivity[0].name : "";
        let statusLabel = ""
        switch (initiative.status) {
          case -1:
            statusLabel = "Draft";
            break;
          case 0:
            statusLabel = "In Review";
            break;
          case 1:
            statusLabel = "In Progress";
            break;
          case 2:
            statusLabel = "Complete";
            break;
          case 3:
            statusLabel = "Closed";
            break;
          case 5:
            statusLabel = "Archived";
            break;
          case 6:
            statusLabel = "Inactive";
            break;
          case 7:
            statusLabel = "Merged";
            break;
          default:
          // code block
        }
        initiative.id = initiative._id.toString();
        initiative.createdAt = moment(initiative.createdAt).format('YYYY-MM-DD');
        initiative.updatedAt = moment(initiative.updatedAt).format('YYYY-MM-DD');
        initiative.priority = initiative.priority ? initiative.priority : "";
        initiative.commaSeparatedTeamMembers = '';
        initiative.commaSeparatedTeamLead = '';
        initiative.commaSeparatedMentors = '';
        initiative.commaSeparatedChallengeTypes = '';
        initiative.commaSeparatedTags = '';
        initiative.commaSeparatedTalents = '';
        initiative.commaSeparatedReferences = '';
        initiative.commaSeperatedAttachments = 0;
        initiative.commaSeparatedBUFuction = '';
        initiative.commaSeparatedExecSponsors = '';
        initiative.description=initiative.description?stripHtml(initiative.description).result:""
        if (initiative.metaData) {
          initiative.elevatorPitch =  initiative.metaData.elevatorPitch?stripHtml(initiative.metaData.elevatorPitch).result:"";
          initiative.marketHorizon = initiative.metaData.marketHorizon;
          if(typeof initiative.metaData.source == 'string'){
            initiative.source = initiative.metaData.source;
          }
          initiative.etiFocusArea = initiative.metaData.etiFocusArea;
          initiative.problemDescription = initiative.metaData.problemDescription?stripHtml(initiative.metaData.problemDescription).result:"";
          initiative.potentialMarket = initiative.metaData.potentialMarket?stripHtml(initiative.metaData.potentialMarket).result:"";
          initiative.aboutTeam = initiative.metaData.aboutTeam?stripHtml(initiative.metaData.aboutTeam).result:"";
          initiative.progress = initiative.metaData.progress;
          initiative.bogFramework = initiative.metaData?.bogFramework ? initiative.metaData.bogFramework : "NA";
          if(initiative.metaData?.isTalentRequired){
            initiative.isTalentRequired = 'Yes';
            if (initiative.metaData && initiative.metaData.talent && initiative.metaData.talent.length > 0) {
              for (let [i, talent] of initiative.metaData.talent.entries()) {
                if (i == (initiative.metaData.talent.length - 1)) {
                  initiative.commaSeparatedTalents += talent;
                } else {
                  initiative.commaSeparatedTalents += talent + ', ';
                }    
              }
            }
          }
        }
        if(initiative?.metaData?.submitter?.length>0){
          let createdByDisplayName = initiative.metaData.submitter[0].name;
          let displayName = initiative.metaData.submitter[0].email.split("@")[0];
          if(createdByDisplayName?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
            initiative.createdByName = `${createdByDisplayName}`
          } else {
            initiative.createdByName = `${createdByDisplayName} (${displayName})`;
          }
        }
        if(statusLabel == "Inactive"){
          initiative.status = "Inactive";
        } else if(statusLabel == "Draft"){
          initiative.status = "Draft";
        } else if(statusLabel == "Merged"){
          initiative.status = "Merged";
        } else {
          initiative.status = "Active";
        }
        initiative.ventureTermsCondition = initiative.metaData.ventureTermsCondition ? "Yes" : "No";
        initiative.origIdeaTermsCodition = initiative.metaData.origIdeaTermsCodition ? "Yes" : "No";
        if (initiative.metaData && initiative.metaData.teamLead && initiative.metaData.teamLead.length > 0) {
          for (let [i, lead] of initiative.metaData.teamLead.entries()) {
            let displayName = lead.email.split("@")[0];
            if (i == (initiative.metaData.teamLead.length - 1)) {
              if(lead.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedTeamLead += `${lead.name}`
              } else {
                initiative.commaSeparatedTeamLead += `${lead.name} (${displayName})`;
              }
            } else {
              if(lead.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedTeamLead += `${lead.name}`+ ', '
              } else {
                initiative.commaSeparatedTeamLead += `${lead.name} (${displayName})` + ', ';
              }
            }
          }
        }
        if (initiative.metaData && initiative.metaData.teamMembers && initiative.metaData.teamMembers.length > 0) {
          for (let [i, member] of initiative.metaData.teamMembers.entries()) {
            let displayName = member?.email?.split("@")[0];
            if (i == (initiative.metaData.teamMembers.length - 1)) {
              if(member.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedTeamMembers += `${member.name}`
              } else {
                initiative.commaSeparatedTeamMembers += `${member.name} (${displayName})`;
              }
            } else {
              if(member.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedTeamMembers += `${member.name}`+ ', '
              } else {
                initiative.commaSeparatedTeamMembers += `${member.name} (${displayName})` + ', ';
              }
            }
          }
        }
        if (initiative?.metaData?.execSponsor?.length>0) {
          for (let [i, sponsor] of initiative.metaData.execSponsor.entries()) {
            let displayName = sponsor.email.split("@")[0];
            if (i == (initiative.metaData.execSponsor.length - 1)) {
              if(sponsor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedExecSponsors += `${sponsor.name}`
              } else {
                initiative.commaSeparatedExecSponsors += `${sponsor.name} (${displayName})`;
              }
            } else {
              if(sponsor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedExecSponsors += `${sponsor.name}`+ ', '
              } else {
                initiative.commaSeparatedExecSponsors += `${sponsor.name} (${displayName})` + ', ';
              }
            }
          }
        }
        if (initiative.metaData && initiative.metaData.challengeType && typeof initiative.metaData.challengeType == 'object' && initiative.metaData.challengeType.length > 0) {
          for (let [i, type] of initiative.metaData.challengeType.entries()) {
            if (i == (initiative.metaData.challengeType.length - 1)) {
              initiative.commaSeparatedChallengeTypes += type;
            } else {
              initiative.commaSeparatedChallengeTypes += type + ', ';
            }
  
          }
        } else if (initiative.metaData && initiative.metaData.challengeType && typeof initiative.metaData.challengeType == 'string') {
          initiative.commaSeparatedChallengeTypes = initiative.metaData.challengeType;
        }
  
        if (initiative.metaData && initiative.tags && typeof initiative.tags == 'object' && initiative.tags.length > 0) {
          for (let [i, type] of initiative.tags.entries()) {
            if (i == (initiative.tags.length - 1)) {
              initiative.commaSeparatedTags += type;
            } else {
              initiative.commaSeparatedTags += type + ', ';
            }
  
          }
        } else if (initiative.metaData && initiative.tags && typeof initiative.tags == 'string') {
          initiative.commaSeparatedTags = initiative.tags;
        }
        if (initiative.metaData && initiative.metaData && initiative.metaData.coreTeamSize && typeof initiative.metaData.coreTeamSize=='string') {
          initiative.coreTeamSize = initiative?.metaData?.coreTeamSize;
        } else if(initiative.metaData && initiative.metaData && initiative.metaData.coreTeamSize){
          initiative.coreTeamSize = initiative?.metaData?.coreTeamSize.toString();
        }
        if (initiative.metaData && initiative.metaData && initiative.metaData.revenueDate) {
          initiative.revenueDate = initiative?.metaData?.revenueDate;
        }
        if (initiative.metaData && initiative.metaData && initiative.metaData.fcsDate) {
          initiative.fcsDate = moment(initiative?.metaData?.fcsDate).format('YYYY-MM-DD');
        }
        // if (initiative.metaData && initiative.metaData && initiative.metaData.activationDate && typeof initiative.metaData.activationDate=='string') {
        //   initiative.activationDate = initiative?.metaData?.activationDate;
        // } else if (initiative.metaData && initiative.metaData && initiative.metaData.activationDate) {
        //   initiative.activationDate = initiative?.metaData?.activationDate.toString();
        // }
        if (initiative.metaData && initiative.metaData.mentors && initiative.metaData.mentors.length > 0) {
          for (let [i, mentor] of initiative.metaData.mentors.entries()) {
            let displayName = mentor.email.split("@")[0];
            if (i == (initiative.metaData.mentors.length - 1)) {
              if(mentor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedMentors += `${mentor.name}`
              } else {
                initiative.commaSeparatedMentors += `${mentor.name} (${displayName})`;
              }
            } else {
              if(mentor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                initiative.commaSeparatedMentors += `${mentor.name}`+ ', '
              } else {
                initiative.commaSeparatedMentors += `${mentor.name} (${displayName})` + ', ';
              }
            }
          }
        }
        if (initiative?.attachments && initiative.attachments.length > 0) {
          let attachmentsCount = initiative.attachments.filter((singleAttachment)=>{
            return !singleAttachment.documentUrl
          }).length;
          initiative.commaSeperatedAttachments=attachmentsCount;
          initiative.attachments = initiative.attachments.filter((data) => {
            if (data.documentUrl) {
              return data;
            }
          });
          for (let [i, attachment] of initiative.attachments.entries()) {
            if(attachment.documentUrl){
              if (i == (initiative.attachments.length - 1)) {
                initiative.commaSeparatedReferences += attachment.documentUrl;
              } else {
                initiative.commaSeparatedReferences += attachment.documentUrl + ', ';
              }
            }
          }
        }
        let currentURL = window.location.href;
        initiative.link = `${currentURL}/details/${initiative.id}`
        if(initiative?.metaData?.managementFields){
          if(initiative.metaData.managementFields?.opsPOC?.email){
            let displayName = initiative.metaData.managementFields.opsPOC.email.split("@")[0];
            if(initiative.metaData.managementFields.opsPOC.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
              initiative.opsPOC = `${initiative.metaData.managementFields.opsPOC.name}`;
            } else {
              initiative.opsPOC = `${initiative.metaData.managementFields.opsPOC.name} (${displayName})`;
            }
          }
          if(initiative.metaData.managementFields?.pmPOC?.email){
            let displayName = initiative.metaData.managementFields.pmPOC.email.split("@")[0];
            if(initiative.metaData.managementFields.pmPOC.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
              initiative.pmPOC = `${initiative.metaData.managementFields.pmPOC.name}`;
            } else {
              initiative.pmPOC = `${initiative.metaData.managementFields.pmPOC.name} (${displayName})`;
            }
          }
          if (initiative?.metaData?.managementFields?.execSponsors?.length>0) {
            for (let [i, sponsor] of initiative.metaData.managementFields.execSponsors.entries()) {
              let displayName = sponsor.email.split("@")[0];
              if (i == (initiative.metaData.managementFields.execSponsors.length - 1)) {
                if(sponsor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                  initiative.commaSeparatedExecSponsors += `${sponsor.name}`
                } else {
                  initiative.commaSeparatedExecSponsors += `${sponsor.name} (${displayName})`;
                }
              } else {
                if(sponsor.name?.toLowerCase().includes(`(${displayName?.toLowerCase()})`)){
                  initiative.commaSeparatedExecSponsors += `${sponsor.name}`+ ', '
                } else {
                  initiative.commaSeparatedExecSponsors += `${sponsor.name} (${displayName})` + ', ';
                }
              }
            }
          }
          if(initiative.metaData.managementFields?.progress){
            initiative.managementProgress = initiative.metaData.managementFields.progress;
          }
          if(initiative.metaData.managementFields?.notes){
            initiative.managementNotes = initiative.metaData.managementFields.notes;
          }
          if(initiative.metaData.managementFields?.buAction){
            for (let [i, buAction] of initiative.metaData.managementFields.buAction.entries()) {
              if (i == (initiative.metaData.managementFields.buAction.length - 1)) {
                initiative.commaSeparatedBUFuction += buAction;
              } else {
                initiative.commaSeparatedBUFuction += buAction + ', ';
              }  
            }
          }
        }
        if(initiative?.metaData?.signUpForOpenmic?.length>0){
          initiative.metaData.signUpForOpenmic = initiative.metaData.signUpForOpenmic.filter((data) => {
            if (data.markComplete) {
              return data;
            }
          });
          let signupLength = initiative.metaData.signUpForOpenmic.length;
          if(signupLength == 1){
            initiative.lastOpenMic = initiative.metaData.signUpForOpenmic[0]?.markComplete?.date ? moment(initiative.metaData.signUpForOpenmic[0].markComplete.date).format('YYYY-MM-DD') : "";
          } else {
            initiative.lastOpenMic = initiative.metaData.signUpForOpenmic[signupLength-1]?.markComplete?.date ? moment(initiative.metaData.signUpForOpenmic[signupLength-1].markComplete.date).format('YYYY-MM-DD') : "";
            initiative.previousOpenMic = initiative.metaData.signUpForOpenmic[signupLength-2]?.markComplete?.date ? moment(initiative.metaData.signUpForOpenmic[signupLength-2].markComplete.date).format('YYYY-MM-DD') : "";
          }
        }
        dataToExport.push(initiative);
      }
      var columnsNames = [
        { header: 'Give your idea a title*', key: 'name', width: 25 },
        { header: 'Link', key: 'link', width: 35 },
        { header: 'Created By', key: 'createdByName', width: 20 },
        { header: 'Created At', key: 'createdAt', width: 20 },
        { header: 'Edited At', key: 'updatedAt', width: 20 },
        { header: 'Status', key: 'status', width: 20 },
        { header: 'Risks*', key: 'elevatorPitch', width: 50 },
        { header: 'Value Proposition & Top 3 Innovations*', key: 'problemDescription', width: 50 },
        { header: 'Problem Statement*', key: 'description', width: 50 },
        { header: 'Results*', key: 'potentialMarket', width: 35 },
        { header: 'Launch/GA Date', key: 'fcsDate', width: 20 },
        { header: 'Revenue to-date', key: 'revenueDate', width: 20 },
        // { header: 'User count/Activation to-date', key: 'activationDate', width: 20 },
        { header: 'Teamwork, Partnerships, Reuse of Technology and Open Source*', key: 'aboutTeam', width: 35 },
        { header: 'Thumbnail', key: 'picture', width: 50 },
        { header: 'Phase', key: 'phase', width: 25 },
        { header: 'Category', key: 'commaSeparatedChallengeTypes', width: 20 },
        { header: 'Core Team Size', key: 'coreTeamSize', width: 20 },
        { header: 'Team Members', key: 'commaSeparatedTeamMembers', width: 35 },
        { header: 'Team Lead*', key: 'commaSeparatedTeamLead', width: 35 },
        { header: 'Exec Sponsor', key: 'commaSeparatedExecSponsors', width: 35 },
        { header: 'Tags', key: 'commaSeparatedTags', width: 15 },
        { header: 'Attachment Count', key: 'commaSeperatedAttachments', width: 15},
        { header: 'Id', key: 'id', width: 30 }
      ]

      let excelData = {};
        excelData.columnsNames = columnsNames;
        excelData.dataToExport = dataToExport;
        excelData.fileName = 'Submissions';
        let exportData = await ExportExcel.create(excelData);
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
        var blob = new Blob([s2ab(atob(exportData))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Submissions.xlsx';
        link.click();
      context.commit('SET_EXPORT_LOADING', false);
    } catch (err) {
      console.log("Error in Exporting Ideas ", err);
      context.commit('SET_EXPORT_LOADING', false);
    }
  },
  async exportJudgingData(context) {
    try {
      let columnsNames = [
        { header: 'Submission Title', key: 'ideaName', width: 25 },
        { header: 'Category', key: 'category', width: 25 },
        { header: 'Phase', key: 'phase', width: 25 },
        { header: 'Status', key: 'status', width: 15 },
        { header: 'Judge Label', key: 'judgeLabel', width: 10 },
        { header: 'Judge Name', key: 'judgeName', width: 25 },
        { header: 'Individual Judge Status', key: 'individualJudgeStatus', width: 25 },
        { header: 'Composite Judge Status', key: 'compositeJudgeStatus', width: 10 },
        { header: 'Judge Score', key: 'judgeScore', width: 10 },
        { header: 'Revenue Rank By Category', key: 'revenueRankByCategory', width: 25 },
        { header: 'Revenue Percentile By Category', key: 'revenuePercentileByCategory', width: 25 },
        { header: 'Last Login', key: 'lastLoginDate', width: 10 },
        { header: 'Comments', key: 'comments', width: 10 },
        { header: 'Reason for not Advancing', key: 'judgingFeedback', width: 25 }
      ]
      context.commit('SET_EXPORT_LOADING', true);
      const baseId = process.env.VUE_APP_BASE_ID;
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      let playbookPhasesData;
      playbookPhasesData = context?.rootState?.playbook?.playbook?.activities;
      let judgingPlaybookBaseIds = [];
      for(let phase of playbookPhasesData){
        if(phase?.metaData?.judgingPlaybookBaseId){
          judgingPlaybookBaseIds.push(phase.metaData.judgingPlaybookBaseId);
        }
      }
      let query = {
        'baseId': {
          "$in": judgingPlaybookBaseIds
        },
        'status': {
          "$in": [0, 1, 2, 6]
        },
        'parentInitiativeId': {
          "$exists": true
        },
        $populate: [{
          path: "teamId",
          populate: {
            "path": "members.accountUserId",
            "select": "firstName lastName email profilePic lastLoginDate"
          }
        },
        {
          path: "parentInitiativeId",
          select: "name metaData"
        },
        {
          path: "practiceId",
          select: "name"
        }]
      }
      let historyRound=[];
      for(let rounds of context.state.totalJudgingRounds){
        if(rounds.id ===context.state.currentRound){
          context.state.rankArrayByRevenue.roundId = rounds.baseId;
        }
      }
      historyRound.push(context.state.rankArrayByRevenue);
      if(context.state.judgingHistoryRounds.length>0){
            for(let rounds of context.state.judgingHistoryRounds){
               await getInitiativeRanks(context,rounds.baseId,true)
               context.state.rankArrayByRevenueHistory.roundId = rounds.baseId;
                historyRound.push(context.state.rankArrayByRevenueHistory);
            }
      }
      const initiativeService = feathersClient.service("initiatives");
      const ExportExcel = feathersClient.service("/export/excel");
      let initiativeData = await initiativeService.find({query: query});
      let dataToExport = [];
      if(initiativeData?.data?.length > 0){
        for(let idea of initiativeData.data){
          if(idea?.teamId?.members?.length>0){
            for(let [i, member] of (idea.teamId.members).entries()){
              let individualJudgeStatus = 'Not Started';
              let compositeJudgeStatus = 'Not Started';
              let judgeScore = 0;
              let comments = '';
              let judgingFeedback='NA';
              let revenueRankByCategory;
              let revenuePercentileByCategory;
              let index;  
              let numerator;
              let percentile;
              if(idea?.metaData?.reviews?.length>0){
                let reviewed = idea.metaData.reviews.filter((review)=>review.lastUpdatedBy == member.accountUserId._id);
                if(reviewed?.length>0){
                  individualJudgeStatus = 'Completed';
                } else {
                  individualJudgeStatus = 'Not Started';
                }
                if(idea.metaData.reviews.length == idea.teamId.members.length){
                  compositeJudgeStatus = 'Completed';
                } else if(idea.metaData.reviews.length > 0){
                  compositeJudgeStatus = 'In Progress';
                } else {
                  compositeJudgeStatus = 'Not Started';
                }
                if(reviewed[0]?.judgeScore || reviewed[0]?.judgeScore==0){
                  if(idea.practiceId.name==="Round 1"){
                    if(reviewed[0]?.judgeScore>=1){
                      judgeScore = "Yes";
                    } else if(reviewed[0]?.judgeScore==0){
                      judgeScore = "No";
                    } 
                  } else {
                  judgeScore = reviewed[0].judgeScore;
                  }
                }
                if(reviewed[0]?.judgingFeedback){
                  judgingFeedback = reviewed[0]?.judgingFeedback;
                }
                if(reviewed[0]?.reasonForNotAdvancing){
                  judgingFeedback = reviewed[0]?.reasonForNotAdvancing;
                }

                if(reviewed[0]?.comments){
                  comments = reviewed[0].comments;
                }
              }
              if(historyRound.length>0){
                for (let i = 0; i < historyRound.length; i++) {
                  let round = historyRound[i];
                  if(idea.baseId === round.roundId){
                  if(round[idea?.parentInitiativeId?.metaData?.challengeType]){
                    index = round[idea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
                      return object.id === idea._id;
                    });
                    if(index===-1){ revenueRankByCategory = "NA"}
                    else{ revenueRankByCategory = index + 1}
                  }
                  if(round[idea?.parentInitiativeId?.metaData?.challengeType]){
                    index = round[idea.parentInitiativeId.metaData.challengeType]?.findIndex(object => {
                      return object.id === idea._id;
                    });
                    numerator = round[idea.parentInitiativeId.metaData.challengeType]?.length-index-1
                    // percentile=(numerator/round[idea.parentInitiativeId.metaData.challengeType]?.length)*100  
                    let multiplyingFactor = 100/(round[idea.parentInitiativeId.metaData.challengeType]?.length-1)
                    let value=(round[idea.parentInitiativeId.metaData.challengeType]?.length)-(index+1)
                    percentile=multiplyingFactor*value
                    if(index===-1 || round[idea.parentInitiativeId.metaData.challengeType].length===1){
                      revenuePercentileByCategory = "NA"
                    }
                    else{ 
                      switch (Math.round(percentile)%10) {
                        case 1:
                          revenuePercentileByCategory = Math.round(percentile)+"st";
                          break;      
                        case 2:
                          revenuePercentileByCategory = Math.round(percentile)+"nd";
                          break;      
                        case 3:
                          revenuePercentileByCategory = Math.round(percentile)+"rd";
                          break;      
                        default:
                          revenuePercentileByCategory = Math.round(percentile)+"th"      
                      }
                     }
                  }
                 }
                }
              }
              let individualData = {
                "ideaName": idea.parentInitiativeId.name,
                "category": idea?.parentInitiativeId?.metaData?.challengeType || "",
                "phase": idea.practiceId.name,
                "judgeLabel": `Judge ${i+1}`,
                "judgeName": `${member.accountUserId.firstName} ${member.accountUserId.lastName}`,
                "individualJudgeStatus": individualJudgeStatus,
                "compositeJudgeStatus": compositeJudgeStatus,
                "judgeScore": judgeScore,
                "comments": comments,
                "judgingFeedback":judgingFeedback,
                "revenueRankByCategory":revenueRankByCategory,
                "revenuePercentileByCategory":revenuePercentileByCategory
              }
              if(member?.accountUserId?.lastLoginDate){
                individualData.lastLoginDate = format(new Date(member.accountUserId.lastLoginDate), "isoDate");
              } else {
                individualData.lastLoginDate = 'N/A';
              }
              if(idea?.status){
                if(idea.status == 6){
                  individualData.status = 'Inactive';
                } else {
                  individualData.status = 'Active';
                }
              }
              dataToExport.push(individualData);
            }
          }
        }
        let excelData = {};
        excelData.columnsNames = columnsNames;
        excelData.dataToExport = dataToExport;
        excelData.fileName = 'Judging Data';
        let exportData = await ExportExcel.create(excelData);
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
        var blob = new Blob([s2ab(atob(exportData))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Judging Data.xlsx';
        link.click();
        context.commit('SET_EXPORT_LOADING', false);
      }
      context.commit('SET_EXPORT_LOADING', false);
    } catch (err) {
      console.log("Error in Exporting Judging Data ", err);
      context.commit('SET_EXPORT_LOADING', false);
    }
  },
  async exportUserActivity(context, activity) {
    try {
      context.commit('SET_EXPORT_LOADING', true);
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const GetWorkspaceUserDetails = feathersClient.service("/workspace/aggregation");
      const ExportExcel = feathersClient.service("/export/excel");
      const getuserInfoService = feathersClient.service("/accountusers/teaminfo");
      const subOrgService = feathersClient.service('suborganizations');
      const judgingTeamId = process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID;
      const ideaTeamId = process.env.VUE_APP_IDEA_TEAM_ID;
      const powerUserTeamId = process.env.VUE_APP_POWERUSER_TEAM_ID;
      let subOrgData = await subOrgService.get(judgingTeamId);
      let judgingTeamIds = [];
      let isUserJudgeQuery = [];
      judgingTeamIds = subOrgData.subOrganizations;
      judgingTeamIds.push(subOrgData._id);
      for (let teamId of judgingTeamIds) {
        isUserJudgeQuery.push({
          $and: [{ $in: ['$$userId', '$members.accountUserId'] },
          { $eq: ['$_id', teamId] }]
        });
      }
      let columnsNames = [
        { header: 'UserName', key: 'displayName', width: 25 },
        { header: 'Email', key: 'email', width: 25 },
        { header: 'Roles', key: 'commaSeparatedRoles', width: 10 },
        { header: 'First Name', key: 'firstName', width: 25 },
        { header: 'Last Name', key: 'lastName', width: 25 },
        { header: 'City', key: 'city', width: 10 },
        { header: 'State', key: 'state', width: 10 },
        { header: 'Country', key: 'country', width: 10 },
        { header: 'Interest', key: 'interest', width: 10 },
        { header: 'Region', key: 'region', width: 10 },
        { header: 'No of ideas created', key: 'ideasCreatedCount', width: 25 },
        { header: 'No of ideas viewed', key: 'ideasViewed', width: 25 },
        { header: 'No of ideas rated', key: 'ideasRated', width: 25 },
        { header: 'No of ideas commented on', key: 'ideasCommentedOn', width: 25 },
        { header: 'No of comments', key: 'noOfComments', width: 25 },
        { header: 'No of ideas shared', key: 'ideasShared', width: 25 },
        { header: 'No of ideas followed', key: 'ideasFollowed', width: 25 },
        { header: 'No of ideas liked', key: 'ideasLiked', width: 25 },
        { header: 'No of ideas where user is part of team', key: 'ideaTeamMember', width: 25 },
        { header: 'No of ideas user judged', key: 'ideasJudged', width: 25 },
        { header: 'No of ideas user mentored', key: 'ideaMentor', width: 25 },
        { header: 'No of ideas where user is the team leader', key: 'ideaTeamLead', width: 25 },
        { header: 'Judge (Y/N)', key: 'isUserJudge', width: 15 },
        { header: 'Last Login Date', key: 'lastLoginDate', width: 25 },
        { header: 'Last Edit Date', key: 'lastEditDate', width: 25 },
        { header: 'Terms and Conditions', key: 'termsAndCondition', width: 25 }
      ]
      let aggregationQuery = [
        {
          $lookup: {
            from: "initiatives",
            localField: "email",
            foreignField: "metaData.submitter.email",
            as: "ideasCreated"
          }
        }, {
          $lookup: {
            from: "initiatives",
            localField: "email",
            foreignField: "metaData.teamMembers.email",
            as: "ideaTeamMember"
          }
        }, {
          $lookup: {
            from: "initiatives",
            localField: "email",
            foreignField: "metaData.mentors.email",
            as: "ideaMentor"
          }
        }, {
          $lookup: {
            from: "initiatives",
            localField: "email",
            foreignField: "metaData.teamLead.email",
            as: "ideaTeamLead"
          }
        }, {
          $lookup: {
            from: "initiativelikes",
            localField: "_id",
            foreignField: "accountUserIds",
            as: "ideasLiked"
          }
        }, {
          $lookup: {
            from: "userfollows",
            localField: "_id",
            foreignField: "accountUserId",
            as: "ideasFollowed"
          }
        }, {
          $lookup: {
            from: "initiativecomments",
            localField: "_id",
            foreignField: "notes.accountUserId",
            as: "ideasCommentedOn"
          }
        }, {
          $lookup: {
            from: "initiatives",
            localField: "_id",
            foreignField: "metaData.lastUpdatedBy",
            as: "ideasJudged"
          }
        }, {
          $lookup: {
            from: "initiativecomments",
            localField: "_id",
            foreignField: "notes.accountUserId",
            as: "noOfComments"
          }
        },
        {
          $lookup: {
            "from": "suborganizations",
            "let": { "userId": "$_id" },
            pipeline: [
              {
                $match: {
                  $expr: {
                    "$or": isUserJudgeQuery
                  }
                }
              },
            ],
            as: "isUserJudge"
          }
        },
        { $addFields: { ideaTeamMember: { $size: "$ideaTeamMember" } } },
        { $addFields: { ideaMentor: { $size: "$ideaMentor" } } },
        { $addFields: { ideaTeamLead: { $size: "$ideaTeamLead" } } },
        { $addFields: { ideasLiked: { $size: "$ideasLiked" } } },
        { $addFields: { ideasFollowed: { $arrayElemAt: ["$ideasFollowed", 0] } } },
        { $addFields: { noOfComments: { $size: "$noOfComments" } } },
        { $addFields: { ideasJudged: { $size: "$ideasJudged" } } }
      ]
      let data = {}
      data.aggregationQuery = JSON.stringify(aggregationQuery);
      let userActivityToExport = await GetWorkspaceUserDetails.find({
        "query": data,
        "headers": {
          "Authorization": tokenString
        }
      });
      let userIds = [];
      userIds = userActivityToExport.map((user) => { return user._id });
      let userData = {
        "organizationId": context.rootState.user.user.organizationId,
        "accountId": context.rootState.user.user.accountId
      };
      userData.userIds = userIds;
      userData.options = {};
      userData.options.excludeTeamsWithParenOrganizationId = ideaTeamId;
      let userInfo = await getuserInfoService.create(userData);
      for (let userActivity of userActivityToExport) {
        userActivity.commaSeparatedBUFuction = '';
        if (userActivity?.lastLoginDate) {
          userActivity.lastLoginDate = format(new Date(userActivity.lastLoginDate), "isoDate");
        }
        if (userActivity?.ideasFollowed?.initiativeIds?.length > 0) {
          userActivity.ideasFollowed = userActivity.ideasFollowed.initiativeIds.length;
        } else {
          userActivity.ideasFollowed = 0;
        }
        if (userActivity?.ideasCreated?.length > 0) {
          userActivity.ideasCreated = userActivity.ideasCreated.filter((idea) => { return !idea.parentInitiativeId });
          userActivity.ideasCreatedCount = userActivity.ideasCreated.length;
        } else {
          userActivity.ideasCreated = 0;
          userActivity.ideasCreatedCount = 0;
        }
        if (userActivity?.isUserJudge?.length > 0) {
          userActivity.isUserJudge = 'Yes';
        } else {
          userActivity.isUserJudge = 'No';
        }
        if (userActivity?.ideasCommentedOn && userActivity.ideasCommentedOn.length > 0) {
          let uniqueIdeasCommentedOn = [...new Map(userActivity.ideasCommentedOn.map(idea => [idea['initiativeId'], idea])).values()];
          userActivity.ideasCommentedOn = uniqueIdeasCommentedOn.length;
        } else {
          userActivity.ideasCommentedOn = 0;
        }
        if (userActivity?.email) {
          userActivity.displayName = userActivity.email.split("@")[0];
        }
        let roles = [];
        let commaSeparatedRoles = '';
        if (userInfo.length > 0) {
          let user = userInfo.find(userData => userData.userId == userActivity._id);
          if (user?.teams?.length > 0) {
            let powerUserTeam = user.teams.find(team => (team.id).toString() == process.env.VUE_APP_POWERUSER_TEAM_ID);
            let findJudge = user.teams.find(team => ((team.id).toString() == process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID) || ((team.parentOrganizationId).toString() == process.env.VUE_APP_SUB_INITIATIVE_TEAM_ID));
            let executivesTeam = user.teams.find(team => (team.id).toString() == process.env.VUE_APP_EXECUTIVE_TEAM_ID);
            if (powerUserTeam) {
              roles.push('Power User');
            }
            if (findJudge) {
              roles.push('Judge');
            }
            if (executivesTeam) {
              roles.push('Executive');
            }
          }
        }
        if (roles.length > 0) {
          for (let [i, role] of roles.entries()) {
            if (i == (roles.length - 1)) {
              commaSeparatedRoles += role;
            } else {
              commaSeparatedRoles += role + ', ';
            }
          }
        } else {
          commaSeparatedRoles = 'Member';
        }
        userActivity.commaSeparatedRoles = commaSeparatedRoles;
      }
      let excelData = {};
      excelData.columnsNames = columnsNames;
      excelData.dataToExport = userActivityToExport;
      excelData.fileName = 'User Activity';
      let exportData = await ExportExcel.create(excelData);
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      var blob = new Blob([s2ab(atob(exportData))], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'User Activity.xlsx';
      link.click();
      context.commit('SET_EXPORT_LOADING', false);
    } catch (err) {
      console.log("Error in Exporting User Activity ", err);
      context.commit('SET_EXPORT_LOADING', false);
    }
  },
  async inactivateIdeaMainIdea(context, { selectedInitiative, comment, status }) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives/onbehalf");
      const initiativeService = feathersClient.service("initiatives");
      var statusPayload;
      let totalPhases = context.rootState.playbook.phaseNames.length;
      // if (selectedInitiative.status === 1) {
      if (selectedInitiative?.subInitiatives?.length > 0) {
        if(status == 1){
          if(totalPhases == selectedInitiative.phaseIndex){
            selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
              const result = await initiativeService.patch(subInitiative._id, {
                status: 2,
              }, {
                headers: {
                  "Authorization": tokenString
                },
              });
            });
          } else {
            selectedInitiative?.subInitiatives.forEach(async function (subInitiative, index) {
              if(selectedInitiative.subInitiatives.length == index+1){
                let status=1;
                if(subInitiative?.metaData && subInitiative?.metaData?.previousStatus){
                  status = subInitiative?.metaData?.previousStatus;
                }
                const result = await initiativeService.patch(subInitiative._id, {
                  status: status,
                  "metaData.previousStatus":null
                }, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
              } else {
                let prevStatus=2;
                if(subInitiative?.metaData && subInitiative?.metaData?.previousStatus){
                  prevStatus = subInitiative?.metaData?.previousStatus;
                }
                const result = await initiativeService.patch(subInitiative._id, {
                  status: prevStatus,
                  "metaData.previousStatus":null
                }, {
                  headers: {
                    "Authorization": tokenString
                  },
                });
              }
            });
          }
        } else {
          selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
            const result = await initiativeService.patch(subInitiative._id, {
              status: status,
              "metaData.previousStatus":subInitiative.status,
            }, {
              headers: {
                "Authorization": tokenString
              },
            });
          });
        }
      }
      let logComment = status === 1 ? "Activated" : status === 6 ? "Inactivated" : "Merged"
      statusPayload = {
        statusUpdate: {
          comment: `Submission ${logComment} Note: ${comment}`,
          status: status
        }
      }
      // } else if (selectedInitiative.status === 6) {
      // if (selectedInitiative?.subInitiatives?.length > 0) {
      //   selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
      //     console.log({ subInitiative })
      //     const result = await initiativeService.patch(subInitiative._id, {
      //       status: 1,
      //     }, {
      //       headers: {
      //         "Authorization": tokenString
      //       },
      //     });
      //   });
      // }
      // statusPayload = {
      //   statusUpdate: {
      //     comment: `Idea Activated Note: ${comment}`,
      //     status: 1
      //   }
      // }
      // }

      const result = await initiatives.patch(selectedInitiative.id, statusPayload, {
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }
      });
      await context.dispatch("readInitiative");
    } catch (error) {
      console.log(error)
    }

  },
  async inactivateIdeaJudgingIdea(context, { selectedInitiative, comment, metaData }) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives/onbehalf");
      const initiativeService = feathersClient.service("initiatives");
      var statusPayload;
      if (selectedInitiative.status === 1) {
        if (selectedInitiative?.subInitiatives?.length > 0) {
          selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
            const result = await initiativeService.patch(subInitiative._id, {
              status: 6,
              "metaData": {
                "comment": "Judging is ended!",
                "date": new Date(),
                "updatedBy": context.rootState.user.user.aspenifyUserId,
                "actionType": "end",
                "lastUpdatedBy": context.rootState.user.user.aspenifyUserId,
                "isJudgingActive": true
              }
            }, {
              headers: {
                "Authorization": tokenString
              },
            });
          });
        }
        statusPayload = {
          statusUpdate: {
            comment: `Submission Inactivated Note: ${comment}`,
            status: 6
          }
        }
      } else if (selectedInitiative.status === 6) {
        if (selectedInitiative?.subInitiatives?.length > 0) {
          selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
            const result = await initiativeService.patch(subInitiative._id, {
              status: 1,
              "metaData": {
                "comment": "Judging is ended!",
                "date": new Date(),
                "updatedBy": context.rootState.user.user.aspenifyUserId,
                "actionType": "end",
                "lastUpdatedBy": context.rootState.user.user.aspenifyUserId,
                "isJudgingActive": false
              }
            }, {
              headers: {
                "Authorization": tokenString
              },
            });
          });
        }
        statusPayload = {
          statusUpdate: {
            comment: `Submission Activated Note: ${comment}`,
            status: 1
          }
        }
      }
      if (metaData) {
        statusPayload = {
          updateMetaData: {
            metaData: metaData
          }
        }
      }

      const result = await initiatives.patch(selectedInitiative.id, statusPayload, {
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }
      });
      await context.dispatch("readInitiative");
    } catch (error) {
      console.log(error)
    }

  },
  async mergeJudgingIdea(context, { selectedInitiative, comment, metaData }) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives/onbehalf");
      const initiativeService = feathersClient.service("initiatives");
      var statusPayload;
      if (selectedInitiative?.subInitiatives?.length > 0) {
        selectedInitiative?.subInitiatives.forEach(async function (subInitiative) {
          const result = await initiativeService.patch(subInitiative._id, {
            status: 7,
            "metaData": {
              "comment": "Judging is ended!",
              "date": new Date(),
              "updatedBy": context.rootState.user.user.aspenifyUserId,
              "actionType": "end",
              "lastUpdatedBy": context.rootState.user.user.aspenifyUserId,
              "isJudgingActive": true
            }
          }, {
            headers: {
              "Authorization": tokenString
            },
          });
        });
      }
      statusPayload = {
        statusUpdate: {
          comment: `Submission Merged Note: ${comment}`,
          status: 7
        }
      }
      if (metaData) {
        statusPayload = {
          updateMetaData: {
            metaData: metaData
          }
        }
      }

      const result = await initiatives.patch(selectedInitiative.id, statusPayload, {
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }
      });
      await context.dispatch("readInitiative");
    } catch (error) {
      console.log(error)
    }

  },
  async setEpicsInInitiativesActivities(context, { selectedEpics, selectedProject, selectedRelease, stageId }) {
    const { apiKey, accessToken } = context.rootState.user.user;
    const initiativeActivitiesService = feathersClient.service('initiatives/activities');
    try {
      const payload = {
        metaData: {
          selectedProject: selectedProject.self,
          selectedRelease: selectedRelease.self,
          selectedEpics: selectedEpics
        }
      }
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives/onbehalf");
      const result = await initiativeActivitiesService.patch(stageId, payload, {
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (error) {
      console.log(error)
    }

  },

  async getSelectedActivityDetails(context, selectedStage) {
    const { apiKey, accessToken } = context.rootState.user.user;
    const activity = feathersClient.service("initiatives/activities");
    try {
      const tokenString = `Bearer ${accessToken}`;
      let result = await activity.get(selectedStage._id, {
        headers: {
          "Authorization": tokenString
        },
      });
      if (result) {
        return result;
      }

    } catch (error) {
      console.log(error)
    }

  },
  async sendRequestToBecomeMentorOrMember(context, payload) {
    let emailIds = [];
    if (payload.selectedInitiative && payload.selectedInitiative.users && payload.selectedInitiative.users.length > 0) {
      payload.selectedInitiative.users.forEach(function (member) {
        if (member.role === "Submitter" || member.role === "Submitter | Team Lead" || member.role === "Team Lead") {
          emailIds.push(member.email);
        }
      });
    }
    const { apiKey, accessToken } = context.rootState.user.user;
    const joinrequestsService = feathersClient.service("joinrequests");
    try {
      const tokenString = `Bearer ${accessToken}`;
      // let payloadTopass = {
      //   senderEmail: payload.senderEmail,
      //   role: payload.role,
      //   senderName: payload.senderName,
      //   image: payload.image,
      //   ideaName: payload.selectedInitiative.name,
      //   currentLoggedInUser: payload.currentLoggedInUser,
      //   emailIds: emailIds
      // }
      let eventDataType = payload.role === "Member" ? "newMemberRequest" : "newMentorRequest";
      // payloadTopass.eventData = {
      //   "type": eventDataType,
      //   //later inside emails pass emailIds array 
      //   "emails": emailIds,
      //   "senderName": payload.senderName,
      //   "name": payload.selectedInitiative.name
      // }

      // let patchResult = await initiatives.patch(payload.selectedInitiative.id, {
      //   $push: { "metaData.requests": patchPayload },
      //   sendRequestToBecomeMentorOrMember: true,
      //   payload: payloadTopass
      // });
      let payloadToPass = {
        role: payload.role,
        from: payload.currentLoggedInUser.aspenifyUserId,
        accountId: context.rootState.user.user.accountId,
        organizationId: context.rootState.user.user.organizationId,
        referenceId: payload.selectedInitiative.id, //initiative Id 
        referenceCollection: "initiatives",
        isDeclined: false,
        isAccepted: false,
        isDeleted: false,
        lastUpdatedBy: payload.currentLoggedInUser.aspenifyUserId,
        createdBy: payload.currentLoggedInUser.aspenifyUserId,
        message: payload.comment
      }
      payloadToPass.eventData = {
        "type": eventDataType,
        "emails": emailIds,
        "senderName": payload.senderName,
        "name": payload.selectedInitiative.name,
        "ideaName": payload.selectedInitiative.name,
        "event": "customEvent"
      }
      let result = await joinrequestsService.create(payloadToPass, {
        headers: {
          Authorization: tokenString,
        },
      });
      await context.dispatch('getAllPendingRequests', payload.selectedInitiative.id);

    } catch (error) {
      console.log(error)
    }
  },
  async getAccessToken(context, payload) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const graphApi = feathersClient.service("msGraph/accessToken");
      payload = {
        ...payload,
        client_id: process.env.VUE_APP_GRAPH_CLIENT_ID,
        client_secret_id: process.env.VUE_APP_GRAPH_CLIENT_SECRET,
        client_tenant_id: process.env.VUE_APP_GRAPH_CLIENT_TENANT,
      }
      const result = await graphApi.find({
        query: payload,
        headers: {
          "Authorization": tokenString
        },
      });
      context.commit('SET_MS_GRAPH_ACCESS_TOKEN', result);
    } catch (error) {
      console.log(error)
    }
  },
  async getUsers(context, payload) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const graphApi = feathersClient.service("msGraph/users");

      var requestPayload = {
        payload: payload
      }
      const result = await graphApi.get(requestPayload, {
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }
      });
      if (result && result) {
        context.commit('SET_TEAM_MEMBERS', [result]);
      }

    } catch (error) {
      console.log(error)
    }
  },
  async getUsersByDisplayName(context, payload) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const msGraphApi = feathersClient.service("msGraph/users/displayName");
      payload.entity = 'displayName'
      var requestPayload = {
        payload: payload
      }
      const result = await msGraphApi.get("statusPayloadId", {
        query: requestPayload,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        }
      });
      if (result) {
        return result;
        // context.commit('SET_USERS_BY_DISPLAYNAME', [result]);
      }

    } catch (error) {
      console.log(error)
    }

  },
  async getAllPendingRequests(context, payload) {
    const { apiKey, accessToken } = context.rootState.user.user;
    try {
      const tokenString = `Bearer ${accessToken}`;
      const initiatives = feathersClient.service("initiatives");
      const joinrequestsService = feathersClient.service("joinrequests");
      // let result = await joinrequestsService.get({referenceId:payload}, {
      // query: {
      //   $populate: [
      //     {
      //       path: "metaData.requests.accountUserId", model: 'accountUsers',
      //       select: "firstName lastName profilePic email",
      //     }
      //   ]
      // }
      // });


      let query = {
        'referenceId': payload,
        isDeclined: false,
        isAccepted: false,
        $populate: [
          {
            path: "from",
            select: "_id firstName lastName email profilePic",
          },
        ],
      }
      const result = await joinrequestsService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      // return false;
      let allrequests = []
      if (result?.data?.length > 0) {
        result.data.forEach(function (member) {
          const user = {
            name: member.from.firstName + " " + member.from.lastName,
            email: member.from.email,
            image: member.from.profilePic,
            role: member.role,
            comment: member.message,
            id: member.from._id,
            requestId: member._id,
            requestFrom: member?.from?._id
          };
          allrequests.push(user);
        });
      }
      context.commit('SET_PENDING_REQUESTS', allrequests);
    } catch (error) {
      console.log(error)
    }
  },
  async acceptRequest(context, payload) {
    const { apiKey, accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("initiatives");
    const joinrequestsService = feathersClient.service("joinrequest/accept");
    const userWorkspaceManagerService = feathersClient.service("workspaces/users");


    const tokenString = `Bearer ${accessToken}`;
    try {
      let teamMember = {
        name: payload.request.name,
        email: payload.request.email,
        image: payload.request.image,
        role: payload.request.role,
        accountUserId: payload.request.requestFrom
      }

      payload.teamMember = teamMember;
      payload.parentOrganizationId = process.env.VUE_APP_IDEA_TEAM_ID;
      // let payloadTopass = {
      //   senderEmail: payload.senderEmail,
      //   role: payload.request.role,
      //   senderName: payload.request.name,
      //   image: payload.image,
      //   ideaName: payload.initiative.name,
      //   loggedinUser: payload.loggedinUser,
      //   emailIds: [payload.request.email],
      //   teamLead: payload.loggedinUser.name,
      //   accountId: payload.loggedinUser.accountId,
      //   organizationId: payload.loggedinUser.organizationId
      // }
      let eventDataType = payload.request.role === "Member" ? "newMemberAdded" : "newMentorAdded";
      payload.eventData = {
        "type": eventDataType,
        "emails": [payload.request.email],
        "senderName": payload.senderName,
        "ideaName": payload.initiative.name,
        "name": payload.initiative.name,
      }
      // } else if (payload.role === "Member") {
      // patchResult = await initiatives.patch(payload.initiative.id, {
      //   $push: { "metaData.teamMembers": object },
      //   $pull: { "metaData.requests": { accountUserId: payload.request.id } },
      //   convertaccountUserIdintoId: true,
      //   acceptRequests: true,
      //   payload: payloadTopass
      // });
      // }// } else if (payload.role === "Member") {
      // patchResult = await initiatives.patch(payload.initiative.id, {
      //   $push: { "metaData.teamMembers": object },
      //   $pull: { "metaData.requests": { accountUserId: payload.request.id } },
      //   convertaccountUserIdintoId: true,
      //   acceptRequests: true,
      //   payload: payloadTopass
      // });
      // }
      // "metaData.teamMembers": object
      let result = await joinrequestsService.patch(payload.request.requestId, payload, {
        headers: {
          "Authorization": tokenString
        },
      }
      );
      let initiativePatchResult;
      if (payload.role === "Member") {
        initiativePatchResult = await initiatives.patch(payload.initiative.id, {
          $push: { "metaData.teamMembers": payload.teamMember },
        });
      } else if (payload.role === "Mentor") {
        initiativePatchResult = await initiatives.patch(payload.initiative.id, {
          $push: { "metaData.mentors": payload.teamMember },
        });
      }
      await context.dispatch('getAllPendingRequests', payload.initiative.id);
      await context.dispatch("getInitiativeDetailsById", payload.initiative.id);
    } catch (err) {
      console.log(err);
    }
  },
  async rejectRequest(context, payload) {
    try {
      const initiatives = feathersClient.service("initiatives");
      const joinrequestsService = feathersClient.service("joinrequest/reject");
      const { apiKey, accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;


      // let payloadTopass = {
      //   senderEmail: payload.senderEmail,
      //   role: payload.request.role,
      //   ideaName: payload.ideaName,
      //   loggedinUser: payload.loggedinUser,
      //   emailIds: [payload.request.email],
      //   teamLead: payload.senderName,
      //   accountId: payload.loggedinUser.accountId,
      //   organizationId: payload.loggedinUser.organizationId,
      //   reason: payload.reasontoreject
      // }
      let eventDataType = payload.request.role === "Member" ? "memberRequestRejected" : "mentorRequestRejected";
      payload.eventData = {
        "type": eventDataType,
        "emails": [payload.request.email],
        "senderName": payload.senderName,
        "ideaName": payload.ideaName,
        "name": payload.ideaName,
      }
      // let patchResult = await initiatives.patch(payload.selectedInitiative.id, {
      //   $pull: { "metaData.requests": { accountUserId: payload.request.id } },
      //   convertaccountUserIdintoId: true,
      //   requestRejected: true,
      //   payload: payloadTopass
      // });

      let result = await joinrequestsService.patch(payload.request.requestId, payload, {
        headers: {
          "Authorization": tokenString
        },
      }
      );
      await context.dispatch('getAllPendingRequests', payload.selectedInitiative.id)
    } catch (error) {
      console.log(error)
    }
  },
  async createProfilePicIfUserNotPresent(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const accountUsersService = feathersClient.service("accountusers");
    const newAccountUsersService = feathersClient.service("accountusers/profilepic");

    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        'email': payload.email.toLowerCase(),
      }
      const result = await accountUsersService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      if (result && result.data && result.data.length > 0) {
        return result.data[0];
      } else {
        // create profilePic 
        // let name = payload.name.split(" ");
        const userData = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          // email: payload.email,
          // isApiUser: true,
          // isVerified: true,
          // accounts: [{
          //   "role": 'user',
          //   "id": context.rootState.user.user.accountId,
          // }],
          // organizationId: context.rootState.user.user.organizationId
        };

        const newUser = await newAccountUsersService.create(userData);
        return newUser;
      }
    } catch (error) {
      console.log(error)
    }
  },

  setPhaseActivitiesFlag(context, payload) {
    context.commit('SET_FLAG_TO_OPEN_ACTIVITIES_TAB', payload);
  },
  async importIdeas(context, ideas) {
    try {
      let loggedInUser = context.rootState.user.user;
      const baseId = process.env.VUE_APP_BASE_ID;
      let totalIdeas = ideas.length;
      let executedIdeas = 0;
      const profilePicUsersService = feathersClient.service("accountusers/profilepic");
      const accountUserService = feathersClient.service("accountusers");
      for (let [i, idea] of ideas.entries()) {
        console.log(`Submission Name : ${idea['Give your submission a title*']}`);
        executedIdeas++;
        console.log(`Import Status : ${executedIdeas}/${totalIdeas}`);
        let teamMembersString = [];
        let mentorsString = [];
        let referencesString = [];
        let teamMembers = [];
        let mentors = [];
        let teamLead = [];
        let createdBy = {};
        let submitterArray = [];
        let execSponsorsString = [];
        let phaseIndex = 1;
        if (idea['Created By']) {
          let created = idea['Created By'];
          if (created) {
            let start = created.indexOf("(");
            let end = created.indexOf(")");
            let displayName = created.slice(start + 1, end);
            let fullName = created.slice(0, start);
            fullName = fullName.trim();
            let email = displayName + "@cisco.com";
            let isValid = await validateEmail(email);
            if (isValid) {
              try {
                createdBy = {
                  "name": fullName,
                  "email": email
                }
              } catch (err) {
                console.log("Error in setting submission created by ", err);
              }
            } else {
              console.log("Invalid Email ", email);
            }
          }
        }
        submitterArray.push(createdBy);
        if (idea['Team Member']) {
          teamMembersString = idea['Team Member'].split(',');
          for (let teamMember of teamMembersString) {
            if (teamMember) {
              let start = teamMember.indexOf("(");
              let end = teamMember.indexOf(")");
              let displayName = teamMember.slice(start + 1, end);
              let fullName = teamMember.slice(0, start);
              fullName = fullName.trim();
              let email = displayName + "@cisco.com";
              let isValid = await validateEmail(email);
              if (isValid) {
                try {
                  teamMembers.push({
                    "name": fullName,
                    "email": email,
                    "role": "Team Member"
                  });
                } catch (err) {
                  console.log("Error in setting team member ", err);
                }
              } else {
                console.log("Invalid Email ", email);
              }
            }
          }
        }
        if (idea['Mentor']) {
          mentorsString = idea['Mentor'].split(',');
          for (let mentor of mentorsString) {
            if (mentor) {
              let start = mentor.indexOf("(");
              let end = mentor.indexOf(")");
              let displayName = mentor.slice(start + 1, end);
              let fullName = mentor.slice(0, start);
              fullName = fullName.trim();
              let email = displayName + "@cisco.com";
              let isValid = await validateEmail(email);
              if (isValid) {
                try {
                  mentors.push({
                    "name": fullName,
                    "email": email,
                    "role": "Mentor"
                  });
                } catch (err) {
                  console.log("Error in setting mentor ", err);
                }
              } else {
                console.log("Invalid Email ", email);
              }
            }
          }
        }
        if (idea['Team Lead*']) {
          let lead = idea['Team Lead*'];
          if (lead) {
            let start = lead.indexOf("(");
            let end = lead.indexOf(")");
            let displayName = lead.slice(start + 1, end);
            let fullName = lead.slice(0, start);
            fullName = fullName.trim();
            let email = displayName + "@cisco.com";
            let isValid = await validateEmail(email);
            if (isValid) {
              try {
                teamLead.push({
                  "name": fullName,
                  "email": email,
                  "role": "TeamLead"
                });
              } catch (err) {
                console.log("Error in setting team lead ", err);
              }
            } else {
              console.log("Invalid Email ", email);
            }
          }
        }
        if (idea['Phase'] == "Ideate") {
          phaseIndex = 1;
        } else if (idea['Phase'] == "Validate") {
          phaseIndex = 2;
        } else if (idea['Phase'] == "Scope") {
          phaseIndex = 3;
        } else if (idea['Phase'] == "Incubate") {
          phaseIndex = 4;
        } else if (idea['Phase'] == "Scale") {
          phaseIndex = 5;
        }
        let randomNumber = Math.floor(Math.random() * 10) + 1;
        let payload = {
          "name": idea['Give your idea a title*'],
          "description": idea['Potential Solution*'],
          "tags": idea['Tags'] ? idea['Tags'].split(',') : [],
          "status": 0,
          "slug": idea['Give your idea a title*'],
          "picture": idea['Thumbnail'] ? idea['Thumbnail'] : `https://res.cloudinary.com/aspenify-prod/image/upload/v1625135810/Cisco%20Idea%20Management%20Program%20Portal/${randomNumber}.png`,
          "baseId": baseId,
          "metaData": {
            "source": idea['Source'] ? idea['Source'] : "NA",
            "priority": "NA",
            "bogFramework": idea['BOG Framework'] ? idea['BOG Framework'] : "NA",
            "challengeType": idea['Themes'] ? idea['Themes'].split(',') : [],
            "marketHorizon": idea['Market Horizon'],
            "elevatorPitch": idea['What is your elevator pitch?*'],
            "problemDescription": idea['Potential Problem*'],
            "potentialMarket": idea['Potential Market*'],
            "aboutTeam": idea['About You / Your Team'],
            "teamMembers": teamMembers,
            "mentors": mentors,
            "teamLead": teamLead,
            "submitter": submitterArray,
            "ventureTermsCondition": true,
            "origIdeaTermsCodition": true,
            "managementFields": {}
          },
          "attachments": [],
          "createdBy": createdBy,
          "phase": phaseIndex
        }
        if (idea['referencesNew']) {
          idea['referencesNew'] = JSON.parse(idea['referencesNew']);
          for (let ref of idea['referencesNew']) {
            payload.attachments.push({
              documentId: objectId(),
              uploadedBy: context.rootState.user.user.aspenifyUserId,
              uploadedAt: new Date(),
              documentName: ref.name,
              documentUrl: ref.link,
            })
          }
        } else if (idea['References']) {
          referencesString = idea['References'].split(',');
          if (referencesString.length > 0) {
            for (let [i, reference] of referencesString.entries()) {
              payload.attachments.push({
                documentId: objectId(),
                uploadedBy: context.rootState.user.user.aspenifyUserId,
                uploadedAt: new Date(),
                documentName: `Link ${i + 1}`,
                documentUrl: reference,
              })
            }
          }
        }
        if (idea['PM POC']) {
          let pmPoc = idea['PM POC'];
          if (pmPoc) {
            let start = pmPoc.indexOf("(");
            let end = pmPoc.indexOf(")");
            let displayName = pmPoc.slice(start + 1, end);
            let fullName = pmPoc.slice(0, start);
            fullName = fullName.trim();
            let email = displayName + "@cisco.com";
            let isValid = await validateEmail(email);
            let name = fullName.split(" ");
            const userData = {
              firstName: name[0],
              lastName: name[1]
            };
            const data = await profilePicUsersService.create(userData);
            if (isValid) {
              try {
                payload.metaData.managementFields.pmPOC = {
                  "name": fullName,
                  "profilePic": (data && data.profilePic) ? data.profilePic : "",
                  "email": email,
                }
              } catch (err) {
                console.log("Error in setting PM POC ", err);
              }
            } else {
              console.log("Invalid Email ", email);
            }
          }
        }
        if (idea['Ops POC']) {
          let opsPoc = idea['Ops POC'];
          if (opsPoc) {
            let start = opsPoc.indexOf("(");
            let end = opsPoc.indexOf(")");
            let displayName = opsPoc.slice(start + 1, end);
            let fullName = opsPoc.slice(0, start);
            fullName = fullName.trim();
            let email = displayName + "@cisco.com";
            let isValid = await validateEmail(email);
            let name = fullName.split(" ");
            const userData = {
              firstName: name[0],
              lastName: name[1]
            };
            const data = await profilePicUsersService.create(userData);
            if (isValid) {
              try {
                payload.metaData.managementFields.opsPOC = {
                  "name": fullName,
                  "profilePic": (data && data.profilePic) ? data.profilePic : "",
                  "email": email,
                }
              } catch (err) {
                console.log("Error in setting Ops POC ", err);
              }
            } else {
              console.log("Invalid Email ", email);
            }
          }
        }
        if (idea['Exec Sponsor']) {
          let execSponsor = idea['Exec Sponsor'];
          if (execSponsor) {
            let start = execSponsor.indexOf("(");
            let end = execSponsor.indexOf(")");
            let displayName = execSponsor.slice(start + 1, end);
            let fullName = execSponsor.slice(0, start);
            fullName = fullName.trim();
            let email = displayName + "@cisco.com";
            let isValid = await validateEmail(email);
            let name = fullName.split(" ");
            const userData = {
              firstName: name[0],
              lastName: name[1]
            };
            const data = await profilePicUsersService.create(userData);
            if (isValid) {
              try {
                payload.metaData.managementFields.execSponcer = {
                  "name": fullName,
                  "profilePic": (data && data.profilePic) ? data.profilePic : "",
                  "email": email,
                }
              } catch (err) {
                console.log("Error in setting Exec Sponsor ", err);
              }
            } else {
              console.log("Invalid Email ", email);
            }
          }
        }
        if (idea['Exec Sponsors']) {
          payload.metaData.managementFields.execSponsors = [];
          execSponsorsString = idea['Exec Sponsors'].split(',');
          for (let sponsor of execSponsorsString) {
            if (sponsor) {
              let start = sponsor.indexOf("(");
              let end = sponsor.indexOf(")");
              let displayName = sponsor.slice(start + 1, end);
              let fullName = sponsor.slice(0, start);
              fullName = fullName.trim();
              let email = displayName + "@cisco.com";
              let isValid = await validateEmail(email);
              if (isValid) {
                try {
                  let accountuserdata = await accountUserService.find({ "query": { "email": email, $select: ['profilePic'] } });
                  if (accountuserdata?.data?.length > 0 && accountuserdata.data[0].profilePic) {
                    payload.metaData.managementFields.execSponsors.push({
                      "name": fullName,
                      "profilePic": accountuserdata.data[0].profilePic,
                      "email": email,
                    })
                  } else {
                    let name = fullName.split(" ");
                    const userData = {
                      firstName: name[0],
                      lastName: name[1]
                    };
                    const data = await profilePicUsersService.create(userData);
                    payload.metaData.managementFields.execSponsors.push({
                      "name": fullName,
                      "profilePic": (data && data.profilePic) ? data.profilePic : "",
                      "email": email,
                    })
                  }
                } catch (err) {
                  console.log("Error in setting Exec sponsors ", err);
                }
              } else {
                console.log("Invalid Email ", email);
              }
            }
          }
        }
        if (idea['Management Notes']) {
          payload.metaData.managementFields.notes = idea['Management Notes'];
        }
        if (idea['Created At']) {
          payload.createdAt = new Date(idea['Created At']);
        }
        if (idea['Edited At']) {
          payload.updatedAt = new Date(idea['Edited At']);
        }
        let signUpForOpenmic = [];
        let userData = {
          "name": loggedInUser.name,
          "email": loggedInUser.email,
          "profilePic": loggedInUser.profilePic
        }
        if (idea['Previous Open Mic']) {
          signUpForOpenmic.push({
            "userComment": {
              "comment": "",
              "date": new Date(idea['Previous Open Mic']),
              "commentBy": userData
            },
            "configure": {
              "scheduledOn": {
                "date": new Date(idea['Previous Open Mic']),
                "from": new Date(idea['Previous Open Mic']),
                "to": new Date(idea['Previous Open Mic'])
              },
              "date": new Date(idea['Previous Open Mic']),
              "scheduledBy": userData
            },
            "markComplete": {
              "comment": "",
              "date": new Date(idea['Previous Open Mic']),
              "commentBy": userData
            }
          })
        }
        if (idea['Last Open Mic']) {
          signUpForOpenmic.push({
            "userComment": {
              "comment": "",
              "date": new Date(idea['Last Open Mic']),
              "commentBy": userData
            },
            "configure": {
              "scheduledOn": {
                "date": new Date(idea['Last Open Mic']),
                "from": new Date(idea['Last Open Mic']),
                "to": new Date(idea['Last Open Mic'])
              },
              "date": new Date(idea['Last Open Mic']),
              "scheduledBy": userData
            },
            "markComplete": {
              "comment": "",
              "date": new Date(idea['Last Open Mic']),
              "commentBy": userData
            }
          })
        }
        if (signUpForOpenmic.length > 0) {
          payload.metaData.signUpForOpenmic = signUpForOpenmic;
        }
        if (idea['Publication Status'] == 'Draft') {
          payload.status = -1;
        }
        if (idea['Status*'] == 'Inactive') {
          payload.ideaStatus = 'Inactive';
        }
        if (idea['Progress 1']) {
          payload.metaData.managementFields.progress = idea['Progress 1'];
        }
        if (idea['ID']) {
          payload.metaData.id = (idea['ID']).toString();
        }
        if (idea['Recommended to BU/Function']) {
          let buSplitArray = [];
          buSplitArray = idea["Recommended to BU/Function"].split(',');
          if (buSplitArray?.length > 0) {
            payload.metaData.managementFields.buAction = buSplitArray;
          }
        }
        if (idea["Skills"]) {
          let skillSplitArray = [];
          skillSplitArray = idea["Skills"].split(',');
          if (skillSplitArray?.length > 0) {
            payload.metaData.isTalentRequired = true;
            payload.metaData.talent = skillSplitArray;
          }
        }
        await context.dispatch('createImportInitiative', payload);
      }
    } catch (err) {
      console.log("Error in importing ideas ", err);
    }
  },
  async getReferencesDetails(context, activityId) {
    const { accessToken } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    let initiativeActivities = feathersClient.service("initiatives/activities");
    let references = await initiativeActivities.get(activityId, {
      query: {
        $populate: [
          { path: 'references.id', populate: 'contentData', select: '_id name scope contentpath type description' }
        ],
        $select: ['references']
      }
    });
    return references;
  },
  async getTeamMembers(context, initiative) {
    if(initiative?.nextPhaseInfo?.metaData?.teamId){
      let payload = {
        teamId: initiative.nextPhaseInfo.metaData.teamId,
        mutation: 'PHASE_TEAM_MEMBERS'
      }
      await context.dispatch("getTeamMembersById", payload)
    }
    if(initiative?.phaseInfo?.metaData?.teamId){
      let payload = {
        teamId: initiative.phaseInfo.metaData.teamId,
        mutation: 'CURRENT_PHASE_TEAM_MEMBERS'
      }
      context.dispatch("getTeamMembersById", payload)
    }
    if(initiative?.nextPhaseInfo?.metaData?.selectedJudgesTeamId){
      let payload = {
        teamId: initiative.nextPhaseInfo.metaData.selectedJudgesTeamId,
        mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
      }
      context.dispatch("getTeamMembersById", payload)
    } else {
      context.commit('SELECTED_TEAM_MEMBERS_PHASE', []);
    }
  },
  async likedIdea(context, payload) {

    const { accessToken } = context.rootState.user.user;

    let likeService = feathersClient.service("initiativeLikes");

    try {

      let data = {

        "accountId": context.rootState.user.user.accountId,

        "organizationId": context.rootState.user.user.organizationId,

        "initiativeId": payload.id,

        "$push": { "accountUserIds": context.rootState.user.user.aspenifyUserId }

      }

      let params = {

        "query": {

          "initiativeId": payload.id

        }

      }

      let updatedLikes = await likeService.patch(null, data, params);

      if (updatedLikes && updatedLikes.length == 0) {

        data.accountUserIds = [context.rootState.user.user.aspenifyUserId];

        await likeService.create(data);

      }

    } catch (err) {

      console.log("Error in likedIdea ", err);

    }

  },

  async unlikedIdea(context, payload) {

    const { accessToken } = context.rootState.user.user;

    const tokenString = `Bearer ${accessToken}`;

    let likeService = feathersClient.service("initiativeLikes");

    try {

      let data = {

        "$pull": { "accountUserIds": context.rootState.user.user.aspenifyUserId }

      }

      let params = {

        "query": {

          "initiativeId": payload.id

        }

      }

      await likeService.patch(null, data, params);

    } catch (err) {

      console.log("Error in unlikedIdea ", err);

    }

  },

  async followedIdea(context, payload) {

    const { accessToken } = context.rootState.user.user;

    let followService = feathersClient.service("userFollows");

    try {

      let data = {

        "accountId": context.rootState.user.user.accountId,

        "organizationId": context.rootState.user.user.organizationId,

        "accountUserId": context.rootState.user.user.aspenifyUserId,

        "$push": { "initiativeIds": payload.id }

      }

      let params = {

        "query": {

          "accountUserId": context.rootState.user.user.aspenifyUserId

        }

      }

      let updatedFollows = await followService.patch(null, data, params);

      if (updatedFollows && updatedFollows.length == 0) {

        data.initiativeIds = [payload.id];

        await followService.create(data);

      }

    } catch (err) {

      console.log("Error in followedIdea ", err);

    }

  },

  async unfollowedIdea(context, payload) {

    const { accessToken } = context.rootState.user.user;

    const tokenString = `Bearer ${accessToken}`;

    let followService = feathersClient.service("userFollows");

    try {

      let data = {

        "$pull": { "initiativeIds": payload.id }

      }

      let params = {

        "query": {

          "accountUserId": context.rootState.user.user.aspenifyUserId

        }

      }

      await followService.patch(null, data, params);

    } catch (err) {

      console.log("Error in unfollowedIdea ", err);

    }

  },
  async updateInitiativeById(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativesServices = feathersClient.service("initiatives");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let result = await initiativesServices.patch(payload.id, {
        $set: {
          [payload.data.key]: payload.data.value,
        }
      }, {
        headers: {
          "Authorization": tokenString
        },
      });


    } catch (error) {
      console.log(error)
    }
  },
  async updateInitiativeMetaData(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativesServices = feathersClient.service("initiatives");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let result = await initiativesServices.patch(payload.id, {
        $set: {
          [payload.data.label]: payload.data.value,
        }
      }, {
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (error) {
      console.log(error)
    }
  },
  async setInitiativeMetaData(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativesServices = feathersClient.service("initiatives");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let result = await initiativesServices.find(
        { query: { _id: payload } },
        {
          headers: {
            "Authorization": tokenString
          }

        });
      context.commit("INITIATIVE_METADATA", result?.data[0]?.metaData)
    } catch (error) {
      console.log(error)
    }
  },
  async getWorkspaceUsers(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const GetWorkspaceUserDetails = feathersClient.service("/workspace/aggregation");
    const tokenString = `Bearer ${accessToken}`;
    let aggregationQuery = [];
    let data = {}
    data.aggregationQuery = JSON.stringify(aggregationQuery);
    try {
      let workspaceUsers = await GetWorkspaceUserDetails.find({
        "query": data,
        "headers": {
          "Authorization": tokenString
        }
      });
      return workspaceUsers;
    } catch (error) {
      console.log(error)
    }
  },
  async getWorkspaceUsersList(context, payload){
    const { accessToken } = context.rootState.user.user;
    let organizationId = context.rootState.user.user.organizationId;
    const tokenString = `Bearer ${accessToken}`;
    const orgService = feathersClient.service("organizations");
    if(accessToken){
      try {
        let orgQuery = {
          "organizationId" : organizationId,
          "$select": ['name', '_id', 'users'],
          "$populate": [
            {
              "path": "users.accountUserId",
              "select": "firstName lastName email profilePic "
            }
          ]
        }
        let orgData = await orgService.get(organizationId,{
          query: orgQuery,
          headers: {
            "Authorization": tokenString
          },
        });
        let users = [];
        for(let user of orgData.users){
          user.accountUserId.name = `${user.accountUserId.firstName} ${user.accountUserId.lastName}`;
          users.push(user.accountUserId);
        }
        context.commit("SET_WORKSPACE_USERS",users);
      } catch(e){
        console.log("Error in fetching members from suborganization ", e);
      }
    }
  },
  async processIdeaParticipants(context, payload) {
    let subOrg = {}
    subOrg.teamId = payload.teamId;
    let selectedTeamUniqueUsersArray = [...new Map(payload.selectedTeamUsersArray.map(user => [user['email'], user])).values()];
    let originalTeamUniqueUsersArray = [...new Map(payload.originalTeamUsersArray.map(user => [user['email'], user])).values()];
    var onlyInselectedTeamUniqueUsersArray = selectedTeamUniqueUsersArray.filter(compareArray(originalTeamUniqueUsersArray, 'email'));
    var onlyInoriginalTeamUniqueUsersArray = originalTeamUniqueUsersArray.filter(compareArray(selectedTeamUniqueUsersArray, 'email'));
    let differenceInBothArrays = onlyInselectedTeamUniqueUsersArray.concat(onlyInoriginalTeamUniqueUsersArray);
    subOrg.users = differenceInBothArrays;
    return await context.dispatch('updateIdeaSubOrg', subOrg);
  },
  async updateIdeaSubOrg(context, payload) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const removeUserTeamsService = await feathersClient.service("/suborganizations/removeuser")
      let usersArray = []
      for (let user of payload.users) {
        if (user.accountUserId) {
          // remove user from Idea Team.

          let data = {
            "accountId": context.rootState.user.user.accountId,
            "accountUserId": user.accountUserId,
            "email": user.email?.toLowerCase(),
            "organizationId": context.rootState.user.user.organizationId,
            "teamId": payload.teamId
          }
          removeUserTeamsService.patch(data.teamId, data)
        } else {
          user.firstName = user.name.split(' ').slice(0, -1)[0];
          user.lastName = user.name.split(' ').slice(0,-1)[1];
          user.accountId = context.rootState.user.user.accountId;
          user.organizationId = context.rootState.user.user.organizationId;
          user.teamId = payload.teamId;
          if (user.role == "TeamLead") {
            user.userRole = 'Manager'
          }
          usersArray.push(user);
        }
      }
      //add users to Idea Team.
      const addUserSservice = feathersClient.service('/accountusers/apiusers');
      let addedUsers = await addUserSservice.create(usersArray, {
        headers: {
          "Authorization": tokenString
        },
      }, {
        user: context.rootState.user.user
      });
      return addedUsers;
    } catch (error) {
      console.log("error in updateIdeaSubOrg ", error)
    }
  },
  async addComment(context, payload) {
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
      const { accessToken } = context.rootState.user.user;
      const initiativeCommentsService = feathersClient.service("initiativeComments");
      let organizationId = context.rootState.user.user.organizationId;
      let accountId = context.rootState.user.user.accountId;
      let initiativeId = payload.initiativeId;
      let accountUserId = context.rootState.user.user.aspenifyUserId
      const tokenString = `Bearer ${accessToken}`;
      let payloadToPass = {
        accountId: accountId,
        organizationId: organizationId,
        initiativeId: initiativeId,
        recipents: payload.recipients,
        notes: {
          accountUserId: accountUserId,
          content: payload.comment,
          notedate: new Date(),
          parentCommentId: payload.parentCommentId
        },
      };
      try {
        let result = await initiativeCommentsService.create(payloadToPass, {
          headers: {
            Authorization: tokenString,
          },
        });

      } catch (error) {
        console.log(error)
      }
    } else {
      alert("Submissions are turned off");
    }
  },
  async getAllComments(context, payload) {
    // return false;
    const { accessToken } = context.rootState.user.user;
    const initiativeCommentsService = feathersClient.service("initiativeComments");

    const tokenString = `Bearer ${accessToken}`;
    try {
      const query = {
        initiativeId: payload,
        $sort: {
          'notes.notedate': 1,
        },
        $populate: [
          {
            path: "notes.accountUserId",
            select: "_id firstName lastName email profilePic",
          },
          {
            path: "recipents",
            select: "_id firstName lastName email",
          },
        ],

      };
      const result = await initiativeCommentsService.find({
        query: query,
        headers: {
          Authorization: tokenString,
        },
      });
      let results = result?.data

      if (results) {
        results = results.map((singleRecord) => {
          let filterResults = results.filter((filteredElement) => {
            return filteredElement?.notes?.parentCommentId === singleRecord._id;
          });
          singleRecord.subComments = filterResults;
          return singleRecord.recipents.length > 0 ? { ...singleRecord, isPrivateMessage: true } : singleRecord;
        });
        // delete if not parentCommentId key is present and subcomments array length is 0,
        //  then it is a subcomment and has no more comments attached to this same comment
        results = results.filter((singleRecord) => {
          return !singleRecord.notes.hasOwnProperty("parentCommentId")
        });
      }

      return results;
    } catch (error) {
      console.log(error)
    }
  },
  async deleteComment(context, payload) {
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
      const { accessToken } = context.rootState.user.user;
      const initiativeCommentsService = feathersClient.service("initiativeComments");
      const tokenString = `Bearer ${accessToken}`;
      try {
        await initiativeCommentsService.remove(
          null,
          {
            query: {
              _id: payload._id,
            },
          },
          {
            headers: {
              Authorization: tokenString,
            },
          }
        );
        // return workspaceUsers;
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Submissions are turned off");
    }
  },
  //update Comments
  async updateComments(context, payload) {
    let createInitiativeStatus = await checkCreateInitiative(feathersClient, context);
    if(createInitiativeStatus){
      const { accessToken } = context.rootState.user.user;
      const initiativeCommentsService = feathersClient.service("initiativeComments");
      const tokenString = `Bearer ${accessToken}`;
      try {

        let result = await initiativeCommentsService.patch(payload._id, {
          $set: {
            "notes.content": payload.content,
          }
        }, {
          headers: {
            "Authorization": tokenString
          },
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Submissions are turned off");
    }
  },
  async sendHelpRequest(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const supportRequestService = feathersClient.service("supportRequest");
    let organizationId = context.rootState.user.user.organizationId;
    const subOrgService = feathersClient.service("suborganizations");
    const businessEmailId = process.env.VUE_APP_BUSINESS_EMAIL_ID;
    const tokenString = `Bearer ${accessToken}`;
    const supportEmailId = process.env.VUE_APP_SUPPORT_EMAIL_ID;
    let emails = [];
    try {
      if (payload.helpTo === "programemanagement") {
        emails = [businessEmailId]
      } else {
        emails = [supportEmailId];
      }
      // return false;
      let payloadToPass = {
        "type": "sendSupportRequest",
        "emails": emails,
        "event": "customEvent",
        "emailSubject": payload.subject,
        "emailBody": `<p>${payload.issue}</p> <p>This email has been sent by “${context.rootState.user.user.name} (${context.rootState.user.user.email})"</p>`,
        "fromEmail": context.rootState.user.user.email,
        "createdBy": context.rootState.user.user.aspenifyUserId
      };

      payloadToPass.eventData = {
        "type": "sendSupportRequest",
        "emails": emails,
        "event": "customEvent",
        "createdBy": context.rootState.user.user.aspenifyUserId
      }
      const result = await supportRequestService.find({
        query: payloadToPass,
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  async importUsers(context, payload) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const addUsersService = feathersClient.service('/accountusers/apiusers');
      const aspenifyUser = context.rootState.user.user;
      let organizationId = context.rootState.user.user.organizationId;
      let accountId = context.rootState.user.user.accountId;
      let orgTeams = [];
      const OrgService = feathersClient.service('organizations');
      let teams = await OrgService.get(organizationId, {
        "query": {
          $select: ["_id", "name", "subOrganizations"],
          $populate: [{
            path: 'subOrganizations',
            select: '_id name'
          }]
        }
      });
      if(teams?.subOrganizations?.length>0){
        orgTeams = teams.subOrganizations;
      }
      let usersArray = [];
      if (payload.length > 0) {
        for (let user of payload) {
          if(user['Name'] && user['Cisco email address']){
            let fullName = user['Name'];
            let email = user['Cisco email address'];
            let i = fullName.indexOf(' ');
            user.first_name = fullName.slice(0, i).trim();
            user.last_name = fullName.slice(i).trim();
            user.email = email.trim();
          }
          if (user.email.includes("@cisco.")) {
            let userData = {
              "firstName": user.first_name,
              "lastName": user.last_name,
              "email": user.email,
              "accountId": accountId,
              "organizationId": organizationId
            }
            if(user['Team Name']){
              let foundTeam = orgTeams.find(team => team.name == user['Team Name']);
              let teamId = foundTeam?._id ? foundTeam._id : null;
              if(teamId){
                userData.teamId = teamId;
              }
            }
            if(user["Role"] == "Manager" || user["Role"] == "Member" || user["Role"] == "workspaceOwner"){
              userData.userRole = user["Role"];
            }
            usersArray.push(userData);
          }
        }
        console.time("Time Taken to execute - Final")
        if (usersArray.length) {
          const splitArray = chunks(usersArray, 25);
          console.log("splitArray ", splitArray);
          if (splitArray.length > 0) {
            let counter = 0;
            for (let arrayChunk of splitArray) {
              counter++;
              console.log(`Batch ${counter} started`)
              console.time(`Time Taken to execute - Batch ${counter}`)
              await addUsersService.create(arrayChunk, {
                headers: {
                  "Authorization": tokenString
                },
              }, {
                user: aspenifyUser
              });
              console.timeEnd(`Time Taken to execute - Batch ${counter}`)
            }
          }
        }
        console.timeEnd("Time Taken to execute - Final")
      }
    } catch (error) {
      console.log("Error while importing users ", error);
    }
  },
  async importLikesFollows(context, payload) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const accountUserService = feathersClient.service("accountusers");
      const initiativeService = feathersClient.service("initiatives");
      let likeService = feathersClient.service("initiativeLikes");
      let followService = feathersClient.service("userFollows");
      const initiativeCommentsService = feathersClient.service("initiativeComments");
      const aspenifyUser = context.rootState.user.user;
      let organizationId = context.rootState.user.user.organizationId;
      let accountId = context.rootState.user.user.accountId;
      let usersArray = [];
      const usersDataMap = new Map();
      const ideaMap = new Map();
      let usersData = await accountUserService.find();
      for (let user of usersData.data) {
        usersDataMap.set(user.email, user);
      }
      const query = {
        "metaData.id": { "$exists": true },
        $select: ['_id', 'metaData.id']
      };
      let initiatives = await initiativeService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      for (let idea of initiatives.data) {
        ideaMap.set(idea.metaData.id, (idea._id).toString());
      }
      if (payload.length > 0) {
        let totalIdeas = payload.length;
        let executedIdeas = 0;
        for (let idea of payload) {
          let initiativeId = ideaMap.get(idea.id);
          if (initiativeId) {
            executedIdeas++;
            console.log(`Import Like/Follow/Comments Status : ${executedIdeas}/${totalIdeas}`);
            console.log("Idea in process from imported json file : ", idea.title);
            console.log("Initiative Id from the db : ", initiativeId);
            //likes
            let accountUserIds = [];
            if (idea?.like?.length > 0) {
              for (let email of idea.like) {
                let accountUserId = usersDataMap.get(email);
                if (accountUserId?._id) {
                  accountUserIds.push(accountUserId._id);
                }
              }
            }
            if (accountUserIds.length > 0) {
              let data = {
                "accountId": accountId,
                "organizationId": organizationId,
                "initiativeId": initiativeId,
                "accountUserIds": accountUserIds
              }
              await likeService.create(data);
            }
            //follows
            if (idea?.favorite?.length > 0) {
              for (let email of idea.favorite) {
                let accountUserId = usersDataMap.get(email);
                if (accountUserId?._id) {
                  let data = {
                    "accountId": accountId,
                    "organizationId": organizationId,
                    "accountUserId": accountUserId._id,
                    "$push": { "initiativeIds": initiativeId }
                  }
                  let params = {
                    "query": {
                      "accountUserId": accountUserId._id
                    }
                  }
                  let updatedFollows = await followService.patch(null, data, params);
                  if (updatedFollows && updatedFollows.length == 0) {
                    data.initiativeIds = [initiativeId];
                    await followService.create(data);
                  }
                }
              }
            }
            //comments
            if (idea?.comments?.length > 0) {
              for (let comment of idea.comments) {
                if (comment.private == false || !comment.private) {
                  let relation = "parent";
                  let relationId = null;
                  await processImportComments(initiativeCommentsService, comment, usersDataMap, accountId, organizationId, initiativeId, relation, relationId)
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log("Error while importing users ", error);
    }
  },
  async importBU(context, payload) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const initiativeService = feathersClient.service("initiatives");
      let totalIdeas = payload.length;
      let executedIdeas = 0;
      if (payload?.length > 0) {
        for (let buData of payload) {
          let initiativeData = await initiativeService.find({ "query": { "metaData.id": buData["ID"] } });
          if (initiativeData?.data?.length > 0) {
            let initiativeId = initiativeData.data[0]._id;
            executedIdeas++;
            console.log("Idea in process : ", initiativeData.data[0].name);
            console.log("Initiative Id from the db : ", initiativeId);
            console.log(`Import BU : ${executedIdeas}/${totalIdeas}`);
            let buSplitArray = [];
            buSplitArray = buData["Recommended to BU/Function"].split(',');
            if (buSplitArray?.length > 0) {
              await initiativeService.patch(initiativeId, {
                $set: {
                  "metaData.managementFields.buAction": buSplitArray
                }
              }, {
                headers: {
                  "Authorization": tokenString
                },
              });
              console.log("BU updated for idea : ", initiativeData.data[0].name);
              console.log("-------------------------------------------------------");
            }
          } else {
            console.log("Initiative not found for id ", buData["ID"]);
            console.log("-------------------------------------------------------");
          }
        }
      }
    } catch (error) {
      console.log("Error while importing BU ", error);
    }
  },
  async importSkills(context, payload) {
    try {
      const { accessToken } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const initiativeService = feathersClient.service("initiatives");
      let totalIdeas = payload.length;
      let executedIdeas = 0;
      if (payload?.length > 0) {
        for (let skillData of payload) {
          let initiativeData = await initiativeService.find({ "query": { "metaData.id": skillData["ID"] } });
          if (initiativeData?.data?.length > 0) {
            let initiativeId = initiativeData.data[0]._id;
            executedIdeas++;
            console.log("Idea in process : ", initiativeData.data[0].name);
            console.log("Initiative Id from the db : ", initiativeId);
            console.log(`Import Skills : ${executedIdeas}/${totalIdeas}`);
            let skillSplitArray = [];
            skillSplitArray = skillData["Skills"].split(',');
            if (skillSplitArray?.length > 0) {
              await initiativeService.patch(initiativeId, {
                $set: {
                  "metaData.isTalentRequired": true,
                  "metaData.talent": skillSplitArray
                }
              }, {
                headers: {
                  "Authorization": tokenString
                },
              });
              console.log("Skills updated for idea : ", initiativeData.data[0].name);
              console.log("-------------------------------------------------------");
            }
          } else {
            console.log("Initiative not found for id ", skillData["ID"]);
            console.log("-------------------------------------------------------");
          }
        }
      }
    } catch (error) {
      console.log("Error while importing skills ", error);
    }
  },

  async deleteAllIdeas(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativesServices = feathersClient.service("initiatives");
    const subOrgService = feathersClient.service("suborganizations");
    const parentOrganizationId = process.env.VUE_APP_IDEA_TEAM_ID;
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        "accountId": context.rootState.user.user.accountId,
        "organizationId": context.rootState.user.user.organizationId,
        "parentInitiativeId": { $exists: false },
        $select: ['accountId', 'name', 'organizationId', '_id', 'baseId'],
      }

      const result = await initiativesServices.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      //get Parent team data
      let subOrgQuery = {
        $select: ['subOrganizations', 'name', 'parentOrganizationId', '_id'],
      }
      let subOrgData = await subOrgService.get(parentOrganizationId, {
        query: subOrgQuery,
        headers: {
          "Authorization": tokenString
        },
      });


      //remove all ideas
      if (result?.data?.length > 0) {
        for (let i = 0; i < result?.data?.length; i++) {
          try {
            console.log("Idea deletion in progress ... ", result?.data[i]?._id);
            await initiativesServices.remove(result?.data[i]?._id, {
              query: {
                "accountId": context.rootState.user.user.accountId,
                "organizationId": context.rootState.user.user.organizationId,
              },
            });
            console.log("Idea deleted... ", result?.data[i]?._id);
          } catch (error) {
            console.log("Error in deleting Idea ", result?.data[i]?._id);
            console.log("Error : ", error)
          }
        }
      }

      // remove all suborganizations
      if (subOrgData?.subOrganizations?.length > 0) {
        for (let i = 0; i < subOrgData?.subOrganizations?.length; i++) {
          try {
            console.log("Team deletion in progress ... ", subOrgData?.subOrganizations[i]);
            await subOrgService.remove(subOrgData?.subOrganizations[i], {
              query: {
                "accountId": context.rootState.user.user.accountId,
                "organizationId": context.rootState.user.user.organizationId,
              },
            });
            console.log("Team deleted... ", subOrgData?.subOrganizations[i]);
          } catch (error) {
            console.log("Error in deleting Team ", result?.data[i]?._id);
            console.log("Error : ", error)
          }
        }
      }

      let updatedParentIdea = await subOrgService.patch(
        parentOrganizationId,
        {
          $set: { "subOrganizations": [] },

        },
        {
          headers: {
            Authorization: tokenString,
          },
        }
      );
      context.dispatch('getInitiatives', payload)

    } catch (error) {
      console.log("error while deleting ideas", error);
    }
  },
  async updateInitiativeMeta(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiatives = feathersClient.service("/initiatives");
    const tokenString = `Bearer ${accessToken}`;
    try {

      let result = await initiatives.patch(payload._id, {
        $set: {
          "metaData.action": payload.metaData
        },
        headers: {
          "Authorization": tokenString
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  async updatePlaybook(context, payload) {
    try {
      const playbooks = feathersClient.service("playbooks");
      await playbooks.patch(payload.id, {
        $set: payload.updateData
      })
      await context.dispatch('readPlaybook');
    } catch (error) {
      console.log("Error in updating playbook ", error)
    }
  },
  async updateIdea(context, payload) {
    try {
      const initiatives = feathersClient.service("/initiatives");
      let data = {
        id: payload.id
      }
      context.dispatch('getJudgingInitiativeById', data);
      let updatedIdea = await initiatives.patch(payload.id, payload.updateData);
      if(updatedIdea?.metaData?.reviews?.length>0){
        let judgeScore = 0;
        let reviewData = updatedIdea.metaData.reviews.filter((review)=> review.lastUpdatedBy == context.rootState.user.user.aspenifyUserId);
        if(reviewData?.length>0){
          for (const [key, value] of Object.entries(reviewData[0])) {
            if(key != 'comments'){
              if(typeof value == 'string'){
                if(value.charCodeAt(0) >=48 && value.charCodeAt(0) <=57 && key != 'lastUpdatedBy' && key != 'updatedAt'){
                  let score = value.charAt(0);
                  judgeScore += Number(score);
                }
              } else if(typeof value == 'object'){
                if(value[0].charCodeAt(0) >=48 && value[0].charCodeAt(0) <=57 && key != 'lastUpdatedBy' && key != 'updatedAt'){
                  let score = value[0].charAt(0);
                  judgeScore += Number(score);
                }
              }
            }
          }
          let params = {
            "query": {
              [`metaData.reviews.lastUpdatedBy`]: context.rootState.user.user.aspenifyUserId
            }
          }
          let dataToSet = {"$set":{"metaData.reviews.$.judgeScore": judgeScore}}
          await initiatives.patch(updatedIdea._id, dataToSet, params);
        }
      }
    } catch (error) {
      console.log("Error in updating ide ", error)
    }
  },
  async updateJudgingIdea(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativeService = feathersClient.service("initiatives");
    const tokenString = `Bearer ${accessToken}`;
    let updateData = {"$set":{}};
    for(let key in payload.dataToUpdate){
      updateData.$set[`${payload.objectToUpdate}.$.${key}`] = payload.dataToUpdate[key];
    }
    try {
      let params = {
        "query": {
          [`${payload.fieldToMatch}`]: payload.dataToUpdate.lastUpdatedBy
        }
      }
      let updatedIdea = await initiativeService.patch(payload.id, updateData, params);
      let data = {
        id: payload.id
      }
      context.dispatch('getJudgingInitiativeById', data);
      if(updatedIdea?.metaData?.reviews?.length>0){
        let judgeScore = 0;
        let reviewData = updatedIdea.metaData.reviews.filter((review)=> review.lastUpdatedBy == payload.dataToUpdate.lastUpdatedBy);
        if(reviewData?.length>0){
          for (const [key, value] of Object.entries(reviewData[0])) {
            if(key != 'comments'){
              if(typeof value == 'string'){
                if(value.charCodeAt(0) >=48 && value.charCodeAt(0) <=57 && key != 'lastUpdatedBy' && key != 'updatedAt'){
                  let score = value.charAt(0);
                  judgeScore += Number(score);
                }
              } else if(typeof value == 'object'){
                if(value[0].charCodeAt(0) >=48 && value[0].charCodeAt(0) <=57 && key != 'lastUpdatedBy' && key != 'updatedAt'){
                  let score = value[0].charAt(0);
                  judgeScore += Number(score);
                }
              }
            }
          }
          let dataToSet = {"$set":{"metaData.reviews.$.judgeScore": judgeScore}}
          await initiativeService.patch(payload.id, dataToSet, params);
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  },
  async updateStatus(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const initiativeService = feathersClient.service("initiatives");
    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await initiativeService.patch(
        payload.selectedInitiative.id,
        {
          status: payload.status,
        },
        {
          headers: {
            Authorization: tokenString,
          },
        }
      );
    } catch (error) {
      console.log("error", error)
    }
  },
  async getTeamMembersById(context, payload){
    const { accessToken } = context.rootState.user.user;
    const subOrgService = feathersClient.service("suborganizations");
    const tokenString = `Bearer ${accessToken}`;
    try {
      //get Parent team data
      let subOrgQuery = {
        $select: ['name', '_id', 'members'],
        "$populate": [
          {
            "path": "members.accountUserId",
            "select": "firstName lastName email profilePic "
          }
        ]
      }
      let subOrgData = await subOrgService.get(payload.teamId, {
        query: subOrgQuery,
        headers: {
          "Authorization": tokenString
        },
      });
      for(let member of subOrgData.members){
        member.firstName = member.accountUserId.firstName;
        member.lastName = member.accountUserId.lastName;
        member.email = member.accountUserId.email;
        member.profilePic = member.accountUserId.profilePic;
        member._id = member.accountUserId._id;
        delete member.accountUserId;
      }
      let sortedMembers = subOrgData.members.sort((a, b) => {
        const result = a.firstName.localeCompare(b.firstName);
        return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
      })
      context.commit(payload.mutation, subOrgData.members);
      if(context.state.selectedTeamMembersPhase){
        context?.state?.phaseTeamMembers?.map((member)=>{
          member.isChecked = false;
          context.state.selectedTeamMembersPhase.map((selectedMember)=>{
            if(member._id == selectedMember._id){
              member.isChecked = true;
            }
          })
        })
      }
    } catch(err){
      console.log("Error in fetching team members for assigning judges", err)    
    }
  },
  async manageTeam(context, payload){
    try {
      let createdTeam;
      const { accessToken } = context.rootState.user.user;
      const subOrgService = feathersClient.service("suborganizations");
      const tokenString = `Bearer ${accessToken}`;
      const addUsersService = feathersClient.service('/accountusers/apiusers');
      if(payload.payload.selectedJudgesTeamId){
        let members = [];
        for(let user of payload.payload.users){
          members.push({"role": 'Manager', 'accountUserId': user._id});
        }
        await subOrgService.patch(payload.payload.selectedJudgesTeamId,
          {$set: { 'members': members }},
          {'user': context.rootState.user.user}
        );
      } else {
        const d1 = new Date();
        const result = d1.getTime();
        let teamObj = {
          name: `${payload.payload.name} ${payload.payload.ideaId} ${result}`, //Phase Name + ideaId
          summary: 'Automatic team created for phase',
          organizationId: context.rootState.user.user.organizationId,
          parentOrganizationId: process.env.VUE_APP_PHASE_JUDGES_TEAM_ID,
          active: true,
          accountId: context.rootState.user.user.accountId,
          createdBy: context.rootState.user.user.aspenifyUserId,
          activityString: 'onBehalfOf'
        }
        teamObj.slug = slugify(teamObj.name)
        createdTeam = await subOrgService.create(teamObj, { user: context.rootState.user.user });
        for (let userObj of payload.payload.users) {
          userObj.firstName = userObj.firstName;
          userObj.lastName = userObj.lastName;
          userObj.email = userObj.email;
          userObj.accountId = context.rootState.user.user.accountId;
          userObj.organizationId = context.rootState.user.user.organizationId;
          userObj.teamId = createdTeam._id;
          userObj.userRole = 'Manager'
        }
        let addedUsers = await addUsersService.create(payload.payload.users, {
          headers: {
            "Authorization": tokenString
          },
        }, {
          user: context.rootState.user.user
        });
        let initiativeActivitiesService = feathersClient.service("initiatives/activities");
        let dataToUpdate = {
          "metaData.selectedJudgesTeamId" : createdTeam._id 
        }
        await initiativeActivitiesService.patch(payload.payload.phaseId, dataToUpdate, {
          headers: {
            "Authorization": tokenString
          }
        });
      }
      let nextPhaseInfo = payload.data.initiative.nextPhaseInfo;
      if(payload.payload.selectedJudgesTeamId){        
        nextPhaseInfo.selectedJudgesTeamId = payload.payload.selectedJudgesTeamId;
      } else {
        nextPhaseInfo.selectedJudgesTeamId = createdTeam._id;
      }
      if(!payload.isBulk){
      await context.commit("UPDATE_SELECTED_INITIATIVE_NEXTPHASEINFO", nextPhaseInfo);
      }
      if(nextPhaseInfo.selectedJudgesTeamId){
        let payload = {
          teamId: nextPhaseInfo.selectedJudgesTeamId,
          mutation: 'SELECTED_TEAM_MEMBERS_PHASE'
        }
        await context.dispatch("getTeamMembersById", payload);
      }
    } catch (error) {
      console.log("🚀 ~ file: initiative.js ~ line 5454 ~ manageTeam ~ error", error);      
    }
  },
  async getJudgingTeamDetails(context, selectedInitiative){
    const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    try {
        if (selectedInitiative.activities[selectedInitiative?.phaseIndex]) {
          const initiatives = feathersClient.service("initiatives");
          let phaseInfo = selectedInitiative.activities[selectedInitiative.phaseIndex];
          selectedInitiative.phaseInfo = phaseInfo;
          if (phaseInfo?.metaData?.isJudgingRequired && phaseInfo?.metaData?.selectedJudgesTeamId) {
            let selectedJudgesTeamId = phaseInfo.metaData.selectedJudgesTeamId;
            const subOrgService = feathersClient.service("suborganizations");
            try {
              let subOrgQuery = {
                $select: ['name', '_id', 'members'],
                "$populate": [{
                  "path": "members.accountUserId",
                  "select": "firstName lastName email profilePic "
                }]
              }
              let subOrgData = await subOrgService.get(selectedJudgesTeamId, {
                query: subOrgQuery,
                headers: {
                  "Authorization": tokenString
                },
              });
              selectedInitiative.seletedPhaseJudgesDetails = subOrgData;
              const membersDataMap = new Map();
              for (let member of subOrgData.members) {
                membersDataMap.set(member.accountUserId._id, member);
              }
              selectedInitiative.initiativeId = selectedInitiative.id;
              selectedInitiative.phaseInfo = phaseInfo;
              const result = await initiatives.find({
                query: {
                  "teamId": selectedJudgesTeamId
                },
                headers: {
                  "Authorization": tokenString
                },
              });
              let reviewCompletedBy = [];
              let reviewNotCompletedBy = [];
              let judgingInitiativeData = {};
              if (result?.data?.length > 0 && result?.data[0]?.metaData?.reviews?.length > 0) {
                for (let review of result.data[0].metaData.reviews) {
                  let updatedByInfo = membersDataMap.get((review.lastUpdatedBy).toString());
                  reviewCompletedBy.push(updatedByInfo);
                  membersDataMap.delete((review.lastUpdatedBy).toString());
                }
              }
              for (let item of membersDataMap) {
                let updatedByInfo = membersDataMap.get((item[0]).toString());
                reviewNotCompletedBy.push(updatedByInfo);
              }
              if (result?.data?.length > 0) {
                judgingInitiativeData.reviewCompletedBy = reviewCompletedBy;
                judgingInitiativeData.reviewNotCompletedBy = reviewNotCompletedBy;
              }
              judgingInitiativeData.initiativeId = selectedInitiative._id;
              judgingInitiativeData.name = result.data[0].name;
              judgingInitiativeData._id = result.data[0]._id;
              judgingInitiativeData.metaData = result.data[0].metaData;
              context.commit('UPDATE_JUDGING_INITIATIVE_DETAILS', judgingInitiativeData);
            } catch (error) {
              console.log("Error in fetching team data for selectedJudgesTeamId ", error)
            }
          }
        }
      } catch (error) {
        console.log("🚀 ~ file: initiative.js ~ line 6228 ~ getJudgingTeamDetails ~ error", error)
      }
  },
  setSelectedRound(context, payload){
    context.commit("SELECTED_ROUND_OPTION", payload);
  },
  async createUsers(context, payload){
    try {
      const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
      const tokenString = `Bearer ${accessToken}`;
      const addUsersService = feathersClient.service('/accountusers/apiusers');
      let addedUsers = await addUsersService.create(payload, {
        headers: {
          "Authorization": tokenString
        },
      }, {
        user: context.rootState.user.user
      });
      return addedUsers;
    } catch (error) {
      console.log("Error in createUsers ",error);
    }
  },
  
}

const getInitiativeRanks= async function(context,judgingPlaybookBaseId,isHistory) {
  let rankArrayByAverageRevenuePerHead = {};
  // let rankArrayByAverageActivationsPerHead = {};
  const { accessToken } = context.rootState.user.user;
  let judgingPlaybookBaseIds = [];
  if (judgingPlaybookBaseId) {
    judgingPlaybookBaseIds.push(judgingPlaybookBaseId);
  }
  const initiatives = feathersClient.service("initiatives");
  const andCondition = [];
  andCondition.push({
    parentInitiativeId: { $exists: true },
    baseId: { $in: judgingPlaybookBaseIds },
  });
  if(isHistory){
    andCondition.push({ status: { $in: [2] } });
  } else {
    andCondition.push({ status: { $in: [0, 1, 2] } });
  }
  const query = {
    $and: andCondition,
    $sort: {
      createdAt: -1,
    },
    $select: [
      "name",
      "parentInitiativeId",
    ],
    $populate: [
      {
        path: "parentInitiativeId",
        select: "_id name metaData",
      },
    ],
  };
  try {
    const tokenString = `Bearer ${accessToken}`;
    const result = await initiatives.find({
      query: query,
      headers: {
        "Authorization": tokenString
      },
    });
    if (result.data && result.data.length > 0) {
      for (let index = 0; index < result.data.length; index++) {
        const element = result.data[index];
        if(element?.parentInitiativeId?.metaData?.revenueDate &&
          // element?.parentInitiativeId?.metaData?.activationDate &&
          element?.parentInitiativeId?.metaData?.coreTeamSize){
          let item={}
          item.id=element._id
          item.revenue=element.parentInitiativeId.metaData.revenueDate.replace(/[$,]/g, "")
          // item.activations=element.parentInitiativeId.metaData.activationDate
          item.coreTeamSize=element.parentInitiativeId.metaData.coreTeamSize
          item.averageRevenuePerHead=item.revenue/item.coreTeamSize
          item.name = element.name;
         // item.averageActivationsPerHead=item.activations/item.coreTeamSize
          if(item.averageRevenuePerHead 
            // && item.averageActivationsPerHead
            ){
            if(!rankArrayByAverageRevenuePerHead[element.parentInitiativeId.metaData.challengeType]
              //  && !rankArrayByAverageActivationsPerHead[element.parentInitiativeId.metaData.challengeType]
               ){
              rankArrayByAverageRevenuePerHead[element.parentInitiativeId.metaData.challengeType]=[]
              // rankArrayByAverageActivationsPerHead[element.parentInitiativeId.metaData.challengeType]=[]
            }
            rankArrayByAverageRevenuePerHead[element.parentInitiativeId.metaData.challengeType].push(item)
            // rankArrayByAverageActivationsPerHead[element.parentInitiativeId.metaData.challengeType].push(item)
          }
        }
      }
      Object.keys(rankArrayByAverageRevenuePerHead).forEach(function(key) {
        rankArrayByAverageRevenuePerHead[key].sort((a, b) => b.averageRevenuePerHead - a.averageRevenuePerHead)
      });
      // Object.keys(rankArrayByAverageActivationsPerHead).forEach(function(key) {
      //   rankArrayByAverageActivationsPerHead[key].sort((a, b) => b.averageActivationsPerHead - a.averageActivationsPerHead)
      // });
      // rankArrayByAverageRevenuePerHead.sort((a, b) => b.averageRevenuePerHead - a.averageRevenuePerHead);
      // rankArrayByAverageActivationsPerHead.sort((a, b) => b.averageActivationsPerHead - a.averageActivationsPerHead);
      if(isHistory){
        context.commit('SET_RANK_BY_REVENUE_HISTORY', rankArrayByAverageRevenuePerHead);
        // context.commit('SET_RANK_BY_ACTIVATION_HISTORY', rankArrayByAverageActivationsPerHead);
      } else {
        context.commit('SET_RANK_BY_REVENUE', rankArrayByAverageRevenuePerHead);
        // context.commit('SET_RANK_BY_ACTIVATION', rankArrayByAverageActivationsPerHead);
      }
    }
  } catch (err) {
    console.log(err);
    context.commit('SET_LOADING_FLAG', false);
  }
}
const getInitiativeLikes = async function (likeService, initiativeId) {

  let ideaLikedBy = [];

  try {

    let accountUserData = await likeService.find({
      "query": {

        "initiativeId": initiativeId,

        "$select": ["accountUserIds"],

        "$populate": [

          {

            "path": "accountUserIds",

            "select": "firstName lastName email profilePic ",

          }

        ]

      }
    });

    if (accountUserData?.data?.length > 0) {

      ideaLikedBy = accountUserData.data[0].accountUserIds.map(m => {
        m.accountUserId = m._id
        return m
      });

    }

  } catch (err) {

    console.log("err in getInitiativeLikes ", err)

  }

  return ideaLikedBy;

}

const getInitiativeFollowers = async function (followService, initiativeId) {

  let ideaFollowedBy = [];

  try {

    let accountUserData = await followService.find({
      "query": {

        "initiativeIds": initiativeId,

        "$select": ["accountUserId"],

        "$populate": [

          {

            "path": "accountUserId",

            "select": "firstName lastName email profilePic",

          }

        ]

      }
    });

    if (accountUserData?.data?.length > 0) {

      ideaFollowedBy = accountUserData.data.map(a => {
        let user = a.accountUserId
        user.accountUserId = user._id
        return user
      });

    }

  } catch (err) {

    console.log("err in getInitiativeFollowers ", err)

  }

  return ideaFollowedBy;
}

const compareArray = function (otherArray, paramToCompare) {
  return function (current) {
    return otherArray.filter(function (other) {
      return other[paramToCompare] == current[paramToCompare]
    }).length == 0;
  }
}
const validateEmail = async (email) => {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

const processInitiativeActivities = async function (activity, raArray, ciArray, feathersClient, context, createInitiativeStatus) {
    if(createInitiativeStatus){
      if (raArray.includes(activity._id)) {
        activity.action = 'readWrite';
      } else if (ciArray.includes(activity._id)) {
        activity.action = 'readOnly';
      } else {
        activity.action = 'hide';
      }
    } else {
      if (raArray.includes(activity._id) || ciArray.includes(activity._id)) {
        activity.action = 'readOnly';
      } else {
        activity.action = 'hide';
      }
    }
  if (activity?.activities?.length > 0) {
    for (let act of activity.activities) {
      processInitiativeActivities(act, raArray, ciArray, feathersClient, context, createInitiativeStatus)
    }
  }
  return activity;
}
const chunks = function (arr, chunkSize) {
  var R = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
}

const processImportComments = async function (initiativeCommentsService, comment, usersDataMap, accountId, organizationId, initiativeId, relation, relationId) {
  let accountUserId = usersDataMap.get(comment.uid);
  let commentData;
  var timestamp = Number(comment.created * 1000)
  comment.created = new Date(timestamp);
  if (accountUserId?._id) {
    let data = {
      "recipents": [],
      "accountId": accountId,
      "organizationId": organizationId,
      "initiativeId": initiativeId,
      "notes": {
        "accountUserId": accountUserId._id,
        "content": comment.commentText,
        "notedate": comment.created
      }
    }
    if (relation == "child" && relationId) {
      data.notes.parentCommentId = relationId;
    }
    commentData = await initiativeCommentsService.create(data);
  }
  if (comment?.children?.length > 0) {
    for (let childrenComment of comment.children) {
      if (childrenComment.private == false || !childrenComment.private) {
        let relation = "child";
        let relationId = commentData._id;
        await processImportComments(initiativeCommentsService, childrenComment, usersDataMap, accountId, organizationId, initiativeId, relation, relationId)
      }
    }
  }
  return;
}
const checkCreateInitiative = async function (feathersClient, context) {
  const { accessToken, aspenifyUserId, roles, userRole } = context.rootState.user.user;
  if(userRole == "workspaceOwner" || roles.includes("manager")){
    return true;
  } else {
    const tokenString = `Bearer ${accessToken}`;
    const playbookService = feathersClient.service("playbooks");
    const baseId = process.env.VUE_APP_BASE_ID;
    const playbookQuery = {
      baseId: baseId,
      status: 4,
      $select: ['createInitiative']
    };
    console.log("🚀 ~ file: feathersClient.js ~ line 65 ~ playbookQuery", playbookQuery)
    var result = await playbookService.find({
      query: playbookQuery,
      headers: {
        "Authorization": tokenString
      },
    });
    if(result?.length>0 && result[0].createInitiative){
      return true;
    } else {
      return false;
    }
  }
}

const getters = {
  initiatives: state => state.initiatives,
  selectedInitiative: state => state.selectedInitiative,
  totalActivitiesToComplete: state => state.totalActivitiesToComplete,
  jiraProjects: state => state.jiraProjects,
  completedActivities: state => state.completedActivities,
  pagination: state => state.pagination,
  selectedInitiativeEvents: state => state.selectedInitiativeEvents,
  activitiesToUpdate: state => state.activitiesToUpdate,
  ideaHistory: state => state.ideaHistory,
  teamMembers: state => state.teamMembers,
  usersByDisplayName: state => state.usersByDisplayName,
  allPendingRequestsToAccept: state => state.allPendingRequestsToAccept,
  msGraphAccessToken: state => state.msGraphAccessToken,
  openActivitiesFlag: state => state.openActivitiesFlag,
  selectedJudgingInitiative: state => state.selectedJudgingInitiative,
  initiativeDataUpdated: state => state.initiativeDataUpdated,
  ideaProcessStatus: state => state.ideaProcessStatus,
  exportLoading: state => state.exportLoading,
  initiativeMetaData: state => state.initiativeMetaData,
  phaseTeamMembers: state => state.phaseTeamMembers,
  currentPhaseTeamMembers: state => state.currentPhaseTeamMembers,
  selectedTeamMembersPhase: state => state.selectedTeamMembersPhase,
  attachmentPopUpFlag: state => state.attachmentPopUpFlag,
  viewAttachmentBase64: state => state.viewAttachmentBase64,
  currentRound: state => state.currentRound,
  currentRoundName: state => state.currentRoundName,
  judgingHistoryRounds: state => state.judgingHistoryRounds,
  selectedRoundOption: state => state.selectedRoundOption,
  execSponsorForSelectedSubmission: state => state.execSponsorForSelectedSubmission,
  enableJudging: state => state.enableJudging,
  enableSubmission: state => state.enableSubmission,
  totalJudgingRounds: state => state.totalJudgingRounds,
  rankArrayByRevenue: state => state.rankArrayByRevenue,
  rankArrayByRevenueHistory: state => state.rankArrayByRevenueHistory,
  rankArrayByActivations: state => state.rankArrayByActivations,
  rankArrayByActivationsHistory: state => state.rankArrayByActivationsHistory,
  payloadForProgressFilterQuery: state => state.payloadForProgressFilterQuery,
}

const InitiativeModule = {
  state,
  mutations,
  actions,
  getters
}

export default InitiativeModule;