<template>
<!-- <pre>{{ metaData.registerArchitecture }}</pre> -->
  <span
    class="
      float-right
      bg-gray-600
      text-white
      rounded-full
      shadow
      ring-white ring-1
      p-1
      ml-1.5
    "
    v-if="isSignupConfigure"
    v-tooltip="{
      text: openmicScheduledDate,
      theme: {
        placement: position ? position : 'top-left',
        width: '150px',
      },
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 16.098 16.102"
    >
      <g
        id="Sign-up_for_Open_Mic"
        data-name="Sign-up for Open Mic"
        transform="translate(-2 -1.996)"
      >
        <path
          id="Path_20260"
          data-name="Path 20260"
          d="M9.577,9.534l-6.49,7.612a.563.563,0,0,0,.03.765l.468.468h0L2,19.958l.795.795L4.38,19.168h0l.468.468a.569.569,0,0,0,.765.035c1.419-1.207,6.188-5.293,7.607-6.5a4.146,4.146,0,0,0,2.194-.262L9.844,7.34a4.146,4.146,0,0,0-.267,2.194ZM8.068,15.48l-.795-.795,1.851-1.846.79.79Z"
          transform="translate(0 -2.655)"
          fill="#fff"
        />
        <path
          id="Path_20261"
          data-name="Path 20261"
          d="M24.6,3.251a4.287,4.287,0,0,0-5.635-.372l-.569-.563L17.6,3.11l7.139,7.139h0l.79-.79-.569-.569A4.287,4.287,0,0,0,24.6,3.251Z"
          transform="translate(-7.751)"
          fill="#fff"
        />
      </g>
    </svg>
  </span>

  <span
    class="
      float-right
      bg-gray-600
      text-white
      rounded-full
      shadow
      ring-white ring-1
      p-1
      ml-1.5
    "
    v-if="isArchConfigure"
    v-tooltip="{
      text: archScheduledDate,
      theme: {
        placement: position ? position : 'top-left',
        width: '150px',
      },
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 16.1 16.073"
    >
      <path
        id="Register_for_Architecture_review"
        data-name="Register for Architecture review"
        d="M134.37,151.88v4.659h1.669v.419H134.37v.943h1.669v.419H134.37v.943h2.717v.419H134.37v.943h1.669v.419H134.37v.943h1.669v.419H134.37v.943h2.717v.419H134.37v.943h1.669v.419H134.37v.943h1.669v.419H134.37v1.456h16.1Zm3.767,8.263,3.97,3.967h-3.97Z"
        transform="translate(-134.37 -151.88)"
        fill="#fff"
      />
    </svg>
  </span>
  <span
    class="
      float-right
      bg-gray-600
      text-white
      rounded-full
      shadow
      ring-white ring-1
      p-1
      ml-1.5
    "
    v-if="isBuildingConfigure"
    v-tooltip="{
      text: buildingScheduledDate,
      theme: {
        placement: position ? position : 'top-left',
        width: '150px',
      },
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 16 11.729"
    >
      <path
        id="Register_for_Building_Block_Review"
        data-name="Register for Building Block Review"
        d="M256,323.729H240v-9.863h2.4V312h4.267v1.866h3.2V312h4.267v1.866H256Z"
        transform="translate(-240 -312)"
        fill="#fff"
      />
    </svg>
  </span>
  <span
    class="
      float-right
      bg-gray-600
      text-white
      rounded-full
      shadow
      ring-white ring-1
      p-1
      ml-1.5
    "
    v-if="isStaffConfigure"
    v-tooltip="{
      text: staffScheduledDate,
      theme: {
        placement: position ? position : 'top-left',
        width: '150px',
      },
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 13.851 16.017"
    >
      <path
        id="Register_for_Extended_Staff"
        data-name="Register for Extended Staff"
        d="M155.577,142.97H144.212a1.243,1.243,0,0,0-1.243,1.243v13.531a1.243,1.243,0,0,0,1.243,1.243h11.365a1.243,1.243,0,0,0,1.243-1.243V144.213a1.243,1.243,0,0,0-1.243-1.243Zm-3.907,13.887h-3.552a.533.533,0,0,1,0-1.065h3.552a.533.533,0,1,1,0,1.065Zm1.065-1.776h-5.682a.533.533,0,0,1,0-1.065h5.682a.533.533,0,0,1,0,1.065Zm.888-3.232a1.243,1.243,0,0,1-1.243,1.243h-4.972a1.243,1.243,0,0,1-1.243-1.243v-.355a3.742,3.742,0,0,1,1.645-3.092,2.308,2.308,0,1,1,4.168,0,3.742,3.742,0,0,1,1.645,3.092Z"
        transform="translate(-142.969 -142.97)"
        fill="#fff"
      />
    </svg>
  </span>
</template>
<script>
import { format } from "fecha";
import { mapGetters } from "vuex";
export default {
  name: "ActionIcons",
  props: {
    metaData: Object,
    initiative: Object
  },
  components: {},
  data() {
    return {
      actions: []
    };
  },
  async beforeMount() {
    let playbookPhaseData = this.playbook.activities?.filter((activity)=>{
      return activity.name == this.initiative.phase;
    });
    if(playbookPhaseData[0]?.metaData?.actions){
      this.actions = playbookPhaseData[0].metaData.actions;
    }
  },
  beforeUpdate: function () {
    let playbookPhaseData = this.playbook.activities?.filter((activity)=>{
      return activity.name == this.initiative.phase;
    });
    if(playbookPhaseData[0]?.metaData?.actions){
      this.actions = playbookPhaseData[0].metaData.actions;
    }
  },
  computed: {
    ...mapGetters([
      "playbook"
    ]),
    meta() {},
    isSignupConfigure() {
      return (
        this.actions.includes('Sign-up for Open Mic') && this.metaData?.signUpForOpenmic?.length > 0 &&
        this.metaData?.signUpForOpenmic[
          this.metaData?.signUpForOpenmic?.length - 1
        ]?.configure?.scheduledOn?.date &&
        !this.metaData?.signUpForOpenmic[
          this.metaData?.signUpForOpenmic?.length - 1
        ]?.markComplete
      );
    },
    isArchConfigure() {
      return (
        this.actions.includes('Register for Architecture Review') && this.metaData?.registerArchitecture?.length > 0 &&
        this.metaData?.registerArchitecture[
          this.metaData?.registerArchitecture?.length - 1
        ]?.configure?.scheduledOn?.date &&
        !this.metaData?.registerArchitecture[
          this.metaData?.registerArchitecture?.length - 1
        ]?.markComplete
      );
    },
    isBuildingConfigure() {
      return (
        this.actions.includes('Register to Building Block Review') && this.metaData?.registerBuildingBlock?.length > 0 &&
        this.metaData?.registerBuildingBlock[
          this.metaData?.registerBuildingBlock?.length - 1
        ]?.configure?.scheduledOn?.date &&
        !this.metaData?.registerBuildingBlock[
          this.metaData?.registerBuildingBlock?.length - 1
        ]?.markComplete
      );
    },
    isStaffConfigure() {
      return (
        this.metaData?.registerExtendedStaff?.length > 0 &&
        this.metaData?.registerExtendedStaff[
          this.metaData?.registerExtendedStaff?.length - 1
        ]?.configure?.scheduledOn?.date &&
        !this.metaData?.registerExtendedStaff[
          this.metaData?.registerExtendedStaff?.length - 1
        ]?.markComplete
      );
    },

    openmicScheduledDate() {
      let s = this.metaData?.signUpForOpenmic;
      let d = s[s?.length - 1]?.configure?.scheduledOn?.date;
      let f = s[s?.length - 1]?.configure?.scheduledOn?.from;
      let t = s[s?.length - 1]?.configure?.scheduledOn?.to;
      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Open Mic is scheduled from
             ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "hh:mm A")}`;
      } else {
        return `Open Mic is scheduled from
             ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "MMM D, YYYY hh:mm A")}`;
      }
    },
    archScheduledDate() {
      let s = this.metaData?.registerArchitecture;
      let d = s[s?.length - 1]?.configure?.scheduledOn?.date;
      let f = s[s?.length - 1]?.configure?.scheduledOn?.from;
      let t = s[s?.length - 1]?.configure?.scheduledOn?.to;

      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Register Architecture is scheduled from
            ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "hh:mm A")}`;
      } else {
        return `Register Architecture is scheduled from
            ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "MMM D, YYYY hh:mm A")}`;
      }
    },
    buildingScheduledDate() {
      let s = this.metaData?.registerBuildingBlock;
      let d = s[s?.length - 1]?.configure?.scheduledOn?.date;
      let f = s[s?.length - 1]?.configure?.scheduledOn?.from;
      let t = s[s?.length - 1]?.configure?.scheduledOn?.to;

      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Register Building Block is scheduled from
            ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "hh:mm A")}`;
      } else {
        return `Register Building Block is scheduled from
            ${format(new Date(f), "MMM D, YYYY hh:mm A")} to ${format(new Date(t), "MMM D, YYYY hh:mm A")}`;
      }
    },
    staffScheduledDate() {
      let s = this.metaData?.registerExtendedStaff;
      let d = s[s?.length - 1]?.configure?.scheduledOn?.date;
      let f = s[s?.length - 1]?.configure?.scheduledOn?.from;
      let t = s[s?.length - 1]?.configure?.scheduledOn?.to;

      return `Register for Extended Staff is scheduled on ${format(
        new Date(d),
        "MMM D, YYYY"
      )}  from  ${format(new Date(f), "hh:mm A")}  to  ${format(
        new Date(t),
        "hh:mm A"
      )}`;
    },
  },
  methods: {},
  created: async function () {},
};
</script>

<style scoped>
</style>
