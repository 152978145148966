<template>
  <div class="py-1">
    <!-- Primary -->
    <div
      class="checkbox-toggle-primary flex w-full p-1"
      v-if="theme === 'primary'"
    >
      <label :for="label" class="flex items-center cursor-pointer">
        <div
          v-if="text && position === 'left'"
          class="mr-1 text-gray-700 font-medium"
        >
          {{ text }}
        </div>
        <div class="relative mx-2">
          <input
            :disabled="disabled"
            :id="id"
            type="checkbox"
            v-on:click="$emit('onChangeCheckbox', $event)"
            class="sr-only"
            v-model="defaultValue"
          />
          <div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
          <div
            class="
              dot
              absolute
              w-6
              h-6
              bg-white
              rounded-full
              shadow
              -left-1
              -top-1
              transition
            "
          ></div>
        </div>
        <div
          v-if="text && position !== 'left'"
          class="ml-3 text-gray-700 font-medium"
        >
          {{ text }}
        </div>
      </label>
    </div>

    <!-- Secondary -->
    <div class="checkbox-toggle-secondary flex w-full" v-else>
      <label :for="label" class="flex items-center cursor-pointer">
        <div
          v-if="text && position === 'left'"
          class="mr-1 text-gray-700 font-medium"
        >
          {{ text }}
        </div>
        <div class="relative mx-2">
          <input
            :disabled="disabled"
            type="checkbox"
            v-on:click="$emit('onChangeCheckbox', $event)"
            :id="id"
            class="sr-only"
            v-model="defaultValue"
          />

          <div class="block bg-gray-200 border w-10 h-6 rounded-full"></div>
          <div
            class="
              dot
              absolute
              left-1
              top-1
              bg-white
              w-4
              h-4
              rounded-full
              transition
            "
          ></div>
        </div>
        <div
          v-if="text && position !== 'left'"
          class="ml-3 text-gray-700 font-medium"
        >
          {{ text }}
        </div>
      </label>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  data() {
    return {};
  },
  props: {
    theme: String,
    defaultValue: Boolean,
    text: String,
    position: String,
    disabled: Boolean,
    label: String,
    id: String
  },
  emits: ["onChangeCheckbox"],
  computed: {},
  methods: {},
});
</script>

<style scoped>
/* Toggle A */
.checkbox-toggle-primary input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3ba4e0;
}

/* Toggle B */
.checkbox-toggle-secondary input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3ba4e0;
}
</style>