<template>
  <div v-if="fieldsData?.length>0 && showAsterisk" class="italic text-xs">All fields with<span class="text-red-500">&nbsp; *</span> mark are mandatory fields.</div>
  <div v-if="!fieldsData" class="text-gray-500">Please add custom attributes in playbook for this phase.</div>
  <div class="font-semibold my-2" v-if="currentRound == 1">Judging Criterion: Could this submission win its category?</div>
  <div :class="{ 'opacity-60': ([2, 6, 7].includes(selectedIdea.status) || !checkEnableJudging()) }" v-for="(field) in fieldsData" :key="field.title">
     <div class="clear-both"></div>
     <div :class="{'pointer-events-none': ([2, 6, 7].includes(selectedIdea.status) || !checkEnableJudging()) }" class="my-2 mt-2" v-if="field.type == 'dropdown' && showField(field)">
     <div class="font-semibold my-2">{{field.title}} <span v-if="field.isRequired" class="text-red-500">*</span></div>
        <Multiselect
            class="
                block
                text-gray-700 text-base
                font-normal
                mb-2
                z-10
                multi-tag
            "
            trackBy="label"
            label="label"
            v-model="field.model"
            placeholder="Select"
            :options='field.modifiedChoices'
            @select="onSelect(field)"
        ></Multiselect><br/>
     </div>
     <!-- <div class="clear-both"></div> -->
     <div class="my-2" v-if="field.type == 'comment'">
     <div class="font-semibold my-2">{{field.title}} <span v-if="field.isRequired" class="text-red-500">*</span></div>
     <textarea
          :rows="field.rowCount"
          v-model="field.model"
          :name="field.name"
          :id="field.name"
          :placeholder="field.placeholder"
          @input="onChange(field,$event)"
          :disabled="([2, 6, 7].includes(selectedIdea.status) || !checkEnableJudging())"
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            mx-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
          "
        ></textarea>
        <span class="characters-count" :class="maxLimit? limitWarning(field) : ''">
          {{ field.model?.length || 0 }}
          {{ maxLimit? `/ ${maxLimit} characters` : "characters" }}
        </span>
        <br/>
     </div>
     <!-- <div class="clear-both"></div> -->
     <div :class="{'pointer-events-none': ([2, 6, 7].includes(selectedIdea.status) || !checkEnableJudging()) }" class="my-2" v-if="field.type == 'checkbox'">
      <div class="font-semibold my-2">{{field.title}}<span v-if="field.isRequired" class="text-red-500">*</span></div> 
      <div class="mb-2">{{field.detail}}</div>
      <form class="checklist">
        <div
          v-for="(choice, index) in field.modifiedChoices"
          :key="index"
          class="custom-control custom-checkbox col pl-9"
        >
        <div class="float-left mt-1 w-full inline-block">
          <input
            type="checkbox"
            class="custom-control-input"
            :checked="choice.model"
            :id="choice.value"
            @click="optionSelected(field, choice)"
          />
          <!-- <div class="checklist-strikethrough pt-5 mb-2"></div> -->
          <label
            class="custom-control-label cursor-pointer"
            :htmlFor="choice.value"
          ></label>
          <span>{{choice.label}}</span>
        </div>
        </div>
      </form><br/>
     </div>
     <!-- <div class="clear-both"></div> -->
     <div :class="{'pointer-events-none': ([2, 6, 7].includes(selectedIdea.status) || !checkEnableJudging()) }" class="my-2" v-if="field.type == 'radiogroup' && showField(field)">
      <div class="font-semibold my-2">{{field.title}}<span v-if="field.isRequired" class="text-red-500">*</span></div> 
      <div class="my-3">{{field.detail}}</div>
      <div
        v-for="(choice, index) in field.modifiedChoices"
        :key="index"
        class=""
      >
      <div class="w5per">
        <input
          type="radio"
          class="my-2 jc-radio"
          :checked="choice.model"
          :id="choice.value"
          @click="onSelectRadio(field, choice)"
        />
        </div>
        <div class="w95per">
          <label for="programemanagement" class="ml-2 jc-radio-label"
            >{{choice.label}}</label
          >
        </div>
        
      </div>
     </div>
  </div>
  <display-info
        :openInfo="openInfo"
        :headerText="headerText"
        :infoText="infoText"
        @openInfoModal="openInfoModal"
        @close="close"
      ></display-info>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import {checkJudgingLiveStatus} from '../../worker-api';
import DisplayInfo from "./confirmationModal/DisplayInfo.vue";

export default {
  data() {
    return {
      payload:{},
      selectedChoices:[],
      setIntervalRefreshId: -1,
      openInfo: false,
    }
  },
  components: {Multiselect, DisplayInfo},
  props: {
    fieldsData: Array,
    selectedIdea: Object,
    showAsterisk: Boolean,
    maxLimit: {
        type: Number,
        default: 500,
      },
  },
  computed: {
      ...mapGetters(['playbook', 'user', 'enableJudging', 'totalJudgingRounds', 'currentRound'])
  },
  mounted: function () {
    let refreshId = setInterval(async () => {
        let result = await checkJudgingLiveStatus(this.user.accessToken, this.user.organizationId, this.user.accountId);
        if(this.checkEnableJudging() != result){
          this.$store.dispatch("readPlaybook");
          if(!this.openInfo){
            this.openInfoModal(true);
          }
          if(!result){
            this.infoText = "Judging is turned off, your changes will not be saved.";
          } else {
            this.infoText = "Judging is turned on";
          }
        }       
    }, 3000);
    if(this.selectedIdea.phaseIndex === 2){
          let checkField = this.fieldsData?.find(field=>field?.title === "Decision")
          let noRegex = /\bno\b/i
          if(!noRegex.test(checkField.model)){
             let regex = /\bReason for not advancing\b/i
             let index = this.fieldsData?.findIndex(field=>regex.test(field?.title))
             if(index!== -1){
              this.fieldsData[index].isRequired = false;
             }
          }
        }
    this.setIntervalRefreshId = refreshId;
  },
  unmounted: function(){
      clearInterval(this.setIntervalRefreshId);
  },
  methods: {
      limitWarning(field) {
          if(field.model?.length){
          const isCloseToMax = field.model?.length >= this.maxLimit - 20;
          const isMax = field.model?.length === this.maxLimit || field.model?.length > this.maxLimit;
          if (isCloseToMax && !isMax) return "warning";
          if (isMax) return "danger";
          return "";
          }
          return "";
      },
      openInfoModal: function(payload){
        this.openInfo = payload;
      },
      close: function(){
        console.log("inside close=-=-=-=>")
        this.openInfo = false;
        this.$emit("openCloseJudgingPanel", false);
        // this.$router.push(`/judging/toreview`);
        window.location.href = `${window.location.origin}/judging/toreview`
      },
      onSelect: function(field, choice){
        this.addMandatory(field);
        this.payload[`${field.name}`] = field.model;
        this.$emit("save", this.payload);
      },
      onChange: function(field, event){
        this.payload[`${field.name}`] = event.target.value;
        this.$emit("save", this.payload);
      },
      optionSelected: function(field, choice){
        this.selectedChoices=[];
        for(let modifiedChoice of field.modifiedChoices){
          if(choice.value == modifiedChoice.value){
            modifiedChoice.model = !modifiedChoice.model
          }
          if(modifiedChoice.model){
            this.selectedChoices.push(modifiedChoice.value)
          }
        }
        this.payload[`${field.name}`] = this.selectedChoices;
        this.$emit("save", this.payload);
      },
      onSelectRadio: function(field, choice){
        this.selectedRadioChoice='';
        for(let modifiedChoice of field.modifiedChoices){
          if(choice.value == modifiedChoice.value){
            if(modifiedChoice.model == true){
              modifiedChoice.model = true;
            } else {
              modifiedChoice.model = !modifiedChoice.model
            }
          } else {
            modifiedChoice.model = false;
          }
        }
        let data = field.modifiedChoices.filter((modifiedChoice)=> modifiedChoice.model==true)
        if(data?.length>0){
          this.payload[`${field.name}`] = data[0].value;
          this.$emit("save", this.payload);
        } else {
          delete this.payload[`${field.name}`];
          this.$emit("save", this.payload);
        }
      },
      checkEnableJudging: function(){
        if (this.playbook?.hasOwnProperty("metaData") && this.playbook.metaData.enableJudging) {
          return true;
        } else if(this.playbook?.hasOwnProperty("metaData") && !this.playbook.metaData.enableJudging) {
          return false;
        } else if(!this.playbook?.hasOwnProperty("metaData")) {
          return true;
        }
      },
      addMandatory(field){
        let noRegex = /\bno\b/i
        if(field.title === "Decision" && noRegex.test(field.model) && this.selectedIdea.phaseIndex === 2){
          let regex = /\bReason for not advancing\b/i
          let index = this.fieldsData.findIndex(field=>regex.test(field?.title))
          if(index !== -1){
            this.fieldsData[index].isRequired = true;
          }
        }
        else if(field.title === "Decision" && this.selectedIdea.phaseIndex === 2){
          let regex = /\bReason for not advancing\b/i
          let index = this.fieldsData.findIndex(field=>regex.test(field?.title))
          if(index){
            this.fieldsData[index].isRequired = false;
          }
        }
      },
      showField(field){
        let regex = /\bReason for not advancing\b/i
        if(regex.test(field?.title) && !field.isRequired && this.selectedIdea.phaseIndex === 2){
          return false;
        }
        return true;
      }
  },
};
</script>
<style scoped>

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1983bf;
  background-color: #1983bf;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e9ecef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
/* .custom-control-input:disabled ~ .custom-control-label::before {
  
   color: #e4e4e4 !important;
   border-color: #e4e4e4 !important;
  background-color: #e4e4e4 !important;
} */
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-label:indeterminate + label {
  background: lime;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #1983bf;
  background-color: #1983bf;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.checklist .custom-control-input input:checked + .checklist-strikethrough {
  width: 100%;
}
.checklist .custom-checkbox input:checked > label {
  opacity: 0.7;
}
.-w-100 {
  width: calc(100% - 140px);
}

.checklist-strikethrough {
  height: 40px;
  /* background: linear-gradient(90deg, #5982ff 0%, rgb(150, 175, 250) 100%); */
  background: #f1f1f1;
  border-radius: 2px 3px 2px 2px;
  mix-blend-mode: multiply;
  /* -webkit-transform: skewX(-10deg); */
  /* transform: skewX(-10deg); */
  /* opacity: 0.3; */
  width: 0;
  transition: width 0.35s ease-in-out, opacity 0.25s ease-in-out;
  position: absolute;
  margin-left: -51px;
  top: 0;
  pointer-events: none;
}

.custom-checkbox-label {
  background: inherit;
  width: 100%;
}

.custom-checkbox-label:focus {
  outline: none;
}
.fluent-Progress-bar-normal {
  background-color: #1983bf !important;
  height: 8px;
}

.checklist .custom-control-input input[type="checkbox"]:checked + p + div {
  background: green;
  width: 100%;
}

.checklist .custom-control-input input[type="checkbox"]:checked + div {
  background: blue;
  width: 100%;
}

.jc-radio {
  float:left;
}

.jc-radio-label {
  margin-bottom: 0.5rem; float:left
}

.w5per {
  width: 4%;
  float:left;
}

.w95per {
  width: 96%;
  float:left;
}
.mbt-2 {
  margin-bottom: -1.8rem;
}

.characters-count.warning {
  color: orange;
}

.characters-count.danger {
  color: red;
}
</style>