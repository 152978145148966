<template>
  <TransitionRoot appear :show="requestDetailsFlag" as="template">
    <Dialog as="div" :open="requestDetailsFlag">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                overflow-hidden
                text-left
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-md
                relative
                top-40
              "
            >
              <div class="p-2 border-b text-left">Request Details</div>

              <div class="flex ml-10 mt-3">
                <div>
                  <img
                    class="w-10 rounded-full"
                    :src="requestDetail.image"
                    alt="image"
                  />
                </div>
                <div class="ml-2">
                  <p class="font-bold text-sm">Name</p>
                  <p class="text-sm text-gray-500 mb-1">
                    {{ requestDetail.name }}
                  </p>
                  <p class="-mt-1 text-sm text-gray-500">
                    {{ requestDetail.email }}
                  </p>
                </div>
              </div>
              <div class="ml-10">
                <p class="font-bold text-sm mt-3">Requested Role:</p>
                <p class="text-sm text-gray-500">{{ requestDetail.role }}</p>
              </div>
              <div class="ml-10 mb-3 mt-3">
                <p class="font-bold text-sm">Comment:</p>
                <p class="text-sm text-gray-500 pr-8">{{ requestDetail.comment }}</p>
                <!-- <p class="text-sm text-gray-500 pr-8">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
                  nisi illo aut quas earum. Cupiditate quam facere eum atque eos
                  repudiandae id inventore beatae sunt minima harum, quibusdam
                  ducimus dolores.
                </p> -->
              </div>

              <!-- <div class="">
                  <img
                    class="w-10   rounded-full"
                    :src="requestDetail.image"
                    alt="image"
                  />
                  <div class="">
                    <p>Name</p>
                    <p class="text-sm text-gray-400 mb-1">
                      {{ requestDetail.name }}
                    </p>
                    <p class="-mt-1">{{ requestDetail.email }}</p>
                  </div>
                </div> -->

              <!-- <div class="">
                  <div>Requested Role :</div>
                  <div>{{ requestDetail.role }}</div>
                </div>

                <div class="">
                  <div>Comment :</div>
                  <div>{{ requestDetail.comment }}</div>
                </div> -->
              <div
                class="
                  w-full
                  text-right
                  float-right
                  items-center
                  justify-between
                  p-5
                  bg-white
                  border-t border-gray-200
                  rounded-bl-lg rounded-br-lg
                "
              >
                <button
                  type="button"
                  @click="$emit('openDetailsPgae', false)"
                  class="
                    mr-3
                    bg-blue-400
                    inline-flex
                    justify-center
                    hover:scale-110
                    focus:outline-none
                    px-4
                    py-2
                    rounded
                    cursor-pointer
                    hover:bg-gray-200
                    bg-gray-100
                    text-gray-700
                    duration-200
                    ease-in-out
                    transition
                  "
                >
                  Got it
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "IdeaSubmittedInfo",
  beforeUpdate() {},
  props: {
    requestDetailsFlag: Boolean,
    selectedInitiative: Object,
    requestDetail: Object,
  },
  data() {
    return {
      pageInfo: {
        heading: "a",
        subHeading: "b",
      },
    };
  },
  created: function () {
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {},
};
</script>
