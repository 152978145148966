<template>
  <svg
    width="20"
    height="20"
    version="1.1"
    viewBox="0 0 700 700"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :fill="fill ? fill : currentColor"
  >
<g>
  <path d="m224 238h-42v42h-42v-42h-42v-42h42v-42h42v42h42zm358.4 153.44c-21.281-12.879-57.68-32.48-100.24-47.602-34.16 53.762-83.441 62.16-110.32 62.16-27.441 0-76.719-8.3984-110.32-62.719-43.68 15.117-80.641 35.277-101.92 48.16-12.32 7.2812-19.602 21.281-19.602 35.84v42c0 11.762 9.5195 21.281 21.281 21.281h420c11.762 0 21.281-9.5195 21.281-21.281v-42c-0.5625-14.562-7.8438-28.562-20.164-35.84zm-210.56-321.44c58.238 0 105.28 48.719 105.28 108.64s-10.078 185.36-105.28 185.36c-95.199 0-105.28-124.88-105.28-185.36 0-59.922 47.043-108.64 105.28-108.64z"/>
  
 </g>
  </svg>
</template>
<script>
export default {
  name: "Icon-Follow",
  props: {
    fill: String,
  },
};
</script>

