<template>
  <div class="p-4 container mx-auto">
    <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
      <div class="bg-white rounded p-2 col-span-2">
        <Filter
          heading="Dashboard"
          @setDateForDashboard="setDateForDashboard"
          :date="date"
        />
      </div>
      <div class="bg-white rounded p-2 col-span-2" :key="bogDataKey">
        <div class="text-lg font-bold">Total Submissions</div>
        <div class="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
          <div>
            <pie
              heading="Category "
              :data="bogData"
              @viewDetails="viewDetails"
              :loading="bogLoader"
            />
          </div>
          <div class="p-8 mt-12">
            <BOGOTable :data="bogData" @viewDetails="viewDetails" />
          </div>
        </div>
      </div>
      <div class="bg-white rounded p-2 col-span-2" :key="phaseDataKey">
      <div class="text-lg font-bold"> Submission Status </div>
        <div class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1">
          <div class="h-full">
            <bar
              :data="phaseData"
              :categoryNames="categoryNames"
              :phaseNames="phaseNames"
              :loading="phaseLoader"
              @viewDetails="viewBarDetails"
            />
          </div>
        </div>
      </div>
      <div class="bg-white rounded p-2 col-span-2" :key="phaseDataKey">
      <div class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1">
        <div class="p-4">
        <TotalIdeas
          :data="phaseSummary"
          :categoryNames="modifiedCategoryNames"
          :phaseCategorySummary="phaseCategorySummary"
          :judgingProgressTableData="judgingProgressTableData"
          @viewDetails="viewBarDetails"
        />
        </div>
      </div>
      </div>
      <div class="bg-white rounded p-2 col-span-2">
        <div class="text-lg font-bold">Judging Status</div>
        <div class="flex justify-content w-full">
          <div :key="phaseDataKey">
          <custom-graph
            :data="phaseData"
            :categoryNames="categoryNames"
            :phaseNames="phaseNames"
            :loading="phaseLoader"
            @viewDetails="viewBarDetails"
            :categoryArray="judgingProgressTableData?.categoryArray"
            :judgingCompleted="judgingProgressTableData?.judgingCompleted"
            :colourArray="judgingProgressTableData?.round1ColourArray"
          />
          <!-- <div class="text-center font-semibold">{{judgingData?.judgingPhaseNames[0]}}</div> -->
          </div>
          <div class="ml-12 py-5 w-auto mt-12">
            <h3 class="
                rounded-t
                p-3
                text-xl
                font-semibold
                bg-indigo-500
                text-white text-center
              "
            >
            Judging Pipeline
            </h3>
            <div>
              <table
                class="
                  min-w-full
                  divide-y divide-gray-200
                  table-auto
                  bg-white
                  shadow-md
                  rounded
                  text-sm text-gray-600
                "
              >
                <thead class="bg-gray-100 text-gray-500">
                  <tr>
                    <th scope="col" class="text-left p-2">Phase</th>
                    <th scope="col" class="text-right p-2">Submissions in Phase</th>
                    <th scope="col" class="text-right p-2">Judging Completed</th>
                    <!-- <th scope="col" class="text-right p-2">Wind Down</th>
                  <th scope="col" class="text-right p-2">Progressed</th> -->
                  </tr>
                </thead>

                <tbody
                  class="bg-white divide-y divide-gray-200"
                  v-if="judgingProgressTableData?.length > 0"
                >
                  <tr
                    v-for="(tableData, index) in judgingProgressTableData.judgingPipelineData"
                    :key="{ index }"
                    class="cursor-pointer hover:bg-gray-50"
                    @click="goToIdea(phase)"
                  >
                    <td class="p-2">{{ tableData.phaseName }}</td>
                    <td class="text-center p-2">{{ tableData.phaseTotalIdeas }}</td>
                    <td class="text-center p-2">{{ tableData.judgingingCompletedInPercentage}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <div class="p-2">No data available !</div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> 
      <div class="bg-white rounded p-2 col-span-2" :key="phaseDataKey">
      <div class="flex justify-between">
        <div class="text-lg font-bold"> User Login Report </div>
        <button @Click="exportUserLogins" class="border bg-blue-500 text-white rounded-md px-2 py-1 text-semibold mr-10 mt-2">Download Report</button>
      </div>
      <div class="text-md font-bold">Unique users login count till date : <span class="bg-blue-500 px-1.5 py-1 text-white rounded-full"> {{ userLogins?.uniquelogins?.length }}</span></div>
        <div class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1">
          <div class="h-full py-2">
            <loginBar
              :data="userLogins?.logins"
              :loading="phaseLoader"
              @viewDetails="viewBarDetails"
            />
          </div>
        </div>
      </div>
      <!-- <div class="bg-white rounded p-2 col-span-2 gap-4">
        <div class="text-lg font-bold mb-5">Judging Data</div>
        <table class="min-w-full">
          <thead style="background-color: #107AA3" class="text-white font-semibold border-solid border-2 border-gray-300">
            <tr>
              <td scope="col" class="">Stage</td>
              <td scope="col" class="">Category</td>
              <td scope="col" class="">Total in Phase</td>
              <td scope="col" class="">Total Judged</td>
              <td scope="col" class="">Total Moved on</td>
            </tr>
          </thead>
          <tbody class="text-black-500" v-for="(data, index) in tableData" :key="{ index }">
            <tr class="border-solid border-2 border-gray-300">
              <td scope="col" style="background-color: #b0c4de">{{data.phaseName}}</td>
              <td scope="col">
                <tr v-for="(category, index) in data.categories" :key="{ index }">
                  <td class="">
                    {{category.name}}
                  </td>
                </tr>
              </td>
              <td scope="col" class="">
                <tr v-for="(category, index) in data.categories" :key="{ index }">
                  <td>
                    {{category.phaseTotal}}
                  </td>
                </tr>
              </td>
              <td scope="col" class="">
                <tr v-for="(category, index) in data.categories" :key="{ index }">
                  <td>
                    {{category.judgedTotal}} / {{data.phaseTotalIdeas}}
                  </td>
                </tr>
              </td>
              <td scope="col" class="">
                <tr v-for="(category, index) in data.categories" :key="{ index }">
                  <td>
                    {{category.movedOnTotal}} / {{data.phaseTotalIdeas}}
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
  <loader-view
    v-if="downloadingReport"
    :loaderFlag="downloadingReport"
    loadingTitle="Downloading...."
  >
  </loader-view>
  <toaster
    :header="toaster.header"
    :subheader="toaster.subheader"
    :text="toaster.text"
    :type="toaster.type"
    />
</template>

<script>
import { mapGetters } from "vuex";
import Funnel from "../components/Charts/Funnel.vue";
import Bar from "../components/Charts/Bar.vue";
import CustomGraph from "../components/Charts/customChart.vue";
import Pie from "../components/Charts/Pie.vue";
import Filter from "../components/Dashboard/Filter.vue";
import LeaderBoard from "../components/Dashboard/LeaderBoard.vue";
import TotalIdeas from "../components/Dashboard/TotalIdeas.vue";
import BOGOTable from "../components/Dashboard/BOGOTable.vue";
import pagination from "../components/Pagination.vue";
import loginBar from "../components/Charts/loginBar.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue"
import Toaster from "../components/Toaster.vue";
export default {
  components: {
    Funnel,
    Pie,
    Filter,
    LeaderBoard,
    TotalIdeas,
    BOGOTable,
    Bar,
    pagination,
    CustomGraph,
    LoaderView,
    Toaster,
    loginBar
  },
  data() {
    return {
      bogData: [],
      phaseData: [],
      phaseSummary: [],
      bogDataKey: 0,
      phaseDataKey: 0,
      date: null,
      userData: [],
      categoryNames: [],
      modifiedCategoryNames: [],
      phaseNames: [],
      phaseInactiveDataValues: [],
      leaderBoardData: [],
      phaseCategorySummary: {},
      judgingData: {},
      toaster:{},
    };
  },
  computed: {
    ...mapGetters([
      "dashboardData",
      "phaseNames",
      "phaseLoading",
      "bogLoading",
      // "tableData",
      "judgingProgressTableData",
      "userLogins",
      "downloadingReport"
    ]),
    bogLoader() {
      return this.$store.getters.bogLoading;
    },
    phaseLoader() {
      return this.$store.getters.phaseLoading;
    },
  },
  created: async function () {
    this.phaseData = [];

    this.bogData = await this.$store.dispatch("getBogData", this.date);
    // this.judgingData = await this.$store.dispatch("getJudgingProgress");
    // this.$store.dispatch("getJudgingTableData");
    await this.$store.dispatch("getJudgingProgressTable");
    await this.$store.dispatch("getUserLogins")
    //console.log(this.bogData);

    const { playbookData, initiativesInfo } = await this.$store.dispatch(
      "getPhaseData"
    );

    if (initiativesInfo.total > 0) {
      const initiativeData = initiativesInfo.data;

      const phaseNames = playbookData.activities.map((data) => {
        return data.name;
      });

      const phaseSummary = phaseNames.map((data) => {
        return {
          name: data,
          totalInitiatives: 0,
        };
      });

      const phaseCategorySummary = {};

      const categoryData = this.bogData.map((data) => {
        phaseCategorySummary[data.name] = 0;
        return {
          name: data.name,
          data: new Array(phaseNames.length).fill(0),
        };
      });

      phaseSummary.forEach((phase) => {
        categoryData.forEach((category) => {
          phase[category.name] = 0;
        });
      });

      initiativeData.forEach((initiative) => {
        let phaseCategory = null;
        if (Array.isArray(initiative.metaData.challengeType)) {
          phaseCategory = initiative.metaData.challengeType[0];
        } else {
          phaseCategory = initiative.metaData.challengeType;
        }

        const initiativeRunStatusArray = initiative.activities.map(
          (activity) => activity.runStatus
        );

        //const activePhaseIndex = initiativeRunStatusArray.lastIndexOf(1);

        let activePhaseIndex = 0;
        let noOfPhasesActive = initiativeRunStatusArray.filter(
          (runStatus) => runStatus === 1
        ).length;
        if (noOfPhasesActive > 1) {
          activePhaseIndex = initiativeRunStatusArray.indexOf(1);
        } else {
          activePhaseIndex = initiativeRunStatusArray.lastIndexOf(1);
        }

        if (activePhaseIndex > -1) {
          const phaseCategoryData = categoryData.find(
            (catData) => catData.name === phaseCategory
          );
          if (phaseCategoryData) {
            phaseCategoryData.data[activePhaseIndex] =
              phaseCategoryData.data[activePhaseIndex] + 1;
          }
          phaseSummary[activePhaseIndex][phaseCategory]++;
          phaseCategorySummary[phaseCategory]++;
          phaseSummary[activePhaseIndex].totalInitiatives++;
        }
      });
      this.phaseData = categoryData;
      this.phaseNames = phaseNames;
      this.categoryNames = categoryData.map((data) => data.name);
      for(let categoryName of this.categoryNames){
        this.modifiedCategoryNames.push(categoryName);
        this.modifiedCategoryNames.push('Rate of Conversion');
      }
      this.modifiedCategoryNames.push('Active Submissions');
      this.modifiedCategoryNames.push('Rate of Conversion');
      this.phaseSummary = phaseSummary;
      this.phaseCategorySummary = phaseCategorySummary;
    }

    this.leaderBoardData = await this.$store.dispatch("getLeaderBoardData");
    this.userData = await this.$store.dispatch("getUsersData");
  },
  methods: {
    setDateForDashboard: async function (date) {
      this.date = date;
      let payload = {
        date: this.date,
      };
      this.bogData = await this.$store.dispatch("getBogData", payload);
      this.phaseData = await this.$store.dispatch("getPhaseData");
    },
    pageChanged: async function (page) {},
    viewDetails(data) {
      let name = "";
      if (data.name) {
        name = data.name;
      } else {
        name = data[0].data.name;
      }
      this.$router.push({
        name: "SubmissionLibrary",
        params: { color: name, dashboardFilter: true },
      });
    },
    viewBarDetails(data) {
      let status = 1;
      if (data[0].seriesName === "Active") {
        status = 1;
      } else if (data[0].seriesName === "In Active") {
        status = 6;
      }
      this.$router.push({
        name: "SubmissionLibrary",
        params: { phase: data[0].name, status: status, dashboardFilter: true },
      });
    },
    async exportUserLogins(){
      await this.$store.dispatch("exportLoginReport",this.userLogins?.logins)
      this.toaster = {
                header:"User Logins Report downloaded Sucessfully",
                subheader:``,
                text:"",
                type:"success"
              }
      setTimeout(()=>{
        this.toaster={}
      },2000)        
    }
  },
  watch: {
    bogData: function (newValue, oldValue) {
      this.bogDataKey += 1;
    },
    phaseData: function (newValue, oldValue) {
      this.phaseDataKey += 1;
    },
  },
};
</script>


