<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-40
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white shadow-lg w-5/12 text-gray-600">
        <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
          <div class="font-medium text-white text-lg flex w-10/12">
            <div class="flex-grow w-1/12 font-bold mt-2">
              {{ setHead }}
            </div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('closeSignUp')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div v-if="actionType === 'signUpOpenMic'">
          <div class="p-3 bg-purple-200 m-5 mb-0 rounded">
            <h3 class="font-bold">What is Open Mic forum?</h3>
            <p class="mb-2">
              Open Mic is an open forum where most of the “Emerging Technologies
              & Incubation” team is present.
            </p>
            <a
              class="text-purple-700 hover:opacity-70 mt-2 underline"
              href="https://cisco.sharepoint.com/sites/IREOpenMic"
              target="_blank"
            >
              https://cisco.sharepoint.com/sites/IREOpenMic</a
            >
          </div>

          <div class="p-5 pb-0">
            <p class="font-bold">
              Please confirm you intrest in signing up for an Open Mic session.
            </p>
            <p class="mt-3">
              Once submitted you will receive a message from the Open Mic
              program team indicating the scheduled date for your Open Mic
              session.
            </p>
          </div>
        </div>



        <!-- //register for architecture  -->
        <div v-if="actionType === 'registerArchitecture'">
          <div class="p-5 pb-0">
            <p class="font-bold">
              Please confirm you interest in signing up for an Architecture Review session.
            </p>
            <p class="mt-3">
              Once submitted you will receive a message from the program team indicating the scheduled date for your session.
            </p>
          </div>
        </div>


        <!-- //register for building block  -->
        <div v-if="actionType === 'registerBuildingBlock'">
          <div class="p-5 pb-0">
            <p class="font-bold">
              Please confirm you interest in signing up for Building Block Review session.
            </p>
            <p class="mt-3">
              Once submitted you will receive a message from the program team indicating the scheduled date for your session.
            </p>
          </div>
        </div>

        <div class="p-5">
          <div>
            <h4 class="font-bold">Comments (optional)</h4>
            <textarea
              name="comment"
              placeholder="Add a comment"
              id="comment"
              rows="6"
              v-model="comment"
              class="
                bg-gray-100
                appearance-none
                border-2 border-gray-200
                rounded
                shadow-md
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
            ></textarea>
            <div class="text-right mt-3">
              <button
                class="
                  inline-flex
                  text-gray-700
                  bg-gray-100
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-gray-200 hover:border hover:border-gray-700
                  rounded
                  text-base
                  mx-2
                "
                @click="$emit('closeSignUp')"
              >
                Cancel
              </button>
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
                @click="$emit('submitComment', comment)"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "SignUpOpenmic",
  components: {},
  props: {
    actionType: String,
  },
  emits: ["closeSignUp"],
  data() {
    return {
      comment: "",
    };
  },
  methods: {},
  computed: {
    setHead() {
      switch (this.actionType) {
        case "signUpOpenMic":
          return "Sign-up for Open Mic";
          break;
        case "registerArchitecture":
          return "Register for Architecture Review";
          break;
        case "registerBuildingBlock":
          return "Register for Building Block Review";
          break;
        case "registerExtendedStaff":
          return "Register for Extended Staff Review";
          break;

        default:
          return "NA";
          break;
      }
    },
  },
  mounted: function () {},
  created: function () {},
  beforeUpdate() {},
};
</script>
