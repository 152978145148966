<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-80
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white rounded shadow-lg w-11/12 text-gray-600">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <div class="font-medium text-white text-lg flex w-11/12">
            <div class="flex-grow w-1/12 font-bold mt-2">Phase</div>
            <div class="flex-grow w-4/12">
              <Multiselect
                class="
                  block
                  text-gray-700 text-base
                  font-normal
                  mb-2
                  z-10
                  multi-tag
                "
                trackBy="value"
                label="name"
                v-model="selectedPhase"
                :placeholder="selectedPhase"
                @select="phaseChanged"
                :options="allPhases"
              ></Multiselect>
            </div>

            <div class="flex-grow w-7/12 font-bold ml-5 mt-2 truncate">
              {{ initiative.name }}
            </div>
          </div>
          <button
            :disabled="isActivityUpdateInProgress"
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            v-bind:class="{
              'disabled:opacity-50 animate-spin': isActivityUpdateInProgress,
              'hover:bg-blue-500': !isActivityUpdateInProgress,
            }"
            @click="$emit('closeMyProgress')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div class="flex">
          <div
            style="height: calc(100vh - 72px); overflow: auto"
            class="p-3 flex-col w-full"
          >
             <MyProgressActivities
              :stages="initiative.phaseData"
              :initiative="initiative"  
              :isActivityUpdateInProgress="isActivityUpdateInProgress"   
            /> 
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import MyProgressActivities from "./MyProgressActivities.vue";
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
export default {
  name: "MyProgressModal",
  components: {
    MyProgressActivities,
    Multiselect
  },
  props: {
    initiative: Object,
    activitiesToUpdate: Object,
    phaseData: Object,
    allInitiatives: Array,
  },
  emits: ["closeMyProgress"],
  data() {
    return {
      selectedPhase: this.initiative.phase,
      allPhases: [],
      updatedPhaseIndex: "",
      currentPhaseIndex:this.initiative.phaseIndex
    };
  },
  methods: {
    phaseChanged: async function () {
      this.$emit(
        "getSeletedPhaseData",
        this.initiative.id,
        this.selectedPhase,
        this.updatedPhaseIndex,
        this.initiative
      );
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "selectedInitiative",
      "phaseNames"
    ]),
    isActivityUpdateInProgress() {
      const activitiesToUpdateCount = this.activitiesToUpdate.length;
      if (activitiesToUpdateCount == 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  created: function () {
    let filteredInitiative = this.allInitiatives?.filter((singleInitiative) => {
      return singleInitiative._id === this.initiative.id;
    });
    if (filteredInitiative) {
      this.currentPhaseIndex = filteredInitiative[0].phaseIndex;
      this.updatedPhaseIndex = this.initiative.phaseIndex;
      let allOptions = filteredInitiative[0].allPhase.slice(
        0,
        filteredInitiative[0].phaseIndex + 1
      );
      let phaseOptions = [];
      for (let i = 0; i < allOptions.length; i++) {
        let object = {
          name: allOptions[i],
          value: i,
        };
        phaseOptions.push(object);
      }
      this.allPhases = phaseOptions;
    }
    // this.allPhases.push({"name": this.initiative.phase, "value": this.initiative.phaseIndex});
  }
};
</script>
<style scoped>
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.height-100 {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  /* margin: 10px 0; */
}

.fluent-Progress-bar-normal {
  background-color: #28a745 !important;
  height: 5px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.progress-menu {
}
.progress-menu li {
  padding: 5px;
  cursor: pointer;
  margin: 0 0 5px;
}
.progress-menu li svg {
  display: inline-block;
}
.progress-menu li.active {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
}
.progress-menu li span {
  float: right;
  font-size: 12px;
  border: 1px solid #333333;
  border-radius: 1rem;
  padding: 2px 10px;
  width: 42px;
  text-align: center;
}
</style>