<template>
  <div id="app">
    <link href="https://cdn.jsdelivr.net/npm/remixicon@2.5/fonts/remixicon.css" rel="stylesheet" />
    <div class="border" id="text-editor">
      <div class="border text-sm toolbar" v-if="editor">
        <div class="align-dropdown" ref="dropdown">
          <button class="dropbtn" @click="toggleHeadingDropdown">
            {{ headingIdx? `Heading H${headingIdx} ▼` : `Heading ▼` }}
          </button>
          <div v-if="isOpen" id="dropdown-content" class="dropdown-content">
            <a v-for="index in 3" :key="index" :class="{ active: editor.isActive('heading', { level: index }) }"
              :style="{ fontSize: 20 - (index+index+index) + 'px' }" role="button" @click="onHeadingClick(index)">
              H{{ index }}
            </a>
            
          </div>
        </div>
        <a :key="4" :class="{ active: editor.isActive(4 ) }"
              :style="{ fontSize: 14 + 'px' }" role="button" @click="onHeadingClick(4)">
             Normal
            </a>
        <button v-for="({ slug, option, active, icon }, index) in textActions" :key="index"
          :class="{ active: editor.isActive(active) }" @click="onActionClick(slug, option)">
          <i :class="icon"></i>
        </button>
      </div>
      <EditorContent :editor="editor" />
      <div v-if="editor" class="flex justify-between footer px-8">
        <span class="characters-count" :class="maxLimit || minLimit ? limitWarning : ''">
          {{ charactersCount }}
          {{ maxLimit? `/ ${maxLimit} characters` : "characters" }}
          {{ minLimit ? `(Min ${minLimit} ${minLimit > 1 ? 'characters' : 'character'})` : "" }}
        </span>
        <!-- <span>|</span> -->
        <span class="words-count"> {{ wordsCount }} words </span>
      </div> 
    </div>
    <!-- <div v-if="editor" class="">
        <span v-if="this.length >= 2000" class='text-left text-xs text-red-500 float-left mt-1 mr-2 ml-0'> Maximum characters limit reached.</span> 
    </div> -->
  </div>
</template>

<script>
  import { Editor, EditorContent } from "@tiptap/vue-3";
  import StarterKit from "@tiptap/starter-kit";
  import TextAlign from "@tiptap/extension-text-align";
  import Underline from "@tiptap/extension-underline";
  import Subscript from "@tiptap/extension-subscript";
  import Superscript from "@tiptap/extension-superscript";
  import CharacterCount from "@tiptap/extension-character-count";
  import Link from '@tiptap/extension-link';
  
  export default {
    components: {
      EditorContent,
    },
    props: {
      content: {
      type: String,
      default: "",
    },
      maxLimit: {
        type: Number,
        default: 4000,
      },
      minLimit: {
        type: Number,
        default: 1,
      },
      label: {
        type: String,
        
      },
    },

    data() {
      return {
        editor: null,
        value:'',
        updateContent:'',
        isOpen:false,
        headingIdx:0,
        textActions: [
        { slug: "bold", icon: "ri-bold", active: "bold" },
    { slug: "italic", icon: "ri-italic", active: "italic" },
    { slug: "underline", icon: "ri-underline", active: "underline" },
    // { slug: "strike", icon: "ri-strikethrough", active: "strike" },
    {
      slug: "align",
      option: "left",
      icon: "ri-align-left",
      active: { textAlign: "left" },
    },
    {
      slug: "align",
      option: "center",
      icon: "ri-align-center",
      active: { textAlign: "center" },
    },
    {
      slug: "align",
      option: "right",
      icon: "ri-align-right",
      active: { textAlign: "right" },
    },
    {
      slug: "align",
      option: "justify",
      icon: "ri-align-justify",
      active: { textAlign: "justify" },
    },
    { slug: "bulletList", icon: "ri-list-unordered", active: "bulletList" },
    { slug: "orderedList", icon: "ri-list-ordered", active: "orderedList" },



    // { slug: "code", icon: "ri-code-view", active: "code" },
    // { slug: "quote", icon: "ri-chat-quote-line", active: "quote" },

    // { slug: "link", icon: "ri-link", active: "link" },
    // { slug: "unlink", icon: "ri-link-unlink", active: "link" },
    // { slug: "image", icon: "ri-image-add-line", active: "image" },
    // { slug: "table", icon: "ri-table-line", active: "table" },
    // { slug: "deleteTable", icon: "ri-delete-back-2-line", active: "table" },
    // { slug: "columnLeft", icon: "ri-insert-column-left", active: "table" },
    // { slug: "columnright", icon: "ri-insert-column-right", active: "table" },
    // { slug: "deleteColumn", icon: "ri-delete-column", active: "table" },
    // { slug: "rowTop", icon: "ri-insert-row-top", active: "table" },
    // { slug: "rowBottom", icon: "ri-insert-row-bottom", active: "table" },
    // { slug: "deleteRow", icon: "ri-delete-row", active: "table" },

    // { slug: "clear", icon: "ri-format-clear", active: "clear" },
    { slug: "undo", icon: "ri-arrow-go-back-line", active: "undo" },
    { slug: "redo", icon: "ri-arrow-go-forward-line", active: "redo" },
        ],
      };
    },
   
    

    computed: {
      charactersCount() {
        return this.editor.storage.characterCount.characters();
      },
      wordsCount() {
        return this.editor.storage.characterCount.words();
      },
      limitWarning() {
        const isMin = this.charactersCount < this.minLimit
        const isCloseToMax = this.charactersCount >= this.maxLimit - 20;
        const isMax = this.charactersCount === this.maxLimit;
  
        if (isCloseToMax && !isMax) return "warning";
        if (isMax || isMin) return "danger";
  
        return "";
      },
    },
    
    
    watch: {
      content(value) {
        console.log(value);
        if (this.editor.getHTML() === value) return;
        this.editor.commands.setContent(this.content, false);
      },
    },
    methods: {
      onActionClick(slug, option = null) {
        const vm = this.editor.chain().focus();
        const actionTriggers = {
          bold: () => vm.toggleBold().run(),
          italic: () => vm.toggleItalic().run(),
          underline: () => vm.toggleUnderline().run(),
          strike: () => vm.toggleStrike().run(),
          bulletList: () => vm.toggleBulletList().run(),
          orderedList: () => vm.toggleOrderedList().run(),
          align: () => vm.setTextAlign(option).run(),
          subscript: () => vm.toggleSubscript().run(),
          superscript: () => vm.toggleSuperscript().run(),
          undo: () => vm.undo().run(),
          redo: () => vm.redo().run(),
          clear: () => {
            vm.clearNodes().run();
            vm.unsetAllMarks().run();
          },
          // quote: () => vm.toggleBlockquote().run(),
          // link: () => this.setLink(),
          // unlink: () => vm.unsetLink().run(),
        };
  
        actionTriggers[slug]();
      },
      toggleHeadingDropdown:function(){
        this.isOpen = !this.isOpen;
      },
      closeDropdownOnOutsideClick(event) {
          if (this.isOpen && !this.$refs.dropdown.contains(event.target)) {
            this.isOpen = false;
          }
      },
      onHeadingClick(index) {
        const vm = this.editor.chain().focus();
        vm.toggleHeading({ level: index }).run();
        this.isOpen = false;
        if(index>3){
          this.headingIdx = 0;
        } else {
          this.headingIdx = index;
        }
      },
      setLink () {
        const previousUrl = this.editor.getAttributes('link').href;
        let url = window.prompt('URL', previousUrl);
        // if (url === null) return;
        if (url === '' || url === null) {
          this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
          return;
        }
        if(url.indexOf('://') === -1){
          url = 'https://' + url;
        }
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .run();
      },
    },

      
    mounted() {
      document.addEventListener('click', this.closeDropdownOnOutsideClick);
  //     const customCharacterCount = CharacterCount.extend({
  //       addStorage() {
  //         return {
  //        characters:()=>0,
  //       }
  //     },
  //     onBeforeCreate() {
  //   this.storage.characters = options => {
  //     const node = options?.node || this.editor.state.doc
  //     const mode = options?.mode || this.options.mode

  //     if (mode === 'textSize') {
  //       const text = node.textBetween(0, node.content.size, undefined, ' ')
  //         const ignoreWhiteSpaces = text.replace(/\s/g, "") || [];
  //         const size=ignoreWhiteSpaces?.length;                  
  //         return size;
  //     }
  //     return node.nodeSize
  //   }
  //   this.storage.words = options => {
  //     const node = options?.node || this.editor.state.doc
  //     const text = node.textBetween(0, node.content.size, ' ', ' ')
  //     const words = text.split(' ').filter(word => word !== '')

  //     return words.length
  //   }
  // },
  //     onUpdate(){
  //       this.storage.characters = options => {
  //       const node = options?.node || this.editor.state.doc
  //       const mode = options?.mode || this.options.mode
  //       if (mode === 'textSize') {
  //         const text = node.textBetween(0, node.content.size, undefined, ' ')
  //         const ignoreWhiteSpaces = text.replace(/\s/g, "") || [];
  //         const size=ignoreWhiteSpaces?.length;                  
  //         return size;
  //       }
  //       return node.nodeSize
  //      }
  //     }
  //   })
      this.editor = new Editor({
        content: this.content,
        extensions: [
          StarterKit,
          Underline,
          Subscript,
          Superscript,
          CharacterCount.configure({
            limit: this.maxLimit,
          }),
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          Link.configure({
            openOnClick: true,
          }),
        ],
        // editorProps: {
        //    handleDOMEvents: {
        //    keypress: (view, event) => {
        //    if (event.keyCode === 32) {
        //     if(this.editor.storage.characterCount.characters()===parseInt(this.maxLimit,10)){
        //       event.preventDefault();
        //       }
        //     }
        //    },
        //   },
        // },
      onUpdate: () => {
          this.$emit("updateContent", this.editor.getHTML(),this.label);
        },
      });
    },
    beforeUnmount() {
      this.editor.destroy();
      document.removeEventListener('click', this.closeDropdownOnOutsideClick);
    },
  };
  </script>

<style>
/* .borderClass{
  border: 1px solid #808080;
} */
#text-editor {
  /* border: 1px solid #808080; */
  background: #ffffff;
}

#text-editor .toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* border-bottom: 1px solid #808080; */
}

#text-editor .toolbar>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 16px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 2px;
  margin: 0.5em 4px;
  -webkit-appearance: none;
  cursor: pointer;
}

#text-editor .toolbar>button.active {
  background: #333;
  color: #fff;
}

#text-editor .align-dropdown {
  position: relative;
  display: inline-block;
  margin: 0.5em 8px;
}

#text-editor .align-dropdown>button {
  height: 32px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 2px;
  -webkit-appearance: none;
  cursor: pointer;
}

#text-editor .align-dropdown>.dropdown-content {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #333;
  outline: 1px solid #fff;
  border-radius: 2px;
  background-color: #fff;
  z-index: 1;
}

#text-editor .align-dropdown>.dropdown-content a {
  display: block;
  padding: 6px 12px;
  text-align: center;
  cursor: pointer;
}

#text-editor .align-dropdown>.dropdown-content a:hover,
#text-editor .align-dropdown>.dropdown-content a.active {
  background: #333;
  color: #fff;
}
.show {
  display: block;
}

.ProseMirror-focused:focus-visible {
  outline: none;
}

/* Placeholder */
.ProseMirror:not(.ProseMirror-focused) p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: theme('colors.gray.500');
  pointer-events: none;
  height: 0;
}


#text-editor .divider {
  width: 1px;
  height: 24px;
  background: #333;
  margin-right: 6px;
}

#text-editor .footer {
  color: #808080;
  font-size: 14px;
  text-align: right;
  padding: 6px;
}

#text-editor .footer .characters-count.warning {
  color: orange;
}

#text-editor .footer .characters-count.danger {
  color: red;
}

#text-editor .ProseMirror {
  height: 125px;
  overflow-y: auto; 
  padding-left: 0.5em;
  padding-right: 0.5em;
  outline: none;
  scroll-behavior: auto !important;
}

#text-editor .ProseMirror>p:first-child {
  margin-top: 0.5em;
}

#text-editor .ProseMirror>h1:first-child,
#text-editor .ProseMirror h2:first-child,
#text-editor .ProseMirror h3:first-child,
#text-editor .ProseMirror h4:first-child,
#text-editor .ProseMirror h5:first-child,
#text-editor .ProseMirror h6:first-child {
  margin-top: 0.5em;
}

#text-editor .prose {
  max-width: 100%;
  color: black;
}

#text-editor .prose>ul>li {
  margin-bottom: 0px;
  margin-top: 0px;
}

#text-editor .ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

#text-editor .ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

#text-editor .ProseMirror table td>*,
.ProseMirror table th>* {
  margin-bottom: 0;
}

#text-editor .ProseMirror table th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}

#text-editor .ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

#text-editor .ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

#text-editor .ProseMirror table p {
  margin: 0;
}

pre, code {
  white-space: pre-wrap;       
  white-space: -moz-pre-wrap;  
  white-space: -pre-wrap;      
  white-space: -o-pre-wrap;    
  word-wrap: break-word;
}
.ProseMirror h1 {
  color: #111827;
  font-weight: 800;
  font-size: 2.25em;
  line-height: 1.1111111;
}
.ProseMirror h2 {
  color: #111827;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.3333333;
}
.ProseMirror h3 {
  color: #111827;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.6;
}
.ProseMirror ul li {
  list-style-type: disc;
}
.ProseMirror ol li {
  list-style-type: decimal;
}
.ProseMirror li {
  margin-left: 12px;
}
</style>
