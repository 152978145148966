<template>
  <TransitionRoot appear :show="openCloseAttachment" as="template">
    <Dialog as="div" :open="openCloseAttachment">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black
        g-opacity-50
              "
            >
        <div class="bg-white rounded shadow-lg w-10/12 md:w-2/4">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <h3 class="font-medium text-white text-lg">{{documentData.documentName.substring(0,documentData.documentName.lastIndexOf("."))}}</h3>         
          <button
            class="
              bg-blue-400
              hover:bg-blue-500
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="close()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
          <div class="modal-body flex justify-center z-20 mt-3 mb-3">
            <img v-bind:src="`${viewAttachmentBase64}`"/>
          </div>
                <div
          class="
            w-full
            text-right
            float-right
            items-center
            justify-between
            p-5
            bg-white
            border-t border-gray-200
            rounded-bl-lg rounded-br-lg
          "
        >

          <button
            type="button"
            @click="close()"
            class="
              mr-3
              inline-flex
              justify-center
              hover:scale-110
              focus:outline-none
              px-4
              py-2
              rounded
              cursor-pointer
              hover:bg-gray-200
              bg-gray-100
              text-gray-700
              duration-200
              ease-in-out
              transition
            "
          >
            Close
          </button>

          <button
            @click="downloadAttachment()"
            class="
              inline-flex
              text-white
              bg-indigo-500
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-600
              rounded
              text-md
              disabled:opacity-30
            "
          >
            Download
          </button>
        </div>
      </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "ConfirmationDialog",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "viewAttachmentBase64",
    ])
  },
  props: {
    openCloseAttachment: Boolean,
    documentData: Object
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {
    downloadAttachment: function(){
      this.$store.dispatch("downloadAttachment", this.documentData);
    },
    close: function(){
      this.$store.dispatch("setAttachmentPopupFlag", false);
      // this.$store.dispatch("setAttachmentBase64", "");
    }
  },
};
</script>
<style scoped>
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-body {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
