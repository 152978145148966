<template>

  <div
    class="
      w-full
      flex
      items-center
      rounded-lg
      p-2
      bg-white
      shadow
      mb-0.5
      border border-gray-100
      hover:bg-gray-50 hover:shadow-lg
    "
    :class="{ 'cursor-pointer': !this.isJudgeOrUser || this.isEditAccess }"
    @click.stop="userDetail(selectUser)"
  >
    <div class="mr-2" v-if="selectUser?.image">
      <img
        :src="selectUser.image"
        :title="selectUser.name"
        alt="avatar"
        class="w-8 h-8 object-cover rounded-full"
      />
    </div>
    <div
      class="
        userImage
        h-8
        w-8
        rounded-full
        ring-2 ring-white
        shadow-lg
        item-center
        text-center
        bg-gray-400
        place-items-center
        grid
        font-thin
        text-lg text-white
        mr-2
      "
      v-else
      @click.stop="userDetail(selectUser)"
    >
      <span v-if="selectUser?.firstName">
        {{ selectUser?.firstName.charAt(0).toUpperCase()
        }}{{ selectUser?.lastName.charAt(0).toUpperCase() }}
      </span>
      <span v-else-if="selectUser?.name">
        {{ selectUser?.name.charAt(0).toUpperCase()
        }}{{ selectUser?.name.charAt(1).toUpperCase() }}
      </span>
    </div>
    <div class="flex-grow">
      <span class="text-gray-700 font-bold">
        {{ selectUser?.name }}
      </span>

      <div class="text-gray-700 text-sm font-light leading-4">
        {{ selectUser?.role }}
      </div>
    </div>
    <div
    v-if="index || index === 0"
      class="hover:bg-gray-200 p-1 rounded-full mx-1 cursor-pointer"
      @click.stop="this.$emit('removeUser', index)"
    >
      <Close />
    </div>
  </div>
</template>

<script>
import Close from "./Icon/Close.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    Close,
  },
  props: {
    selectUser: Object,
    index: Number,
  },
  computed:{
    ...mapGetters(["user"]),
    isEditAccess() {
      return this.selectUser?.accountUserId === this.user?.aspenifyUserId;
    },
    isWSO() {
      return this.user.roles.includes("manager");
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    }
  },
  methods: {
    userDetail(selectUser) {
      if(!this.isJudgeOrUser || this.isEditAccess){
      var email = selectUser.email?.toLowerCase();
      var name = email.substring(0, email.lastIndexOf("@"));
      this.$router.push({
        name: "UserProfile",
        params: { userId: selectUser?.accountUserId, userName: name },
      });
    }
    },
  },
};
</script>