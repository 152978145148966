<template>
  <form ref="anyName" @submit.prevent>
    <div style="position: relative" v-bind:class="{ open: openSuggestion }">
      <span
        class="closeIcon"
        @click="clearSelection()"
        v-bind:class="{ displayNone: selection.length === 0 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
      <input
        class="
          bg-white
          appearance-none
          border border-gray-200
          rounded
          z-3
          w-full
          py-2
          px-4
          text-gray-700
          leading-tight
          focus:outline-none focus:bg-white focus:border-gray-500
        "
        type="text"
        v-model="selection"
        :disabled="selected.length > 3"
        v-bind:class="{ 'bg-gray-300': selected.length > 3 }"
        @keydown.down="down"
        @keydown.up="up"
        @input="searchUser"
      />
      <p v-if="selected.length > 3" class="mt-2 text-sm text-red-600">
        Maximum team size limit reached
      </p>
      <!-- {{ selectToOpenList }} {{openList}} {{dropdownVisibleTo}} -->
      <ul
        class="z-10 w-full absolute overflow-auto max-h-48"
        v-if="openList === dropdownVisibleTo && selection.length !== 0"
      >
        <li v-if="loaderFlag && selectToOpenList === openList">
          <div
            class="bg-gray-50 w-full flex items-center rounded shadow border"
          >
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 animate-spin float-left "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg> -->
            <div><circularloader /></div>
          </div>
        </li>
        <li
          v-else-if="
            selection.length > 0 && suggestions.length == 0 && !loaderFlag
          "
        >
          <div
            class="
              bg-gray-50
              w-full
              flex
              items-center
              p-2
              rounded
              shadow
              border
            "
          >
            <div class="font-semibold text-gray-700">Name Not Found!</div>
          </div>
        </li>
        <li
          v-for="(suggestion, index) in suggestions"
          :key="{ index }"
          v-bind:class="{ active: isActive(index) }"
          @click="suggestionClick(suggestion)"
          v-else
        >
          <a href="javascript:void(0)">
            <div
              class="
                bg-gray-50
                hover:gray-200
                w-full
                flex
                items-center
                rounded
                shadow
                border
              "
            >
              <!-- <div class="w-12 items-center space-x-4">
                <img
                  src="data:image/jpeg;base64, iVBORw0KGgoAAAANSUhEUgAAAAUA
    AAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO
        9TXL0Y4OHwAAAABJRU5ErkJggg=="
                  alt="My profile"
                  class="w-10 h-10 rounded-full"
                />
              </div> -->
              <div class="w-full px-3 py-2">
                <div class="font-semibold text-gray-700">
                  {{ suggestion.name }}
                </div>
                <div class="text-gray-700">
                  {{ suggestion.jobTitle }}
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </form>
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import _ from "lodash";

export default {
  data() {
    return {
      open: false,
      current: 0,
      backdoor: 0,
      selection: "",
      selectToOpenList: "NA",
    };
  },
  props: {
    loaderFlag: {
      type: Boolean,
    },
    suggestions: {
      type: Array,
      required: true,
    },

    openList: {
      type: String,
      required: true,
    },
    dropdownVisibleTo: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      twoWay: true,
    },
  },
  computed: {
    // async matches() {
    //   return (
    //     this.selection.trim() !== ""  &&
    //     this.suggestions
    //       .filter(
    //         (x) => !this.selected.filter((y) => y.name == x.name).length > 0
    //       )
    //       .filter((str) => {
    //         return (
    //           str.name.toLowerCase().indexOf(this.selection.toLowerCase()) >= 0
    //         );
    //       })
    //   );
    // },

    openSuggestion() {
      return this.selection !== "" && this.open === true;
    },
  },
  beforeUpdate() {},
  created() {},
  methods: {
    // enter() {
    //   this.selection = this.matches[this.current];
    //   this.open = false;
    // },

    up() {
      if (this.current > 0) this.current--;
    },

    down() {
      if (this.current < this.matches.length - 1) this.current++;
    },

    isActive(index) {
      return index === this.current;
    },
    async searchUser(data) {
      await this.$emit("setLoadeFlag", true);
      if (this.selectToOpenList !== this.openList) {
        this.selectToOpenList = this.openList;
      }
      if (this.open == false) {
        this.open = true;
        this.current = 0;
        this.$emit("enableSave");
      }
      await this.$emit("setActiveWindow", this.selectToOpenList);
      this.change(data);
    },
    change: _.debounce(async function (data) {
      await this.$emit(
        "displayNameFilterSearch",
        this.selection,
        this.selected
      );
    }, 500),

    clearSelection() {
      this.selection = "";
      this.open = false;
      this.selectToOpenList = "NA";
      this.$emit("cleared");
      this.$emit("displayNameFilterSearch", this.selection, this.selected);
    },
    async suggestionClick(suggestion) {
      this.selected.push({
        name: suggestion.name,
        email: suggestion.email.toLowerCase(),
        // image: user.profilePic,
      });
      await this.clearSelection();
      await this.$emit("setLoadeFlag", true);
      await this.$emit("setLoadingFlag", true);
      let user = await this.$store.dispatch(
        "createProfilePicIfUserNotPresent",
        suggestion
      );
      await this.$emit(
        "displayNameFilterSearch",
        this.selection,
        this.selected
      );
      this.selected[this.selected?.length - 1].image = user?.profilePic;
      await this.$emit("setLoadeFlag", true);
      await this.$emit("setLoadingFlag", false);
    },
  },
};
</script>
<style scoped>
.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: gray;
  display: block;
  cursor: pointer;
}
.closeIcon:hover {
  opacity: 0.7;
}
.displayNone {
  display: none;
}
</style>
