<template>
    <TransitionRoot appear :show="openAssignJudgesConfirmation" as="template">
      <Dialog as="div" :open="openAssignJudgesConfirmation">
        <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>
  
            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
  
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="
                  inline-block
                  w-full
                  max-w-md
                  p-6
                  my-8
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-xl
                  rounded-md
                "
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Assign Judges
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">This selection of judges will override any existing judge assignments (if applicable), are you sure you
want to save changes? </p>
                </div>
                <div class="mt-4">
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      mr-3
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-indigo-600
                      bg-indigo-500
                      text-white
                      duration-200
                      ease-in-out
                      transition
                      disabled:opacity-30
                    "
                    @click="
                      $emit('assignJudges')
                    "
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    @click="$emit('openAssignJudgesConfirmationModal', false)"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      duration-200
                      ease-in-out
                      transition
                    "
                  >
                    No
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  
  <script>
  import { ref } from "vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  import { mapGetters } from "vuex";
  export default {
    name: "ConfirmationDialog",
    data() {
      return {};
    },
    props: {
      openAssignJudgesConfirmation: Boolean,
    },
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    computed: {
      ...mapGetters(["phaseNames","playbook"]),
    },
  };
  </script>
  