<template>
  <loader-view
    loaderFlag="true"
    loadingTitle="Logging Out"
  >
  </loader-view>
</template>
<script>
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
export default {
  components: {
    LoaderView,
  },
  created() {
    this.$store.dispatch("setLogoutFlag", true);
    this.$auth0.client.logout({ returnTo: window.location.origin });
    this.$store.dispatch("logout");
  },
};
</script>