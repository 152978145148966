import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import axios from "axios";

import auth from "@feathersjs/authentication-client";

import store from "../store/store";


const authConfig = auth({
  cookie: 'feathers-jwt',
  storageKey: 'feathers-jwt',
  storage: window.localStorage,
});

const app = feathers()
  .configure(authConfig);


//const app = feathers();

// Connect to Aspenify API Server url
const restClient = rest(process.env.VUE_APP_ASPENIFY_SERVER);

const instance = axios.create({
  timeout: 500000,
  headers: {
    "Content-Type": "application/json",
  }
});

app.configure(restClient.axios(instance));
app.hooks({
  before: {
    all: [
      async (context) => {
        const params = context.params;
        if (context.service.name === 'eventlogs') {
           return context;
        }
        const { user } = store.getters
        if (params) {
          if (!params.query) {
            params.query = {}
          }
          if (user){
            params.query.accountId = store.getters.user.accountId;
            params.query.organizationId = store.getters.user.organizationId;
          }
        }
        return context;
      },
    ]
  },
  after: {
    all: [
      async (context) => {
        return context;
      },
    ]
  },
  error: {
    all: [
      async (context) => {
        //store.dispatch('setLoadingFlag',false);
        console.error(context.service.name, context.method, context.error);
        return context;
      },
    ],
  },
})

export default app;