<template>
  <div class="relative w-full max-w-full flex-grow flex-1">
    <h2 v-if="isParticipant || isWSO" class="text-xl font-bold text-gray-700">
      Actions
    </h2>
    <div v-if="isParticipant || isWSO" class="flex flex-col mx-auto">
      <ul class="actionsList">
        <li v-if="showOpenmicScheduleDate && !executiveAccess && isCreateInitiativeFlag">
          <p
            class="text-center text-gray-500"
            v-html="OpenmicScheduledDate"
            v-if="OpenmicScheduledDate"
          ></p>
          <p class="text-center text-indigo-500" v-else>Something went wrong</p>
        </li>

        <li v-if="(isWSO || isManager) && rocbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="
              $emit(
                'openConfigureDateModal',
                'configureSignUp',
                'rescheduleOpenMic'
              )
            "
          >
            Reschedule
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'signUpCancelled', 'cancelOpenMic')"
          >
            Cancel
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'signUpCompleted', 'openMicCompleted')"
          >
            Mark as Completed
          </button>
        </li>
      </ul>

      <ul class="actionsList">
        <li v-if="showregArchScheduleDate && !executiveAccess && isCreateInitiativeFlag">
          <p
            class="text-center text-gray-500"
            v-html="registerArchScheduledDate"
            v-if="registerArchScheduledDate"
          ></p>
          <p class="text-center text-indigo-500" v-else>Something went wrong</p>
        </li>

        <li v-if="(isWSO || isManager) && rocRegisterArchbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="
              $emit(
                'openConfigureDateModal',
                'configureArchitecture',
                'rescheduleArchitecture'
              )
            "
          >
            Reschedule
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocRegisterArchbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'ArchitectureCancelled','cancelArchitecture')"
          >
            Cancel
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocRegisterArchbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'ArchitectureComplete','architectureCompleted')"
          >
            Mark as Completed
          </button>
        </li>
      </ul>

      <ul class="actionsList">
        <li v-if="showBuildingScheduleDate && !executiveAccess && isCreateInitiativeFlag">
          <p
            class="text-center text-gray-500"
            v-html="buildingScheduledDate"
            v-if="buildingScheduledDate"
          ></p>
          <p class="text-center text-indigo-500" v-else>Something went wrong</p>
        </li>

        <li v-if="(isWSO || isManager) && rocBuildingbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="
              $emit(
                'openConfigureDateModal',
                'configureBuildingBlock',
                'rescheduleBuildingBlock'
              )
            "
          >
            Reschedule
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocBuildingbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'BuildingBlockCancelled','cancelBuildingBlock')"
          >
            Cancel
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocBuildingbtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'BuildingBlockComplete','buildingBlockCompleted')"
          >
            Mark as Completed
          </button>
        </li>
      </ul>

      <!-- <ul class="actionsList">
        <li v-if="showStaffScheduleDate">
          <p
            class="text-center text-gray-500"
            v-html="staffScheduledDate"
            v-if="staffScheduledDate"
          ></p>
          <p class="text-center text-indigo-500" v-else>Something went wrong</p>
        </li>

        <li v-if="(isWSO || isManager) && rocStaffbtn && !statusCheck">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="
              $emit(
                'openConfigureDateModal',
                'configureStaff',
                'rescheduleStaff'
              )
            "
          >
            Reschedule
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocStaffbtn && !statusCheck">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'StaffCancelled')"
          >
            Cancel
          </button>
        </li>
        <li v-if="(isWSO || isManager) && rocStaffbtn && !statusCheck">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openMarkAsCompletedModal', 'StaffComplete')"
          >
            Mark as Completed
          </button>
        </li>
      </ul> -->

      <ul class="actionsList">
        <li v-if="isManager ">
          <button
            class="btnPsvg"
            :class="primaryBtn"
            @click="$emit('viewProgress')"
          >
            View Progress
          </button>
        </li>
        <li
          v-if="
            signupBtn &&
            (isWSO ||
              isUser ||
              isManager ||
              isJManager ||
              isJMember ||
              isTeamlead) && !statusCheck && !executiveAccess && isCreateInitiativeFlag
          "
        >
          <button
            :disabled="statusCheck"
            class="btnPsvg"
            :class="primaryBtn"
            @click="$emit('openSignUp', 'signUpOpenMic')"
          >
            Sign-up for Open Mic
          </button>
        </li>
        <li v-if="(isWSO || isManager || isTeamlead) && configureBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnPsvg"
            :class="primaryBtn"
            @click="$emit('openConfigureDateModal', 'configureSignUp', 'configureOpenMic')"
          >
            Configure date for Open Mic
          </button>
        </li>
        <li v-if="(isUser || isJManager || isJMember) && viewRequestBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openRequestModal', 'viewSignUp')"
          >
            View Open Mic Request
          </button>
        </li>

        <li
          v-if="
            archReviewBtn &&
            (isWSO ||
              isUser ||
              isManager ||
              isJManager ||
              isJMember ||
              isTeamlead) && !statusCheck && !executiveAccess && isCreateInitiativeFlag
          "
        >
          <button
            :disabled="statusCheck"
            :class="primaryBtn"
            @click="$emit('openSignUp', 'registerArchitecture')"
          >
            Register for Architecture Review
          </button>
        </li>
        <li v-if="(isWSO || isManager || isTeamlead) && configureArchBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnPsvg"
            :class="primaryBtn"
            @click="$emit('openConfigureDateModal', 'configureArchitecture','configureArchitecture')"
          >
            Configure date for Architecture
          </button>
        </li>
        <li v-if="(isUser || isJManager || isJMember) && viewArchRequestBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openRequestModal', 'viewArchitecture')"
          >
            View Register for Architecture Request
          </button>
        </li>
        <li
          v-if="
            buildingReviewBtn &&
            (isWSO ||
              isUser ||
              isManager ||
              isJManager ||
              isJMember ||
              isTeamlead)  && !statusCheck && !executiveAccess && isCreateInitiativeFlag
          "
          @click="$emit('openSignUp', 'registerBuildingBlock')"
        >
          <button :disabled="statusCheck" :class="primaryBtn">
            Register to Building Block Review
          </button>
        </li>
        <li v-if="(isWSO || isManager || isTeamlead) && configureBuildingBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag">
          <button
            :disabled="statusCheck"
            class="btnPsvg"
            :class="primaryBtn"
            @click="$emit('openConfigureDateModal', 'configureBuildingBlock','configureBuildingBlock')"
          >
            Configure date for Building Block
          </button>
        </li>
        <li
          v-if="(isUser || isJManager || isJMember) && viewBuildingRequestBtn && !statusCheck && !executiveAccess && isCreateInitiativeFlag"
        >
          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="secondryBtn"
            @click="$emit('openRequestModal', 'viewBuildingBlock')"
          >
            View Building Block Review Request
          </button>
        </li>
       

        <li v-if="isParticipant && !statusCheck">
          

          <button
            :disabled="statusCheck"
            class="btnSsvg"
            :class="primaryBtn"
            @click="getHelp"
          >
            <svg
              width="24"
              height="24"
              version="1.1"
              viewBox="0 0 700 700"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m350 513.33c-61.883 0-121.23-24.582-164.99-68.34-43.758-43.758-68.34-103.11-68.34-164.99s24.582-121.23 68.34-164.99c43.758-43.758 103.11-68.34 164.99-68.34s121.23 24.582 164.99 68.34c43.758 43.758 68.34 103.11 68.34 164.99 0 40.957-10.781 81.195-31.258 116.67-20.48 35.469-49.938 64.926-85.406 85.406-35.473 20.477-75.711 31.258-116.67 31.258zm0-420c-49.508 0-96.988 19.668-131.99 54.676-35.008 35.004-54.676 82.484-54.676 131.99s19.668 96.988 54.676 131.99c35.004 35.008 82.484 54.676 131.99 54.676s96.988-19.668 131.99-54.676c35.008-35.004 54.676-82.484 54.676-131.99 0-32.766-8.625-64.957-25.012-93.332-16.383-28.379-39.945-51.941-68.324-68.324-28.375-16.387-60.566-25.012-93.332-25.012zm23.332 70c0-8.3359-4.4453-16.039-11.664-20.207s-16.117-4.168-23.336 0-11.664 11.871-11.664 20.207c0 8.3359 4.4453 16.039 11.664 20.207 7.2188 4.168 16.117 4.168 23.336 0 7.2188-4.168 11.664-11.871 11.664-20.207zm0 233.33v-163.33c0-8.3359-4.4453-16.039-11.664-20.207s-16.117-4.168-23.336 0-11.664 11.871-11.664 20.207v163.34c0 8.3359 4.4453 16.039 11.664 20.207s16.117 4.168 23.336 0 11.664-11.871 11.664-20.207z"
              />
            </svg>
            Get Help
          </button>
        </li>

        <li v-if="selectedInitiative.status !== 6 && isProposer  && selfTerminateButton && !executiveAccess && isCreateInitiativeFlag">
          <button :class="primaryBtn" @click="openSelfTerminateModal(true)">
            Self-terminate Submission
          </button>
        </li>
       
      </ul>
    </div>
  </div>

  <SelfTerminateIdea
    v-if="isSelfTerminate"
    @openSelfTerminateModal="openSelfTerminateModal"
    :selectedInitiative="selectedInitiative"
  >
  </SelfTerminateIdea>
</template>
<script>
import { format } from "fecha";
import SelfTerminateIdea from "../components/Modal/SelfTerminateIdea.vue";
import feathersClient from "../services/feathersClient";
import { mapGetters } from "vuex";
export default {
  name: "ActionList",
  props: {
    selectedInitiative: Object,
    user: Object,
  },
  components: {
    SelfTerminateIdea,
  },
  data() {
    return {
      isSelfTerminate: false,
    };
  },
  computed: {
    ...mapGetters([
      "playbook",
    ]),
    primaryBtn() {
      return "w-full text-center rounded bg-indigo-500 text-white  px-1 py-1   w-full border border-indigo-500 disabled:opacity-70 ";
    },
    secondryBtn() {
      return "w-full text-center rounded bg-white text-indigo-500  px-1 py-1    w-full border border-indigo-500 disabled:opacity-70  ";
    },
    isManager() {
      return this.user?.roles.includes("manager");
    },
    isTeamlead() {
      return this.user?.role === "TeamLead";
    },
    isUser() {
      return this.user?.roles.includes("user");
    },
    isJManager() {
      return this.user?.roles.includes("judgeManager");
    },
    isJMember() {
      return this.user?.roles.includes("JudgeMember");
    },
    isExecutive() {
      return this.user?.roles.includes("isExecutive");
    },
    isProposer() {
      return (
        this.selectedInitiative.metaData?.submitter[0]?.email?.toString() ===
        this.user.email?.toString()
      );
    },
    isParticipant() {
      let metaData = this.selectedInitiative.metaData;
      let user = this.user;

      let allMembers = [
        ...metaData?.teamLead,
        ...metaData?.teamMembers,
        ...metaData?.mentors,
        ...metaData?.submitter
      ];
      return (
        allMembers.filter(
          (f) => f?.email?.toLowerCase() === user?.email?.toLowerCase()
        )?.length !== 0
      );
    },
    statusCheck() {
      return this.selectedInitiative.status !== 1;
    },
    openMicData() {
      return this.selectedInitiative?.metaData?.signUpForOpenmic
        ? this.selectedInitiative?.metaData?.signUpForOpenmic
        : [];
    },
    executiveAccess() {
      return this.user && this.user.roles.includes("executive");
    },
    showOpenmicScheduleDate() {
      return (
        (this.pI('Sign-up for Open Mic')) &&
        this.openMicData[this.openMicData?.length - 1]?.configure &&
        !this.openMicData[this.openMicData?.length - 1]?.markComplete
      );
    },
    signupBtn() {
      return (
        (this.pI('Sign-up for Open Mic')) &&
        (this.openMicData?.length === 0 ||
          (this.openMicData[this.openMicData?.length - 1]?.userComment &&
            this.openMicData[this.openMicData?.length - 1]?.configure &&
            this.openMicData[this.openMicData?.length - 1]?.markComplete))
      );
    },
    viewRequestBtn() {
      return (
        this.openMicData[this.openMicData?.length - 1]?.userComment &&
        !this.openMicData[this.openMicData?.length - 1]?.configure
      );
    },

    configureBtn() {
      return (
        (this.pI("Configure date for Open Mic")) &&
        this.openMicData[this.openMicData?.length - 1]?.userComment &&
        !this.openMicData[this.openMicData?.length - 1]?.configure &&
        !this.openMicData[this.openMicData?.length - 1]?.markComplete
      );
    },

    waitingBtn() {
      return (
        this.openMicData?.length === 0 ||
        (this.openMicData[this.openMicData?.length - 1]?.userComment &&
          this.openMicData[this.openMicData?.length - 1]?.configure &&
          this.openMicData[this.openMicData?.length - 1]?.markComplete)
      );
    },
    rocbtn() {
      return (
        (this.pI("Sign-up for Open Mic")) && 
        this.openMicData[this.openMicData?.length - 1]?.userComment &&
        this.openMicData[this.openMicData?.length - 1]?.configure &&
        !this.openMicData[this.openMicData?.length - 1]?.markComplete
      );
    },
    OpenmicScheduledDate() {
      let d =
        this.openMicData[this.openMicData?.length - 1]?.configure?.scheduledOn
          ?.date;

      let f =
        this.openMicData[this.openMicData?.length - 1]?.configure?.scheduledOn
          ?.from;

      let t =
        this.openMicData[this.openMicData?.length - 1]?.configure?.scheduledOn
          ?.to;
      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Open Mic is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "hh:mm A")} </b> `;
      } else {
        return `Open Mic is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "MMM D, YYYY hh:mm A")} </b> `;
      }
    },

    regArchData() {
      return this.selectedInitiative?.metaData?.registerArchitecture
        ? this.selectedInitiative?.metaData?.registerArchitecture
        : [];
    },
    viewArchRequestBtn() {
      return (
        this.regArchData[this.regArchData?.length - 1]?.userComment &&
        !this.regArchData[this.regArchData?.length - 1]?.configure
      );
    },
    archReviewBtn() {
      return (
        this.pI("Register for Architecture Review") &&
        (this.regArchData?.length === 0 ||
          (this.regArchData[this.regArchData?.length - 1]?.userComment &&
            this.regArchData[this.regArchData?.length - 1]?.configure &&
            this.regArchData[this.regArchData?.length - 1]?.markComplete))
      );
    },
    configureArchBtn() {
      return (
        (this.pI("Configure date for Architecture")) &&
        this.regArchData[this.regArchData?.length - 1]?.userComment &&
        !this.regArchData[this.regArchData?.length - 1]?.configure &&
        !this.regArchData[this.regArchData?.length - 1]?.markComplete
      );
    },
    rocRegisterArchbtn() {
      return (
        (this.pI("Register for Architecture Review")) &&
        this.regArchData[this.regArchData?.length - 1]?.userComment &&
        this.regArchData[this.regArchData?.length - 1]?.configure &&
        !this.regArchData[this.regArchData?.length - 1]?.markComplete
      );
    },
    showregArchScheduleDate() {
      return (
        (this.pI("Register for Architecture Review")) &&
        this.regArchData[this.regArchData?.length - 1]?.configure &&
        !this.regArchData[this.regArchData?.length - 1]?.markComplete
      );
    },
    registerArchScheduledDate() {
      let d =
        this.regArchData[this.regArchData?.length - 1]?.configure?.scheduledOn
          ?.date;

      let f =
        this.regArchData[this.regArchData?.length - 1]?.configure?.scheduledOn
          ?.from;

      let t =
        this.regArchData[this.regArchData?.length - 1]?.configure?.scheduledOn
          ?.to;
      
      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Register Architecture is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "hh:mm A")} </b> `;
      } else {
        return `Register Architecture is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "MMM D, YYYY hh:mm A")} </b> `;
      }
    },

    buildingBlockData() {
      return this.selectedInitiative?.metaData?.registerBuildingBlock
        ? this.selectedInitiative?.metaData?.registerBuildingBlock
        : [];
    },
    viewBuildingRequestBtn() {
      return (
        this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.userComment &&
        !this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure
      );
    },
    buildingReviewBtn() {
      return (
        this.pI("Register to Building Block Review") &&
        (this.buildingBlockData?.length === 0 ||
          (this.buildingBlockData[this.buildingBlockData?.length - 1]
            ?.userComment &&
            this.buildingBlockData[this.buildingBlockData?.length - 1]
              ?.configure &&
            this.buildingBlockData[this.buildingBlockData?.length - 1]
              ?.markComplete))
      );
    },
    configureBuildingBtn() {
      return (
        this.pI("Configure date for Building Block") &&
        this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.userComment &&
        !this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.configure &&
        !this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.markComplete
      );
    },
    rocBuildingbtn() {
      return (
        this.pI("Register to Building Block Review") &&
        this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.userComment &&
        this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure &&
        !this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.markComplete
      );
    },
    showBuildingScheduleDate() {
      return (
        this.pI("Register to Building Block Review") &&
        this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure &&
        !this.buildingBlockData[this.buildingBlockData?.length - 1]
          ?.markComplete
      );
    },
    buildingScheduledDate() {
      let d =
        this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure
          ?.scheduledOn?.date;

      let f =
        this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure
          ?.scheduledOn?.from;

      let t =
        this.buildingBlockData[this.buildingBlockData?.length - 1]?.configure
          ?.scheduledOn?.to;

      if(new Date(f).getDate() == new Date(t).getDate() && new Date(f).getMonth() == new Date(t).getMonth() &&  new Date(f).getFullYear() == new Date(t).getFullYear()){
        return `Register Building Block is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "hh:mm A")} </b> `;
      } else {
        return `Register Building Block is scheduled from <br />
            <b> ${format(new Date(f), "MMM D, YYYY hh:mm A")} </b> to <b>  ${format(new Date(t), "MMM D, YYYY hh:mm A")} </b> `;
      }
    },

    staffData() {
      return this.selectedInitiative?.metaData?.registerExtendedStaff
        ? this.selectedInitiative?.metaData?.registerExtendedStaff
        : [];
    },
    viewStaffRequestBtn() {
      return (
        this.staffData[this.staffData?.length - 1]?.userComment &&
        !this.staffData[this.staffData?.length - 1]?.configure
      );
    },
    selfTerminateButton(){
      return this.pI("Self-terminate Idea") ;
    },
    isWSO() {
      return this.user.roles.includes("manager");
    },
    isCreateInitiativeFlag(){
      if (this.playbook?.createInitiative) {
        return true;
      }
      return false;
    }
  },
  emits: [
    "openSignUp",
    "openRequestModal",
    "openConfigureDateModal",
    "openMarkAsCompletedModal",
    "viewMyProgress",
    "viewProgress",
  ],
  methods: {
    openSelfTerminateModal: function (flag) {
      this.isSelfTerminate = flag;
    },
    getHelp() {
      window.open("https://cisco.sharepoint.com/sites/InnovationAwards");
    },
    pI(actionButtonName) {
      if(this.selectedInitiative?.playbookPhaseMetaData?.actions.includes(actionButtonName)){
        return true;
      } else {
        return false;
      }
    }
  },
  created:  function () {
  },
};
</script>

<style scoped>
.actionsList {
  margin: 0 0 5px 0;
}
.actionsList li {
  background: white;
  padding: 10px 10px 0 10px;
}
.actionsList li:first-child {
  margin: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.actionsList li:last-child {
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 10px;
}

.btnPsvg svg {
  float: left;
  padding-top: 2px;
  fill: white;
}
.btnSsvg svg {
  float: left;
  padding-top: 2px;
  fill: #1983bf;
}
</style>
