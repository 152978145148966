<template>
  <header
    class="
      flex
      justify-between
      items-center
      py-2
      px-4
      text-white
      bg-gray-primary
      z-40
      header-shadow
      fixed
      w-full
    "
    style="height: 60px"
    :key="componentKey"
  >
    <div
      class="flex items-center cursor-pointer"
      v-tooltip="{ theme: { placement: 'right' } }"
    >
      <div
        v-if="!contractorAccess"
        class="w-6 mr-6 hover:bg-gray-600 duration-500 rounded p-0.5"
        @click="setSideOpen"
        v-tooltip="'Toggle Menu'"
      >
        <!-- @mouseleave="mouseleave" -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div class="relative mx-7 lg:mx-0">
        <a href="https://cisco.com/" target="_blank">
          <img src="/logo-blue.png" width="54" />
        </a>
      </div>
      <div v-if="!contractorAccess">
        <div
          v-for="(page, index) in allPages"
          :key="index"
          @click="updatePage(page, $event)"
          class="ml-7 subMenu"
          :class="{
            'text-cisco-blue': $route.params.page === page.metaData.pageId,
            'border-b-2 border-cisco-blue':
              $route.params.page === page.metaData.pageId,
          }"
        >
          {{ page?.metaData?.name }}
        </div>
      </div>

      <!-- <div
        @click="
          $router.push({
            name: 'Home',
          })
        "
        class="ml-7 subMenu"
        :class="{
          'text-cisco-blue': $route.name === 'Home',
          'border-b-2 border-cisco-blue': $route.name === 'Home',
        }"
      >
        <span> <span class="font-semibold">Bold </span><span>Bets</span> </span>
      </div>
      <div
        @click="
          $router.push({
            name: 'Faq',
          })
        "
        class="ml-7 subMenu"
        :class="{
          'text-cisco-blue': $route.name === 'Faq',
          'border-b-2 border-cisco-blue': $route.name === 'Faq',
        }"
      >
        <span>FAQ </span>
      </div>
      <div
        @click="
          $router.push({
            name: 'Term',
          })
        "
        class="ml-7 subMenu"
        :class="{
          'text-cisco-blue': $route.name === 'Term',
          'border-b-2 border-cisco-blue': $route.name === 'Term',
        }"
      >
        <span>Terms & Conditions</span>
      </div> -->
    </div>

    <div class="flex items-center">
      <!-- <div class="mr-5 flex" style="width: 350px" v-if="displaySearch">
        <input
          autofocus
          v-on:keyup.enter="onEnter"
          type="text"
          name=""
          id=""
          placeholder="Search Nomination"
          v-model="search"
          class="
            w-full
            px-4
            py-1
            text
            bg-gray-700
            rounded-full
            focus:outline-none
            bg-transparent
          "
        />
        <span
         v-if="search?.length > 0"
          class="
            cursor-pointer
            float-right
            hover:bg-gray-300 hover:rounded-r-full
          "
          @click="clearSearchInput"
        >
          <IconClose />
        </span>
      </div> -->
      <!-- <pre>{{user?.roles}}</pre> -->
      <div class="hidden">{{ user?.roles }}</div>
      <div
        class="flex items-center max-w-md mx-auto bg-white rounded-lg mr-4"
        v-if="displaySearch && !contractorAccess"
      >
        <div class="w-full">
          <input
            autofocus
            v-on:keyup.enter="onEnter"
            type="text"
            name=""
            id=""
            placeholder="Search Submissions"
            v-model="search"
            class="w-full px-4 py-1 focus:outline-none bg-gray-700"
          />
        </div>
        <div>
          <button
            type="submit"
            class="
              bg-gray-800
              cursor-pointer
              flex
              items-center
              justify-center
              w-8
              h-8
              text-white
              disabled:opacity-60
              hover:bg-gray-600
            "
            @click="clearSearchInput"
            :disabled="search?.length === 0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        v-if="!contractorAccess"
        v-tooltip="{
          theme: {
            placement: 'left',
          },
        }"
      >
        <button
          class="
            flex
            mx-2
            p-1.5
            rounded-full
           
            hover:bg-gray-600
            hover:shadow
            focus:outline-none
          "
          v-tooltip="'Help'"
          @click="helpRequest(true)"
        >
          <svg
            fill="white"
            class="w-6 h-6"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <symbol id="s" overflow="visible">
                <path
                  d="m18.766-1.125c-0.96875 0.5-1.9805 0.875-3.0312 1.125-1.043 0.25781-2.1367 0.39062-3.2812 0.39062-3.3984 0-6.0898-0.94531-8.0781-2.8438-1.9922-1.9062-2.9844-4.4844-2.9844-7.7344 0-3.2578 0.99219-5.8359 2.9844-7.7344 1.9883-1.9062 4.6797-2.8594 8.0781-2.8594 1.1445 0 2.2383 0.13281 3.2812 0.39062 1.0508 0.25 2.0625 0.625 3.0312 1.125v4.2188c-0.98047-0.65625-1.9453-1.1406-2.8906-1.4531-0.94922-0.3125-1.9492-0.46875-3-0.46875-1.875 0-3.3516 0.60547-4.4219 1.8125-1.0742 1.1992-1.6094 2.8555-1.6094 4.9688 0 2.1055 0.53516 3.7617 1.6094 4.9688 1.0703 1.1992 2.5469 1.7969 4.4219 1.7969 1.0508 0 2.0508-0.14844 3-0.45312 0.94531-0.3125 1.9102-0.80078 2.8906-1.4688z"
                />
              </symbol>
              <symbol id="b" overflow="visible">
                <path
                  d="m13.734-11.141c-0.4375-0.19531-0.87109-0.34375-1.2969-0.4375-0.41797-0.10156-0.83984-0.15625-1.2656-0.15625-1.2617 0-2.2305 0.40625-2.9062 1.2188-0.67969 0.80469-1.0156 1.9531-1.0156 3.4531v7.0625h-4.8906v-15.312h4.8906v2.5156c0.625-1 1.3438-1.7266 2.1562-2.1875 0.82031-0.46875 1.8008-0.70312 2.9375-0.70312 0.16406 0 0.34375 0.011719 0.53125 0.03125 0.19531 0.011719 0.47656 0.039062 0.84375 0.078125z"
                />
              </symbol>
              <symbol id="a" overflow="visible">
                <path
                  d="m17.641-7.7031v1.4062h-11.453c0.125 1.1484 0.53906 2.0078 1.25 2.5781 0.70703 0.57422 1.7031 0.85938 2.9844 0.85938 1.0312 0 2.082-0.14844 3.1562-0.45312 1.082-0.3125 2.1914-0.77344 3.3281-1.3906v3.7656c-1.1562 0.4375-2.3125 0.76562-3.4688 0.98438-1.1562 0.22656-2.3125 0.34375-3.4688 0.34375-2.7734 0-4.9297-0.70312-6.4688-2.1094-1.5312-1.4062-2.2969-3.3789-2.2969-5.9219 0-2.5 0.75391-4.4609 2.2656-5.8906 1.5078-1.4375 3.582-2.1562 6.2188-2.1562 2.4062 0 4.332 0.73047 5.7812 2.1875 1.4453 1.4492 2.1719 3.3828 2.1719 5.7969zm-5.0312-1.625c0-0.92578-0.27344-1.6719-0.8125-2.2344-0.54297-0.57031-1.25-0.85938-2.125-0.85938-0.94922 0-1.7188 0.26562-2.3125 0.79688s-0.96484 1.2969-1.1094 2.2969z"
                />
              </symbol>
              <symbol id="d" overflow="visible">
                <path
                  d="m9.2188-6.8906c-1.0234 0-1.793 0.17188-2.3125 0.51562-0.51172 0.34375-0.76562 0.85547-0.76562 1.5312 0 0.625 0.20703 1.1172 0.625 1.4688 0.41406 0.34375 0.98828 0.51562 1.7188 0.51562 0.92578 0 1.7031-0.32812 2.3281-0.98438 0.63281-0.66406 0.95312-1.4922 0.95312-2.4844v-0.5625zm7.4688-1.8438v8.7344h-4.9219v-2.2656c-0.65625 0.92969-1.3984 1.6055-2.2188 2.0312-0.82422 0.41406-1.8242 0.625-3 0.625-1.5859 0-2.8711-0.45703-3.8594-1.375-0.99219-0.92578-1.4844-2.1289-1.4844-3.6094 0-1.7891 0.61328-3.1016 1.8438-3.9375 1.2383-0.84375 3.1797-1.2656 5.8281-1.2656h2.8906v-0.39062c0-0.76953-0.30859-1.332-0.92188-1.6875-0.61719-0.36328-1.5703-0.54688-2.8594-0.54688-1.0547 0-2.0312 0.10547-2.9375 0.3125-0.89844 0.21094-1.7305 0.52344-2.5 0.9375v-3.7344c1.0391-0.25 2.0859-0.44141 3.1406-0.57812 1.0625-0.13281 2.125-0.20312 3.1875-0.20312 2.7578 0 4.75 0.54688 5.9688 1.6406 1.2266 1.0859 1.8438 2.8555 1.8438 5.3125z"
                />
              </symbol>
              <symbol id="c" overflow="visible">
                <path
                  d="m7.7031-19.656v4.3438h5.0469v3.5h-5.0469v6.5c0 0.71094 0.14062 1.1875 0.42188 1.4375s0.83594 0.375 1.6719 0.375h2.5156v3.5h-4.1875c-1.9375 0-3.3125-0.39844-4.125-1.2031-0.80469-0.8125-1.2031-2.1797-1.2031-4.1094v-6.5h-2.4219v-3.5h2.4219v-4.3438z"
                />
              </symbol>
              <symbol id="j" overflow="visible">
                <path
                  d="m12.766-13.078v-8.2031h4.9219v21.281h-4.9219v-2.2188c-0.66797 0.90625-1.4062 1.5703-2.2188 1.9844s-1.7578 0.625-2.8281 0.625c-1.8867 0-3.4336-0.75-4.6406-2.25-1.2109-1.5-1.8125-3.4258-1.8125-5.7812 0-2.3633 0.60156-4.2969 1.8125-5.7969 1.207-1.5 2.7539-2.25 4.6406-2.25 1.0625 0 2 0.21484 2.8125 0.64062 0.82031 0.42969 1.5664 1.0859 2.2344 1.9688zm-3.2188 9.9219c1.0391 0 1.8359-0.37891 2.3906-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3516-1.1562-2.3906-1.1562-1.043 0-1.8398 0.38672-2.3906 1.1562-0.55469 0.76172-0.82812 1.8711-0.82812 3.3281 0 1.4609 0.27344 2.5742 0.82812 3.3438 0.55078 0.76172 1.3477 1.1406 2.3906 1.1406z"
                />
              </symbol>
              <symbol id="i" overflow="visible">
                <path
                  d="m10.5-3.1562c1.0508 0 1.8516-0.37891 2.4062-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3555-1.1562-2.4062-1.1562-1.0547 0-1.8594 0.38672-2.4219 1.1562-0.55469 0.77344-0.82812 1.8828-0.82812 3.3281 0 1.4492 0.27344 2.5586 0.82812 3.3281 0.5625 0.77344 1.3672 1.1562 2.4219 1.1562zm-3.25-9.9219c0.67578-0.88281 1.4219-1.5391 2.2344-1.9688 0.82031-0.42578 1.7656-0.64062 2.8281-0.64062 1.8945 0 3.4453 0.75 4.6562 2.25 1.207 1.5 1.8125 3.4336 1.8125 5.7969 0 2.3555-0.60547 4.2812-1.8125 5.7812-1.2109 1.5-2.7617 2.25-4.6562 2.25-1.0625 0-2.0078-0.21094-2.8281-0.625-0.8125-0.42578-1.5586-1.0859-2.2344-1.9844v2.2188h-4.8906v-21.281h4.8906z"
                />
              </symbol>
              <symbol id="h" overflow="visible">
                <path
                  d="m0.34375-15.312h4.8906l4.125 10.391 3.5-10.391h4.8906l-6.4375 16.766c-0.64844 1.6953-1.4023 2.8828-2.2656 3.5625-0.86719 0.6875-2 1.0312-3.4062 1.0312h-2.8438v-3.2188h1.5312c0.83203 0 1.4375-0.13672 1.8125-0.40625 0.38281-0.26172 0.67969-0.73047 0.89062-1.4062l0.14062-0.42188z"
                />
              </symbol>
              <symbol id="g" overflow="visible">
                <path d="m2.3594-21.281h4.8906v21.281h-4.8906z" />
              </symbol>
              <symbol id="f" overflow="visible">
                <path
                  d="m16.547-12.766c0.61328-0.94531 1.3477-1.6719 2.2031-2.1719 0.85156-0.5 1.7891-0.75 2.8125-0.75 1.7578 0 3.0977 0.54688 4.0156 1.6406 0.92578 1.0859 1.3906 2.6562 1.3906 4.7188v9.3281h-4.9219v-7.9844-0.35938c0.007813-0.13281 0.015625-0.32031 0.015625-0.5625 0-1.082-0.16406-1.8633-0.48438-2.3438-0.3125-0.48828-0.82422-0.73438-1.5312-0.73438-0.92969 0-1.6484 0.38672-2.1562 1.1562-0.51172 0.76172-0.77344 1.8672-0.78125 3.3125v7.5156h-4.9219v-7.9844c0-1.6953-0.14844-2.7852-0.4375-3.2656-0.29297-0.48828-0.8125-0.73438-1.5625-0.73438-0.9375 0-1.6641 0.38672-2.1719 1.1562-0.51172 0.76172-0.76562 1.8594-0.76562 3.2969v7.5312h-4.9219v-15.312h4.9219v2.2344c0.60156-0.86328 1.2891-1.5156 2.0625-1.9531 0.78125-0.4375 1.6406-0.65625 2.5781-0.65625 1.0625 0 2 0.25781 2.8125 0.76562 0.8125 0.51172 1.4258 1.2305 1.8438 2.1562z"
                />
              </symbol>
              <symbol id="r" overflow="visible">
                <path
                  d="m12.422-21.281v3.2188h-2.7031c-0.6875 0-1.1719 0.125-1.4531 0.375-0.27344 0.25-0.40625 0.6875-0.40625 1.3125v1.0625h4.1875v3.5h-4.1875v11.812h-4.8906v-11.812h-2.4375v-3.5h2.4375v-1.0625c0-1.6641 0.46094-2.8984 1.3906-3.7031 0.92578-0.80078 2.3672-1.2031 4.3281-1.2031z"
                />
              </symbol>
              <symbol id="e" overflow="visible">
                <path
                  d="m9.6406-12.188c-1.0859 0-1.9141 0.39062-2.4844 1.1719-0.57422 0.78125-0.85938 1.9062-0.85938 3.375s0.28516 2.5938 0.85938 3.375c0.57031 0.77344 1.3984 1.1562 2.4844 1.1562 1.0625 0 1.875-0.38281 2.4375-1.1562 0.57031-0.78125 0.85938-1.9062 0.85938-3.375s-0.28906-2.5938-0.85938-3.375c-0.5625-0.78125-1.375-1.1719-2.4375-1.1719zm0-3.5c2.6328 0 4.6914 0.71484 6.1719 2.1406 1.4766 1.418 2.2188 3.3867 2.2188 5.9062 0 2.5117-0.74219 4.4805-2.2188 5.9062-1.4805 1.418-3.5391 2.125-6.1719 2.125-2.6484 0-4.7148-0.70703-6.2031-2.125-1.4922-1.4258-2.2344-3.3945-2.2344-5.9062 0-2.5195 0.74219-4.4883 2.2344-5.9062 1.4883-1.4258 3.5547-2.1406 6.2031-2.1406z"
                />
              </symbol>
              <symbol id="q" overflow="visible">
                <path
                  d="m17.75-9.3281v9.3281h-4.9219v-7.1094c0-1.3438-0.03125-2.2656-0.09375-2.7656s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-21.281h4.8906v8.2031c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"
                />
              </symbol>
              <symbol id="p" overflow="visible">
                <path
                  d="m2.5781-20.406h5.875l7.4219 14v-14h4.9844v20.406h-5.875l-7.4219-14v14h-4.9844z"
                />
              </symbol>
              <symbol id="o" overflow="visible">
                <path
                  d="m2.1875-5.9688v-9.3438h4.9219v1.5312c0 0.83594-0.007813 1.875-0.015625 3.125-0.011719 1.25-0.015625 2.0859-0.015625 2.5 0 1.2422 0.03125 2.1328 0.09375 2.6719 0.070313 0.54297 0.17969 0.93359 0.32812 1.1719 0.20703 0.32422 0.47266 0.57422 0.79688 0.75 0.32031 0.16797 0.69141 0.25 1.1094 0.25 1.0195 0 1.8203-0.39062 2.4062-1.1719 0.58203-0.78125 0.875-1.8672 0.875-3.2656v-7.5625h4.8906v15.312h-4.8906v-2.2188c-0.74219 0.89844-1.5234 1.5586-2.3438 1.9844-0.82422 0.41406-1.7344 0.625-2.7344 0.625-1.7617 0-3.1055-0.53906-4.0312-1.625-0.92969-1.082-1.3906-2.6602-1.3906-4.7344z"
                />
              </symbol>
              <symbol id="n" overflow="visible">
                <path
                  d="m17.75-9.3281v9.3281h-4.9219v-7.1406c0-1.3203-0.03125-2.2344-0.09375-2.7344s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-15.312h4.8906v2.2344c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"
                />
              </symbol>
              <symbol id="m" overflow="visible">
                <path
                  d="m2.5781-20.406h8.7344c2.5938 0 4.582 0.57812 5.9688 1.7344 1.3945 1.1484 2.0938 2.7891 2.0938 4.9219 0 2.1367-0.69922 3.7812-2.0938 4.9375-1.3867 1.1562-3.375 1.7344-5.9688 1.7344h-3.4844v7.0781h-5.25zm5.25 3.8125v5.7031h2.9219c1.0195 0 1.8047-0.25 2.3594-0.75 0.5625-0.5 0.84375-1.2031 0.84375-2.1094 0-0.91406-0.28125-1.6172-0.84375-2.1094-0.55469-0.48828-1.3398-0.73438-2.3594-0.73438z"
                />
              </symbol>
              <symbol id="l" overflow="visible">
                <path
                  d="m2.3594-15.312h4.8906v15.031c0 2.0508-0.49609 3.6172-1.4844 4.7031-0.98047 1.082-2.4062 1.625-4.2812 1.625h-2.4219v-3.2188h0.85938c0.92578 0 1.5625-0.21094 1.9062-0.625 0.35156-0.41797 0.53125-1.2461 0.53125-2.4844zm0-5.9688h4.8906v4h-4.8906z"
                />
              </symbol>
              <symbol id="k" overflow="visible">
                <path
                  d="m14.719-14.828v3.9844c-0.65625-0.45703-1.3242-0.79688-2-1.0156-0.66797-0.21875-1.3594-0.32812-2.0781-0.32812-1.3672 0-2.4336 0.40234-3.2031 1.2031-0.76172 0.79297-1.1406 1.9062-1.1406 3.3438 0 1.4297 0.37891 2.543 1.1406 3.3438 0.76953 0.79297 1.8359 1.1875 3.2031 1.1875 0.75781 0 1.4844-0.10938 2.1719-0.32812 0.6875-0.22656 1.3203-0.56641 1.9062-1.0156v4c-0.76172 0.28125-1.5391 0.48828-2.3281 0.625-0.78125 0.14453-1.5742 0.21875-2.375 0.21875-2.7617 0-4.9219-0.70703-6.4844-2.125-1.5547-1.4141-2.3281-3.3828-2.3281-5.9062 0-2.5312 0.77344-4.5039 2.3281-5.9219 1.5625-1.4141 3.7227-2.125 6.4844-2.125 0.80078 0 1.5938 0.074219 2.375 0.21875 0.78125 0.13672 1.5547 0.35156 2.3281 0.64062z"
                />
              </symbol>
            </defs>
            <g>
              <path
                d="m349.99 39.203c-70.395 0-127.68 56.91-128.23 127.23 0 5.1523 2.543 6.6094 6.4805 6.6094h39.969c3.8398 0 6.5234-3.8945 6.5234-6.4336 0.44531-41.277 33.871-74.484 75.254-74.484 41.66 0 75.32 33.66 75.32 75.32 0 24.258-7.0625 39.918-17.605 51.441-10.543 11.523-24.961 18.895-39.91 24.855-14.945 5.9609-30.262 10.398-42.527 16.957-6.1328 3.2773-11.578 7.1328-15.605 12.293-4.0273 5.1562-6.4297 11.68-6.4297 19.109v89.543c0 1.4648 0.60547 2.9219 1.6406 3.9609 1.0352 1.0352 2.4922 1.6406 3.9609 1.6406h41.16c1.4648 0 2.9219-0.60547 3.9609-1.6406 1.0352-1.0352 1.6406-2.4922 1.6406-3.9609v-74.145c0-2.3867 0.79688-3.957 3.3672-6.2109 2.5664-2.2539 6.9141-4.6641 12.438-7.0742 11.047-4.8242 26.613-9.793 42.434-17.762 31.641-15.938 64.402-45.359 64.402-109.01 0-70.785-57.457-128.24-128.24-128.24z"
                fill-rule="evenodd"
              />
              <path
                d="m329.44 444.08c-21.117 0-38.359 17.242-38.359 38.359 0 21.117 17.242 38.359 38.359 38.359 21.117 0 38.359-17.242 38.359-38.359 0-21.117-17.242-38.359-38.359-38.359z"
                fill-rule="evenodd"
              />
              
            </g>
          </svg>
        </button>
      </div>

      <div
        v-if="user"
        class="relative flex"
        v-tooltip="{
          theme: {
            placement: 'left',
          },
        }"
      >
        <!-- <pre>{{user?.email}}</pre> -->
        <button v-tooltip="user?.name">
          <img
            @click="dropdownOpen = !dropdownOpen"
            class="object-cover h-8 w-8 rounded-full"
            :src="user.profilePic"
            alt="Your avatar"
          />
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-50"
        ></div>

        <div
          v-show="dropdownOpen"
          class="
            absolute
            right-0
            mt-2
            py-2
            w-48
            bg-white
            rounded-md
            shadow-xl
            z-75
          "
        >
          <span
            @click="profile"
            class="
              cursor-pointer
              block
              px-4
              py-2
              text-sm text-gray-700
              hover:bg-indigo-600 hover:text-white
            "
            >Profile</span
          >
          <a
            @click="logout"
            href="#"
            class="
              block
              px-4
              py-2
              text-sm text-gray-700
              hover:bg-indigo-600 hover:text-white
            "
            >Log out</a
          >
        </div>
      </div>
    </div>
  </header>
  <!-- <pre> {{allPages}}</pre> -->
  <help-request-modal
    :openHelp="openHelp"
    @helpRequest="helpRequest"
  >

  </help-request-modal>
</template>

<script>
import { defineComponent, ref } from "vue";
import IconClose from "../components/Icon/Close.vue";
import { mapGetters } from "vuex";
import { useSidebar } from "../hooks/useSidebar";
import HelpRequestModal from "./confirmationModal/HelpRequestModal.vue";
export default defineComponent({
  components: {
    IconClose,
    HelpRequestModal
  },
  data() {
    return {
      search: "",
      enterButtonPressed: false,
      openSideBar: false,
      componentKey: 0,
      openHelp:false
    };
  },

  watch: {
    user() {
      this.componentKey += 1;
    },
    webPages() {
      this.componentKey += 1;
    },
    enterButtonPressed(){
      this.$router.push({
        name: "SubmissionLibrary",
        params: {
          searchText: this.search,
          openFilter: true,
          enterButtonPressed: this.enterButtonPressed,
        },
      });
    },
  },
  created: async function () {
    await this.$store.dispatch("getPages");
  },
  methods: {
    setSideOpen() {
      this.openSideBar = !this.openSideBar;
      this.$emit("openSideBarAction", this.openSideBar);
    },
    mouseover() {
      this.openSideBar = true;
      this.$emit("openSideBarAction", this.openSideBar);
    },
    mouseleave() {
      this.openSideBar = false;
      this.$emit("openSideBarAction", this.openSideBar);
    },
    onEnter: function () {
      this.enterButtonPressed = !this.enterButtonPressed;
      this.$router.push({
        name: "SubmissionLibrary",
        params: { searchText: this.search, openFilter: true },
      });
    },
    profile() {
      this.dropdownOpen = false;

      var email = this.user?.email?.toLowerCase();
      var name = email.substring(0, email.lastIndexOf("@"));

      this.$router.push({
        name: "UserProfile",
        params: {
          userId: this.user?.aspenifyUserId,
          userName: name,
          isOwnProfile: true,
        },
      });

      // this.$router.push({ name: "myProfile" });
    },
    async updatePage(item, event) {
      let payload = {
        pageId: item.metaData.pageId,
      };
      await this.$store.dispatch("getPageById", payload);

      this.$router.push({
        name: "PageView",
        params: { page: item.metaData.pageId },
      });
    },
    clearSearchInput: function () {
      this.search = "";
      this.onEnter();
    },
    helpRequest(open){
      this.openHelp = open
    }
  },
  computed: {
    ...mapGetters(["user", "webPages"]),
    contractorAccess() {
      return this.user && this.user.roles.includes("contractor");
    },
    displayRole() {
      let r = this.user.roles;
      if (r.includes("judgeManager")) {
        return "Manager";
      } else if (r.includes("judgeMember")) {
        return "Member";
      } else if (r.includes("manager")) {
        return "Power User";
      } else if (r.includes("user")) {
        return "User";
      } else {
        return this.user.role;
      }
    },

    displaySearch() {
      // let display = this.$route.path.startsWith("/judging")
      //   ? // this.$route.path.startsWith("/allideas")
      //     false
      //   : true;
      return !(this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")));
    },
    allPages() {
      return this.$store.getters.webPages;
    },
  },
  setup() {
    const dropdownOpen = ref(false);
    const { isOpen } = useSidebar();

    function logout() {
      this.$router.push({ name: "Logout" });
    }

    return {
      isOpen,
      logout,
      dropdownOpen,
    };
  },
});
</script>
<style scoped>
.header-shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.subMenu {
}
.subMenu:hover {
  color: #3ba4e0;
}
</style>
