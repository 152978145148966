import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist';

import user from './user.js';
import initiative from './initiative.js';
import playbook from './playbook.js';
import loadingFlag from './loadingFlag.js';
import jira from './jira.js';
import dashboard from './dashboard.js';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: state.user
  })
})

const store = createStore({
  modules: {
    user,
    playbook,
    loadingFlag,
    initiative,
    jira,
    dashboard
  },
  plugins: [vuexLocal.plugin]
})

export default store;