<template>
  <div>
  <h3 class="text-gray-700 text-2xl font-medium">Playbook Library</h3>


<aspenify-playbook 
      :api-Key="apikey">
  </aspenify-playbook>


  </div>
</template>
<script>
//
const API_KEY = process.env.VUE_APP_API_KEY;
export default {
  data() {
    return {
      apikey: API_KEY
    };
  }
}
</script>
