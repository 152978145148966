<template>
  <div
    class="border-b p-2 bg-gray-100 font-semibold text-sm cursor-pointer"
    @click="openRelease(release)"
  >
    {{ release.name }}

    <span class="float-right"> </span>

    <button
      class="
        border border-gray-300
        rounded-lg
        pl-2
        pr-2
        w-auto
        mr-2
        text-center
        float-right
        bg-blue-500
        hover:bg-blue-700 text-white 
      "
      @click="showProjectModal(true)"
    >
      Assign Project
    </button>
  </div>
  <div class="flex flex-col m-3" v-show="release.isReleaseTabOpen">
    <div
      class="rounded border border-gray-300"
      v-for="(epic, index) in selectedEpics"
      :key="index"
    >
      <jira-epics-tabs :epic="epic"> </jira-epics-tabs>
    </div>
  </div>

  <jira-project :projectModal="projectModal" @close="closeModal" @closeModalOnEpicSelection="closeModalOnEpicSelection"></jira-project>
</template>

<script>
import JiraProject from "./JiraProject.vue";
import JiraEpicsTabs from "./JiraEpicsTabs.vue";
export default {
  name: "jira-integration",
  props: {
    release: Object,
  },
  data() {
    return {
      projectModal: false,
      selectedEpics:[]
    };
  },
  methods: {
    openRelease: function (item) {
      item.isReleaseTabOpen = !item.isReleaseTabOpen;
    },

    setClass: function (item) {
      if (item.isReleaseTabOpen == true) {
        return "open";
      }

      return "close";
    },

    showProjectModal: function (value) {
      this.projectModal = value;
    },
    closeModal: function () {
      this.projectModal = false;
    },
    closeModalOnEpicSelection:function(selectedEpics){
      this.selectedEpics=selectedEpics
      this.projectModal = false;
    }
  },
  mounted: function () {},
  components: {
    JiraProject,
    JiraEpicsTabs,
  },
};
</script>
